exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__mainCont---26ozJ{margin:20px 40px 0}.styles-m__analyticsCont---1Qcc4{min-height:2em;background-color:rgba(207,223,209,.513);margin:3px;border-radius:5px;display:flex;align-items:center;justify-content:stretch}.styles-m__row---3iLcJ{width:100%;display:flex;align-items:center;height:2.5em;padding-left:5px}.styles-m__col---2-8iI,.styles-m__colCentered---1pOrg,.styles-m__colHeader---GbZdq,.styles-m__colToRight---2Q54X{display:flex;align-items:center;justify-content:left;height:100%}.styles-m__colHeader---GbZdq{font-size:1em;font-weight:bolder}.styles-m__colCentered---1pOrg{justify-content:center}.styles-m__colToRight---2Q54X{justify-content:right}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,4BACI,kBACJ,CAEA,iCAEI,cAAe,CACf,uCAA4C,CAC5C,UAAW,CACX,iBAAkB,CAClB,YAAa,CACb,kBAAmB,CACnB,uBAEJ,CAEA,uBACI,UAAW,CACX,YAAa,CACb,kBAAmB,CACnB,YAAa,CACb,gBACJ,CAEA,iHACI,YAAa,CACb,kBAAmB,CACnB,oBAAqB,CACrB,WACJ,CAEA,6BACI,aAAc,CACd,kBACJ,CAEA,+BACI,sBACJ,CAEA,8BACI,qBACJ","file":"styles.m.css","sourcesContent":[".mainCont {\n    margin: 20px 40px 0 40px;\n}\n\n.analyticsCont {\n    \n    min-height: 2em;\n    background-color: rgba(207, 223, 209, 0.513);\n    margin: 3px;\n    border-radius: 5px;\n    display: flex; \n    align-items: center;\n    justify-content: stretch;\n    /* margin-left: 4%; */\n}\n\n.row {\n    width: 100%;\n    display: flex;  \n    align-items: center;\n    height: 2.5em;\n    padding-left: 5px;\n}\n\n.col, .colHeader, .colCentered, .colToRight {\n    display: flex; \n    align-items: center;\n    justify-content: left;\n    height: 100%;\n}\n\n.colHeader {\n    font-size: 1em;\n    font-weight: bolder;\n}\n\n.colCentered {\n    justify-content: center;\n}\n\n.colToRight {\n    justify-content: right;\n}\n"]}]);

// Exports
exports.locals = {
	"mainCont": "styles-m__mainCont---26ozJ",
	"analyticsCont": "styles-m__analyticsCont---1Qcc4",
	"row": "styles-m__row---3iLcJ",
	"col": "styles-m__col---2-8iI",
	"colHeader": "styles-m__colHeader---GbZdq",
	"colCentered": "styles-m__colCentered---1pOrg",
	"colToRight": "styles-m__colToRight---2Q54X"
};