import AccountPlanPage from 'pages/AccountPlanPage';
import ApiSettingsPage from 'pages/ApiSettingsPage';
import CloseDebtPage from 'pages/CloseDebtPage';
import DebtMovementPage from 'pages/DebtMovementPage';
import EntriesJournalPage from 'pages/EntriesJournalPage';
import MyNormativePage from 'pages/MyNormativePage';
import ReferenceBookPage from 'pages/ReferenceBookPage';
import TaskPage from 'pages/TaskPage';
import TasksPage from 'pages/TasksPage';
import SupplierDebtsPage from '../containers/SuppliersDebtsContainer';
import ACCDocumentsPage from './ACCDocumentsPage';
import AddEmployeePage from './AddEmployeePage';
import AddInteractionPage from './AddInteractionPage';
import AddOrderPage from './AddOrderPage';
import AggregatePage from './AggregatePage';
import AggregatesPage from './AggregatesPage';
import AgreementPage from './AgreementPage';
import AvailabilitiesPage from './AvailabilitiesPage';
import BarcodePage from './BarcodePage';
import BrandsPage from './BrandsPage';
import BusinessPackagePage from './BusinessPackagePage';
import CallsJournalPage from './CallsJournalPage';
import CallsPage from './CallsPage';
import CashBankPage from './CashBankPage';
import CashFlowPage from './CashFlowPage';
import CashSettingsPage from './CashSettingsPage';
import ChartPage from './ChartPage';
import ClientHotOperationsPage from './ClientHotOperationsPage';
import ClientPage from './ClientPage';
import ClientsPage from './ClientsPage';
import ComplexesPage from './ComplexesPage';
import CrossesPage from './CrossesPage';
import DashboardPage from './DashboardPage';
import DetailStatusSettingsPage from './DetailStatusSettingsPage';
import DiagnosticPatternsPage from './DiagnosticPatternsPage';
import DiagnosticsDirectoryPage from './DiagnosticsDirectoryPage';
import DirectoriesJournalPage from './DirectoriesJournalPage ';
import DirectoriesPage from './DirectoriesPage';
import DirectoryBrandsPage from './DirectoryBrandsPage';
import EditEmployeePage from './EditEmployeePage';
import EditMakesPage from './EditMakesPage';
import EmployeesPage from './EmployeesPage';
import ExceptionPage from './ExceptionPage';
import ExchangeRatePage from './ExchangeRatePage';
import ForgotPasswordPage from './ForgotPasswordPage';
import GraphicReportsPage from './GraphicReportsPage';
import InteractionsDirectoriesPage from './InteractionsDirectoriesPage';
import InteractionsPage from './InteractionsPage';
import LaborsPage from './LaborsPage';
import LoginPage from './LoginPage';
import ManagerRolePage from './ManagerRolePage';
import MarkupsPage from './MarkupsPage';
import MyTasksPage from './MyTasksPage';
import NewDocumentPage from './NewDocumentPage';
import NewPasswordPage from './NewPasswordPage';
import OnlineServiceBookPage from './OnlineServiceBookPage';
import OrderPage from './OrderPage';
import OrdersKanbanPage from './OrdersKanbanPage';
import OrdersPage from './OrdersPage';
import PackagePage from './PackagePage';
import PaymentPage from './PaymentPage';
import PricelistsPage from './PricelistsPage';
import ProductPage from './ProductPage';
import ProfilePage from './ProfilePage';
import { QuickRequestsPage } from './QuickRequestsPage';
import ReceivablesAndPayablesPage from './ReceivablesAndPayablesPage';
import RefactoredRightsPage from './RefactoredRightsPage';
import RegulationsPage from './RegulationsPage';
import RepairMapSettingPage from './RepairMapSettingPage';
import ReportsPage from './ReportsPage';
import RequisiteSettingPage from './RequisiteSettingPage';
import ReviewPage from './ReviewPage';
import ReviewsPage from './ReviewsPage';
import RolePage from './RolePage';
import SMSSettingsPage from './SMSSettingsPage';
import SalaryDocumentPage from './SalaryDocumentPage';
import ServicesPage from './ServicesPage';
import SettingsDirectoriesPage from './SettingsDirectoriesPage';
import SettingsPage from './SettingsPage';
import SourcePage from './SourcePage';
import SparePartsWorkplacePage from './SparePartsWorkplacePage';
import SubscriptionHistoryPage from './SubscriptionHistoryPage';
import SubscriptionPackagesPage from './SubscriptionPackagesPage';
import SupplierPage from './SupplierPage';
import SuppliersPage from './SuppliersPage';
import UnitsPage from './UnitsPage';
import UserAgreementPage from './UserAgreementPage';
import VehiclePage from './VehiclePage';
import VehiclesPage from './VehiclesPage';
import WMSPage from './WMSPage';
import WebSettingsPage from './WebSettingsPage';

// re-exports (*) must be before ES6 other (default) exports
// webpack issue: https://github.com/webpack/webpack/issues/3509
export * from './Locations';
export * from './Reports';
export * from './Storage';
export * from './SyncImportExport';
export {
    ACCDocumentsPage,
    AccountPlanPage,
    AddEmployeePage,
    AddInteractionPage,
    AddOrderPage,
    AggregatePage,
    AggregatesPage,
    AgreementPage,
    ApiSettingsPage,
    AvailabilitiesPage,
    BarcodePage,
    BrandsPage,
    BusinessPackagePage,
    CallsJournalPage,
    CallsPage,
    CashBankPage,
    CashFlowPage,
    CashSettingsPage,
    ChartPage,
    ClientHotOperationsPage,
    ClientPage,
    ClientsPage,
    CloseDebtPage,
    ComplexesPage,
    CrossesPage,
    DashboardPage,
    DebtMovementPage,
    DetailStatusSettingsPage,
    DiagnosticPatternsPage,
    DiagnosticsDirectoryPage,
    DirectoriesJournalPage,
    DirectoriesPage,
    DirectoryBrandsPage,
    EditEmployeePage,
    EditMakesPage,
    EmployeesPage,
    EntriesJournalPage,
    ExceptionPage,
    ExchangeRatePage,
    ForgotPasswordPage,
    GraphicReportsPage,
    InteractionsDirectoriesPage,
    InteractionsPage,
    LaborsPage,
    LoginPage,
    ManagerRolePage,
    MarkupsPage,
    MyNormativePage,
    MyTasksPage,
    NewDocumentPage,
    NewPasswordPage,
    OnlineServiceBookPage,
    OrderPage,
    OrdersKanbanPage,
    OrdersPage,
    PackagePage,
    PaymentPage,
    PricelistsPage,
    ProductPage,
    ProfilePage,
    QuickRequestsPage,
    ReceivablesAndPayablesPage,
    RefactoredRightsPage,
    ReferenceBookPage,
    RegulationsPage,
    RepairMapSettingPage,
    ReportsPage,
    RequisiteSettingPage,
    ReviewPage,
    ReviewsPage,
    RolePage,
    SMSSettingsPage,
    SalaryDocumentPage,
    ServicesPage, SettingsDirectoriesPage, SettingsPage,
    SourcePage,
    SparePartsWorkplacePage,
    SubscriptionHistoryPage,
    SubscriptionPackagesPage,
    SupplierDebtsPage,
    SupplierPage,
    SuppliersPage,
    TaskPage,
    TasksPage,
    UnitsPage,
    UserAgreementPage,
    VehiclePage,
    VehiclesPage,
    WMSPage,
    WebSettingsPage
};

