/*
This is cash flow report page, it uses analytics to provide report(it is base of this report).
Each analytics level has it's sum values.
Each analytics is grouped accordingly to its level in a binary tree(parent analytics and its dependencies)

Release date: 24.02.2021;
Last updated: 24.02.2021;
*/

import { Button } from 'antd';
import { Layout } from 'commons';
import { StatsPanel } from 'components';
import {
    fetchAnalytics,
    fetchCashboxes,
    fetchExcelFileReport,
    fetchReportCashFlow
} from 'core/reports/reportCashFlow/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isForbidden, permissions } from 'utils';
import CashFlowFilter from './CashFlowFilter';
import CashFlowItemsDropdown from './CashFlowItemsDropdown';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    tableData: state.reportCashFlow.tableData,
    stats: state.reportCashFlow.stats,
    user: state.auth
});

const mapDispatchToProps = {
    fetchReportCashFlow,
    fetchAnalytics,
    fetchCashboxes,
    fetchExcelFileReport
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class ReportCashFlowPage extends Component {
    constructor(props) {
        super(props);

        this.onGetExelFileReport = this.onGetExelFileReport.bind(this);
    }

    componentDidMount() {
        this.props.fetchReportCashFlow();

        // For filter
        this.props.fetchAnalytics();
        this.props.fetchCashboxes();

        if (this.props.location.state && this.props.location.state.downloadReport) {
            // this.props.setModal(this.props.location.state.showModal)
            this.onGetExelFileReport();
        }
    }

    getNormalizedStats(stats) {
        const {
            intl: { formatMessage }
        } = this.props;

        const normalizedStats = [
            {
                label: formatMessage({ id: 'report_cash_flow_page.total_increase' }),
                value: stats.totalIncreaseSum
            },
            {
                label: formatMessage({ id: 'report_cash_flow_page.total_decrease' }),
                // TT requires decreasing sums to be with minus before nuber
                value: -stats.totalDecreaseSum
            },
            {
                label: formatMessage({ id: 'report_cash_flow_page.total_balance' }),
                value: stats.totalBalanceSum
            }
        ];

        return normalizedStats;
    }

    onGetExelFileReport(e) {
        const { fetchExcelFileReport } = this.props;

        fetchExcelFileReport();
    }

    render() {
        const {
            tableData,
            stats,
            user,
            intl: { formatMessage }
        } = this.props;

        const normalizedStats = this.getNormalizedStats(stats);

        return (
            <Layout
                controls={
                    <div className={Styles.buttonGroup}>
                        <Button
                            disabled={isForbidden(
                                user,
                                permissions.ACCESS_REPORT_PROFIT_FROM_BUSINESSES_PRINT
                            )}
                            onClick={this.onGetExelFileReport}
                            type='primary'
                        >
                            <FormattedMessage id='report_cash_flow_page.download_full_report' />
                        </Button>
                    </div>
                }
                paper
                title={
                    <div>
                        <div>
                            <FormattedMessage id='navigation.report_cash_flow' />
                        </div>
                    </div>
                }
            >
                <div className={Styles.header}>
                    <div className={Styles.filterCont}>
                        <CashFlowFilter />
                    </div>

                    <div>
                        <StatsPanel extended stats={normalizedStats} />
                    </div>
                </div>

                <CashFlowItemsDropdown tableData={tableData} />
            </Layout>
        );
    }
}
