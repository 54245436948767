import { Form } from '@ant-design/compatible';
import { EditOutlined, FilterFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Select, Table } from 'antd';
import { Catcher } from 'commons';
import { DateRangePicker } from 'components';
import {
    createBusinessPackage,
    fetchBusinessPackages,
    hideForms,
    setFilters,
    setPage,
    setShowCreateBusinessPackageForm,
    setShowUpdateBusinessPackageForm,
    setSort,
    updateBusinessPackage
} from 'core/businessPackage/duck';
import dayjs from 'dayjs';
import { AddBusinessPackageForm, BusinessPackageForm } from 'forms';
import { BusinessSearchField } from 'forms/_formkit';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import { DownloadReportModal } from './modals/DownloadReportModal';
import Styles from './styles.m.css';

const FormItem = Form.Item;
const { Option } = Select;

const mapDispatchToProps = {
    createBusinessPackage,
    updateBusinessPackage,
    setSort,
    setPage,
    setFilters,
    hideForms,
    setShowCreateBusinessPackageForm,
    setShowUpdateBusinessPackageForm,
    fetchBusinessPackages
};

const mapStateToProps = state => ({
    showCreateBusinessPackageForm: state.businessPackage.showCreateBusinessPackageForm,
    businessPackage: state.businessPackage.businessPackage,
    businessPackages: state.businessPackage.businessPackages,
    rolesPackages: state.businessPackage.rolesPackages,
    errors: state.businessPackage.errors,
    sort: state.businessPackage.sort,
    filters: state.businessPackage.filters,
    page: state.businessPackage.page,
    isFetching: state.ui.businessPackageFetching,
    businesses: state.search.businesses,
    count: Number(state.businessPackage.count || 0),
    user: state.auth
});

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
};

const sortOptions = {
    asc: 'ascend',
    desc: 'descend'
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class BusinessPackageContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleDownloadReportModal: false,
            rolesPackages: []
        };
    }

    componentDidMount() {
        this.props.fetchBusinessPackages();
        this.fetchAllPackages();
    }

    _handleColumnOrder = (sort, fieldName) => (sort.field === fieldName ? sortOptions[sort.order] : void 0);

    _handleTableChange = (pagination, filters, sorter) => {
        if (!sorter) {
            return;
        }
        const sort = {
            field: sorter.field,
            order: sorter.order === 'ascend' ? 'asc' : 'desc'
        };

        this.props.setSort(sort);
    };

    fetchAllPackages = async () => {
        const res = await fetchAPI('GET', 'v2/roles_and_accesses/standard_packages/simple_list', null, null, {
            handleErrorInternally: true
        });

        this.setState({
            rolesPackages: res
        });
    };

    getColumnSearchProps = dataIndex => {
        let filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.props.setFilters({ filterDocumentType: e.target.value });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                style={{ marginBottom: 8, display: 'block', width: 180 }}
                value={this.props.filterDocumentType}
            />
        );

        if (dataIndex === 'dateFrom') {
            filterComponent = (confirm, clearFilters) => (
                <div style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        allowClear
                        dateRange={[
                            this.props.filters.dateFrom ? dayjs(this.props.filters.dateFrom) : undefined,
                            this.props.filters.dateTo ? dayjs(this.props.filters.dateTo) : undefined
                        ]}
                        format='YYYY-MM-DD'
                        getPopupContainer={trigger => trigger.parentNode}
                        onDateChange={async dateRange => {
                            await this.props.setFilters({
                                dateFrom: dayjs(dateRange[0]).format('YYYY-MM-DD'),
                                dateTo: dayjs(dateRange[1]).format('YYYY-MM-DD')
                            });
                            this.handleSearch(confirm, dataIndex);
                            this.props.fetchBusinessPackages();
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            );
        }

        // if (dataIndex === 'packageId') {
        //     filterComponent = (confirm, clearFilters) => (
        //         <Select
        //             allowClear
        //             onChange={value => {
        //                 this.props.setFilters({
        //                     packageId: value
        //                 });
        //             }}
        //             // mode='multiple'
        //             placeholder={this.props.intl.formatMessage({
        //                 id: 'storage_journal.choose_type'
        //             })}
        //             style={{ marginBottom: 8, display: 'block', width: 275 }}
        //             value={this.props.filters.packageId}
        //         >
        //             {this.props.rolesPackages.map(elem => (
        //                 <Option key={elem.packageId} value={elem.packageId}>
        //                     {elem.name}
        //                 </Option>
        //             ))}
        //         </Select>
        //     );
        // }

        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}
                    >
                        {dataIndex !== 'dateFrom' && (
                            <Button
                                icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                onClick={() => this.handleSearch(confirm, dataIndex)}
                                size='small'
                                type='primary'
                            >
                                <FormattedMessage id='search' />
                            </Button>
                        )}
                        <Button onClick={() => this.handleReset(confirm, clearFilters, dataIndex)} size='small'>
                            <FormattedMessage id='reset' />
                        </Button>
                    </div>
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.props[dataIndex] || this.props.filters.dateFrom ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = confirm => {
        confirm();
        this.props.setPage(1);
        this.props.fetchBusinessPackages();
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.props.setFilters({
            dateFrom: undefined,
            dateTo: undefined
        });
        await this.props.setPage(1);
        await this.props.setSort({
            field: 'activationDatetime'
        });

        this.props.fetchBusinessPackages();
    };

    render() {
        const {
            user,
            businesses,
            businessPackage,
            businessPackages,
            filters,

            showCreateBusinessPackageForm,

            createBusinessPackage,
            updateBusinessPackage,
            setFilters,
            setShowCreateBusinessPackageForm
        } = this.props;

        const { setShowUpdateBusinessPackageForm } = this.props;
        const { formatMessage } = this.props.intl;

        const { visibleDownloadReportModal, rolesPackages } = this.state;

        const columns = [
            {
                title: formatMessage({
                    id: 'business-package-container.business_name'
                }),
                dataIndex: 'businessName',
                sorter: true,
                sortOrder: this._handleColumnOrder(this.props.sort, 'businessName'),
                width: '15%'
            },
            {
                title: formatMessage({
                    id: 'business-package-container.business_address'
                }),
                dataIndex: 'businessAddress',
                width: '20%'
            },
            {
                title: formatMessage({
                    id: 'business-package-container.activation_datetime'
                }),
                dataIndex: 'activationDatetime',
                sorter: true,
                sortOrder: this._handleColumnOrder(this.props.sort, 'activationDatetime'),
                width: '15%',
                render: (name, record) => dayjs(record.activationDatetime).format('YYYY-MM-DD HH:mm:ss')
            },
            {
                title: formatMessage({
                    id: 'business-package-container.expiration_datetime'
                }),
                dataIndex: 'expirationDatetime',
                sorter: true,
                sortOrder: this._handleColumnOrder(this.props.sort, 'expirationDatetime'),
                ...this.getColumnSearchProps('dateFrom'),
                width: '15%',
                render: (name, record) => dayjs(record.expirationDatetime).format('YYYY-MM-DD HH:mm:ss')
            },
            {
                title: formatMessage({
                    id: 'business-package-container.package_name'
                }),
                dataIndex: 'packageName',
                sorter: true,
                sortOrder: this._handleColumnOrder(this.props.sort, 'packageName'),
                width: '20%'
            },

            {
                width: '15%',
                render: record => (
                    <EditOutlined
                        className={Styles.businessEditIcon}
                        onClick={() => setShowUpdateBusinessPackageForm(record)}
                    />
                )
            }
        ];

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(this.props.count / 25) * 25,
            hideOnSinglePage: true,
            current: this.props.page,
            onChange: page => this.props.setPage(page)
        };

        return (
            <Catcher>
                <Form className={Styles.businessPackageFilters} layout='inline'>
                    <FormItem
                        {...formItemLayout}
                        className={Styles.formItemSelectFilter}
                        colon={false}
                        label={<FormattedMessage id='business-package-container.package' />}
                    >
                        <Select
                            allowClear
                            filterOption={(input, option) =>
                                Boolean(option.props.children.toLowerCase().indexOf(input.toLowerCase() !== -1))
                            }
                            onChange={packageId => this.props.setFilters({ packageId })}
                            optionFilterProp='children'
                            showSearch
                            style={{ minWidth: 240 }}
                            value={filters.packageId || void 0}
                        >
                            {rolesPackages
                                ? rolesPackages.map(({ id, name }) => (
                                      <Option key={id} value={id}>
                                          {name}
                                      </Option>
                                  ))
                                : []}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        className={Styles.formItemSelectFilter}
                        colon={false}
                        label={<FormattedMessage id='business-package-container.business' />}
                    >
                        <BusinessSearchField
                            businessId={filters.businessId}
                            onSelect={businessId => setFilters({ businessId })}
                        />
                    </FormItem>
                    <Button
                        disabled={!filters.businessId}
                        onClick={() => setShowCreateBusinessPackageForm(true)}
                        style={{ alignSelf: 'normal' }}
                    >
                        <FormattedMessage id='business-package-container.create' />
                    </Button>

                    <Button
                        disabled={!isGrantAccessed(user, grants.ADMINISTRATION_BUSINESS_PACKAGES)}
                        onClick={() => {
                            this.setState({
                                visibleDownloadReportModal: true
                            });
                        }}
                        style={{ alignSelf: 'normal', marginLeft: 12 }}
                    >
                        <FormattedMessage id='business-package-container.download_report' />
                    </Button>
                </Form>
                <Table
                    bordered
                    columns={columns}
                    dataSource={businessPackages}
                    loading={this.props.isFetching}
                    onChange={this._handleTableChange}
                    pagination={pagination}
                    rowClassName={record =>
                        dayjs(record.expirationDatetime).isBefore(dayjs()) ? Styles.expiredRaw : null
                    }
                    rowKey={record => record.id}
                    size='small'
                />
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={() => this.props.hideForms()}
                    title={<FormattedMessage id='business-package-container.create_form_title' />}
                    visible={Boolean(showCreateBusinessPackageForm && filters.businessId)}
                >
                    <AddBusinessPackageForm
                        {...filters}
                        businessName={
                            filters.businessId &&
                            _.get(
                                _.find(businesses, {
                                    businessId: filters.businessId
                                }),
                                'name'
                            )
                        }
                        createBusinessPackage={createBusinessPackage}
                        rolesPackages={rolesPackages}
                    />
                </Modal>
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={() => this.props.hideForms()}
                    title={<FormattedMessage id='business-package-container.edit_form_title' />}
                    visible={Boolean(businessPackage)}
                >
                    <BusinessPackageForm
                        businessPackage={businessPackage}
                        updateBusinessPackage={updateBusinessPackage}
                    />
                </Modal>
                <DownloadReportModal
                    hideModal={() => {
                        this.setState({ visibleDownloadReportModal: false });
                    }}
                    visible={visibleDownloadReportModal}
                />
            </Catcher>
        );
    }
}
