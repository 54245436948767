import { CheckCircleFilled, CloseSquareFilled, WarningFilled } from '@ant-design/icons';
import { Button, Radio, Select, Space, Table, Tooltip, notification } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import DebouncedInput from 'pages/AccountPlanPage/components/DebouncedInput';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, filterByPartGeneral } from 'utils';
import Styles from './styles.m.css';

const fontOptions = {
    1: 'bold',
    2: 'bold',
    4: 'italic'
};

const allRolesAcees = [
    {
        name: <FormattedMessage id='packages.role.NA' />,
        value: 'NA',
        color: '#eb8c86',
        colorValue: 'red'
    },
    {
        name: <FormattedMessage id='packages.role.AD' />,
        value: 'AD',
        color: '#eb8c86',
        colorValue: 'red'
    },
    {
        name: <FormattedMessage id='packages.role.RO' />,
        value: 'RO',
        color: '#f0a871',
        colorValue: 'orange'
    },
    {
        name: <FormattedMessage id='packages.role.RA' />,
        value: 'RA',
        color: '#f0a871',
        colorValue: 'orange'
    },
    {
        name: <FormattedMessage id='packages.role.ROWO' />,
        value: 'ROWO',
        color: '#83c468',
        colorValue: 'green'
    },
    {
        name: <FormattedMessage id='packages.role.RAWO' />,
        value: 'RAWO',
        color: '#83c468',
        colorValue: 'green'
    },
    {
        name: <FormattedMessage id='packages.role.WA' />,
        value: 'WA',
        color: '#83c468',
        colorValue: 'green'
    }
];

const { Option } = Select;

const radioOptions = Array.from(Array(4)).map((_, index) => index + 1);

const BussinessPackagesTab = ({ intl }) => {
    const [query, setQuery] = useState({
        dateFrom: dayjs().startOf('month').toISOString(),
        dateTo: dayjs().toISOString()
    });
    const [businessPackages, setBusinessPackages] = useState([]);
    const [flattenBusinessPackages, setFlattenBusinessPackages] = useState([]);
    const [tablePagination, setTablePagination] = useState({ pageSize: 2 });
    const [foundIds, setFoundIds] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [visibleLevel, setVisibleLevel] = useState(3);
    const [filter, setFilter] = useState('');
    const [allPackages, setAllPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState();
    const [packageName, setPackageName] = useState();

    const onFlatten = rights => {
        const flattenTree = [];
        const flattenStoreGroups = data => {
            for (let item = 0; item < get(data, 'length'); item++) {
                const node = data[item];

                flattenTree.push({
                    id: node.id,
                    name: node.name,
                    level: node.level,
                    parentId: node.parentId,
                    currentPackage: node.package ? node.package.name : undefined
                });
                if (node.childs) {
                    flattenStoreGroups(node.childs);
                }
            }
        };
        flattenStoreGroups(rights);

        setFlattenBusinessPackages(flattenTree);
        setExpandedRowKeys(flattenTree.map(({ id }) => id));

        return flattenTree;
    };

    const fetchBusinessPackages = useMemo(
        () => async query => {
            const res = await fetchAPI('GET', '/v2/roles_and_accesses/business_packages', query, null, {
                handleErrorInternally: true
            });

            setBusinessPackages(res.list);

            onFlatten(res.list);
        },
        []
    );

    const findIcon = useCallback(isInclude => {
        const icon =
            get(isInclude, 'access', 'NA') == 'AD' || get(isInclude, 'access', 'NA') == 'NA' ? (
                <CloseSquareFilled className={Styles.optionsDangerIconStyle} />
            ) : get(isInclude, 'access', 'NA') == 'RO' || get(isInclude, 'access', null) == 'RA' ? (
                <WarningFilled className={Styles.optionsWarningIconStyle} />
            ) : get(isInclude, 'access', 'NA') == 'ROWO' ||
              get(isInclude, 'access', 'NA') == 'RAWO' ||
              get(isInclude, 'access', 'NA') == 'WA' ? (
                <CheckCircleFilled className={Styles.optionsSuccessIconStyle} />
            ) : undefined;

        return icon;
    }, []);

    const handleTablePackage = useMemo(
        () => async (access, treeIds) => {
            await fetchAPI(
                'PUT',
                '/v2/roles_and_accesses/business_package/access',
                null,
                {
                    access,
                    treeIds
                },
                {
                    handleErrorInternally: true
                }
            );
            await fetchBusinessPackages();
        },
        []
    );

    const fetchAllPackages = useMemo(
        () => async () => {
            const res = await fetchAPI('GET', '/v2/roles_and_accesses/standard_packages/simple_list', null, null, {
                handleErrorInternally: true
            });

            setAllPackages(res);
        },
        []
    );

    const handleLevelVisibility = useCallback(
        e => {
            setExpandedRowKeys(
                flattenBusinessPackages.filter(({ level }) => level < e.target.value).map(({ id }) => id)
            );
            setVisibleLevel(Number(e.target.value));
        },
        [flattenBusinessPackages]
    );

    const debouncedFilterChange = useMemo(
        () => filter => {
            const foundMap = {};
            const found = flattenBusinessPackages.filter(
                entry => filterByPartGeneral(filter, entry.name) || filterByPartGeneral(filter, entry.id)
            );

            const findParents = entry => {
                if (!foundMap[entry.id]) {
                    foundMap[entry.id] = entry;
                    const parent = flattenBusinessPackages.find(genEntry => genEntry.id === entry.parentId);
                    if (parent) findParents(parent);
                }
            };

            found.forEach(foundEntry => {
                findParents(foundEntry);
            });

            const res = Object.values(foundMap);

            if (filter.length > 0) {
                const maxLevel = Math.max(...found.map(({ level }) => level));
                setExpandedRowKeys(res.filter(({ level }) => level < maxLevel).map(({ id }) => id));
                setFoundIds(found.map(({ id }) => id));
            } else {
                setFoundIds([]);
                setExpandedRowKeys(
                    flattenBusinessPackages.filter(({ level }) => level < visibleLevel).map(({ id }) => id)
                );
            }

            const rows = range => {
                const diff = range[1] - range[0];
                if (diff === 0) return `${intl.formatMessage({ id: 'storage_journal.row_amount' })}: 0`;

                return `${intl.formatMessage({ id: 'storage_journal.row_amount' })}: ${diff + 1}`;
            };

            if (found.length < 30) {
                setTablePagination({ showTotal: (total, range) => rows(range), pageSize: 9999 });
            } else {
                visibleLevel === 1
                    ? setTablePagination({
                          pageSize: 10,
                          showTotal: (total, range) => rows(range)
                      })
                    : setTablePagination({ pageSize: 2, showTotal: (total, range) => rows(range) });
            }
        },
        [flattenBusinessPackages, intl, visibleLevel]
    );

    const renderNestedTable = useCallback(
        roots => {
            const columns = [
                {
                    title: <FormattedMessage id='packages.id_access' />,
                    key: 'id',
                    width: '180px',
                    render: record => {
                        const slicedId =
                            record.id && record.level == 1
                                ? String(record.id).slice(0, 2)
                                : record.id && record.level == 2
                                ? String(record.id).slice(0, 4)
                                : record.id && record.level == 3
                                ? String(record.id).slice(0, 6)
                                : String(record.id).slice(0, 8);

                        return record.id ? slicedId : record.id;
                    }
                },
                {
                    title: <FormattedMessage id='description' />,
                    key: 'name',
                    render: record => {
                        const space =
                            record.level === 2 ? '8px' : record.level === 3 ? '24px' : record.level === 4 ? '38px' : 0;

                        return record.name ? (
                            <div
                                style={{
                                    marginLeft: space
                                }}
                            >
                                {record.name}
                            </div>
                        ) : (
                            <FormattedMessage id='long_dash' />
                        );
                    }
                },
                {
                    title: () => (
                        <div>
                            <div
                                style={{
                                    marginBottom: 4
                                }}
                            >
                                <FormattedMessage id='packages.default_package' />
                            </div>
                            <div
                                style={{
                                    opacity: '0.6'
                                }}
                            >
                                {get(flattenBusinessPackages, '[0].currentPackage', '')}
                            </div>
                        </div>
                    ),
                    key: 'default_package',
                    align: 'center',
                    render: row => {
                        const isInclude = row.package ? row.package : undefined;

                        const icon = findIcon(isInclude);

                        return (
                            <Tooltip
                                title={
                                    isInclude ? (
                                        <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                    ) : (
                                        'NA'
                                    )
                                }
                            >
                                <div>
                                    {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
                                </div>
                            </Tooltip>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='packages.business_package' />,
                    key: 'business_package',
                    align: 'center',
                    render: row => {
                        const isInclude = row.businessPackage ? row.businessPackage : undefined;

                        return (
                            <Tooltip title={isInclude ? <FormattedMessage id={`packages.role.${isInclude}`} /> : 'NA'}>
                                <Select
                                    dropdownStyle={{
                                        width: 275
                                    }}
                                    onChange={value => {
                                        handleTablePackage(value, [row.id]);
                                    }}
                                    optionFilterProp='children'
                                    placeholder={intl.formatMessage({
                                        id: 'packages.access'
                                    })}
                                    style={{
                                        width: '100%'
                                    }}
                                    value={isInclude ? isInclude.access : 'NA'}
                                >
                                    {allRolesAcees.map(elem => (
                                        <Option key={elem.value} value={elem.value}>
                                            {elem.colorValue == 'red' ? (
                                                <CloseSquareFilled className={Styles.optionsDangerIconStyle} />
                                            ) : elem.colorValue == 'orange' ? (
                                                <WarningFilled className={Styles.optionsWarningIconStyle} />
                                            ) : (
                                                <CheckCircleFilled className={Styles.optionsSuccessIconStyle} />
                                            )}{' '}
                                            {elem.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Tooltip>
                        );
                    }
                }
            ];
            const tableProps = {
                dataSource: roots,
                columns,
                expandable: {
                    childrenColumnName: 'childs',
                    expandedRowKeys,
                    onExpandedRowsChange: expandedRowKeys => setExpandedRowKeys(expandedRowKeys)
                },
                onRow: ({ id, level }) => {
                    const classNames = `${Styles[fontOptions[level]] || ''} ${
                        foundIds.includes(id) ? Styles.foundEntry : ''
                    }`.trim();
                    if (classNames.length) {
                        return {
                            className: classNames
                        };
                    }
                },
                rowKey: 'id'
            };

            return (
                <Table
                    style={{
                        overflow: 'scroll'
                    }}
                    {...tableProps}
                    bordered
                    pagination={tablePagination}
                    rowKey='id'
                />
            );
        },
        [expandedRowKeys, tablePagination, flattenBusinessPackages, findIcon, intl, handleTablePackage, foundIds]
    );

    useEffect(() => {
        fetchBusinessPackages();
    }, [fetchBusinessPackages, fetchAllPackages]);

    useEffect(() => {
        fetchAllPackages();
    }, [fetchAllPackages]);

    useEffect(() => {
        debouncedFilterChange(filter);
    }, [flattenBusinessPackages, filter, debouncedFilterChange]);

    console.log(flattenBusinessPackages, 'flattenBusinessPackages');

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <Space>
                <Radio.Group optionType='button' value={visibleLevel}>
                    {radioOptions.map(index => (
                        <Radio key={index} onClick={handleLevelVisibility} value={index}>
                            {index}
                        </Radio>
                    ))}
                </Radio.Group>
                <DebouncedInput
                    allowClear
                    placeholder={intl.formatMessage({ id: 'packages.search_by' })}
                    setState={setFilter}
                    width={300}
                />
                <Button
                    onClick={async () => {
                        const res = await fetchAPI(
                            'GET',
                            '/v2/roles_and_accesses/business_packages',
                            { copyAccesses: true },
                            null,
                            {
                                handleErrorInternally: true
                            }
                        );

                        setBusinessPackages(res.list);

                        notification.success({
                            message: intl.formatMessage({ id: 'barcode.success' })
                        });
                    }}
                    type='primary'
                >
                    <FormattedMessage id='packages.copy_access' />
                </Button>
            </Space>

            {renderNestedTable(businessPackages)}
        </div>
    );
};

export default injectIntl(BussinessPackagesTab);
