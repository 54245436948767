import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
// import { hot } from 'react-hot-loader/root';
import ConfigProviderExtended from 'components/ConfigProviderExtended';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedIntlProvider as IntlProvider } from 'utils';
import Routes from './routes/Main';
import history from './store/history';
import store, { persistor } from './store/store';

// const loadScript = src => {
//     const script = document.createElement('script');
//     script.type = 'text/javascript';
//     document.getElementsByTagName('head')[0].appendChild(script);
//     script.src = src;
// };
// const lang = 'ua';
// loadScript(
//     `https://maps.googleapis.com/maps/api/js?key=AIzaSyB44HA5xmBlrPN6PwKGxEZoU95aZAy-UXc&v=3.exp&language=${lang}&libraries=drawing,places`
// );

const App = () => {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <IntlProvider>
                        <ConfigProviderExtended>
                            <ConnectedRouter history={history}>
                                <Routes />
                            </ConnectedRouter>
                        </ConfigProviderExtended>
                    </IntlProvider>
                </PersistGate>
            </Provider>
        </React.StrictMode>
    );
};

export default App;
