import { Modal, Radio, Space } from 'antd';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

// import Styles from './styles.m.css';

const printOptions = [
    { label: 'order_form_table.diagnostic.print.print_all', value: 'all' },
    { label: 'order_form_table.diagnostic.print.print_done', value: 'onlyDone' },
    { label: 'order_form_table.diagnostic.print.print_problems', value: 'onlyProblem' }
];

export const DiagnosticPrintModal = ({ open, handleClose, orderId, isMobile, token }) => {
    const [loading, setLoading] = useState(false);

    const [radioValue, setRadioValue] = useState(printOptions[0].value);

    const handleCancel = () => {
        setLoading(false);
        handleClose();
    };

    const onRadioChange = e => {
        setRadioValue(e.target.value);
    };

    const okOk = async () => {
        const url = `/orders/reports/diagnosticsResultReport/${orderId}`;
        try {
            setLoading(true);
            const response = await fetchAPI(
                'GET',
                url,
                {
                    diagnosticV2type: radioValue
                },
                null,
                {
                    rawResponse: true,
                    handleErrorInternally: true,
                    headers: {
                        Authorization: token
                    }
                }
            );

            const reportFile = await response.blob();

            const contentDispositionHeader = response.headers.get('content-disposition');
            const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
            await saveAs(reportFile, fileName);
            setLoading(false);
            handleCancel();
        } catch (error) {
            setLoading(false);
            console.error('ERROR:', error);
        }
    };

    return (
        <Modal
            destroyOnClose
            okButtonProps={{ loading }}
            onCancel={handleCancel}
            onOk={okOk}
            open={open}
            // style={{ minWidth: 800 }}
            title={<FormattedMessage id='print' />}
            width={isMobile ? '100%' : '35%'}
        >
            <Radio.Group onChange={onRadioChange} value={radioValue}>
                <Space direction='vertical'>
                    {printOptions.map(({ label, value }) => (
                        <Radio value={value}>
                            <FormattedMessage id={label} />
                        </Radio>
                    ))}
                </Space>
            </Radio.Group>
        </Modal>
    );
};

export default injectIntl(DiagnosticPrintModal);
