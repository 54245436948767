import { Modal } from 'antd';
import { CancelReasonForm } from 'forms';
import { get } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export default class CancelResonModal extends Component {
    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible && !get(this.props.orderComments, 'length')) {
            this.props.fetchComments();
        }
    }

    render() {
        const {
            visible,
            handleCancelReasonModalSubmit,
            resetModal,
            orderComments,
            handleCardClickTransfer,
            id,
            transferOrder,
            handleCancel,
            handleCancelReasonsModal
        } = this.props;

        return (
            <Modal
                cancelText={<FormattedMessage id='cancel' />}
                footer={null}
                maskClosable={false}
                onCancel={() => handleCancel()}
                open={visible}
            >
                <CancelReasonForm
                    handleCancel={handleCancel}
                    handleCancelReasonModalSubmit={handleCancelReasonModalSubmit}
                    handleCardClickTransfer={handleCardClickTransfer}
                    id={id}
                    orderComments={orderComments}
                    transferOrder={transferOrder}
                    visible={visible}
                />
            </Modal>
        );
    }
}
