/* eslint-disable no-undefined */
/* eslint-disable complexity */
import { notification } from 'antd';
import { setErrorMessage } from 'core/errorMessage/duck';
import { addError, emitError } from 'core/ui/duck';
import _ from 'lodash';
import { all, call, fork, put, select, take } from 'redux-saga/effects';
import book from 'routes/book';
import history from 'store/history';
import { fetchAPI, toDuckError } from 'utils';
import {
    CREATE_VEHICLE,
    fetchVehicleColorsSuccess,
    fetchVehicleMakesSuccess,
    fetchVehicleModelsSuccess,
    fetchVehicleModificationsSuccess,
    fetchVehicleSuccess,
    fetchVehicleYearsSuccess,
    FETCH_ALL_VEHICLE_DATA,
    FETCH_VEHICLE,
    FETCH_VEHICLE_COLORS,
    FETCH_VEHICLE_DATA_BY_NUMBER,
    FETCH_VEHICLE_DATA_BY_VIN,
    FETCH_VEHICLE_MAKES,
    FETCH_VEHICLE_MODELS,
    FETCH_VEHICLE_MODIFICATIONS,
    FETCH_VEHICLE_YEARS,
    selectFields,
    setComment,
    setFetchingAllVehicleData,
    setManagerName,
    setManagerPhone,
    setModelDropdownState,
    setRegistrationName,
    setSelectType,
    setVehicle,
    setVehicleColor,
    setVehicleColorPattern,
    setVehicleEngineName,
    setVehicleMakeId,
    setVehicleMakeName,
    setVehicleModelId,
    setVehicleModelName,
    setVehicleVin,
    setVehicleYear,
    SET_VEHICLE_INIT_VALUES,
    UPDATE_VEHICLE
} from './duck';

// ------------------------- Workers ----------------------

/**
 * Used to initialized vehicle values. Automatically fetches all required data.
 * @param [ initValues.number ]
 * @param [ initValues.vin ]
 * @param [ initValues.year ]
 * @param [ initValues.makeId ]
 * @param [ initValues.modelId ]
 * @param [ initValues.modificationId ]
 */
function* setAllVehicleDataWorker(payload) {
    const {
        number,
        vin,
        year,
        makeId,
        modelId,
        modificationId,
        colorId,
        vehicleTypeId,
        wheelRadius,
        managerName,
        managerPhone,
        comment,
        type,
        bodyId
    } = payload;

    yield put(setVehicle(payload));

    // yield put(setVehicleNumber({number}));
    // yield put(setVehicleVin({vin}));
    // yield put(setVehicleYear({year}));
    // yield put(setVehicleMakeId({makeId}));
    // yield put(setVehicleModelId({modelId}));
    // yield put(setVehicleModificationId({modificationId}));
    // yield put(setVehicleColor({ colorId }));

    // Fetch data for each field if needed
    const { years } = yield call(fetchAPI, 'GET', 'vehicles_info');

    yield put(fetchVehicleYearsSuccess({ years }));

    if (year) {
        const { makes } = yield call(fetchAPI, 'GET', 'vehicles_info', { year });
        yield put(fetchVehicleMakesSuccess({ makes }));
    }

    if (year && makeId) {
        const { models } = yield call(fetchAPI, 'GET', 'vehicles_info', { year, makeId });
        yield put(fetchVehicleModelsSuccess({ models }));
    }

    if (year && makeId && modelId) {
        const { modifications } = yield call(fetchAPI, 'GET', 'vehicles_info', {
            year,
            makeId,
            modelId
        });
        yield put(fetchVehicleModificationsSuccess({ modifications }));
    }
}

// ------------------- Watchers -----------------------

export function* fetchVehicleSaga() {
    while (true) {
        const {
            payload: { vehicleId }
        } = yield take(FETCH_VEHICLE);
        const vehicle = yield call(fetchAPI, 'GET', `clients/vehicles/${vehicleId}`);
        yield put(fetchVehicleSuccess({ vehicle }));
    }
}

// export function* fetchVehicleTypeIdSaga() {
//     while (true) {
//         yield take(FETCH_VEHICLE_TYPE_ID);
//         const types = yield call(fetchAPI, 'GET', 'vehicles_info');
//         yield put(fetchVehicleTypeIdSuccess({ types }));
//     }
// }

/**
 * This saga is used to initialize all vehicle data and setup all fields.
 * For example: it fetches vehicle, takes its values and puts them intu "fields" object vie setters,
 * then it fetches all data for vehicle of that type, yer, model etc.(models, makes, modifications)
 */
export function* fetchAllVehicleDataSaga() {
    while (true) {
        const {
            payload: { vehicleId }
        } = yield take(FETCH_ALL_VEHICLE_DATA);
        yield put(setFetchingAllVehicleData(true));

        const vehicle = yield call(fetchAPI, 'GET', `clients/vehicles/${vehicleId}`);
        yield put(fetchVehicleSuccess({ vehicle }));

        const initValues = {
            number: vehicle.vehicleNumber,
            vin: vehicle.vehicleVin,
            year: vehicle.year,
            makeId: vehicle.makeId,
            modelId: vehicle.vehicleModelId,
            modificationId: vehicle.vehicleModificationId,
            colorId: vehicle.colorId,
            vehicleTypeId: vehicle.vehicleTypeId,
            wheelRadius: vehicle.wheelRadius,
            manager: vehicle.managerName,
            phone: vehicle.managerPhone,
            com: vehicle.comment,
            registrationName: vehicle.registrationName || `${vehicle.make}, ${vehicle.model}, ${vehicle.modification}`,
            bodyId: vehicle.bodyId,
            fuelId: vehicle.fuelId,
            bodyType: vehicle.bodyType
        };

        yield fork(setAllVehicleDataWorker, initValues);

        yield put(setFetchingAllVehicleData(false));
    }
}

/**
 * Initialize vehicle by provided fields without fetching vehicle by id
 */
export function* initializeVehicleDataSaga() {
    while (true) {
        const { payload: initValues } = yield take(SET_VEHICLE_INIT_VALUES);
        yield put(setFetchingAllVehicleData(true));

        yield fork(setAllVehicleDataWorker, initValues);

        yield put(setFetchingAllVehicleData(false));
    }
}

/**
 * This saga is used to fetch data about vehicle by vin
 * it automatically set up fields and fetches data model, modifications, years, etc
 */
export function* fetchVehicleDataByVinSaga() {
    while (true) {
        try {
            yield take(FETCH_VEHICLE_DATA_BY_VIN);

            yield put(setVehicleMakeName({ makeName: undefined }));
            yield put(setVehicleModelName({ modelName: undefined }));

            let { vin } = yield select(selectFields);

            vin = String(vin).trim();

            const vehicleListAttributes = yield call(
                fetchAPI,
                'GET',
                'vin/get_list_vehicle_attributes',
                { vin },
                null,
                { handleErrorInternally: true }
            );

            if (_.isEmpty(vehicleListAttributes)) {
                // shows message car does not exist
                notification.error({
                    message: 'Не вдалося визначити автомобіль по заданому VIN'
                });
            }

            const { brand, name: carModel, manufacturedYear, engine, engineInfo, model } = vehicleListAttributes;

            const brandName = String(brand);
            const carModelName = String(carModel);

            console.log('S: ', carModelName);

            if (manufacturedYear) {
                yield put(setVehicleYear({ year: manufacturedYear }));

                const { years } = yield call(fetchAPI, 'GET', 'vehicles_info');
                yield put(fetchVehicleYearsSuccess({ years }));

                if (brand && String(brand).length > 0) {
                    const { makes } = yield call(fetchAPI, 'GET', 'vehicles_info', {
                        year: manufacturedYear
                    });
                    yield put(fetchVehicleMakesSuccess({ makes }));

                    const filteredMakes = _.filter(makes, make => {
                        const makeName = String(make.name).toLowerCase();

                        return makeName.includes(brandName.toLowerCase()) || brandName.includes(makeName.toLowerCase());
                    });

                    const firstMakeId = _.get(filteredMakes, '[0].id');
                    const makeName = _.get(filteredMakes, '[0].name');

                    if (firstMakeId) {
                        yield put(setVehicleMakeId({ makeId: firstMakeId }));

                        if (String(carModel).length > 0) {
                            const { models } = yield call(fetchAPI, 'GET', 'vehicles_info', {
                                year: manufacturedYear,
                                makeId: firstMakeId
                            });
                            yield put(fetchVehicleModelsSuccess({ models }));

                            const filteredModels = _.filter(models, model => {
                                const modelName = String(model.name).toLowerCase();

                                return (
                                    modelName.includes(carModelName.toLowerCase()) ||
                                    carModelName.includes(modelName.toLowerCase())
                                );
                            });

                            const firstModelId = _.get(filteredModels, '[0].id');
                            const modelCar = _.get(filteredModels, '[0].aliasModel');

                            if (firstModelId) {
                                if (filteredModels.length == 1) {
                                    yield put(setVehicleModelId({ modelId: firstModelId }));
                                    yield put(setSelectType({ selectType: 'SINGLE' }));
                                    yield put(setModelDropdownState(false));
                                } else {
                                    yield put(setVehicleModelId({ modelId: firstModelId }));
                                    yield put(setSelectType({ selectType: 'MULTIPLE' }));
                                    yield put(setModelDropdownState(true));
                                }

                                const { modifications } = yield call(fetchAPI, 'GET', 'vehicles_info', {
                                    year: manufacturedYear,
                                    makeId: firstMakeId,
                                    modelId: firstModelId
                                });

                                yield put(fetchVehicleModificationsSuccess({ modifications }));
                            } else {
                                yield put(setSelectType({ selectType: 'NONE' }));
                                yield put(setModelDropdownState(true));
                            }
                            yield put(
                                setRegistrationName({
                                    registrationName: `${makeName || ''}${makeName ? ',' : ''} ${modelCar || ''}${
                                        modelCar ? ',' : ''
                                    } ${manufacturedYear || ''}`
                                })
                            );
                        }
                    }
                }
            } else {
                yield put(setVehicleYear({ year: undefined }));
            }

            const engineName = `${engine} ${engineInfo}`;

            const regName = `${brandName} ${carModelName}, ${manufacturedYear}, ${engine}, ${engineInfo}`;

            yield put(setVehicleMakeName({ makeName: brandName }));
            yield put(setVehicleModelName({ modelName: carModelName }));
            yield put(setVehicleEngineName({ engineInfo: engineName }));
            // yield put(setRegistrationName({ registrationName: regName }));
        } catch (err) {
            yield put(setErrorMessage(err));
        }
    }
}

/**
 * This saga is used to fetch data about vehicle by registration number
 * it automatically set up fields and fetches vin, model modifications, years, etc
 */
export function* fetchVehicleDataByNumberSaga() {
    while (true) {
        try {
            yield take(FETCH_VEHICLE_DATA_BY_NUMBER);

            yield put(setVehicleMakeName({ makeName: undefined }));
            yield put(setVehicleModelName({ modelName: undefined }));

            let { number } = yield select(selectFields);

            number = String(number).replace(/\s/g, '');

            const vehicleListAttributes = yield call(fetchAPI, 'GET', 'state_vehicle', { number }, null, {
                handleErrorInternally: true
            });

            if (!vehicleListAttributes) {
                return;
            }

            const result = yield call(fetchAPI, 'GET', 'state_vehicle_colors', null, null, {
                handleErrorInternally: true
            });

            const {
                makeName: brand,
                modelName: carModel,
                year: manufacturedYear,
                vin,
                colorId,
                managerName,
                managerPhone,
                comment
            } = vehicleListAttributes;

            const colorPattern = result.find(elem => elem.colorId == colorId);

            // set vin code if exists
            if (vin) {
                yield put(setVehicleVin({ vin }));
            }
            if (colorId) {
                yield put(setVehicleColor({ colorId }));
                yield put(setVehicleColorPattern({ colorPattern: _.get(colorPattern, 'colorCode') }));
            }
            if (managerName) {
                yield put(setManagerName({ managerName }));
            }
            if (managerPhone) {
                yield put(setManagerPhone({ managerPhone }));
            }
            if (comment) {
                yield put(setComment({ comment }));
            }

            const brandName = String(brand);
            const carModelName = String(carModel);

            console.log('S: ', brand, carModel, manufacturedYear);

            if (manufacturedYear) {
                yield put(setVehicleYear({ year: manufacturedYear }));

                const { years } = yield call(fetchAPI, 'GET', 'vehicles_info');
                yield put(fetchVehicleYearsSuccess({ years }));

                if (brand && String(brand).length > 0) {
                    const { makes } = yield call(fetchAPI, 'GET', 'vehicles_info', {
                        year: manufacturedYear
                    });
                    yield put(fetchVehicleMakesSuccess({ makes }));

                    const filteredMakes = _.filter(makes, make => {
                        const makeName = String(make.name).toLowerCase();

                        return makeName.includes(brandName.toLowerCase()) || brandName.includes(makeName.toLowerCase());
                    });

                    const firstMakeId = _.get(filteredMakes, '[0].id');
                    const makeName = _.get(filteredMakes, '[0].name');

                    if (firstMakeId) {
                        yield put(setVehicleMakeId({ makeId: firstMakeId }));

                        if (String(carModel).length > 0) {
                            const { models } = yield call(fetchAPI, 'GET', 'vehicles_info', {
                                year: manufacturedYear,
                                makeId: firstMakeId
                            });
                            yield put(fetchVehicleModelsSuccess({ models }));

                            const filteredModels = _.filter(models, model => {
                                const modelName = String(model.name).toLowerCase();

                                return (
                                    modelName.includes(carModelName.toLowerCase()) ||
                                    carModelName.includes(modelName.toLowerCase())
                                );
                            });

                            const firstModelId = _.get(filteredModels, '[0].id');
                            const modelCar = _.get(filteredModels, '[0].aliasModel');

                            if (firstModelId) {
                                if (filteredModels.length == 1) {
                                    yield put(setVehicleModelId({ modelId: firstModelId }));
                                    yield put(setSelectType({ selectType: 'SINGLE' }));
                                    yield put(setModelDropdownState(false));
                                } else {
                                    yield put(setVehicleModelId({ modelId: firstModelId }));
                                    yield put(setSelectType({ selectType: 'MULTIPLE' }));
                                    yield put(setModelDropdownState(true));
                                }

                                const { modifications } = yield call(fetchAPI, 'GET', 'vehicles_info', {
                                    year: manufacturedYear,
                                    makeId: firstMakeId,
                                    modelId: firstModelId
                                });

                                yield put(fetchVehicleModificationsSuccess({ modifications }));
                            } else {
                                yield put(setSelectType({ selectType: 'NONE' }));
                                yield put(setModelDropdownState(true));
                            }
                            yield put(
                                setRegistrationName({
                                    registrationName: `${makeName || ''}${makeName ? ',' : ''} ${modelCar || ''}${
                                        modelCar ? ',' : ''
                                    } ${manufacturedYear || ''}`
                                })
                            );
                        }
                    }
                }
            } else {
                yield put(setVehicleYear({ year: undefined }));
            }

            const regName = `${brandName} ${carModelName}, ${manufacturedYear}`;

            yield put(setVehicleMakeName({ makeName: brandName }));
            yield put(setVehicleModelName({ modelName: carModelName }));
            // yield put(setRegistrationName({ registrationName: regName }));
        } catch (err) {
            const duckError = toDuckError(err, 'vehicleModal');
            yield put(addError(duckError));

            // yield put(setErrorMessage(err));
        }
    }
}

export function* fetchVehiclesYearsSaga() {
    while (true) {
        yield take(FETCH_VEHICLE_YEARS);

        const { years, type } = yield call(fetchAPI, 'GET', 'vehicles_info', null, null, {
            handleErrorInternally: true
        });
        yield put(fetchVehicleYearsSuccess({ type, years }));
    }
}

export function* fetchVehiclesMakesSaga() {
    while (true) {
        yield take(FETCH_VEHICLE_MAKES);

        const { year, type, popular } = yield select(selectFields);
        const { makes } = yield call(fetchAPI, 'GET', 'vehicles_info', { type, year, popular }, null, {
            handleErrorInternally: true
        });
        yield put(fetchVehicleMakesSuccess({ makes }));
    }
}

export function* fetchVehiclesModelsSaga() {
    while (true) {
        yield take(FETCH_VEHICLE_MODELS);

        const { year, makeId, type } = yield select(selectFields);
        const { models } = yield call(fetchAPI, 'GET', 'vehicles_info', { type, year, makeId });
        yield put(fetchVehicleModelsSuccess({ models }));
    }
}

export function* fetchVehiclesModificationsSaga() {
    while (true) {
        yield take(FETCH_VEHICLE_MODIFICATIONS);

        const { year, makeId, modelId, type } = yield select(selectFields);
        const { modifications } = yield call(fetchAPI, 'GET', 'vehicles_info', {
            type,
            year,
            makeId,
            modelId
        });
        yield put(fetchVehicleModificationsSuccess({ modifications }));
    }
}

export function* fetchVehiclesColorsSaga() {
    while (true) {
        yield take(FETCH_VEHICLE_COLORS);

        const result = yield call(fetchAPI, 'GET', 'state_vehicle_colors');

        yield put(fetchVehicleColorsSuccess({ colors: result }));
    }
}

export function* createVehicleSaga() {
    while (true) {
        try {
            const {
                payload: { autoRedirect }
            } = yield take(CREATE_VEHICLE);

            const {
                clientId,
                modificationId: vehicleModificationId,
                number: vehicleNumber,
                vin: vehicleVin,
                modelId: vehicleModelId,
                year: vehicleYear,
                colorId,
                vehicleTypeId,
                wheelRadius,
                manager: managerName,
                phone: managerPhone,
                com: comment,
                registrationName,
                type,
                bodyId
            } = yield select(selectFields);

            const payload = {
                vehicleModelId,
                vehicleModificationId,
                vehicleVin,
                vehicleNumber,
                vehicleYear,
                colorId,
                vehicleTypeId,
                wheelRadius,
                managerName,
                managerPhone,
                comment,
                registrationName,
                type,
                bodyId
            };

            // Create vehicle for user if we have its id else just create a vehicle
            const { inserted } = yield call(
                fetchAPI,
                'POST',
                clientId ? `clients/${clientId}/vehicles` : 'clients/add_vehicle',
                null,
                payload
            );

            if (autoRedirect && inserted) {
                // Redirect to vehicle page
                history.push({
                    pathname: `${book.vehicle}/${inserted}`
                });
            }
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* updateVehicleSaga() {
    while (true) {
        const {
            payload: { vehicleId }
        } = yield take(UPDATE_VEHICLE);

        const {
            clientId,
            number,
            vin,
            year,
            makeId, // This is not needed as it is fetched from modificationId by default
            modelId,
            modificationId,
            colorId,
            vehicleTypeId,
            wheelRadius,
            manager,
            phone,
            com,
            registrationName,
            type,
            bodyId
        } = yield select(selectFields);

        const payload = {
            clientId,
            vehicleNumber: number,
            vehicleVin: vin,
            vehicleYear: year,
            vehicleModelId: modelId,
            vehicleModificationId: modificationId,
            colorId,
            vehicleTypeId,
            wheelRadius,
            managerName: manager,
            managerPhone: phone,
            comment: com,
            registrationName: registrationName || null,
            type,
            bodyId
        };

        try {
            yield call(fetchAPI, 'PUT', `clients/vehicles/${vehicleId}`, null, payload, {
                handleErrorInternally: true
            });
        } catch (err) {
            notification.error({
                message: 'Error'
            });
        }
    }
}

export function* saga() {
    yield all([
        call(fetchVehicleSaga),
        call(fetchAllVehicleDataSaga),
        call(initializeVehicleDataSaga),
        call(fetchVehicleDataByVinSaga),
        call(fetchVehicleDataByNumberSaga),
        call(fetchVehiclesColorsSaga),
        call(fetchVehiclesYearsSaga),
        call(fetchVehiclesMakesSaga),
        call(fetchVehiclesModelsSaga),
        call(fetchVehiclesModificationsSaga),
        call(createVehicleSaga),
        call(updateVehicleSaga)
    ]);
}
