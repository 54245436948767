import { emitError } from 'core/ui/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import book from 'routes/book';
import history from 'store/history';
import { fetchAPI } from 'utils';
import {
    CLOSE_TASK,
    DELEGATE_TASK,
    fetchManagersSuccess,
    fetchTask,
    fetchTaskSuccess,
    FETCH_MANAGERS,
    FETCH_TASK,
    POSTPONED_TASK,
    selectPerformer,
    selectPostponedTaskFields,
    selectTask,
    setFetchingTask,
    UPDATE_TASK
} from './duck';

export function* fetchTaskSaga() {
    while (true) {
        try {
            const { payload } = yield take(FETCH_TASK);

            yield put(setFetchingTask(true));

            yield nprogress.start();

            const data = yield call(fetchAPI, 'GET', `employee_tasks/${payload}`);

            yield put(fetchTaskSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setFetchingTask(false));
        }
    }
}

export function* fetchManagersSaga() {
    while (true) {
        try {
            yield take(FETCH_MANAGERS);

            yield nprogress.start();

            const data = yield call(fetchAPI, 'GET', 'managers/search', { notFired: true });

            yield put(fetchManagersSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* fetchDelegateTaskSaga() {
    while (true) {
        try {
            yield take(DELEGATE_TASK);

            const task = yield select(selectTask);
            const performerId = yield select(selectPerformer);

            yield nprogress.start();

            const response = yield call(
                fetchAPI,
                'POST',
                'employee_tasks_delegate',
                undefined,
                { taskId: task.id, performerId },
                {
                    handleErrorInternally: true
                }
            );

            if (_.get(response, '[0]')) {
                history.push(`${book.myTasksPage}/${response[0]}`);
            }
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* fetchPostponedTaskSaga() {
    while (true) {
        try {
            yield take(POSTPONED_TASK);

            const task = yield select(selectTask);
            const fields = yield select(selectPostponedTaskFields);

            const scheduledDatetime = dayjs(
                `${dayjs(fields.scheduledDate).format('YYYY-MM-DD')} ${dayjs(
                    fields.scheduledTime
                ).format('HH:mm')}`
            ).format();

            yield nprogress.start();

            const response = yield call(
                fetchAPI,
                'POST',
                'employee_tasks_postponed',
                undefined,
                {
                    taskId: task.id,
                    scheduledDatetime
                },
                { handleErrorInternally: true }
            );

            if (_.get(response, '[0]')) {
                history.push(`${book.myTasksPage}/${response[0]}`);
            }
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* closeTaskSaga() {
    while (true) {
        try {
            yield take(CLOSE_TASK);

            const task = yield select(selectTask);
            yield nprogress.start();

            const response = yield call(
                fetchAPI,
                'POST',
                'employee_tasks_close',
                undefined,
                {
                    taskId: task.id
                },
                { handleErrorInternally: true }
            );

            yield put(fetchTask(task.id));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* updateTaskSaga() {
    while (true) {
        try {
            const {
                payload: { id, data }
            } = yield take(UPDATE_TASK);

            yield nprogress.start();

            yield call(fetchAPI, 'PUT', `employee_tasks/${id}`, undefined, data, {
                handleErrorInternally: true
            });

            yield put(fetchTask(id));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* saga() {
    yield all([
        call(fetchTaskSaga),
        call(fetchManagersSaga),
        call(fetchDelegateTaskSaga),
        call(fetchPostponedTaskSaga),
        call(closeTaskSaga),
        call(updateTaskSaga)
    ]);
}
