import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.less';

export const AdditionalSettings = ({ isDisabledCRUD, cb24data, formatMessage, saveData }) => {
    const [additionalSettingsForm] = Form.useForm();

    useEffect(() => {
        additionalSettingsForm.setFieldsValue({
            title: _.get(cb24data, 'title'),
            metaDescription: _.get(cb24data, 'meta_description'),
            googleAnalytics: _.get(cb24data, 'ga_id')
        });
    }, [cb24data, additionalSettingsForm]);

    return (
        <div
            style={{
                maxWidth: '70%'
            }}
        >
            <Form
                disabled={isDisabledCRUD}
                form={additionalSettingsForm}
                labelCol={{
                    span: 4
                }}
                name='additional-settings'
                onFinish={saveData}
            >
                <Form.Item
                    label='<TITLE>'
                    name='title'
                    tooltip={{
                        title: formatMessage({
                            id: 'general_settings.title_tooltip'
                        }),
                        icon: <QuestionCircleOutlined />
                    }}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='<Meta Description>'
                    name='metaDescription'
                    tooltip={{
                        title: formatMessage({
                            id: 'general_settings.meta_desc_tooltip'
                        }),
                        icon: <QuestionCircleOutlined />
                    }}
                >
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                </Form.Item>
                <Form.Item label={<FormattedMessage id='general_settings.google_code' />} name='googleAnalytics'>
                    <Input />
                </Form.Item>
                {/* <Form.Item label='Google Site Verification' name='verify'>
                    <Input
                        placeholder={formatMessage({
                            id: 'general_settings.verification_placeholder'
                        })}
                    />
                </Form.Item> */}
                <Form.Item
                    wrapperCol={{
                        offset: 14,
                        span: 12
                    }}
                >
                    <Button
                        htmlType='submit'
                        style={{
                            marginTop: 24
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='general_settings.submit' />
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};
