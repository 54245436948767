import {
    BarcodeOutlined,
    CopyOutlined,
    ExclamationCircleOutlined,
    PrinterOutlined,
    ScanOutlined,
    SyncOutlined
} from '@ant-design/icons';
import { Button, Input, Modal, Tooltip, message, notification } from 'antd';
import JsBarcode from 'jsbarcode'; // https://github.com/lindell/JsBarcode/wiki/Options
import _ from 'lodash';
import { NewBarcodePrintModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

function replaceLocation(str, reverse) {
    const replacer = {
        q: 'й',
        w: 'ц',
        e: 'у',
        r: 'к',
        t: 'е',
        y: 'н',
        u: 'г',
        i: 'ш',
        o: 'щ',
        p: 'з',
        '[': 'х',
        ']': 'ъ',
        a: 'ф',
        s: 'ы',
        d: 'в',
        f: 'а',
        g: 'п',
        h: 'р',
        j: 'о',
        k: 'л',
        l: 'д',
        ';': 'ж',
        '\'': 'э',
        z: 'я',
        x: 'ч',
        c: 'с',
        v: 'м',
        b: 'и',
        n: 'т',
        m: 'ь',
        ',': 'б',
        '.': 'ю',
        '/': '.'
    };

    reverse &&
        Object.keys(replacer).forEach(key => {
            const v = replacer[key];
            delete replacer[key];
            replacer[v] = key;
        });

    for (let i = 0; i < str.length; i++) {
        if (replacer[str[i].toLowerCase()] != undefined) {
            let replace;
            if (str[i] == str[i].toLowerCase()) {
                replace = replacer[str[i].toLowerCase()];
            } else if (str[i] == str[i].toUpperCase()) {
                replace = replacer[str[i].toLowerCase()].toUpperCase();
            }
            str = str.replace(str[i], replace);
        }
    }

    return str;
}

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

/**
 * @property {*} barcodeClassName - custom styles for barcode container
 * @property { Boolean } [provideHint = true] - false if you want to disable hint when hower on a component
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class Barcode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            scanedCode: undefined,
            scanedInputValue: undefined,
            visibleNewBarcodePrintModal: false
        };

        this.input = React.createRef();

        this.id = _.uniqueId('barcode-');

        this.defaultBarcodeOptions = {
            // format: "EAN13",
            lineColor: '#000',
            background: 'transparent',
            width: 2,
            height: 40,
            fontSize: 14,
            fontOptions: '',
            textAlign: 'center',
            textPosition: 'left',
            textMargin: 2,
            margin: 4,
            marginTop: undefined,
            marginBottom: undefined,
            marginLeft: undefined,
            marginRight: undefined,
            flat: true,
            displayValue: false
        };

        this.defaultModalBarcodeOptions = {
            // format: "EAN13",
            lineColor: '#000',
            background: 'transparent',
            width: 2,
            height: 90,
            fontSize: 14,
            fontOptions: '',
            textAlign: 'center',
            textPosition: 'bottom',
            textMargin: 2,
            margin: 0,
            marginTop: undefined,
            marginBottom: undefined,
            marginLeft: undefined,
            marginRight: undefined,
            flat: true,
            displayValue: true
        };
    }

    updateBarcode = () => {
        const { prefix, user, displayBarcode } = this.props;
        const { scanedCode } = this.state;
        const { id } = this;
        const defaultOptions = displayBarcode ? this.defaultBarcodeOptions : this.defaultModalBarcodeOptions;
        const options = _.get(this.props, 'options', {});

        let code = scanedCode || 'SCAN YOUR CODE';
        if (Boolean(scanedCode) && !/\w+-\d+\-\w+/.test(scanedCode) && prefix) {
            code = `${prefix}-${user.businessId}-${code}`;
        }

        try {
            JsBarcode(`#${id}`, code, {
                ...defaultOptions,
                ...options
            });
        } catch (e) {}

        if (this.input.current) this.input.current.focus();
    };

    showModal = () => {
        const { disabled, value, prefix, user } = this.props;
        let code = value;
        if (value && prefix) {
            code = code.replace(`${prefix}-${user.businessId}-`, '');
        }
        if (!disabled) {
            this.setState({
                visible: true,
                scanedCode: code
            });
        }
    };

    handleCancel = () => {
        const { onCancel } = this.props;
        this.setState({
            scanedInputValue: undefined,
            scanedCode: undefined,
            visible: false
        });
        if (onCancel) onCancel();
    };

    handleOk = async () => {
        const { referenceId, table, onConfirm, prefix, user, multipleMode } = this.props;
        const { scanedCode } = this.state;
        const fullPrefix = `${prefix}-${user.businessId}`;
        const codeWithPrefix = `${fullPrefix}-${scanedCode}`;

        if (referenceId && table && scanedCode) {
            try {
                await fetchAPI(
                    'POST',
                    'barcodes',
                    undefined,
                    [
                        {
                            referenceId: String(referenceId),
                            table,
                            customCode: scanedCode
                        }
                    ],
                    { handleErrorInternally: true }
                );
                this.setState({
                    inputCode: ''
                });
                notification.success({
                    message: this.props.intl.formatMessage({ id: 'barcode.barcode_setted' })
                });
                if (onConfirm) {
                    onConfirm(scanedCode, fullPrefix, codeWithPrefix);
                    this.handleCancel();
                }
            } catch (e) {
                notification.error({
                    message: this.props.intl.formatMessage({ id: 'barcode.barcode_already_set' })
                });
                this.setState({
                    scanedInputValue: undefined,
                    scanedCode: undefined
                });
            }
        } else if (onConfirm) {
            if (multipleMode) {
                onConfirm(scanedCode, fullPrefix, codeWithPrefix);
                this.setState({
                    scanedInputValue: undefined,
                    scanedCode: undefined
                });
            } else {
                onConfirm(scanedCode, fullPrefix, codeWithPrefix);
                this.handleCancel();
            }
        }
    };

    checkBarcodeExisting = async () => {
        const { scanedCode } = this.state;

        const barcodes = await fetchAPI('GET', 'barcodes', null, null);

        if (barcodes) {
            const index = barcodes.findIndex(({ customCode }) => scanedCode === customCode);

            if (index >= 0) {
                notification.error({
                    message: this.props.intl.formatMessage({
                        id: 'barcode.check_existing'
                    })
                });
            }
        }
    };

    componentDidMount() {
        this.updateBarcode();
    }

    componentDidUpdate(prevProps, prevState) {
        this.updateBarcode();
        if (this.state.visible && !prevState.visible) {
            this.input.current.focus();
            setTimeout(() => this.input.current.focus(), 0);
        }
    }

    render() {
        const {
            barcodeClassName,
            provideHint = true,
            zIndex,
            displayBarcode,
            iconStyle,
            buttonStyle,
            button,
            disabled: propsDisabled,
            style,
            onConfirm,
            prefix,
            user,
            referenceId,
            value,
            enableScanIcon,
            multipleMode,
            product,
            empData
        } = this.props;
        const { visible, scanedCode, scanedInputValue, visibleNewBarcodePrintModal } = this.state;
        const { id } = this;
        // const iconType = enableScanIcon && !value ? 'scan' : 'barcode';

        const icon =
            enableScanIcon && !value ? (
                <ScanOutlined
                    style={{
                        ...iconStyle
                    }}
                />
            ) : (
                <BarcodeOutlined
                    style={{
                        ...iconStyle
                    }}
                />
            );

        const disabled = propsDisabled || !isGrantAccessed(user, grants.WORKPLACE_BARCODES, accesses.ROWO);

        return !displayBarcode ? (
            <div className={barcodeClassName}>
                <Tooltip title={provideHint && <FormattedMessage id='navigation.barcode' />} zIndex={2001}>
                    <Button
                        disabled={disabled}
                        icon={icon}
                        onClick={this.showModal}
                        style={buttonStyle}
                        type={button ? 'default' : 'text'}
                    />
                </Tooltip>
                <Modal
                    bodyStyle={{ padding: 0 }}
                    destroyOnClose
                    footer={
                        scanedCode && onConfirm
                            ? [
                                  <Button key='back' onClick={this.handleCancel}>
                                      <FormattedMessage id='cancel' />
                                  </Button>,
                                  <Button key='submit' onClick={this.handleOk} type='primary'>
                                      <FormattedMessage id={value ? 'update' : 'add'} />
                                  </Button>
                              ]
                            : null
                    }
                    forceRender
                    onCancel={this.handleCancel}
                    title={
                        <div
                            onClick={() => {
                                this.input.current.focus();
                            }}
                        >
                            <FormattedMessage id='navigation.barcode' />
                        </div>
                    }
                    visible={visible}
                    width='fit-content'
                    zIndex={zIndex || 300}
                >
                    <div className={Styles.barcodeWrapp}>
                        <div className={Styles.barcodeActions}>
                            {scanedCode && (
                                <React.Fragment>
                                    <CopyOutlined
                                        onClick={() => {
                                            let code = scanedCode;
                                            if (scanedCode && prefix) {
                                                code = `${prefix}-${user.businessId}-${code}`;
                                            }
                                            navigator.clipboard.writeText(code);
                                            message.success('Coppied!');
                                        }}
                                    />
                                    <PrinterOutlined
                                        onClick={() => {
                                            this.setState({
                                                visibleNewBarcodePrintModal: true
                                            });
                                        }}
                                        // onClick={() => {
                                        //     window.print();
                                        // }}
                                    />
                                </React.Fragment>
                            )}
                            {referenceId && (
                                <SyncOutlined
                                    onClick={() => {
                                        this.setState({
                                            scanedCode: String(referenceId)
                                        });
                                    }}
                                />
                            )}
                        </div>
                        <div
                            className={Styles.barcode}
                            onClick={() => {
                                this.input.current.focus();
                            }}
                        >
                            <canvas id={id}></canvas>
                        </div>
                        <Input
                            ref={this.input}
                            className={Styles.barcodeInput}
                            disabled={disabled || !onConfirm}
                            onChange={({ target }) => {
                                this.setState({
                                    scanedInputValue: replaceLocation(target.value, true)
                                });
                            }}
                            onPressEnter={async () => {
                                if (scanedInputValue) {
                                    await this.setState({
                                        scanedCode: String(scanedInputValue)
                                            .replace(`${prefix}-${user.businessId}-`, '')
                                            .toUpperCase(),
                                        scanedInputValue: undefined
                                    });
                                    this.checkBarcodeExisting();
                                }
                                if (multipleMode) {
                                    this.handleOk();
                                }
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'barcode.scan_barcode'
                            })}
                            value={scanedInputValue}
                        />

                        <span
                            style={{
                                opacity: 0.7,
                                color: 'grey',
                                fontWeight: 700
                            }}
                        >
                            <ExclamationCircleOutlined /> <FormattedMessage id='barcode.tooltip_enter' />
                        </span>
                    </div>
                </Modal>
                <NewBarcodePrintModal
                    code={scanedCode}
                    empData={empData}
                    hideModal={() => {
                        this.setState({
                            visibleNewBarcodePrintModal: false
                        });
                    }}
                    product={product}
                    visible={visibleNewBarcodePrintModal}
                />
            </div>
        ) : (
            <div className={Styles.barcode} style={style}>
                <canvas id={id}></canvas>
            </div>
        );
    }
}
