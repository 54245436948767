import { ArrowLeftOutlined, CarryOutFilled } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import { DataItem } from 'commons';
import { getReport } from 'core/order/duck';
import { setView } from 'core/ui/duck';
import dayjs from 'dayjs';
import _, { throttle } from 'lodash';
import {
    fetchVehicleOrders,
    selectVehicleOrdersSort,
    selectVehicleOrdersStats,
    setChangeMileageModalVisibility
} from 'pages/OnlineServiceBookPage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { DiagnosticIcon, RecommendationsIcon, RegulationsIcon, RepairIcon, VehicleHistoryIcon } from 'theme';
import { _breakpoints, fetchAPI } from 'utils';
import {
    BookCurrentOrderContainer,
    BookDiagnosticsContainer,
    BookHistoryContainer,
    BookRecommendationsContainer,
    BookRegulationsContainer
} from './components/containers';
import { ChangeMileageModal, RefactoredRegisterForServiceModal } from './components/modals';
import {
    fetchAgreements,
    fetchAppurtenances,
    fetchLabors,
    fetchRecommendations,
    fetchVehicleIntervals,
    selectAppurtenances,
    selectClient,
    selectCurrentTab,
    selectLabors,
    selectManagerId,
    selectVehicle,
    selectVehicleData,
    selectVehicleOrders,
    setCurrentTab,
    setManagerId,
    setPageOrders,
    setRegisterForServiceModalVisibility,
    setToken,
    setVehicleId
} from './redux/duck';
import Styles from './styles.m.css';

const { TabPane } = Tabs;

const DATETIME_FORMAT = 'DD.MM.YYYY';

const mapStateToProps = state => ({
    user: state.auth,
    client: selectClient(state),
    vehicle: selectVehicle(state),
    currentTab: selectCurrentTab(state),
    managerId: selectManagerId(state),
    labors: selectLabors(state),
    vehicleData: selectVehicleData(state),
    appurtenances: selectAppurtenances(state),
    orders: selectVehicleOrders(state),
    sort: selectVehicleOrdersSort(state),
    stats: selectVehicleOrdersStats(state),
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setVehicleId,
    fetchVehicleOrders,
    fetchVehicleIntervals,
    fetchRecommendations,
    fetchLabors,
    fetchAppurtenances,
    fetchAgreements,
    setCurrentTab,
    setToken,
    setRegisterForServiceModalVisibility,
    setManagerId,
    setPageOrders,
    setView,
    setChangeMileageModalVisibility,
    getReport
};

/**
 * Service book is used by a user to monitor its car state.
 * \
 * ***This page is VERY DANGER:***
 * \
 * This page can be opened without authentication token(but with sessionId). To receive information from backend it has to have JsonWebToken(JWT).
 * So token is fetched with provided sessionId, it has full manager rights(manager who provided this session), so be very careful!
 * Any requests on this page with that token work as ***MANAGER*** actions.
 * ***DO NOT REDIRECT TO OTHER PAGES WITH SENSITIVE STUFF AND USE SPECIAL FETCHING METHOD IN REDUX***
 * \
 * \
 * It accepts base 64 encoded json object that contains vehicleId and sessionId, it is decoded later and used
 * to fetch required data.
 * \
 * Encoded url has to be a json object of structure: {vehicleId: 12345, managerId: 12345, businessId: 12345, sessionId: '1aa00b38-369a-47a1-b82d-450927b31b9c'}
 */
@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class OnlineServiceBookPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allVehicleRegulations: [],
            data: {
                currentVehicleInfo: {}
            },
            allRecommendations: [],
            diagtosticsData: [],
            visibleRegisterServiceModal: false,
            bookCurrentOrderShow: false,
            bookRecomShow: false,
            bookHistoryShow: false,
            bookRegulationsShow: false,
            bookDiagnosticsShow: false
        };
    }

    async componentDidMount() {
        const {
            setVehicleId,
            fetchVehicleOrders,
            fetchRecommendations,
            fetchAgreements,
            match,
            setToken,
            setManagerId,
            fetchLabors,
            fetchAppurtenances,
            fetchVehicleOrdersLatest,
            orders,
            isMobile
        } = this.props;

        // Convert provided base64 code to initial parameters
        const base64Data = _.get(match, 'params.base64Data');
        const jsonString = window.atob(base64Data);
        const data = JSON.parse(jsonString);

        const { vehicleId, sessionId, businessId } = data;

        this.setState({ data });
        this.setState({ dataServiceBook: data });

        const { token, managerId } = await fetchAPI(
            'GET',
            'get_token_by_session_id',
            { sessionId, businessId, language: 'ru' },
            null,
            { noAuth: true }
        );
        setToken(token);
        setManagerId(managerId);
        this.setState({
            token
        });

        this.fetchVehicleRegulations(vehicleId, token);
        this.fetchVehicleRecommendations(vehicleId, token);
        this.fetchGeneralData(vehicleId, token);
        this.fetchDiagnosticData(vehicleId, token);

        // Load data
        if (vehicleId) {
            window.addEventListener('resize', this.setView);
            this.setView();

            setVehicleId(vehicleId); // Init vehicle id when it is provided
            fetchVehicleOrders();
            fetchLabors();
            fetchAppurtenances();
            fetchVehicleIntervals();
            fetchAgreements();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setView);
    }

    setView = throttle(() => {
        this.props.setView({
            isMobile: window.innerWidth <= _breakpoints.mobile.max
        });
    }, 500);

    // fetchVehicleOrders = async (vehicleId, token) => {
    //     const data = await fetchAPI('GET', `orders/vehicle/${vehicleId}`, { pageSize: 100 }, undefined, {
    //         handleErrorInternally: true,
    //         headers: {
    //             Authorization: token
    //         }
    //     });
    //     this.setState({
    //         orders: data.orders || []
    //     });
    // };

    fetchVehicleRecommendations = async (vehicleId, token) => {
        const recommendations = await fetchAPI('GET', 'recommendations/labors', { vehicleId }, undefined, {
            handleErrorInternally: true,
            headers: {
                Authorization: token
            }
        });
        this.setState({
            allRecommendations: recommendations.list || [],
            recomStats: recommendations.count.count
        });
    };

    fetchGeneralData = async (vehicleId, token) => {
        const data = await fetchAPI('GET', 'order_latest_info', { vehicleId }, undefined, {
            handleErrorInternally: true,
            headers: {
                Authorization: token
            }
        });
        this.setState({
            generalData: data
        });
    };

    fetchDiagnosticData = async (vehicleId, token) => {
        const diagnostics = await fetchAPI('GET', '/order_diagnostics_by_vehicleId', { vehicleId }, undefined, {
            handleErrorInternally: true,
            headers: {
                Authorization: token
            }
        });
        this.setState({
            diagtosticsData: diagnostics ? diagnostics.filter(({ diagnostics }) => _.get(diagnostics, 'length') > 0 ) : undefined
        });
    };

    fetchVehicleRegulations = async (vehicleId, token) => {
        const allVehicleRegulations = await fetchAPI('GET', 'vehicle/regulations', { vehicleId }, undefined, {
            handleErrorInternally: true,
            headers: {
                Authorization: token
            }
        });
        this.setState({
            allVehicleRegulations: allVehicleRegulations.result || [],
            data: {
                currentVehicleInfo: allVehicleRegulations.vehicleOptions
            }
        });
    };

    render() {
        const {
            client,
            vehicle,
            currentTab,
            setCurrentTab,
            setRegisterForServiceModalVisibility,
            managerId,
            labors,
            vehicleData,
            appurtenances,
            orders,
            fetchVehicleOrders,
            setPageOrders,
            sort,
            stats,
            recommendations,
            generalData,
            isMobile,
            setChangeMileageModalVisibility,
            getReport
        } = this.props;

        const {
            allRecommendations,
            visibleRegisterServiceModal,
            recomStats,
            dataServiceBook,
            bookCurrentOrderShow,
            bookRecomShow,
            bookHistoryShow,
            bookRegulationsShow,
            bookDiagnosticsShow,
            token,
            sessionBussiness
        } = this.state;

        return (
            <div>
                <div className={Styles.header}>
                    <div className={Styles.title}>
                        {isMobile &&
                            (bookCurrentOrderShow ||
                                bookRecomShow ||
                                bookRegulationsShow ||
                                bookHistoryShow ||
                                bookDiagnosticsShow) && (
                                <ArrowLeftOutlined
                                    className={Styles.backBtn}
                                    onClick={() => {
                                        this.setState({
                                            bookCurrentOrderShow: false,
                                            bookRecomShow: false,
                                            bookRegulationsShow: false,
                                            bookHistoryShow: false,
                                            bookDiagnosticsShow: false
                                        });
                                    }}
                                />
                            )}
                        <FormattedMessage id='online_service_book_page.page_title' />

                        {!isMobile && (
                            <div className={isMobile ? Styles.controlsMobile : Styles.controls}>
                                <Button
                                    onClick={() =>
                                        this.setState({
                                            visibleRegisterServiceModal: true
                                        })
                                    }
                                    size='small'
                                    type='primary'
                                >
                                    <FormattedMessage id='online_service_book_page.register_for_service' />
                                </Button>
                            </div>
                        )}
                    </div>
                    {/* <div className={isMobile ? Styles.controlsMobile : Styles.controls}>
                        <Button
                            onClick={() =>
                                this.setState({
                                    visibleRegisterServiceModal: true
                                })
                            }
                            size='small'
                            type='primary'
                        >
                            <FormattedMessage id='online_service_book_page.register_for_service' />
                        </Button>
                        
                    </div> */}
                    <div></div>
                </div>
                <div className={isMobile ? Styles.mainDivMobileStyle : Styles.mainDivStyle}>
                    {isMobile &&
                        !bookCurrentOrderShow &&
                        !bookRecomShow &&
                        !bookRegulationsShow &&
                        !bookHistoryShow &&
                        !bookDiagnosticsShow && (
                            <div className={isMobile ? Styles.dataItemMobileBlock : Styles.dataItemBlock}>
                                <DataItem
                                    className={Styles.dataItem}
                                    label={<FormattedMessage id='add_client_form.number' />}
                                >
                                    {vehicle.vehicleNumber}
                                </DataItem>
                                <DataItem
                                    className={Styles.dataItem}
                                    label={<FormattedMessage id='add_order_form.vin' />}
                                >
                                    {vehicle.vehicleVin}
                                </DataItem>
                                <DataItem
                                    className={Styles.dataItem}
                                    label={<FormattedMessage id='client_order_tab.car' />}
                                >
                                    {`${vehicle.make || '-'}, ${vehicle.model || '-'}${
                                        vehicle.modification ? ',' : ''
                                    } ${vehicle.modification || ''} ${vehicle.year || '-'} `}
                                </DataItem>
                                <DataItem
                                    className={Styles.dataItem}
                                    controls={
                                        <div>
                                            <Button
                                                className={Styles.button}
                                                onClick={() => {
                                                    setChangeMileageModalVisibility(true);
                                                }}
                                                size='small'
                                                type='primary'
                                            >
                                                <FormattedMessage id='online_service_book_page.update' />
                                            </Button>
                                        </div>
                                    }
                                    label={<FormattedMessage id='client_order_tab.odometer' />}
                                >
                                    {_.get(vehicleData, 'currentMileage') || '-'}{' '}
                                    <span>
                                        <FormattedMessage id='online_service_book_page.km_by' />
                                    </span>{' '}
                                    {dayjs(_.get(vehicleData, 'currentDate')).format(DATETIME_FORMAT) || '-'}
                                </DataItem>
                                <DataItem
                                    className={Styles.dataItem}
                                    label={<FormattedMessage id='online_service_book_page.manager_name' />}
                                >
                                    {vehicle.managerName || '-'}
                                </DataItem>
                                <DataItem
                                    className={Styles.dataItem}
                                    label={<FormattedMessage id='online_service_book_page.manager_phone' />}
                                >
                                    {vehicle.managerPhone || '-'}
                                </DataItem>
                            </div>
                        )}
                    {!isMobile && (
                        <div className={isMobile ? Styles.dataItemMobileBlock : Styles.dataItemBlock}>
                            <DataItem
                                className={Styles.dataItem}
                                label={<FormattedMessage id='add_client_form.number' />}
                            >
                                {vehicle.vehicleNumber}
                            </DataItem>
                            <DataItem className={Styles.dataItem} label={<FormattedMessage id='add_order_form.vin' />}>
                                {vehicle.vehicleVin}
                            </DataItem>
                            <DataItem
                                className={Styles.dataItem}
                                label={<FormattedMessage id='client_order_tab.car' />}
                            >
                                {`${vehicle.make || '-'}, ${vehicle.model || '-'}${vehicle.modification ? ',' : ''} ${
                                    vehicle.modification || ''
                                } ${vehicle.year || '-'} `}
                            </DataItem>
                            <DataItem
                                className={Styles.dataItem}
                                controls={
                                    <div>
                                        <Button
                                            className={Styles.button}
                                            onClick={() => {
                                                setChangeMileageModalVisibility(true);
                                            }}
                                            size='small'
                                            type='primary'
                                        >
                                            <FormattedMessage id='online_service_book_page.update' />
                                        </Button>
                                    </div>
                                }
                                label={<FormattedMessage id='client_order_tab.odometer' />}
                            >
                                {_.get(vehicleData, 'currentMileage') || '-'}{' '}
                                <span>
                                    <FormattedMessage id='online_service_book_page.km_by' />
                                </span>{' '}
                                {dayjs(_.get(vehicleData, 'currentDate')).format(DATETIME_FORMAT) || '-'}
                            </DataItem>
                            <DataItem
                                className={Styles.dataItem}
                                label={<FormattedMessage id='online_service_book_page.manager_name' />}
                            >
                                {vehicle.managerName || '-'}
                            </DataItem>
                            <DataItem
                                className={Styles.dataItem}
                                label={<FormattedMessage id='online_service_book_page.manager_phone' />}
                            >
                                {vehicle.managerPhone || '-'}
                            </DataItem>
                        </div>
                    )}
                    {isMobile &&
                        !bookCurrentOrderShow &&
                        !bookRecomShow &&
                        !bookRegulationsShow &&
                        !bookHistoryShow &&
                        !bookDiagnosticsShow && (
                            <div className={Styles.btnsBlock}>
                                <div className={Styles.btnsContainer}>
                                    <Button
                                        className={Styles.transferBtns}
                                        onClick={() =>
                                            this.setState({
                                                bookCurrentOrderShow: true
                                            })
                                        }
                                    >
                                        <div className={Styles.iconStyle}>
                                            <RepairIcon />
                                        </div>
                                        <span className={Styles.textStyle}>
                                            <FormattedMessage id='online_service_book_page.current_order' />
                                        </span>
                                    </Button>
                                    <Button
                                        className={Styles.transferBtns}
                                        onClick={() =>
                                            this.setState({
                                                bookRecomShow: true
                                            })
                                        }
                                    >
                                        <div className={Styles.iconStyle}>
                                            <RecommendationsIcon />
                                        </div>
                                        <span className={Styles.textStyle}>
                                            <FormattedMessage id='vehicle_page.recommendations' />
                                        </span>
                                    </Button>
                                    <Button
                                        className={Styles.transferBtns}
                                        onClick={() =>
                                            this.setState({
                                                bookHistoryShow: true
                                            })
                                        }
                                    >
                                        <div className={Styles.iconStyle}>
                                            <VehicleHistoryIcon />
                                        </div>
                                        <span className={Styles.textStyle}>
                                            <FormattedMessage id='online_service_book_page.history_of_orders' />
                                        </span>
                                    </Button>

                                    <Button
                                        className={Styles.transferBtns}
                                        onClick={() =>
                                            this.setState({
                                                bookRegulationsShow: true
                                            })
                                        }
                                    >
                                        <div className={Styles.iconStyle}>
                                            <RegulationsIcon />
                                        </div>
                                        <span className={Styles.textStyle}>
                                            <FormattedMessage id='navigation.regulations' />
                                        </span>
                                    </Button>
                                    <Button
                                        className={Styles.transferBtns}
                                        onClick={() =>
                                            this.setState({
                                                bookDiagnosticsShow: true
                                            })
                                        }
                                    >
                                        <div className={Styles.iconStyle}>
                                            <DiagnosticIcon />
                                        </div>
                                        <span className={Styles.textStyle}>
                                            <FormattedMessage id='online_service_book_page.diagnostics' />
                                        </span>
                                    </Button>
                                    <Button
                                        className={Styles.transferBtns}
                                        onClick={() =>
                                            this.setState({
                                                visibleRegisterServiceModal: true
                                            })
                                        }
                                    >
                                        <div className={Styles.iconStyle}>
                                            <CarryOutFilled />
                                        </div>
                                        <span className={Styles.textStyle}>
                                            <FormattedMessage id='online_service_book_page.register_for_service' />
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        )}
                    {isMobile &&
                        (bookCurrentOrderShow ||
                            bookRecomShow ||
                            bookRegulationsShow ||
                            bookHistoryShow ||
                            bookDiagnosticsShow) && (
                            <div className={isMobile ? Styles.tabsMobileBlock : Styles.tabsBlock}>
                                <Tabs centered size='small' tabPosition='top' type='card'>
                                    {bookCurrentOrderShow && (
                                        <TabPane
                                            key='current_order'
                                            tab={
                                                <span
                                                    style={{
                                                        fontSize: 18
                                                    }}
                                                >
                                                    <FormattedMessage id='online_service_book_page.current_order' />
                                                </span>
                                            }
                                        >
                                            <BookCurrentOrderContainer
                                                appurtenances={appurtenances}
                                                dataServiceBook={dataServiceBook}
                                                downloadReport={this.downloadReport}
                                                fetchVehicleOrders={fetchVehicleOrders}
                                                generalData={generalData}
                                                getReport={getReport}
                                                isMobile={isMobile}
                                                labors={labors}
                                                recommendations={this.state.allRecommendations}
                                                token={token}
                                            />
                                        </TabPane>
                                    )}

                                    {bookRecomShow && (
                                        <TabPane
                                            key='recommendations'
                                            tab={
                                                <span
                                                    style={{
                                                        fontSize: 18
                                                    }}
                                                >
                                                    <FormattedMessage id='vehicle_page.recommendations' />
                                                </span>
                                            }
                                        >
                                            <BookRecommendationsContainer
                                                fetchVehicleRecommendations={this.fetchVehicleRecommendations}
                                                getReport={getReport}
                                                isMobile={isMobile}
                                                recommendations={this.state.allRecommendations}
                                                recomStats={recomStats}
                                                token={token}
                                                vehicleId={_.get(vehicle, 'id')}
                                            />
                                        </TabPane>
                                    )}

                                    {bookHistoryShow && (
                                        <TabPane
                                            key='history'
                                            tab={
                                                <span
                                                    style={{
                                                        fontSize: 18
                                                    }}
                                                >
                                                    {' '}
                                                    <FormattedMessage id='online_service_book_page.history_of_orders' />
                                                </span>
                                            }
                                        >
                                            <BookHistoryContainer
                                                fetchVehicleOrders={fetchVehicleOrders}
                                                getReport={getReport}
                                                isMobile={isMobile}
                                                orders={orders}
                                                setPageOrders={setPageOrders}
                                                sort={sort}
                                                stats={stats}
                                                token={token}
                                            />
                                        </TabPane>
                                    )}
                                    {bookRegulationsShow && (
                                        <TabPane
                                            key='regulations'
                                            tab={
                                                <span
                                                    style={{
                                                        fontSize: 18
                                                    }}
                                                >
                                                    {' '}
                                                    <FormattedMessage id='navigation.regulations' />{' '}
                                                </span>
                                            }
                                        >
                                            <BookRegulationsContainer
                                                allVehicleRegulations={this.state.allVehicleRegulations}
                                                data={this.state.data}
                                                fetchVehicleRegulations={this.fetchVehicleRegulations}
                                                getReport={getReport}
                                                isMobile={isMobile}
                                                token={token}
                                            />
                                        </TabPane>
                                    )}

                                    {bookDiagnosticsShow && (
                                        <TabPane
                                            key='diagnostics'
                                            tab={
                                                <span
                                                    style={{
                                                        fontSize: 18
                                                    }}
                                                >
                                                    {' '}
                                                    <FormattedMessage id='online_service_book_page.diagnostics' />{' '}
                                                </span>
                                            }
                                        >
                                            <BookDiagnosticsContainer
                                                diagtosticsData={this.state.diagtosticsData}
                                                getReport={getReport}
                                                isMobile={isMobile}
                                                token={token}
                                            />
                                        </TabPane>
                                    )}
                                </Tabs>
                            </div>
                        )}
                    {!isMobile && (
                        <div className={isMobile ? Styles.tabsMobileBlock : Styles.tabsBlock}>
                            <Tabs size='small' tabPosition='top' type='card'>
                                <TabPane
                                    key='current_order'
                                    tab={<FormattedMessage id='online_service_book_page.current_order' />}
                                >
                                    <BookCurrentOrderContainer
                                        appurtenances={appurtenances}
                                        dataServiceBook={dataServiceBook}
                                        downloadReport={this.downloadReport}
                                        fetchVehicleOrders={fetchVehicleOrders}
                                        generalData={generalData}
                                        getReport={getReport}
                                        isMobile={isMobile}
                                        labors={labors}
                                        recommendations={this.state.allRecommendations}
                                        token={token}
                                    />
                                </TabPane>

                                <TabPane
                                    key='recommendations'
                                    tab={<FormattedMessage id='vehicle_page.recommendations' />}
                                >
                                    <BookRecommendationsContainer
                                        fetchVehicleRecommendations={this.fetchVehicleRecommendations}
                                        getReport={getReport}
                                        isMobile={isMobile}
                                        recommendations={this.state.allRecommendations}
                                        recomStats={recomStats}
                                        token={token}
                                        vehicleId={_.get(vehicle, 'id')}
                                    />
                                </TabPane>

                                <TabPane
                                    key='history'
                                    tab={<FormattedMessage id='online_service_book_page.history_of_orders' />}
                                >
                                    <BookHistoryContainer
                                        fetchVehicleOrders={fetchVehicleOrders}
                                        getReport={getReport}
                                        isMobile={isMobile}
                                        orders={orders}
                                        setPageOrders={setPageOrders}
                                        sort={sort}
                                        stats={stats}
                                        token={token}
                                    />
                                </TabPane>
                                <TabPane key='regulations' tab={<FormattedMessage id='navigation.regulations' />}>
                                    <BookRegulationsContainer
                                        allVehicleRegulations={this.state.allVehicleRegulations}
                                        data={this.state.data}
                                        fetchVehicleRegulations={this.fetchVehicleRegulations}
                                        getReport={getReport}
                                        isMobile={isMobile}
                                        token={token}
                                    />
                                </TabPane>
                                <TabPane
                                    key='diagnostics'
                                    tab={<FormattedMessage id='online_service_book_page.diagnostics' />}
                                >
                                    <BookDiagnosticsContainer
                                        diagtosticsData={this.state.diagtosticsData}
                                        getReport={getReport}
                                        isMobile={isMobile}
                                        token={token}
                                    />
                                </TabPane>
                            </Tabs>
                        </div>
                    )}
                </div>
                {/* <Tabs activeKey={currentTab} onChange={key => setCurrentTab(key)} tabPosition='top'>
                    <TabPane
                        key={onlineServiceBookTabs.home}
                        tab={<FormattedMessage id='online_service_book_page.home' />}
                    >
                        <HomeTab />
                    </TabPane>

                    <TabPane
                        key={onlineServiceBookTabs.inspectionIntervals}
                        tab={
                            <FormattedMessage id='online_service_book_page.inspection_intervals' />
                        }
                    >
                        <InspectionIntervalsTab />
                    </TabPane>

                    <TabPane
                        key={onlineServiceBookTabs.orders}
                        tab={<FormattedMessage id='online_service_book_page.orders' />}
                    >
                        <OrdersTab />
                    </TabPane>
                </Tabs> */}

                {/* <RegisterForServiceModal
                    businessId={_.get(this.state, 'data.businessId')}
                    clientId={_.get(client, 'clientId')}
                    managerId={managerId}
                    vehicleId={_.get(vehicle, 'id')}
                /> */}
                <RefactoredRegisterForServiceModal
                    businessId={_.get(this.state, 'data.businessId')}
                    client={client}
                    clientId={_.get(client, 'clientId')}
                    dataServiceBook={dataServiceBook}
                    hideModal={() => {
                        this.setState({
                            visibleRegisterServiceModal: false
                        });
                    }}
                    isMobile={isMobile}
                    managerId={managerId}
                    token={token}
                    vehicle={vehicle}
                    vehicleId={_.get(vehicle, 'id')}
                    visible={visibleRegisterServiceModal}
                />
                <ChangeMileageModal
                    fetchVehicleOrders={fetchVehicleOrders}
                    token={token}
                    vehicleData={vehicleData}
                    vehicleId={_.get(vehicle, 'id')}
                />
            </div>
        );
    }
}
