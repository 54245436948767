import { Form } from '@ant-design/compatible';
import { SaveOutlined } from '@ant-design/icons';
import { InputNumber, Select } from 'antd';
import { Catcher } from 'commons';
import {
    createService,
    deleteService,
    onChangeServicesForm,
    resetFields,
    updateService
} from 'core/forms/servicesForm/duck';
import { fetchServicesSuggestions, selectServicesSuggestionsOptions } from 'core/servicesSuggestions/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withReduxForm2 } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

@injectIntl
@withReduxForm2({
    name: 'servicesForm',
    actions: {
        change: onChangeServicesForm,
        fetchServicesSuggestions,
        resetFields,
        createService,
        updateService,
        deleteService
    },
    mapStateToProps: state => ({
        ...selectServicesSuggestionsOptions(state)
    })
})
export class ServicesForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceId: undefined
        };
    }

    render() {
        const {
            form,
            resetFields,
            createService,
            updateService,
            deleteService,

            services,
            details
        } = this.props;
        const { getFieldDecorator, getFieldsValue, validateFields } = this.props.form;
        const { serviceId, detailId, quantity } = this.state;

        console.log(serviceId, 'serviceId');

        return (
            <Catcher>
                <Form className={Styles.form} layout='horizontal'>
                    <Select
                        cnStyles={Styles.servicesSelect}
                        onSelect={value => {
                            this.setState({
                                serviceId: value
                            })
                        }}
                        placeholder='Виберіть роботу'
                        rules={[
                            {
                                required: true,
                                message: 'serviceId is required!'
                            }
                        ]}
                        showSearch
                        style={{
                            maxWidth: 380,
                            marginRight: 8
                        }}
                        value={serviceId}
                        dropdownStyle={{ width: '50%' }}
                        // initialValue={ _getDefaultValue(key, 'serviceId') }
                        optionFilterProp='children'
                    >
                        {services.map(({ serviceId, serviceName }) => (
                            <Option key={serviceId} value={serviceId}>
                                {serviceName}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        cnStyles={Styles.detailsSelect}
                        dropdownStyle={{ width: '50%' }}
                        onSelect={value => {
                            this.setState({
                                detailId: value
                            });
                        }}
                        optionFilterProp='children'
                        placeholder='Виберіть деталь'
                        rules={[
                            {
                                required: true,
                                message: 'detailId is required!'
                            }
                        ]}
                        showSearch
                        style={{
                            maxWidth: 380,
                            marginRight: 8
                        }}
                        value={detailId}
                    >
                        {details.map(({ detailId, detailName }) => (
                            <Option key={detailId} value={String(detailId)}>
                                {detailName}
                            </Option>
                        ))}
                    </Select>
                    <InputNumber
                        cnStyles={Styles.quantity}
                        min={1}
                        onChange={quantity => {
                            this.setState({
                                quantity
                            });
                        }}
                        placeholder='кіл-ть'
                        rules={[
                            {
                                required: true,
                                message: 'quantity is required!'
                            }
                        ]}
                        style={{
                            marginRight: 8
                        }}
                    />
                    <SaveOutlined
                        className={Styles.saveIcon}
                        onClick={async () => {
                            await createService({
                                serviceId: Number(serviceId),
                                detailId: Number(detailId),
                                quantity: Number(quantity)
                            });
                        }}
                    />
                </Form>
            </Catcher>
        );
    }
}
