import { replace } from 'connected-react-router';
import { fetchHeaderData } from 'core/subscription/duck';
import { emitError, setAuthFetchingState } from 'core/ui/duck';
import { purgeStoredState } from 'redux-persist';
import { all, call, put, putResolve, take } from 'redux-saga/effects';
import book from 'routes/book';
import { persistConfig } from 'store/rootReducer';
import {
    removeBusinessTypes,
    removeHeaderIcons,
    removeLocale,
    removeOrderIcons,
    removeSessionId,
    removeToken,
    setBusinessTypes,
    setHeaderIcons,
    setLocale,
    setOrderIcons,
    setToken
} from 'utils';
import {
    AUTHENTICATE,
    authenticateSuccess,
    LOGOUT,
    logoutSuccess,
    updateUserSuccess,
    UPDATE_USER
} from './duck';

export function* authenticateSaga() {
    while (true) {
        try {
            const { payload: user } = yield take(AUTHENTICATE);
            yield setLocale(user.language);
            yield setToken(user.token);
            yield setBusinessTypes(user.businessTypes);
            yield setHeaderIcons(user.navigationIcons);
            yield setOrderIcons(user.ordersIcons);
            yield putResolve(fetchHeaderData(true));

            yield authenticateSuccess();
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* logoutSaga() {
    while (true) {
        try {
            yield take(LOGOUT);

            yield put(setAuthFetchingState(true));
            yield removeLocale();
            yield removeToken();
            yield removeSessionId();
            yield removeBusinessTypes();
            yield removeHeaderIcons();
            yield removeOrderIcons();
            yield put(replace(`${book.login}`));
            yield purgeStoredState(persistConfig);
            yield put(logoutSuccess());
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setAuthFetchingState(false));
        }
    }
}

export function* updateUserSaga() {
    while (true) {
        try {
            const { payload: user } = yield take(UPDATE_USER);
            yield put(updateUserSuccess(user));
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* saga() {
    yield all([call(authenticateSaga), call(logoutSaga), call(updateUserSaga)]);
}
