import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Image, Popconfirm, Tabs, Upload, notification } from 'antd';
import imageCompression from 'browser-image-compression';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchAPI, getToken } from 'utils';
import './styles.less';

const { TabPane } = Tabs;
const { Dragger } = Upload;

export class MediaSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileList: [],
            backgrounds: [],
            preset: [],
            current: {}
        };
    }

    componentDidMount() {
        this.getFileList();
        this.getBackgrounds();
    }

    getFileList = async () => {
        const { sessionId } = this.props;
        const fileList = await fetchAPI(
            'GET',
            'rest/v1/gallery',
            {
                sessionId
            },
            null,
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        this.setState({
            fileList
        });
    };

    getBackgrounds = async () => {
        const { sessionId } = this.props;
        const { backgrounds, current, preset } = await fetchAPI(
            'GET',
            'rest/v1/backgrounds',
            {
                sessionId
            },
            null,
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        this.setState({
            backgrounds,
            current,
            preset
        });
    };

    updateLogo = async file => {
        const { sessionId } = this.props;
        const formData = new FormData();
        const compressedFile = await imageCompression(file, {
            maxSizeMB: 2
        });
        formData.append('file', compressedFile);
        formData.append('sessionId', sessionId);

        await fetch(`${__OLD_APP_API_URL__}/rest/v1//gallery`, {
            method: 'POST',
            headers: {
                Authorization: getToken()
            },
            body: formData
        });
        this.getFileList();
    };

    updateBackground = async file => {
        const { sessionId } = this.props;
        const formData = new FormData();
        const compressedFile = await imageCompression(file, {
            maxSizeMB: 2
        });
        formData.append('file', compressedFile);
        formData.append('sessionId', sessionId);

        await fetch(`${__OLD_APP_API_URL__}/rest/v1//backgrounds`, {
            method: 'POST',
            headers: {
                Authorization: getToken()
            },
            body: formData
        });
        this.getBackgrounds();
    };

    setCurrent = async index => {
        const { sessionId } = this.props;
        await fetchAPI(
            'PUT',
            `rest/v1/backgrounds/${index + 1}/use`,
            null,
            {
                sessionId,
                use: true,
                index
            },
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        this.getBackgrounds();
    };

    setBackgrounds = async (id, index) => {
        const { sessionId } = this.props;
        await fetchAPI(
            'PUT',
            `rest/v1/backgrounds/${id}/use-my`,
            null,
            {
                sessionId,
                use: true,
                index
            },
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        this.getBackgrounds();
    };

    deleteMedia = async id => {
        const { sessionId } = this.props;
        await fetchAPI(
            'DELETE',
            `rest/v1/gallery/${id}`,
            null,
            {
                sessionId
            },
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        this.getFileList();
    };

    deleteBackground = async id => {
        const { sessionId } = this.props;
        await fetchAPI(
            'DELETE',
            `rest/v1/backgrounds/${id}`,
            null,
            {
                sessionId
            },
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        this.getBackgrounds();
    };

    visibleChange = async (id, index, visible) => {
        const { sessionId } = this.props;
        await fetchAPI(
            'PUT',
            `rest/v1/gallery/${id}/visibility`,
            null,
            {
                index,
                sessionId,
                visible
            },
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        this.getFileList();
    };

    render() {
        const { formatMessage, isDisabledCRUD } = this.props;
        const { fileList, backgrounds, current, preset } = this.state;

        return (
            <Tabs tabPosition='right'>
                <TabPane key='photo' tab={<FormattedMessage id='web_settings.photo' />}>
                    <div
                        style={{
                            maxWidth: '55%',
                            marginBottom: 16,
                            marginLeft: '24%'
                        }}
                    >
                        <Dragger
                            action={this.updateLogo}
                            beforeUpload={file => {
                                const isPNG = file.type === 'image/png';
                                const isJPG = file.type === 'image/jpeg';
                                if (!isPNG && !isJPG) {
                                    notification.error({
                                        message: `${file.name} ${formatMessage({
                                            id: 'photo_modal.is_not_an_image'
                                        })}!`
                                    });
                                }

                                return isPNG || isJPG;
                            }}
                            disabled={isDisabledCRUD}
                            showUploadList={false}
                        >
                            <p className='ant-upload-drag-icon'>
                                <InboxOutlined />
                            </p>
                            <p className='ant-upload-text'>
                                <FormattedMessage id='web_settings.click_or_drag' />
                            </p>
                            <p className='ant-upload-hint'>
                                <FormattedMessage id='web_settings.upload_desc' />
                            </p>
                        </Dragger>
                    </div>
                    <Image.PreviewGroup>
                        {fileList.map(({ image, id, visible }, index) => (
                            <div
                                style={{
                                    display: 'inline-block',
                                    margin: 8
                                }}
                            >
                                <Image key={id} height={160} src={`//s1.car-book.com${image.path}`} width={280} />
                                <div
                                    style={{
                                        marginTop: 8,
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Popconfirm
                                        onConfirm={() => {
                                            this.deleteMedia(id);
                                        }}
                                        title={<FormattedMessage id='general_settings_delete_question' />}
                                    >
                                        <Button
                                            disabled={isDisabledCRUD}
                                            icon={
                                                <DeleteOutlined
                                                    style={{
                                                        fontSize: 18,
                                                        marginRight: 8
                                                    }}
                                                />
                                            }
                                        >
                                            <FormattedMessage id='delete' />
                                        </Button>
                                    </Popconfirm>
                                    <Button
                                        disabled={isDisabledCRUD}
                                        icon={
                                            visible ? (
                                                <EyeOutlined
                                                    style={{
                                                        fontSize: 24
                                                    }}
                                                />
                                            ) : (
                                                <EyeInvisibleOutlined
                                                    style={{
                                                        fontSize: 24
                                                    }}
                                                />
                                            )
                                        }
                                        onClick={() => {
                                            this.visibleChange(id, index, !visible);
                                        }}
                                    />
                                </div>{' '}
                            </div>
                        ))}
                    </Image.PreviewGroup>
                </TabPane>
                <TabPane key='background_photo' tab={<FormattedMessage id='background_photo' />}>
                    {current && (
                        <div>
                            <Image src={`//s1.car-book.com${current.path}`} width='100%' />
                        </div>
                    )}
                    <div
                        style={{
                            maxWidth: '55%',
                            marginBottom: 16,
                            marginLeft: '24%',
                            marginTop: 16
                        }}
                    >
                        <Dragger
                            action={this.updateBackground}
                            beforeUpload={file => {
                                const isPNG = file.type === 'image/png';
                                const isJPG = file.type === 'image/jpeg';
                                if (!isPNG && !isJPG) {
                                    notification.error({
                                        message: `${file.name} ${formatMessage({
                                            id: 'photo_modal.is_not_an_image'
                                        })}!`
                                    });
                                }

                                return isPNG || isJPG;
                            }}
                            disabled={isDisabledCRUD}
                            showUploadList={false}
                        >
                            <p className='ant-upload-drag-icon'>
                                <InboxOutlined />
                            </p>
                            <p className='ant-upload-text'>
                                <FormattedMessage id='web_settings.click_or_drag' />
                            </p>
                            <p className='ant-upload-hint'>
                                <FormattedMessage id='web_settings.upload_desc' />
                            </p>
                        </Dragger>
                    </div>
                    <Image.PreviewGroup>
                        {backgrounds.map(({ image, id }, index) => (
                            <div
                                style={{
                                    display: 'inline-block',
                                    margin: 8
                                }}
                            >
                                <Image key={id} height={130} src={`//s1.car-book.com${image.path}`} width={280} />
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: 8
                                    }}
                                >
                                    <Button
                                        disabled={isDisabledCRUD}
                                        onClick={() => {
                                            this.setBackgrounds(id, index);
                                        }}
                                    >
                                        <FormattedMessage id='background_photo.to_use' />
                                    </Button>
                                    <Popconfirm
                                        onConfirm={() => {
                                            this.deleteBackground(id);
                                        }}
                                        title={<FormattedMessage id='general_settings_delete_question' />}
                                    >
                                        <Button
                                            disabled={isDisabledCRUD}
                                            icon={
                                                <DeleteOutlined
                                                    style={{
                                                        fontSize: 18,
                                                        marginRight: 8
                                                    }}
                                                />
                                            }
                                        >
                                            <FormattedMessage id='delete' />
                                        </Button>
                                    </Popconfirm>
                                </div>
                            </div>
                        ))}
                    </Image.PreviewGroup>
                    <Image.PreviewGroup>
                        {preset.map(({ image, id }, index) => (
                            <div
                                style={{
                                    display: 'inline-block',
                                    margin: 8
                                }}
                            >
                                <Image key={id} height={130} src={`//s1.car-book.com${image.path}`} width={280} />
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: 8
                                    }}
                                >
                                    <Button
                                        disabled={isDisabledCRUD}
                                        onClick={() => {
                                            this.setCurrent(index);
                                        }}
                                    >
                                        <FormattedMessage id='background_photo.to_use' />
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </Image.PreviewGroup>
                </TabPane>
            </Tabs>
        );
    }
}
