// core
import { TrademarkOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { images } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

@connect(mapStateToProps, mapDispatchToProps)
class Footer extends Component {
    static defaultProps = {
        startYear: 2015,
        currentYear: new Date().getFullYear()
    };

    render() {
        const { startYear, currentYear, collapsed, user } = this.props;

        return (
            <Layout.Footer className={`${Styles.footer} ${collapsed && Styles.footerCollapsed}`}>
                <div className={Styles.contacts}>
                    <FormattedMessage id='footer.support' />
                    :&nbsp;
                    <a className={Styles.link} href='tel:380443893355'>
                        +38(044) 389-3355
                    </a>
                    &nbsp;
                    <FormattedMessage id='or' />
                    &nbsp;
                    <a className={Styles.link} href='mailto:support@carbook.pro'>
                        support@carbook.pro
                    </a>
                    &nbsp;
                </div>
                <div className={Styles.copyright}>
                    <Link className={Styles.link} to={book.userAgreement}>
                        <FormattedMessage id='user_agreement' />
                    </Link>
                    <img
                        alt='logo'
                        className={Styles.logo}
                        src={user.primaryLogoUrl || images.carbookLogo2}
                        style={{ margin: 8 }}
                    />
                    <div>
                        {`${startYear} - ${currentYear} `}
                        <TrademarkOutlined />
                    </div>
                </div>
            </Layout.Footer>
        );
    }
}

export default Footer;
