import {
    CaretDownOutlined,
    CaretUpOutlined,
    DollarOutlined,
    FilterFilled,
    InfoOutlined,
    RedoOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { Button, Input, Modal, Select, Table, Tag, Tooltip, notification } from 'antd';
import { Loader, Numeral } from 'commons';
import { DateRangePicker, DebtsPairComponent } from 'components';
import {
    fetchClientMRDs,
    setCashOrderEntityIsFetching,
    setCashOrderModalMounted,
    setClientMRDsPage,
    setFilterDate,
    setFilters
} from 'core/clientMRDs/duck';
import { clearCashOrderForm } from 'core/forms/cashOrderForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { DebtPairResultsModal, RefactoredCashOrderModal, UnpairAllActionsBtnsModal } from 'modals';
import { CASH_ORDER_TYPES, COUNTERPARTY_TYPES } from 'modals/RefactoredCashOrderModal/constants';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI, getCurrency, journalTypes } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const DEF_DATE_FORMAT = 'DD/MM/YYYY';

const mapStateToProps = state => ({
    isFetching: state.ui.clientMRDsFetching,
    user: state.auth,
    modal: state.modals.modal,
    modalProps: state.modals.modalProps,
    datetime: state.clientMRDs.filter.datetime,
    cashOrderEntity: state.clientMRDs.cashOrderEntity,
    clientMRDsPage: state.clientMRDs.filter.page,
    pageSize: state.clientMRDs.filter.pageSize,
    mrds: state.clientMRDs.mrds,
    cashOrderModalMounted: state.clientMRDs.cashOrderModalMounted,
    cashOrderEntityIsFetching: state.clientMRDs.cashOrderEntityIsFetching,
    filterSign: state.clientMRDs.filter.filterSign,
    filterDocumentType: state.clientMRDs.filter.filterDocumentType,
    datetimeFrom: state.clientMRDs.filter.datetimeFrom,
    datetimeTo: state.clientMRDs.filter.datetimeTo,
    nonZeroRows: state.clientMRDs.filter.nonZeroRows
});

const mapDispatchToProps = {
    setModal,
    resetModal,
    fetchClientMRDs,
    fetchAPI,
    setClientMRDsPage,
    setFilterDate,
    setCashOrderModalMounted,
    setCashOrderEntityIsFetching,
    clearCashOrderForm,
    setFilters
};

const dateFormat = 'DD/MM/YYYY';
const { confirm } = Modal;
const { Option } = Select;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class EmployeeDebtContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allDebt: [],
            page: 1,
            pageSize: 25,
            stats: [],
            pairSelectedIds: []
        };

        this.columns = () => [
            {
                title: () => (
                    <Button
                        disabled
                        // disabled={isForbidden(this.props.user, permissions.UPDATE_SUCCESS_ORDER)}
                        onClick={() => {
                            this.setState({ visibleUnpairAllActionsBtnsModal: true });
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='navigation.unpair_all_button' />
                    </Button>
                ),
                key: 'pairCol',
                width: '15%',
                render: row => (
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <DebtsPairComponent
                            createPair={this.createPair}
                            disabled={
                                !isGrantAccessed(
                                    this.props.user,
                                    grants.DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS_PAIRING_AND_UNPAIRING
                                )
                            }
                            id={row.rowId}
                            onPair={this.onPair}
                            onUnpair={this.deletePair}
                            pairSelectedIds={this.state.pairSelectedIds}
                            pairSign={this.state.pairSign}
                            remainSum={row.remaining}
                            singleType={this.state.singleType}
                            {...row}
                        />
                        <Tooltip title={<FormattedMessage id='navigation.paired_documents' />}>
                            <Button
                                disabled={
                                    !isGrantAccessed(
                                        this.props.user,
                                        grants.DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS,
                                        accesses.ROWO
                                    )
                                }
                                icon={<InfoOutlined />}
                                onClick={() => {
                                    this.setState({ visibleDebtPairResultsModal: true, infoRow: row });
                                }}
                                size='large'
                                style={{
                                    marginLeft: 8
                                }}
                            />
                        </Tooltip>
                    </div>
                )
            },
            {
                title: <FormattedMessage id='storage_document.document' />,
                key: 'documentNum',
                dataIndex: 'documentNum',
                render: (documentNum, data) => {
                    const salId = documentNum ? _.last(documentNum.split('-')) : undefined;

                    return data.orderId ? (
                        <Link style={{ color: 'var(--link)', fontWeight: 'bold' }} to={`${book.order}/${data.orderId}`}>
                            {documentNum}
                        </Link>
                    ) : data.storeDocId && isGrantAccessed(this.props.user, grants.WAREHOUSE_DOCUMENT) ? (
                        <Link
                            style={{ color: 'var(--link)', fontWeight: 'bold' }}
                            to={`${book.storageDocument}/${data.storeDocId}`}
                        >
                            {documentNum}
                        </Link>
                    ) : data.storeDocId ? (
                        documentNum
                    ) : data.cashOrderId ? (
                        <div>
                            <Link
                                style={{ color: 'var(--link)', fontWeight: 'bold' }}
                                to={{
                                    pathname: book.cashFlowPage,
                                    state: { cashOrderId: documentNum }
                                }}
                            >
                                {documentNum}
                            </Link>
                        </div>
                    ) : (
                        <Link
                            style={{ color: 'var(--link)', fontWeight: 'bold' }}
                            to={{
                                pathname: `${book.salariesPage}/${salId}`
                            }}
                        >
                            {documentNum}
                        </Link>
                    );
                }
            },
            {
                title: <FormattedMessage id='client_container.document_type' />,
                key: 'documentType',
                width: 150,
                align: 'center',
                dataIndex: 'documentType',
                ...this.getColumnSearchProps('filterDocumentType'),
                render: (documentType, data) => {
                    return data.orderId ? (
                        <Tooltip
                            title={
                                <div>
                                    {documentType} -{' '}
                                    <FormattedMessage id={`storage_document.docType.${documentType}`} />
                                </div>
                            }
                        >
                            <div>{documentType}</div>
                        </Tooltip>
                    ) : data.storeDocId ? (
                        <Tooltip
                            title={
                                <div>
                                    {documentType} -{' '}
                                    <FormattedMessage id={`storage_document.docType.${documentType}`} />
                                </div>
                            }
                        >
                            <div>{documentType}</div>
                        </Tooltip>
                    ) : (
                        <div>
                            <Tooltip
                                title={
                                    <div>
                                        {documentType} -{' '}
                                        <FormattedMessage id={`storage_document.docType.${documentType}`} />
                                    </div>
                                }
                            >
                                <div>{documentType}</div>
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_journal.operation_type' />,
                key: 'sign',
                width: 150,
                align: 'center',
                dataIndex: 'sign',
                ...this.getColumnSearchProps('filterSign'),
                render: (key, data) => {
                    const sign = _.get(data, 'sign');

                    return (
                        <div>
                            <Tag color={sign === '+' ? 'var(--green)' : 'var(--warning)'}>
                                {this.props.intl.formatMessage({
                                    id: `storage.${sign === '+' ? 'sign_+' : 'sign_-'}`
                                })}
                            </Tag>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='client-mrds-table.date' />,
                key: 'dateTime',
                dataIndex: 'dateTime',
                ...this.getColumnSearchProps('datetimeFrom'),
                render: date => dayjs(date).format('DD.MM.YYYY')
            },
            {
                title: <FormattedMessage id='client-mrds-table.amount' />,
                key: 'sum',
                align: 'right',
                render: ({ sum, sign }) => {
                    const strVal = Number(sum).toFixed(2);

                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            {sign === '+' ? (
                                <CaretUpOutlined style={{ color: 'var(--enabled)' }} />
                            ) : (
                                <CaretDownOutlined style={{ color: 'var(--disabled)' }} />
                            )}

                            <div>
                                {sign}
                                {sum ? `${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0}
                            </div>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='client-mrds-table.due_amount' />,
                key: 'remaining',
                dataIndex: 'remaining',
                align: 'right',
                render: (oweAmount, row) => {
                    const sign = _.get(row, 'sign');
                    const strVal = Number(oweAmount).toFixed(2);

                    const split = String(row.documentNum).split('-');
                    const orderId = row.documentNum ? split[2] : null;

                    return (
                        <React.Fragment>
                            {Boolean(oweAmount) && oweAmount > 0 && (
                                <DollarOutlined
                                    className='dollar-icon'
                                    disabled={
                                        !isGrantAccessed(
                                            this.props.user,
                                            grants.DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS,
                                            accesses.ROWO
                                        )
                                    }
                                    onClick={() => {
                                        this.showCashOrderModal({ ...row, orderId });
                                    }}
                                />
                            )}
                            {oweAmount > 0 ? sign : undefined}
                            <span>{oweAmount ? `${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0}</span>
                        </React.Fragment>
                    );
                }
            }
        ];
    }

    componentDidMount() {
        this.getAllEmployeeDebt();
    }

    // Event: date was changed in datapicker
    // onDatePicker(date) {
    //     const { clientId } = this.props;

    //     this.props.setFilterDate(date);
    //     this.props.fetchClientMRDs({ clientId });
    // }

    getAllEmployeeDebt = async () => {
        const { employeeId } = this.props;
        const { page, pageSize, filterDocumentType, filterSign, datetimeFrom, datetimeTo } = this.state;
        const debts = await fetchAPI(
            'GET',
            '/report/debts',
            { counterpartId: employeeId, page, pageSize, filterDocumentType, filterSign, datetimeFrom, datetimeTo },
            null,
            {
                handleErrorInternally: true
            }
        );
        this.setState({
            allDebt: debts.list.debsObject,
            stats: debts.list ? debts.list.stats[0] : undefined,
            oweAmount: debts.stats.oweAmount
        });
    };

    showCashOrderModal = row => {
        const { employeeId, client, setModal } = this.props;
        const { remaining, orderId, documentType, storeDocId, sign, counterpartId } = row;

        if (remaining > 0) {
            setModal(MODALS.REFACTORED_CASH_ORDER, {
                initValues: {
                    type: sign === '-' ? CASH_ORDER_TYPES.EXPENSE : CASH_ORDER_TYPES.INCOME,
                    counterpartyType: COUNTERPARTY_TYPES.EMPLOYEE,
                    employeeId: counterpartId,
                    orderId: storeDocId || documentType == 'SAL' ? undefined : orderId,
                    analyticsUniqueId: sign === '+' ? 87 : 31,
                    sum: remaining,
                    signType: sign,
                    clientStoreDocId: storeDocId
                },
                onCashOrderCreatedCallback: () => this.getAllEmployeeDebt()
            });
        }
    };

    createPair = async () => {
        const { user } = this.props;
        const { pairSelectedIds, pairSign: cashOrderSign, singleType } = this.state;

        await fetchAPI('GET', 'debts/pairing', {
            cashOrderSign: singleType === 'CASH' ? cashOrderSign : cashOrderSign === '+' ? '-' : '+',
            pairingIds: cashOrderSign === '+' ? pairSelectedIds.slice(1) : [pairSelectedIds[0]],
            cashOrderIds: cashOrderSign === '+' ? [pairSelectedIds[0]] : pairSelectedIds.slice(1),
            managerId: user.employeeId
        });
        this.setState({
            pairSelectedIds: [],
            pairSign: undefined
        });
        this.getAllEmployeeDebt();
    };

    deletePair = async ({ pairingIds, cashOrderSign, cashOrderId }) => {
        try {
            await fetchAPI(
                'DELETE',
                'debts/pairing',
                {
                    pairingIds,
                    cashOrderSign,
                    cashOrderId
                },
                null,
                {
                    handleErrorInternally: true
                }
            );
            this.setState({
                pairSelectedIds: [],
                pairSign: undefined
            });
            this.getAllEmployeeDebt();
        } catch (err) {
            notification.error({
                message: this.props.intl.formatMessage({ id: 'client-mrds-table.unpair_error' })
            });
        }
    };

    unpairAll = async () => {
        const { employeeId } = this.props;

        // await fetchAPI('DELETE', 'debts/client/unpair', {
        //     employeeId
        // });
        // this.getAllEmployeeDebt();
    };

    onPair = ({ id, sign, cashOrderId }) => {
        const { pairSelectedIds, pairSign, singleType } = this.state;

        if (pairSelectedIds.includes(id)) {
            this.setState({
                pairSelectedIds: pairSelectedIds.filter(el => el !== id),
                pairSign: pairSelectedIds.length > 1 ? pairSign : undefined,
                singleType: pairSelectedIds.length > 1 ? singleType : undefined
            });
        } else {
            pairSelectedIds.push(id);
            this.setState({
                pairSelectedIds,
                pairSign: pairSign || sign,
                singleType: singleType || cashOrderId ? 'CASH' : 'ORDER'
            });
        }
    };

    getColumnSearchProps = dataIndex => {
        let filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.props.setFilters({ filterDocumentType: e.target.value });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                style={{ marginBottom: 8, display: 'block', width: 180 }}
                value={this.props.filterDocumentType}
            />
        );

        if (dataIndex === 'datetimeFrom') {
            filterComponent = (confirm, clearFilters) => (
                <div style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        dateRange={[
                            this.state.datetimeFrom ? dayjs(this.state.datetimeFrom) : undefined,
                            this.state.datetimeTo ? dayjs(this.state.datetimeTo) : undefined
                        ]}
                        format='YYYY-MM-DD'
                        getPopupContainer={trigger => trigger.parentNode}
                        onDateChange={async dateRange => {
                            await this.setState({
                                datetimeFrom: dayjs(dateRange[0]).format('YYYY-MM-DD'),
                                datetimeTo: dayjs(dateRange[1]).format('YYYY-MM-DD')
                            });
                            this.handleSearch(confirm, dataIndex);
                            this.getAllEmployeeDebt();
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            );
        }

        if (dataIndex === 'filterDocumentType') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({
                            filterDocumentType: value
                        });
                    }}
                    // mode='multiple'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_journal.choose_type'
                    })}
                    style={{ marginBottom: 8, display: 'block', width: 275 }}
                    value={this.state.filterDocumentType}
                >
                    {journalTypes.map(type => (
                        <Option key={type} value={type}>
                            {type} - <FormattedMessage id={`storage_document.docType.${type}`} />
                        </Option>
                    ))}
                </Select>
            );
        }

        if (dataIndex === 'filterSign') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={value => {
                        this.setState({
                            filterSign: value
                        });
                    }}
                    // mode='multiple'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_journal.choose_type'
                    })}
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                    value={this.state.filterSign}
                >
                    <Option value='+'>
                        <FormattedMessage id='storage.sign_+' />
                    </Option>
                    <Option value='-'>
                        <FormattedMessage id='storage.sign_-' />
                    </Option>
                </Select>
            );
        }

        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}
                    {dataIndex !== 'datetimeFrom' && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Button
                                icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                onClick={() => this.handleSearch(confirm, dataIndex)}
                                size='small'
                                type='primary'
                            >
                                <FormattedMessage id='search' />
                            </Button>
                            <Button onClick={() => this.handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                <FormattedMessage id='reset' />
                            </Button>
                        </div>
                    )}
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.state[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = async confirm => {
        const { pageSize } = this.state;
        confirm();
        await this.setState({ pageSize });
        this.getAllEmployeeDebt();
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.setState({ [dataIndex]: undefined, page: 1 });
        this.getAllEmployeeDebt();
    };

    render() {
        const {
            isFetching,
            user,
            intl: { formatMessage }
        } = this.props;

        const {
            allDebt,
            page,
            pageSize,
            visibleUnpairAllActionsBtnsModal,
            visibleDebtPairResultsModal,
            pairSelectedIds,
            pairSign,
            infoRow,
            stats,
            oweAmount
        } = this.state;

        if (isFetching) {
            return <Loader loading={isFetching} />;
        }

        const pagination = {
            pageSize,
            showSizeChanger: true,
            size: 'small',
            total: Math.ceil(_.get(stats, 'count', 0) / pageSize) * pageSize,
            current: page,
            hideOnSinglePage: false,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.getAllEmployeeDebt();
            }
        };

        // const pagination = {
        //     pageSize,
        //     total: Math.ceil(_.get(stats, 'count', 0) / pageSize) * pageSize,
        //     hideOnSinglePage: true,
        //     current: page,
        //     onChange: async (page, pageSize) => {
        //         await this.setState({ page, pageSize });
        //         this.getStoreDocProducts();
        //     }
        // };

        const isGrantedAccess = !isGrantAccessed(
            user,
            grants.DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS,
            accesses.ROWO
        );

        return (
            <React.Fragment>
                <div className={Styles.headerContainer}>
                    <Tooltip title={<FormattedMessage id='storage.reset_all_filters' />}>
                        <Button
                            disabled={isGrantedAccess && !isGrantedAccess(user, grants.DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS_CORRECTION)}
                            icon={<RedoOutlined />}
                            onClick={async () => {
                                await this.setState({
                                    dateRange: undefined,
                                    filterSign: undefined,
                                    page: 1,
                                    datetimeFrom: undefined,
                                    datetimeTo: undefined,
                                    filterDocumentType: undefined
                                });
                                this.getAllEmployeeDebt();
                            }}
                        />
                    </Tooltip>
                </div>

                <Table
                    columns={this.columns()}
                    dataSource={allDebt}
                    pagination={pagination}
                    scroll={{
                        x: 800,
                        y: '60vh'
                    }}
                    summary={() => {
                        return (
                            <React.Fragment>
                                <Table.Summary.Row fixed>
                                    <Table.Summary.Cell className={Styles.hidden} index={0}></Table.Summary.Cell>
                                    <Table.Summary.Cell className={Styles.hidden} index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell className={Styles.hidden} index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell className={Styles.hidden} index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell className={Styles.hidden} index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell className={Styles.summaryWrap} index={5}>
                                        <div className={Styles.summary}>
                                            <Numeral mask='0,0.00'>{_.get(oweAmount, 'sum', [])}</Numeral>
                                            {getCurrency()}
                                        </div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className={Styles.summaryWrap} index={6}>
                                        <div className={Styles.summary}>
                                            <Numeral mask='0,0.00'>{_.get(oweAmount, 'debt', [])}</Numeral>
                                            {getCurrency()}
                                        </div>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </React.Fragment>
                        );
                    }}
                />

                <RefactoredCashOrderModal />
                <UnpairAllActionsBtnsModal
                    hideModal={() => {
                        this.setState({ visibleUnpairAllActionsBtnsModal: false });
                    }}
                    row={visibleUnpairAllActionsBtnsModal}
                    unpairAll={this.unpairAll}
                    visible={Boolean(visibleUnpairAllActionsBtnsModal)}
                />
                <DebtPairResultsModal
                    hideModal={() => {
                        this.setState({ visibleDebtPairResultsModal: false });
                    }}
                    row={infoRow}
                    visible={Boolean(visibleDebtPairResultsModal)}
                />
            </React.Fragment>
        );
    }
}
