import { get } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// import locale from 'antd/es/locale/de_DE';

const { ConfigProvider } = require('antd');
const { setLocaleProvider } = require('utils');

const mapStateToProps = state => ({
    auth: state.auth
});

const ConfigProviderExtended = ({ children, auth }) => {
    useEffect(() => {
        if (auth && auth.palette) {
            Object.entries(auth.palette).forEach(([key, value]) => {
                document.documentElement.style.setProperty(`--${key}`, value);
            });
        }
    }, [auth]);

    return (
        <ConfigProvider
            locale={setLocaleProvider()}
            theme={{
                token: {
                    colorPrimary: get(auth, 'palette.primary', '#9b59b6'),
                    hashed: false
                }
            }}
        >
            {children}
        </ConfigProvider>
    );
};

export default connect(mapStateToProps)(ConfigProviderExtended);
