import { get } from 'lodash';
import { Resizable } from 're-resizable';
import React, { Component } from 'react';
import { isForbidden, permissions } from 'utils';
import { ordersStatus } from '../dashboardConfig';
import DashboardOrderDragSource from './DashboardOrderDragSource';

export default class DashboardOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resizing: false,
            height: 30
        };
    }

    componentDidMount() {
        // this.resizable.resizable.getBoundingClientRect().height

        this.setState({
            height: this.props.rows * 30
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rows * 30 !== this.props.rows * 30) {
            this.setState({
                height: this.props.rows * 30
            });
        }
    }

    _setResizeOrderState = () => this.setState({ resizing: !this.state.resizing });

    _resizeOrder = async (event, direction, ref, delta) => {
        // step in 30 equal with dashboard ROW_HEIGHT / minutes in one hour
        const {
            options: { stationLoadId, laborId, orderId, beginDatetime },
            dropOrder
        } = this.props;
        const resizedDuration = (this.state.height + delta.height) / 60;

        await this.setState({ height: this.state.height + delta.height });
        // id has to be equal for this.props.options.stationLoadId
        dropOrder({ duration: resizedDuration, stationLoadId, laborId, orderId, beginDatetime });
    };

    render() {
        const {
            x,
            y,
            columns,
            rows,
            status,
            dashboardRef,
            user
            // hideSourceOnDrag,
        } = this.props;
        const { resizing } = this.state;

        const canUpdate =
            !isForbidden(user, permissions.EDIT_DASHBOARD_ORDER) && !isForbidden(user, permissions.ACCESS_ORDER_BODY);

        const resizableStyles = {
            gridRow: `${x + 1} / span ${rows}`,
            gridColumn: `${y + 1} / span ${columns}`,
            borderRight: '1px solid white',
            borderLeft: '1px solid white',
            background: this.props.user.orderStatusNewVersion
                ? get(this.props, 'options.subStatusColor')
                : ordersStatus(this.props.status)
        };

        const resizingStyles = {
            gridRow: `${x + 1} / span ${rows}`,
            gridColumn: `${y + 1} / span ${columns}`,
            background: this.props.user.orderStatusNewVersion
                ? get(this.props, 'options.subStatusColor')
                : ordersStatus(this.props.status),
            opacity: 0.5,
            borderRight: '1px solid white',
            borderLeft: '1px solid white',
            zIndex: 10
        };

        return (
            <Resizable
                ref={c => {
                    this.resizable = c;
                }}
                bounds={dashboardRef.current}
                defaultSize={{ witdh: 'auto' }}
                enable={{
                    top: false,
                    right: false,
                    bottom: canUpdate && status !== 'success',
                    left: false,
                    topRight: false,
                    bottomRight: false,
                    bottomLeft: false,
                    topLeft: false
                }}
                // size={ { width: 'auto' } }
                grid={[void 0, 30]}
                minHeight={30}
                // size={ { width: this.state.width, height: this.state.height } }
                minWidth={0}
                onResizeStart={() => {
                    this._setResizeOrderState();
                }}
                onResizeStop={(event, direction, ref, delta) => {
                    this._setResizeOrderState();
                    this._resizeOrder(event, direction, ref, delta);
                }}
                style={resizing ? resizingStyles : resizableStyles}
            >
                <DashboardOrderDragSource {...this.props} />
            </Resizable>
        );
    }
}
