/* eslint-disable no-plusplus */
import { DiffOutlined, FilterFilled, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Menu, Space, Table, Tag } from 'antd';
import { Layout, Numeral } from 'commons';
import { DateRangePicker, HamburgerMenu } from 'components';
import { _ } from 'core-js';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const dateFormat = 'YYYY-MM-DD';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class JournalVATPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vat: [],
            page: 1,
            pageSize: 25,
            selectedRowKeys: [],
            selectedRows: []
        };

        this.columns = () => [
            {
                title: '№',
                key: 'index',
                width: '3%',
                // ...this.getColumnSearchProps('filterOrder'),
                render: (data, row, index) => {
                    return (
                        <div>
                            <div>{index + 1}.</div>{' '}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_document.counterparty' />,
                key: 'counterparty',
                width: '10%',
                // ...this.getColumnSearchProps('filterOrder'),
                render: (data, row) => {
                    return (
                        <div>
                            <div>{row.counterpartName}</div>{' '}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='vat_page.subject' />,
                key: 'subject',
                width: '15%',
                // ...this.getColumnSearchProps('filterOrder'),
                render: (data, row) => {
                    return _.get(row, 'objects[0].subjectType', []) == 'MRD' ? (
                        <div>
                            <a href={`${book.order}/${row.id}`} style={{ color: 'var(--link)', fontWeight: 'bold' }}>
                                {row.subject}
                            </a>
                        </div>
                    ) : isGrantAccessed(this.props.user, grants.WAREHOUSE_DOCUMENT) ? (
                        <div>
                            <a
                                href={`${book.storageDocument}/${row.id}`}
                                style={{ color: 'var(--link)', fontWeight: 'bold' }}
                            >
                                {row.subject}
                            </a>
                        </div>
                    ) : (
                        <div>
                            <div>{row.subject}</div>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='date' />,
                key: 'data',
                width: '10%',
                ...this.getColumnSearchProps('startDate'),
                render: (data, row) => {
                    return (
                        <div>
                            <div>
                                {_.get(row, 'objects[0].date', [])
                                    ? dayjs(_.get(row, 'objects[0].date', [])).format('DD.MM.YYYY')
                                    : '-'}
                            </div>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='vat_page.vat_type' />,
                key: 'vat_type',
                width: '10%',
                render: (data, row) => (
                    <div>
                        <Tag color={_.get(row, 'objects[0].vatType', []) === 'VAR' ? 'var(--green)' : 'var(--warning)'}>
                            <FormattedMessage id={`vat_page.${_.get(row, 'objects[0].vatType', [])}`} />{' '}
                        </Tag>
                    </div>
                )
            },
            {
                title: <FormattedMessage id='vat_page.vat_direction' />,
                key: 'dir',
                width: '10%',
                render: (data, row) => (
                    <div>
                        <div>
                            <FormattedMessage id={`vat_page.${_.get(row, 'objects[0].direction', [])}`} />{' '}
                        </div>
                    </div>
                )
            },
            {
                title: <FormattedMessage id='client_container.document_type' />,
                key: 'doc_type',
                width: '10%',
                render: (data, row) => <div>{_.get(row, 'objects[0].subjectType', [])} </div>
            },
            {
                title: <FormattedMessage id='vat_page.doc_sum' />,
                key: 'doc_sum',
                width: '15%',
                render: (data, row) => (
                    <div>
                        {_.get(row, 'objects[0].subjectSum', []) ? (
                            <Numeral mask='0,0.00'>{Number(_.get(row, 'objects[0].subjectSum', []))}</Numeral>
                        ) : (
                            '0'
                        )}{' '}
                        ₴
                    </div>
                )
            },
            {
                title: <FormattedMessage id='vat_page.vat_sum' />,
                key: 'vat_sum',
                width: '15%',
                render: (data, row) => (
                    <div>
                        {_.get(row, 'objects[0].vatSum', []) ? (
                            <Numeral mask='0,0.00'>{Number(_.get(row, 'objects[0].vatSum', []))}</Numeral>
                        ) : (
                            '0'
                        )}{' '}
                        ₴
                    </div>
                )
            },
            {
                title: <FormattedMessage id='vat_page.registered' />,
                key: 'registered',
                width: '10%',
                render: (data, row) => (
                    <Space>
                        {!_.get(row, 'objects[0].registred', []) ? (
                            <FormattedMessage id='no' />
                        ) : (
                            <FormattedMessage id='yes' />
                        )}
                    </Space>
                )
            }
        ];
    }

    componentDidMount() {
        this.getVATInfo();
    }

    // calcSalary = async (id, sDate, eDate) => {
    //     const calculatedSalary = await fetchAPI(
    //         'POST',
    //         '/recalculate/employee/salary',
    //         undefined,
    //         {
    //             employeeId: id,
    //             startDate: sDate,
    //             endDate: eDate,
    //             status: 'ACCRUED'
    //         },
    //         { handleErrorInternally: true }
    //     );
    //     this.setState({
    //         calculatedSalary
    //     });
    //     this.getVATInfo();
    // };

    getVATInfo = async () => {
        const { page, pageSize, startDate, endDate } = this.state;
        const data = await fetchAPI('GET', '/business/vat/journal', { page, pageSize, startDate, endDate }, null, {
            handleErrorInternally: true
        });
        this.setState({
            vat: data.vatJournal,
            count: data.count
        });
    };

    getAllEmployees = async () => {
        const employees = await fetchAPI('GET', 'employees', null, null, { handleErrorInternally: true });
        this.setState({
            allEmployees: employees
        });
    };

    getColumnSearchProps = dataIndex => {
        const { startDate, endDate } = this.state;
        let filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.setState({
                        [dataIndex]: e.target.value
                    });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                style={{ marginBottom: 8, display: 'block', width: 180 }}
                value={this.state[dataIndex]}
            />
        );

        if (dataIndex === 'startDate') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        dateRange={[startDate ? dayjs(startDate) : undefined, endDate ? dayjs(endDate) : undefined]}
                        format={dateFormat}
                        getPopupContainer={trigger => trigger.parentNode}
                        onDateChange={async dateRange => {
                            await this.setState({
                                startDate: dayjs(dateRange[0]).format(dateFormat),
                                endDate: dayjs(dateRange[1]).format(dateFormat)
                            });
                            this.handleSearch(confirm, dataIndex);
                            this.getVATInfo();
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            );
        }

        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}
                    {dataIndex !== 'dateRange' && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Button
                                icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                onClick={() => this.handleSearch(confirm, dataIndex)}
                                size='small'
                                type='primary'
                            >
                                <FormattedMessage id='search' />
                            </Button>
                            <Button onClick={() => this.handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                <FormattedMessage id='reset' />
                            </Button>
                        </div>
                    )}
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.state[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownOpenChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = async confirm => {
        confirm();
        await this.setState({ page: 1 });
        this.getVATInfo();
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.setState({ [dataIndex]: undefined, page: 1, startDate: undefined, endDate: undefined });
        this.getVATInfo();
    };

    handleRegisterVAT = () => {
        const { user } = this.props;
        const { selectedRows } = this.state;
        const medoc = get(user, 'medoc', {});

        selectedRows.forEach(async ({ subject, status }, index) => {
            if (!status) return;

            const resp = await fetchAPI(
                'POST',
                'api/Document/MakeDoc',
                {
                    userLogin: medoc.login,
                    edrpou: medoc.edrpou,
                    dept: medoc.dept,
                    shablonType: 1, // 1 - Звітний документ (податкові накладні, статистика звіти, та ін.); 2 - Первинний документ.
                    charCode: subject
                },
                null,
                { handleErrorInternally: true, url: medoc.medocUrl }
            );
            selectedRows[index].cardCode = get(resp, 'cardCode');
        });
    };

    handleUpdateStatus = () => {
        const { user } = this.props;
        const { selectedRows } = this.state;
        const medoc = get(user, 'medoc', {});

        selectedRows.forEach(async ({ cardCode }, index) => {
            if (!cardCode) return;

            const resp = await fetchAPI('GET', 'api/Info/GetDocStatus', { cardCode }, null, {
                handleErrorInternally: true,
                url: medoc.medocUrl
            });
            selectedRows[index].status = get(resp, 'status');
        });
    };

    render() {
        const { user } = this.props;
        const { vat, page, pageSize, count, selectedRowKeys } = this.state;

        const medocEnabled = get(user, 'medoc.edrpou', false);

        const pagination = {
            pageSize,
            total: Math.ceil(count / pageSize) * pageSize,
            hideOnSinglePage: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.getVATInfo();
            }
        };

        const actionsMenu = (
            <Menu>
                <Menu.Item
                    key='create'
                    disabled={!selectedRowKeys.length || !medocEnabled}
                    onClick={this.handleRegisterVAT}
                >
                    <Space>
                        <DiffOutlined />
                        <FormattedMessage id='Створити податкову декларацію ПДВ' />
                    </Space>
                </Menu.Item>
                <Menu.Item
                    key='update'
                    disabled={!selectedRowKeys.length || !medocEnabled}
                    onClick={this.handleUpdateStatus}
                >
                    <Space>
                        <RedoOutlined />
                        <FormattedMessage id='orders.update_stage' />
                    </Space>
                </Menu.Item>
            </Menu>
        );

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRows, selectedRowKeys });
            },
            columnTitle: (
                <HamburgerMenu
                    actionsMenu={actionsMenu}
                    destroyPopupOnHide
                    // dropdownProps={{ getPopupContainer: trigger => trigger.parentNode }}
                />
            )
        };

        return (
            <Layout controls={<Space></Space>} paper title={<FormattedMessage id='navigation.journal_vat' />}>
                <Table
                    columns={this.columns()}
                    dataSource={vat}
                    pagination={pagination}
                    rowKey='id'
                    rowSelection={rowSelection}
                />
            </Layout>
        );
    }
}
