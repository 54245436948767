import { Modal } from 'antd';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { OrdersTable } from './components';
import {
    addDetailsToOrder,
    addLaborsToOrder,
    fetchOrders,
    modes,
    selectOrders,
    selectSelectedOrderId,
    selectVehicleId,
    setDetails,
    setLabors,
    setOrdersSearchQuery,
    setSelectedOrderId,
    setVehicleId
} from './redux/duck';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    orders: selectOrders(state),
    selectedOrderId: selectSelectedOrderId(state),
    vehicleId: selectVehicleId(state)
});

const mapDispatchToProps = {
    fetchOrders,
    resetModal,
    setLabors,
    setDetails,
    addLaborsToOrder,
    addDetailsToOrder,
    setSelectedOrderId,
    setVehicleId,
    setOrdersSearchQuery
};

/**
 * This modal is used to add labors or spare parts to an order. Orders are fetched automatically.
 *
 * @property {String|integer} modalProps.mode - One of: "ADD_LABOR", "ADD_DETAIL"
 * @property {String|integer} [modalProps.vehicleId] - Id of a vehicle to sort orders for, if not provided all orders will be available in OrdersTable
 * @property { Array } modalProps.labors - Array of labors you want to add to an order. Used only in "ADD_LABOR" mode.
 * @property { Array } modalProps.details - Array of spare parts you want to add to an order. Used only in "ADD_DETAIL" mode.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AddLaborOrDetailToOrderModal extends Component {
    componentDidMount() {
        this.props.fetchOrders();
    }

    componentDidUpdate(prevProps) {
        const prevVehicleId = _.get(prevProps, 'modalProps.vehicleId');
        const currVehicleId = _.get(this.props, 'modalProps.vehicleId');
        const orderId = _.get(this.props, 'modalProps.orderId');
        const selectedOrderId = _.get(this.props, 'selectedOrderId');

        // Update orders list if filter is provided
        if (prevVehicleId !== currVehicleId) {
            this.props.setVehicleId({ vehicleId: currVehicleId });
        }
    }

    /**
     * Handle submit depending on mode that is currently used.
     * labors and details are provided via modalProps, so we put this data into state only before processing
     * other operations.
     */
    handleSubmit = e => {
        e.preventDefault();
        const {
            modalProps: { mode, labors, details, onConfirm },
            setLabors,
            setDetails
        } = this.props;

        switch (mode) {
            case modes.ADD_LABOR:
                setLabors({ labors });
                this.props.addLaborsToOrder();
                break;
            case modes.ADD_DETAIL:
                setDetails({ details });
                this.props.addDetailsToOrder();
                break;
        }
        if (onConfirm) {
            onConfirm();
        }
        this.onClose();
    };

    onClose = () => {
        this.props.resetModal();
        this.props.setSelectedOrderId({ orderId: undefined }); // Reset
    };

    render() {
        const { visible, selectedOrderId } = this.props;

        return (
            <div>
                <Modal
                    destroyOnClose
                    okButtonProps={{
                        disabled: !selectedOrderId
                    }}
                    onCancel={this.onClose}
                    onOk={this.handleSubmit}
                    open={visible === MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER}
                    title={
                        <div className={Styles.title}>
                            <FormattedMessage id='add_labor_detail_to_order_modal.title' />
                        </div>
                    }
                    width='80%'
                >
                    <div className={Styles.tableCont}>
                        <OrdersTable />
                    </div>
                </Modal>
            </div>
        );
    }
}
