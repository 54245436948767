import { LogoutOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { goTo } from 'utils';
import menuConfig from './menuConfig';
import Styles from './styles.m.css';

const SiderMenu = ({ history, collapsed, isMobile, onCollapse, user, hidden, setCollapsedState, logout }) => {
    const defaultOpenKeys = [];
    const selected = menuConfig.getSelectedByRoute(history.location.pathname);
    if (selected.sectionKey && !defaultOpenKeys.includes(selected.sectionKey)) {
        defaultOpenKeys.push(selected.sectionKey);
    }

    if (!defaultOpenKeys.length && !collapsed) {
        defaultOpenKeys.push('operations');
    }

    const menuItems = useMemo(() => {
        const items = menuConfig.sections
            .filter(({ items, visible }) => items && visible(user))
            .map(section => {
                const { key, icon, name, items, disabled } = section;

                return {
                    label: <FormattedMessage id={name} />,
                    key,
                    icon,
                    children: items
                        .filter(({ visible }) => !visible || visible(user))
                        .map(item => {
                            const { key, link, name, disabled, visible } = item;

                            return {
                                key,
                                label: <FormattedMessage id={name} />,
                                link
                            };
                        })
                };
            });

        if (isMobile) {
            items.push({
                label: <FormattedMessage id='header.logout' />,
                key: 'LOGOUT',
                icon: (
                    <LogoutOutlined
                        style={{
                            paddingRight: 8,
                            fontSize: 16
                        }}
                    />
                )
            });
        }

        return items;
    }, [isMobile, user]);

    return (
        <Layout.Sider
            collapsed={collapsed}
            style={{
                background: '#fff'
            }}
        >
            <Menu
                activeKey={selected.itemKey}
                className={`${Styles.navMenu} ${isMobile && Styles.navMenuMobile}`}
                // defaultOpenKeys={defaultOpenKeys}
                inlineCollapsed={collapsed}
                items={menuItems}
                mode='inline'
                onClick={({ key }) => {
                    if (key === 'LOGOUT') {
                        logout();
                        onCollapse();
                    } else {
                        !isMobile && setCollapsedState(true);
                        goTo(key);
                    }
                }}
                selectedKeys={[selected.itemKey]}
                style={{
                    // position: !isMobile && 'fixed',
                    width: isMobile && 300,
                    maxWidth: '420px',
                    top: 74,
                    left: 0,
                    height: !isMobile && 'calc(100% - 74px)',
                    zIndex: 125,
                    transition: '0.5s',
                    overflowX: 'hidden',
                    overflowY: 'auto'
                }}
                theme='light'
            />
        </Layout.Sider>
    );
};

export default withRouter(SiderMenu);
