import { Button, Checkbox, Flex, InputNumber, Modal, Radio, Space } from 'antd';
import { get, omit } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, getCurrency } from 'utils';
import Styles from './styles.m.css';

const initialData = {
    purchaseCoefficient: 1,
    purchaseFixed: 1,
    sellCoefficient: 1,
    sellFixed: 1,
    sellFixedDiscount: 0,
    sellFixedMarkup: 50,
    sellMinMarkup: 25
};

const UpdatePriceModal = ({ open, ordersAppurtenanciesIds, handleOk, handleClose, intl: { formatMessage } }) => {
    const [inputs, setInputs] = useState(initialData);
    const [recalculateSalesWhenChangingPurchase, updCheckbox] = useState(true);
    const [loading, setLoading] = useState(false);
    const [purchase, setPurchase] = useState('BY_COEFFICIENT');
    const [sell, setSell] = useState('BY_COEFFICIENT');
    const inputRef = useRef(null);

    const onChangePurchase = e => {
        setPurchase(e.target.value);
    };

    const onChangeSell = e => {
        setSell(e.target.value);
    };

    const handleInputsChange = key => value => {
        setInputs({ ...inputs, [key]: value });
    };

    const getProduct = async product => {
        const prices = await fetchAPI(
            'GET',
            '/prices_by_product',
            { productId: get(product, 'productId'), clientId: null, supplierId: null },
            null,
            {
                handleErrorInternally: true
            }
        );
        setInputs(prev => ({ ...prev, ...prices }));
    };

    useEffect(() => {
        if (open) {
            const timeoutId = setTimeout(() => {
                inputRef.current.focus();
            }, 100);

            return () => clearTimeout(timeoutId);
        }
    }, [open]);

    useEffect(() => {
        if (open) {
            setInputs(initialData);
            if (open.length === 1) {
                getProduct(open[0]);
            }
        } else {
            setLoading(false);
        }
    }, [open]);

    const handleFifo = async () => {
        await fetchAPI(
            'PUT',
            '/orders/details/prices',
            null,
            { ordersAppurtenanciesIds, purchase: 'FIFO' },
            {
                handleErrorInternally: true
            }
        );
        handleClose();
        handleOk();
    };

    const handleApi = async () => {
        await fetchAPI(
            'PUT',
            '/orders/details/prices',
            null,
            { ordersAppurtenanciesIds, purchase: 'API' },
            {
                handleErrorInternally: true
            }
        );
        handleClose();
        handleOk();
    };

    const onOk = async () => {
        setLoading(true);
        await fetchAPI(
            'PUT',
            '/orders/details/prices',
            null,
            {
                ordersAppurtenanciesIds,
                ...omit(inputs, [
                    'purchasePriceFromProductCard',
                    'sellingPriceFromProductCard',
                    'purchasePriceLast',
                    'purchasePriceFromSuppliersPrice',
                    'sellingPriceFromSuppliersPrice',
                    'purchasePriceDefaultMarkup',
                    'sellingPriceStandartMarkup'
                ]),
                sell,
                purchase,
                recalculateSalesWhenChangingPurchase
            },
            { handleErrorInternally: true }
        );

        handleClose();
        handleOk();
    };

    return (
        <Modal
            maskClosable={false}
            okButtonProps={{
                loading
            }}
            onCancel={handleClose}
            onOk={onOk}
            open={open}
            title={formatMessage({ id: 'update_price' })}
            width='fit-content'
        >
            <Flex justify='flex-end'>
                <Space>
                    <Button onClick={handleFifo}>FIFO</Button>
                    <Button onClick={handleApi}>API</Button>
                </Space>
            </Flex>
            <Flex justify='space-between'>
                <Space direction='vertical'>
                    <p style={{ fontSize: 16, fontWeight: 700 }}>
                        <FormattedMessage id='Закупочна ціна' />
                    </p>
                    <Radio.Group onChange={onChangePurchase} value={purchase}>
                        <Space direction='vertical'>
                            <Radio value='BY_COEFFICIENT'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='за коефіцієнтом' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            decimalSeparator=','
                                            min={0}
                                            onChange={handleInputsChange('purchaseCoefficient')}
                                            precision={4}
                                            step={0.002}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.purchaseCoefficient}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='FIXED'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='фіксована ціна' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            min={0}
                                            onChange={handleInputsChange('purchaseFixed')}
                                            precision={2}
                                            step={5}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.purchaseFixed}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='STORE_PRODUCT'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='з картки товару' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            disabled
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.purchasePriceFromProductCard}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='FIFO'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='зі складу - FIFO' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            disabled
                                            style={{ width: 160, marginLeft: 10 }}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='LAST'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='зі складу - остання закупка' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            disabled
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.purchasePriceLast}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='PRICELIST'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='із прайса постачальника' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            disabled
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.purchasePriceFromSuppliersPrice}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='API'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='по API постачальника' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            disabled
                                            style={{ width: 160, marginLeft: 10 }}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Checkbox
                                checked={recalculateSalesWhenChangingPurchase}
                                onChange={() => updCheckbox(!recalculateSalesWhenChangingPurchase)}
                            >
                                <FormattedMessage id='перерах.прод.ціни при зміні закупочної' />
                            </Checkbox>
                        </Space>
                    </Radio.Group>
                </Space>
                <Space direction='vertical'>
                    <p style={{ fontSize: 16, fontWeight: 700 }}>
                        <FormattedMessage id='Продажна ціна' />
                    </p>
                    <Radio.Group onChange={onChangeSell} value={sell}>
                        <Space direction='vertical'>
                            <Radio value='BY_COEFFICIENT'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='за коефіцієнтом' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            ref={inputRef}
                                            decimalSeparator=','
                                            min={0}
                                            onChange={handleInputsChange('sellCoefficient')}
                                            precision={4}
                                            step={0.002}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.sellCoefficient}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='FIXED'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='фіксована ціна' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            min={0}
                                            onChange={handleInputsChange('sellFixed')}
                                            precision={2}
                                            step={5}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.sellFixed}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='FIXED_DISCOUNT'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='фіксована знижка' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter='%'
                                            decimalSeparator=','
                                            onChange={handleInputsChange('sellFixedDiscount')}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.sellFixedDiscount}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='STORE_PRODUCT'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='з картки товару' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            disabled
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.sellingPriceFromProductCard}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='STANDART_MARKUP'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='стандартна націнка' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter='%'
                                            decimalSeparator=','
                                            disabled
                                            // onChange={handleInputsChange('sellingPriceStandartMarkup')}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.sellingPriceStandartMarkup}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='FIXED_MARKUP'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='фіксована націнка' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter='%'
                                            decimalSeparator=','
                                            min={0}
                                            onChange={handleInputsChange('sellFixedMarkup')}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.sellFixedMarkup}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='PRICELIST'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='із прайса постачальника' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            disabled
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.sellingPriceFromSuppliersPrice}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio disabled value='API'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='по API постачальника' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            disabled
                                            style={{ width: 160, marginLeft: 10 }}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='MIN_MARKUP'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='мінімальна націнка' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter='%'
                                            decimalSeparator=','
                                            min={0}
                                            onChange={handleInputsChange('sellMinMarkup')}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.sellMinMarkup}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Space>
            </Flex>
        </Modal>
    );
};

export default injectIntl(UpdatePriceModal);
