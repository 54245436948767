export { default as GeneralInfoTab } from './GeneralInfoTab';
export { default as PreviewTab } from './PreviewTab';
export { default as VehicleInspectionIntervalsTab } from './VehicleInspectionIntervalsTab';
export { default as VehicleLaborsTab } from './VehicleLaborsTab';
export { default as VehicleNormHoursTab } from './VehicleNormHoursTab';
export { default as VehicleOdometrTab } from './VehicleOdometrTab';
export { default as VehicleOrdersTab } from './VehicleOrdersTab';
export { default as VehicleRecomendationsTab } from './VehicleRecomendationsTab';
export { default as VehicleSparePartsTab } from './VehicleSparePartsTab';
export { default as VinTab } from './VinTab';

