import {
    AuditOutlined,
    ContactsOutlined,
    ControlOutlined,
    DashboardOutlined,
    DatabaseOutlined,
    InboxOutlined,
    LaptopOutlined,
    LineChartOutlined,
    TeamOutlined
} from '@ant-design/icons';
import React from 'react';
import book from 'routes/book';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

export default {
    sections: [
        /*  APM */
        {
            key: 'APM',
            iconType: 'APM',
            icon: <LaptopOutlined className={Styles.menuIcon} />,
            name: 'navigation.arm',
            visible: user => isGrantAccessed(user, grants.WORKPLACE),
            items: [
                {
                    key: '/new-document',
                    link: book.newDocumentPage,
                    visible: user => isGrantAccessed(user, grants.WORKPLACE_QUICK_NAVIGATION),
                    name: 'navigation.new_document'
                },
                {
                    key: '/barcode',
                    link: book.barcodePage,
                    visible: user => isGrantAccessed(user, grants.WORKPLACE_BARCODES),
                    name: 'navigation.barcode'
                },
                {
                    key: '/journal-page',
                    link: book.journalsPage,
                    visible: user => isGrantAccessed(user, grants.WORKPLACE_JOURNALS),
                    disabled: false,
                    name: 'navigation.journals'
                }
            ]
        },
        /*  Operations submenu */
        {
            key: 'operations',
            iconType: 'dashboard',
            icon: <DashboardOutlined className={Styles.menuIcon} />,
            name: 'navigation.operations',
            visible: user => isGrantAccessed(user, grants.OPERATIONS),
            items: [
                {
                    key: '/dashboard',
                    link: book.dashboard,
                    visible: user => isGrantAccessed(user, grants.OPERATIONS_PLANNER),
                    name: 'navigation.planner'
                },
                {
                    key: '/orders/appointments',
                    link: book.ordersAppointments,
                    visible: user => isGrantAccessed(user, grants.OPERATIONS_ORDER_LIST),
                    name: 'directories.orders'
                },
                {
                    key: '/spare-parts-workplace',
                    link: book.sparePartsWorkplacePage,
                    visible: user => isGrantAccessed(user, grants.OPERATIONS_JOB_DETAILS),
                    name: 'profile.spare_parts_workplace.title'
                },
                {
                    key: '/locations',
                    link: book.locationsPage,
                    visible: user => isGrantAccessed(user, grants.OPERATIONS_LOCATIONS_LOCATION_LIST),
                    name: 'navigation.locations'
                }
            ]
        },
        /* Storage */
        {
            key: 'storage',
            iconType: 'inbox',
            icon: <InboxOutlined className={Styles.menuIcon} />,
            name: 'navigation.storage',
            visible: user => isGrantAccessed(user, grants.WAREHOUSE),
            items: [
                {
                    key: '/storage-orders',
                    visible: user => isGrantAccessed(user, grants.WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS),
                    link: book.storageOrders,
                    name: 'navigation.orders'
                },
                {
                    key: '/storage-incomes',
                    visible: user => isGrantAccessed(user, grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS),
                    link: book.storageIncomes,
                    name: 'navigation.incomes_docs'
                },
                {
                    key: '/storage-expenses',
                    visible: user => isGrantAccessed(user, grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES),
                    link: book.storageExpenses,
                    name: 'navigation.expenses_docs'
                },
                {
                    key: '/storage-transfers',
                    visible: user => isGrantAccessed(user, grants.WAREHOUSE_DOCUMENTS_LIST_MOVEMENTS),
                    link: book.storageTransfers,
                    name: 'navigation.transfers_storage'
                },
                {
                    key: '/storage-balance',
                    visible: user => isGrantAccessed(user, grants.WAREHOUSE_PRODUCTS_IN_STOCK),
                    link: book.storageBalance,
                    name: 'navigation.storage_balance'
                },
                {
                    key: '/storage-movement',
                    visible: user => isGrantAccessed(user, grants.WAREHOUSE_GOODS_MOVEMENT),
                    link: book.storageMovement,
                    name: 'navigation.goods_movement'
                },
                {
                    key: '/wms',
                    visible: user => isGrantAccessed(user, grants.SETTINGS_CELLS),
                    link: book.wms,
                    name: 'navigation.cells_wms'
                },
                {
                    key: '/storage-journal',
                    visible: user => isGrantAccessed(user, grants.WAREHOUSE_JOURNAL),
                    link: book.storageJournal,
                    name: 'navigation.storage_journal'
                }
            ]
        },
        /* Accounting */
        {
            key: 'accounting',
            iconType: 'audit',
            icon: <AuditOutlined className={Styles.menuIcon} />,
            name: 'navigation.accounting',
            visible: user => isGrantAccessed(user, grants.ACCOUNTING),
            items: [
                {
                    key: '/cash/bank',
                    visible: user => isGrantAccessed(user, grants.ACCOUNTING_CASH_REGISTERS),
                    link: book.cashBankPage,
                    name: 'navigation.cash_settings'
                },
                {
                    key: '/cash/flow',
                    visible: user => isGrantAccessed(user, grants.ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER),
                    link: book.cashFlowPage,
                    name: 'navigation.flow_of_money'
                },
                {
                    key: '/receivables_and_payables',
                    visible: user => isGrantAccessed(user, grants.ACCOUNTING_OUTSTANDING_DEBTS),
                    link: book.receivablesAndPayablesPage,
                    name: 'navigation.receivables_and_payables'
                },
                {
                    key: '/SAL',
                    visible: user => isGrantAccessed(user, grants.ACCOUNTING_SALARIES_DOCUMENT_LIST, accesses.ROWO),
                    link: book.salariesPage,
                    name: 'navigation.sal'
                },
                {
                    key: '/acc',
                    visible: user => isGrantAccessed(user, grants.ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST),
                    link: book.accDocs,
                    name: 'navigation.acc_docs'
                },
                {
                    key: '/account-plan',
                    visible: user => isGrantAccessed(user, grants.ACCOUNTING_GENERAL_LEDGER_CHART_OF_ACCOUNTS),
                    link: book.accountPlan,
                    name: 'navigation.account_plan_main_book'
                },
                {
                    key: '/journal-entries',
                    visible: user => isGrantAccessed(user, grants.ACCOUNTING_JOURNAL_ENTRIES),
                    link: book.journalEntries,
                    name: 'navigation.entries'
                },
                {
                    key: '/report/cash_orders_logs',
                    visible: user =>
                        isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_REGISTER_DEVICES),
                    link: book.reportCashOrdersLogs,
                    name: 'navigation.report_cash_orders_logs'
                },
                {
                    key: '/storage-calculations',
                    visible: user => isGrantAccessed(user, grants.ACCOUNTING_SETTLEMENT_JOURNAL),
                    link: book.storageCalculations,
                    name: 'navigation.storage_calculations'
                },
                {
                    key: '/journal-vat',
                    visible: user => isGrantAccessed(user, grants.ACCOUNTING_VAT_JOURNAL),
                    link: book.journalVAT,
                    name: 'navigation.journal_vat'
                }
            ]
        },
        /* CRM submenu */
        {
            key: 'crm',
            iconType: 'team',
            icon: <TeamOutlined className={Styles.menuIcon} />,
            name: 'navigation.crm',
            visible: user => isGrantAccessed(user, grants.CRM),
            items: [
                {
                    key: '/interactions',
                    link: book.interactions,
                    visible: user => isGrantAccessed(user, grants.CRM_INTERACTIONS_LIST),
                    name: 'navigation.interactions'
                },
                {
                    key: '/orders-kanban',
                    link: book.ordersKanban,
                    visible: user => isGrantAccessed(user, grants.CRM_KANBAN),
                    name: 'navigation.kan'
                },
                {
                    key: '/journal-calls',
                    visible: user => isGrantAccessed(user, grants.CRM_CALL_LOG),
                    name: 'navigation.calls_journal',
                    link: book.callsJournal
                },
                {
                    key: '/mytasks',
                    link: book.myTasksPage,
                    visible: user => isGrantAccessed(user, grants.CRM_TASKS),
                    name: 'navigation.mytasks'
                },
                {
                    key: '/feedback',
                    link: book.feedback,
                    visible: user => isGrantAccessed(user, grants.CRM_REVIEWS),
                    name: 'navigation.feedback'
                }
            ]
        },
        /* Statistics and reports submenu */
        {
            key: 'reports',
            iconType: 'line-chart',
            icon: <LineChartOutlined className={Styles.menuIcon} />,
            visible: user => isGrantAccessed(user, grants.REPORTS),
            name: 'navigation.reports',
            items: [
                {
                    key: '/report/graphic',
                    visible: user => isGrantAccessed(user, grants.REPORTS_GRAPHICAL_REPORTS),
                    link: book.reportGraphic,
                    name: 'navigation.graphic_reports'
                },
                {
                    key: '/reports',
                    visible: user => isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS),
                    link: book.reportsPage,
                    name: 'navigation.reports_xlsx'
                },
                {
                    key: '/report/cash_flow',
                    visible: user => isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_FLOW),
                    link: book.reportCashFlow,
                    name: 'navigation.report_cash_flow'
                }
            ]
        },
        /* Reference Book submenu */
        {
            key: 'catalog',
            iconType: 'contacts',
            icon: <ContactsOutlined className={Styles.menuIcon} />,
            name: 'navigation.directories',
            visible: user => isGrantAccessed(user, grants.DIRECTORIES),
            items: [
                {
                    key: '/products',
                    visible: user => isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST),
                    link: book.products,
                    name: 'navigation.products'
                },
                {
                    key: '/labors',
                    visible: user => isGrantAccessed(user, grants.DIRECTORIES_JOBS),
                    link: book.laborsPage,
                    name: 'navigation.labors_page'
                },
                {
                    key: '/vehicles',
                    visible: user => isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST),
                    link: book.vehicles,
                    name: 'navigation.vehicles'
                },
                {
                    key: '/client-hot-operations',
                    link: book.clientHotOperations,
                    visible: user => isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST),
                    name: 'navigation.client_hot_operations'
                },
                {
                    key: '/suppliers',
                    visible: user => isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST),
                    link: book.suppliersPage,
                    name: 'navigation.suppliers'
                },
                {
                    key: '/employees',
                    link: book.employeesPage,
                    visible: user => isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEES_LIST),
                    name: 'navigation.employees'
                },
                {
                    key: '/directories',
                    link: book.directoriesPage,
                    visible: user => isGrantAccessed(user, grants.DIRECTORIES),
                    name: 'navigation.other_directories'
                }
            ]
        },
        /* Settings submenu */
        {
            key: 'settings',
            iconType: 'setting',
            icon: <ControlOutlined className={Styles.menuIcon} />,
            name: 'navigation.settings',
            visible: user => isGrantAccessed(user, grants.SETTINGS),
            items: [
                {
                    key: '/settings',
                    visible: user => isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS),
                    link: book.settings,
                    name: 'navigation.station_settings'
                },
                {
                    key: '/rights',
                    visible: user => isGrantAccessed(user, grants.ADMINISTRATION_BUSINESS_PACKAGES),
                    link: book.rights,
                    name: 'packages.rights_n_access'
                },
                {
                    key: '/settings_directories',
                    visible: user => isGrantAccessed(user, grants.SETTINGS),
                    link: book.settingsDirectoriesPage,
                    name: 'navigation.other_settings'
                }
            ]
        },
        /* Administration submenu */
        {
            key: 'administration',
            iconType: 'database',
            icon: <DatabaseOutlined className={Styles.menuIcon} />,
            name: 'navigation.administration',
            visible: user => isGrantAccessed(user, grants.ADMINISTRATION),
            items: [
                {
                    key: '/pricelists',
                    link: book.pricelistsPage,
                    visible: user => isGrantAccessed(user, grants.ADMINISTRATION_PRICELISTS, accesses.ROWO),
                    name: 'navigation.pricelists'
                },
                {
                    key: '/brands',
                    link: book.brands,
                    visible: user => isGrantAccessed(user, grants.ADMINISTRATION_BRANDS, accesses.ROWO),
                    name: 'navigation.brands'
                },
                {
                    key: '/administration/sync-import',
                    link: book.syncImportPage,
                    visible: user => isGrantAccessed(user, grants.ADMINISTRATION_SYNCHRONIZATION_IMPORT),
                    name: 'navigation.sync_import'
                },
                {
                    key: '/administration/sync-export',
                    link: book.syncExportPage,
                    visible: user => isGrantAccessed(user, grants.ADMINISTRATION_SYNCHRONIZATION_BACKUP),
                    name: 'navigation.sync_export'
                },
                {
                    key: '/administration/edit-makes',
                    link: book.editMakes,
                    visible: user => isGrantAccessed(user, grants.ADMINISTRATION_CAR_MANAGEMENT, accesses.ROWO),
                    name: 'navigation.edit_makes'
                },
                {
                    key: '/packages',
                    link: book.businessPackagePage,
                    visible: user => isGrantAccessed(user, grants.ADMINISTRATION_BUSINESS_PACKAGES, accesses.ROWO),
                    name: 'packages.bussiness_packages'
                }
            ]
        }
    ],

    getSelectedByRoute: function getSelectedByRoute(currentPath) {
        const result = {
            sectionKey: '',
            itemKey: ''
        };
        // TODO
        for (const section of this.sections) {
            if (section.items) {
                for (const item of section.items) {
                    if (currentPath.startsWith(item.key) && item.key.length > result.itemKey.length) {
                        Object.assign(result, {
                            sectionKey: section.key,
                            itemKey: item.key
                        });
                    }
                }
            } else if (currentPath.startsWith(section.key) && section.key.length > result.itemKey.length) {
                Object.assign(result, {
                    itemKey: section.key
                });
            }
        }

        return result;
    }
};
