import { Card, Input, Modal, Pagination, notification } from 'antd';
import { setBusiness, setBusinessTransfer, setOrderIdTransfer } from 'core/forms/switchBusinessForm/duck';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

import { debounce } from 'lodash';
import { CancelReasonModal } from 'modals';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

/* 
    {
        businessId: 6110,
        businessName: 'Автосервіс PRO.CTO.UA LV1',
        businessAddress: 'вул. Стрийска, 55, Солонка'
    }
*/

const mapDispatchToProps = {
    setBusiness,
    setBusinessTransfer,
    setOrderIdTransfer
};

const pageSizeFetch = 100;
const pageSize = 12;

const NetworkModal = ({
    open,
    onClose,
    setBusiness,
    setBusinessTransfer,
    setOrderIdTransfer,
    intl,
    orderId,
    onStatusChange,
    visible,
    orderComments,
    resetModal,
    onOpenReasonsModal,
    visibleReasonsModal,
    handleCancelReasonsModal,
    handleCancel
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [businesses, setBusinesses] = useState([]);
    const [id, setBusinessId] = useState(null);
    const [pagedBusinesses, setPagedBusinesses] = useState([]);
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [loading, setLoading] = useState();

    const getPagedBusinesses = (page, businesses) => {
        setPagedBusinesses(businesses.slice((page - 1) * pageSize, page * pageSize));
    };

    const handlePageChange = useCallback(
        page => {
            setCurrentPage(page);
            getPagedBusinesses(page, businesses);
        },
        [businesses]
    );

    const handleDebouncedSearch = useCallback(
        debounce(search => setDebouncedSearch(search), 500),
        []
    );

    const handleSearch = e => {
        setSearch(e.target.value);
        handleDebouncedSearch(e.target.value);
    };

    const handleCardClick = useCallback(
        async businessId => {
            const response = await fetchAPI(
                'POST',
                'managers/businesses/set',
                undefined,
                { businessId },
                {
                    handleErrorInternally: true
                }
            );
            onClose();

            if (orderId) {
                setBusinessTransfer(response.businessId);
            } else {
                setBusiness(response.businessId);
            }
        },
        [onClose, setBusiness]
    );

    const transferOrder = useCallback(
        async (businessId, stat, redirectStatus, options) => {
            const order = await fetchAPI(
                'POST',
                '/copy/order',
                undefined,
                { orderId, newBusinessId: businessId },
                {
                    handleErrorInternally: true
                }
            );
            try {
                await onStatusChange(stat, redirectStatus, options);
                await setOrderIdTransfer(order.success.id);
                handleCardClick(businessId);
            } catch (err) {
                notification.error({
                    message: intl.formatMessage({
                        id: 'order-page.transfer_order__error_notif'
                    })
                });
            }
            onClose();
        },
        [onClose, setBusiness]
    );

    const handleCardClickTransfer = useCallback(
        async (businessId, stat, redirectStatus, options) => {
            await Modal.confirm({
                title: intl.formatMessage({
                    id: 'order-page.transfer_order_notif'
                }),
                onOk: () => transferOrder(businessId, stat, redirectStatus, options),
                okType: 'primary',
                onCancel() {}
            });
        },
        [onClose]
    );

    useEffect(() => {
        const fetchBusinesses = async () => {
            setLoading(true);
            const response = await fetchAPI(
                'GET',
                'managers/businesses/get',
                { pageSize: pageSizeFetch, search: debouncedSearch },
                undefined,
                {
                    handleErrorInternally: true
                }
            );
            setBusinesses(response);
            getPagedBusinesses(1, response);
            setTimeout(() => setLoading(false), 200);
        };

        open && fetchBusinesses();
    }, [open, debouncedSearch]);

    const getContent = useCallback(() => {
        if (loading) {
            return (
                <div className={Styles.cardContainer}>
                    <Card className={Styles.card} loading={loading} />
                    <Card className={Styles.card} loading={loading} />
                    <Card className={Styles.card} loading={loading} />
                </div>
            );
        }

        if (businesses.length === 0) {
            return (
                <div className={Styles.noData}>
                    <FormattedMessage id='no_data' />
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className={Styles.cardContainer}>
                    {pagedBusinesses.map(({ businessName, businessAddress, businessId }) => (
                        <Card
                            key={businessId}
                            className={Styles.card}
                            loading={loading}
                            onClick={() => {
                                if (orderId) {
                                    setBusinessId(businessId);
                                    onOpenReasonsModal();
                                } else handleCardClick(businessId);
                            }}
                            title={`${businessName} (#${businessId})`}
                        >
                            <p>{businessAddress}</p>
                        </Card>
                    ))}
                </div>
                <Pagination
                    current={currentPage}
                    onChange={handlePageChange}
                    pageSize={pageSize}
                    total={businesses.length}
                />
            </React.Fragment>
        );
    }, [businesses.length, currentPage, handleCardClick, handlePageChange, loading, pagedBusinesses]);

    return (
        <React.Fragment>
            <Modal
                bodyStyle={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
                footer={null}
                onCancel={onClose}
                open={open}
                title={intl.formatMessage({ id: 'dashboard-page.sto_network' })}
                width={1000}
            >
                <Input
                    allowClear
                    onChange={handleSearch}
                    placeholder={intl.formatMessage({ id: 'search_business' })}
                    style={{ width: '400px' }}
                    value={search}
                />
                {getContent()}
            </Modal>
            <CancelReasonModal
                cancelForTrancfer
                handleCancel={handleCancel}
                handleCardClickTransfer={handleCardClickTransfer}
                id={id}
                orderComments={orderComments}
                resetModal={resetModal}
                transferOrder={transferOrder}
                visible={visibleReasonsModal}
            />
        </React.Fragment>
    );
};

export default connect(null, mapDispatchToProps)(injectIntl(NetworkModal));
