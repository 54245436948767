import { Col, Modal, Row, Select } from 'antd';
import {
    delegateTask,
    selectManagers,
    selectPerformer,
    selectSetManagerModalVisibility,
    setManagerModalVisibility,
    setPerformer
} from 'pages/TaskPage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    visible: selectSetManagerModalVisibility(state),
    managers: selectManagers(state),
    performerId: selectPerformer(state)
});

const mapDispatchToProps = {
    setManagerModalVisibility,
    setPerformer,
    delegateTask
    // resetFields,
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SetManagerModal extends Component {
    onSubmit = () => {
        const { delegateTask } = this.props;
        delegateTask();
        this.closeModal();
    };

    closeModal = () => {
        const { setManagerModalVisibility, resetFields } = this.props;
        setManagerModalVisibility();
        // resetFields();
    };

    render() {
        const {
            visible,
            managers,
            performerId,
            setPerformer,
            intl: { formatMessage }
        } = this.props;

        return (
            <div>
                <Modal
                    destroyOnClose
                    maskClosable={false}
                    okButtonProps={{
                        disabled: !performerId
                    }}
                    onCancel={this.closeModal}
                    onOk={this.onSubmit}
                    title={
                        <div className={Styles.title}>
                            <FormattedMessage id='task_page.change_manager' />
                        </div>
                    }
                    visible={visible}
                >
                    <Row className={Styles.row}>
                        <Col className={Styles.boldCol} span={6}>
                            <FormattedMessage id='task_page.performer' />:
                        </Col>
                        <Col span={12}>
                            <Select
                                dropdownMatchSelectWidth
                                dropdownStyle={{ zIndex: '9999' }}
                                onChange={value => {
                                    setPerformer(value);
                                }}
                                optionFilterProp='children'
                                placeholder={<FormattedMessage id='task_page.performer' />}
                                showSearch
                                value={performerId}
                            >
                                {managers.map(elem => (
                                    <Option key={elem.managerId} value={elem.managerId}>
                                        {`${elem.managerSurname} ${elem.managerName}`}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}
