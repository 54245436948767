import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import { withRouter } from 'react-router';
import book from 'routes/book';
import styled from 'styled-components';
import { isForbidden, permissions } from 'utils';
import DashboardTooltip from '../../DashboardTooltip';
import { DragItemTypes, ordersStatus } from '../../dashboardConfig';
import getBeginDatetime from '../../dashboardCore/getBeginDatetime';
import handleHover from '../../dashboardCore/handleHover';
import DashboardOrderDropTarget from '../DashboardOrderDropTarget';

const orderSource = {
    canDrag(props) {
        const canUpdate =
            !isForbidden(props.user, permissions.EDIT_DASHBOARD_ORDER) &&
            !isForbidden(props.user, permissions.ACCESS_ORDER_BODY);

        return canUpdate && props.status !== 'success';
    },

    beginDrag(props) {
        return {
            stationLoadId: props.options.stationLoadId,
            orderId: props.options.orderId,
            station: props.options.stationNum,
            employeeId: props.options.employeeId
        };
    },
    // keep station and stationNum separate naming here
    // they both received by different sources for a certain purpouse
    // it is happening because of realisation approach of 'dashboard columns'
    endDrag(props, monitor) {
        const { stationLoadId, station } = monitor.getItem();
        const didDrop = monitor.didDrop();

        if (didDrop) {
            const { dropOrder, schedule, mode } = props;
            const { orderId, laborId, duration } = props.options;
            const { day, time, stationNum, employeeId } = monitor.getDropResult();

            if (mode === 'calendar') {
                dropOrder({
                    beginDatetime: getBeginDatetime(day, time, schedule.beginHour).toISOString(),
                    stationNum,
                    stationLoadId
                });
            } else if (mode === 'stations') {
                dropOrder({
                    beginDatetime: getBeginDatetime(day, time, schedule.beginHour).toISOString(),
                    stationNum,
                    stationLoadId
                });
            } else if (mode === 'employees') {
                dropOrder({
                    beginDatetime: getBeginDatetime(day, time, schedule.beginHour).toISOString(),
                    laborId,
                    employeeId,
                    orderId,
                    mode,
                    duration
                });
            }
        }

        if (!didDrop) {
            console.warn(`Station Load ${stationLoadId} didn\'t drop`); // eslint-disable-line
        }
    }
};

const collectSource = (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
});

@withRouter
@DragSource(DragItemTypes.ORDER, orderSource, collectSource)
export default class DashboardOrderDragSource extends Component {
    static propTypes = {
        connectDragSource: PropTypes.func,
        connectDragPreview: PropTypes.func,
        isDragging: PropTypes.bool
    };

    static defaultProps = {
        isDragging: false
    };

    state = {
        tooltipPosition: null,
        resizePosition: null
    };

    _getOrderRef = order => {
        this.orderRef = order;
        this.props.connectDragSource(order);
    };

    _showDashboardTooltip = (ev, order, dashboard) => {
        const tooltipPosition = handleHover(ev, order, dashboard);
        this.setState({ tooltipPosition });
    };

    _hideDashboardTooltip = () => this.setState({ tooltipPosition: null });

    render() {
        const {
            history,
            isDragging,
            x,
            y,
            columns,
            rows,
            status,
            dashboardRef,
            options,
            mode,
            user
            // hideSourceOnDrag,
        } = this.props;

        const { tooltipPosition } = this.state;
        const canOpenOrder =
            !isForbidden(user, permissions.OPEN_DASHBOARD_ORDER) && !isForbidden(user, permissions.SHOW_ORDERS);

        const openOrder = () =>
            history.push(`${book.order}/${options.orderId}`, {
                fromDashboard: true
            });

        const openOrderNewTab = () => window.open(`${book.order}/${options.orderId}`, '_blank');

        return (
            <StyledDashboardOrder
                columns={columns}
                isdragging={isDragging ? 1 : 0}
                rows={rows}
                status={status}
                x={x}
                y={y}
                {...(canOpenOrder ? { onClick: openOrder, onContextMenu: openOrderNewTab } : {})}
                ref={order => this._getOrderRef(order)}
                onMouseDown={this._hideDashboardTooltip}
                onMouseEnter={ev => this._showDashboardTooltip(ev, this.orderRef.getBoundingClientRect(), dashboardRef)}
                onMouseLeave={this._hideDashboardTooltip}
                user={user}
            >
                <StyledDashboardOrderBox>
                    {[...Array(rows).keys()].map((_, index) => this._renderDashboardOrderDropTarget(index))}
                </StyledDashboardOrderBox>
                <DashboardTooltip mode={mode} position={tooltipPosition} {...options} />
            </StyledDashboardOrder>
        );
    }

    _renderDashboardOrderDropTarget = index => {
        const { day, stationNum, globalPosition, label, employeeId, options, mode } = this.props;

        return (
            <DashboardOrderDropTarget
                key={index}
                day={day}
                employeeId={employeeId}
                globalPosition={globalPosition + index}
                label={index === 0 ? label : null}
                options={options}
                stationNum={stationNum}
                mode={mode}
            />
        );
    };
}

const StyledDashboardOrder = styled.div`
    position: relative;
    background: ${props =>
        props.user.orderStatusNewVersion ? get(props, 'options.subStatusColor') : ordersStatus(props.status)};
    color: black;
    font-size: 12px;
    cursor: ${props => (props.status === 'success' ? 'pointer' : 'move')};
    opacity: ${props => (props.isdragging ? 0.5 : 1)};
    ${
        '' /* grid-row: ${props => `${props.x + 1} / span ${props.rows}`};
    grid-column: ${props => `${props.y + 1} / span ${props.columns}`}; */
    }
    ${'' /* https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items */} ${
        '' /* min-width: 0; */
    };
`;

const StyledDashboardOrderBox = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
