import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Popover } from 'antd';
import { Numeral } from 'commons';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI, goTo, isForbidden, permissions } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    client_full_name: '15%',
    actions: '5%',
    client_phones: '20%',
    client_vehicles: 'auto',
    client_debts: '8%',
    vehicle_vin: '15%'
};

/**
 * Creates specificly styled button with popup hint
 * @property onClick - event handler
 * @property user - user to make permissin validations for
 * @property popMessage - message to show when hovered
 * @returns Styled button with handler, popMessage and permisions setup
 */
const CreateOrderBtn = props => {
    const { onClick, user, popMessage, formatMessage } = props;

    const content = <div>{popMessage}</div>;

    return (
        <Popover content={content}>
            <Button disabled={isForbidden(user, permissions.CREATE_ORDER)} onClick={onClick}>
                <PlusOutlined className={Styles.newOrderIcon} />
            </Button>
        </Popover>
    );
};

export function columnsConfig(props) {
    const { user, onCreateOrderForClient, formatMessage, fetchClients } = props;

    const clientFullNameCol = {
        title: <FormattedMessage id='name' />,
        key: v4(),
        render: (val, obj) => {
            return (
                <Link className={Styles.clientLink} to={`${book.client}/${obj.clientId}`}>
                    {`${obj.name || ''} ${obj.surname || ''}`}
                </Link>
            );
        }
    };

    const actionsCol = {
        width: defWidth.actions,
        align: 'center',
        render: (val, client) => {
            return (
                <CreateOrderBtn
                    onClick={() => onCreateOrderForClient({ clientId: client.clientId })}
                    popMessage={<FormattedMessage id='client_hot_operations_page.hint_create_order_with_client' />}
                    user={user}
                />
            );
        }
    };

    const phonesCol = {
        title: <FormattedMessage id='add_order_form.phone' />,
        dataIndex: 'phones',
        key: 'phones',
        render: phones => {
            return phones.map(phone => (
                <a key={v4()} className={Styles.clientPhone} href={`tel:${phone}`}>
                    {phone}
                </a>
            ));
        }
    };

    const orderSumByStatuses = {
        title: <FormattedMessage id='client_hot_operations_page.order_sum_statuses' />,
        dataIndex: 'totalSumByStatuses',
        key: 'totalSumByStatuses',
        render: totalSum => {
            return (
                <div>
                    <Numeral className={Styles.debt} mask='0,0.00' nullText='0'>
                        {totalSum}
                    </Numeral>
                </div>
            );
        }
    };

    const debtsCol = {
        title: <FormattedMessage id='client_hot_operations_page.debt' />,
        dataIndex: 'totalDebtWithTaxes',
        key: 'totalDebtWithTaxes',
        render: (totalDebtWithTaxes, client) => {
            const debt = Number(totalDebtWithTaxes || 0);
            const debtZero = Number(0);

            const debtText = (
                <Numeral className={Styles.debt} mask='0,0.00' nullText='0'>
                    {parseFloat(debt.toFixed(2))}
                </Numeral>
            );

            const debtTextNoAccess = (
                <Numeral className={Styles.debt} mask='0,0.00' nullText='0'>
                    {parseFloat(debtZero.toFixed(2))}
                </Numeral>
            );

            const isAccess = isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS);

            return isAccess ? (
                <Link
                    to={{
                        pathname: `${book.client}/${client.clientId}`,
                        state: {
                            specificTab: 'clientDebt'
                        }
                    }}
                >
                    {debtText}
                </Link>
            ) : (
                debtTextNoAccess
            );
        }
    };

    const vehicleCol = {
        title: <FormattedMessage id='vehicle' />,
        key: 'vehicle',
        render: client => {
            const vehicles = _.get(client, 'vehicles');

            return vehicles
                ? _.map(vehicles, vehicle => {
                      if (!vehicle.model) {
                          return '';
                      }

                      return (
                          <div key={v4()} className={Styles.vehicle}>
                              <span
                                  onClick={() => {
                                      goTo(`${book.vehicle}/${vehicle.id}`);
                                  }}
                                  style={{
                                      cursor: 'pointer',
                                      color: 'var(--link)'
                                  }}
                              >{`${vehicle.make} ${vehicle.model} (${vehicle.year})`}</span>
                              <CreateOrderBtn
                                  onClick={() =>
                                      onCreateOrderForClient({
                                          clientId: client.clientId,
                                          vehicleId: vehicle.id
                                      })
                                  }
                                  popMessage={
                                      <FormattedMessage id='client_hot_operations_page.hint_create_order_with_client_and_vehicle' />
                                  }
                                  user={user}
                              />
                          </div>
                      );
                  })
                : '---';
        }
    };

    const vinCol = {
        title: <FormattedMessage id='add_order_form.vin' />,
        key: 'vin',
        render: client => {
            const vehicles = _.get(client, 'vehicles');

            return vehicles
                ? _.map(vehicles, vehicle => {
                      if (!vehicle.model) {
                          return '';
                      }

                      const vehicleVin = vehicle.vin || '';
                      const vehicleNumber = vehicle.number || '';

                      return (
                          <div key={v4()} className={Styles.vin}>
                              <div>{`${`${vehicleNumber} ${vehicleVin}`}`}</div>
                          </div>
                      );
                  })
                : '---';
        }
    };

    const deleteBtn = {
        key: 'deleteBtn',
        render: client => {
            const orders = _.get(client, 'orders');
            const clientId = _.get(client, 'clientId');
            const disabled = !isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST, accesses.ROWO);

            return orders === null ? (
                <div>
                    <Popconfirm
                        disabled={disabled}
                        onConfirm={async () => {
                            await fetchAPI('DELETE', `/clients/${clientId}`, null, null, {
                                handleErrorInternally: true
                            });
                            fetchClients();
                        }}
                        title={`${formatMessage({ id: 'delete' })} ?`}
                    >
                        <Button disabled={disabled} icon={<DeleteOutlined />} />
                    </Popconfirm>
                </div>
            ) : undefined;
        }
    };

    return [clientFullNameCol, phonesCol, debtsCol, orderSumByStatuses, vehicleCol, vinCol, deleteBtn];
}
