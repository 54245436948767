/* eslint-disable no-undefined */
import { FilterFilled, PercentageOutlined, PlusOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Select, Table, Tooltip } from 'antd';
import { Layout } from 'commons';
import { DateRangePicker } from 'components';
import { setModal } from 'core/modals/duck';
import _ from 'lodash';
import { RefactoredCashOrderModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { AddSalaryDocModal } from './components/AddSalaryDocModal';
import { AddSalaryDocModalCalculated } from './components/AddSalaryDocModalCalculated';
import Styles from './styles.m.css';
import { tableConfig } from './tableConfig';

const dateFormat = 'DD.MM.YYYY';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setModal
};

const statuses = [
    {
        title: <FormattedMessage id='employee_salary_rules.status.NEW"' />,
        key: 'NEW',
        status: 'NEW',
        name: 'Новий'
    },
    {
        title: <FormattedMessage id='employee_salary_rules.status.DONE' />,
        key: 'DONE',
        status: 'DONE',
        name: 'Завершено'
    },
    {
        title: <FormattedMessage id='employee_salary_rules.status.CANCEL' />,
        key: 'CANCEL',
        status: 'CANCEL',
        name: 'Відмова'
    }
];

const positions = [
    {
        title: <FormattedMessage id='employee.is_mechanic' />,
        key: 'MECHANIC',
        value: 'MECHANIC',
        name: 'Механік'
    },
    {
        title: <FormattedMessage id='employee.is_master_receiver' />,
        key: 'MASTER_RECEIVER',
        value: 'MASTER_RECEIVER',
        name: 'Майстер приймальник'
    },
    {
        title: <FormattedMessage id='employee.is_spare_parts_specialist' />,
        key: 'SPARE_PARTS_SPECIALIST',
        value: 'SPARE_PARTS_SPECIALIST',
        name: 'Запчастист'
    },
    {
        title: <FormattedMessage id='employee.is_workshop_master' />,
        key: 'WORKSHOP_MASTER',
        value: 'WORKSHOP_MASTER',
        name: 'Майстер цеха'
    },
    {
        title: <FormattedMessage id='employee.is_administrator' />,
        key: 'ADMINISTRATOR',
        value: 'ADMINISTRATOR',
        name: 'Адміністратор'
    },
    {
        title: <FormattedMessage id='employee.is_cashier' />,
        key: 'CASHIER',
        value: 'CASHIER',
        name: 'Касир'
    },
    {
        title: <FormattedMessage id='employee.is_accountant' />,
        key: 'ACCOUNTANT',
        value: 'ACCOUNTANT',
        name: 'Бухгалтер'
    },
    {
        title: <FormattedMessage id='employee.is_head' />,
        key: 'HEAD',
        value: 'HEAD',
        name: 'Бухгалтер'
    },
    {
        title: <FormattedMessage id='employee.is_it_administrator' />,
        key: 'IT_ADMINISTRATOR',
        value: 'IT_ADMINISTRATOR',
        name: 'IT адміністратор'
    },
    {
        title: <FormattedMessage id='employee.is_owner' />,
        key: 'OWNER',
        value: 'OWNER',
        name: 'Власник'
    },
    {
        title: <FormattedMessage id='employee.is_other' />,
        key: 'OTHER',
        value: 'OTHER',
        name: 'Інше'
    }
];

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class SalariesDocumentContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            page: 1,
            pageSize: 25,
            loading: false,
            visibleAddSalaryDocModal: false,
            allEmployees: [],
            selectedRows: [],
            selectedRowKeys: undefined
        };
    }

    componentDidMount() {
        this.fetchSalariesList();
        this.getAllEmployees();
        // if (!isGrantAccessed(this.props.user, grants.ACCOUNTING_SALARIES_DOCUMENT_LIST)) {
        //     goTo(book.dashboard);
        // }
    }

    handleSearchEmployee = _.debounce(value => {
        this.getAllEmployees(value);
    }, 1000);

    getAllEmployees = async query => {
        const employees = await fetchAPI('GET', 'employees', { query }, null, { handleErrorInternally: true });
        this.setState({
            allEmployees: employees
        });
    };

    fetchSalariesList = async () => {
        const { status, page, pageSize, dateRange, accountingDate, amountLeftAboveZero, jobTitle, documentNum } =
            this.state;
        const { data, count, totalCount } = await fetchAPI('GET', 'salary/docs', {
            page,
            pageSize,
            status,
            startDate: _.get(dateRange, '[0]') ? dateRange[0].format('YYYY-MM-DD') : undefined,
            endDate: _.get(dateRange, '[1]') ? dateRange[1].format('YYYY-MM-DD') : undefined,
            startAccountingDate: _.get(accountingDate, '[0]') ? accountingDate[0].format('YYYY-MM-DD') : undefined,
            endAccountingDate: _.get(accountingDate, '[1]') ? accountingDate[1].format('YYYY-MM-DD') : undefined,
            amountLeftAboveZero,
            jobTitle,
            documentNum
        });
        this.setState({
            dataSource: data,
            count,
            totalCount,
            loading: false
        });
    };

    getColumnSearchProps = dataIndex => {
        const { amountLeftAboveZero } = this.state;
        let filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.setState({
                        [dataIndex]: e.target.value
                    });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                style={{ marginBottom: 8, display: 'block', width: 240 }}
                value={this.state[dataIndex]}
            />
        );

        if (dataIndex === 'accountingDate' || dataIndex === 'dateRange') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        allowClear
                        dateRange={this.state[dataIndex]}
                        format={dateFormat}
                        getPopupContainer={trigger => trigger.parentNode}
                        onDateChange={async dateRange => {
                            await this.setState({
                                [dataIndex]: dateRange
                            });
                            this.handleSearch(confirm, dataIndex);
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            );
        }

        if (dataIndex === 'amountLeftAboveZero') {
            filterComponent = (confirm, clearFilters) => (
                <div style={{ backgroundColor: 'white', display: 'flex' }}>
                    <div
                        style={{
                            marginRight: 8
                        }}
                    >
                        <Checkbox
                            allowClear
                            checked={amountLeftAboveZero}
                            onClick={async () => {
                                await this.setState({
                                    amountLeftAboveZero: !amountLeftAboveZero
                                });
                                this.handleSearch(confirm, amountLeftAboveZero);
                            }}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div>
                        <FormattedMessage id='employee_salary_rules.remain_above_zero' />
                    </div>
                </div>
            );
        }

        if (dataIndex === 'jobTitle') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({
                            jobTitle: value
                        });
                    }}
                    optionFilterProp='children'
                    placeholder={this.props.intl.formatMessage({
                        id: 'position'
                    })}
                    showSearch
                    style={{ marginBottom: 8, display: 'block', width: 275 }}
                    value={this.state.jobTitle}
                >
                    {positions.map(elem => (
                        <Option key={elem.status} value={elem.value}>
                            {elem.name}
                        </Option>
                    ))}
                </Select>
            );
        }

        if (dataIndex === 'status') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({
                            status: value
                        });
                    }}
                    optionFilterProp='children'
                    placeholder={this.props.intl.formatMessage({
                        id: 'status_directory.statuses_tab'
                    })}
                    showSearch
                    style={{ marginBottom: 8, display: 'block', width: 275 }}
                    value={this.state.status}
                >
                    {statuses.map(({ status, name }) => (
                        <Option key={status} value={status}>
                            {name}
                        </Option>
                    ))}
                </Select>
            );
        }

        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}
                    {dataIndex !== 'dateRange' &&
                        dataIndex !== 'accountingDate' &&
                        dataIndex !== 'filtertDoneDate' &&
                        dataIndex !== 'amountLeftAboveZero' && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around'
                                }}
                            >
                                <Button
                                    icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                    onClick={() => this.handleSearch(confirm, dataIndex)}
                                    size='small'
                                    type='primary'
                                >
                                    <FormattedMessage id='search' />
                                </Button>
                                <Button onClick={() => this.handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                    <FormattedMessage id='reset' />
                                </Button>
                            </div>
                        )}
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.state[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = async confirm => {
        confirm();
        await this.setState({ page: 1 });
        this.fetchSalariesList();
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.setState({
            [dataIndex]: undefined,
            page: 1
        });
        this.fetchSalariesList();
    };

    render() {
        const { user, setModal } = this.props;
        const {
            dataSource,
            loading,
            visibleAddSalaryDocModal,
            visibleAddSalaryDocModalCalculated,
            page,
            pageSize,
            employeeId,
            allEmployees,
            selectedRowKeys,
            selectedRows,
            count,
            totalCount
        } = this.state;

        const pagination = {
            pageSize,
            total: Math.ceil(totalCount / pageSize) * pageSize,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.fetchSalariesList();
            }
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            }
        };

        const columns = tableConfig({
            allEmployees,
            selectedRows,
            selectedRowKeys,
            statuses,
            setModal,
            fetchSalariesList: this.fetchSalariesList,
            getColumnSearchProps: this.getColumnSearchProps,
            formatMessage: this.props.intl.formatMessage,
            user: this.props.user
        });

        return (
            <Layout
                controls={
                    <React.Fragment>
                        <div
                            style={{
                                marginRight: 12
                            }}
                        >
                            <Tooltip title={<FormattedMessage id='storage_journal.add_row' />}>
                                <Button
                                    disabled={
                                        !isGrantAccessed(
                                            user,
                                            grants.ACCOUNTING_SALARIES_DOCUMENT_LIST_SALARY_UNPOSTING,
                                            accesses.ROWO
                                        )
                                    }
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        this.setState({
                                            visibleAddSalaryDocModal: true
                                        });
                                    }}
                                />
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title={<FormattedMessage id='employee_salary_rules.period_calculated' />}>
                                <Button
                                    icon={<PercentageOutlined />}
                                    onClick={() => {
                                        this.setState({
                                            visibleAddSalaryDocModalCalculated: true
                                        });
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </React.Fragment>
                }
                paper
                title={<FormattedMessage id='employee_salary_rules.SAL_list' />}
            >
                <div>
                    <div className={Styles.searchEmployeeTitle}>
                        <FormattedMessage id='employee_salary_rules.search_employee' />
                    </div>
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <Select
                            allowClear
                            
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={value => {
                                this.setState({
                                    employeeId: value
                                });
                            }}
                            onSearch={input => this.handleSearchEmployee(input)}
                            optionFilterProp='children'
                            
                            placeholder={this.props.intl.formatMessage({
                                id: 'search'
                            })}
                            showSearch
                            value={employeeId} 
                            // mode='multiple'
                            style={{ marginBottom: 8, display: 'block', width: '25%' }}
                        >
                            {allEmployees
                                .filter(({ disabled }) => !disabled)
                                .map(({ id, name, surname }) => (
                                    <Option key={id} value={id}>
                                        {`${name} ${surname}`}
                                    </Option>
                                ))}
                        </Select>
                        <Button
                            icon={<SearchOutlined />}
                            onClick={async () => {
                                const filteredData = await fetchAPI(
                                    'GET',
                                    'salary/docs',
                                    {
                                        page,
                                        pageSize,
                                        employeeId
                                    },
                                    null,
                                    { handleErrorInternally: true }
                                );
                                this.setState({
                                    dataSource: filteredData.data,
                                    totalCount: filteredData.count
                                });
                            }}
                        />
                        <Button
                            icon={<UndoOutlined />}
                            onClick={async () => {
                                const { data, count } = await fetchAPI(
                                    'GET',
                                    'salary/docs',
                                    {
                                        employeeId: undefined,
                                        page,
                                        pageSize,
                                        orderId: undefined,
                                        startDate: undefined,
                                        endDate: undefined
                                    },
                                    null,
                                    { handleErrorInternally: true }
                                );
                                this.setState({
                                    dataSource: data,
                                    count,
                                    employeeId: undefined
                                });
                            }}
                        />
                    </div>
                </div>
                <Table columns={columns} dataSource={dataSource} loading={loading} pagination={pagination} />
                <AddSalaryDocModalCalculated
                    allEmployees={allEmployees}
                    fetchSalariesList={this.fetchSalariesList}
                    handleSearchEmployee={this.handleSearchEmployee}
                    hideModal={() => {
                        this.setState({
                            visibleAddSalaryDocModalCalculated: false
                        });
                    }}
                    visible={visibleAddSalaryDocModalCalculated}
                />
                <AddSalaryDocModal
                    allEmployees={allEmployees}
                    fetchSalariesList={this.fetchSalariesList}
                    handleSearchEmployee={this.handleSearchEmployee}
                    hideModal={() => {
                        this.setState({
                            visibleAddSalaryDocModal: false
                        });
                    }}
                    visible={visibleAddSalaryDocModal}
                />
                <RefactoredCashOrderModal />
            </Layout>
        );
    }
}

export default SalariesDocumentContainer;
