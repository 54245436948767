/* Constants */
import dayjs from 'dayjs';
import {
    priorityValues,
    statusValues
} from 'modals/EmployeeTaskModal/constants';

export const moduleName = 'employeeTaskModal';
const prefix = `cpb/${moduleName}`;

export const FETCH_EMPLOYEES = `${prefix}/FETCH_EMPLOYEES`;
export const FETCH_EMPLOYEES_SUCCESS = `${prefix}/FETCH_EMPLOYEES_SUCCESS`;

export const FETCH_DOCUMENTS = `${prefix}/FETCH_DOCUMENTS`;
export const FETCH_DOCUMENTS_SUCCESS = `${prefix}/FETCH_DOCUMENTS_SUCCESS`;

export const FETCH_CLIENTS = `${prefix}/FETCH_CLIENTS`;
export const FETCH_CLIENTS_SUCCESS = `${prefix}/FETCH_CLIENTS_SUCCESS`;

export const FETCH_SUPPLIERS = `${prefix}/FETCH_SUPPLIERS`;
export const FETCH_SUPPLIERS_SUCCESS = `${prefix}/FETCH_SUPPLIERS_SUCCESS`;

export const FETCH_MANAGERS = `${prefix}/FETCH_MANAGERS`;
export const FETCH_MANAGERS_SUCCESS = `${prefix}/FETCH_MANAGERS_SUCCESS`;

export const CREATE_EMPLOYEE_TASK = `${prefix}/CREATE_EMPLOYEE_TASK`;
export const CREATE_EMPLOYEE_TASK_SUCCESS = `${prefix}/CREATE_EMPLOYEE_TASK_SUCCESS`;

export const SET_FIELDS = `${prefix}/SET_FIELDS`;
export const RESET_FIELDS = `${prefix}/RESET_FIELDS`;

export const SET_DOCUMENT_FILTERS = `${prefix}/SET_DOCUMENT_FILTERS`;
export const SET_DOCUMENT_FETCHING = `${prefix}/SET_DOCUMENT_FETCHING`;

/** Date format for backend */
export const DEF_DATE_FORMAT = 'YYYY/MM/DD';

/* Reducer */
const ReducerState = {
    fields: {
        name: undefined,
        performerId: undefined,
        scheduledDatetime: undefined,
        priority: priorityValues.MIDDLE,
        comment: undefined,
        status: statusValues.NEW,

        counterpartyType: undefined,
        documentType: undefined, // ORDER, CASH_ORDER, STORE_DOC

        counterpartClientId: undefined,
        counterpartEmployeeId: undefined,
        counterpartBusinessSupplierId: undefined,

        storeDocId: undefined,
        orderId: undefined,
        cashOrderId: undefined,

        scheduledDate: dayjs().add(30 - (dayjs().minute() % 30) + 30, 'minutes'), // current date with rounded to nearest 1 hour interval
        scheduledTime: dayjs().add(30 - (dayjs().minute() % 30) + 30, 'minutes') // current date with rounded to nearest 1 hour interval,
    },
    managers: [],
    employees: [],
    clientsData: {
        clients: [],
        stats: {}
    },
    suppliers: [],

    documentsData: {
        documents: [],
        stats: {},
        filters: {
            query: undefined,
            page: 1,
            pageSize: 25
        },
        fetching: false
    }
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_FIELDS:
            return {
                ...state,
                fields: { ...state.fields, ...payload }
            };

        case SET_DOCUMENT_FILTERS:
            return {
                ...state,
                documentsData: {
                    ...state.documentsData,
                    filters: {
                        ...state.documentsData.filters,
                        ...payload
                    }
                }
            };

        case SET_DOCUMENT_FETCHING:
            return {
                ...state,
                documentsData: {
                    ...state.documentsData,
                    fetching: payload
                }
            };

        case RESET_FIELDS:
            return {
                ...state,
                fields: {
                    name: undefined,
                    performerId: undefined,
                    scheduledDatetime: undefined,
                    priority: priorityValues.MIDDLE,
                    comment: undefined,
                    status: statusValues.NEW,

                    counterpartyType: undefined,
                    documentType: undefined, // ORDER, CASH_ORDER, STORE_DOC

                    counterpartClientId: undefined,
                    counterpartEmployeeId: undefined,
                    counterpartBusinessSupplierId: undefined,
                    storeDocId: undefined,
                    orderId: undefined,
                    cashOrderId: undefined,
                    scheduledDate: dayjs().add(30 - (dayjs().minute() % 30) + 30, 'minutes'), // current date with rounded to nearest 1 hour interval
                    scheduledTime: dayjs().add(30 - (dayjs().minute() % 30) + 30, 'minutes') // current date with rounded to nearest 1 hour interval,
                }
            };
        case FETCH_EMPLOYEES_SUCCESS:
            return {
                ...state,
                employees: payload
            };

        case FETCH_MANAGERS_SUCCESS:
            return {
                ...state,
                managers: payload
            };

        case FETCH_SUPPLIERS_SUCCESS:
            return {
                ...state,
                suppliers: payload
            };

        case FETCH_DOCUMENTS_SUCCESS:
            return {
                ...state,
                documentsData: {
                    ...state.documentsData,
                    documents: payload.documents,
                    stats: payload.stats
                }
            };

        case FETCH_CLIENTS_SUCCESS:
            return {
                ...state,
                clientsData: {
                    clients: payload.clients,
                    stats: payload.stats
                }
            };

        default:
            return state;
    }
}

/* Selectors */

export const selectFields = state => state[moduleName].fields;
export const selectEmployees = state => state[moduleName].employees;
export const selectSuppliers = state => state[moduleName].suppliers;
export const selectManagers = state => state[moduleName].managers;
export const selectClients = state => state[moduleName].clientsData.clients;
export const selectDocumentFilters = state => state[moduleName].documentsData.filters;
export const selectDocuments = state => state[moduleName].documentsData.documents;
export const selectDocumentsStats = state => state[moduleName].documentsData.stats;
export const selectFetchingDocuments = state => state[moduleName].documentsData.fetching;

/* Actions */

export const setFields = fields => ({
    type: SET_FIELDS,
    payload: fields
});

export const setDocumentFilters = filters => {
    return function (dispatch) {
        dispatch({
            type: SET_DOCUMENT_FILTERS,
            payload: filters
        });

        dispatch(fetchDocuments());
    };
};

export const fetchEmployees = () => ({
    type: FETCH_EMPLOYEES
});

export const fetchEmployeesSuccess = employees => ({
    type: FETCH_EMPLOYEES_SUCCESS,
    payload: employees
});

export const fetchManagers = () => ({
    type: FETCH_MANAGERS
});

export const fetchManagersSuccess = data => ({
    type: FETCH_MANAGERS_SUCCESS,
    payload: data
});

export const fetchClients = () => ({
    type: FETCH_CLIENTS
});

export const fetchClientsSuccess = clients => ({
    type: FETCH_CLIENTS_SUCCESS,
    payload: clients
});

export const fetchSuppliers = () => ({
    type: FETCH_SUPPLIERS
});

export const fetchSuppliersSuccess = suppliers => ({
    type: FETCH_SUPPLIERS_SUCCESS,
    payload: suppliers
});

export const fetchDocuments = () => ({
    type: FETCH_DOCUMENTS
});

export const fetchDocumentsSuccess = data => ({
    type: FETCH_DOCUMENTS_SUCCESS,
    payload: data
});

export const createEmployeeTask = callback => ({
    type: CREATE_EMPLOYEE_TASK,
    payload: { callback }
});

export const createEmployeeTaskSuccess = () => ({
    type: CREATE_EMPLOYEE_TASK_SUCCESS
});

export const resetFields = () => ({
    type: RESET_FIELDS
});

export const setDocumentFetching = value => ({
    type: SET_DOCUMENT_FETCHING,
    payload: value
});
