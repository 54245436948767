import { Modal } from 'antd';
import { loadModal } from 'core/modals/duck';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile,
    modalProps: state.modals.modalProps
});

const mapDispatchToProps = {
    loadModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class PhotoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { visible, data: row, hideModal } = this.props;

        const attributes = _.get(row, 'attributes') || [];
        const src = _.get(row, 'images[0]')
            ? `${__TECDOC_IMAGES_URL__}/${row.images[0].pictureName}`
            : `${__TECDOC_IMAGES_URL__}/not_found.png`;
        const additionalData = (
            <div>
                {attributes.map((attribute, i) => (
                    <div key={i} style={{ border: '1px solid var(--text4)', padding: 8 }}>
                        {attribute.description}: {attribute.value}
                    </div>
                ))}
            </div>
        );

        return (
            <Modal
                footer={null}
                maskClosable={false}
                onCancel={hideModal}
                title={<FormattedMessage id='photo' />}
                visible={visible}
                width='45%'
            >
                <div style={{ textAlign: 'center' }}>
                    <img
                        alt='spare_part'
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = `${__TECDOC_IMAGES_URL__}/not_found.png`;
                        }}
                        src={src}
                        style={{ cursor: 'pointer' }}
                        width='70%'
                    />
                </div>
                {additionalData}
            </Modal>
        );
    }
}

export default PhotoModal;
