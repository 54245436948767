/* eslint-disable max-classes-per-file */
import { ClockCircleOutlined, CloseOutlined, DeleteOutlined, FormOutlined, MessageOutlined } from '@ant-design/icons';
import {
    Button,
    Checkbox,
    DatePicker,
    Input,
    InputNumber,
    Modal,
    Select,
    Space,
    Table,
    TimePicker,
    Tooltip,
    TreeSelect,
    notification
} from 'antd';
import { EmployeeSupplierSelector } from 'components';
import { MODALS } from 'core/modals/duck';
import dayjs from 'dayjs';
import _, { get } from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { buildSupplierOptions, fetchAPI, filterTreeNodeByPart, numeralFormatter, numeralParser } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import './styles.less';
import Styles from './styles.m.css';

const { Option } = Select;
const { confirm } = Modal;

const NUMBER_CLMN_WIDTH = 98;
const SMALL_BTN_WIDTH = 40;

@injectIntl
class AddServiceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            mainTableSource: [],
            relatedServices: [],
            relatedServicesCheckbox: false,
            relatedDetailCheckbox: JSON.parse(localStorage.getItem('_my.carbook.relatedDetailCheckbox')) || false,
            laborSearch: ''
        };
        this.labors = [];
        this.masterLabors = [];
        this.storeGroups = [];
        this.laborsTreeData = [];
        this.brandOptions = [];
        this.servicesOptions = [];
        this.employeeOptions = [];
        this.relatedDetailsOptions = [];

        // this.laborRef = React.createRef();

        this.columns = () => {
            const checkedCol = {
                key: 'checked',
                dataIndex: 'checked',
                width: 'min-content',
                render: (data, elem) => {
                    return elem.related ? (
                        <Checkbox
                            checked={data}
                            data-qa='checkbox_add_service_modal'
                            onChange={({ target }) => {
                                elem.checked = target.checked;
                                this.setState({});
                            }}
                        />
                    ) : null;
                }
            };
            const storeGroupCol = {
                title: <FormattedMessage id='services_table.store_group' />,
                key: 'storeGroupId',
                dataIndex: 'storeGroupId',
                width: 180,
                render: (data, elem) => {
                    return (
                        <Tooltip position='top' title={<FormattedMessage id='services_table.store_group' />}>
                            <div className={Styles.laborSelect}>
                                <TreeSelect
                                    className={Styles.groupsTreeSelect}
                                    data-qa='tree_select_store_group_id_add_service_modal'
                                    disabled={this.state.editing || Boolean(elem.masterLaborId)}
                                    dropdownMatchSelectWidth={320}
                                    filterTreeNode={filterTreeNodeByPart}
                                    getPopupContainer={() => document.querySelector('.addLaborModal')}
                                    listHeight={440}
                                    onSelect={(value, option) => {
                                        elem.storeGroupId = value;
                                        elem.laborId = undefined;
                                        elem.serviceName = undefined;
                                        this.filterOptions(elem.masterLaborId, value);
                                        this.setState({});
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'services_table.store_group'
                                    })}
                                    showSearch
                                    style={{
                                        maxWidth: 180
                                    }}
                                    treeData={this.props.detailsTreeData}
                                    value={data}
                                />
                            </div>
                        </Tooltip>
                    );
                }
            };
            const serviceTypeCol = {
                title: <FormattedMessage id='order_form_table.service_type' />,
                key: 'masterLaborId',
                dataIndex: 'masterLaborId',
                width: 180,
                render: (data, elem) => {
                    return (
                        <Tooltip position='top' title={<FormattedMessage id='order_form_table.service_type' />}>
                            <div className={Styles.laborSelect}>
                                <TreeSelect
                                    className={Styles.groupsTreeSelect}
                                    data-qa='tree_select_master_labor_id_add_service_modal'
                                    disabled={this.state.editing || Boolean(elem.storeGroupId)}
                                    dropdownMatchSelectWidth={280}
                                    filterTreeNode={filterTreeNodeByPart}
                                    getPopupContainer={() => document.querySelector('.addLaborModal')}
                                    listHeight={440}
                                    onSelect={(value, option) => {
                                        elem.masterLaborId = value;
                                        this.filterOptions(value, elem.storeGroupId);
                                        this.setState({});
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'order_form_table.service_type'
                                    })}
                                    showSearch
                                    style={{
                                        maxWidth: 180
                                    }}
                                    treeData={this.props.laborsTreeData}
                                    value={data}
                                />
                            </div>
                        </Tooltip>
                    );
                }
            };

            const laborCol = {
                title: (
                    <React.Fragment>
                        <FormattedMessage id='services_table.labor' /> <span style={{ color: 'red' }}>*</span>
                    </React.Fragment>
                ),
                key: 'laborId',
                dataIndex: 'laborId',
                render: (data, elem) => {
                    const currentServiceOption = this.servicesOptions.find(labor => labor.id == data);

                    return (
                        <Tooltip position='top' title={<FormattedMessage id='services_table.labor' />}>
                            <span className={Styles.laborSelect}>
                                <Select
                                    ref={node => (this.laborRef = node)}
                                    allowClear
                                    data-qa='select_labor_id_add_service_modal'
                                    disabled={this.state.editing || elem.related}
                                    dropdownMatchSelectWidth={!this.props.isMobile ? 420 : undefined}
                                    filterOption={(input, option) => {
                                        const parts = input.toLowerCase().split(' ');

                                        return (
                                            String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            String(option.value).indexOf(input.toLowerCase()) >= 0 ||
                                            String(option.cross_id).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            String(option.barcode).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            parts.every(part => String(option.children).toLowerCase().includes(part))
                                        );
                                    }}
                                    getPopupContainer={() => document.querySelector('.addLaborModal')}
                                    onChange={(value, option) => {
                                        if (option) {
                                            const price = option.price
                                                ? option.price
                                                : Number(this.props.normHourPrice);
                                            const count = option.norm_hours || 1;
                                            elem.laborId = value;
                                            elem.serviceName = option.children;
                                            elem.masterLaborId = option.master_id;
                                            elem.storeGroupId = option.store_group_id;
                                            elem.count = option.hours;
                                            elem.price = price;
                                            elem.sum = price * count;
                                            elem.specificationId = option.specificationId;
                                            elem.laborUnitId = option.laborUnitId;
                                            if (!elem.related) {
                                                this.getRelatedLabors(value);
                                            }
                                        } else {
                                            elem.laborId = value;
                                            elem.serviceName = value;
                                            elem.masterLaborId = value;
                                            elem.storeGroupId = value;
                                            elem.specificationId = value;
                                            this.state.relatedLabors = [];
                                        }
                                        this.setState({
                                            commentForCalls: option.comment
                                        });
                                    }}
                                    onSearch={async input => {
                                        this.setState({
                                            laborSearch: input
                                        });
                                        await this.props.handleSearchLabors(input);
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'services_table.labor'
                                    })}
                                    showAction={['focus', 'click']}
                                    showSearch
                                    suffixIcon={
                                        <span
                                            style={{
                                                color: 'red'
                                            }}
                                        >
                                            *
                                        </span>
                                    }
                                    value={!elem.related ? data : elem.name}
                                >
                                    {elem.laborId
                                        ? buildSupplierOptions(this.servicesOptions, {
                                              id: get(elem, 'laborId', undefined),
                                              name: get(elem, 'serviceName', undefined)
                                          }).map((elem, index) => (
                                              <Option value={elem.id}>{elem.customName || elem.name}</Option>
                                          ))
                                        : this.servicesOptions.map((elem, index) => (
                                              <Option
                                                  key={index}
                                                  barcode={elem.barcode}
                                                  comment={elem.comment}
                                                  cross_id={elem.crossId}
                                                  hours={elem.laborPrice.normHours}
                                                  laborUnitId={elem.laborUnitId}
                                                  master_id={elem.masterLaborId}
                                                  price={elem.laborPrice.price}
                                                  specificationId={elem.specificationId}
                                                  store_group_id={elem.storeGroupId}
                                                  value={elem.id}
                                              >
                                                  {elem.customName || elem.name}
                                              </Option>
                                          ))}
                                </Select>
                            </span>
                        </Tooltip>
                    );
                }
            };

            const detailNameCol = {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'serviceName',
                dataIndex: 'serviceName',
                width: 407,
                render: (data, elem) => {
                    return (
                        <Tooltip placement='bottom' title={<FormattedMessage id='order_form_table.detail_name' />}>
                            <Input
                                data-qa='input_service_name_add_service_modal'
                                disabled={this.state.editing && elem.stage != 'INACTIVE'}
                                onChange={({ target }) => {
                                    const { value } = target;
                                    elem.serviceName = value;
                                    this.setState({});
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.detail_name'
                                })}
                                style={{ minWidth: 180, maxWidth: 407 }}
                                value={data}
                            />
                        </Tooltip>
                    );
                }
            };

            const masterOrSupplierCol = {
                title: (
                    <React.Fragment>
                        <FormattedMessage id='order_form_table.master' /> /{' '}
                        <FormattedMessage id='order_form_table.supplier' />
                    </React.Fragment>
                ),
                key: 'employeeId',
                render: row => {
                    const employeesOptions = this.props.employees
                        .filter(
                            ({ posts, disabled }) =>
                                !disabled && posts.map(({ postName }) => postName).includes('MECHANIC')
                        )
                        .map(({ name, surname, id }) => ({
                            value: id,
                            label: `${surname || ''} ${name || ''}`
                        }));

                    const suppliersOptions = this.props.suppliers.map(({ name, id }) => ({
                        value: id,
                        label: name
                    }));

                    return (
                        <Tooltip
                            placement='bottom'
                            title={
                                <React.Fragment>
                                    <FormattedMessage id='order_form_table.master' /> /{' '}
                                    <FormattedMessage id='order_form_table.supplier' />
                                </React.Fragment>
                            }
                        >
                            <EmployeeSupplierSelector
                                employeesOptions={employeesOptions}
                                initialState={{
                                    type: row.counterparty,
                                    options:
                                        row.employeeId || row.businessSupplierId
                                            ? [
                                                  {
                                                      value: row.employeeId || row.businessSupplierId,
                                                      label: row.employeeId
                                                          ? row.employeeName
                                                          : row.businessSupplierName
                                                  }
                                              ]
                                            : undefined
                                }}
                                onChange={async (value, type) => {
                                    row.counterparty = type || 'EMPLOYEE';
                                    row.employeeId = type === 'EMPLOYEE' && value ? value.key : null;
                                    row.businessSupplierId = type === 'SUPPLIER' && value ? value.key : null;
                                    if (type === 'SUPPLIER') {
                                        row.businessSupplierName = value ? value.label : null;
                                    } else {
                                        row.employeeName = value ? value.label : null;
                                    }
                                    this.setState({});
                                }}
                                servicesStyle={{
                                    width: '100%'
                                }}
                                suppliersOptions={suppliersOptions}
                                value={row.employeeId || row.businessSupplierId}
                            />
                            {/* <TreeSelect
                                data-qa='tree_select_employee_id_add_service_modal'
                                dropdownMatchSelectWidth={280}
                                filterTreeNode={(input, node) => {
                                    return (
                                        node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        String(node.props.value).indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                getPopupContainer={() => document.querySelector('.addLaborModal')}
                                listHeight={440}
                                onSelect={async (valueString, option) => {
                                    const value = JSON.parse(valueString);
                                    row.counterparty = value.counterparty;
                                    row.employeeId = value.counterparty == 'EMPLOYEE' ? value.id : null;
                                    row.businessSupplierId = value.counterparty == 'SUPPLIER' ? value.id : null;
                                    this.setState({});
                                }}
                                placeholder={
                                    <React.Fragment>
                                        <FormattedMessage id='order_form_table.master' /> /{' '}
                                        <FormattedMessage id='order_form_table.supplier' />
                                    </React.Fragment>
                                }
                                showSearch
                                treeData={this.props.counterpartyTreeData}
                                treeDefaultExpandedKeys={['EMPLOYEE']}
                                value={
                                    row.employeeId || row.businessSupplierId
                                        ? JSON.stringify({
                                              counterparty: row.counterparty || 'EMPLOYEE',
                                              id:
                                                  row.counterparty == 'SUPPLIER'
                                                      ? row.businessSupplierId
                                                      : row.employeeId
                                          })
                                        : undefined
                                }
                            /> */}
                        </Tooltip>
                    );
                }
            };

            const commentCol = {
                title: <FormattedMessage id='comment' />,
                key: 'comment',
                dataIndex: 'comment',
                width: SMALL_BTN_WIDTH,
                render: (data, elem) => {
                    let detail = elem.serviceName;
                    if (detail && detail.indexOf(' - ') > -1) {
                        detail = detail.slice(0, detail.indexOf(' - '));
                    }

                    return (
                        <CommentaryButton
                            commentary={
                                data || {
                                    comment: undefined,
                                    positions: [],
                                    problems: []
                                }
                            }
                            data-qa='button_comment_add_service_modal'
                            detail={detail}
                            disabled={elem.laborId == null}
                            setComment={(comment, positions, problems) => {
                                elem.comment = {
                                    comment,
                                    positions,
                                    problems
                                };
                                elem.serviceName = comment || elem.serviceName;
                                this.setState({});
                            }}
                        />
                    );
                }
            };

            const purchasePriceCol = {
                title: <FormattedMessage id='order_form_table.purchasePrice' />,
                key: 'purchasePrice',
                dataIndex: 'purchasePrice',
                width: NUMBER_CLMN_WIDTH,
                render: (data, elem) => {
                    return (
                        <Tooltip placement='topLeft' title={<FormattedMessage id='order_form_table.purchasePrice' />}>
                            <div className={Styles.detailNumberInput}>
                                <InputNumber
                                    className='detailNumberInput'
                                    data-qa='input_number_purchase_price_add_service_modal'
                                    decimalSeparator=','
                                    disabled={
                                        !isGrantAccessed(
                                            this.props.user,
                                            grants.OPERATIONS_ORDER_DOCUMENT_JOBS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT
                                        )
                                    }
                                    formatter={value => numeralFormatter(value)}
                                    min={0}
                                    onChange={value => {
                                        elem.purchasePrice = value;
                                        this.setState({});
                                    }}
                                    onStep={() => this.setState({})}
                                    parser={value => numeralParser(value)}
                                    precision={4}
                                    style={{
                                        width: '100%',
                                        minWidth: 60,
                                        fontSize: data > 9999 && data !== undefined ? 11 : 13
                                    }}
                                    value={data || 0}
                                />
                            </div>
                        </Tooltip>
                    );
                }
            };

            const serviceRowDiscountCol = {
                title: <FormattedMessage id='discount_or_markup' />,
                key: 'serviceRowDiscount',
                dataIndex: 'serviceRowDiscount',
                width: NUMBER_CLMN_WIDTH,
                align: 'right',
                render: (data, elem) => {
                    return (
                        <Tooltip placement='top' title={<FormattedMessage id='discount_or_markup' />}>
                            <div className={Styles.detailNumberInput}>
                                <InputNumber
                                    className='detailNumberInput'
                                    decimalSeparator=','
                                    formatter={value => `${value}%`}
                                    max={100}
                                    min={-100}
                                    onChange={value => {
                                        elem.serviceRowDiscount = value;

                                        this.setState({});
                                    }}
                                    parser={value => value.replace('%', '').replace(',', '.')}
                                    precision={2}
                                    style={{
                                        width: '100%',
                                        minWidth: 60,
                                        fontSize: data > 99999 && data !== undefined ? 11 : 14
                                    }}
                                    value={Math.round(data * 100) / 100 || 0}
                                />
                            </div>
                        </Tooltip>
                    );
                }
            };

            const priceCol = {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'price',
                dataIndex: 'price',
                width: NUMBER_CLMN_WIDTH,
                render: (data, elem) => {
                    const value = data ? data.toFixed(4) : 0;

                    return (
                        <Tooltip
                            placement='topRight'
                            title={
                                <div>
                                    <FormattedMessage id='order_form_table.price' />{' '}
                                    <span
                                        style={{
                                            fontSize: 12,
                                            fontWeight: 400
                                        }}
                                    >
                                        (<FormattedMessage id='without' /> <FormattedMessage id='VAT' />)
                                    </span>
                                </div>
                            }
                        >
                            <div className={Styles.detailNumberInput}>
                                <InputNumber
                                    className='detailNumberInput'
                                    data-qa='input_number_price_add_service_modal'
                                    decimalSeparator=','
                                    disabled={
                                        !isGrantAccessed(
                                            this.props.user,
                                            grants.OPERATIONS_ORDER_DOCUMENT_JOBS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT
                                        )
                                    }
                                    formatter={value => numeralFormatter(value)}
                                    min={0}
                                    onChange={value => {
                                        elem.price = value;
                                        elem.sum = value * elem.count;
                                        this.setState({});
                                    }}
                                    onStep={() => this.setState({})}
                                    parser={value => numeralParser(value)}
                                    precision={4}
                                    style={{
                                        width: '100%',
                                        minWidth: 60,
                                        fontSize: data > 9999 && data !== undefined ? 11 : 13
                                    }}
                                    value={data || 0}
                                />
                            </div>
                        </Tooltip>
                    );
                }
            };

            const countCol = {
                title: <FormattedMessage id='order_form_table.count' />,
                key: 'count',
                dataIndex: 'count',
                width: NUMBER_CLMN_WIDTH,
                render: (data, elem) => {
                    const value = data ? Number(data).toFixed(4) : 0;

                    return (
                        <Tooltip placement='bottomLeft' title={<FormattedMessage id='order_form_table.count' />}>
                            <div className={Styles.detailNumberInput}>
                                <InputNumber
                                    className='detailNumberInput'
                                    data-qa='input_number_count_add_service_modal'
                                    decimalSeparator=','
                                    min={0}
                                    onChange={value => {
                                        elem.count = value;
                                        elem.sum = value * elem.price;
                                        this.setState({});
                                    }}
                                    onStep={() => this.setState({})}
                                    parser={value => numeralParser(value)}
                                    style={{
                                        width: '100%',
                                        minWidth: 60,
                                        fontSize: data > 9999 && data !== undefined ? 11 : 13
                                    }}
                                    value={value}
                                />
                            </div>
                        </Tooltip>
                    );
                }
            };

            const unitsCol = {
                title: (
                    <React.Fragment>
                        <FormattedMessage id='services_table.units' /> <span style={{ color: 'red' }}>*</span>
                    </React.Fragment>
                ),
                key: 'laborUnitId',
                dataIndex: 'laborUnitId',
                align: 'right',
                width: NUMBER_CLMN_WIDTH,
                render: (data, elem) => {
                    const currentServiceOption = this.servicesOptions.find(labor => labor.id == data);

                    const defaultUse = _.get(
                        this.props.unitDefault.filter(({ defaultUse }) => defaultUse),
                        '[0].id'
                    );

                    return (
                        <Tooltip placement='bottom' title={<FormattedMessage id='services_table.units' />}>
                            <Select
                                ref={node => (this.laborRef = node)}
                                allowClear
                                data-qa='select_labor_unit_id_add_service_modal'
                                disabled={this.state.editing || elem.related}
                                dropdownMatchSelectWidth={100}
                                filterOption={(input, option) => {
                                    const parts = input.toLowerCase().split(' ');

                                    return (
                                        String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        String(option.value).indexOf(input.toLowerCase()) >= 0 ||
                                        String(option.cross_id).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        String(option.barcode).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        parts.every(part => String(option.children).toLowerCase().includes(part))
                                    );
                                }}
                                getPopupContainer={() => document.querySelector('.addLaborModal')}
                                onChange={(value, option) => {
                                    elem.laborUnitId = value;

                                    this.setState({});
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'services_table.units_placeholder'
                                })}
                                showAction={['focus', 'click']}
                                showSearch
                                style={{
                                    width: '100%',
                                    float: 'right',
                                    minWidth: 60,
                                    maxWidth: 90
                                }}
                                suffixIcon={
                                    <span
                                        style={{
                                            color: 'red'
                                        }}
                                    >
                                        *
                                    </span>
                                }
                                value={elem.laborUnitId || defaultUse}
                            >
                                {(this.props.units || []).map((elem, index) => (
                                    <Option key={index} value={elem.id}>
                                        {elem.shortcut}
                                    </Option>
                                ))}
                            </Select>
                        </Tooltip>
                    );
                }
            };

            const normHoursCol = {
                title: <FormattedMessage id='services_table.norm_hours' />,
                key: 'hours',
                dataIndex: 'hours',
                width: SMALL_BTN_WIDTH,
                render: (data, elem) => {
                    return (
                        <Tooltip title={<FormattedMessage id='labors_table.check_labor_hours' />} zIndex={2001}>
                            <Button
                                data-qa='button_hours_add_service_modal'
                                disabled={
                                    !isGrantAccessed(
                                        this.props.user,
                                        grants.OPERATIONS_ORDER_DOCUMENT_JOBS_STANDARD_HOURS
                                    )
                                }
                                onClick={() => {
                                    this.props.setModal(MODALS.NORM_HOURS_MODAL, {
                                        storeGroupId: elem.storeGroupId,
                                        laborId: elem.laborId,

                                        onSelect: ({ hours, storeGroupId, normHourPrice, price }) => {
                                            elem.hours = hours;
                                            elem.count = hours * this.props.laborTimeMultiplier;
                                            elem.storeGroupId = elem.storeGroupId || storeGroupId;
                                            elem.price = price || elem.price || normHourPrice;
                                            this.setState({});
                                        }
                                    });
                                }}
                            >
                                {data ? (
                                    <React.Fragment>
                                        {data} <FormattedMessage id='order_form_table.hours_short' />
                                    </React.Fragment>
                                ) : (
                                    <ClockCircleOutlined />
                                )}
                            </Button>
                        </Tooltip>
                    );
                }
            };

            const sumCol = {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.sum' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            (<FormattedMessage id='without' /> <FormattedMessage id='VAT' />)
                        </p>
                    </div>
                ),
                key: 'sum',
                width: NUMBER_CLMN_WIDTH,
                render: elem => {
                    const sum = elem.price * (elem.count || 1) || 0;
                    const discountSum = sum - (sum * elem.serviceRowDiscount) / 100;

                    const discount = discountSum.toFixed(4);
                    const sumValue = sum.toFixed(4);

                    return (
                        <Tooltip
                            placement='bottomRight'
                            title={
                                <div>
                                    <FormattedMessage id='order_form_table.sum' />{' '}
                                    <span
                                        style={{
                                            fontSize: 12,
                                            fontWeight: 400
                                        }}
                                    >
                                        <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                                    </span>
                                </div>
                            }
                        >
                            <div className={Styles.detailNumberInput}>
                                <InputNumber
                                    className='detailNumberInput'
                                    data-qa='input_number_sum_add_service_modal'
                                    decimalSeparator=','
                                    disabled
                                    formatter={value => numeralFormatter(value)}
                                    parser={value => numeralParser(value)}
                                    precision={4}
                                    readOnly
                                    style={{
                                        width: '100%',
                                        minWidth: 60,
                                        fontSize: discountSum > 9999 || sum > 9999 ? 11 : 13
                                    }}
                                    value={elem.serviceRowDiscount > 0 ? discount : sumValue}
                                />
                            </div>
                        </Tooltip>
                    );
                }
            };

            const deleteCol = {
                key: 'delete',
                width: '6px',
                render: elem => {
                    return (
                        <CloseOutlined
                            onClick={() => {
                                elem.storeGroupId = this.state.editing || elem.related ? elem.storeGroupId : undefined;
                                elem.masterLaborId =
                                    this.state.editing || elem.related ? elem.masterLaborId : undefined;
                                elem.serviceName = undefined;
                                elem.comment = undefined;
                                elem.purchasePrice = 0;
                                elem.price = 1;
                                elem.count = 1;
                                elem.hours = 0;
                                elem.sum = undefined;
                                this.setState({});
                            }}
                        />
                    );
                }
            };

            return {
                upperRow: [
                    laborCol,
                    storeGroupCol,
                    serviceTypeCol,
                    commentCol,
                    purchasePriceCol,
                    priceCol,
                    serviceRowDiscountCol
                ],
                lowerRow: [detailNameCol, masterOrSupplierCol, normHoursCol, countCol, unitsCol, sumCol],
                mobileRow: [
                    storeGroupCol,
                    serviceTypeCol,
                    laborCol,
                    detailNameCol,
                    masterOrSupplierCol,
                    priceCol,
                    countCol,
                    sumCol
                ]
            };
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { visible, labor, labors, employees, defaultEmployeeId } = this.props;
        const editing = Boolean(labor && labor.id);
        if (labors.length && !this.servicesOptions.length) {
            this.servicesOptions = [...labors];
            this.setState({});
        }

        if ((prevProps.visible == false && visible) || prevProps.labors !== this.props.labors) {
            this.getOptions();
            this.state.mainTableSource = [{ ...labor }];
            if (!editing) {
                const employee = employees.find(({ id }) => id == defaultEmployeeId);

                this.state.mainTableSource[0].employeeId = defaultEmployeeId;
                this.state.mainTableSource[0].counterparty = 'EMPLOYEE';
                this.state.mainTableSource[0].employeeName = `${_.get(employee, 'surname', '')} ${_.get(
                    employee,
                    'name',
                    ''
                )}`;
            }

            this.setState({
                editing
            });
        }
    }

    handleOk = () => {
        const { isMobile, beginDatetime, orderServices } = this.props;
        const { editing, mainTableSource, relatedServices, relatedServicesCheckbox, relatedDetailCheckbox } =
            this.state;

        if (mainTableSource[0].laborId === undefined) {
            notification.warning({
                message: 'Заповніть всі необхідні поля!'
            });

            return;
        }
        if (editing) {
            if (mainTableSource[0].startTime && mainTableSource[0].endTime) {
                if (dayjs(mainTableSource[0].endTime).isBefore(dayjs(mainTableSource[0].startTime))) {
                    notification.error({ message: 'Перевірте правильність вводу дат!' });

                    return;
                }
            }
            this.props.updateLabor(this.props.tableKey, { ...mainTableSource[0] });
        } else {
            // const defaultUse = _.get(
            //     this.props.unitDefault.filter(({ defaultUse }) => defaultUse),
            //     '[0].id'
            // );

            const data = {
                insertMode: true,
                details: [],
                services: []
            };
            mainTableSource.map(element => {
                data.services.push({
                    serviceId: element.laborId,
                    laborUnitId: !isMobile
                        ? element.laborUnitId ||
                          _.get(
                              this.props.unitDefault.filter(({ defaultUse }) => defaultUse),
                              '[0].id'
                          )
                        : element.laborUnitId || 1,
                    serviceName: element.serviceName,
                    counterparty: element.counterparty,
                    employeeId: element.employeeId || null,
                    businessSupplierId: element.businessSupplierId || null,
                    serviceHours: element.hours || 0,
                    purchasePrice: element.purchasePrice || 0,
                    count: element.count || 0,
                    servicePrice: element.price || 0,
                    serviceRowDiscount: element.serviceRowDiscount || 0,
                    comment: element.comment || {
                        comment: undefined,
                        positions: []
                    },
                    specificationId: Number(element.specificationId || 0) || undefined,
                    startTime: orderServices.length
                        ? orderServices[orderServices.length - 1].endTime
                            ? String(dayjs(orderServices[orderServices.length - 1].endTime).format('YYYY-MM-DD HH:mm'))
                            : null
                        : String(dayjs(beginDatetime).format('YYYY-MM-DD HH:mm')),
                    endTime: orderServices.length
                        ? orderServices[orderServices.length - 1].endTime
                            ? String(dayjs(orderServices[orderServices.length - 1].endTime).format('YYYY-MM-DD HH:mm'))
                            : null
                        : String(dayjs(beginDatetime).format('YYYY-MM-DD HH:mm'))
                });
            });
            if (relatedServicesCheckbox) {
                relatedServices.map(element => {
                    if (element.checked) {
                        data.services.push({
                            serviceId: element.laborId,
                            laborUnitId: !isMobile
                                ? element.laborUnitId ||
                                  _.get(
                                      this.props.unitDefault.filter(({ defaultUse }) => defaultUse),
                                      '[0].id'
                                  )
                                : element.laborUnitId || 1,
                            serviceName: element.serviceName,
                            counterparty: element.counterparty,
                            employeeId: element.employeeId || null,
                            businessSupplierId: element.businessSupplierId || null,
                            serviceHours: element.hours || 0,
                            purchasePrice: Math.round(element.purchasePrice * 100) / 100 || 0,
                            count: element.count || 0,
                            servicePrice: Math.round(element.price * 100) / 100 || 0,
                            serviceRowDiscount: element.serviceRowDiscount || 0,
                            comment: element.comment || {
                                comment: undefined,
                                positions: []
                            }
                        });
                    }
                });
            }
            if (relatedDetailCheckbox) {
                data.details.push({
                    storeGroupId: mainTableSource[0].storeGroupId
                });
                data.modificationId = this.props.tecdocId || undefined;
            }
            this.addDetailsAndLabors(data);
        }
        this.props.handleSearchLabors(undefined);
        this.props.hideModal();
    };

    handleCancel = () => {
        this.setState({
            mainTableSource: [],
            relatedServices: [],
            relatedServicesCheckbox: false,
            editTime: false,
            commentForCalls: undefined,
            laborSearch: undefined
        });
        this.props.handleSearchLabors(undefined);
        this.props.hideModal();
    };

    async getRelatedLabors(laborId) {
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/labors/related?id=${laborId}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                }
            });
            const result = await response.json();
            if (result.labors && result.labors.length) {
                this.setState({
                    relatedServices: result.labors[0].relatedLabors.map(labor => {
                        return {
                            ...labor,
                            laborId: labor.id,
                            related: true,
                            serviceName: labor.name,
                            storeGroupId: labor.storeGroupId,
                            customName: labor.customName,
                            count: labor.laborPrice.normHours || 1,
                            price: labor.laborPrice.price || 300,
                            employeeId: this.props.defaultEmployeeId,
                            counterparty: 'EMPLOYEE',
                            comment: {
                                comment: undefined,
                                positions: [],
                                problems: []
                            },
                            checked: true
                        };
                    })
                });
            }
        } catch (error) {
            console.error('ERROR:', error);
        }
    }

    async addDetailsAndLabors(data) {
        const { status } = await fetchAPI('GET', 'orders/status', { orderId: this.props.orderId }, null);
        if (status === 'success') {
            window.location.reload();

            return;
        }
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/orders/${this.props.orderId}`;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            if (result.success) {
                this.props.updateDataSource();
            } else {
                console.log('BAD', result);
            }
        } catch (error) {
            console.error('ERROR:', error);
        }
    }

    fetchData() {
        this.masterLabors = this.props.masterLabors;
        this.labors = this.props.labors;
        this.storeGroups = this.props.details;
        this.getOptions();
    }

    getOptions() {
        this.servicesOptions = [...this.props.labors];
        this.employeeOptions = this.props.employees.map((elem, i) => (
            <Option key={i} value={elem.id}>
                {elem.name} {elem.surname}
            </Option>
        ));
    }

    filterOptions(masterLaborId, storeGroupId, laborId) {
        let servicesOptions = [...this.props.labors];
        if (masterLaborId) {
            servicesOptions = servicesOptions.filter((elem, index) => elem.masterLaborId == masterLaborId);
        }
        if (storeGroupId) {
            servicesOptions = servicesOptions.filter((elem, index) => elem.storeGroupId == storeGroupId);
        }
        if (laborId) {
            servicesOptions = servicesOptions.filter((elem, index) => elem.id == laborId);
        }

        this.servicesOptions = [...servicesOptions];
    }

    deleteService = async () => {
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = `/orders/${this.props.orderId}/labors?ids=[${this.props.labor.id}]`;
        url += params;
        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                }
            });
            const result = await response.json();
            if (result.success) {
                this.props.updateDataSource();
                this.handleCancel();
            } else {
                console.log('BAD', result);
            }
        } catch (error) {
            console.error('ERROR:', error);
        }
    };

    confirmDelete = () => {
        const { formatMessage } = this.props.intl;
        const that = this;
        confirm({
            title: formatMessage({ id: 'add_order_form.delete_confirm' }),
            onOk() {
                that.deleteService();
            },
            okType: 'danger'
        });
    };

    getMobileForm() {
        const { mainTableSource } = this.state;
        const dataSource = mainTableSource[0] || {};
        const columns = this.columns();

        return columns.mobileRow.map(({ title, key, render, dataIndex }) => {
            return (
                <div
                    key={key}
                    className={`${Styles.mobileTable} ${
                        (key == 'price' || key == 'count') && Styles.mobileTableNumber
                    } ${key == 'employee' && Styles.mobileTableEmployee} ${
                        key == 'comment' && Styles.mobileTableComment
                    } ${key == 'sum' && Styles.mobileTableSum} `}
                >
                    {key != 'comment' && title}
                    <div>{dataIndex ? render(dataSource[dataIndex], dataSource) : render(dataSource)}</div>
                </div>
            );
        });
    }

    render() {
        const { visible, isMobile, unitDefault, labors } = this.props;
        const {
            relatedServicesCheckbox,
            mainTableSource,
            relatedServices,
            editing,
            relatedDetailCheckbox,
            editTime,
            commentForCalls
        } = this.state;

        const columns = this.columns();

        return (
            <React.Fragment>
                <Modal
                    footer={
                        isMobile && editing ? (
                            <div>
                                <Button
                                    data-qa='button_confirm_delete_add_service_modal'
                                    onClick={() => this.confirmDelete()}
                                    style={{
                                        float: 'left'
                                    }}
                                    type='danger'
                                >
                                    <DeleteOutlined />
                                </Button>
                                <Button
                                    data-qa='button_handle_cancel_add_service_modal'
                                    onClick={() => this.handleCancel()}
                                >
                                    <FormattedMessage id='cancel' />
                                </Button>
                                <Button
                                    data-qa='button_handle_ok_add_service_modal'
                                    onClick={() => this.handleOk()}
                                    type='primary'
                                >
                                    <FormattedMessage id='save' />
                                </Button>
                            </div>
                        ) : (
                            void 0
                        )
                    }
                    forceRender
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='add_labor' />}
                    visible={visible}
                    width='90%'
                    wrapClassName='addLaborModal'
                >
                    <div className={Styles.tableWrap}>
                        {!isMobile ? (
                            <div className='tableWrap'>
                                <Table
                                    className='ordDetailModalTable'
                                    columns={columns.upperRow}
                                    dataSource={mainTableSource}
                                    pagination={false}
                                    showHeader={false}
                                    size='small'
                                />
                                <Table
                                    className='ordDetailModalTable'
                                    columns={columns.lowerRow}
                                    dataSource={mainTableSource}
                                    pagination={false}
                                    showHeader={false}
                                    size='small'
                                />
                            </div>
                        ) : (
                            this.getMobileForm()
                        )}
                    </div>
                    {!isMobile && (
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <div
                                style={{
                                    marginRight: 14
                                }}
                            >
                                <FormattedMessage id='add_order_form.related' />
                                :
                                <FormattedMessage id='add_order_form.services' />
                                <Checkbox
                                    checked={relatedServicesCheckbox}
                                    data-qa='checkbox_related_services_add_service_modal'
                                    disabled={editing}
                                    onChange={() => {
                                        this.setState({
                                            relatedServicesCheckbox: !relatedServicesCheckbox
                                        });
                                    }}
                                    style={{ marginLeft: 5, marginRight: 14 }}
                                />
                                <FormattedMessage id='add_order_form.details' />
                                <Checkbox
                                    checked={relatedDetailCheckbox}
                                    disabled={editing}
                                    onChange={() => {
                                        this.setState({
                                            relatedDetailCheckbox: !relatedDetailCheckbox
                                        });
                                        localStorage.setItem(
                                            '_my.carbook.relatedDetailCheckbox',
                                            !relatedDetailCheckbox
                                        );
                                    }}
                                    style={{ marginLeft: 5 }}
                                />
                                {editing && (
                                    <Space style={{ marginLeft: 18 }}>
                                        <FormattedMessage id='refactored_cash_order_modal.edit_service_duration' />
                                        <Checkbox
                                            checked={editTime}
                                            onChange={() => {
                                                this.setState({
                                                    editTime: !editTime
                                                });
                                            }}
                                            style={{ marginLeft: 5 }}
                                        />
                                    </Space>
                                )}
                                {relatedServicesCheckbox && (
                                    <div className={Styles.tableWrap}>
                                        <Table
                                            bordered
                                            columns={[...columns.upperRow, ...columns.lowerRow]}
                                            dataSource={relatedServices}
                                            pagination={false}
                                            rowKey='laborId'
                                            size='small'
                                        />
                                    </div>
                                )}
                            </div>
                            <div
                                style={{
                                    maxWidth: '50%'
                                }}
                            >
                                {commentForCalls && (
                                    <div>
                                        <FormattedMessage id='labor.comment_from_directory' />:{' '}
                                        <span className={Styles.commentStyle}>{`' ${commentForCalls} '`}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {editTime && !isMobile && (
                        <Space style={{ marginTop: 14 }}>
                            <Space>
                                <span>
                                    <FormattedMessage id='start' />:
                                </span>
                                <DatePicker
                                    format='DD-MM-YY'
                                    onChange={time => {
                                        mainTableSource[0].startTime = time.toISOString();
                                        this.setState({ mainTableSource });
                                    }}
                                    value={
                                        mainTableSource[0].startTime ? dayjs(mainTableSource[0].startTime) : undefined
                                    }
                                />
                                <TimePicker
                                    format='HH:mm'
                                    onChange={time => {
                                        mainTableSource[0].startTime = time.toISOString();
                                        this.setState({ mainTableSource });
                                    }}
                                    value={
                                        mainTableSource[0].startTime ? dayjs(mainTableSource[0].startTime) : undefined
                                    }
                                />
                            </Space>
                            <Space>
                                <span>
                                    <FormattedMessage id='end_1' />:
                                </span>
                                <DatePicker
                                    format='DD-MM-YY'
                                    onChange={time => {
                                        mainTableSource[0].endTime = time.toISOString();
                                        this.setState({ mainTableSource });
                                    }}
                                    value={mainTableSource[0].endTime ? dayjs(mainTableSource[0].endTime) : undefined}
                                />
                                <TimePicker
                                    format='HH:mm'
                                    onChange={time => {
                                        mainTableSource[0].endTime = time.toISOString();
                                        this.setState({ mainTableSource });
                                    }}
                                    value={mainTableSource[0].endTime ? dayjs(mainTableSource[0].endTime) : undefined}
                                />
                            </Space>
                        </Space>
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}
export default AddServiceModal;

@injectIntl
class CommentaryButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            currentCommentaryProps: {
                name: props.detail,
                positions: [],
                problems: []
            },
            currentCommentary: undefined
        };
        this.commentaryInput = React.createRef();
        this.positions = [
            'front_axle',
            'ahead',
            'overhead',
            'rear_axle',
            'behind',
            'down_below',
            'Right_wheel',
            'on_right',
            'outside',
            'left_wheel',
            'left',
            'inside',
            'lever_arm',
            'at_both_sides',
            'centered'
        ];
        this._isMounted = false;
    }

    showModal = () => {
        this.setState({
            currentCommentary: this.props.commentary.comment ? this.props.commentary.comment : this.props.detail,
            visible: true
        });
        if (this.commentaryInput.current) {
            try {
                this.commentaryInput.current.focus();
            } catch (e) {}
        }

        if (!this.props.commentary.comment) {
            this.setState({
                currentCommentaryProps: {
                    name: this.props.detail,
                    positions: [],
                    problems: []
                }
            });
        }
    };

    handleOk = async () => {
        const { currentCommentary, currentCommentaryProps } = this.state;
        this.setState({
            loading: true
        });
        this.props.setComment(currentCommentary, currentCommentaryProps.positions, currentCommentaryProps.problems);
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
            this.props.handleSearchLabors();
        }, 100);
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            currentCommentary: this.props.detail,
            currentCommentaryProps: {
                name: this.props.detail,
                positions: [],
                problems: []
            }
        });
    };

    renderHeader = () => {
        return (
            <div>
                <p>{this.props.detail}</p>
            </div>
        );
    };

    getCommentary() {
        const { currentCommentaryProps } = this.state;
        let currentCommentary = this.props.detail;

        if (currentCommentaryProps.positions.length) {
            currentCommentary += ' -';
            currentCommentary += `${currentCommentaryProps.positions.map(
                data => ` ${this.props.intl.formatMessage({ id: data }).toLowerCase()}`
            )};`;
        }
        this.setState({
            currentCommentary
        });
    }

    setCommentaryPosition(position) {
        const { currentCommentaryProps } = this.state;
        const positionIndex = currentCommentaryProps.positions.indexOf(position);
        if (positionIndex == -1) {
            currentCommentaryProps.positions.push(position);
        } else {
            currentCommentaryProps.positions = currentCommentaryProps.positions.filter(
                (value, index) => index != positionIndex
            );
        }
        this.getCommentary();
    }

    componentDidMount() {
        this._isMounted = true;
        const { commentary, detail } = this.props;
        if (this._isMounted) {
            this.setState({
                currentCommentaryProps: {
                    name: detail,
                    positions: commentary.positions || [],
                    problems: commentary.problems || []
                }
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { TextArea } = Input;
        const { visible, loading, currentCommentaryProps, currentCommentary } = this.state;
        const { disabled, commentary } = this.props;
        const { positions } = this;

        return (
            <div>
                {commentary.comment ? (
                    <Button
                        className={Styles.commentaryButton}
                        data-qa='button_commentary_edit_add_service_modal'
                        onClick={this.showModal}
                        title={this.props.intl.formatMessage({ id: 'commentary.edit' })}
                        type='primary'
                    >
                        <FormOutlined className={Styles.commentaryButtonIcon} />
                    </Button>
                ) : (
                    <Tooltip title={<FormattedMessage id='commentary.add' />} zIndex={2001}>
                        <Button
                            data-qa='button_commentary_add_add_service_modal'
                            disabled={disabled}
                            onClick={this.showModal}
                        >
                            <MessageOutlined />
                        </Button>
                    </Tooltip>
                )}
                <Modal
                    footer={
                        disabled
                            ? null
                            : [
                                  <Button
                                      key='back'
                                      data-qa='button_handle_cancel_commentary_button_add_service_modal'
                                      onClick={this.handleCancel}
                                  >
                                      <FormattedMessage id='cancel' />
                                  </Button>,
                                  <Button
                                      key='submit'
                                      data-qa='button_handle_ok_commentary_button_add_service_modal'
                                      loading={loading}
                                      onClick={this.handleOk}
                                      type='primary'
                                  >
                                      <FormattedMessage id='save' />
                                  </Button>
                              ]
                    }
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={this.renderHeader()}
                    visible={visible}
                >
                    <React.Fragment>
                        <div className={Styles.commentaryVehicleSchemeWrap}>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='commentary_modal.where' />?
                            </p>
                            <div className={Styles.blockButtonsWrap}>
                                {positions.map((position, key) => {
                                    return (
                                        <Button
                                            key={key}
                                            className={Styles.commentaryBlockButton}
                                            data-qa='button_set_comementary_block_commentary_button_add_service_modal'
                                            onClick={() => {
                                                this.setCommentaryPosition(position);
                                            }}
                                            type={
                                                currentCommentaryProps.positions.findIndex(elem => position == elem) ===
                                                -1
                                                    ? 'default'
                                                    : 'primary'
                                            }
                                        >
                                            <FormattedMessage id={position} />
                                        </Button>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='order_form_table.diagnostic.commentary' />
                            </p>
                            <TextArea
                                ref={this.commentaryInput}
                                autoFocus
                                data-qa='text_area_current_commentary_button_add_service_modal'
                                disabled={disabled}
                                onChange={() => {
                                    this.setState({
                                        currentCommentary: event.target.value
                                    });
                                }}
                                placeholder={`${this.props.intl.formatMessage({
                                    id: 'comment'
                                })}...`}
                                style={{ width: '100%', minHeight: '150px', resize: 'none' }}
                                value={currentCommentary}
                            />
                        </div>
                    </React.Fragment>
                </Modal>
            </div>
        );
    }
}

export { CommentaryButton };
