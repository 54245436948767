import {
    LoadingOutlined,
    MinusOutlined,
    PlusOutlined,
    UnorderedListOutlined,
    ZoomInOutlined,
    ZoomOutOutlined
} from '@ant-design/icons';
import { Button, Input, Modal, Radio, Slider, Spin, Table } from 'antd';
import { MODALS, setModal } from 'core/modals/duck';
import { get } from 'lodash';
import { AddLaborOrDetailToOrderModal } from 'modals';
import { selectVehicle } from 'pages/VehiclePage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { GridIcon } from 'theme';
import Styles from './styles.m.css';

const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

const mapStateToProps = state => ({
    vehicle: selectVehicle(state)
});

const mapDispatchToProps = {
    setModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VinTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayType: 'list',
            categoryMode: false,
            categories: [],
            visible: false,
            loading: false,
            zoomed: false,
            itemsInfo: [],
            blockPositions: [],
            tableHoverCode: undefined,
            imgHoverCode: undefined,
            imgHoverIndex: undefined,
            checkedCodes: [],
            infoModalVisible: false,
            infoItem: undefined,
            imageList: [],
            image: undefined,
            itemsListEmpty: false,
            zoomMultiplier: 0.75,
            allCategories: [],
            imgSearch: '',
            listItemPreview: [],
            previewIndex: undefined,
            variantRadioValue: 0
        };
        this.onImgLoad = this.onImgLoad.bind(this);

        this.columns = [
            {
                title: '№',
                key: 'key',
                dataIndex: 'key',
                width: 'auto',
                render: (data, elem) => {
                    const itemsInfo = this.state.itemsInfo.length
                        ? this.state.itemsInfo[this.state.itemsInfo.length - 1]
                        : [];
                    const isChecked = this.state.checkedCodes.indexOf(elem.codeonimage) >= 0;
                    const isVariant =
                        itemsInfo.findIndex(item => item.key == elem.key || item.codeonimage == elem.codeonimage) !=
                        elem.key;
                    const outputText = !isVariant || !elem.codeonimage ? elem.codeonimage || data + 1 : 'Var.';

                    return (
                        <span
                            style={{
                                color: isVariant ? 'var(--text2)' : 'var(--text1)',
                                fontSize: 12
                            }}
                        >
                            {isChecked ? (
                                <Radio
                                    checked={this.state.variantRadioValue == data}
                                    onChange={event => {
                                        this.setState({
                                            variantRadioValue: data
                                        });
                                    }}
                                >
                                    {outputText}
                                </Radio>
                            ) : (
                                outputText
                            )}
                        </span>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_code' />,
                key: 'oem',
                dataIndex: 'oem',
                width: 'auto',
                render: (data, elem) => {
                    return data;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'name',
                dataIndex: 'name',
                width: 'auto',
                render: (data, elem) => {
                    return (
                        <div>
                            <p>{data}</p>
                            <p style={{ fontSize: 12, color: 'var(--text2)' }}>
                                {(elem.attributes || []).map(attr => `${attr.name}: ${attr.value}`).join('; ')}
                            </p>
                        </div>
                    );
                }
            }
        ];
    }

    onImgLoad({ target: img }) {
        if (this.state.imageList.length) {
            this.state.imageList[this.state.imageList.length - 1].height = img.naturalHeight;
            this.state.imageList[this.state.imageList.length - 1].width = img.naturalWidth;
        }
        this.setState({});
    }

    handleOk = () => {
        const { checkedCodes, variantRadioValue } = this.state;
        const itemsInfo = this.state.itemsInfo.length ? this.state.itemsInfo[this.state.itemsInfo.length - 1] : [];
        const selectedItem = itemsInfo.find(item => item.key == variantRadioValue);

        if (selectedItem) {
            this.onAddDetailToOrder({
                count: 1,
                name: selectedItem.name,
                price: 1,
                productCode: selectedItem.oem,
                purchasePrice: 0,
                supplierBrandId: 8000 //
            });
        }

        // this.handleCancel();
    };

    handleCancel = () => {
        this.setState({
            displayType: 'list',
            categoryMode: false,
            categories: [],
            visible: false,
            loading: false,
            zoomed: false,
            itemsInfo: [],
            blockPositions: [],
            tableHoverCode: undefined,
            imgHoverCode: undefined,
            imgHoverIndex: undefined,
            checkedCodes: [],
            infoModalVisible: false,
            infoItem: undefined,
            imageList: [],
            image: undefined,
            itemsListEmpty: false,
            zoomMultiplier: 0.75,
            allCategories: [],
            imgSearch: '',
            listItemPreview: [],
            previewIndex: undefined,
            variantRadioValue: 0
        });
    };

    handleBack = () => {
        if (this.state.categoryMode && !this.props.storeGroupId) {
            this.setState({
                categories: this.state.allCategories,
                categoryMode: true
            });
        } else if (this.state.itemsInfo.length > 1) {
            const itemsInfo = this.state.itemsInfo.pop();
            const blockPositions = this.state.blockPositions.pop();
            const imageList = this.state.imageList.pop();
            this.setState({
                itemsInfo,
                blockPositions,
                imageList,
                checkedCodes: []
            });
        } else {
            this.setState({
                categoryMode: true,
                checkedCodes: []
            });
        }
    };

    fetchData() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        if (this.props.storeGroupId) {
            const url = `${__API_URL__}/vin/list_quick_detail?vin=${this.props.vehicle.vehicleVin}&storeGroupIds=[${this.props.storeGroupId}]`;
            fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: token
                }
            })
                .then(function (response) {
                    if (response.status !== 200) {
                        return Promise.reject(new Error(response.statusText));
                    }

                    return Promise.resolve(response);
                })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    if (data) {
                        const { catalog, ssd, vehicleid } = data.vehicle;
                        const categoriesArray = data.categories;
                        const normalizedCategories = [];

                        if (categoriesArray.length) {
                            categoriesArray.map(elem => {
                                if (elem.units.length == 1) {
                                    elem.name = elem.units[0].name;
                                    normalizedCategories.push({
                                        catalog,
                                        vehicleId: vehicleid,
                                        ssd,
                                        ...elem,
                                        unit: { ...elem.units[0] }
                                    });
                                } else {
                                    elem.units.map(unit => {
                                        elem.name = unit.name;
                                        normalizedCategories.push({
                                            catalog,
                                            vehicleId: vehicleid,
                                            ssd,
                                            ...elem,
                                            unit
                                        });
                                    });
                                }
                            });
                        }
                        if (normalizedCategories.length == 1) {
                            that.setState({
                                loading: false,
                                categoryMode: false,
                                categories: normalizedCategories,
                                displayType: 'grid'
                            });
                            that.fetchItemsList(
                                normalizedCategories[0].unit.ssd,
                                normalizedCategories[0].unit.unitid,
                                normalizedCategories[0].catalog
                            );
                        } else {
                            for (let i = 0; i < normalizedCategories.length % 3; i++) {
                                normalizedCategories.push({
                                    emptyElement: true
                                });
                            }
                            that.setState({
                                loading: false,
                                displayType: 'grid',
                                categoryMode: normalizedCategories.length,
                                categories: normalizedCategories
                            });
                        }
                    } else {
                        that.setState({
                            loading: false,
                            itemsListEmpty: true
                        });
                    }
                })
                .catch(function (error) {
                    console.log('error', error);
                    that.setState({
                        loading: false,
                        itemsListEmpty: true
                    });
                });
        } else {
            const url = `${__API_URL__}/vin/categories?vin=${this.props.vehicle.vehicleVin}`;
            fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: token
                }
            })
                .then(function (response) {
                    if (response.status !== 200) {
                        return Promise.reject(new Error(response.statusText));
                    }

                    return Promise.resolve(response);
                })
                .then(function (response) {
                    return response.json();
                })
                .then(function ({ data }) {
                    if (data) {
                        const { catalog, ssd, vehicleid } = data.response.FindVehicle.response.FindVehicle[0].row[0].$;
                        const categoriesArray = data.response.ListCategories[0].row;
                        const normalizedCategories = [];

                        if (categoriesArray.length) {
                            categoriesArray.map(elem => {
                                normalizedCategories.push({
                                    catalog,
                                    vehicleId: vehicleid,
                                    ...elem.$,
                                    unit: { ...elem.$ }
                                });
                            });
                        }
                        if (normalizedCategories.length == 1) {
                            that.setState({
                                loading: false,
                                categoryMode: false,
                                categories: normalizedCategories
                            });
                            that.fetchItemsList(
                                normalizedCategories[0].unit.ssd,
                                normalizedCategories[0].unit.unitid,
                                normalizedCategories[0].catalog
                            );
                        } else {
                            if (that.state.displayType == 'grid') {
                                for (let i = 0; i < normalizedCategories.length % 5; i++) {
                                    normalizedCategories.push({
                                        emptyElement: true
                                    });
                                }
                            }
                            that.setState({
                                loading: false,
                                categoryMode: normalizedCategories.length,
                                categories: normalizedCategories,
                                allCategories: normalizedCategories
                            });
                        }
                        if (that.state.displayType == 'list' && normalizedCategories.length) {
                            that.fetchCategoryItemsList(
                                normalizedCategories[0].ssd,
                                normalizedCategories[0].catalog,
                                normalizedCategories[0].categoryid,
                                normalizedCategories[0].vehicleId,
                                true,
                                0
                            );
                        }
                    } else {
                        that.setState({
                            loading: false,
                            itemsListEmpty: true
                        });
                    }
                })
                .catch(function (error) {
                    console.log('error', error);
                    that.setState({
                        loading: false,
                        itemsListEmpty: true
                    });
                });
        }
    }

    fetchItemsList(ssd, unitId, catalog) {
        this.setState({
            loading: true
        });
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/vin/list_detail_by_unit_id?ssd=${ssd}` + `&unitId=${unitId}&catalog=${catalog}`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function ({ data }) {
                const itemsInfo = [];
                const blockPositions = [];
                const image = get(data.response.GetUnitInfo, '[0].row[0].$'); // data.response.GetUnitInfo[0].row[0].$;
                get(data.response.ListDetailsByUnit, '[0].row', []).map(({ $: item, attribute }, key) => {
                    itemsInfo.push({
                        key,
                        ...item,
                        attributes: attribute && attribute.map(attr => attr.$)
                    });
                });

                get(data.response.ListImageMapByUnit, '[0].row', []).map(({ $: position }, key) => {
                    blockPositions.push({
                        ...position,
                        key
                    });
                });
                that.setState({
                    loading: false,
                    imageList: [image],
                    itemsInfo: [itemsInfo],
                    blockPositions: [blockPositions],
                    categoryMode: false
                });
            })
            .catch(function (error) {
                console.log('error', error);
                that.setState({
                    loading: false,
                    itemsInfo: [],
                    blockPositions: [],
                    categoryMode: false
                });
            });
    }

    fetchCategoryItemsList(ssd, catalog, categoryid, vehicleId, previewMode = false, index) {
        if (!previewMode) {
            this.setState({
                loading: true
            });
        } else {
            this.setState({
                previewIndex: index
            });
        }
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/vin/list_units?catalog=${catalog}&vehicleid=${vehicleId}&categoryId=${categoryid}&ssd=${ssd}`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function ({ data }) {
                if (data) {
                    const categoriesArray = data.response.ListUnits[0].row;
                    const normalizedCategories = [];

                    if (categoriesArray.length) {
                        categoriesArray.map(elem => {
                            normalizedCategories.push({
                                catalog,
                                vehicleId,
                                ...elem.$,
                                unit: { ...elem.$ }
                            });
                        });
                    }
                    if (previewMode) {
                        for (var i = 0; i < normalizedCategories.length % 3; i++) {
                            normalizedCategories.push({
                                emptyElement: true
                            });
                        }
                        that.setState({
                            loading: false,
                            listItemPreview: normalizedCategories
                        });
                    } else if (normalizedCategories.length == 1) {
                        that.setState({
                            loading: false,
                            categoryMode: false,
                            categories: normalizedCategories
                        });
                        that.fetchItemsList(
                            normalizedCategories[0].unit.ssd,
                            normalizedCategories[0].unit.unitid,
                            normalizedCategories[0].catalog
                        );
                    } else {
                        for (var i = 0; i < normalizedCategories.length % 5; i++) {
                            normalizedCategories.push({
                                emptyElement: true
                            });
                        }
                        that.setState({
                            loading: false,
                            categoryMode: normalizedCategories.length,
                            categories: normalizedCategories
                        });
                    }
                } else {
                    that.setState({
                        loading: false,
                        itemsListEmpty: true
                    });
                }
            })
            .catch(function (error) {
                console.log('error', error);
                that.setState({
                    loading: false,
                    itemsInfo: [],
                    blockPositions: [],
                    categoryMode: true
                });
            });
    }

    searchImage(code) {
        this.setState({
            loading: true
        });
    }

    onAddDetailToOrder = detail => {
        const { vehicle } = this.props;
        this.props.setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
            details: [detail],
            mode: 'ADD_DETAIL',
            vehicleId: vehicle.id
        });
    };

    componentDidMount() {
        this.setState({
            loading: true
        });

        this.fetchData();
    }

    render() {
        const { vehicle, disabled } = this.props;

        const {
            displayType,
            // visible,
            zoomed,
            // positions,
            tableHoverCode,
            imgHoverCode,
            imgHoverIndex,
            checkedCodes,
            infoItem,
            infoModalVisible,
            loading,
            categories,
            categoryMode,
            // imageList,
            itemsListEmpty,
            zoomMultiplier,
            allCategories,
            imgSearch,
            listItemPreview,
            previewIndex
        } = this.state;

        const itemsInfo = this.state.itemsInfo.length ? this.state.itemsInfo[this.state.itemsInfo.length - 1] : [];
        const blockPositions = this.state.blockPositions.length
            ? this.state.blockPositions[this.state.blockPositions.length - 1]
            : [];
        const image = this.state.imageList.length ? this.state.imageList[this.state.imageList.length - 1] : undefined;

        return (
            <React.Fragment>
                <div
                    style={{
                        maxWidth: '100%',
                        minWidth: displayType == 'list' ? '95%' : '80%'
                    }}
                    width='fit-content'
                >
                    {!loading &&
                        !itemsListEmpty &&
                        categoryMode &&
                        !this.props.storeGroupId &&
                        allCategories &&
                        allCategories == categories && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    margin: '0 0 8px 0'
                                }}
                            >
                                <div style={{ fontSize: 18 }}>
                                    {displayType == 'list' && categories.length > previewIndex
                                        ? categories[previewIndex].name
                                        : null}
                                </div>
                                <Radio.Group
                                    buttonStyle='solid'
                                    onChange={event => {
                                        this.setState({
                                            displayType: event.target.value
                                        });
                                    }}
                                    value={displayType}
                                >
                                    <Radio.Button value='list'>
                                        <UnorderedListOutlined
                                            style={{
                                                fontSize: 18,
                                                verticalAlign: 'middle'
                                            }}
                                        />
                                    </Radio.Button>
                                    <Radio.Button value='grid'>
                                        <GridIcon
                                            style={{
                                                fontSize: 18,
                                                verticalAlign: 'middle'
                                            }}
                                        />
                                    </Radio.Button>
                                </Radio.Group>
                            </div>
                        )}
                    {!loading &&
                        !itemsListEmpty &&
                        (!categoryMode ||
                            (!this.props.storeGroupId && allCategories && allCategories != categories)) && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    margin: '0 0 8px 0'
                                }}
                            >
                                <div style={{ display: 'none' }}>
                                    <Input
                                        onChange={event => {
                                            this.setState({
                                                imgSearch: event.target.value
                                            });
                                        }}
                                    />
                                    <Button
                                        onClick={() => {
                                            this.searchImage(imgSearch);
                                        }}
                                    >
                                        <FormattedMessage id='back' />
                                    </Button>
                                </div>
                                <Button key='back' onClick={this.handleBack}>
                                    <FormattedMessage id='step_back' />
                                </Button>
                                <Button
                                    key='submit'
                                    disabled={checkedCodes.length == 0 || disabled}
                                    onClick={this.handleOk}
                                    style={{ marginLeft: 10 }}
                                    type='primary'
                                >
                                    <FormattedMessage id='select' />
                                </Button>
                            </div>
                        )}
                    {loading ? (
                        <Spin indicator={spinIcon} />
                    ) : categoryMode && !itemsListEmpty ? (
                        <div className={Styles.categoryList}>
                            {displayType == 'grid' ? (
                                categories.map((category, key) => {
                                    return category.emptyElement ? (
                                        <div key={key} className={Styles.emptyItem} style={{ pointerEvents: 'none' }} />
                                    ) : (
                                        <div
                                            key={key}
                                            className={Styles.categoryItem}
                                            onClick={() => {
                                                if (category.unit.imageurl) {
                                                    this.fetchItemsList(
                                                        category.unit.ssd,
                                                        category.unit.unitid,
                                                        category.catalog
                                                    );
                                                } else {
                                                    this.fetchCategoryItemsList(
                                                        category.ssd,
                                                        category.catalog,
                                                        category.categoryid,
                                                        category.vehicleId
                                                    );
                                                }
                                            }}
                                        >
                                            {category.unit.imageurl && (
                                                <img
                                                    src={category.unit.imageurl.replace('%size%', 'source')}
                                                    style={{
                                                        cursor: 'pointer',
                                                        width: '100%'
                                                    }}
                                                    title={category.unit.name}
                                                />
                                            )}
                                            <div className={Styles.categoryName}>{category.name}</div>
                                        </div>
                                    );
                                })
                            ) : (
                                <React.Fragment>
                                    <div className={Styles.allCategoriesList}>
                                        {categories.map((category, key) => {
                                            return category.emptyElement ? (
                                                <div
                                                    key={key}
                                                    className={Styles.emptyItem}
                                                    style={{ pointerEvents: 'none' }}
                                                />
                                            ) : (
                                                <div
                                                    key={key}
                                                    className={Styles.categoryListItem}
                                                    onClick={() => {
                                                        this.fetchCategoryItemsList(
                                                            category.ssd,
                                                            category.catalog,
                                                            category.categoryid,
                                                            category.vehicleId,
                                                            true,
                                                            key
                                                        );
                                                    }}
                                                    style={
                                                        previewIndex == key
                                                            ? {
                                                                  backgroundColor: 'var(--db_reserve)'
                                                              }
                                                            : {}
                                                    }
                                                >
                                                    <div className={Styles.categoryListName}>{category.name}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={Styles.previewBLock}>
                                        {listItemPreview.length == 0 ? (
                                            <FormattedMessage id='no_data' />
                                        ) : (
                                            listItemPreview.map((item, key) => {
                                                return item.emptyElement ? (
                                                    <div
                                                        key={key}
                                                        className={Styles.emptyItem}
                                                        style={{
                                                            pointerEvents: 'none',
                                                            width: '33%'
                                                        }}
                                                    />
                                                ) : (
                                                    <div
                                                        key={key}
                                                        className={Styles.categoryItem}
                                                        onClick={() => {
                                                            this.fetchItemsList(
                                                                item.unit.ssd,
                                                                item.unit.unitid,
                                                                item.catalog
                                                            );
                                                        }}
                                                        style={{
                                                            width: '33%',
                                                            height: 'fit-content'
                                                        }}
                                                    >
                                                        <img
                                                            src={item.unit.imageurl.replace('%size%', 'source')}
                                                            style={{
                                                                cursor: 'pointer',
                                                                width: '100%'
                                                            }}
                                                            title={item.unit.name}
                                                        />
                                                        <div className={Styles.categoryName}>{item.name}</div>
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    ) : !itemsListEmpty ? (
                        <React.Fragment>
                            <div className={Styles.categoryTitle}>{image && image.name}</div>
                            <div className={Styles.vinModal}>
                                <div className={Styles.imgWrap}>
                                    <div className={Styles.zoomActionBlock}>
                                        {zoomed ? (
                                            <ZoomOutOutlined
                                                onClick={() => {
                                                    this.setState({
                                                        zoomed: !zoomed
                                                    });
                                                }}
                                                style={{
                                                    fontSize: 24,
                                                    zIndex: 9999
                                                }}
                                            />
                                        ) : (
                                            <ZoomInOutlined
                                                onClick={() => {
                                                    this.setState({
                                                        zoomed: !zoomed
                                                    });
                                                }}
                                                style={{
                                                    fontSize: 24,
                                                    zIndex: 9999
                                                }}
                                            />
                                        )}
                                        <MinusOutlined
                                            style={{
                                                marginLeft: 15
                                            }}
                                        />
                                        <Slider
                                            dots
                                            max={2}
                                            min={0.25}
                                            onChange={value => {
                                                this.setState({ zoomMultiplier: value });
                                            }}
                                            step={0.1}
                                            style={{
                                                minWidth: 200
                                            }}
                                            value={zoomMultiplier}
                                        />
                                        <PlusOutlined />
                                    </div>
                                    <div
                                        className={Styles.zoomBlock}
                                        style={{
                                            width: `${100 * zoomMultiplier}%`
                                        }}
                                    >
                                        {blockPositions.map((item, key) => {
                                            const { code } = item;
                                            const mainItem = itemsInfo.find(elem => elem.codeonimage == code);
                                            const title = mainItem ? mainItem.name : '';
                                            const isHovered = imgHoverCode == code || imgHoverIndex == key;
                                            const isChecked = checkedCodes.indexOf(code) >= 0;

                                            return (
                                                <div
                                                    key={key}
                                                    className={`${Styles.zoomBlockItem} ${
                                                        isHovered && Styles.hoveredItem
                                                    } ${isChecked && Styles.checkedItem}`}
                                                    onClick={event => {
                                                        if (event.ctrlKey) {
                                                            if (!isChecked) {
                                                                checkedCodes.push(code);
                                                                this.setState({});
                                                            } else {
                                                                this.setState({
                                                                    checkedCodes: checkedCodes.filter(
                                                                        index => index != code
                                                                    )
                                                                });
                                                            }
                                                        } else {
                                                            this.setState({
                                                                checkedCodes: [code],
                                                                variantRadioValue: mainItem.key
                                                            });
                                                        }
                                                    }}
                                                    onMouseEnter={event => {
                                                        this.setState({
                                                            tableHoverCode: code,
                                                            imgHoverIndex: key
                                                        });
                                                    }}
                                                    onMouseLeave={event => {
                                                        this.setState({
                                                            tableHoverCode: undefined,
                                                            imgHoverIndex: undefined
                                                        });
                                                    }}
                                                    style={{
                                                        left: `${(item.x1 / image.width) * 100}%`,
                                                        top: `${(item.y1 / image.height) * 100}%`,
                                                        width: `${((item.x2 - item.x1) / image.width) * 100}%`,
                                                        height: `${((item.y2 - item.y1) / image.height) * 100}%`
                                                    }}
                                                    title={title}
                                                />
                                            );
                                        })}
                                        <img
                                            onLoad={this.onImgLoad}
                                            src={`${image && image.imageurl.replace('%size%', 'source')}`}
                                            width={`${100}%`}
                                        />
                                        <Modal
                                            footer={[]}
                                            maskClosable={false}
                                            onCancel={() => {
                                                this.setState({
                                                    zoomed: false
                                                });
                                            }}
                                            title={image && image.name.toUpperCase()}
                                            visible={zoomed}
                                            width='85%'
                                        >
                                            <img
                                                src={`${image && image.imageurl.replace('%size%', 'source')}`}
                                                width='100%'
                                            />
                                        </Modal>
                                    </div>
                                </div>
                                <div className={Styles.listWrap}>
                                    <Table
                                        bordered
                                        columns={this.columns}
                                        dataSource={itemsInfo}
                                        loading={loading}
                                        onRow={(record, rowIndex) => {
                                            const code = record.codeonimage;

                                            return {
                                                onClick: event => {
                                                    if (event.ctrlKey) {
                                                        const isChecked = checkedCodes.indexOf(code) >= 0;
                                                        if (!isChecked) {
                                                            checkedCodes.push(code);
                                                            this.setState({});
                                                        } else {
                                                            this.setState({
                                                                checkedCodes: checkedCodes.filter(
                                                                    index => index != code
                                                                )
                                                            });
                                                        }
                                                    } else {
                                                        this.setState({
                                                            checkedCodes: [code],
                                                            variantRadioValue: record.key
                                                        });
                                                    }
                                                },
                                                onMouseEnter: event => {
                                                    this.setState({
                                                        imgHoverCode: code
                                                    });
                                                },
                                                onMouseLeave: event => {
                                                    this.setState({
                                                        imgHoverCode: undefined
                                                    });
                                                }
                                            };
                                        }}
                                        pagination={false}
                                        rowClassName={(record, rowIndex) => {
                                            const code = record.codeonimage;
                                            const isHovered = tableHoverCode == code;
                                            const isChecked = checkedCodes.indexOf(code) >= 0;

                                            return `${Styles.listTableRow} ${isHovered && Styles.tableRowHovered} ${
                                                isChecked && Styles.checkedRow
                                            }`;
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'none',
                                    textAlign: 'end',
                                    fontSize: 12,
                                    color: 'var(--text2)'
                                }}
                            >
                                <i style={{ color: 'var(--required)' }}>* </i>Ctrl + click to select multiple item
                            </div>
                        </React.Fragment>
                    ) : (
                        <FormattedMessage id='no_data' />
                    )}
                    <Modal
                        footer={[]}
                        maskClosable={false}
                        onCancel={() => {
                            this.setState({
                                infoModalVisible: false
                            });
                        }}
                        title={infoItem && infoItem.name.toUpperCase()}
                        visible={infoModalVisible}
                    >
                        {infoItem && infoItem.body}
                    </Modal>
                    <AddLaborOrDetailToOrderModal />
                </div>
            </React.Fragment>
        );
    }
}
