import { EditOutlined } from '@ant-design/icons';
import { Button, Input, Menu, notification, Popconfirm, Switch, Tabs } from 'antd';
import { Layout } from 'commons';
import HamburgerMenu from 'components/HamburgerMenu';
import { pick } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import { BusinessRolesTab, BussinessPackagesTab, DefaultPackagesTab, DefaultRolesTab } from './components';

const forbiddenUpdateComplexFields = ['laborComplexId', 'laborBusinessId', 'productComplexId', 'productBusinessId'];

const { TabPane } = Tabs;

const cleanForEdit = data =>
    data.map(el =>
        pick(
            el,
            Object.keys(el).filter(key => el[key] !== null && !forbiddenUpdateComplexFields.includes(key))
        ));

const RefactoredRightsPage = ({ user, intl: { formatMessage } }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [createComplexOpen, setCreateComplexOpen] = useState(false);
    const [editObj, setEditObj] = useState(null);
    const [newName, setNewName] = useState('');
    const [additionalRoles, setAdditionalRoles] = useState([]);
    const [labors, setLabors] = useState([]);
    const [details, setDetails] = useState([]);
    const [brands, setBrands] = useState([]);
    const [complexes, setComplexes] = useState([]);

    const openComplexModal = (row = true) => setCreateComplexOpen(row);
    const hideComplexModal = () => setCreateComplexOpen(false);

    const fetchComplexes = async () => {
        const complexes = await fetchAPI(
            'GET',
            '/business/complexes',
            { types: ['PRODUCTION', 'OWN', 'DISASSEMBLY'] },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        setComplexes(complexes);
    };

    const fetchData = async () => {
        const [laborsResponse, details, brands] = await Promise.all([
            fetchAPI('GET', 'labors', null, null, {
                handleErrorInternally: true
            }),
            fetchAPI('GET', '/store_groups', undefined, undefined, {
                handleErrorInternally: true
            }),
            fetchAPI('GET', 'brands', undefined, undefined, {
                handleErrorInternally: true
            })
        ]);
        fetchComplexes();
        const { labors } = laborsResponse;
        setLabors(labors);
        setDetails(details);
        setBrands(brands);
    };

    const editComplexes = async complexes => {
        const response = await fetchAPI('PUT', '/business/complexes', null, { complexesData: complexes });

        return response;
    };
    const handleEditOpen = row => {
        setEditObj(row);
        setNewName(row.name);
    };
    const handleEditClose = () => {
        setEditObj(null);
        setNewName('');
    };
    const handleEdit = useMemo(
        () => async () => {
            console.log(editObj, [
                {
                    name: newName,
                    id: editObj.id,
                    complexLaborsData: editObj.complexLaborsData,
                    complexProductsData: editObj.complexProductsData
                }
            ]);
            await editComplexes([
                {
                    name: newName,
                    id: editObj.id,
                    complexLaborsData: cleanForEdit(editObj.labors),
                    complexProductsData: cleanForEdit(editObj.products)
                }
            ]);
            handleEditClose();
            await fetchComplexes();
        },
        [newName, editObj]
    );

    const handleStatusChange = useCallback(
        async status => {
            await editComplexes(
                selectedRows.map(({ id, labors, products }) => ({
                    id,
                    enabled: status,
                    complexLaborsData: cleanForEdit(labors),
                    complexProductsData: cleanForEdit(products)
                }))
            );
            await fetchComplexes();
            setSelectedRows([]);
            setSelectedRowKeys([]);
        },
        [selectedRows]
    );

    const handleCopy = useCallback(async complex => {
        const response = await fetchAPI('PUT', '/business/complexes/copy', null, {
            id: complex.id
        });
        await fetchComplexes();

        return response;
    }, []);

    const deleteComplexes = useCallback(async complexes => {
        const complexIds = complexes.map(({ id }) => id);
        try {
            await fetchAPI(
                'DELETE',
                '/business/complexes',
                null,
                { complexIds },
                {
                    handleErrorInternally: true
                }
            );
            await fetchComplexes();
            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'complexes.delete_error_message' })
            });
        }
    }, []);

    // useEffect(() => {
    //     fetchData();
    // }, []);

    const columns = useMemo(
        () => [
            {
                title: () => {
                    const actionMenu = () => (
                        <Menu>
                            <Menu.Item key='complex.group_enable' onClick={() => handleStatusChange(true)}>
                                <FormattedMessage id='complexes.enable_complex' />
                            </Menu.Item>
                            <Menu.Item key='complex.group_disable' onClick={() => handleStatusChange(false)}>
                                <FormattedMessage id='complexes.disable_complex' />
                            </Menu.Item>

                            <Menu.Item key='complex.group_delete'>
                                <Popconfirm
                                    key='popconfirm.complex.group_delete'
                                    onConfirm={() => deleteComplexes(selectedRows)}
                                    title={formatMessage({ id: 'add_order_form.delete_confirm' })}
                                >
                                    <div>
                                        <FormattedMessage id='delete' />
                                    </div>
                                </Popconfirm>
                            </Menu.Item>
                            {/* <Menu.Item
                            key='complex.group_copy'
                            // disabled={!selectedRows.length}
                            // onClick={() => handleGroupChange(selectedRows, { enabled: true })}
                            onClick={() => console.log('group copy')}
                        >
                            <FormattedMessage id='copy' />
                        </Menu.Item> */}
                        </Menu>
                    );

                    return <HamburgerMenu actionsMenu={actionMenu} disabled={!selectedRows.length} />;
                },
                render: row => {
                    const singleMenu = () => (
                        <Menu>
                            <Menu.Item
                                key='complex.single_copy'
                                // disabled={!selectedRows.length}
                                onClick={() => handleCopy(row)}
                                // onClick={() => console.log('single copy')}
                            >
                                <FormattedMessage id='copy' />
                            </Menu.Item>
                            <Menu.Item key='complex.single_delete'>
                                <Popconfirm
                                    key='popconfirm.complex.single_delete'
                                    onConfirm={() => deleteComplexes([row])}
                                    title={formatMessage({ id: 'add_order_form.delete_confirm' })}
                                >
                                    <div>
                                        <FormattedMessage id='delete' />
                                    </div>
                                </Popconfirm>
                            </Menu.Item>
                        </Menu>
                    );

                    return <HamburgerMenu actionsMenu={singleMenu} />;
                },
                width: '48px',
                align: 'center'
            },
            {
                title: <Button disabled icon={<EditOutlined />} />,
                width: '48px',
                align: 'center',
                render: row => <Button icon={<EditOutlined />} onClick={() => openComplexModal(row)} />
            },
            {
                title: '№',
                key: 'index',
                width: '48px',
                render: (r, r2, index) => index + 1,
                align: 'right'
            },
            {
                title: <FormattedMessage id='services_table.complex' />,
                render: row => (
                    <div>
                        {row.name}
                        <Popconfirm
                            description={
                                <Input
                                    onChange={e => setNewName(e.target.value)}
                                    placeholder={formatMessage({ id: 'complexes.complex_name' })}
                                    value={newName}
                                ></Input>
                            }
                            onCancel={handleEditClose}
                            onConfirm={handleEdit}
                            open={editObj && editObj.id === row.id}
                            title={formatMessage({ id: 'complexes.change_name' })}
                        >
                            <Button icon={<EditOutlined />} onClick={() => handleEditOpen(row)} type='text' />
                        </Popconfirm>
                    </div>
                )
            },
            {
                title: <FormattedMessage id='complexes.type' />,
                render: row => (
                    <div>
                        <FormattedMessage id={`complexes.type.${row.type}`} />
                    </div>
                )
            },
            {
                title: <FormattedMessage id='complexes.jobs' />,
                align: 'right',
                render: ({ labors }) => labors.length
            },
            {
                title: <FormattedMessage id='complexes.parts' />,
                align: 'right',
                render: ({ products }) => products.length
            },
            {
                title: formatMessage({ id: 'product.visibility' }),
                render: row => (
                    <Switch
                        checked={row.enabled}
                        onChange={async enabled => {
                            await editComplexes([
                                {
                                    enabled,
                                    id: row.id,
                                    complexLaborsData: cleanForEdit(row.labors),
                                    complexProductsData: cleanForEdit(row.products)
                                }
                            ]);
                            await fetchComplexes();
                        }}
                    />
                )
            }
        ],
        [deleteComplexes, editObj, formatMessage, handleCopy, handleEdit, handleStatusChange, newName, selectedRows]
    );

    const fetchAdditionalRoles = useMemo(
        () => async () => {
            const res = await fetchAPI('GET', '/v2/roles_and_accesses/standard_roles/simple_list', null, null, {
                handleErrorInternally: true
            });

            setAdditionalRoles(res);
        },
        []
    );

    useEffect(() => {
        fetchAdditionalRoles();
    }, [fetchAdditionalRoles]);

    const rowSelection = useMemo(() => {
        return {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRows(selectedRows);
                setSelectedRowKeys(selectedRowKeys);
            }
        };
        // }, [actionsMenu, selectedRowKeys, selectedRows]);
    }, [selectedRowKeys, selectedRows]);

    return (
        <Layout title={<FormattedMessage id='packages.rights_n_access' />}>
            <Tabs>
                {isGrantAccessed(user, grants.SETTINGS_PERMISSIONS_AND_ACCESS_STANDARD_PACKAGES) && (
                    <TabPane
                        key='1'
                        tab={formatMessage({
                            id: 'packages.default_packages'
                        })}
                    >
                        <DefaultPackagesTab />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.SETTINGS_PERMISSIONS_AND_ACCESS_STANDARD_ROLES) && (
                    <TabPane
                        key='2'
                        tab={formatMessage({
                            id: 'packages.default_roles'
                        })}
                    >
                        <DefaultRolesTab allRoles={additionalRoles} />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.SETTINGS_PERMISSIONS_AND_ACCESS_BUSINESS_PACKAGE) && (
                    <TabPane
                        key='3'
                        tab={formatMessage({
                            id: 'packages.bussiness_packages'
                        })}
                    >
                        <BussinessPackagesTab />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.SETTINGS_PERMISSIONS_AND_ACCESS_BUSINESS_ROLES) && (
                    <TabPane
                        key='4'
                        tab={formatMessage({
                            id: 'packages.bussiness_roles'
                        })}
                    >
                        <BusinessRolesTab
                            additionalRoles={additionalRoles}
                            fetchAdditionalRoles={fetchAdditionalRoles}
                            user={user}
                        />
                    </TabPane>
                )}
            </Tabs>
        </Layout>
    );
};

const mapStateToProps = state => ({
    user: state.auth
});

export default injectIntl(connect(mapStateToProps)(RefactoredRightsPage));
