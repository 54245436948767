export { AccountingTabSettings } from './accountingTabSettings';
export { ContactsSettings } from './contactsSettings';
export { CountryTabSettings } from './countryTabSettings';
export { GeneralSettings } from './generalSettings';
export { OtherTabSettings } from './otherTabSettings';
export { PostsSettings } from './postsSettings';
export { PricesTabSettings } from './pricesTabSettings';
export { PrintTabSettings } from './printTabSettings';
export { ScheduleSettings } from './scheduleSettings';

