import { Image, InputNumber, Select } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, phoneCodeToCountry, phoneNumberFormatter, phoneNumberParser } from 'utils';

const mapStateToProps = state => ({
    user: state.auth
});

const { Option } = Select;

@injectIntl
@connect(mapStateToProps)
class PhoneInput extends Component {
    constructor(props) {
        super(props);

        this.state = { codes: [] };
    }

    componentDidMount() {
        this.fetchCountryCodes();
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setValue();
        }
    }

    setValue = () => {
        if (!this.props.value) {
            this.setState({
                phoneCode: this.props.user.phoneCode
            });

            return;
        }

        const phone = phoneNumberParser(this.props.value);

        const code = this.state.codes.find(({ phoneCode }) => {
            const substr = phone.substring(0, phoneCode.length);

            return substr === phoneCode;
        });
        if (code) {
            this.setState({
                phoneCode: code.phoneCode,
                phoneNumber: phone.substring(code.phoneCode.length)
            });
        }
    };

    fetchCountryCodes = async () => {
        const countries = await fetchAPI('GET', 'countrysettings', null, null, {
            handleErrorInternally: true
        });

        const codes = _.uniqBy(countries, 'phoneCode');
        await this.setState({
            codes: codes.sort((a, b) => a.country.localeCompare(b.country))
        });
        this.setValue();
    };

    render() {
        const {
            user,
            onChange,
            rules,
            intl: { formatMessage },
            disabled
        } = this.props;

        const { codes, phoneCode, phoneNumber } = this.state;

        const selectBefore = (
            <Select
                disabled={disabled}
                getPopupContainer={trigger => trigger.parentNode}
                onChange={async phoneCode => {
                    await this.setState({
                        phoneCode
                    });
                    onChange(phoneCode + phoneNumber);
                }}
                optionFilterProp='children'
                showSearch
                style={{
                    width: 140
                }}
                value={phoneCode}
            >
                {codes.map(({ phoneCode, country, photoUrl }) => (
                    <Option key={phoneCode} style={{ textAlign: 'left' }} value={phoneCode}>
                        {country}
                        <div style={{ margin: '0px 8px -8px', display: 'inline-block' }}>
                            <Image
                                preview={false}
                                src={require(`../../theme/images/flags/${photoUrl}.png`)}
                                width={24}
                            />
                        </div>
                        +{phoneCode}
                    </Option>
                ))}
            </Select>
        );

        return (
            <InputNumber
                addonBefore={selectBefore}
                controls={false}
                formatter={value => phoneNumberFormatter(value, phoneCodeToCountry[user.phoneCode])}
                onChange={async phoneNumber => {
                    await this.setState({
                        phoneNumber
                    });
                    onChange(phoneCode + phoneNumber);
                }}
                onKeyDown={e => {
                    const validKeys = [
                        '0',
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '.',
                        ',',
                        'Tab',
                        'Enter',
                        'Backspace',
                        'Delete'
                    ];

                    const actions = ['Tab', 'Enter', 'Backspace', 'Delete'];

                    const value = String(e.target.value).replace(/[+() ]/g, '');
                    if ((validKeys.includes(e.key) && value.length < 14) || actions.includes(e.key)) {
                        return e;
                    }

                    return e.preventDefault();
                }}
                parser={phoneNumberParser}
                rules={rules}
                value={phoneNumber}
            />
        );
    }
}

export default PhoneInput;
