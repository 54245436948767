import ExpensesPage from './ExpensesPage';
import IncomesPage from './IncomesPage';
import JournalVATPage from './JournalVATPage';
import OrdersExpensesPage from './OrdersExpensesPage';
import ProductsGroupsPage from './ProductsGroupsPage';
import ProductsWithoutCellPage from './ProductsWithoutCellPage';
import StorageCalculationsPage from './StorageCalculationsPage';
import StorageDocumentPage from './StorageDocumentPage';
import StorageFundsPage from './StorageFundsPage';
import StorageJournalPage from './StorageJournalPage';
import StorageOrdersPage from './StorageOrdersPage';
import StorageTransferPage from './StorageTransferPage';
import WarehousesPage from './WarehousesPage';
import WaybillsPage from './WaybillsPage';

export { IncomeDocPage } from './IncomeDocPage';
export { PriceGroupsPage } from './PriceGroupsPage';
export { ProductsPage } from './ProductsPage';
export { ProductsTrackingPage } from './ProductsTrackingPage';
export { StorageBalancePage } from './StorageBalancePage';
export { StorageMovementPage } from './StorageMovementPage';
export {
    StorageOrdersPage,
    IncomesPage,
    ExpensesPage,
    StorageTransferPage,
    StorageDocumentPage,
    WarehousesPage,
    ProductsWithoutCellPage,
    WaybillsPage,
    OrdersExpensesPage,
    StorageJournalPage,
    StorageCalculationsPage,
    StorageFundsPage,
    ProductsGroupsPage,
    JournalVATPage
};

