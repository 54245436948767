exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__formItemSelectFilter---2fpEj{width:30%}.styles-m__businessPackageFilters---3Dxrw{display:flex;flex-direction:row;align-items:center;justify-content:space-around}.styles-m__expiredRaw---1kBqo{background-color:rgba(255,45,45,.28)}.styles-m__businessEditIcon---339iJ{font-size:18px;cursor:pointer}.styles-m__businessEditIcon---339iJ:hover{color:var(--primary)}.styles-m__checkboxStyle---leubb{margin-top:8px}.styles-m__checkBoxMargin---2kBgT{margin-left:8px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,wCACI,SACJ,CAEA,0CACI,YAAa,CACb,kBAAmB,CACnB,kBAAmB,CACnB,4BACJ,CAEA,8BACI,oCACJ,CAEA,oCACI,cAAe,CACf,cAKJ,CAHI,0CACI,oBACJ,CAGJ,iCACI,cACJ,CAEA,kCACI,eACJ","file":"styles.m.css","sourcesContent":[".formItemSelectFilter {\n    width: 30%;\n}\n\n.businessPackageFilters {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-around;\n}\n\n.expiredRaw {\n    background-color: rgba(255, 45, 45, 0.28);\n}\n\n.businessEditIcon {\n    font-size: 18px;\n    cursor: pointer;\n\n    &:hover {\n        color: var(--primary);\n    }\n}\n\n.checkboxStyle {\n    margin-top: 8px;\n}\n\n.checkBoxMargin {\n    margin-left: 8px;\n}"]}]);

// Exports
exports.locals = {
	"formItemSelectFilter": "styles-m__formItemSelectFilter---2fpEj",
	"businessPackageFilters": "styles-m__businessPackageFilters---3Dxrw",
	"expiredRaw": "styles-m__expiredRaw---1kBqo",
	"businessEditIcon": "styles-m__businessEditIcon---339iJ",
	"checkboxStyle": "styles-m__checkboxStyle---leubb",
	"checkBoxMargin": "styles-m__checkBoxMargin---2kBgT"
};