/* eslint-disable react/sort-comp */
/* eslint-disable max-classes-per-file */
import { CameraOutlined, FileImageOutlined, QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Drawer, Image, Input, InputNumber, Modal, Select, Tabs, Tooltip, Upload, notification } from 'antd';
import imageCompression from 'browser-image-compression';
import { Catcher } from 'commons';
import {
    addNewDiagnosticTemplate,
    deleteDiagnosticProcess,
    deleteDiagnosticTemplate,
    getPartProblems,
    sendDiagnosticAnswer
} from 'core/forms/orderDiagnosticForm/saga';
import _, { get } from 'lodash';
import { ConfirmDiagnosticModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { VinDiagnostic } from './components';
import Styles from './styles.m.css';

const { Option } = Select;
const { TabPane } = Tabs;

@injectIntl
class DiagnosticTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderDiagnostic: props.orderDiagnostic,
            selectedRows: [],
            rowsCount: 0,
            headerCheckboxIndeterminate: false,
            checkedAll: false,
            possibleRows: [],
            dataSource: [],
            filterPlan: null,
            filterStage: null,
            filterStatus: null,
            filterCommentary: null,
            filterPhoto: null,
            templatesTitles: [],
            helperDrawerOpen: false,
            allLinks: []
        };

        this.groupsTitles = [];
        this.partsTitles = [];
        this.planFilterOptions = [];
        this.ok = 0;
        this.bad = 0;
        this.critical = 0;
        this.open = 0;
        this.withCommentary = 0;
        this.withPhoto = 0;
        this.photoKeys = [];
    }

    addNewDiagnostic = async id => {
        await addNewDiagnosticTemplate(this.props.orderId, id);
        this.getCurrentDiagnostic();
    };

    deleteDiagnostic = async id => {
        await deleteDiagnosticTemplate(this.props.orderId, id);
        this.getCurrentDiagnostic();
    };

    getCurrentDiagnostic = async () => {
        this.setState({
            loading: true
        });
        const response = await fetchAPI('GET', `orders/diagnostics?orderId=${this.props.orderId}`, null, null, {
            handleErrorInternally: true
        });
        await this.setState({
            orderDiagnostic: response.diagnosis
        });
        this.updateDataSource();
    };

    getTemplatesList = () => {
        const {
            templatesData: { diagnosticTemplates }
        } = this.state;
        const templatesData = {};
        diagnosticTemplates.forEach(({ diagnosticTemplateId, diagnosticTemplateTitle, groups }) => {
            templatesData[diagnosticTemplateId] = {
                id: diagnosticTemplateId,
                title: diagnosticTemplateTitle,
                nx: {}
            };
            groups.forEach(({ groupId, groupTitle, parts }) => {
                templatesData[diagnosticTemplateId].nx[groupId] = {
                    id: groupId,
                    title: groupTitle,
                    nx: {}
                };

                parts.forEach(({ partId, partTitle }) => {
                    templatesData[diagnosticTemplateId].nx[groupId].nx[partId] = {
                        id: partId,
                        title: partTitle
                    };
                });
            });
        });

        this.setState({ templatesData });
    };

    deleteRow = async index => {
        const photoIndex = this.photoKeys.findIndex(
            elem =>
                elem.partId === this.state.dataSource[index].partId &&
                elem.groupId === this.state.dataSource[index].groupId
        );
        this.photoKeys.splice(photoIndex, 1);
        await deleteDiagnosticProcess(
            this.props.orderId,
            this.state.dataSource[index].diagnosticTemplateId,
            this.state.dataSource[index].groupId,
            this.state.dataSource[index].partId,
            this.state.dataSource[index].templateIndex
        );
        this.setState({
            selectedRows: [],
            possibleRows: [],
            checkedAll: false,
            headerCheckboxIndeterminate: false
        });
        await this.getCurrentDiagnostic();
    };

    onCheckAll = () => {
        if (!this.state.checkedAll) {
            this.setState({
                checkedAll: true,
                selectedRows: this.state.possibleRows,
                headerCheckboxIndeterminate: false
            });
        } else {
            this.setState({
                checkedAll: false,
                selectedRows: [],
                headerCheckboxIndeterminate: false
            });
        }
        this.getCurrentDiagnostic();
    };

    editSelectedRowsStatus = async status => {
        const func = async () => {
            for (let i = 0; i < this.state.selectedRows.length; i++) {
                const key = this.state.dataSource.findIndex((element, index, array) => {
                    return element.key == this.state.selectedRows[i];
                });
                if (key != -1 && this.state.dataSource[key].partId) {
                    try {
                        await sendDiagnosticAnswer(
                            this.props.orderId,
                            this.state.dataSource[key].diagnosticTemplateId,
                            this.state.dataSource[key].groupId,
                            this.state.dataSource[key].partId,
                            this.state.dataSource[key].templateIndex,
                            status,
                            this.state.dataSource[key].commentary,
                            this.state.dataSource[key].oeCode,
                            this.state.dataSource[key].photoHashes
                        );
                    } catch (e) {}
                }
            }
        };
        await func();
        await this.setState({
            selectedRows: [],
            possibleRows: [],
            checkedAll: false,
            headerCheckboxIndeterminate: false
        });

        this.getCurrentDiagnostic();
    };

    deleteSelectedRows = async () => {
        const func = async () => {
            for (let i = 0; i < this.state.selectedRows.length; i++) {
                const key = this.state.dataSource.findIndex(elem => elem.key == this.state.selectedRows[i]);
                if (key != -1) {
                    try {
                        await deleteDiagnosticProcess(
                            this.props.orderId,
                            this.state.dataSource[key].diagnosticTemplateId,
                            this.state.dataSource[key].groupId,
                            this.state.dataSource[key].partId,
                            this.state.dataSource[key].templateIndex
                        );
                    } catch (e) {}
                }
            }
        };
        await func();
        await this.setState({
            selectedRows: [],
            possibleRows: [],
            checkedAll: false,
            headerCheckboxIndeterminate: false
        });

        this.getCurrentDiagnostic();
    };

    onChangeCheckbox = key => {
        const data = this.state.selectedRows;
        if (event.target.checked) {
            this.state.selectedRows.push(key);
        } else {
            const index = data.indexOf(key);
            this.state.selectedRows = data.filter((_, i) => i !== index);
        }
        const allchecked = this.state.selectedRows.length == this.state.rowsCount;
        const indeterminate =
            this.state.selectedRows.length < this.state.rowsCount && this.state.selectedRows.length > 0;
        this.setState({
            headerCheckboxIndeterminate: indeterminate,
            checkedAll: allchecked
        });
        this.getCurrentDiagnostic();
    };

    filterDataSource = dataSource => {
        let data = dataSource;
        const { filterPlan, filterStage, filterStatus, filterCommentary, filterPhoto } = this.state;
        if (filterPlan !== null) {
            data = data.filter(data => data.plan == filterPlan);
        }
        if (filterStage !== null) {
            data = data.filter(data => data.stage == filterStage);
        }
        if (filterStatus == 'OK') {
            data = data.filter(data => data.status == 1);
        }
        if (filterStatus == 'BAD') {
            data = data.filter(data => data.status == 2);
        }
        if (filterStatus == 'CRITICAL') {
            data = data.filter(data => data.status == 3);
        }
        if (filterStatus == 'BAD&CRITICAL') {
            data = data.filter((data, i) => data.status == 2 || data.status == 3);
        }
        if (filterStatus == 'OPEN') {
            data = data.filter(data => data.status == 0);
        }
        if (filterCommentary !== null) {
            data = data.filter(data => data.commentary.comment !== null);
        }

        return data;
    };

    updateDataSource = async () => {
        const { disabled, orderId } = this.props;
        this.ok = 0;
        this.bad = 0;
        this.critical = 0;
        this.open = 0;
        this.withCommentary = 0;
        this.withPhoto = 0;
        await this.setState({
            possibleRows: []
        });
        const { orderDiagnostic } = this.state;
        const dataSource = [];

        const { diagnosticTemplatesCount } = _.pick(orderDiagnostic, ['diagnosticTemplatesCount']);
        const { diagnosticTemplates } = _.pick(orderDiagnostic, ['diagnosticTemplates']);
        let key = 1;
        for (let i = 0; i < diagnosticTemplatesCount; i++) {
            const { groupsCount } = _.pick(diagnosticTemplates[i], ['groupsCount']);
            const { diagnosticTemplateTitle } = _.pick(diagnosticTemplates[i], ['diagnosticTemplateTitle']);
            const { diagnosticTemplateId } = _.pick(diagnosticTemplates[i], ['diagnosticTemplateId']);
            const { groups } = _.pick(diagnosticTemplates[i], ['groups']);
            for (let j = 0; j < groupsCount; j++) {
                const { groupTitle } = _.pick(groups[j], ['groupTitle']);
                const { groupId } = _.pick(groups[j], ['groupId']);
                const { partsCount } = _.pick(groups[j], ['partsCount']);
                const { parts } = _.pick(groups[j], ['parts']);
                for (let k = 0; k < partsCount; k++) {
                    const { index } = _.pick(parts[k], ['index']);
                    const { partTitle } = _.pick(parts[k], ['partTitle']);
                    const { actionTitle } = _.pick(parts[k], ['actionTitle']);
                    const { partId } = _.pick(parts[k], ['partId']);
                    const { oeCode } = _.pick(parts[k], ['oeCode']);
                    const { photoHashes } = _.pick(parts[k], ['photoHashes']);
                    const { storeGroupId } = _.pick(parts[k], ['storeGroupId']);
                    const { answer } = _.pick(parts[k], ['answer']);
                    const { calcDone } = _.pick(parts[k], ['calcDone']);
                    const { rectangleId } = _.pick(parts[k], ['rectangleId']);
                    let { comment } = _.pick(parts[k], ['comment']);
                    if (comment === null) {
                        comment = {
                            comment: undefined,
                            positions: [],
                            problems: [],
                            mm: 0,
                            percent: 0,
                            deg: 0
                        };
                    }
                    dataSource.push({
                        templateIndex: index,
                        plan: diagnosticTemplateTitle,
                        stage: groupTitle,
                        detail: partTitle,
                        status: answer,
                        commentary: comment,
                        disabled: calcDone,
                        storeGroupId,
                        key,
                        partId,
                        orderId,
                        diagnosticTemplateId,
                        actionTitle,
                        groupId,
                        oeCode,
                        photoHashes,
                        rectangleId
                    });
                    if (!calcDone) {
                        this.state.possibleRows.push(key);
                    }
                    key++;
                    if (answer == 0) {
                        this.open++;
                    }
                    if (answer == 1) {
                        this.ok++;
                    }
                    if (answer == 2) {
                        this.bad++;
                    }
                    if (answer == 3) {
                        this.critical++;
                    }
                }
            }
        }
        const filtredData = this.filterDataSource(dataSource);

        if (filtredData.length < dataSource.length) {
            const { filterPlan, filterStage, filterCommentary, filterPhoto } = this.state;
            if (filterPlan || filterStage || filterCommentary || filterPhoto) {
                this.ok = 0;
                this.bad = 0;
                this.critical = 0;
                this.open = 0;
            }
            filtredData.map(data => {
                if (filterPlan || filterStage || filterCommentary || filterPhoto) {
                    if (data.status == 0) {
                        this.open++;
                    }
                    if (data.status == 1) {
                        this.ok++;
                    }
                    if (data.status == 2) {
                        this.bad++;
                    }
                    if (data.status == 3) {
                        this.critical++;
                    }
                }
                if (data.commentary.comment) {
                    this.withCommentary++;
                }
            });
            this.state.possibleRows.push(key - 1);
            this.setState({
                dataSource: filtredData,
                rowsCount: filtredData.length,
                loading: false
            });
        } else {
            dataSource.map(data => {
                if (data.commentary.comment) {
                    this.withCommentary++;
                }
            });
            if (!disabled) {
                dataSource.push({
                    key,
                    partId: '',
                    plan: '',
                    detail: '',
                    actionTitle: '',
                    stage: '',
                    status: '',
                    commentary: '',
                    orderId,
                    diagnosticTemplateId: '',
                    groupId: '',
                    photo: null,
                    allTemplatesData: orderDiagnostic
                });
            }
            this.state.possibleRows.push(key);

            this.setState({
                dataSource,
                rowsCount: key,
                loading: false
            });
        }
    };

    getDiagnosticAndUpdate = async () => {
        // const templatesData = await fetchAPI('GET', 'diagnostics');
        // await this.setState({
        //     templatesData
        // });

        this.getTemplatesList();
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'order_mrd_vin_diagnostic' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    componentDidMount() {
        // this.getDiagnosticAndUpdate();
    }

    handleSearchLabors = _.debounce(value => {
        this.props.fetchLabors(value);
    }, 500);

    render() {
        const {
            defaultEmployeeId,
            defaultResponsibleId,
            user,
            tecdocId,
            orderId,
            orderServices,
            orderDetails,
            disabled,
            updateTabs,
            reloadOrderPageComponents,
            labors,
            details,
            action,
            laborTimeMultiplier,
            normHourPrice,
            modals,
            setModal,
            orderImgId,
            reloadOrderForm,
            activeKey,
            detailsTreeData,
            unitDefault,
            orderImgVehicleType,
            bodyId,
            selectedVehicle
        } = this.props;
        const {
            loading,
            rowsCount,
            selectedRows,
            headerCheckboxIndeterminate,
            checkedAll,
            dataSource,
            templatesData,
            helperDrawerOpen,
            allLinks
        } = this.state;

        const confirmModal = props => (
            <ConfirmDiagnosticModal
                action={action}
                dataSource={dataSource}
                defaultEmployeeId={defaultEmployeeId}
                defaultResponsibleId={defaultResponsibleId}
                details={details}
                disabled={disabled}
                getCurrentDiagnostic={this.getCurrentDiagnostic}
                handleSearchLabors={this.handleSearchLabors}
                hideEndButton
                isMobile={false}
                labors={labors}
                laborTimeMultiplier={laborTimeMultiplier}
                normHourPrice={normHourPrice}
                orderDetails={orderDetails}
                orderId={orderId}
                orderServices={orderServices}
                reloadOrderPageComponents={reloadOrderPageComponents}
                tecdocId={tecdocId}
                updateTabs={updateTabs}
                user={user}
                {...props}
            />
        );

        // const selectedVehicle =
        //     selectedVehicleId && _.find(selectedClient.vehicles, { id: selectedVehicleId });

        return (
            <Catcher>
                <div
                    style={{
                        marginLeft: 8,
                        display: 'flex',
                        justifyContent: 'end'
                    }}
                >
                    <Button
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            this.setState({
                                helperDrawerOpen: true
                            });
                            await this.fetchHelperLinks();
                        }}
                        style={{
                            fontSize: 22
                        }}
                        type='text'
                    />
                </div>
                <VinDiagnostic
                    activeKey={activeKey}
                    bodyId={bodyId}
                    confirmModal={confirmModal}
                    dataSource={dataSource}
                    defaultEmployeeId={defaultEmployeeId}
                    defaultResponsibleId={defaultResponsibleId}
                    detailsTreeData={detailsTreeData}
                    handleSearchLabors={this.handleSearchLabors}
                    labors={labors}
                    modals={modals}
                    orderId={orderId}
                    orderImgId={orderImgId}
                    orderImgVehicleType={orderImgVehicleType}
                    reloadOrderForm={reloadOrderForm}
                    selectedVehicle={selectedVehicle}
                    setModal={setModal}
                    tecdocId={tecdocId}
                    unitDefault={unitDefault}
                    updateDiagnostics={this.getCurrentDiagnostic}
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Catcher>
        );
    }
}

export default DiagnosticTable;

@injectIntl
export class CommentaryButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            currentCommentaryProps: {
                name: '',
                positions: [],
                problems: [],
                params: {
                    mm: 0,
                    percent: 0,
                    deg: 0
                }
            },
            currentCommentary: undefined
        };
        this.commentaryInput = React.createRef();
        this.positions = [
            'front_axle',
            'ahead',
            'overhead',
            'rear_axle',
            'behind',
            'down_below',
            'Right_wheel',
            'on_right',
            'outside',
            'left_wheel',
            'left',
            'inside',
            'lever_arm',
            'at_both_sides',
            'centered'
        ];
        this.problems = [];
        this.params = [
            { name: 'mm', symbol: 'mm' },
            { name: 'percent', symbol: '%' },
            { name: 'deg', symbol: '°' }
        ];
    }

    componentDidUpdate({ rowProp }) {
        if (this.props.rowProp && !rowProp) {
            this.showModal();
        }
    }

    showModal = async () => {
        const { rowProp } = this.props;
        const commentary = get(rowProp, 'partComment', {});
        await getPartProblems(rowProp.partId, data => {
            this.problems = data.map(elem => {
                return {
                    label: elem.description,
                    value: elem.code
                };
            });
        });
        await this.setState({
            currentCommentary: commentary.comment,
            currentCommentaryProps: {
                name: get(rowProp, 'detail') || get(rowProp, 'partName'),
                positions: commentary.positions || [],
                problems: commentary.problems || [],
                params: {
                    mm: commentary.mm || 0,
                    percent: commentary.percent || 0,
                    deg: commentary.deg || 0
                }
            }
        });
        if (this.commentaryInput.current != undefined) {
            this.commentaryInput.current.focus();
        }
    };

    handleOk = async () => {
        const { rowProp, onOk } = this.props;
        const { currentCommentary, currentCommentaryProps } = this.state;
        this.setState({
            loading: true
        });

        if (onOk) {
            onOk({
                comment: currentCommentary || ' ',
                positions: currentCommentaryProps.positions,
                problems: currentCommentaryProps.problems,
                mm: currentCommentaryProps.params.mm,
                percent: currentCommentaryProps.params.percent,
                deg: currentCommentaryProps.params.deg
            });
        } else {
            await sendDiagnosticAnswer(
                rowProp.orderId,
                rowProp.diagnosticTemplateId,
                rowProp.groupId,
                rowProp.partId,
                rowProp.templateIndex,
                rowProp.status,
                {
                    comment: currentCommentary,
                    positions: currentCommentaryProps.positions,
                    problems: currentCommentaryProps.problems,
                    mm: currentCommentaryProps.params.mm,
                    percent: currentCommentaryProps.params.percent,
                    deg: currentCommentaryProps.params.deg
                },
                rowProp.oeCode,
                rowProp.photoHashes
            );
        }
        await this.props.getCurrentDiagnostic();
        setTimeout(() => {
            this.setState({ loading: false });
        }, 500);

        this.handleCancel();
    };

    handleCancel = () => {
        this.props.handleClose();
        this.setState({
            currentCommentary: null,
            currentCommentaryProps: {
                name: '',
                positions: [],
                problems: [],
                params: {
                    mm: 0,
                    percent: 0,
                    deg: 0
                }
            }
        });
    };

    renderHeader = () => {
        const { currentCommentaryProps } = this.state;
        const { problems } = this;

        return (
            <div>
                <p>{get(this.props, 'rowProp.detail') || get(this.props, 'rowProp.partName')}</p>
                <p
                    style={{
                        fontSize: '16px',
                        fontStyle: 'italic',
                        fontWeight: 'normal'
                    }}
                >
                    {
                        // this.props.rowProp.actionTitle
                        currentCommentaryProps.problems.map((data, index) => {
                            const punctuation = index == currentCommentaryProps.problems.length - 1 ? '' : ',';
                            const problemLable = problems.find(problem => problem.value == data);

                            return ` ${problemLable ? problemLable.label.toLowerCase() : null}${punctuation}`;
                        })
                    }
                </p>
            </div>
        );
    };

    getCommentary = () => {
        const { currentCommentaryProps } = this.state;
        const { problems, params } = this;
        const paramsValue = Object.entries(currentCommentaryProps.params).map((pair, key) => {
            if (pair[1] !== 0) {
                return ` ${pair[1]}${params[key].symbol}`;
            }
        });
        const isParamsSet = paramsValue.some(param => !_.isNil(param));
        let currentCommentary = get(this.props, 'rowProp.detail') || get(this.props, 'rowProp.partName');

        if (currentCommentaryProps.positions.length || currentCommentaryProps.problems.length || isParamsSet) {
            currentCommentary += ' -';
            if (currentCommentaryProps.positions.length) {
                currentCommentary += `${currentCommentaryProps.positions.map(
                    data => ` ${this.props.intl.formatMessage({ id: data }).toLowerCase()}`
                )};`;
            }
            if (currentCommentaryProps.problems.length) {
                currentCommentary += `${currentCommentaryProps.problems.map(data => {
                    return ` ${problems.find(problem => problem.value == data).label.toLowerCase()}`;
                })};`;
            }
            if (isParamsSet) {
                currentCommentary += `${paramsValue.filter(param => !_.isNil(param))};`;
            }
        }
        this.setState({
            currentCommentary
        });
    };

    setCommentaryPosition = position => {
        const { currentCommentaryProps } = this.state;
        const positionIndex = currentCommentaryProps.positions.indexOf(position);
        if (positionIndex == -1) {
            currentCommentaryProps.positions.push(position);
        } else {
            currentCommentaryProps.positions = currentCommentaryProps.positions.filter(
                (value, index) => index != positionIndex
            );
        }
        this.getCommentary();
    };

    setCommentaryProblems = value => {
        const { currentCommentaryProps } = this.state;
        const problemIndex = currentCommentaryProps.problems.indexOf(value);
        if (problemIndex == -1) {
            currentCommentaryProps.problems.push(value);
        } else {
            currentCommentaryProps.problems = currentCommentaryProps.problems.filter(
                (value, index) => index != problemIndex
            );
        }
        this.getCommentary();
    };

    setCommetaryParams = (param, value) => {
        const { currentCommentaryProps } = this.state;
        currentCommentaryProps.params[param] = value;
        this.getCommentary();
    };

    // componentDidMount() {
    //     const { rowProp } = this.props;
    //     const commentary = rowProp.commentary || {};
    //     if (!this.problems.length && get(rowProp, 'partId')) {
    //         this.setState({
    //             currentCommentaryProps: {
    //                 name: rowProp.detail || rowProp.partName,
    //                 positions: commentary.positions || [],
    //                 problems: commentary.problems || [],
    //                 params: {
    //                     mm: commentary.mm || 0,
    //                     percent: commentary.percent || 0,
    //                     deg: commentary.deg || 0
    //                 }
    //             }
    //         });
    //     }
    // }

    render() {
        const { TextArea } = Input;
        const { loading, currentCommentaryProps, currentCommentary } = this.state;
        const { disabled, rowProp } = this.props;
        const { positions, problems, params } = this;

        const resProblems = problems.filter(({ label }) => label);

        return (
            <Modal
                footer={
                    disabled
                        ? null
                        : [
                              <Button key='back' onClick={this.handleCancel}>
                                  <FormattedMessage id='cancel' />
                              </Button>,
                              <Button key='submit' loading={loading} onClick={this.handleOk} type='primary'>
                                  <FormattedMessage id='save' />
                              </Button>
                          ]
                }
                maskClosable={false}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                open={!!rowProp}
                title={this.renderHeader()}
                zIndex={3001}
            >
                <React.Fragment>
                    {get(rowProp, 'oeCode') && (
                        <div className={Styles.commentaryVehicleSchemeWrap}>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='order_form_table.OE_code' />
                            </p>
                            <Input
                                disabled
                                style={{
                                    color: 'var(--text)'
                                }}
                                value={rowProp.oeCode}
                            />
                        </div>
                    )}
                    <div className={Styles.commentaryVehicleSchemeWrap}>
                        <p className={Styles.commentarySectionHeader}>
                            <FormattedMessage id='commentary_modal.where' />?
                        </p>
                        <div className={Styles.blockButtonsWrap}>
                            {positions.map((position, key) => {
                                const disabledClass =
                                    disabled &&
                                    currentCommentaryProps.positions.findIndex(elem => position == elem) > -1
                                        ? Styles.disabledCommentaryProp
                                        : '';

                                return (
                                    <Button
                                        key={key}
                                        className={`${Styles.commentaryBlockButton} ${disabledClass}`}
                                        disabled={disabled}
                                        onClick={() => {
                                            this.setCommentaryPosition(position);
                                        }}
                                        type={
                                            currentCommentaryProps.positions.findIndex(elem => position == elem) === -1
                                                ? 'default'
                                                : 'primary'
                                        }
                                    >
                                        <FormattedMessage id={position} />
                                    </Button>
                                );
                            })}
                        </div>
                    </div>

                    {!!resProblems.length && (
                        <div className={Styles.commentaryVehicleSchemeWrap}>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='commentary_modal.what' />?
                            </p>
                            <div className={Styles.blockButtonsWrap}>
                                {problems
                                    .filter(({ label }) => label)
                                    .map((problem, key) => {
                                        const disabledClass =
                                            disabled &&
                                            currentCommentaryProps.problems.findIndex(elem => problem.value == elem) >
                                                -1
                                                ? Styles.disabledCommentaryProp
                                                : '';

                                        return (
                                            <Button
                                                key={key}
                                                className={`${Styles.commentaryBlockButton} ${disabledClass}`}
                                                disabled={disabled}
                                                onClick={() => {
                                                    this.setCommentaryProblems(problem.value);
                                                }}
                                                type={
                                                    currentCommentaryProps.problems.findIndex(
                                                        elem => problem.value == elem
                                                    ) === -1
                                                        ? 'default'
                                                        : 'primary'
                                                }
                                            >
                                                <span>
                                                    {problem.label[0].toUpperCase() +
                                                        problem.label.toLowerCase().slice(1)}
                                                </span>
                                            </Button>
                                        );
                                    })}
                            </div>
                        </div>
                    )}
                    <div className={Styles.commentaryVehicleSchemeWrap}>
                        <p className={Styles.commentarySectionHeader}>
                            <FormattedMessage id='commentary_modal.parameters' />
                        </p>
                        <div className={Styles.blockButtonsWrap}>
                            {params.map((param, key) => {
                                return (
                                    <InputNumber
                                        key={key}
                                        className={`${Styles.commentaryBlockButton} ${
                                            disabled ? Styles.disabledCommentaryProp : ''
                                        }`}
                                        decimalSeparator=','
                                        disabled={disabled}
                                        formatter={value => `${value} ${param.symbol}`}
                                        onChange={value => {
                                            this.setCommetaryParams(param.name, value);
                                        }}
                                        parser={value => value.replace(` ${param.symbol}`, '')}
                                        value={currentCommentaryProps.params[param.name]}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div>
                        <p className={Styles.commentarySectionHeader}>
                            <FormattedMessage id='order_form_table.diagnostic.commentary' />
                        </p>
                        <TextArea
                            ref={this.commentaryInput}
                            autoFocus
                            className={disabled ? Styles.disabledCommentaryProp : ''}
                            disabled={disabled}
                            onChange={() => {
                                this.setState({
                                    currentCommentary: event.target.value
                                });
                            }}
                            placeholder={`${this.props.intl.formatMessage({
                                id: 'comment'
                            })}...`}
                            style={{
                                width: '100%',
                                minHeight: '150px',
                                resize: 'none'
                            }}
                            value={currentCommentary}
                        />
                    </div>
                </React.Fragment>
            </Modal>
        );
    }
}

@injectIntl
@connect(
    state => ({
        isMobile: state.ui.views.isMobile
    }),
    {}
)
export class PhotoButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            fileList: []
        };
    }

    removeHash = async hash => {
        const { onOk } = this.props;
        const rowProp = get(this.props, 'rowProp', {});
        const { orderId, diagnosticTemplateId, templateIndex, groupId, partId, status, commentary, oeCode } = rowProp;

        const partPhotosHashes = (_.get(rowProp, 'partPhotosHashes', []) || []).filter(sh => sh !== hash);
        await this.setState({ partPhotosHashes });
        if (onOk) {
            onOk(partPhotosHashes);
        } else {
            await sendDiagnosticAnswer(
                orderId,
                diagnosticTemplateId,
                groupId,
                partId,
                templateIndex,
                status,
                commentary,
                oeCode,
                partPhotosHashes
            );
        }
        this.props.getCurrentDiagnostic();
    };

    getHash = async data => {
        const { added, hash } = data;

        const { rowProp, onOk } = this.props;
        const { orderId, diagnosticTemplateId, templateIndex, groupId, partId, status, commentary, oeCode } = rowProp;

        const partPhotosHashes = _.get(rowProp, 'partPhotosHashes', []) || [];
        partPhotosHashes.push(hash);

        if (added) {
            await this.setState({ partPhotosHashes });
            if (onOk) {
                onOk(partPhotosHashes);
            } else {
                await sendDiagnosticAnswer(
                    orderId,
                    diagnosticTemplateId,
                    groupId,
                    partId,
                    templateIndex,
                    status,
                    commentary,
                    oeCode,
                    partPhotosHashes
                );
            }
            this.props.getCurrentDiagnostic();
        }
    };

    getPhoto = async hash => {
        const photo = await fetchAPI('GET', 'orders/photo', { hash }, null, {
            handleErrorInternally: true,
            rawResponse: true
        });
        const reportFile = await photo.blob();
        const url = URL.createObjectURL(reportFile);
        const file = {
            key: hash,
            uid: hash,
            status: 'done',
            url,
            thumbUrl: url
        };

        return file;
    };

    showModal = async () => {
        const partPhotosHashes = _.get(this.props.rowProp, 'partPhotosHashes', []) || [];
        const fileList = await Promise.all(partPhotosHashes.map(hash => this.getPhoto(hash)));

        this.setState({
            visible: true,
            fileList
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false
        });
    };

    componentDidUpdate = async prevProps => {
        if (prevProps.rowProp.partPhotosHashes !== this.props.rowProp.partPhotosHashes) {
            const partPhotosHashes = _.get(this.props.rowProp, 'partPhotosHashes', []);
            const fileList = await Promise.all(partPhotosHashes.map(hash => this.getPhoto(hash)));
            this.setState({
                fileList
            });
        }
    };

    render() {
        const {
            disabled,
            rowProp,
            isMobile,
            intl: { formatMessage }
        } = this.props;
        const { visible, fileList } = this.state;

        const partPhotosHashes = _.get(rowProp, 'partPhotosHashes', []) || [];

        const token = localStorage.getItem('_my.carbook.pro_token');

        const uploadButton = !isMobile ? (
            <React.Fragment>
                <UploadOutlined />
                <FormattedMessage id='upload' />
            </React.Fragment>
        ) : (
            <Button style={{ width: '100%' }}>
                <UploadOutlined />
                <FormattedMessage id='upload' />
            </Button>
        );

        return (
            <Tooltip title={<FormattedMessage id='order_form_table.diagnostic.photo' />} zIndex={3001}>
                <div>
                    <Button
                        disabled={disabled}
                        onClick={this.showModal}
                        type={partPhotosHashes.length ? 'primary' : ''}
                    >
                        {partPhotosHashes.length ? <FileImageOutlined /> : <CameraOutlined />}
                    </Button>
                    <Modal
                        className={Styles.photoModal}
                        destroyOnClose
                        footer={null}
                        onCancel={this.handleCancel}
                        open={visible}
                        title={<FormattedMessage id='order_form_table.diagnostic.photo' />}
                        zIndex={3001}
                    >
                        <Upload
                            action={async file => {
                                const formData = new FormData();
                                const compressedFile = await imageCompression(file, { maxSizeMB: 1 });

                                formData.append('file', compressedFile);

                                return fetch(`${__API_URL__}/orders/photo`, {
                                    method: 'POST',
                                    headers: {
                                        Authorization: token
                                    },
                                    body: formData
                                })
                                    .then(rsp => Promise.resolve(rsp))
                                    .then(rsp => rsp.json())
                                    .then(data => this.getHash(data))
                                    .catch(e => {});
                            }}
                            beforeUpload={file => {
                                const isPNG = file.type === 'image/png';
                                const isJPG = file.type === 'image/jpeg';
                                if (!isPNG && !isJPG) {
                                    notification.error({
                                        message: `${file.name} ${formatMessage({
                                            id: 'photo_modal.is_not_an_image'
                                        })}!`
                                    });
                                }

                                return isPNG || isJPG;
                            }}
                            fileList={fileList}
                            listType='picture-card'
                            onRemove={({ uid }) => {
                                return fetch(`${__API_URL__}/orders/photo`, {
                                    method: 'DELETE',
                                    headers: {
                                        Authorization: token
                                    },
                                    body: JSON.stringify({ hashes: [uid] })
                                })
                                    .then(rsp => Promise.resolve(rsp))
                                    .then(() => this.removeHash(uid))
                                    .catch(() => {});
                            }}
                        >
                            {fileList.length >= 8 ? null : uploadButton}
                        </Upload>
                    </Modal>
                </div>
            </Tooltip>
        );
    }
}
