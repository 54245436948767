import LocationsPage from './LocationsPage';
import LocationSettingsPage from './LocationSettingsPage';
import LocationsDocumentPage from './LocationsDocumentPage';
import LocationsVehiclesPage from './LocationsVehiclesPage';
import LocationsMovementPage from './LocationsMovementPage';

export {
    LocationsPage,
    LocationSettingsPage,
    LocationsDocumentPage,
    LocationsVehiclesPage,
    LocationsMovementPage
};
