import { Input, Table } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    fetchVehicles,
    selectExpandedVehicleId,
    selectFetchingVehicles,
    selectSort,
    selectVehicles,
    selectVehiclesStats,
    setExpandedVehicleId,
    setPage,
    setSearchQuery
} from '../../redux/duck';
import VehicleOrdersTable from '../VehicleOrdersTable';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    vehicles: selectVehicles(state),
    stats: selectVehiclesStats(state),
    sort: selectSort(state),
    expandedVehicleId: selectExpandedVehicleId(state),
    fetchingVehicles: selectFetchingVehicles(state)
});

const mapDispatchToProps = {
    fetchVehicles,
    setPage,
    setSearchQuery,
    setExpandedVehicleId
};

/**
 * This table contains vehicles, each vehicle row can be expanded and its orders will appear
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@withRouter
export default class VehiclesTable extends React.Component {
    constructor(props) {
        super(props);

        /** When user want to search just pass here its input, if no mere was provided in a second it will perform a search action */
        this.handleSearch = _.debounce(value => {
            this.props.setSearchQuery({
                query: _.toLower(value.replace(/[+()]/g, ''))
            });
        }, 1000).bind(this);

        this.urlParams = new URLSearchParams(location.search); // Get params from query sting
        this.initialSearchQuery = this.urlParams.get('initial_search_query'); // Get init search value, we can use it to initialize Inputs
        if (this.props.location.state && this.props.location.state.searchValue) {
            this.initialSearchQuery = this.props.location.state.searchValue;
        }
        this.initialSearchQuery && this.props.setSearchQuery({ query: this.initialSearchQuery.replace(/[+()]/g, '') }); // Set filter and fetch data if needed
    }

    componentDidMount() {
        this.props.fetchVehicles();
    }

    onSearch = e => {
        const { value } = e.target;
        this.handleSearch(value);
    };

    render() {
        const {
            stats,
            setPage,
            sort,
            expandedVehicleId,
            user,
            vehicles,
            setExpandedVehicleId,
            fetchingVehicles,
            intl: { formatMessage }
        } = this.props;

        const pagination = {
            pageSizeOptions: [10, 25, 50],
            pageSize: sort.pageSize,
            size: 'large',
            total: Math.ceil(stats.totalRowsCount / sort.pageSize) * sort.pageSize,
            current: sort.page,
            onChange: (page, pageSize) => setPage({ page, pageSize })
        };

        return (
            <React.Fragment>
                <div className={Styles.filtersCont}>
                    <div className={Styles.inputCont}>
                        <Input.Search
                            allowClear
                            defaultValue={this.initialSearchQuery}
                            onChange={this.onSearch}
                            placeholder={formatMessage({ id: 'vehicles_page.search' })}
                        />
                    </div>
                </div>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columnsConfig({ user })}
                    dataSource={vehicles}
                    expandable={{
                        expandedRowKeys: [expandedVehicleId],
                        expandedRowRender: () => <VehicleOrdersTable />,
                        onExpand: (expanded, vehicle) => {
                            setExpandedVehicleId({
                                vehicleId: expanded ? vehicle.clientVehicleId : undefined
                            });
                        }
                    }}
                    loading={fetchingVehicles} // Only one row can be expanded at the time
                    pagination={pagination}
                    rowKey={vehicle => vehicle.clientVehicleId}
                    width='100%'
                />
            </React.Fragment>
        );
    }
}
