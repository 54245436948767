/* eslint-disable max-classes-per-file */
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
    DeleteOutlined,
    EditOutlined,
    InfoCircleOutlined,
    PlusOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Col,
    Drawer,
    Image,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Radio,
    Row,
    Select,
    Table,
    Tooltip,
    notification
} from 'antd';
import { Layout } from 'commons';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const CheckGroup = Checkbox.Group;
const { Option } = Select;

const mapStateToProps = state => {
    return {
        user: state.auth
    };
};

@injectIntl
@connect(mapStateToProps, void 0)
export default class ReferenceBookPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            visible: false,
            edit: false,
            books: [],
            allLinks: [],
            helperDrawerOpen: false
        };

        const isDisabledCRUD = !isGrantAccessed(this.props.user, grants.SETTINGS_NUMBERING, accesses.ROWO);
        this.columns = [
            {
                title: <FormattedMessage id='directory_page.requisites' />,
                key: 'requisiteName',
                dataIndex: 'requisiteName'
            },
            {
                title: <FormattedMessage id='directory_page.id_print_form' />,
                key: 'formCode',
                dataIndex: 'formCode',
                render: formCode => {
                    return <div style={{ textAlign: 'center' }}>{formCode}</div>;
                }
            },
            {
                title: <FormattedMessage id='directory_page.type_print_form' />,
                key: 'formName',
                dataIndex: 'formName'
            },
            {
                title: <FormattedMessage id='directory_page.prefix_1' />,
                key: 'prefix0',
                dataIndex: 'prefix0'
            },
            {
                title: <FormattedMessage id='directory_page.prefix_2' />,
                key: 'prefix1',
                dataIndex: 'prefix1'
            },
            {
                title: <FormattedMessage id='directory_page.number_of_digits' />,
                key: 'numberOfDigitsInIndex',
                dataIndex: 'numberOfDigitsInIndex'
            },
            {
                title: <FormattedMessage id='directory_page.current_number' />,
                key: 'index',
                dataIndex: 'index'
            },
            {
                title: <FormattedMessage id='directory_page.end-to-end_numbering' />,
                key: 'throughNumbering',
                dataIndex: 'throughNumbering',
                render: throughNumbering => {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            <LegacyIcon type={throughNumbering ? 'check' : 'close'} />
                        </div>
                    );
                }
            },
            {
                key: 'edit',
                render: row => {
                    return (
                        <div>
                            <Button
                                disabled={isDisabledCRUD}
                                onClick={() => {
                                    this.setState({
                                        visible: row,
                                        edit: row
                                    });
                                }}
                            >
                                <EditOutlined
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    title={this.props.intl.formatMessage({
                                        id: 'directory_page.edit'
                                    })}
                                />
                            </Button>
                        </div>
                    );
                }
            },
            {
                key: 'delete',
                render: row => {
                    return (
                        <div>
                            <Popconfirm
                                onConfirm={() => this.deleteBook(row.id)}
                                title={`${props.intl.formatMessage({ id: 'delete' })} ?`}
                            >
                                <Button disabled={isDisabledCRUD}>
                                    <DeleteOutlined
                                        style={{ color: 'red' }}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        title={this.props.intl.formatMessage({
                                            id: 'directory_page.delete'
                                        })}
                                    />
                                </Button>
                            </Popconfirm>
                        </div>
                    );
                }
            }
        ];
    }

    showModal = () => {
        this.setState({
            visible: {}
        });
    };

    deleteBook = async id => {
        await fetchAPI('DELETE', 'reference_book/settings', null, { ids: [id] });
        this.fetchBooks();
    };

    editBook = async id => {
        await fetchAPI('PUT', 'reference_book/settings', null, { id });
    };

    fetchBooks = async () => {
        await this.setState({
            loading: true
        });
        const books = await fetchAPI('GET', 'reference_book/settings');

        this.setState({
            loading: false,
            books
        });
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings_referencebook' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    componentDidMount() {
        this.fetchBooks();
    }

    render() {
        const {
            user,
            intl: { formatMessage }
        } = this.props;
        const { loading, books, visible, edit, helperDrawerOpen, allLinks } = this.state;
        const { columns } = this;

        const isDisabledCRUD = !isGrantAccessed(user, grants.SETTINGS_NUMBERING, accesses.ROWO);

        return (
            <Layout
                controls={
                    <div className={Styles.controlsWrapper}>
                        <Button disabled={isDisabledCRUD} onClick={this.showModal} type='primary'>
                            <PlusOutlined
                                style={{
                                    fontSize: 18,
                                    verticalAlign: 'middle'
                                }}
                                title={formatMessage({ id: 'tasks_page.add_num' })}
                            />
                        </Button>
                        <Button
                            icon={<QuestionCircleOutlined />}
                            onClick={async () => {
                                this.setState({
                                    helperDrawerOpen: true
                                });
                                await this.fetchHelperLinks();
                            }}
                            style={{
                                fontSize: 22,
                                marginLeft: 8,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            type='text'
                        />
                    </div>
                }
                title={<FormattedMessage id='directory_page.title' />}
            >
                <Table
                    bordered
                    columns={columns}
                    dataSource={books}
                    loading={loading}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    rowKey='id'
                    size='small'
                />
                <ReferenceBookModal
                    edit={Boolean(edit)}
                    hideModal={() => {
                        this.setState({ visible: false, edit: false });
                    }}
                    modalProps={(visible, edit)}
                    updateTable={this.fetchBooks}
                    visible={Boolean(visible)}
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}

@injectIntl
class ReferenceBookModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bookCodes: [],
            requisites: [],
            numberOfDigitsInIndex: 2
        };

        const { formatMessage } = props.intl;
    }

    handleOk = async () => {
        const { id, requisiteId, formCode, throughNumbering, numberOfDigitsInIndex, prefix0, prefix1, index } =
            this.state;
        if (id) {
            await fetchAPI(
                'PUT',
                'reference_book/settings',
                null,
                [
                    {
                        id,
                        requisiteId,
                        formCode,
                        throughNumbering,
                        numberOfDigitsInIndex,
                        prefix0,
                        prefix1,
                        index
                    }
                ],
                { handleErrorInternally: true }
            );
        } else {
            try {
                await fetchAPI(
                    'POST',
                    'reference_book/settings',
                    null,
                    [
                        {
                            requisiteId,
                            formCode,
                            throughNumbering,
                            numberOfDigitsInIndex,
                            prefix0,
                            prefix1
                        }
                    ],
                    { handleErrorInternally: true }
                );
                notification.success({
                    message: this.props.intl.formatMessage({ id: 'barcode.success' })
                });
            } catch (e) {
                notification.error({
                    message: this.props.intl.formatMessage({
                        id: 'directory_page.reference_book_alredy_created'
                    })
                });
                console.log(e);
            }
        }

        this.props.updateTable();
        this.handleCancel();
    };

    handleCancel = () => {
        this.setState({
            id: undefined,
            requisiteId: undefined,
            formCode: undefined,
            throughNumbering: false,
            numberOfDigitsInIndex: 2,
            prefix0: undefined,
            prefix1: undefined,
            edit: false
        });
        this.props.edit;
        this.props.hideModal();
    };

    fetchReferenceBooks = async () => {
        const bookCodes = await fetchAPI('GET', 'reference_book/form_codes');

        this.setState({ bookCodes });
    };

    fetchRequisites = async () => {
        const requisites = await fetchAPI('GET', 'businesses/requisites', null, null, {
            handleErrorInternally: true
        });
        this.setState({
            requisites
        });
    };

    componentDidMount() {
        this.fetchRequisites();
        this.fetchReferenceBooks();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            this.setState({
                ...this.props.modalProps
            });
        }
    }

    render() {
        const {
            visible,
            hideModal,
            intl: { formatMessage },
            edit
        } = this.props;
        const {
            requisiteId,
            formCode,
            throughNumbering,
            numberOfDigitsInIndex,
            prefix0,
            prefix1,
            bookCodes,
            requisites,
            index
        } = this.state;

        return (
            <div>
                <Modal
                    maskClosable={false}
                    okButtonProps={{
                        disabled: !requisiteId || !formCode
                    }}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={
                        <FormattedMessage
                            id={this.props.edit ? 'directory_page.title_edit_modal' : 'directory_page.title_add_modal'}
                        />
                    }
                    visible={visible}
                    width='60vw'
                >
                    <Row className={Styles.row}>
                        <Col className={Styles.boldCol} span={6}>
                            <FormattedMessage id='directory_page.requisites' />:
                        </Col>
                        <Col span={12}>
                            <Select
                                dropdownMatchSelectWidth
                                dropdownStyle={{ zIndex: '9999' }}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={requisiteId => {
                                    this.setState({ requisiteId });
                                }}
                                optionFilterProp='children'
                                placeholder={<FormattedMessage id='directory_page.requisites' />}
                                showSearch
                                value={requisiteId}
                            >
                                {requisites.map(({ id, name, ifi }) => (
                                    <Option key={id} value={id}>
                                        {`${name} ${ifi ? ` (${ifi})` : ''} `}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                    {this.props.edit && (
                        <Row className={Styles.row}>
                            <Col className={Styles.boldCol} span={6}>
                                <FormattedMessage id='directory_page.current_number' />
                            </Col>
                            <Col span={4}>
                                <InputNumber
                                    onChange={index => {
                                        this.setState({
                                            index
                                        });
                                    }}
                                    value={index}
                                />
                            </Col>
                        </Row>
                    )}
                    {/* <Row className={Styles.row}>
                        <Col className={Styles.boldCol} span={6}>
                            <FormattedMessage id='directory_page.doc_type'/>:
                        </Col>
                        <Col span={12}>
                            <Select 
                                dropdownMatchSelectWidth
                                mode="multiple"
                                placeholder={<FormattedMessage id='directory_page.doc_type' />}
                                dropdownStyle={{zIndex: "9999"}}
                                value={docTypes} 
                                getPopupContainer={ trigger=>trigger.parentNode }
                                onChange={(docTypes) => { 
                                    this.setState({docTypes}) }} 
                                showSearch
                                optionFilterProp = 'children'
                            >
                                {posibleDocCodes.map(doc => (
                                    <Option value={doc} key={doc}>
                                        {doc}
                                    </Option>
                                ))}
                                </Select>
                        </Col>
                    </Row> */}
                    <Row className={Styles.row}>
                        <Col className={Styles.boldCol} span={6}>
                            <FormattedMessage id='directory_page.type_print_form' />:
                        </Col>
                        <Col span={12}>
                            <Select
                                dropdownMatchSelectWidth
                                dropdownStyle={{ zIndex: '9999' }}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={formCode => {
                                    this.setState({ formCode });
                                }}
                                optionFilterProp='children'
                                placeholder={<FormattedMessage id='directory_page.type_print_form' />}
                                showSearch
                                value={formCode}
                            >
                                {bookCodes.map(elem => (
                                    <Option key={elem.code} value={elem.code}>
                                        {`${elem.code} - ${elem.name}`}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col className={Styles.boldCol} span={6}>
                            <FormattedMessage id='directory_page.number_of_digits' />:
                        </Col>
                        <Col span={12}>
                            <InputNumber
                                min={0}
                                onChange={numberOfDigitsInIndex => this.setState({ numberOfDigitsInIndex })}
                                precision={0}
                                value={numberOfDigitsInIndex}
                            />
                        </Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col className={Styles.boldCol} span={6}>
                            <FormattedMessage id='directory_page.end-to-end_numbering' />:
                        </Col>
                        <Col span={12}>
                            <Checkbox
                                checked={throughNumbering}
                                onChange={event => {
                                    this.setState({ throughNumbering: event.target.checked });
                                }}
                            ></Checkbox>
                        </Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col className={Styles.boldCol} span={6}>
                            <FormattedMessage id='directory_page.prefix_1' />:
                        </Col>
                        <Col span={4}>
                            <Input
                                onChange={event => {
                                    const e = event.target.value.replace(/[а-яА-ЯіІєЄґҐёЁ]/g, '');
                                    this.setState({ prefix0: e });
                                }}
                                suffix={
                                    <Tooltip
                                        getPopupContainer={trigger => trigger.parentNode}
                                        title={formatMessage({ id: 'directory_page.input_hint' })}
                                    >
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                                value={prefix0}
                            ></Input>
                        </Col>
                        <Col className={Styles.boldCol} offset={1} span={3}>
                            <FormattedMessage id='directory_page.prefix_2' />:
                        </Col>
                        <Col span={4}>
                            <Input
                                onChange={event => {
                                    const e = event.target.value.replace(/[а-яА-ЯiІєЄґҐёЁ]/g, '');
                                    this.setState({ prefix1: e });
                                }}
                                suffix={
                                    <Tooltip
                                        getPopupContainer={trigger => trigger.parentNode}
                                        title={formatMessage({ id: 'directory_page.input_hint' })}
                                    >
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                                value={prefix1}
                            ></Input>
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}

export { ReferenceBookModal };
