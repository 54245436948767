import { Input, Modal, Select } from 'antd';
import { get, omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { filterByPart } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const partTypes = [
    { label: <FormattedMessage id='diagnostics_directories.title.PC' />, value: 'PC' },
    { label: <FormattedMessage id='diagnostics_directories.title.CV' />, value: 'CV' },
    { label: <FormattedMessage id='diagnostics_directories.title.SMT' />, value: 'SMT' }
];

const ManagePartModal = ({ open, handleClose, onOk, part, labors, intl }) => {
    const editMode = typeof get(part, 'partId') === 'number';
    const titleId = editMode ? 'diagnostics_directories.title.edit_part' : 'diagnostics_directories.title.add_part';

    const [partData, setPartData] = useState({});

    useEffect(() => setPartData(part ? { ...omit(part, 'type') } : {}), [open]);

    const handlePartDataChange = key => value => {
        setPartData(prev => ({ ...prev, [key]: value }));
    };

    return (
        <Modal
            destroyOnClose
            disabledButton
            okButtonProps={{ disabled: !partData.partName || !partData.partLaborId || (!partData.type && !editMode) }}
            onCancel={handleClose}
            onOk={() => {
                onOk(partData);
                handleClose();
            }}
            open={open}
            title={<FormattedMessage id={titleId} />}
            width={480}
        >
            <div className={Styles.inputsContainer}>
                <div className={Styles.inputLabelGroup}>
                    <p>
                        <FormattedMessage id='diagnostics_directories.edit.partName' />
                    </p>
                    <Input
                        onChange={e => handlePartDataChange('partName')(e.target.value)}
                        placeholder={intl.formatMessage({ id: 'diagnostics_directories.edit.partName' })}
                        value={partData.partName}
                    />
                </div>
                <div className={Styles.inputLabelGroup}>
                    <p>
                        <FormattedMessage id='diagnostics_directories.edit.partLabor' />
                    </p>
                    <Select
                        filterOption={(input, option) => filterByPart(input, option, 'children')}
                        onChange={(value, { masterLaborId, storeGroupId }) => {
                            handlePartDataChange('partLaborId')(masterLaborId);
                            handlePartDataChange('partStoreGroupId')(storeGroupId);
                        }}
                        placeholder={intl.formatMessage({ id: 'diagnostics_directories.edit.partLabor' })}
                        showSearch
                        value={partData.partLaborId ? `${partData.partLaborId}${partData.partStoreGroupId}` : null}
                    >
                        {labors.map(({ id, name, masterLaborId, storeGroupId }) => (
                            <Option key={id} masterLaborId={masterLaborId} storeGroupId={storeGroupId} value={id}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                </div>
                {!editMode && (
                    <div className={Styles.inputLabelGroup}>
                        <p>
                            <FormattedMessage id='diagnostics_directories.edit.partType' />
                        </p>
                        <Select
                            onChange={value => handlePartDataChange('type')(value)}
                            placeholder={intl.formatMessage({ id: 'diagnostics_directories.edit.partType' })}
                            value={partData.type}
                        >
                            {partTypes.map(({ value, label }) => (
                                <Option key={value} value={value}>
                                    {label}
                                </Option>
                            ))}
                        </Select>
                    </div>
                )}
                <div className={Styles.inputLabelGroup}>
                    <p>
                        <FormattedMessage id='diagnostics_directories.edit.partDuration' />
                    </p>
                    <Input
                        min={0}
                        onChange={e => handlePartDataChange('partDuration')(e.target.value)}
                        placeholder={intl.formatMessage({ id: 'diagnostics_directories.edit.partDuration' })}
                        step={0.1}
                        type='number'
                        value={Number(partData.partDuration)}
                    />
                </div>
                {/* <div className={Styles.inputLabelGroup}>
                    <p>
                        <FormattedMessage id='diagnostics_directories.edit.partAction' />
                    </p>
                    <Input
                        onChange={e => handlePartDataChange('partAction')(e.target.value)}
                        placeholder={intl.formatMessage({ id: 'diagnostics_directories.edit.partAction' })}
                        value={partData.partAction}
                    />
                </div> */}
            </div>
        </Modal>
    );
};

export default injectIntl(ManagePartModal);
