import { Button, Checkbox, Input } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FeaturesIcons } from 'theme';
import './styles.less';

export const FeaturesSettings = ({ cb24data, formatMessage, saveData, isDisabledCRUD }) => {
    const [features, setFeatures] = useState(_.get(cb24data, 'features', []));

    return (
        <React.Fragment>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}
            >
                {features.map((ft, index) => {
                    const { name, type, description, lock } = ft;

                    return (
                        <div
                            key={type}
                            icon={`theme/images/icons/features.svg#${type}`}
                            style={{
                                width: '48%',
                                margin: '1%'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingBottom: 8
                                }}
                            >
                                <Checkbox
                                    checked={lock}
                                    disabled={isDisabledCRUD}
                                    onChange={() => {
                                        const tmp = [...features];
                                        tmp[index].lock = !tmp[index].lock;
                                        setFeatures(tmp);
                                    }}
                                >
                                    {name}
                                </Checkbox>
                                <FeaturesIcons color='var(--primary)' name={type} size={24} />
                            </div>

                            <Input
                                disabled={!lock || isDisabledCRUD}
                                onChange={e => {
                                    const tmp = [...features];
                                    tmp[index].description = e.target.value;
                                    setFeatures(tmp);
                                }}
                                placeholder={formatMessage({
                                    id: 'general_settings.additional_description'
                                })}
                                value={description}
                            />
                        </div>
                    );
                })}
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Button
                    onClick={() => {
                        saveData({ features });
                    }}
                    style={{
                        marginTop: 24
                    }}
                    type='primary'
                >
                    <FormattedMessage id='general_settings.submit' />
                </Button>
            </div>
        </React.Fragment>
    );
};
