import { Modal } from 'antd';
import { MODALS, resetModal } from 'core/modals/duck';
import { resetProductEanData } from 'core/storage/products';
import { StoreProductForm } from 'forms/StorageForms';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

const StoreProductModal = props => {
    const [editing, setEditing] = useState(_.get(props, 'modalProps.editing'));

    useEffect(() => {
        setEditing(_.get(props, 'modalProps.editing'));
    }, [_.get(props, 'modalProps.editing')]);

    return (
        <Modal
            cancelText={<FormattedMessage id='cancel' />}
            destroyOnClose
            footer={null}
            maskClosable={false}
            okText={<FormattedMessage id='save' />}
            onCancel={() => {
                if (props.modalProps.onClose) {
                    props.modalProps.onClose();
                }
                props.resetProductEanData();
                props.resetModal();
            }}
            title={
                editing ? (
                    <span>
                        <FormattedMessage id='storage.product_code' />: {props.modalProps.code}
                    </span>
                ) : (
                    <FormattedMessage id='storage.add_product' />
                )
            }
            visible={props.modal === MODALS.STORE_PRODUCT}
            width='60%'
            zIndex={300}
        >
            <StoreProductForm editing={editing} modalProps={props.modalProps} resetModal={props.resetModal} />
        </Modal>
    );
};

const mapStateToProps = state => ({
    modal: state.modals.modal,
    modalProps: state.modals.modalProps
});

export default connect(mapStateToProps, { resetModal, resetProductEanData })(StoreProductModal);
