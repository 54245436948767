import { FilterFilled, PrinterOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, Select, Slider, Table, Tooltip } from 'antd';
import { DataItem, Numeral } from 'commons';
import { DateRangePicker, OrderStatusIcon } from 'components';
import { setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { numeralFormatter, numeralParser } from 'utils';
import Styles from './styles.m.css';

const dateFormat = 'YYYY-MM-DD';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setModal
};

/**
 * Table of inspection intervals
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class BookHistoryContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            statusesFilter: undefined
        };

        this.columns = () => [
            {
                title: <FormattedMessage id='orders.record_date' />,
                key: 'beginDatetime',
                dataIndex: 'beginDatetime',
                width: '10%',
                ...this.getColumnSearchProps('startDate'),
                render: (data, row) => {
                    return <div>{row.beginDatetime ? dayjs(row.beginDatetime).format('DD.MM.YYYY HH:mm') : ''}</div>;
                }
            },
            {
                title: <FormattedMessage id='storage_journal.closing_date' />,
                key: 'successDatetime',
                dataIndex: 'successDatetime',
                width: '10%',
                ...this.getColumnSearchProps('startSuccessDatetime'),
                render: (data, row) => {
                    return (
                        <div>{row.successDatetime ? dayjs(row.successDatetime).format('DD.MM.YYYY HH:mm') : ''}</div>
                    );
                }
            },
            {
                title: <FormattedMessage id='client_order_tab.odometer' />,
                key: 'odometer',
                dataIndex: 'odometer',
                width: '10%',
                sorter: (a, b) => a.odometer - b.odometer,
                sortDirections: ['descend', 'ascend'],
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='calls-table.order' />,

                key: 'num',
                dataIndex: 'num',
                width: '25%',
                render: (data, order) => (
                    <React.Fragment>
                        <div
                            className={Styles.orderLink}
                            onClick={() => {
                                this.props.getReport({
                                    link: `/orders/reports/completedWorkReport/${order.id}`,
                                    name: 'completedWorkReport',
                                    token: this.props.token
                                });
                            }}
                        >
                            {order.num ? order.num : <FormattedMessage id='orders.manually_data' />}
                        </div>{' '}
                        {order.num && <OrderStatusIcon status={order.status} />}
                        {order.serviceNames && (
                            <div className={Styles.serviceNames}>{[...new Set(order.serviceNames)].join(', ')}</div>
                        )}
                        {order.recommendation && <div className={Styles.recommendation}>{order.recommendation}</div>}
                        {(order.cancelReason || order.cancelStatusReason || order.cancelStatusOwnReason) && (
                            <div className={Styles.cancelReason}>
                                <div>{order.cancelStatusReason}</div>
                                <div>{order.cancelStatusOwnReason}</div>
                            </div>
                        )}
                    </React.Fragment>
                )
            },
            {
                title: <FormattedMessage id='orders.sum_without_VAT' />,
                key: 'sum',
                dataIndex: 'totalSum',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral> ₴
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.recommendation_n_reasons' />,
                key: 'recom',
                dataIndex: 'recommendation',
                align: 'center',
                width: '10%',
                render: data => {
                    return data || undefined;
                }
            },
            {
                title: <FormattedMessage id='status' />,
                key: 'recom',
                dataIndex: 'status',
                align: 'center',
                width: '10%',
                ...this.getColumnSearchProps('statuses'),
                render: data => {
                    return (
                        <span
                            className={data == 'progress' ? Styles.green : data == 'success' ? Styles.blue : Styles.red}
                        >
                            <FormattedMessage id={`order_statuses_mapper.${data}`} />{' '}
                        </span>
                    );
                }
            },
            {
                key: 'laborName',
                dataIndex: 'laborName',
                align: 'center',
                width: '5%',
                render: (data, row) => {
                    return (
                        <div>
                            <Tooltip title={<FormattedMessage id='print' />}>
                                <Button
                                    icon={<PrinterOutlined />}
                                    onClick={() => {
                                        this.props.getReport({
                                            link: `/orders/reports/completedWorkReport/${row.id}`,
                                            name: 'completedWorkReport',
                                            token: this.props.token
                                        });
                                    }}
                                    type='text'
                                />
                            </Tooltip>
                        </div>
                    );
                }
            }
        ];
    }

    getColumnSearchProps = dataIndex => {
        let filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.setState({
                        [dataIndex]: e.target.value
                    });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                style={{ marginBottom: 8, display: 'block', width: 180 }}
                value={this.state[dataIndex]}
            />
        );

        if (dataIndex === 'statuses') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={value => {
                        this.setState({
                            statusesFilter: value
                        });
                    }}
                    // mode='multiple'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_journal.choose_type'
                    })}
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                    value={this.state.statusesFilter}
                >
                    <Option value='not_complete'>
                        <FormattedMessage id='order_statuses_mapper.not_complete' />
                    </Option>
                    <Option value='progress'>
                        <FormattedMessage id='order_statuses_mapper.progress' />
                    </Option>
                    <Option value='reserve'>
                        <FormattedMessage id='order_statuses_mapper.reserve' />
                    </Option>
                    <Option value='required'>
                        <FormattedMessage id='order_statuses_mapper.required' />
                    </Option>
                    <Option value='success'>
                        <FormattedMessage id='order_statuses_mapper.success' />
                    </Option>
                    <Option value='invite'>
                        <FormattedMessage id='order_statuses_mapper.invite' />
                    </Option>
                    <Option value='call'>
                        <FormattedMessage id='order_statuses_mapper.call' />
                    </Option>
                    <Option value='approve'>
                        <FormattedMessage id='order_statuses_mapper.approve' />
                    </Option>
                    <Option value='cancel'>
                        <FormattedMessage id='order_statuses_mapper.cancel' />
                    </Option>
                </Select>
            );
        }

        if (dataIndex === 'startDate') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        dateRange={[
                            this.props.sort.startDate ? dayjs(this.props.sort.startDate) : undefined,
                            this.props.sort.endDate ? dayjs(this.props.sort.endDate) : undefined
                        ]}
                        format={dateFormat}
                        getPopupContainer={trigger => trigger.parentNode}
                        onDateChange={async dateRange => {
                            await this.props.setPageOrders({
                                startDate: dayjs(dateRange[0]).format(dateFormat),
                                endDate: dayjs(dateRange[1]).format(dateFormat),
                                page: 1
                            });
                            this.handleSearch(confirm, dataIndex);
                            this.props.fetchVehicleOrders();
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            );
        }

        if (dataIndex === 'startSuccessDatetime') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        dateRange={[
                            this.props.sort.startSuccessDatetime
                                ? dayjs(this.props.sort.startSuccessDatetime)
                                : undefined,
                            this.props.sort.endSuccessDatetime ? dayjs(this.props.sort.endSuccessDatetime) : undefined
                        ]}
                        format={dateFormat}
                        getPopupContainer={trigger => trigger.parentNode}
                        onDateChange={async dateRangeSuccess => {
                            await this.props.setPageOrders({
                                startSuccessDatetime: dayjs(dateRangeSuccess[0]).format(dateFormat),
                                endSuccessDatetime: dayjs(dateRangeSuccess[1]).format(dateFormat),
                                page: 1
                            });
                            this.handleSearch(confirm, dataIndex);
                            this.props.fetchVehicleOrders();
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            );
        }

        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            marginTop: 8
                        }}
                    >
                        {dataIndex === 'statuses' && (
                            <Button
                                icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                onClick={() => this.handleSearch(confirm, dataIndex)}
                                size='small'
                                type='primary'
                            >
                                <FormattedMessage id='search' />
                            </Button>
                        )}
                        <Button
                            onClick={() => this.handleReset(confirm, clearFilters, dataIndex)}
                            size='small'
                            type='primary'
                        >
                            <FormattedMessage id='reset' />
                        </Button>
                    </div>
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.props.sort[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = async confirm => {
        const { sort } = this.props;
        const { statusesFilter } = this.state;
        confirm();
        await this.props.setPageOrders({
            page: 1,
            startDate: sort.startDate,
            endDate: sort.endDate,
            statuses: statusesFilter,
            startSuccessDatetime: sort.startSuccessDatetime,
            endSuccessDatetime: sort.endSuccessDatetime
        });
        this.props.fetchVehicleOrders();
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.setState({
            [dataIndex]: undefined,
            statusesFilter: undefined
        });
        await this.props.setPageOrders({
            page: 1,
            pageSize: 25,
            startDate: undefined,
            endDate: undefined,
            startSuccessDatetime: undefined,
            endSuccessDatetime: undefined,
            statuses: undefined
        });
        this.props.fetchVehicleOrders();
    };

    render() {
        const {
            orders,
            setPageOrders,
            sort,
            stats,
            fetchVehicleOrders,
            intl: { formatMessage },
            isMobile
        } = this.props;

        const { searchInput, startDate, endDate, mileageFrom, mileageTo } = this.state;

        const pagination = {
            pageSize: sort.pageSize,
            total: Math.ceil(_.get(stats, 'countOrders', 0) / sort.pageSize) * sort.pageSize,
            hideOnSinglePage: true,
            current: sort.page,
            onChange: async (page, pageSize) => {
                await setPageOrders({ page, pageSize, startDate: sort.startDate, endDate: sort.endDate });
                fetchVehicleOrders();
            }
        };

        return !isMobile ? (
            <div>
                <DataRow>
                    <DataWrapper>
                        <span
                            style={{
                                marginRight: 4
                            }}
                        >
                            <FormattedMessage id='remain' /> (₴):
                        </span>
                        <Numeral mask='0,0.00'>{orders.reduce((acc, cur) => acc + cur.remainingSum, 0)}</Numeral>
                    </DataWrapper>
                </DataRow>
                <div
                    style={{
                        marginLeft: 8
                    }}
                >
                    <Input
                        onChange={event => {
                            this.setState({
                                searchInput: event.target.value
                            });
                        }}
                        placeholder={formatMessage({ id: 'online_service_book_page.search_by_order_history' })}
                        style={{
                            width: '60%'
                        }}
                        value={searchInput}
                    />
                    <Button
                        icon={<SearchOutlined />}
                        onClick={async () => {
                            await setPageOrders({
                                page: 1,
                                pageSize: 25,
                                startDate: sort.startDate || undefined,
                                endDate: sort.endDate || undefined,
                                queryFilters: searchInput
                            });
                        }}
                    />
                    <Button
                        icon={<UndoOutlined />}
                        onClick={async () => {
                            await setPageOrders({
                                page: 1,
                                pageSize: 25,
                                startDate: sort.startDate || undefined,
                                endDate: sort.endDate || undefined,
                                queryFilters: undefined
                            });
                            this.setState({
                                searchInput: undefined
                            });
                        }}
                    />
                </div>
                <div className={Styles.tableCont}>
                    <Table
                        bordered
                        columns={this.columns()}
                        dataSource={orders}
                        pagination={pagination}
                        rowKey='id'
                        size='small'
                    />
                </div>
            </div>
        ) : (
            <div>
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        allowClear
                        dateRange={[startDate ? dayjs(startDate) : undefined, endDate ? dayjs(endDate) : undefined]}
                        onDateChange={async dateRange => {
                            await this.setState({
                                startDate: dayjs(dateRange[0]).format(dateFormat),
                                endDate: dayjs(dateRange[1]).format(dateFormat)
                            });
                        }}
                        popupStyle={{
                            maxHeight: 250,
                            overflow: 'auto',
                            zIndex: '9999',
                            maxWidth: 150
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
                <div className={Styles.filteredInput}>
                    <Input
                        onChange={event => {
                            this.setState({
                                searchInput: event.target.value
                            });
                        }}
                        placeholder={formatMessage({ id: 'online_service_book_page.search_by_order_history' })}
                        style={{
                            width: '100%'
                        }}
                        value={searchInput}
                    />
                </div>
                <div className={Styles.odometrBlock}>
                    <div className={Styles.titleOdometr}>
                        <FormattedMessage id='client_order_tab.odometer' />
                    </div>
                    <Slider
                        max={500000}
                        min={1}
                        onChange={([mileageFrom, mileageTo]) => {
                            this.setState({
                                mileageFrom,
                                mileageTo
                            });
                        }}
                        range
                        value={typeof mileageFrom === 'number' ? [mileageFrom, mileageTo] : [0, 1]}
                    />
                    <div className={Styles.inputNumberOdometr}>
                        <InputNumber
                            formatter={value => numeralFormatter(value)}
                            max={mileageTo}
                            onChange={mileageFrom => {
                                this.setState({ mileageFrom });
                            }}
                            parser={value => numeralParser(value)}
                            style={{
                                margin: '0 16px'
                            }}
                            value={Math.round(mileageFrom * 100) / 100 || 0}
                        />
                        <InputNumber
                            formatter={value => numeralFormatter(value)}
                            min={mileageFrom}
                            onChange={mileageTo => {
                                this.setState({ mileageTo });
                            }}
                            parser={value => numeralParser(value)}
                            style={{
                                margin: '0 16px'
                            }}
                            value={Math.round(mileageTo * 100) / 100 || 0}
                        />
                    </div>
                </div>
                <div className={Styles.filterBtns}>
                    <Button
                        disabled={mileageFrom >= mileageTo}
                        onClick={async () => {
                            await setPageOrders({
                                startDate: startDate || undefined,
                                endDate: endDate || undefined,
                                mileageFrom: mileageFrom || undefined,
                                mileageTo: mileageTo || undefined,
                                queryFilters: searchInput
                            });
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='search' />
                    </Button>
                    <Button
                        onClick={async () => {
                            await setPageOrders({
                                searchInput: undefined,
                                startDate: undefined,
                                endDate: undefined,
                                mileageFrom: undefined,
                                mileageTo: undefined
                            });
                            this.setState({
                                searchInput: undefined,
                                startDate: undefined,
                                endDate: undefined,
                                mileageFrom: undefined,
                                mileageTo: undefined
                            });
                        }}
                    >
                        <FormattedMessage id='storage.reset_all_filters' />
                    </Button>
                </div>

                {orders.map(
                    ({ num, id, status, odometer, beginDatetime, deliveryDatetime, totalSum, remainingSum }) => (
                        <div
                            style={{
                                borderBottom: '3px solid var(--primary)',
                                marginBottom: 12
                            }}
                        >
                            <div className={Styles.firtBlockInfoStyle}>
                                <div className={Styles.dataItemBlock}>
                                    <DataItem
                                        className={Styles.dataItemMobile}
                                        label={<FormattedMessage id='reports_page.orders' />}
                                    >
                                        <div
                                            className={Styles.orderLink}
                                            onClick={() => {
                                                this.props.getReport({
                                                    link: `/orders/reports/completedWorkReport/${id}`,
                                                    name: 'completedWorkReport',
                                                    token: this.props.token
                                                });
                                            }}
                                        >
                                            {num}
                                        </div>
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItemMobile}
                                        label={<FormattedMessage id='orders.status' />}
                                    >
                                        <span
                                            className={
                                                status == 'progress'
                                                    ? Styles.green
                                                    : status == 'success'
                                                    ? Styles.blue
                                                    : Styles.red
                                            }
                                        >
                                            <FormattedMessage id={`order_statuses_mapper.${status}`} />
                                        </span>
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItemMobile}
                                        label={<FormattedMessage id='client_order_tab.odometer' />}
                                    >
                                        <Numeral mask='0,0'>{Number(odometer) || '-'}</Numeral>{' '}
                                        <FormattedMessage id='online_service_book_page.km' />
                                    </DataItem>
                                    <DataItem className={Styles.dataItemMobile} label={<FormattedMessage id='print' />}>
                                        <Tooltip title={<FormattedMessage id='print' />}>
                                            <Button
                                                icon={<PrinterOutlined />}
                                                onClick={() => {
                                                    this.props.getReport({
                                                        link: `/orders/reports/completedWorkReport/${id}`,
                                                        name: 'completedWorkReport',
                                                        token: this.props.token
                                                    });
                                                }}
                                                type='text'
                                            />
                                        </Tooltip>
                                    </DataItem>
                                </div>
                                <div className={Styles.dataItemBlock}>
                                    <DataItem
                                        className={Styles.dataItemMobile}
                                        label={<FormattedMessage id='orders.begin_date' />}
                                    >
                                        {beginDatetime ? dayjs(beginDatetime).format(dateFormat) : '-'}
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItemMobile}
                                        label={<FormattedMessage id='orders.delivery_date' />}
                                    >
                                        {deliveryDatetime ? dayjs(deliveryDatetime).format(dateFormat) : '-'}
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItemMobile}
                                        label={<FormattedMessage id='remain' />}
                                    >
                                        <Numeral mask='0,0'>{Number(remainingSum) || '-'}</Numeral> ₴
                                    </DataItem>
                                    <DataItem className={Styles.dataItemMobile} label={<FormattedMessage id='sum' />}>
                                        <Numeral mask='0,0'>{Number(totalSum) || '-'}</Numeral> ₴
                                    </DataItem>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
        );
    }
}

const DataRow = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 14px;
    padding: 14px 0;

    border-bottom: 1px solid;
    border-top: 1px solid;
`;

const DataWrapper = styled.div`
    display: flex;
    font-size: 16px;
`;
