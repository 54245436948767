import DashboardPage from './DashboardPage';
import ExceptionPage from './ExceptionPage';
import ProfilePage from './ProfilePage';
import LaborsPage from './LaborsPage';
import AddEmployeePage from './AddEmployeePage';
import AddOrderPage from './AddOrderPage';
import CashFlowPage from './CashFlowPage';
import CashSettingsPage from './CashSettingsPage';
import ClientsPage from './ClientsPage';
import ClientHotOperationsPage from './ClientHotOperationsPage';
import ClientPage from './ClientPage';
import EditEmployeePage from './EditEmployeePage';
import EmployeesPage from './EmployeesPage';
import OrdersPage from './OrdersPage';
import OrderPage from './OrderPage';
import ReportOrdersPage from './ReportOrdersPage';
import ChartPage from './ChartPage';
import ManagerRolePage from './ManagerRolePage';
import VehicleTypesPage from './VehicleTypesPage';
import TirePriceGroupsPage from './TirePriceGroupsPage';
import MainMenuPage from './MainMenuPage';
import CashBankPage from './CashBankPage';

export {
    DashboardPage,
    ExceptionPage,
    ProfilePage,
    LaborsPage,
    OrdersPage,
    OrderPage,
    AddEmployeePage,
    AddOrderPage,
    CashFlowPage,
    CashSettingsPage,
    ClientsPage,
    ClientPage,
    EditEmployeePage,
    EmployeesPage,
    ReportOrdersPage,
    ChartPage,
    ManagerRolePage,
    VehicleTypesPage,
    TirePriceGroupsPage,
    ClientHotOperationsPage,
    MainMenuPage,
    CashBankPage
};
