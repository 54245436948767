/* eslint-disable no-undefined */
import { DollarOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image } from 'antd';
import { Layout } from 'commons';
import { StorageDocumentsFilters } from 'components';
import { StorageTable } from 'containers';
import { MODALS, setModal } from 'core/modals/duck';
import _ from 'lodash';
import { RefactoredCashOrderModal } from 'modals';
import { CASH_ORDER_TYPES, COUNTERPARTY_TYPES } from 'modals/RefactoredCashOrderModal/constants';
import * as constants from 'pages/Storage/constants';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const dateFormat = 'DD.MM.YYYY';
const fetchConst = {
    INCOME: {
        operationCodes: '["INC", "STP", "SRV", "CRT", "KPP", "MAN", "PRI", "DSI"]'
    },
    EXPENSE: {
        operationCodes: '["SRT", "OUT", "STM", "CST", "AUT", "VRT", "KPM", "MAN", "PRO", "DSO"]'
    },
    TRANSFER: {
        operationCodes: '["TSF", "TOL", "TOR"]'
    },
    ORDER: {
        operationCodes: '["ORD", "BOR", "COM"]'
    },
    STORAGE_ORDERS_EXPENSE: {
        operationCodes: '["AUT", "TSF"]'
    }
};

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setModal
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class StorageDocumentsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storageDocumentsList: [],
            dateRange: [],
            page: 1,
            totalCount: 0,
            pageSize: 25,
            allLinks: [],
            helperDrawerOpen: false,
            warehouse: ''
        };
    }

    fetchHelperLinks = async () => {
        const { listType } = this.props;
        const links = await fetchAPI(
            'GET',
            '/helps',
            {
                helpId:
                    listType === constants.ORDER
                        ? 'storage_orders'
                        : listType === constants.INCOME
                        ? 'storage_incomes'
                        : listType === constants.EXPENSE
                        ? 'storage_expenses'
                        : listType === constants.TRANSFER
                        ? 'storage_transfers'
                        : 'storage_orders'
            },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        this.setState({
            allLinks: links
        });
    };

    fetchStorageList = async (pageVar, pageSizeVar) => {
        await this.setState({ loading: true });
        const { query, operationCodes, status, dateRange, page, pageSize, warehouseId } = this.state;
        const {
            list: storageDocumentsList,
            stats: { count }
        } = await fetchAPI('GET', 'store_docs', {
            page: pageVar || page,
            pageSize: pageSizeVar || pageSize,
            operationCodes: operationCodes || fetchConst[this.props.listType].operationCodes,
            status,
            warehouseId,
            query,
            startDate: dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : undefined,
            endDate: dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : undefined
        });
        this.setState({
            storageDocumentsList,
            totalCount: count,
            loading: false
        });
    };

    fetchWarehouses = async () => {
        await this.setState({ loading: true });
        const warehouses = await fetchAPI('GET', 'warehouses');
        this.setState({
            warehouses,
            loading: false
        });
    };

    componentDidMount = () => {
        this.fetchStorageList();
        this.fetchWarehouses();
    };

    setWarehouseId = async warehouseId => {
        await this.setState({ warehouseId });
        this.fetchStorageList();
    };

    render() {
        const { newDocType, isCRUDForbidden, listType, setModal, user } = this.props;
        const {
            dateRange,
            storageDocumentsList,
            loading,
            page,
            pageSize,
            totalCount,
            operationCodes,
            helperDrawerOpen,
            allLinks,
            warehouses,
            warehouseId
        } = this.state;

        const pagination = {
            pageSize,
            size: 'small',
            total: Math.ceil(totalCount / pageSize) * pageSize,
            hideOnSinglePage: false,
            current: page,
            onChange: (page, pageSize) => {
                this.setState({ page, pageSize });
                this.fetchStorageList(page, pageSize);
            }
        };

        const renderIfGranted =
            isGrantAccessed(user, grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS_PAYMENT) ||
            isGrantAccessed(user, grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES_PAYMENT);

        return (
            <Layout
                controls={
                    <React.Fragment>
                        <StorageDocumentsFilters
                            dateFormat={dateFormat}
                            dateRange={dateRange}
                            documentStatusFilter={async status => {
                                await this.setState({ status });
                                this.fetchStorageList();
                            }}
                            documentTypeFilter={async operationCodes => {
                                await this.setState({ operationCodes });
                                this.fetchStorageList();
                            }}
                            fetchStorageList={this.fetchStorageList}
                            isFetched={Boolean(storageDocumentsList.length)}
                            listType={listType}
                            onDateChange={async dateRange => {
                                await this.setState({
                                    dateRange
                                });
                                this.fetchStorageList();
                            }}
                            type={newDocType}
                            user={user}
                        />

                        {listType === constants.EXPENSE && operationCodes !== constants.AUT && renderIfGranted && (
                            <Button
                                onClick={() => {
                                    setModal(MODALS.REFACTORED_CASH_ORDER);
                                }}
                                style={{
                                    margin: '0 15px 0 0',
                                    fontSize: 18
                                }}
                            >
                                <DollarOutlined
                                    style={{
                                        display: 'flex'
                                    }}
                                />
                            </Button>
                        )}
                        {listType === constants.INCOME && (
                            <Button
                                onClick={() => {
                                    setModal(MODALS.REFACTORED_CASH_ORDER, {
                                        initValues: {
                                            type: CASH_ORDER_TYPES.EXPENSE,
                                            counterpartyType: COUNTERPARTY_TYPES.BUSINESS_SUPPLIER
                                        }
                                    });
                                }}
                                style={{
                                    margin: '0 15px 0 0',
                                    fontSize: 18
                                }}
                            >
                                <DollarOutlined
                                    style={{
                                        display: 'flex'
                                    }}
                                />
                            </Button>
                        )}
                        {operationCodes !== constants.AUT && (
                            <Link
                                to={
                                    !isCRUDForbidden && {
                                        pathname: book.storageDocument,
                                        state: {
                                            operationCode: operationCodes,
                                            formData: {
                                                type: newDocType
                                            }
                                        }
                                    }
                                }
                            >
                                <Button disabled={isCRUDForbidden} type='primary'>
                                    <FormattedMessage id='add' />
                                </Button>
                            </Link>
                        )}
                        <Button
                            icon={<QuestionCircleOutlined />}
                            onClick={async () => {
                                const links = await fetchAPI(
                                    'GET',
                                    '/helps',
                                    {
                                        helpId:
                                            listType === constants.ORDER
                                                ? 'storage_orders'
                                                : listType === constants.INCOME
                                                ? 'storage_incomes'
                                                : listType === constants.EXPENSE
                                                ? 'storage_expenses'
                                                : listType === constants.TRANSFER
                                                ? 'storage_transfers'
                                                : 'storage_orders'
                                    },
                                    undefined,
                                    {
                                        handleErrorInternally: true
                                    }
                                );
                                this.setState({
                                    allLinks: links,
                                    helperDrawerOpen: true
                                });
                            }}
                            style={{
                                fontSize: 16,
                                marginLeft: 8
                            }}
                        />
                    </React.Fragment>
                }
                paper
                title={
                    <FormattedMessage
                        id={`navigation.${
                            this.props.docType === constants.TRANSFER ? 'transfer' : this.props.listType.toLowerCase()
                        }s`}
                    />
                }
            >
                <StorageTable
                    docType={newDocType}
                    documentsList={storageDocumentsList}
                    isCRUDForbidden={isCRUDForbidden}
                    listType={listType}
                    loading={loading}
                    onSearch={async query => {
                        await this.setState({ query });
                        this.fetchStorageList();
                    }}
                    page={page}
                    pageSize={pageSize}
                    pagination={pagination}
                    setPage={async (page, pageSize) => {
                        await this.setState({ page, pageSize });
                        this.fetchStorageList();
                    }}
                    setWarehouseId={this.setWarehouseId}
                    totalCount={totalCount}
                    warehouseId={warehouseId}
                    warehouses={warehouses}
                />
                <RefactoredCashOrderModal />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}

export default StorageDocumentsContainer;
