import { Catcher, Layout, Paper } from 'commons';
import { PriceGroupsTable } from 'components';
import { PriceGroupForm } from 'forms';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const PriceGroupsPage = () => {
    return (
        <Layout
            controls={<PriceGroupForm />}
            paper={false}
            title={<FormattedMessage id='navigation.price_groups' />}
        >
            <Catcher>
                <Paper>
                    <PriceGroupsTable />
                </Paper>
            </Catcher>
        </Layout>
    );
};
