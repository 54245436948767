import { BellOutlined, GlobalOutlined } from '@ant-design/icons';
import { Radio, Spin, Table } from 'antd';
import _ from 'lodash';
import {
    fetchSubtasks,
    selectFetching,
    selectFilters,
    selectStats,
    selectSubtasks,
    setFilters
} from 'modals/EmployeeSubTasksModal/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import book from 'routes/book';
import history from 'store/history';
import { fetchAPI } from 'utils';
import { v4 } from 'uuid';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const mapStateToProps = state => ({
    user: state.auth,
    subtasks: selectSubtasks(state),
    fetching: selectFetching(state),
    stats: selectStats(state),
    filters: selectFilters(state)
});

const mapDispatchToProps = {
    // setModal,
    fetchSubtasks,
    setFilters
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class TasksTable extends React.Component {
    componentDidMount() {
        this.props.fetchSubtasks(this.props.taskId);
    }

    handleTaskRedirect = async task => {
        const { user } = this.props;
        // if in NEW status then should be change to INWORK and task is assigned to manager

        if (task.status === 'NEW' && user.id === task.performerId) {
            await fetchAPI(
                'PUT',
                `employee_tasks/${task.id}`,
                undefined,
                { status: 'INWORK' },
                {
                    handleErrorInternally: true
                }
            ).then(() => {
                history.push({
                    pathname: `${book.myTasksPage}/${task.id}`
                });
            });
        } else {
            history.push({
                pathname: `${book.myTasksPage}/${task.id}`
            });
        }
    };

    render() {
        const {
            user,
            subtasks,
            stats,
            filters,
            fetching,
            setFilters,
            intl: { formatMessage }
        } = this.props;

        const columns = columnsConfig({
            user,
            onEmployeeSubTasks: this.onEmployeeSubTasks,
            filters,
            handleTaskRedirect: this.handleTaskRedirect
        });

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(stats.totalRowsCount / 25) * 25,
            current: filters.page,
            hideOnSinglePage: true,
            onChange: page => {
                setFilters({ page });
                this.props.fetchSubtasks(this.props.taskId);
            }
        };

        return (
            <div className={Styles.tableCont}>
                {fetching ? (
                    <Spin />
                ) : (
                    <div>
                        <RadioGroup
                            className={Styles.buttonFilterGroup}
                            defaultValue={filters.onlyNew}
                            onChange={e => {
                                this.props.setFilters({ onlyNew: e.target.value });
                                this.props.fetchSubtasks(this.props.taskId);
                            }}
                        >
                            <RadioButton value={false}>
                                <GlobalOutlined
                                    style={{
                                        fontSize: 18,
                                        verticalAlign: 'middle'
                                    }}
                                    title={formatMessage({ id: 'all' })}
                                />
                            </RadioButton>
                            <RadioButton value>
                                <BellOutlined
                                    style={{
                                        fontSize: 18,
                                        verticalAlign: 'middle'
                                    }}
                                    title={formatMessage({ id: 'tasks_page.new' })}
                                />
                            </RadioButton>
                        </RadioGroup>
                        <Table
                            bordered
                            columns={columns}
                            dataSource={subtasks}
                            pagination={pagination}
                            rowClassName={item => {
                                if (_.get(item, 'status') === 'NEW') return Styles.newTaskRow;
                                if (_.get(item, 'status') === 'COMPLETED') {
                                    return Styles.completedTaskRow;
                                }
                            }}
                            rowKey={() => v4()}
                            scroll={{ x: 'auto', y: '60vh' }}
                        />
                    </div>
                )}
            </div>
        );
    }
}
