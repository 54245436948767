import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image, Input, Popover, Select, Table, Tabs, Tooltip } from 'antd';
import { Layout } from 'commons';
import PaddedWrapper from 'forms/OrderForm/OrderFormTables/DiagnosticTable/components/PaddedWrapper';
import useFilterDataSource from 'forms/OrderForm/OrderFormTables/DiagnosticTable/components/VinDiagnostic/DiagnosticTableDrawer/useFilterDataSource';
import { get, isArray, pick, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, filterByPart } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import ManagePartModal from './components/ManagePartModal';
import Styles from './styles.m.css';

const { TabPane } = Tabs;

const normalizedPartProps = [
    // add partProblemsMask when BE fixes
    'partId',
    'partName',
    'partDuration',
    'partAction',
    'partLaborId',
    'partStoreGroupId',
    'partComplexOfLaborId',
    'type'
];

const { Option } = Select;

export const diagnosticsDirectoryColumns = [
    {
        title: <FormattedMessage id='duration' />,
        key: 'partDuration',
        dataIndex: 'partDuration'
    }
];

const mapStateToProps = state => ({
    user: state.auth
});

const DiagnosticsDirectoryPage = ({ intl, user }) => {
    const [loading, setLoading] = useState(false);
    const [partModalOpen, setPartModalOpen] = useState(false);
    const [helperDrawerOpen, sethelperDrawerOpen] = useState(false);

    const [part, setPart] = useState(null);
    const [labors, setLabors] = useState([]);

    const [allLinks, setAllLinks] = useState([]);
    const [diagnosticsDirectory, setDiagnosticsDirectory] = useState([]);
    const [diagnosticsTemplates, setDiagnosticsTemplates] = useState([]);
    const [filters, setFilters] = useState({});
    const handleFiltersChange = filterObj => {
        setFilters({ ...filters, ...filterObj });
    };

    const filteredDiagnosticsDirectory = useFilterDataSource(diagnosticsDirectory, filters);
    const fetchDiagnosticsParts = async () => {
        setLoading(true);
        const data = await fetchAPI('GET', 'directory_of_diagnostics', null, {}, { handleErrorInternally: true });

        setDiagnosticsDirectory(data);
        setLoading(false);
    };

    const fetchHelperLinks = async () => {
        const links = await fetchAPI(
            'GET',
            '/helps',
            { helpId: 'directories_and_settings_diagnostics-directory' },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        setAllLinks(links);
    };

    const fetchDiagnosticsTemplates = async () => {
        setLoading(true);
        const templates = await fetchAPI('GET', 'directory_of_templates', null, {}, { handleErrorInternally: true });

        setDiagnosticsTemplates(templates);
        setLoading(false);
    };

    const updateCustomParts = async parts => {
        const noramlizedParts = [parts].flat().map(el => pick(el, normalizedPartProps));
        await fetchAPI(
            'PUT',
            'custom_diagnostic_parts',
            null,
            {
                parts: [noramlizedParts].flat()
            },
            {
                handleErrorInternally: true
            }
        );
    };

    const addCustomParts = async parts => {
        const noramlizedParts = [parts].flat().map(el => pick(el, normalizedPartProps));
        await fetchAPI(
            'POST',
            'custom_diagnostic_parts',
            null,
            {
                parts: [noramlizedParts].flat()
            },
            {
                handleErrorInternally: true
            }
        );
    };

    const deleteCustomParts = async parts => {
        const partIds = [parts].flat().map(el => el.partId);
        await fetchAPI(
            'DELETE',
            'custom_diagnostic_parts',
            null,
            {
                partIds: [partIds].flat()
            },
            {
                handleErrorInternally: true
            }
        );
    };

    const handlePartModalOpen = row => {
        setPart(row);
        setPartModalOpen(true);
    };
    const handlePartModalClose = () => {
        setPart(null);
        setPartModalOpen(false);
    };

    const handlePartAction = async (partData = {}) => {
        if (partData.partId) {
            await updateCustomParts(partData);
        } else await addCustomParts(partData);

        await fetchDiagnosticsParts();
    };

    const handlePartDelete = async row => {
        await deleteCustomParts(row);
        await fetchDiagnosticsParts();
    };

    const handleTemplateDelete = async templateId => {
        await fetchAPI(
            'DELETE',
            'custom_templates',
            null,
            {
                templateId
            },
            {
                handleErrorInternally: true
            }
        );
        fetchDiagnosticsTemplates();
    };

    const handleTemplateEdit = async (templateId, templateName) => {
        await fetchAPI(
            'PUT',
            'custom_templates',
            null,
            {
                templateId,
                templateName
            },
            {
                handleErrorInternally: true
            }
        );
        fetchDiagnosticsTemplates();
    };

    useEffect(() => {
        const asyncCall = async () => {
            await fetchDiagnosticsParts();
            await fetchDiagnosticsTemplates();
            const { labors } = await fetchAPI('GET', 'labors', null, null, {
                handleErrorInternally: true
            });
            setLabors(labors);
        };

        asyncCall();
    }, []);

    const dgColumns = useCallback(
        () => [
            {
                title: <FormattedMessage id='order_form_table.diagnostic.node_title' />,
                key: 'partName',
                filtered: filters.partName && filters.partName.length,
                dataIndex: 'partName',
                filterDropdown: () => {
                    return (
                        <PaddedWrapper>
                            <Select
                                allowClear
                                filterOption={filterByPart}
                                mode='multiple'
                                onChange={selectValue => handleFiltersChange({ partName: selectValue })}
                                placeholder={intl.formatMessage({
                                    id: 'order_form_table.diagnostic.filters.position.placeholder'
                                })}
                                style={{ minWidth: 300, maxWidth: 500 }}
                                value={filters.partName}
                            >
                                {uniqBy(diagnosticsDirectory, 'partName').map(({ partName }) => (
                                    <Option key={partName} value={partName}>
                                        {partName}
                                    </Option>
                                ))}
                            </Select>
                        </PaddedWrapper>
                    );
                }
            },
            ...diagnosticsDirectoryColumns,
            {
                title: <FormattedMessage id='order_form_table.action' />,
                render: row => (
                    <React.Fragment>
                        <Tooltip title={intl.formatMessage({ id: 'diagnostics_directories.title.edit_part' })}>
                            <Button
                                disabled={!row.partBusinessId}
                                icon={<EditOutlined />}
                                onClick={() => {
                                    handlePartModalOpen(row);
                                }}
                                type='text'
                            />
                        </Tooltip>
                        <Tooltip title={intl.formatMessage({ id: 'diagnostics_directories.title.delete_part' })}>
                            <Button
                                disabled={!row.partBusinessId}
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    handlePartDelete(row);
                                }}
                                type='text'
                            />
                        </Tooltip>
                    </React.Fragment>
                )
            }
        ],
        [filters, diagnosticsDirectory]
    );

    const ownDgColumns = useCallback(
        () => [
            {
                title: <FormattedMessage id='general_settings.name' />,
                key: 'templateName',
                dataIndex: 'templateName'
            },
            {
                title: <FormattedMessage id='order_form_table.action' />,
                key: 'act',
                width: '15%',
                render: row => (
                    <React.Fragment>
                        <Popover
                            content={
                                <Input
                                    onChange={e => {
                                        row.templateName = e.target.value;
                                        setDiagnosticsTemplates(
                                            diagnosticsTemplates.map(el => {
                                                return el.templateId === row.templateId ? row : el;
                                            })
                                        );
                                    }}
                                    value={row.templateName}
                                />
                            }
                            disabled={!isGrantAccessed(user, grants.DIRECTORIES_DIAGNOSTICS, accesses.ROWO)}
                            onOpenChange={open => {
                                if (open) return;

                                handleTemplateEdit(row.templateId, row.templateName);
                            }}
                            trigger='click'
                        >
                            <Button
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_DIAGNOSTICS, accesses.ROWO)}
                                icon={<EditOutlined />}
                                type='text'
                            />
                        </Popover>

                        <Tooltip title={intl.formatMessage({ id: 'diagnostics_directories.title.delete_part' })}>
                            <Button
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_DIAGNOSTICS, accesses.ROWO)}
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    handleTemplateDelete(row.templateId);
                                }}
                                type='text'
                            />
                        </Tooltip>
                    </React.Fragment>
                )
            }
        ],
        [diagnosticsTemplates, intl, user]
    );

    const accessAddButton = isGrantAccessed(user, grants.DIRECTORIES_DIAGNOSTICS, accesses.ROWO);

    return (
        <Layout
            controls={
                <React.Fragment>
                    <Button
                        disabled={!accessAddButton}
                        onClick={() => {
                            handlePartModalOpen({});
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='diagnostics_directories.title.add_part' />
                    </Button>
                    <Button
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            sethelperDrawerOpen(true);
                            await fetchHelperLinks();
                        }}
                        style={{
                            fontSize: 22,
                            marginLeft: 8,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        type='text'
                    />
                </React.Fragment>
            }
            title={<FormattedMessage id='diagnostics_directories.page_title' />}
        >
            <Tabs tabPosition='top'>
                <TabPane key='1' tab={<FormattedMessage id='Рядки діагностик' />}>
                    <Table
                        bordered
                        columns={dgColumns()}
                        dataSource={filteredDiagnosticsDirectory}
                        loading={loading}
                        rowKey='diagnosticId'
                        size='small'
                    />
                </TabPane>
                <TabPane key='2' tab={<FormattedMessage id='Кастомні діагностики' />}>
                    <Table
                        bordered
                        columns={ownDgColumns()}
                        dataSource={diagnosticsTemplates}
                        loading={loading}
                        rowKey='templateId'
                        size='small'
                    />
                </TabPane>
            </Tabs>
            <ManagePartModal
                handleClose={handlePartModalClose}
                labors={labors}
                onOk={handlePartAction}
                open={!!part}
                part={part}
            />
            <Drawer
                onClose={() => {
                    sethelperDrawerOpen(false);
                }}
                open={helperDrawerOpen}
                title={<FormattedMessage id='navigation.helper' />}
                width={420}
            >
                <div>
                    {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                        <div className={Styles.linkBlock}>
                            <div className={Styles.ogTitle}>
                                {index + 1}. {ogTitle}
                            </div>
                            <div className={Styles.ogDesc}>{ogDescription}</div>
                            <div className={Styles.ogImg}>
                                <Image src={isArray(ogImage) ? get(ogImage, '[0].url', []) : get(ogImage, 'url', [])} />
                            </div>
                            <a href={ogUrl} rel='noreferrer' target='_blank'>
                                <Button
                                    style={{
                                        width: '100%'
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='repair_map_table.goto' />
                                </Button>
                            </a>
                        </div>
                    ))}
                </div>
            </Drawer>
        </Layout>
    );
};

export default injectIntl(connect(mapStateToProps)(DiagnosticsDirectoryPage));
