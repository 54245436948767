import { CheckOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, InputNumber, Switch } from 'antd';
import { Numeral } from 'commons';
import dayjs from 'dayjs';
import _, { debounce } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { PencilIcon } from 'theme';
import { grants, isGrantAccessed } from 'utils/grants';

export const recommendationsColumns = ({
    updateRecommendation,
    onEdit,
    onEndEdit,
    editableRcmId,
    user,
    showDetailColumn,
    disabled
}) => [
    {
        title: <FormattedMessage id='new-document-page.item.order' />,
        key: 'orderId',
        render: row => {
            return !row.orderId ? (
                <FormattedMessage id='long_dash' />
            ) : (
                <div>
                    <Link style={{ textDecoration: 'underline', fontWeight: 700 }} to={`${book.order}/${row.orderId}`}>
                        {row.orderNum || row.orderId}
                    </Link>

                    {(row.orderSuccessDatetime || row.orderMillage) && (
                        <div style={{ fontSize: 12 }}>
                            <span>
                                {row.orderSuccessDatetime ? (
                                    dayjs(row.orderSuccessDatetime).format('DD.MM.YY')
                                ) : (
                                    <FormattedMessage id='long_dash' />
                                )}
                            </span>
                            <span style={{ marginLeft: 8 }}>
                                {row.orderMillage ? (
                                    <React.Fragment>
                                        {row.orderMillage} <FormattedMessage id='online_service_book_page.km' />
                                    </React.Fragment>
                                ) : (
                                    <FormattedMessage id='long_dash' />
                                )}
                            </span>
                        </div>
                    )}
                </div>
            );
        }
    },
    {
        title: <FormattedMessage id='date' />,
        key: 'recommendationToDate',
        align: 'center',
        render: row => {
            const date = row.recommendationToDate
                ? dayjs.isDayjs(row.recommendationToDate)
                    ? row.recommendationToDate
                    : dayjs(row.recommendationToDate)
                : undefined;

            if (editableRcmId === row.recommendationId) {
                return (
                    <DatePicker
                        allowClear={false}
                        disabled={disabled}
                        format='DD MMM YYYY'
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={recommendationToDate => {
                            updateRecommendation({ ...row, recommendationToDate });
                        }}
                        style={{ width: '100%' }}
                        value={date}
                    />
                );
            }

            return date ? date.format('DD.MM.YY') : <FormattedMessage id='long_dash' />;
        }
    },
    {
        title: <FormattedMessage id='orders.odometer' />,
        key: 'recommendationToOdometerValue',
        align: 'center',
        render: row => {
            if (editableRcmId === row.recommendationId) {
                const upd = debounce(
                    recommendationToOdometerValue => updateRecommendation({ ...row, recommendationToOdometerValue }),
                    1000
                );

                return (
                    <InputNumber
                        defaultValue={row.recommendationToOdometerValue}
                        disabled={disabled}
                        onChange={val => upd(val)}
                    />
                );
            }

            return (
                (
                    <div>
                        <Numeral mask='0,0'>{Number(row.recommendationToOdometerValue)}</Numeral>
                    </div>
                ) || <FormattedMessage id='long_dash' />
            );
        }
    },
    {
        title: <FormattedMessage id='services_table.labor' />,
        key: 'laborName',
        dataIndex: 'laborName',
        render: (data, row) => {
            const laborId = `${String(row.laborId).substring(0, 4)}-${String(row.laborId).substring(4)}`;

            return (
                <div>
                    <div>{data || <FormattedMessage id='long_dash' />}</div>

                    {user && isGrantAccessed(user, grants.DIRECTORIES_JOBS) ? (
                        <Link
                            style={{ textDecoration: 'underline' }}
                            to={{
                                pathname: book.laborsPage,
                                state: {
                                    laborId: laborId.replace('-', '')
                                }
                            }}
                        >
                            <div style={{ fontSize: 12 }}>{laborId}</div>
                        </Link>
                    ) : (
                        <div style={{ fontSize: 12 }}>{laborId}</div>
                    )}
                </div>
            );
        }
    },
    {
        title: <FormattedMessage id='order_form_table.detail_name' />,
        key: 'laborName',
        dataIndex: 'laborName',
        render: (data, row) => {
            if (editableRcmId === row.recommendationId) {
                const upd = debounce(laborName => updateRecommendation({ ...row, laborName }), 1000);

                return (
                    <Input
                        defaultValue={data}
                        disabled={disabled}
                        onChange={({ target }) => {
                            upd(target.value);
                        }}
                        style={{ minWidth: 160 }}
                    />
                );
            }

            return data || <FormattedMessage id='long_dash' />;
        }
    },
    {
        title: <FormattedMessage id='comment' />,
        key: 'recommendationComment',
        render: row => {
            if (editableRcmId === row.recommendationId) {
                const upd = debounce(
                    recommendationComment => updateRecommendation({ ...row, recommendationComment }),
                    1000
                );

                return (
                    <Input
                        defaultValue={row.recommendationComment}
                        disabled={disabled}
                        onChange={({ target }) => {
                            upd(target.value);
                        }}
                        style={{ minWidth: 160 }}
                    />
                );
            }

            return row.recommendationComment || <FormattedMessage id='long_dash' />;
        }
    },

    {
        title: <FormattedMessage id='status' />,
        key: 'recommendationStatus',
        align: 'center',
        render: row => {
            return (
                <Switch
                    checked={row.recommendationStatus}
                    disabled={!_.isFunction(updateRecommendation) || disabled}
                    onChange={() => {
                        updateRecommendation({
                            ...row,
                            recommendationStatus: !row.recommendationStatus
                        });
                    }}
                />
            );
        }
    },
    ...(onEdit
        ? [
              {
                  title: <FormattedMessage id='edit' />,
                  key: 'edit',
                  align: 'center',
                  render: row => {
                      return (
                          <Button
                              data-qa='btn_add_edit_button_service_product_modal_services_table_order_page'
                              disabled={disabled}
                              icon={editableRcmId === row.recommendationId ? <CheckOutlined /> : <PencilIcon />}
                              onClick={() => (editableRcmId === row.recommendationId ? onEndEdit(row) : onEdit(row))}
                          />
                      );
                  }
              }
          ]
        : [])
];
