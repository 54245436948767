import { GlobalOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Spin, Tabs } from 'antd';
import { Layout } from 'commons';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { fetchAPI, getSessionId } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import {
    AdditionalSettings,
    FeaturesSettings,
    GeneralSettings,
    MediaSettings,
    ServicesSettings,
    SpecializationSettings
} from './components';
import './styles.less';

const { TabPane } = Tabs;
const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

const formatCB24data = ({
    address,
    business_partners_networks: businessPartnersNetworks,
    business_vehicle_makes: businessVehicleMakes,
    count_stations: countStations,
    description,
    email,
    facebook_link: facebookLink,
    facebook_widget: facebookWidget,
    features,
    ga_id: googleAnalytics,
    google_link: googleLink,
    google_widget: googleWidget,
    is_car_wash: isCarWash,
    is_garage: isGarage,
    is_tire_station: isTireStation,
    language_id: language,
    latitude: mapLatitude,
    longitude: mapLongitude,
    map_zoom: mapZoom,
    meta_description: metaDescription,
    minimal_time_order: minimalTimeOrder,
    name,
    nonWorkingDays,
    owner_email: ownerEmail,
    owner_name: ownerName,
    owner_phone: ownerPhone,
    phones,
    responsible_email: responsibleEmail,
    responsible_name: responsibleName,
    responsible_phone: responsiblePhone,
    schedule,
    site,
    synonym,
    theme,
    time_to_end_working_day: timeToEndWorkingDay,
    title,
    twitter_link: twitterLink,
    vk_link: vkLink,
    vk_widget: vkWidget,
    youtube
}) => ({
    address,
    businessPartnersNetworks,
    businessVehicleMakes,
    countStations,
    description,
    email,
    facebookLink,
    facebookWidget,
    features,
    googleAnalytics,
    googleLink,
    googleWidget,
    isCarWash,
    isGarage,
    isTireStation,
    language,
    mapLatitude,
    mapLongitude,
    mapZoom,
    metaDescription,
    minimalTimeOrder,
    name,
    nonWorkingDays,
    ownerEmail,
    ownerName,
    ownerPhone,
    phones,
    responsibleEmail,
    responsibleName,
    responsiblePhone,
    schedule,
    sessionId: getSessionId(),
    site,
    synonym,
    theme,
    timeToEndWorkingDay,
    title,
    twitterLink,
    vkLink,
    vkWidget,
    youtube
});

const WebSettingsPage = injectIntl(({ user, intl: { formatMessage } }) => {
    const sessionId = getSessionId();
    const [cb24data, setCB24data] = useState();
    const [activeKey, setActiveKey] = useState();
    const isDisabledCRUD = !isGrantAccessed(user, grants.SETTINGS_WEB_SETTINGS, accesses.ROWO);

    const generalSettingsRef = useRef();

    const fetchCB24data = async () => {
        setCB24data(null);
        const cb24data = await fetchAPI(
            'GET',
            'rest/v1/business',
            {
                sessionId
            },
            null,
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        setCB24data(cb24data);
    };

    const saveCB24data = async values => {
        const payload = {
            ...formatCB24data(cb24data),
            ...values
        };

        const features = {};

        payload.features.forEach(({ type, lock, name, description }) => {
            features[type] = {
                value: Boolean(lock),
                label: name,
                description
            };
        });

        payload.features = features;

        try {
            await fetchAPI('PUT', 'rest/v1/business', null, payload, {
                handleErrorInternally: true,
                url: __OLD_APP_API_URL__
            });
            fetchCB24data();
        } catch (e) {
            fetchCB24data();
        }
    };

    useEffect(() => {
        fetchCB24data();
    }, []);

    return (
        <Layout
            className='settings_page'
            controls={
                <a href={book.oldApp.settings}>
                    <Button icon={<GlobalOutlined />} size='large' />
                </a>
            }
            description={<FormattedMessage id='navigation.web_settings' />}
            title={<FormattedMessage id='navigation.settings' />}
        >
            {cb24data ? (
                <Tabs activeKey={activeKey} onChange={setActiveKey} tabPosition='top'>
                    <TabPane
                        key='general'
                        className='settings_form_tab'
                        tab={<FormattedMessage id='settings_page.general_information' />}
                    >
                        <GeneralSettings
                            ref={generalSettingsRef}
                            cb24data={cb24data}
                            fetchCB24data={fetchCB24data}
                            formatMessage={formatMessage}
                            isDisabledCRUD={isDisabledCRUD}
                            saveData={saveCB24data}
                            sessionId={sessionId}
                        />
                    </TabPane>
                    <TabPane key='features' tab={<FormattedMessage id='settings_page.specifics' />}>
                        <FeaturesSettings
                            cb24data={cb24data}
                            fetchData={fetchCB24data}
                            formatMessage={formatMessage}
                            isDisabledCRUD={isDisabledCRUD}
                            saveData={saveCB24data}
                            sessionId={sessionId}
                        />
                    </TabPane>
                    <TabPane key='services' tab={<FormattedMessage id='settings_page.services' />}>
                        <ServicesSettings
                            cb24data={cb24data}
                            fetchData={fetchCB24data}
                            formatMessage={formatMessage}
                            isDisabledCRUD={isDisabledCRUD}
                            saveData={saveCB24data}
                            sessionId={sessionId}
                        />
                    </TabPane>
                    <TabPane key='media' tab={<FormattedMessage id='settings_page.media' />}>
                        <MediaSettings
                            cb24data={cb24data}
                            fetchData={fetchCB24data}
                            formatMessage={formatMessage}
                            isDisabledCRUD={isDisabledCRUD}
                            saveData={saveCB24data}
                            sessionId={sessionId}
                        />
                    </TabPane>
                    <TabPane key='specialization' tab={<FormattedMessage id='settings_page.specialization' />}>
                        <SpecializationSettings
                            cb24data={cb24data}
                            fetchData={fetchCB24data}
                            formatMessage={formatMessage}
                            isDisabledCRUD={isDisabledCRUD}
                            saveData={saveCB24data}
                            sessionId={sessionId}
                        />
                    </TabPane>
                    <TabPane key='additional' tab={<FormattedMessage id='settings_page.additional' />}>
                        <AdditionalSettings
                            cb24data={cb24data}
                            fetchData={fetchCB24data}
                            formatMessage={formatMessage}
                            isDisabledCRUD={isDisabledCRUD}
                            saveData={saveCB24data}
                            sessionId={sessionId}
                        />
                    </TabPane>
                </Tabs>
            ) : (
                <Spin indicator={spinIcon} />
            )}
        </Layout>
    );
});

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WebSettingsPage);
