/* eslint-disable no-template-curly-in-string */
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, notification, Upload } from 'antd';
import imageCompression from 'browser-image-compression';
import _ from 'lodash';
import React, { forwardRef, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchAPI, getToken, phoneNumberParser, toBase64 } from 'utils';
import './styles.less';

export const GeneralSettings = forwardRef(
    ({ cb24data, saveData, fetchCB24data, sessionId, formatMessage, user, isDisabledCRUD }, ref) => {
        const [generalSettingsForm] = Form.useForm();
        const [logo, setLogo] = useState([]);

        useEffect(() => {
            generalSettingsForm.setFieldsValue({
                name: _.get(cb24data, 'name'),
                ownerName: _.get(cb24data, 'owner_name'),
                phones: _.get(cb24data, 'phones[0]'),
                email: _.get(cb24data, 'email')
            });
            setLogo(_.get(cb24data, 'logo'));
        }, [cb24data, generalSettingsForm, user.country]);

        const deleteLogo = async () => {
            await fetchAPI('DELETE', 'rest/v1/business/logo', null, { sessionId }, { url: __OLD_APP_API_URL__ });

            fetchCB24data();
        };

        const confirmLogo = async file => {
            const base64 = await toBase64(file);

            await fetchAPI(
                'PUT',
                'rest/v1/business/logo-base64',
                null,
                { sessionId, base64 },
                { url: __OLD_APP_API_URL__ }
            );

            fetchCB24data();
        };

        const updateLogo = async file => {
            if (_.get(logo, 'path')) {
                deleteLogo();
            }
            const formData = new FormData();
            const compressedFile = await imageCompression(file, {
                maxWidthOrHeight: 240,
                maxSizeMB: 2
            });
            formData.append('file', compressedFile);

            await fetch(`${__OLD_APP_API_URL__}/rest/v1/business/logo`, {
                method: 'POST',
                headers: {
                    Authorization: getToken()
                },
                body: formData
            });

            confirmLogo(compressedFile);
        };

        const onFinish = values => {
            console.log(values);
            saveData({
                ...values,
                phones: [phoneNumberParser(values.phones, user.country)]
            });
        };

        return (
            <div className='general-settings'>
                <Form
                    ref={ref}
                    className='general-settings-form'
                    disabled={isDisabledCRUD}
                    form={generalSettingsForm}
                    labelCol={{
                        span: 8
                    }}
                    name='general-settings'
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={<FormattedMessage id='general_settings.business_name' />}
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'general_settings.enter_your_name'
                                })
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id='general_settings.manager' />} name='ownerName'>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id='general_settings.phone_number' />}
                        name='phones'
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'general_settings.enter_your_number'
                                })
                            }
                            // {
                            //     pattern: /(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})/,
                            //     transform: value => String(value),
                            //     message: formatMessage({
                            //         id: 'add_client_form.invalid_phone_format'
                            //     })
                            // }
                        ]}
                    >
                        <InputNumber
                            controls={false}
                            // formatter={value => phoneNumberFormatter(value, user.country)}
                            onKeyDown={e => {
                                const validKeys = [
                                    '0',
                                    '1',
                                    '2',
                                    '3',
                                    '4',
                                    '5',
                                    '6',
                                    '7',
                                    '8',
                                    '9',
                                    '.',
                                    ',',
                                    'Tab',
                                    'Enter',
                                    'Backspace',
                                    'Delete'
                                ];

                                const actions = ['Tab', 'Enter', 'Backspace', 'Delete'];

                                const value = String(e.target.value).replace(/[+() ]/g, '');
                                if ((validKeys.includes(e.key) && value.length < 14) || actions.includes(e.key)) {
                                    return e;
                                }

                                return e.preventDefault();
                            }}
                            // parser={phoneNumberParser}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id='general_settings.e_mail' />}
                        name='email'
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 14,
                            span: 12
                        }}
                    >
                        <Button htmlType='submit' type='primary'>
                            <FormattedMessage id='general_settings.submit' />
                        </Button>
                    </Form.Item>
                </Form>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Upload
                        action={updateLogo}
                        beforeUpload={file => {
                            const isPNG = file.type === 'image/png';
                            const isJPG = file.type === 'image/jpeg';
                            if (!isPNG && !isJPG) {
                                notification.error({
                                    message: `${file.name} ${formatMessage({
                                        id: 'photo_modal.is_not_an_image'
                                    })}!`
                                });
                            }

                            return isPNG || isJPG;
                        }}
                        className='avatar-uploader'
                        fileList={
                            _.get(logo, 'path')
                                ? [
                                      {
                                          uid: '-1',
                                          name: 'logo.png',
                                          status: 'done',
                                          url: `//s1.car-book.com${_.get(logo, 'path')}`
                                      }
                                  ]
                                : []
                        }
                        listType='picture-card'
                        // showUploadList={false}
                        name='avatar'
                        onRemove={deleteLogo}
                    >
                        {!_.get(logo, 'path') && (
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>
                                    <FormattedMessage id='general_settings.upload_logo' />
                                </div>
                            </div>
                        )}
                    </Upload>
                    <span
                        style={{
                            opacity: 0.5,
                            fontSize: 16
                        }}
                    >
                        <FormattedMessage id='general_settings.recomendation_size' />
                    </span>
                </div>
            </div>
        );
    }
);
