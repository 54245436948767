import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, InputNumber, Popconfirm, Select, Table, Tooltip } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, getCurrency, numeralFormatter, numeralParser } from 'utils';
import './styles.less';

const { Option } = Select;

@injectIntl
export class SpecializationSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            vehicleGroups: [],
            vehicleMakes: [],
            serveVehicleGroups: [],
            serveVehicleMakes: [],
            specializationVehicleMakes: [],
            list: [],
            loading: true
        };

        const { isDisabledCRUD } = this.props;

        this.columns = () => [
            {
                title: <FormattedMessage id='web_settings.price' />,
                width: '20%',
                key: 'price',
                render: ({ price, id }) => {
                    return (
                        <InputNumber
                            addonAfter={getCurrency()}
                            disabled={isDisabledCRUD}
                            onBlur={event => {
                                this.updateList(id, { price: event.target.value });
                            }}
                            value={price}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='web_settings.brand' />,
                key: 'vehicles',
                render: ({ vehicles, id }) => {
                    return (
                        <Select
                            disabled={isDisabledCRUD}
                            mode='multiple'
                            onChange={value => {
                                this.updateList(id, {
                                    vehicles: String(value)
                                });
                            }}
                            optionFilterProp='children'
                            optionLabelProp='children'
                            showSearch
                            value={vehicles ? String(vehicles).split(',') : undefined}
                        >
                            {this.state.vehicleMakes.map(({ value, label }) => (
                                <Option key={value} value={String(value)}>
                                    {label}
                                </Option>
                            ))}
                        </Select>
                    );
                }
            },
            {
                title: (
                    <Button
                        icon={
                            <Tooltip title={<FormattedMessage id='add' />}>
                                <PlusOutlined />
                            </Tooltip>
                        }
                        onClick={this.addListItem}
                    />
                ),
                width: '5%',
                align: 'center',
                key: 'delete',
                render: ({ id }) => {
                    return (
                        <Popconfirm
                            disabled={isDisabledCRUD}
                            onConfirm={() => this.deleteListItem(id)}
                            title={<FormattedMessage id='general_settings_delete_question' />}
                        >
                            <Tooltip title={<FormattedMessage id='delete' />}>
                                <Button disabled={isDisabledCRUD} icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Popconfirm>
                    );
                }
            }
        ];
    }

    componentDidMount() {
        this.fetchSpecializations();
        this.fetchCost();
    }

    fetchSpecializations = async () => {
        const { sessionId } = this.props;
        const {
            all_vehicle_groups: vehicleGroups,
            all_vehicle_makes: vehicleMakes,
            serve_vehicle_groups: serveVehicleGroups,
            serve_vehicle_makes: serveVehicleMakes,
            specialization_vehicle_makes: specializationVehicleMakes
        } = await fetchAPI(
            'GET',
            'rest/v1/specialization',
            {
                sessionId
            },
            null,
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        this.setState({
            vehicleGroups,
            vehicleMakes,
            serveVehicleGroups: serveVehicleGroups ? String(serveVehicleGroups).split(',') : undefined,
            serveVehicleMakes: serveVehicleMakes ? String(serveVehicleMakes).split(',') : undefined,
            specializationVehicleMakes: specializationVehicleMakes
                ? String(specializationVehicleMakes).split(',')
                : undefined
        });
    };

    putResults = async field => {
        const { sessionId } = this.props;
        await fetchAPI(
            'PUT',
            'rest/v1/specialization',
            null,
            {
                sessionId,
                ...field
            },
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        this.fetchSpecializations();
    };

    fetchCost = async () => {
        const { sessionId } = this.props;
        const { generalCost, list } = await fetchAPI(
            'GET',
            'rest/v1/labor-time-costs',
            {
                sessionId
            },
            null,
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        this.setState({
            generalCost,
            list,
            loading: false
        });
    };

    saveCost = async () => {
        const { sessionId } = this.props;
        const { generalCost: cost } = this.state;

        await fetchAPI(
            'PUT',
            'rest/v1/labor-time-costs/save-general',
            null,
            {
                cost,
                sessionId
            },
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        this.fetchCost();
    };

    updateList = async (id, field) => {
        const { sessionId } = this.props;

        await fetchAPI(
            'PUT',
            `rest/v1/labor-time-costs/${id}`,
            null,
            {
                sessionId,
                ...field
            },
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        this.fetchCost();
    };

    deleteListItem = async id => {
        this.setState({ loading: true });
        const { sessionId } = this.props;

        await fetchAPI(
            'DELETE',
            `rest/v1/labor-time-costs/${id}`,
            null,
            {
                sessionId
            },
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        this.fetchCost();
    };

    addListItem = async () => {
        this.setState({ loading: true });
        const { sessionId } = this.props;

        await fetchAPI(
            'POST',
            'rest/v1/labor-time-costs',
            null,
            {
                sessionId
            },
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        this.fetchCost();
    };

    render() {
        const {
            isDisabledCRUD,
            intl: { formatMessage }
        } = this.props;
        const {
            vehicleMakes,
            vehicleGroups,
            serveVehicleMakes,
            serveVehicleGroups,
            specializationVehicleMakes,
            generalCost,
            list,
            loading
        } = this.state;

        return (
            <div
                style={{
                    maxWidth: '70%'
                }}
            >
                <div className='selectLine'>
                    <span>
                        <FormattedMessage id='general_settings.official' />
                    </span>
                    <Select disabled placeholder={formatMessage({ id: 'web_settings.officials' })} />
                </div>
                <div className='selectLine'>
                    <span>
                        <FormattedMessage id='general_settings.car_brand' />
                    </span>
                    <Select
                        disabled={isDisabledCRUD}
                        mode='multiple'
                        onChange={value => {
                            this.putResults({ specialization_vehicle_makes: String(value) });
                        }}
                        optionFilterProp='children'
                        optionLabelProp='children'
                        showSearch
                        value={specializationVehicleMakes}
                    >
                        {vehicleMakes.map(({ value, label }) => (
                            <Option key={value} value={String(value)}>
                                {label}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className='selectLine'>
                    <span>
                        <FormattedMessage id='general_settings.car_groups' />
                    </span>
                    <Select
                        disabled={isDisabledCRUD}
                        mode='multiple'
                        onChange={value => {
                            this.putResults({ serve_vehicle_groups: String(value) });
                        }}
                        optionFilterProp='children'
                        optionLabelProp='children'
                        showSearch
                        value={serveVehicleGroups}
                    >
                        {vehicleGroups.map(({ value, label }) => (
                            <Option key={value} value={String(value)}>
                                {label}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className='selectLine'>
                    <span>
                        <FormattedMessage id='general_settings.or_list' />
                    </span>
                    <Select
                        disabled={isDisabledCRUD}
                        mode='multiple'
                        onChange={value => {
                            this.putResults({ serve_vehicle_makes: String(value) });
                        }}
                        optionFilterProp='children'
                        optionLabelProp='children'
                        showSearch
                        value={serveVehicleMakes}
                    >
                        {vehicleMakes.map(({ value, label }) => (
                            <Option key={value} value={String(value)}>
                                {label}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className='selectLine'>
                    <span
                        style={{
                            marginRight: 8
                        }}
                    >
                        <FormattedMessage id='general_settings.whole_price' />
                    </span>
                    <InputNumber
                        disabled={isDisabledCRUD}
                        formatter={value => numeralFormatter(value)}
                        onBlur={this.saveCost}
                        onChange={generalCost => this.setState({ generalCost })}
                        parser={value => numeralParser(value)}
                        value={generalCost}
                    />
                </div>

                <Table bordered columns={this.columns()} dataSource={list} loading={loading} rowKey='id'></Table>
            </div>
        );
    }
}
