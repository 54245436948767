import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Select, Switch, Table, Tooltip, notification } from 'antd';
import { updateUser } from 'core/auth/duck';
import { onChangeProfileForm, submitProfileFormOther } from 'core/forms/profileForm/duck';
import { get } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.less';

const { Option } = Select;
const { confirm } = Modal;

const mapDispatchToProps = {
    onChangeProfileForm,
    submitProfileFormOther,
    updateUser
};

const mapStateToProps = state => {
    return {
        user: state.auth,
        profileUpdating: state.ui.profileUpdating
    };
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class PrintTabSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            printSettings: [],
            dateOfNextVisit: 360,
            sum: undefined,
            loading: false
        };

        const { isDisabledCRUD } = this.props;

        this.columns = () => [
            {
                title: <FormattedMessage id='settings_page.print_brand_code_product' />,
                key: 'reportName',
                dataIndex: 'reportName',
                width: '15%',
                render: data => {
                    return (
                        <div>
                            <FormattedMessage id={`settings_page.${data}`} />
                        </div>
                    );
                }
            },

            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'enableBrand',
                dataIndex: 'enableBrand',
                width: '10%',
                align: 'center',
                render: (data, elem) => {
                    return !['businessOrderReport', 'actOfAcceptanceReport'].includes(elem.reportName) ? (
                        <Switch
                            checked={elem.enableBrand}
                            disabled={elem.reportName === 'businessOrderReport' || isDisabledCRUD}
                            onChange={async () => {
                                if (elem.reportName == 'orderAgreement' && elem.enableBrand) {
                                    confirm({
                                        title: this.props.intl.formatMessage({
                                            id: 'settings_page.noficiation_aggreement'
                                        }),
                                        content: (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around'
                                                }}
                                            >
                                                <div>
                                                    <Button
                                                        onClick={() => {
                                                            Modal.destroyAll();
                                                        }}
                                                    >
                                                        {this.props.intl.formatMessage({
                                                            id: 'cancel'
                                                        })}
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        onClick={async () => {
                                                            await fetchAPI('PUT', 'business/report/settings', null, {
                                                                enableBrand: !elem.enableBrand,
                                                                reportTypeId: elem.reportTypeId,
                                                                priceDecimalPlaces: elem.priceDecimalPlaces,
                                                                sumDecimalPlaces: elem.sumDecimalPlaces,
                                                                quantityDecimalPlaces: elem.quantityDecimalPlaces
                                                            });
                                                            await this.fetchPrintSettings();
                                                            Modal.destroyAll();
                                                        }}
                                                        type='primary'
                                                    >
                                                        {this.props.intl.formatMessage({
                                                            id: 'general_settings.submit'
                                                        })}
                                                    </Button>
                                                </div>
                                            </div>
                                        ),
                                        footer: null
                                    });
                                } else {
                                    await fetchAPI('PUT', 'business/report/settings', null, {
                                        enableBrand: !elem.enableBrand,
                                        reportTypeId: elem.reportTypeId,
                                        priceDecimalPlaces: elem.priceDecimalPlaces,
                                        sumDecimalPlaces: elem.sumDecimalPlaces,
                                        quantityDecimalPlaces: elem.quantityDecimalPlaces
                                    });
                                    this.fetchPrintSettings();
                                }
                            }}
                        />
                    ) : undefined;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.product_code' />,
                key: 'enableCode',
                dataIndex: 'enableCode',
                width: '10%',
                align: 'center',
                render: (data, elem) => {
                    return !['businessOrderReport', 'actOfAcceptanceReport'].includes(elem.reportName) ? (
                        <Switch
                            checked={elem.enableCode}
                            disabled={elem.reportName === 'businessOrderReport' || isDisabledCRUD}
                            onChange={async () => {
                                await fetchAPI('PUT', 'business/report/settings', null, {
                                    enableCode: !elem.enableCode,
                                    reportTypeId: elem.reportTypeId,
                                    priceDecimalPlaces: elem.priceDecimalPlaces,
                                    sumDecimalPlaces: elem.sumDecimalPlaces,
                                    quantityDecimalPlaces: elem.quantityDecimalPlaces
                                });
                                this.fetchPrintSettings();
                            }}
                        />
                    ) : undefined;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.discount' />,
                key: 'discountRow',
                dataIndex: 'discountRow',
                width: '10%',
                align: 'center',
                render: (data, elem) => {
                    return elem.reportName !== 'businessOrderReport' &&
                        elem.reportName !== 'orderAgreement' &&
                        elem.reportName !== 'documentReport' &&
                        elem.reportName !== 'documentAcceptanceReport' &&
                        elem.reportName !== 'actOfAcceptanceReport' ? (
                        <Switch
                            checked={elem.discountRow}
                            disabled={elem.reportName === 'businessOrderReport' || isDisabledCRUD}
                            onChange={async () => {
                                await fetchAPI('PUT', 'business/report/settings', null, {
                                    discountRow: !elem.discountRow,
                                    reportTypeId: elem.reportTypeId,
                                    priceDecimalPlaces: elem.priceDecimalPlaces,
                                    sumDecimalPlaces: elem.sumDecimalPlaces,
                                    quantityDecimalPlaces: elem.quantityDecimalPlaces
                                });
                                this.fetchPrintSettings();
                            }}
                        />
                    ) : undefined;
                }
            },
            {
                title: <FormattedMessage id='comment' />,
                key: 'comment',
                dataIndex: 'clientComment',
                width: '10%',
                align: 'center',
                render: (data, elem) => {
                    return elem.reportName !== 'businessOrderReport' &&
                        elem.reportName !== 'orderAgreement' &&
                        elem.reportName !== 'documentReport' &&
                        elem.reportName !== 'documentAcceptanceReport' &&
                        elem.reportName !== 'actOfAcceptanceReport' &&
                        elem.reportName !== 'invoiceReport' &&
                        elem.reportName !== 'salesInvoiceReport' ? (
                        <Switch
                            checked={elem.clientComment}
                            disabled={elem.reportName === 'businessOrderReport'}
                            onChange={async () => {
                                await fetchAPI('PUT', 'business/report/settings', null, {
                                    clientComment: !elem.clientComment,
                                    discountRow: elem.discountRow,
                                    reportTypeId: elem.reportTypeId,
                                    priceDecimalPlaces: elem.priceDecimalPlaces,
                                    sumDecimalPlaces: elem.sumDecimalPlaces,
                                    quantityDecimalPlaces: elem.quantityDecimalPlaces
                                });
                                this.fetchPrintSettings();
                            }}
                        />
                    ) : undefined;
                }
            },
            {
                title: <FormattedMessage id='settings_page.date_print' />,
                key: 'enableDate',
                dataIndex: 'enableDate',
                width: '5%',
                align: 'center',
                render: (data, elem) => {
                    return elem.reportName !== 'orderAgreement' &&
                        elem.reportName !== 'documentReport' &&
                        elem.reportName !== 'documentAcceptanceReport' ? (
                        <Switch
                            checked={elem.enableDate}
                            disabled={isDisabledCRUD}
                            onChange={async () => {
                                await fetchAPI('PUT', 'business/report/settings', undefined, {
                                    enableDate: !elem.enableDate,
                                    reportTypeId: elem.reportTypeId,
                                    priceDecimalPlaces: elem.priceDecimalPlaces,
                                    sumDecimalPlaces: elem.sumDecimalPlaces,
                                    quantityDecimalPlaces: elem.quantityDecimalPlaces
                                });
                                this.fetchPrintSettings();
                            }}
                        />
                    ) : undefined;
                }
            },
            {
                title: <FormattedMessage id='responsible' />,
                key: 'enableResponsible',
                dataIndex: 'enableResponsible',
                width: '5%',
                align: 'center',
                render: (data, elem) => {
                    return elem.reportName !== 'orderAgreement' &&
                        elem.reportName !== 'documentReport' &&
                        elem.reportName !== 'documentAcceptanceReport' &&
                        elem.reportName !== 'actOfAcceptanceReport' &&
                        elem.reportName !== 'businessOrderReport' ? (
                        <Switch
                            checked={elem.enableResponsible}
                            disabled={isDisabledCRUD}
                            onChange={async () => {
                                await fetchAPI('PUT', 'business/report/settings', undefined, {
                                    enableResponsible: !elem.enableResponsible,
                                    enableDate: elem.enableDate,
                                    reportTypeId: elem.reportTypeId,
                                    priceDecimalPlaces: elem.priceDecimalPlaces,
                                    sumDecimalPlaces: elem.sumDecimalPlaces,
                                    quantityDecimalPlaces: elem.quantityDecimalPlaces
                                });
                                this.fetchPrintSettings();
                            }}
                        />
                    ) : undefined;
                }
            },
            {
                title: <FormattedMessage id='settings_page.characters_after_coma.count' />,
                key: 'quantityDecimalPlaces',
                dataIndex: 'quantityDecimalPlaces',
                width: '7%',
                render: (data, elem) => {
                    return elem.reportName !== 'orderAgreement' && elem.reportName !== 'actOfAcceptanceReport' ? (
                        <Select
                            disabled={isDisabledCRUD}
                            onSelect={async value => {
                                await fetchAPI('PUT', 'business/report/settings', undefined, {
                                    quantityDecimalPlaces: Number(value),
                                    priceDecimalPlaces: elem.priceDecimalPlaces,
                                    sumDecimalPlaces: elem.sumDecimalPlaces,
                                    reportTypeId: elem.reportTypeId
                                });
                                this.fetchPrintSettings();
                            }}
                            value={data}
                        >
                            <Option key='1' value={1}>
                                1
                            </Option>
                            <Option key='2' value={2}>
                                2
                            </Option>
                            <Option key='3' value={3}>
                                3
                            </Option>
                            <Option key='4' value={4}>
                                4
                            </Option>
                        </Select>
                    ) : undefined;
                }
            },
            {
                title: <FormattedMessage id='settings_page.characters_after_coma.price' />,
                key: 'priceDecimalPlaces',
                dataIndex: 'priceDecimalPlaces',
                width: '7%',
                render: (data, elem) => {
                    return elem.reportName !== 'orderAgreement' && elem.reportName !== 'actOfAcceptanceReport' ? (
                        <Select
                            disabled={isDisabledCRUD}
                            onSelect={async value => {
                                await fetchAPI('PUT', 'business/report/settings', undefined, {
                                    priceDecimalPlaces: value,
                                    sumDecimalPlaces: elem.sumDecimalPlaces,
                                    quantityDecimalPlaces: elem.quantityDecimalPlaces,
                                    reportTypeId: elem.reportTypeId
                                });
                                this.fetchPrintSettings();
                            }}
                            value={data}
                        >
                            <Option key='1' value={1}>
                                1
                            </Option>
                            <Option key='2' value={2}>
                                2
                            </Option>
                            <Option key='3' value={3}>
                                3
                            </Option>
                            <Option key='4' value={4}>
                                4
                            </Option>
                        </Select>
                    ) : undefined;
                }
            },
            {
                title: <FormattedMessage id='settings_page.characters_after_coma.sum' />,
                key: 'sumDecimalPlaces',
                dataIndex: 'sumDecimalPlaces',
                width: '7%',
                render: (data, elem) => {
                    return elem.reportName !== 'orderAgreement' && elem.reportName !== 'actOfAcceptanceReport' ? (
                        <Select
                            disabled={isDisabledCRUD}
                            onSelect={async value => {
                                await fetchAPI('PUT', 'business/report/settings', undefined, {
                                    sumDecimalPlaces: value,
                                    quantityDecimalPlaces: elem.quantityDecimalPlaces,
                                    priceDecimalPlaces: elem.priceDecimalPlaces,
                                    reportTypeId: elem.reportTypeId
                                });
                                this.fetchPrintSettings();
                            }}
                            value={data}
                        >
                            <Option key='1' value={1}>
                                1
                            </Option>
                            <Option key='2' value={2}>
                                2
                            </Option>
                            <Option key='3' value={3}>
                                3
                            </Option>
                            <Option key='4' value={4}>
                                4
                            </Option>
                        </Select>
                    ) : undefined;
                }
            },
            {
                title: <FormattedMessage id='OTHER' />,
                key: 'expressDiagnostic',
                dataIndex: 'expressDiagnostic',
                align: 'center',
                width: '7%',
                render: (data, elem) => {
                    return (
                        <React.Fragment>
                            {(elem.reportName === 'actOfAcceptanceReport' ||
                                elem.reportName === 'calculationReport' ||
                                elem.reportName === 'invoiceReport' ||
                                elem.reportName === 'clientOrderReport' ||
                                elem.reportName === 'warrantyReport' ||
                                elem.reportName === 'serviceAgreementReport' ||
                                elem.reportName === 'partsAgreementReport' ||
                                elem.reportName === 'salesInvoiceReport' ||
                                elem.reportName === 'completedWorkReport') && (
                                <div>
                                    <Tooltip title={<FormattedMessage id='settings_page.director' />}>
                                        <Switch
                                            checked={elem.showDirector}
                                            disabled={isDisabledCRUD}
                                            onChange={async () => {
                                                await fetchAPI('PUT', 'business/report/settings', undefined, {
                                                    reportTypeId: elem.reportTypeId,
                                                    showDirector: !elem.showDirector
                                                });
                                                this.fetchPrintSettings();
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                            )}
                            {elem.reportName === 'actOfAcceptanceReport' && (
                                <div>
                                    <Tooltip title={<FormattedMessage id='settings_page.expressDiagnostic' />}>
                                        <Switch
                                            checked={elem.expressDiagnostic}
                                            disabled={isDisabledCRUD}
                                            onChange={async () => {
                                                await fetchAPI('PUT', 'business/report/settings', undefined, {
                                                    reportTypeId: elem.reportTypeId,
                                                    expressDiagnostic: !elem.expressDiagnostic
                                                });
                                                this.fetchPrintSettings();
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                            )}
                            {elem.reportName === 'completedWorkReport' && (
                                <div>
                                    <Tooltip title={<FormattedMessage id='settings_page.next_visit_date' />}>
                                        <Switch
                                            checked={elem.enableDateOfNextVisit}
                                            disabled={isDisabledCRUD}
                                            onChange={async () => {
                                                await fetchAPI('PUT', 'business/report/settings', undefined, {
                                                    reportTypeId: elem.reportTypeId,
                                                    enableDateOfNextVisit: !elem.enableDateOfNextVisit,
                                                    priceDecimalPlaces: elem.priceDecimalPlaces,
                                                    sumDecimalPlaces: elem.sumDecimalPlaces,
                                                    quantityDecimalPlaces: elem.quantityDecimalPlaces
                                                });
                                                this.fetchPrintSettings();
                                            }}
                                            style={{ marginBottom: 8 }}
                                        />
                                    </Tooltip>
                                </div>
                            )}
                            {elem.reportName === 'completedWorkReport' && (
                                <Tooltip title={<FormattedMessage id='settings_page.next_visit_through' />}>
                                    <Select
                                        disabled={
                                            !elem.enableDateOfNextVisit || !elem.enableDateOfNextVisit || isDisabledCRUD
                                        }
                                        onChange={async value => {
                                            await fetchAPI('PUT', 'business/report/settings', undefined, {
                                                nextVisitAfterDistance: Number(value),
                                                reportTypeId: elem.reportTypeId,
                                                priceDecimalPlaces: elem.priceDecimalPlaces,
                                                sumDecimalPlaces: elem.sumDecimalPlaces,
                                                quantityDecimalPlaces: elem.quantityDecimalPlaces
                                            });

                                            this.fetchPrintSettings();
                                        }}
                                        style={{ marginBottom: 8 }}
                                        value={elem.nextVisitAfterDistance}
                                    >
                                        <Option key='1000' value={1000}>
                                            1,000 <FormattedMessage id='online_service_book_page.km' />.
                                        </Option>
                                        <Option key='2000' value={2000}>
                                            2,000 <FormattedMessage id='online_service_book_page.km' />.
                                        </Option>
                                        <Option key='5000' value={5000}>
                                            5,000 <FormattedMessage id='online_service_book_page.km' />.
                                        </Option>
                                        <Option key='10000' value={10000}>
                                            10,000 <FormattedMessage id='online_service_book_page.km' />.
                                        </Option>
                                        <Option key='15000' value={15000}>
                                            15,000 <FormattedMessage id='online_service_book_page.km' />.
                                        </Option>
                                    </Select>
                                </Tooltip>
                            )}
                            {elem.reportName === 'completedWorkReport' && (
                                <Tooltip title={<FormattedMessage id='settings_page.next_visit_date_by' />}>
                                    <Select
                                        allowClear
                                        disabled={
                                            !elem.enableDateOfNextVisit || !elem.enableDateOfNextVisit || isDisabledCRUD
                                        }
                                        onChange={async value => {
                                            await fetchAPI('PUT', 'business/report/settings', undefined, {
                                                dateOfNextVisit: Number(value),
                                                reportTypeId: elem.reportTypeId,
                                                priceDecimalPlaces: elem.priceDecimalPlaces,
                                                sumDecimalPlaces: elem.sumDecimalPlaces,
                                                quantityDecimalPlaces: elem.quantityDecimalPlaces
                                            });

                                            this.fetchPrintSettings();
                                        }}
                                        style={{ marginBottom: 8 }}
                                        value={elem.dateOfNextVisit}
                                    >
                                        <Option key='30' value={30}>
                                            30 <FormattedMessage id='universal_filters_form.days' />
                                        </Option>
                                        <Option key='90' value={90}>
                                            90 <FormattedMessage id='universal_filters_form.days' />
                                        </Option>
                                        <Option key='180' value={180}>
                                            180 <FormattedMessage id='universal_filters_form.days' />
                                        </Option>
                                        <Option key='365' value={365}>
                                            365 <FormattedMessage id='universal_filters_form.days' />
                                        </Option>
                                    </Select>
                                </Tooltip>
                            )}
                            {elem.reportName === 'clientOrderReport' && (
                                <Tooltip title={<FormattedMessage id='settings_page.next_visit_date' />}>
                                    <Switch
                                        checked={elem.enableDateOfNextVisit}
                                        disabled={isDisabledCRUD}
                                        onChange={async () => {
                                            await fetchAPI('PUT', 'business/report/settings', undefined, {
                                                reportTypeId: elem.reportTypeId,
                                                enableDateOfNextVisit: !elem.enableDateOfNextVisit,
                                                priceDecimalPlaces: elem.priceDecimalPlaces,
                                                sumDecimalPlaces: elem.sumDecimalPlaces,
                                                quantityDecimalPlaces: elem.quantityDecimalPlaces
                                            });
                                            this.fetchPrintSettings();
                                        }}
                                        style={{ marginBottom: 8 }}
                                    />
                                </Tooltip>
                            )}
                            {elem.reportName === 'businessOrderReport' && (
                                <Tooltip title={<FormattedMessage id='settings_page.print_client_n_phone' />}>
                                    <Switch
                                        checked={elem.enableClientNameAndTel}
                                        disabled={elem.reportName !== 'businessOrderReport' || isDisabledCRUD}
                                        onChange={async () => {
                                            await fetchAPI('PUT', 'business/report/settings', undefined, {
                                                enableClientNameAndTel: !elem.enableClientNameAndTel,
                                                reportTypeId: elem.reportTypeId,
                                                priceDecimalPlaces: elem.priceDecimalPlaces,
                                                sumDecimalPlaces: elem.sumDecimalPlaces,
                                                quantityDecimalPlaces: elem.quantityDecimalPlaces
                                            });
                                            this.fetchPrintSettings();
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </React.Fragment>
                    );
                }
            }
        ];
    }

    componentDidMount() {
        this.fetchPrintSettings();
    }

    fetchPrintSettings = async () => {
        const printSettings = await fetchAPI('GET', 'business/reports/settings', undefined, undefined);

        this.setState({
            printSettings
        });
    };

    onOkPrint = async (brand, id) => {
        await fetchAPI('PUT', 'business/report/settings', null, {
            enableBrand: !brand,
            reportTypeId: id
        });
    };

    updatePrintSettings = async settings => {
        await fetchAPI('PUT', 'business/reports/settings', undefined, {
            settings
        });
        this.fetchPrintSettings();
    };

    updatePackageListDetailStatuses = async () => {
        const { user, updateUser } = this.props;
        const { packageStatus } = this.state;

        try {
            this.setState({
                loading: true
            });

            await fetchAPI(
                'PUT',
                '/businesses',
                undefined,
                {
                    packageListDetailStatuses: [packageStatus || get(user, 'packageListDetailStatuses[0]')]
                },
                { handleErrorInternally: true }
            );
            await updateUser({ ...user, packageListDetailStatuses: packageStatus });

            this.setState({
                loading: false
            });
        } catch (err) {
            this.setState({
                loading: false
            });

            notification.warning({
                message: 'Помилка'
            });
        }
    };

    render() {
        const {
            user,
            isDisabledCRUD,
            intl: { formatMessage }
        } = this.props;

        const { printSettings, packageStatus, loading } = this.state;

        return (
            <React.Fragment>
                <Form>
                    <Row className={Styles.row}>
                        <Col span={4}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='profile-form.packing_list_printing' />
                            </div>
                        </Col>
                        <Col span={8}>
                            <div
                                style={{
                                    display: 'flex'
                                }}
                            >
                                <div>
                                    <Select
                                        defaultValue={user.packageListDetailStatuses}
                                        disabled={isDisabledCRUD}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={value => {
                                            this.setState({
                                                packageStatus: value
                                            });
                                        }}
                                        placeholder={formatMessage({
                                            id: 'profile-form.print.placeholder'
                                        })}
                                        style={{
                                            width: 250
                                        }}
                                        value={packageStatus}
                                    >
                                        <Option key='all' value='all'>
                                            <FormattedMessage id='profile-form.choose_list_printing' />
                                        </Option>
                                        <Option key='to_the_shop' value='to_the_shop'>
                                            <FormattedMessage id='profile-form.choose_variant_list_printing' />
                                        </Option>
                                    </Select>
                                </div>
                                <div>
                                    <Button
                                        icon={<SaveOutlined />}
                                        loading={loading}
                                        onClick={() => {
                                            this.updatePackageListDetailStatuses();
                                        }}
                                        type='primary'
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* <Form.Item
                        label={<FormattedMessage id='profile-form.packing_list_printing' />}
                        name='packageStatuses'
                        rules={[
                            {
                                message: formatMessage({
                                    id: 'profile-form.please_select_an_option'
                                })
                            }
                        ]}
                        tooltip={{
                            title: formatMessage({
                                id: 'profile-form.relogin_required'
                            }),
                            icon: <InfoCircleOutlined />
                        }}
                    >
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <div>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        this.setState({
                                            packageListDetailStatuses: value
                                        });
                                    }}
                                    placeholder={formatMessage({
                                        id: 'profile-form.print.placeholder'
                                    })}
                                    style={{
                                        width: 250
                                    }}
                                    value={packageListDetailStatuses}
                                >
                                    <Option value='all'>
                                        <FormattedMessage id='profile-form.choose_list_printing' />
                                    </Option>
                                    <Option value='to_the_shop'>
                                        <FormattedMessage id='profile-form.choose_variant_list_printing' />
                                    </Option>
                                </Select>
                            </div>
                            <div>
                                <Button htmlType='submit' icon={<SaveOutlined />} type='primary' />
                            </div>
                        </div>
                    </Form.Item>

                    <Form.Item
                        className={Styles.itemBtn}
                        wrapperCol={{
                            offset: 11,
                            span: 12
                        }}
                    ></Form.Item> */}
                </Form>

                <div
                    style={{
                        marginTop: 16
                    }}
                >
                    <Table
                        bordered
                        className={Styles.callsTable}
                        columns={this.columns()}
                        dataSource={printSettings}
                        locale={{
                            emptyText: <FormattedMessage id='no_data' />
                        }}
                        pagination={false}
                        size='small'
                    />
                </div>
            </React.Fragment>
        );
    }
}
