import { Input, InputNumber, Select, Tooltip } from 'antd';
import { MODALS, loadModal, saveModal, setModal } from 'core/modals/duck';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { numeralFormatter, numeralParser } from 'utils';
import {
    fetchAnalytics,
    selectAnalytics,
    selectStoreDocServiceFields,
    setStoreDocServiceFields
} from '../../redux/duck';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    analytics: selectAnalytics(state),
    fields: selectStoreDocServiceFields(state)
});

const mapDispatchToProps = {
    fetchAnalytics,
    setStoreDocServiceFields,
    setModal,
    saveModal,
    loadModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class StoreDocServiceContainer extends React.Component {
    constructor(props) {
        super(props);

        _.get(props, 'modalProps.storeDocServiceEntity') &&
            props.setStoreDocServiceFields({
                ...this.props.modalProps.storeDocServiceEntity
            });
    }

    componentDidMount() {
        this.props.fetchAnalytics();
    }

    render() {
        const {
            fields,
            analytics,
            setStoreDocServiceFields,
            mapperData,
            intl: { formatMessage }
        } = this.props;

        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        margin: '24px 0 0 0'
                    }}
                >
                    <div className={Styles.addStoreDocServiceItemWrap}>
                        <FormattedMessage id='store_doc_service.name' />
                        <Input
                            className={Styles.inputStoreDocService}
                            onChange={e => setStoreDocServiceFields({ name: e.target.value })}
                            value={fields.name}
                        />
                    </div>

                    <div className={Styles.addStoreDocServiceItemWrap}>
                        <FormattedMessage id='store_doc_service.analytics' />

                        <Select
                            allowClear
                            className={Styles.inputStoreDocService}
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={analyticsId => setStoreDocServiceFields({ analyticsId })}
                            placeholder={this.props.intl.formatMessage({
                                id: 'store_doc_service.analytics'
                            })}
                            showSearch
                            style={{ width: '150px' }}
                            value={fields.analyticsId}
                        >
                            {analytics.map(({ analyticsUniqueId, analyticsName }) => (
                                <Option key={analyticsUniqueId} value={analyticsUniqueId}>
                                    {analyticsName}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    <div className={Styles.addStoreDocServiceItemWrap}>
                        <FormattedMessage id='directories.orders' />
                        <Tooltip title={<FormattedMessage id='store_doc_service.source_tooltip' />}>
                            <Input
                                className={Styles.inputStoreDocService}
                                onClick={() => {
                                    this.props.saveModal();
                                    this.props.setModal(MODALS.SELECT_ORDER, {
                                        onSubmit: orderId => {
                                            setStoreDocServiceFields({ orderId });
                                        },
                                        onClose: () => {
                                            this.props.loadModal(MODALS.STORE_DOC_SERVICE);
                                        }
                                    });
                                }}
                                value={fields.orderId}
                            />
                        </Tooltip>
                    </div>

                    <div className={Styles.addStoreDocServiceItemWrap}>
                        <FormattedMessage id='order_form_table.purchasePrice' />
                        <InputNumber
                            className={Styles.inputStoreDocService}
                            decimalSeparator=','
                            disabled={mapperData.purchasePriceDisabled}
                            formatter={numeralFormatter}
                            min={1}
                            onChange={price => {
                                setStoreDocServiceFields({ price });
                                setStoreDocServiceFields({
                                    sum: parseFloat((price * fields.count).toFixed(4))
                                });
                            }}
                            parser={numeralParser}
                            precision={4}
                            step={0.1}
                            value={fields.price}
                        />
                    </div>

                    <div className={Styles.addStoreDocServiceItemWrap}>
                        <FormattedMessage id='storage_document.sell_price' />
                        <InputNumber
                            className={Styles.inputStoreDocService}
                            decimalSeparator=','
                            disabled={mapperData.sellingPriceDisabled}
                            formatter={numeralFormatter}
                            min={1}
                            onChange={price => {
                                setStoreDocServiceFields({ price });
                                setStoreDocServiceFields({
                                    sum: parseFloat((price * fields.count).toFixed(4))
                                });
                            }}
                            parser={numeralParser}
                            precision={4}
                            step={0.1}
                            value={fields.price}
                        />
                    </div>

                    <div className={Styles.addStoreDocServiceItemWrap}>
                        <FormattedMessage id='store_doc_service.count' />
                        <InputNumber
                            className={Styles.inputStoreDocService}
                            decimalSeparator=','
                            formatter={numeralFormatter}
                            min={1}
                            onChange={count => {
                                setStoreDocServiceFields({ count });
                                setStoreDocServiceFields({
                                    sum: parseFloat((fields.price * count).toFixed(4))
                                });
                            }}
                            parser={numeralParser}
                            precision={4}
                            step={0.1}
                            value={fields.count}
                        />
                    </div>

                    <div className={Styles.addStoreDocServiceItemWrap}>
                        <FormattedMessage id='services_table.units' />
                        <Select
                            allowClear
                            dropdownMatchSelectWidth={100}
                            getPopupContainer={trigger => trigger.parentNode}
                            onSelect={docServiceUnitId => {
                                setStoreDocServiceFields({ docServiceUnitId });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'services_table.units_placeholder'
                            })}
                            showSearch
                            style={{ width: 100, color: 'var(--text)' }}
                            value={fields.docServiceUnitId}
                        >
                            {(this.props.units || []).map((elem, index) => (
                                <Option key={elem.id} value={elem.id}>
                                    {elem.shortcut}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    <div className={Styles.addStoreDocServiceItemWrap}>
                        <FormattedMessage id='store_doc_service.sum' />
                        <InputNumber
                            className={Styles.inputStoreDocService}
                            decimalSeparator=','
                            disabled
                            formatter={numeralFormatter}
                            min={1}
                            parser={numeralParser}
                            precision={4}
                            step={0.1}
                            value={fields.sum}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
