// Core
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Popconfirm, Table } from 'antd';
import { resetModal, setModal } from 'core/modals/duck';
import { AddCrossLocationModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { PencilIcon } from 'theme';
import { fetchAPI } from 'utils';

const { Panel } = Collapse;

const mapStateToProps = state => ({
    modal: state.modals.modal,
    user: state.auth
});

const mapDispatchToProps = {
    setModal,
    resetModal
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class LocationsTabContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            locations: [],
            allLocations: [],
            page: 1,
            pageSize: 25
        };

        const { isDisabledCRUD } = this.props;

        this.columns = () => [
            {
                title: () => (
                    <Button
                        disabled={isDisabledCRUD}
                        icon={<PlusOutlined />}
                        onClick={() => {
                            this.setState({
                                visibleAddLocationsModal: true
                            });
                        }}
                    />
                ),
                key: 'edit',
                width: '7%',
                align: 'center',
                render: (data, row) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly'
                            }}
                        >
                            <Button
                                disabled={isDisabledCRUD}
                                icon={<PencilIcon />}
                                onClick={() => {
                                    this.editRegulations(row);
                                }}
                            />
                            <Popconfirm
                                disabled={isDisabledCRUD}
                                onConfirm={async () => {
                                    await fetchAPI(
                                        'DELETE',
                                        `pricelists/cross/${row.id}`,
                                        undefined,
                                        { id: row.id },
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    await setTimeout(() => this.fetchData(), 100);
                                }}
                                title={`${props.intl.formatMessage({ id: 'delete' })}?`}
                            >
                                <Button disabled={isDisabledCRUD} icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_document.supplier' />,
                key: 'supplierId',
                dataIndex: 'name',
                width: '20%'
            },
            {
                title: <FormattedMessage id='pricelists.supplier_location_name' />,
                key: 'excelName',
                dataIndex: 'excelName',
                width: '20%'
            },
            {
                title: <FormattedMessage id='pricelists.carbook_location_name' />,
                key: 'code',
                dataIndex: 'code',
                width: '20%'
            }
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        this.fetchAllDefaultLocations();
        this.fetchAllCustomSupplierLocations();
    };

    fetchAllDefaultLocations = async () => {
        const allLocations = await fetchAPI('GET', 'availabilities/locations', undefined, undefined);
        this.setState({
            allLocations: allLocations || []
        });
    };

    fetchAllCustomSupplierLocations = async () => {
        const { page, pageSize } = this.state;
        const locations = await fetchAPI('GET', 'pricelists/cross', { page, pageSize }, undefined);
        this.setState({
            locations: locations.list || [],
            stats: locations.count
        });
    };

    editRegulations = row => {
        this.setState({
            visibleAddLocationsModal: true,
            locationsModalRow: row
        });
    };

    render() {
        const { suppliers } = this.props;
        const {
            allRegulations,
            allLocations,
            visibleAddLocationsModal,
            locations,
            locationsModalRow,
            supplierLocations,
            page,
            pageSize,
            stats
        } = this.state;

        const pagination = {
            pageSize,
            total: Math.ceil(stats / pageSize) * pageSize,
            hideOnSinglePage: false,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.fetchData();
            }
        };

        return (
            <React.Fragment>
                <Table bordered columns={this.columns()} dataSource={locations} pagination={pagination} size='small' />
                <AddCrossLocationModal
                    allLocations={allLocations}
                    fetchData={this.fetchData}
                    hideModal={() => {
                        this.setState({
                            visibleAddLocationsModal: false,
                            locationsModalRow: undefined
                        });
                    }}
                    locationsModalRow={locationsModalRow}
                    suppliers={suppliers}
                    visible={visibleAddLocationsModal}
                />
            </React.Fragment>
        );
    }
}
