import { Spin } from 'antd';
import {
    fetchProductAnalogues,
    selectProductAnaloguesLoading,
    selectStoreProduct,
    selectStoreProductAnalogues
} from 'core/storage/products';
import _ from 'lodash';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    product: selectStoreProduct(state),
    productAnalogues: selectStoreProductAnalogues(state),
    productAnaloguesLoading: selectProductAnaloguesLoading(state)
});

const mapDispatchToProps = {
    fetchProductAnalogues
};

/**
 * Tech info tab contains product attributes
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class TechInfoTab extends Component {
    componentDidMount() {
        // this.props.fetchProductAnalogues();
    }

    render() {
        const { productAnalogues, productAnaloguesLoading, images, attributes } = this.props;

        const productAnalogue =
            _.filter(productAnalogues, item => {
                return item.images.length > 0 && item.attributes.length > 0;
            })[0] || productAnalogues[0];

        const src =
            images && images.length
                ? `${__TECDOC_IMAGES_URL__}/${images[0].pictureName}`
                : `${__TECDOC_IMAGES_URL__}/not_found.png`;

        return productAnaloguesLoading ? (
            <Spin />
        ) : (
            <div className={Styles.techInfoWrapper}>
                <div className={Styles.techInfoImageWrapper}>
                    <img
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = `${__TECDOC_IMAGES_URL__}/not_found.png`;
                        }}
                        src={src}
                        style={{ cursor: 'pointer' }}
                        width='100%'
                    />
                </div>
                <div className={Styles.techInfoItemWrapper}>
                    {attributes &&
                        attributes.map((attribute, i) => (
                            <div key={i} className={Styles.attributeItem}>
                                {attribute.description}: {attribute.value}
                            </div>
                        ))}
                </div>
            </div>
        );
    }
}
