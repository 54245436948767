import {
    CarOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    CloseSquareOutlined,
    LockOutlined,
    PlusSquareOutlined,
    QuestionCircleOutlined,
    SafetyOutlined,
    StopOutlined,
    SwapOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Modal, notification } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

const { confirm } = Modal;

const StatusDropdown = ({
    orderStatus,
    orderSubStatus,
    checkReserved,
    onStatusChange,
    onSubStatusChange,
    isMobile,
    setModal,
    modals,
    intl
}) => {
    const [subStatuses, setSubStatuses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingStatus, setloadingStatus] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        const substatuses = await fetchAPI(
            'GET',
            'business_substatuses_relationship',
            { filterByPackage: true, isForOrder: true, subStatus: orderSubStatus },
            null,
            {
                handleErrorInternally: true
            }
        );
        setSubStatuses(get(substatuses, '[0].availableStatuses'));
        setLoading(false);
    };

    useEffect(() => {
        const setupFetch = async () => {
            await fetchData();
        };
        setupFetch();
    }, []);

    const handleMenuClick = ({ item, key }) => {
        const status = item.props.title;
        if (status !== orderStatus) {
            if (status === 'success') {
                checkReserved(() => setModal(modals.TO_SUCCESS));

                return;
            }
            if (status === 'not_complete') {
                confirm({
                    title: intl.formatMessage({
                        id: 'order-page.change_status_to_new'
                    }),
                    onOk: () => {
                        setloadingStatus(true);
                        notification.warning({ message: 'Н/З обробляється, не закривайте вкладку 20 секунд.' });
                        setTimeout(() => {
                            onStatusChange('not_complete');
                            notification.success({
                                message: 'Успішно!'
                            });
                        }, 20000);
                    },
                    okButtonProps: {
                        disabled: loadingStatus
                    }
                });

                return;
            }
        }
        onSubStatusChange({ status, subStatus: key });
    };

    const icons = {
        required: <QuestionCircleOutlined />,
        reserve: <LockOutlined />,
        not_complete: <PlusSquareOutlined />,
        approve: <SafetyOutlined />,
        progress: <CarOutlined />,
        success: <CheckOutlined />,
        processing: <ClockCircleOutlined />,
        stop: <StopOutlined />,
        cancel: <CloseSquareOutlined />
    };

    const items = get(subStatuses, 'length')
        ? subStatuses.map(({ statusName, subStatusDefaultName, subStatusName }) => ({
              label: subStatusName || <FormattedMessage id={`status_directory.${subStatusDefaultName}`} />,
              key: subStatusDefaultName,
              icon: icons[statusName],
              title: statusName
          }))
        : [];

    const menuProps = {
        items,
        onClick: handleMenuClick
    };

    return (
        <Dropdown menu={menuProps}>
            <Button
                icon={<SwapOutlined style={{ fontSize: isMobile ? 14 : 24 }} />}
                loading={loading}
                size='large'
                type='text'
            />
        </Dropdown>
    );
};

export default injectIntl(StatusDropdown);
