/* eslint-disable max-classes-per-file */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
import {
    CheckOutlined,
    CopyOutlined,
    DeleteOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { Button, Checkbox, Input, InputNumber, Modal, Popconfirm, Select, Table, Tabs, Tooltip } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FeaturesIcons } from 'theme';
import { fetchAPI, getCurrency } from 'utils';
import './styles.less';

const { TabPane } = Tabs;
const { Option } = Select;

@injectIntl
export class ServicesSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            services: [],
            allServices: [],
            basicServices: [],
            allBusinessServices: [],
            complexes: [],
            tireFittingGroups: []
        };
        const {
            isDisabledCRUD,
            intl: { formatMessage }
        } = props;

        this.servicesColumns = () => [
            {
                title: (
                    <Tooltip title={<FormattedMessage id='web_settings.add_category' />}>
                        <Button
                            disabled={isDisabledCRUD}
                            icon={<PlusOutlined />}
                            onClick={async () => {
                                let id;
                                this.props.cb24data.services_categories.forEach(({ value }) => {
                                    const index = this.state.categories.findIndex(
                                        ({ category_id }) => category_id === value
                                    );
                                    if (index === -1) {
                                        id = value;
                                    }
                                });
                                await fetchAPI(
                                    'POST',
                                    'rest/v1/services/categories',
                                    null,
                                    { sessionId: this.props.sessionId, id },
                                    {
                                        url: __OLD_APP_API_URL__
                                    }
                                );
                                this.fetchCategories();
                            }}
                        />
                    </Tooltip>
                ),
                width: '5%',
                key: 'eye',
                align: 'center',
                render: (row, _, index) => {
                    return (
                        <div
                            onClick={async () => {
                                const { sessionId } = this.props;
                                await fetchAPI(
                                    'PUT',
                                    `rest/v1/services/categories/${row.id}/visibility`,
                                    null,
                                    {
                                        index,
                                        sessionId,
                                        visible: !row.visible
                                    },
                                    { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
                                );
                                this.fetchCategories();
                            }}
                            style={{
                                cursor: 'pointer'
                            }}
                        >
                            {row.visible ? (
                                <EyeOutlined
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            ) : (
                                <EyeInvisibleOutlined
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            )}
                        </div>
                    );
                }
            },
            {
                width: '5%',
                key: 'icon',
                align: 'center',
                render: row => {
                    return <FeaturesIcons name={row.type} size={24} />;
                }
            },
            {
                title: formatMessage({ id: 'web_settings.category_col' }),
                key: 'category',
                render: row => {
                    return (
                        <Select
                            disabled={isDisabledCRUD}
                            onChange={category_id => {
                                row.category_id = category_id;
                                this.setState({});
                            }}
                            optionFilterProp='children'
                            showSearch
                            value={row.category_id}
                        >
                            {this.props.cb24data.services_categories.map(({ value, label }) => (
                                <Option key={value} value={value}>
                                    {label}
                                </Option>
                            ))}
                        </Select>
                    );
                }
            },
            {
                title: formatMessage({ id: 'web_settings.additional_desc' }),
                key: 'category',
                render: row => {
                    return (
                        <Input
                            disabled={isDisabledCRUD}
                            onChange={event => {
                                row.description = event.target.value;
                                this.setState({});
                            }}
                            placeholder={formatMessage({ id: 'web_settings.additional_desc' })}
                            value={row.description}
                        />
                    );
                }
            },
            {
                title: formatMessage({ id: 'web_settings.services' }),
                key: 'services',
                align: 'center',
                render: row => {
                    return (
                        <div
                            onClick={() => this.setState({ selectedCategoryId: row.id })}
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'underline'
                            }}
                        >
                            {row.count_services}
                        </div>
                    );
                }
            },
            {
                width: '5%',
                key: 'delete',
                align: 'center',
                render: ({ id, category_id, description }) => {
                    return (
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <Button
                                disabled={isDisabledCRUD}
                                icon={<CheckOutlined />}
                                onClick={async () => {
                                    const { sessionId } = this.props;
                                    await fetchAPI(
                                        'PUT',
                                        `rest/v1/services/categories/${id}`,
                                        null,
                                        { sessionId, category_id, description },
                                        {
                                            url: __OLD_APP_API_URL__
                                        }
                                    );
                                    this.fetchCategories();
                                }}
                                style={{
                                    fontSize: 20,
                                    marginRight: 8
                                }}
                            />
                            <Popconfirm
                                onConfirm={async () => {
                                    const { sessionId } = this.props;
                                    await fetchAPI(
                                        'DELETE',
                                        `rest/v1/services/categories/${id}`,
                                        null,
                                        { sessionId },
                                        {
                                            url: __OLD_APP_API_URL__
                                        }
                                    );
                                    this.fetchCategories();
                                }}
                                title={<FormattedMessage id='general_settings_delete_question' />}
                            >
                                <Button
                                    disabled={isDisabledCRUD}
                                    icon={<DeleteOutlined />}
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            </Popconfirm>
                        </div>
                    );
                }
            }
        ];

        this.carWashColumns = () => [
            {
                title: (
                    <Button
                        disabled={isDisabledCRUD}
                        icon={
                            <Tooltip title={<FormattedMessage id='web_settings.add_category' />}>
                                <PlusOutlined />
                            </Tooltip>
                        }
                    />
                ),
                width: '5%',
                key: 'eye',
                align: 'center',
                render: (row, _, index) => {
                    return (
                        <div
                            onClick={async () => {
                                const { sessionId } = this.props;
                                await fetchAPI(
                                    'PUT',
                                    `rest/v1/carwash-services/${row.id}/visibility`,
                                    null,
                                    {
                                        index,
                                        sessionId,
                                        visible: !row.visible
                                    },
                                    { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
                                );
                                this.fetchCarWash();
                            }}
                            style={{
                                cursor: 'pointer'
                            }}
                        >
                            {row.visible ? (
                                <EyeOutlined
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            ) : (
                                <EyeInvisibleOutlined
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            )}
                        </div>
                    );
                }
            },
            {
                title: formatMessage({ id: 'web_settings.wash_service' }),
                key: 'wash_service',
                render: row => {
                    return !row.name_subheader ? (
                        <Select
                            disabled={isDisabledCRUD}
                            onChange={async service_id => {
                                const { sessionId } = this.props;
                                await fetchAPI(
                                    'PUT',
                                    `rest/v1/carwash-services/${row.id}`,
                                    null,
                                    { sessionId, ...row, service_id },
                                    {
                                        url: __OLD_APP_API_URL__
                                    }
                                );
                                this.fetchCarWash();
                            }}
                            optionFilterProp='children'
                            showSearch
                            value={row.service_id}
                        >
                            {this.state.allServices.map(({ value, label }) => (
                                <Option key={value} value={value}>
                                    {label}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        row.name_subheader
                    );
                }
            },
            {
                title: formatMessage({ id: 'web_settings.wash_service' }),
                key: 'wash_service',
                render: row => {
                    return (
                        !row.name_subheader && (
                            <Input
                                disabled={isDisabledCRUD}
                                onBlur={async () => {
                                    const { sessionId } = this.props;
                                    await fetchAPI(
                                        'PUT',
                                        `rest/v1/carwash-services/${row.id}`,
                                        null,
                                        { sessionId, ...row },
                                        {
                                            url: __OLD_APP_API_URL__
                                        }
                                    );
                                    this.fetchCarWash();
                                }}
                                onChange={e => {
                                    row.description = e.target.value;
                                    this.setState({});
                                }}
                                value={row.description}
                            />
                        )
                    );
                }
            },
            {
                key: 'action_buttons',
                render: row => {
                    return (
                        !row.name_subheader && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly'
                                }}
                            >
                                <Tooltip title={<FormattedMessage id='web_settings.delete_wash_service' />}>
                                    <Button
                                        icon={<DeleteOutlined />}
                                        style={{
                                            fontSize: 20
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title={<FormattedMessage id='web_settings.duplicate_wash_service' />}>
                                    <Button
                                        disabled={isDisabledCRUD}
                                        icon={<CopyOutlined />}
                                        style={{
                                            fontSize: 20
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        )
                    );
                }
            },
            ...(this.state.vehiclesGroups || []).map(group => ({
                key: group.id,
                title: group.name,
                children: [
                    {
                        title: <FormattedMessage id='price' />,
                        key: 'price',
                        render: row => {
                            const price =
                                row.prices && row.prices[group.id] ? row.prices[group.id].cwp_price : undefined;

                            return (
                                !row.name_subheader && (
                                    <InputNumber
                                        addonAfter={getCurrency()}
                                        disabled={isDisabledCRUD}
                                        onBlur={async e => {
                                            const { sessionId } = this.props;
                                            await fetchAPI(
                                                'PUT',
                                                `rest/v1/carwash-services/${row.id}`,
                                                null,
                                                {
                                                    sessionId,
                                                    ...row,
                                                    prices: undefined,
                                                    vehicleGroupId: group.id,
                                                    vehicleGroupPrice: e.target.value
                                                },
                                                {
                                                    url: __OLD_APP_API_URL__
                                                }
                                            );
                                            this.fetchCarWash();
                                        }}
                                        placeholder={formatMessage({
                                            id: 'web_settings.wash_amount'
                                        })}
                                        value={price}
                                    />
                                )
                            );
                        }
                    },
                    {
                        title: <FormattedMessage id='deadlineTime' />,
                        key: 'amount',
                        render: row => {
                            const amount =
                                row.prices && row.prices[group.id] ? row.prices[group.id].cwp_minutes : undefined;

                            return (
                                !row.name_subheader && (
                                    <InputNumber
                                        addonAfter={<FormattedMessage id='min' />}
                                        disabled={isDisabledCRUD}
                                        onBlur={async e => {
                                            const { sessionId } = this.props;
                                            await fetchAPI(
                                                'PUT',
                                                `rest/v1/carwash-services/${row.id}`,
                                                null,
                                                {
                                                    sessionId,
                                                    ...row,
                                                    prices: undefined,
                                                    vehicleGroupId: group.id,
                                                    vehicleGroupMinutes: e.target.value
                                                },
                                                {
                                                    url: __OLD_APP_API_URL__
                                                }
                                            );
                                            this.fetchCarWash();
                                        }}
                                        placeholder={formatMessage({
                                            id: 'web_settings.wash_amount'
                                        })}
                                        value={amount}
                                    />
                                )
                            );
                        }
                    }
                ]
            }))
        ];

        this.carWashComplexColumns = () => [
            {
                title: (
                    <Button
                        disabled={isDisabledCRUD}
                        icon={
                            <Tooltip title={<FormattedMessage id='web_settings.add_complex' />}>
                                <PlusOutlined />
                            </Tooltip>
                        }
                        onClick={async () => {
                            const { sessionId } = this.props;
                            await fetchAPI(
                                'POST',
                                'rest/v1/carwash-complexes',
                                null,
                                {
                                    sessionId
                                },
                                { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
                            );
                            this.fetchCarWashComplex();
                        }}
                    />
                ),
                width: '5%',
                key: 'eye',
                align: 'center',
                render: (row, _, index) => {
                    return (
                        <div
                            onClick={async () => {
                                const { sessionId } = this.props;
                                await fetchAPI(
                                    'PUT',
                                    `rest/v1/carwash-complexes/${row.id}/visibility`,
                                    null,
                                    {
                                        index,
                                        sessionId,
                                        visible: !row.visible
                                    },
                                    { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
                                );
                                this.fetchCarWashComplex();
                            }}
                            style={{
                                cursor: 'pointer'
                            }}
                        >
                            {row.visible ? (
                                <EyeOutlined
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            ) : (
                                <EyeInvisibleOutlined
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            )}
                        </div>
                    );
                }
            },
            {
                title: formatMessage({ id: 'web_settings.wash_service_complex' }),
                key: 'wash_service',
                width: '25%',
                render: row => {
                    return (
                        <div>
                            <Input
                                disabled={isDisabledCRUD}
                                onBlur={async () => {
                                    const { sessionId } = this.props;
                                    await fetchAPI(
                                        'PUT',
                                        `rest/v1/carwash-complexes/${row.id}`,
                                        null,
                                        { sessionId, ...row },
                                        {
                                            url: __OLD_APP_API_URL__
                                        }
                                    );
                                    this.fetchCarWashComplex();
                                }}
                                onChange={event => {
                                    row.name = event.target.value;
                                    this.setState({});
                                }}
                                placeholder={formatMessage({
                                    id: 'web_settings.wash_service_complex'
                                })}
                                style={{
                                    marginBottom: 8
                                }}
                                value={row.name}
                            />
                            <Select
                                disabled={isDisabledCRUD}
                                mode='multiple'
                                onChange={async value => {
                                    const { sessionId } = this.props;
                                    await fetchAPI(
                                        'PUT',
                                        `rest/v1/carwash-complexes/${row.id}`,
                                        null,
                                        {
                                            sessionId,
                                            ...row,
                                            services_ids: value.join(',')
                                        },
                                        { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
                                    );
                                    this.fetchCarWashComplex();
                                }}
                                optionFilterProp='children'
                                showSearch
                                style={{
                                    marginBottom: 8
                                }}
                                value={row.services_ids ? String(row.services_ids).split(',') : undefined}
                            >
                                {this.state.allBusinessServices.map(({ label, value }) => (
                                    <Option key={value} value={String(value)}>
                                        {label}
                                    </Option>
                                ))}
                            </Select>
                            <Input
                                disabled={isDisabledCRUD}
                                onBlur={async () => {
                                    const { sessionId } = this.props;
                                    await fetchAPI(
                                        'PUT',
                                        `rest/v1/carwash-complexes/${row.id}`,
                                        null,
                                        { sessionId, ...row },
                                        {
                                            url: __OLD_APP_API_URL__
                                        }
                                    );
                                    this.fetchCarWashComplex();
                                }}
                                onChange={event => {
                                    row.description = event.target.value;
                                    this.setState({});
                                }}
                                placeholder={formatMessage({ id: 'web_settings.wash_desc' })}
                                value={row.description}
                            />
                        </div>
                    );
                }
            },
            {
                width: '5%',
                key: 'delete',
                align: 'center',
                render: row => {
                    return (
                        <Popconfirm
                            disabled={isDisabledCRUD}
                            onConfirm={async () => {
                                const { sessionId } = this.props;
                                await fetchAPI(
                                    'DELETE',
                                    `rest/v1/carwash-complexes/${row.id}`,
                                    null,
                                    { sessionId, ...row },
                                    {
                                        url: __OLD_APP_API_URL__
                                    }
                                );
                                this.fetchCarWashComplex();
                            }}
                            title={<FormattedMessage id='general_settings_delete_question' />}
                        >
                            <Button
                                disabled={isDisabledCRUD}
                                icon={<DeleteOutlined />}
                                onClick={() => {}}
                                style={{
                                    fontSize: 20
                                }}
                            />
                        </Popconfirm>
                    );
                }
            },
            ...(this.state.washVehiclesGroups || []).map(group => ({
                key: group.id,
                title: group.name,
                render: row => {
                    return (
                        <div>
                            <div
                                style={{
                                    marginBottom: 14
                                }}
                            >
                                <span>
                                    <FormattedMessage id='web_settings.wash_list_price' />:{' '}
                                    {_.get(row.prices_services, `[${group.id}].sum_prices`)}
                                </span>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    marginBottom: 8
                                }}
                            >
                                <InputNumber
                                    addonAfter={getCurrency()}
                                    disabled={isDisabledCRUD}
                                    onBlur={async e => {
                                        const { sessionId } = this.props;
                                        await fetchAPI(
                                            'PUT',
                                            `rest/v1/carwash-complexes/${row.id}`,
                                            null,
                                            {
                                                sessionId,
                                                ...row,
                                                vehicleGroupId: group.id,
                                                vehicleGroupPrice: e.target.value
                                            },
                                            {
                                                url: __OLD_APP_API_URL__
                                            }
                                        );
                                        this.fetchCarWashComplex();
                                    }}
                                    placeholder={formatMessage({ id: 'web_settings.wash_price' })}
                                    style={{
                                        marginRight: 8
                                    }}
                                    value={_.get(row.prices, `[${group.id}].cwp_price`)}
                                />
                                <InputNumber
                                    addonAfter={getCurrency()}
                                    disabled={isDisabledCRUD}
                                    onBlur={async e => {
                                        const { sessionId } = this.props;
                                        await fetchAPI(
                                            'PUT',
                                            `rest/v1/carwash-complexes/${row.id}`,
                                            null,
                                            {
                                                sessionId,
                                                ...row,
                                                vehicleGroupId: group.id,
                                                vehicleGroupMinutes: e.target.value
                                            },
                                            {
                                                url: __OLD_APP_API_URL__
                                            }
                                        );
                                        this.fetchCarWashComplex();
                                    }}
                                    placeholder={formatMessage({ id: 'web_settings.wash_amount' })}
                                    value={_.get(row.prices, `[${group.id}].cwp_minutes`)}
                                />
                            </div>
                            <div
                                style={{
                                    width: '49%'
                                }}
                            >
                                <InputNumber
                                    addonAfter='%'
                                    disabled
                                    placeholder={formatMessage({ id: 'web_settings.discount' })}
                                />
                            </div>
                        </div>
                    );
                }
            }))
        ];

        this.tireFittingColumns = () => [
            {
                width: '5%',
                key: 'eye',
                align: 'center',
                render: (row, _, index) => {
                    return (
                        <div
                            onClick={async () => {
                                const { sessionId } = this.props;
                                await fetchAPI(
                                    'PUT',
                                    `rest/v1/tire-station-services/${row.id}/visibility`,
                                    null,
                                    {
                                        index,
                                        sessionId,
                                        visible: !row.visible
                                    },
                                    { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
                                );
                                this.fetchTireFitting();
                            }}
                            style={{
                                cursor: 'pointer'
                            }}
                        >
                            {row.visible ? (
                                <EyeOutlined
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            ) : (
                                <EyeInvisibleOutlined
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            )}
                        </div>
                    );
                }
            },
            {
                title: formatMessage({ id: 'web_settings.wash_service' }),
                key: 'wash_service',
                render: row => {
                    return (
                        <div>
                            <Select
                                disabled={isDisabledCRUD}
                                onChange={async service_id => {
                                    const { sessionId } = this.props;
                                    await fetchAPI(
                                        'PUT',
                                        `rest/v1/tire-station-services/${row.id}`,
                                        null,
                                        {
                                            sessionId,
                                            ...row,
                                            service_id
                                        },
                                        { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
                                    );
                                    this.fetchTireFitting();
                                }}
                                optionFilterProp='children'
                                showSearch
                                style={{
                                    marginBottom: 8
                                }}
                                value={row.service_id}
                            >
                                {this.state.allRadiusesServices.map(({ label, value }) => (
                                    <Option key={value} value={value}>
                                        {label}
                                    </Option>
                                ))}
                            </Select>
                            <Input
                                disabled={isDisabledCRUD}
                                onBlur={async () => {
                                    const { sessionId } = this.props;
                                    await fetchAPI(
                                        'PUT',
                                        `rest/v1/tire-station-services/${row.id}`,
                                        null,
                                        { sessionId, ...row },
                                        {
                                            url: __OLD_APP_API_URL__
                                        }
                                    );
                                    this.fetchTireFitting();
                                }}
                                onChange={event => {
                                    row.description = event.target.value;
                                    this.setState({});
                                }}
                                placeholder={formatMessage({ id: 'web_settings.wash_desc' })}
                                value={row.description}
                            />
                        </div>
                    );
                }
            },
            {
                key: 'action_buttons',
                align: 'center',
                render: row => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly'
                            }}
                        >
                            <Popconfirm
                                disabled={isDisabledCRUD}
                                onConfirm={async () => {
                                    const { sessionId } = this.props;
                                    await fetchAPI(
                                        'DELETE',
                                        `rest/v1/tire-station-services/${row.id}`,
                                        null,
                                        { sessionId, ...row },
                                        {
                                            url: __OLD_APP_API_URL__
                                        }
                                    );
                                    this.fetchTireFitting();
                                }}
                                title={<FormattedMessage id='general_settings_delete_question' />}
                            >
                                <Button
                                    disabled={isDisabledCRUD}
                                    icon={<DeleteOutlined />}
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            </Popconfirm>
                            <Tooltip title={<FormattedMessage id='web_settings.duplicate_wash_service' />}>
                                <Button
                                    disabled={isDisabledCRUD}
                                    icon={<CopyOutlined />}
                                    onClick={async () => {
                                        const { sessionId } = this.props;
                                        await fetchAPI(
                                            'POST',
                                            `rest/v1/tire-station-services/clone/${row.id}`,
                                            null,
                                            {
                                                sessionId,
                                                ...row
                                            },
                                            {
                                                handleErrorInternally: true,
                                                url: __OLD_APP_API_URL__
                                            }
                                        );
                                        this.fetchTireFitting();
                                    }}
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            }
        ];
    }

    fetchCategories = async () => {
        const { sessionId } = this.props;
        const categories = await fetchAPI('GET', 'rest/v1/services/categories', { sessionId }, null, {
            url: __OLD_APP_API_URL__
        });
        this.setState({ categories });
    };

    fetchCarWash = async () => {
        const { sessionId } = this.props;
        const { allServices, services, vehiclesGroups } = await fetchAPI(
            'GET',
            'rest/v1/carwash-services',
            { sessionId },
            null,
            {
                url: __OLD_APP_API_URL__
            }
        );
        this.setState({
            allServices,
            services,
            vehiclesGroups
        });
    };

    componentDidMount() {
        this.fetchCategories();
        this.fetchCarWash();
        this.fetchBasicServices();
        this.fetchCarWashComplex();
        this.fetchTireFitting();
    }

    async fetchBasicServices() {
        const { sessionId } = this.props;
        const { services, service_id_1, service_id_2, service_id_3, service_id_4 } = await fetchAPI(
            'GET',
            'rest/v1/services/basic-services',
            { sessionId },
            null,
            {
                url: __OLD_APP_API_URL__
            }
        );
        this.setState({
            basicServices: services,
            service_id_1,
            service_id_2,
            service_id_3,
            service_id_4
        });
    }

    async fetchCarWashComplex() {
        const { sessionId } = this.props;
        const { allBusinessServices, complexes, vehiclesGroups } = await fetchAPI(
            'GET',
            'rest/v1/carwash-complexes',
            { sessionId },
            null,
            {
                url: __OLD_APP_API_URL__
            }
        );
        this.setState({
            allBusinessServices,
            complexes,
            washVehiclesGroups: vehiclesGroups
        });
    }

    async fetchTireFitting() {
        const { sessionId } = this.props;
        const { allGeneralServices, allRadiusesServices, vehicleGroups } = await fetchAPI(
            'GET',
            'rest/v1/tire-station-services',
            { sessionId },
            null,
            {
                url: __OLD_APP_API_URL__
            }
        );
        this.setState({
            allGeneralServices,
            allRadiusesServices,
            tireFittingGroups: vehicleGroups
        });
    }

    async saveBasicServices() {
        const { sessionId } = this.props;
        const { service_id_1, service_id_2, service_id_3, service_id_4 } = this.state;
        await fetchAPI(
            'PUT',
            'rest/v1/services/basic-services',
            null,
            { sessionId, service_id_1, service_id_2, service_id_3, service_id_4 },
            {
                url: __OLD_APP_API_URL__
            }
        );
        this.fetchBasicServices();
    }

    render() {
        const { cb24data, sessionId, isDisabledCRUD } = this.props;
        const {
            categories,
            services,
            selectedCategoryId,
            basicServices,
            service_id_1,
            service_id_2,
            service_id_3,
            service_id_4,
            complexes,
            tireFittingGroups,
            allRadiusesServices
        } = this.state;

        return (
            <Tabs tabPosition='right'>
                {cb24data.is_garage && (
                    <React.Fragment>
                        <TabPane key='car_service' tab={<FormattedMessage id='web_settings.car_service' />}>
                            <div
                                style={{
                                    marginBottom: 8
                                }}
                            >
                                <Button
                                    disabled={isDisabledCRUD}
                                    onClick={async () => {
                                        await cb24data.services_categories.forEach(async ({ value }) => {
                                            const index = categories.findIndex(
                                                ({ category_id }) => category_id === value
                                            );
                                            if (index === -1) {
                                                await fetchAPI(
                                                    'POST',
                                                    'rest/v1/services/categories',
                                                    null,
                                                    {
                                                        sessionId,
                                                        id: value
                                                    },
                                                    {
                                                        url: __OLD_APP_API_URL__
                                                    }
                                                );
                                                this.fetchCategories();
                                            }
                                        });
                                    }}
                                >
                                    <FormattedMessage id='web_settings.add_all_categories' />
                                </Button>
                            </div>
                            <Table
                                bordered
                                columns={this.servicesColumns()}
                                dataSource={categories}
                                pagination={false}
                                rowKey='id'
                            />
                            <CategoryModal
                                hideModal={() => {
                                    this.setState({ selectedCategoryId: undefined });
                                    this.fetchCategories();
                                }}
                                id={selectedCategoryId}
                                sessionId={sessionId}
                                updateData={this.fetchCategories}
                                visible={Boolean(selectedCategoryId)}
                            />
                        </TabPane>
                        <TabPane key='basic services' tab={<FormattedMessage id='web_settings.basic services' />}>
                            <div>
                                <div className='baseServices_block'>
                                    <span className='baseServices_text'>
                                        <FormattedMessage id='web_settings.cost_of_norm_houres' />
                                    </span>
                                    <Select
                                        disabled={isDisabledCRUD}
                                        onChange={async service_id_1 => {
                                            await this.setState({ service_id_1 });
                                            this.saveBasicServices();
                                        }}
                                        optionFilterProp='children'
                                        showSearch
                                        value={service_id_1}
                                    >
                                        {basicServices.map(({ value, label }) => (
                                            <Option key={value} value={value}>
                                                {label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className='baseServices_block'>
                                    <span className='baseServices_text'>
                                        <FormattedMessage id='web_settings.cost_of_norm_houres' />
                                    </span>
                                    <Select
                                        disabled={isDisabledCRUD}
                                        onChange={async service_id_2 => {
                                            await this.setState({ service_id_2 });
                                            this.saveBasicServices();
                                        }}
                                        optionFilterProp='children'
                                        showSearch
                                        value={service_id_2}
                                    >
                                        {basicServices.map(({ value, label }) => (
                                            <Option key={value} value={value}>
                                                {label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className='baseServices_block'>
                                    <span className='baseServices_text'>
                                        <FormattedMessage id='web_settings.cost_of_norm_houres' />
                                    </span>
                                    <Select
                                        disabled={isDisabledCRUD}
                                        onChange={async service_id_3 => {
                                            await this.setState({ service_id_3 });
                                            this.saveBasicServices();
                                        }}
                                        optionFilterProp='children'
                                        showSearch
                                        value={service_id_3}
                                    >
                                        {basicServices.map(({ value, label }) => (
                                            <Option key={value} value={value}>
                                                {label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className='baseServices_block'>
                                    <span className='baseServices_text'>
                                        <FormattedMessage id='web_settings.cost_of_norm_houres' />
                                    </span>
                                    <Select
                                        disabled={isDisabledCRUD}
                                        onChange={async service_id_4 => {
                                            await this.setState({ service_id_4 });
                                            this.saveBasicServices();
                                        }}
                                        optionFilterProp='children'
                                        showSearch
                                        value={service_id_4}
                                    >
                                        {basicServices.map(({ value, label }) => (
                                            <Option key={value} value={value}>
                                                {label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </TabPane>
                    </React.Fragment>
                )}
                {cb24data.is_car_wash && (
                    <React.Fragment>
                        <TabPane key='car_wash' tab={<FormattedMessage id='web_settings.car_wash' />}>
                            <Table bordered columns={this.carWashColumns()} dataSource={services} pagination={false} />
                        </TabPane>
                        <TabPane key='car_wash_complex' tab={<FormattedMessage id='web_settings.car_wash_complex' />}>
                            <Table bordered columns={this.carWashComplexColumns()} dataSource={complexes} />
                        </TabPane>
                    </React.Fragment>
                )}
                {cb24data.is_tire_station && (
                    <TabPane
                        key='tire_fitting_servises'
                        tab={<FormattedMessage id='web_settings.tire_fitting_servises' />}
                    >
                        {tireFittingGroups.map(group => (
                            <div key={group.id}>
                                <div className='tireFitting_add'>
                                    {group.name}
                                    <Button
                                        disabled={isDisabledCRUD}
                                        onClick={async () => {
                                            const { sessionId } = this.props;
                                            let id;
                                            allRadiusesServices.forEach(({ value }) => {
                                                const index = group.services.findIndex(({ id }) => id === value);
                                                if (index === -1) {
                                                    id = value;
                                                }
                                            });
                                            await fetchAPI(
                                                'POST',
                                                'rest/v1/tire-station-services',
                                                null,
                                                {
                                                    sessionId,
                                                    group_id: group.id,
                                                    id
                                                },
                                                {
                                                    handleErrorInternally: true,
                                                    url: __OLD_APP_API_URL__
                                                }
                                            );
                                            this.fetchTireFitting();
                                        }}
                                    >
                                        <FormattedMessage id='web_settings.add_a_subcategory' />
                                    </Button>
                                </div>
                                <Table bordered columns={this.tireFittingColumns()} dataSource={group.services} />
                            </div>
                        ))}
                    </TabPane>
                )}
            </Tabs>
        );
    }
}

export class CategoryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allServices: []
        };

        this.columns = () => [
            {
                width: '5%',
                key: 'eye',
                align: 'center',
                render: (row, _, index) => {
                    return (
                        <div
                            onClick={async () => {
                                const { sessionId } = this.props;
                                await fetchAPI(
                                    'PUT',
                                    `rest/v1/services/${row.id}/visibility`,
                                    null,
                                    {
                                        index,
                                        sessionId,
                                        visible: !row.visible
                                    },
                                    { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
                                );
                                this.fetchData();
                            }}
                            style={{
                                cursor: 'pointer'
                            }}
                        >
                            {row.visible ? (
                                <EyeOutlined
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            ) : (
                                <EyeInvisibleOutlined
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            )}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='web_settings.sevices' />,
                key: 'categories',
                render: row => {
                    return (
                        <Select
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={async service_id => {
                                const { sessionId } = this.props;
                                await fetchAPI(
                                    'PUT',
                                    `rest/v1/services/${row.id}`,
                                    null,
                                    {
                                        categoryId: this.props.id,
                                        sessionId,
                                        service_id,
                                        description: row.description,
                                        hours: row.hours,
                                        price: row.price
                                    },
                                    { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
                                );
                                this.fetchData();
                            }}
                            optionFilterProp='children'
                            showSearch
                            value={row.service_id}
                        >
                            {this.state.allServices.map(({ value, label }) => (
                                <Option key={value} value={value}>
                                    {label}
                                </Option>
                            ))}
                        </Select>
                    );
                }
            },
            {
                title: <FormattedMessage id='web_settings.wash_desc' />,
                key: 'desc',
                render: row => {
                    return (
                        <Input
                            onBlur={async () => {
                                const { sessionId } = this.props;
                                await fetchAPI(
                                    'PUT',
                                    `rest/v1/services/${row.id}`,
                                    null,
                                    {
                                        categoryId: this.props.id,
                                        sessionId,
                                        service_id: row.service_id,
                                        description: row.description,
                                        hours: row.hours,
                                        price: row.price
                                    },
                                    { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
                                );
                                this.fetchData();
                            }}
                            onChange={e => {
                                row.description = e.target.value;
                                this.setState({});
                            }}
                            value={row.description}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='web_settings.amount_of_norm_houres' />,
                key: 'hours',
                render: row => {
                    return (
                        <InputNumber
                            onBlur={async () => {
                                const { sessionId } = this.props;
                                await fetchAPI(
                                    'PUT',
                                    `rest/v1/services/${row.id}`,
                                    null,
                                    {
                                        categoryId: this.props.id,
                                        sessionId,
                                        service_id: row.service_id,
                                        description: row.description,
                                        hours: row.hours,
                                        price: row.price
                                    },
                                    { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
                                );
                                this.fetchData();
                            }}
                            onChange={hours => {
                                row.hours = hours;
                                this.setState({});
                            }}
                            value={row.hours}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='web_settings.estimated_price' />,
                key: 'prices',
                render: row => {
                    return (
                        <div>
                            {row.min_price}
                            {getCurrency()} - {row.max_price}
                            {getCurrency()}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='web_settings.fixed_price' />,
                key: 'fixed',
                render: row => {
                    return (
                        <Checkbox
                            checked={row.fixed_price}
                            onChange={() => {
                                row.fixed_price = !row.fixed_price;
                                this.setState({});
                            }}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='web_settings.price' />,
                key: 'price',
                render: row => {
                    return row.fixed_price ? (
                        <InputNumber
                            onBlur={async () => {
                                const { sessionId } = this.props;
                                await fetchAPI(
                                    'PUT',
                                    `rest/v1/services/${row.id}`,
                                    null,
                                    {
                                        categoryId: this.props.id,
                                        sessionId,
                                        service_id: row.service_id,
                                        description: row.description,
                                        hours: row.hours,
                                        price: row.price
                                    },
                                    { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
                                );
                                this.fetchData();
                            }}
                            onChange={price => {
                                row.price = price;
                                this.setState({});
                            }}
                            value={row.price}
                        />
                    ) : (
                        <div>
                            {row.min_price}
                            {getCurrency()} - {row.max_price}
                            {getCurrency()}
                        </div>
                    );
                }
            },
            {
                width: '5%',
                key: 'delete',
                align: 'center',
                render: row => {
                    return (
                        <Tooltip title={<FormattedMessage id='web_settings.delete_wash_service' />}>
                            <Popconfirm
                                getPopupContainer={trigger => trigger.parentNode}
                                onConfirm={async () => {
                                    const { sessionId } = this.props;
                                    await fetchAPI(
                                        'DELETE',
                                        `rest/v1/services/${row.id}`,
                                        null,
                                        { sessionId },
                                        {
                                            url: __OLD_APP_API_URL__
                                        }
                                    );
                                    this.fetchData();
                                }}
                                title={<FormattedMessage id='general_settings_delete_question' />}
                            >
                                <Button
                                    icon={<DeleteOutlined />}
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            </Popconfirm>
                        </Tooltip>
                    );
                }
            }
        ];
    }

    fetchData = async () => {
        const { sessionId, id } = this.props;
        const { allServices, list, name } = await fetchAPI(
            'GET',
            `rest/v1/services/categories/${id}`,
            { sessionId },
            null,
            {
                url: __OLD_APP_API_URL__
            }
        );
        this.setState({ allServices, list, name });
    };

    getId = () => {
        const { allServices, list } = this.state;
        let id;
        allServices.forEach(({ value }) => {
            const index = list.findIndex(({ service_id }) => service_id === value);
            if (index === -1) {
                id = value;
            }
        });

        return id;
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.fetchData();
        }
    }

    render() {
        const { visible, hideModal, id } = this.props;
        const { name, list } = this.state;
        const columns = this.columns();

        return (
            <Modal footer={null} onCancel={hideModal} title={name} visible={visible} width='90%'>
                <div
                    style={{
                        marginBottom: 8
                    }}
                >
                    <Button
                        onClick={async () => {
                            const { sessionId } = this.props;
                            await fetchAPI(
                                'POST',
                                'rest/v1/services/add',
                                null,
                                { sessionId, category_id: id, id: this.getId() },
                                {
                                    url: __OLD_APP_API_URL__
                                }
                            );
                            this.fetchData();
                        }}
                        style={{
                            marginRight: 8
                        }}
                    >
                        <FormattedMessage id='add' />
                    </Button>
                    <Button
                        onClick={async () => {
                            const { sessionId } = this.props;
                            await fetchAPI(
                                'POST',
                                'rest/v1/services/add-all',
                                null,
                                { sessionId, category_id: id },
                                {
                                    url: __OLD_APP_API_URL__
                                }
                            );
                            this.fetchData();
                        }}
                    >
                        <FormattedMessage id='web_settings.add_all' />
                    </Button>
                </div>
                <Table bordered columns={columns} dataSource={list} rowKey='id' />
            </Modal>
        );
    }
}
