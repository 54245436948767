/* eslint-disable complexity */
import { notification } from 'antd';
import { registerCashOrderInCashdesk } from 'core/cash/duck';
import { emitError } from 'core/ui/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { SEARCH_TYPES } from 'modals/RefactoredCashOrderModal/components/containers/ClientCounterpartyContainer/constants';
import {
    CASH_ORDER_TYPES,
    COUNTERPARTY_TYPES,
    DEF_BACK_DATE,
    SERVICE_OPERATION_TYPES
} from 'modals/RefactoredCashOrderModal/constants';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { saga as analyticsSaga } from './analytics/saga';
import { saga as cashboxesSaga } from './cashboxes/saga';
import { saga as clientsSaga } from './clients/saga';
import {
    CREATE_CASH_ORDER,
    FETCH_CASH_ORDER,
    PRINT_CASH_ORDER,
    UPDATE_ORDER,
    fetchCashOrderSuccess,
    selectCashOrderDate,
    selectCashOrderId,
    selectCashOrderSum,
    selectCashOrderType,
    selectCashboxes,
    selectClientSearchType,
    selectComment,
    selectOnCashOrderCreatedCallback,
    selectOnCashOrderUpdatedCallback,
    selectOtherCounterpartyName,
    selectSalary,
    selectSalaryDocId,
    selectSalaryDocNum,
    selectSelectedAnalyticsUniqueId,
    selectSelectedCashboxId,
    selectSelectedClientId,
    selectSelectedCounterpartyType,
    selectSelectedEmployeeId,
    selectSelectedOrderId,
    selectSelectedStoreDoc,
    selectSelectedStoreDocId,
    selectSelectedSupplierId,
    selectSelectedSupplierStoreDoc,
    selectSelectedSupplierStoreDocId,
    setCashOrderDate,
    setCashOrderSum,
    setCashOrderType,
    setClientSearchType,
    setComment,
    setFetchingCashOrder,
    setOtherCounterpartyName,
    setRegisteredRst,
    setResponsible,
    setSelectedAnalyticsUniqueId,
    setSelectedCashboxId,
    setSelectedClientId,
    setSelectedCounterpartyType,
    setSelectedEmployeeId,
    setSelectedOrderId,
    setSelectedStoreDocId,
    setSelectedSupplierId,
    setSelectedSupplierStoreDocId,
    setServiceOperationType
} from './duck';
import { saga as employeesSaga } from './employees/saga';
import { saga as ordersSaga } from './orders/saga';
import { saga as rstSaga } from './rst/saga';
import { saga as storeDocsSaga } from './storeDocs/saga';
import { saga as suppliersSaga } from './suppliers/saga';
import { saga as suppliersStoreDocsSaga } from './suppliersStoreDocs/saga';

const { INCOME, EXPENSE, ADJUSTMENT_INCOME, ADJUSTMENT_EXPENSE } = CASH_ORDER_TYPES;
const { CLIENT, EMPLOYEE, BUSINESS_SUPPLIER, OTHER } = COUNTERPARTY_TYPES;

/**
 * Creates payload object based on all data from redux.
 * This can be used to update or create cash order.
 *
 * @returns cash order(payload)
 */
export function* generateCashOrderPayloadWorker() {
    // Get selected cashbox to register in cashdesk later
    const selectedCashBoxId = yield select(selectSelectedCashboxId);
    const datetime = yield select(selectCashOrderDate);

    const clientId = yield select(selectSelectedClientId);
    const orderId = yield select(selectSelectedOrderId);
    const storeDocId = yield select(selectSelectedStoreDocId);
    const employeeId = yield select(selectSelectedEmployeeId);
    const supplierId = yield select(selectSelectedSupplierId);
    const supplierStoreDocId = yield select(selectSelectedSupplierStoreDocId);
    const otherCounterpartyName = yield select(selectOtherCounterpartyName);
    const clientSearchType = yield select(selectClientSearchType);
    const selectedStoreDoc = yield select(selectSelectedStoreDoc);
    const selectedSupplierStoreDoc = yield select(selectSelectedSupplierStoreDoc);

    const comment = yield select(selectComment);
    const salaryDocNum = yield select(selectSalaryDocNum);
    const salary = yield select(selectSalary);
    const salaryDocId = yield select(selectSalaryDocId);
    const analyticsUniqueId = yield select(selectSelectedAnalyticsUniqueId);
    const currentCounterpartyType = yield select(selectSelectedCounterpartyType);

    const selectedCashOrderType = yield select(selectCashOrderType);
    const sum = yield select(selectCashOrderSum);
    const cashOrderType =
        selectedCashOrderType === INCOME || selectedCashOrderType === EXPENSE ? selectedCashOrderType : 'ADJUSTMENT';

    // Depending on a modal type we have to set increase or decrease property
    let increase = null;
    let decrease = null;
    if (selectedCashOrderType === INCOME || selectedCashOrderType === ADJUSTMENT_INCOME) {
        increase = sum;
    } else {
        decrease = sum;
    }

    // Basic cash order entity
    const cashOrder = {
        type: cashOrderType,
        cashBoxId: selectedCashBoxId,
        description: comment,
        datetime,
        salaryDocNum,
        increase,
        decrease,
        salary,
        salaryDocId,
        analyticsUniqueId
    };

    // Handle generation depending on a current counterparty
    switch (currentCounterpartyType) {
        case CLIENT:
            if (clientSearchType === SEARCH_TYPES.FOR_CLIENT || clientSearchType === SEARCH_TYPES.FOR_ORDER) {
                cashOrder.clientId = clientId;
                cashOrder.orderId = orderId;
            } else if (clientSearchType === SEARCH_TYPES.FOR_DOCUMENT) {
                cashOrder.storeDocId = storeDocId;
                cashOrder.clientId = _.get(selectedStoreDoc, 'counterpartClientId');
            }
            break;
        case EMPLOYEE:
            cashOrder.employeeId = employeeId;
            break;
        case BUSINESS_SUPPLIER:
            cashOrder.businessSupplierId =
                supplierId || _.get(selectedSupplierStoreDoc, 'counterpartBusinessSupplierId');
            cashOrder.storeDocId = supplierStoreDocId;
            break;
        case OTHER:
            if (!otherCounterpartyName) {
                cashOrder.otherCounterparty = null; // Required for backend
            } else {
                cashOrder.otherCounterparty = otherCounterpartyName;
            }
            break;

        default:
            break;
    }

    return cashOrder;
}

/**
 * Generates payload and handles all logic for creation cash orders and registering in cashdesk if needed
 */
export function* createCashOrderSaga() {
    while (true) {
        try {
            yield take(CREATE_CASH_ORDER);

            const cashBoxes = yield select(selectCashboxes); // Get selected cashbox to register in cashdesk later
            const selectedCashBoxId = yield select(selectSelectedCashboxId);
            const cashBox = _.get(
                _.filter(cashBoxes, obj => obj.id === selectedCashBoxId),
                '[0]'
            );
            const isCashBoxRst = Boolean(_.get(cashBox, 'rst'));
            const callback = yield select(selectOnCashOrderCreatedCallback);

            const cashOrderPayload = yield call(generateCashOrderPayloadWorker);

            const response = yield call(fetchAPI, 'POST', 'cash_orders', null, cashOrderPayload, {
                handleErrorInternally: true
            });

            // If cashbox contains rst and cash order was created it must be registered in cashdesk if possible
            if (isCashBoxRst && cashOrderPayload.type !== 'EXPENSE') {
                yield put(registerCashOrderInCashdesk(response.id));
            }

            // eslint-disable-next-line callback-return
            callback && callback({ cashOrderId: response.id });

            notification.success({
                duration: 3,
                message: 'Успішно!'
            });
        } catch (error) {
            yield put(emitError(error));

            // Print special error message if it exists
            notification.error({ message: _.get(error, 'response.message') });
            notification.error({
                duration: 8,
                message:
                    'Якщо у Вас виникла помилка, будь ласка, зробіть скріншот(знімок екрана), настиснувши PrintScreen і повідомте в службу підтримки.'
            });
        }
    }
}

export function* updateOrderSaga() {
    while (true) {
        try {
            yield take(UPDATE_ORDER);

            const cashOrderId = yield select(selectCashOrderId);
            const callback = yield select(selectOnCashOrderUpdatedCallback);

            const cashOrderDate = yield select(selectCashOrderDate);
            const analyticsUniqueId = yield select(selectSelectedAnalyticsUniqueId);
            const selectedCashOrderType = yield select(selectCashOrderType);
            const sum = yield select(selectCashOrderSum);

            // Depending on a modal type we have to set increase or decrease property
            let increase = null;
            let decrease = null;
            if (selectedCashOrderType === INCOME || selectedCashOrderType === ADJUSTMENT_INCOME) {
                increase = sum;
            } else {
                decrease = sum;
            }
            const description = yield select(selectComment);

            const cashOrderPayload = yield call(generateCashOrderPayloadWorker);

            yield call(
                fetchAPI,
                'PUT',
                `cash_orders/${cashOrderId}`,
                null,
                {
                    ...cashOrderPayload,
                    datetime: cashOrderDate,
                    analyticsUniqueId,
                    increase,
                    decrease,
                    description
                },
                { handleErrorInternally: true }
            );

            // eslint-disable-next-line callback-return
            callback && callback({ cashOrderId });

            notification.success({
                duration: 3,
                message: 'Успішно!'
            });
        } catch (error) {
            yield put(emitError(error));

            // Print special error message if it exists
            notification.error({ message: _.get(error, 'response.message') });
            notification.error({
                message:
                    'Якщо у Вас виникла помилка, будь ласка, зробіть скріншот(знімок екрана), настиснувши PrintScreen і повідомте в службу підтримки.'
            });
        }
    }
}

/**
 * Fetch cash order and initialize all fields based on fetched data. Used in EDIT and PRINT modes.
 */
export function* fetchCashOrderSaga() {
    while (true) {
        try {
            yield take(FETCH_CASH_ORDER);

            const cashOrderId = yield select(selectCashOrderId);

            yield put(setFetchingCashOrder(true));

            const response = yield call(fetchAPI, 'GET', `cash_orders/${cashOrderId}`, null, null, {handleErrorInternally: true});
            const {
                type,
                increase,
                decrease,
                datetime,
                cashBoxId,
                description,

                // Counterparts
                clientId,
                orderId,

                employeeId,

                businessSupplierId,
                storeDocId,

                otherCounterparty,
                analyticsUniqueId,
                responsible,
                isRegisteredWithRst
            } = response;

            const cashBoxes = yield call(fetchAPI, 'GET', 'cash_boxes');
            const cashBox = _.get(
                _.filter(cashBoxes, obj => obj.id == cashBoxId),
                '[0]'
            ); // Cashbox if this cash order
            const isCashBoxRst = Boolean(_.get(cashBox, 'rst'));

            yield put(setCashOrderDate(dayjs(datetime).format(DEF_BACK_DATE)));
            yield put(setSelectedCashboxId(cashBoxId));
            yield put(setSelectedAnalyticsUniqueId(analyticsUniqueId));
            yield put(setResponsible(responsible));
            yield put(setRegisteredRst(isRegisteredWithRst));

            // Cash order type and parameters depending on it
            if (type === 'INCOME') {
                yield put(setCashOrderType(CASH_ORDER_TYPES.INCOME));
                yield put(setCashOrderSum(increase));

                // If cash box was with rst, it is service input
                if (isCashBoxRst) {
                    yield put(setServiceOperationType(SERVICE_OPERATION_TYPES.SERVICE_INPUT));
                    yield put(setServiceOperationType(SERVICE_OPERATION_TYPES.TRANSFER));
                }
            } else if (type === 'EXPENSE') {
                yield put(setCashOrderType(CASH_ORDER_TYPES.EXPENSE));
                yield put(setCashOrderSum(decrease));

                // If cash box was with rst, it is service output
                if (isCashBoxRst) {
                    yield put(setServiceOperationType(SERVICE_OPERATION_TYPES.SERVICE_OUTPUT));
                    yield put(setServiceOperationType(SERVICE_OPERATION_TYPES.TRANSFER));
                }
            } else if (increase) {
                yield put(setCashOrderType(CASH_ORDER_TYPES.ADJUSTMENT_INCOME));
                yield put(setCashOrderSum(increase));
            } else {
                yield put(setCashOrderType(CASH_ORDER_TYPES.ADJUSTMENT_EXPENSE));
                yield put(setCashOrderSum(decrease));
            }

            // Set counterparty type and parameters depending on it
            if (clientId) {
                yield put(setSelectedCounterpartyType(COUNTERPARTY_TYPES.CLIENT));
                yield put(setSelectedClientId(clientId));
                if (orderId) {
                    // Order is not required
                    yield put(setSelectedOrderId(orderId));
                    yield put(setClientSearchType(SEARCH_TYPES.FOR_ORDER));
                }
                if (storeDocId) {
                    // Store doc is not required
                    yield put(setSelectedStoreDocId(storeDocId));
                    yield put(setClientSearchType(SEARCH_TYPES.FOR_DOCUMENT));
                }
            } else if (employeeId) {
                yield put(setSelectedCounterpartyType(COUNTERPARTY_TYPES.EMPLOYEE));
                yield put(setSelectedEmployeeId(employeeId));
            } else if (businessSupplierId) {
                yield put(setSelectedCounterpartyType(COUNTERPARTY_TYPES.BUSINESS_SUPPLIER));
                yield put(setSelectedSupplierId(businessSupplierId));
                if (storeDocId) {
                    yield put(setSelectedSupplierStoreDocId(storeDocId));
                } // Store document is not required
            } else {
                yield put(setSelectedCounterpartyType(COUNTERPARTY_TYPES.OTHER));
                yield put(setOtherCounterpartyName(otherCounterparty));
            }

            if (description) {
                yield put(setComment(description));
            }

            yield put(fetchCashOrderSuccess({ cashOrder: response }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingCashOrder(false));
        }
    }
}

/**
 * Save cash order in form of pdf
 */
export function* printCashOrderSaga() {
    while (true) {
        try {
            yield take(PRINT_CASH_ORDER);

            const cashOrderId = yield select(selectCashOrderId);

            if (!cashOrderId) {
                notification.error({ message: 'No cash order id provided' });
                continue;
            }

            const response = yield call(fetchAPI, 'GET', `cash_orders/${cashOrderId}/pdf`, null, null, {
                rawResponse: true
            });
            const reportFile = yield response.blob();

            const contentDispositionHeader = response.headers.get('content-disposition');
            const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
            yield saveAs(reportFile, fileName);
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* saga() {
    yield all([
        call(createCashOrderSaga),
        call(updateOrderSaga),
        call(fetchCashOrderSaga),
        call(printCashOrderSaga),

        suppliersStoreDocsSaga(),
        suppliersSaga(),
        employeesSaga(),
        ordersSaga(),
        clientsSaga(),
        cashboxesSaga(),
        analyticsSaga(),
        storeDocsSaga(),
        rstSaga()
    ]);
}
