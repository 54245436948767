import { DeleteOutlined, EditOutlined, FileOutlined } from '@ant-design/icons';
import { Button, Input, Select, Switch, Tooltip } from 'antd';
import { Barcode } from 'components';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { accesses, grants, isGrantAccessed } from 'utils/grants';

const { Option } = Select;

export default props => {
    const editable = isGrantAccessed(props.user, grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD, accesses.ROWO);

    const handleQuery = _.debounce((key, value) => {
        props.setStoreProductsFilters({ [key]: value.replace(/[+()]/g, '') });
    }, 1000);

    const codeCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='storage.product_code' />
                    <Input
                        defaultValue={props.lacationSearchValue}
                        onChange={e => {
                            handleQuery('query', _.get(e, 'target.value').replace(/[^A-Za-z0-9\u0400-\u04FF]/gm, ''));
                        }} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        key: 'code',
        dataIndex: 'partNumber',
        // sorter: (a, b) => a.partNumber.localeCompare(b.partNumber),
        // sortDirections : [ 'descend', 'ascend' ],
        render: (data, elem) => {
            return (
                <div>
                    {isGrantAccessed(props.user, grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD) ? (
                        <Link
                            to={{
                                pathname: `${book.product}/${elem.id}`,
                                state: {
                                    specificTab: 'general'
                                }
                            }}
                        >
                            <div style={{ fontWeight: 'bold' }}>{elem.code}</div>
                        </Link>
                    ) : (
                        <div>{elem.code}</div>
                    )}
                    <div>{elem.name}</div>
                </div>
            );
        }
    };

    const code = {
        title: props.intl.formatMessage({
            id: 'storage.product_code'
        }),
        dataIndex: 'code',
        width: 'auto',
        render: (key, { id, name, code }) =>
            isGrantAccessed(props.user, grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD) ? (
                <Link to={`${book.product}/${id}`}>{code}</Link>
            ) : (
                <div>{code}</div>
            )
        // render:    (key, { id, name, code }) => (
        //     <div
        //         onClick={ () =>
        //             props.setModal(MODALS.STORE_PRODUCT, {
        //                 id,
        //                 name,
        //                 code,
        //                 editing: true,
        //             })
        //         }
        //     >
        //         { code }
        //     </div>
        // ),
    };

    const brandName = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='storage.brand' />
                    <Input
                        onChange={e => {
                            handleQuery('productBrand', _.get(e, 'target.value'));
                        }} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        dataIndex: 'brand',
        width: 'auto',
        render: (key, data) => {
            if (data.brandName) {
                return data.brandName;
            }

            return _.get(data, 'brand.name', '');
        }
    };

    const markup = {
        title: props.intl.formatMessage({
            id: 'storage.markup'
        }),
        dataIndex: 'priceGroup',
        width: 'auto',
        render: (key, data) => <div>{_.get(data, 'priceGroup.multiplier')}</div>
    };

    const purchasePriceCol = {
        title: <FormattedMessage id='order_form_table.purchasePrice' />,
        key: 'purchasePrice',
        dataIndex: 'purchasePrice',
        className: 'columnAlignedRight',
        render: data => {
            const strVal = String(Math.round(data * 10) / 10);

            return data ? (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {Number(strVal)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </span>
            ) : (
                <FormattedMessage id='long_dash' />
            );
        }
    };

    const priceCol = {
        title: (
            <div>
                <FormattedMessage id='order_form_table.price' />
                <p
                    style={{
                        color: 'var(--text2)',
                        fontSize: 12,
                        fontWeight: 400
                    }}
                >
                    <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                </p>
            </div>
        ),
        key: 'sellingPrice',
        dataIndex: 'sellingPrice',
        className: 'columnAlignedRight',
        // sorter: (a, b) => {
        //     if (!this.state.inStock) {
        //         this.setState({
        //             inStock: true,
        //         })
        //     }
        //     if (!b.salePrice) return -1;
        //     return Number(a.salePrice) - Number(b.salePrice);
        // },
        // sortDirections: [ 'descend', 'ascend' ],
        render: (data, elem) => {
            const strVal = String(Math.round(data * 10) / 10);

            return data ? (
                isGrantAccessed(props.user, grants.DIRECTORIES_PRODUCTS_LIST_PRICES) ? (
                    <Link
                        to={{
                            pathname: `${book.product}/${elem.id}`,
                            state: {
                                specificTab: 'price'
                            }
                        }}
                    >
                        {Number(strVal)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    </Link>
                ) : (
                    <div>
                        {Number(strVal)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    </div>
                )
            ) : (
                <FormattedMessage id='long_dash' />
            );
        }
    };

    const unitsCol = {
        title: <FormattedMessage id='services_table.units' />,
        key: 'unit',
        dataIndex: 'unit',
        className: 'columnAlignedRight',
        render: data => {
            return data ? <span style={{ whiteSpace: 'nowrap' }}>{data}</span> : <FormattedMessage id='long_dash' />;
        }
    };

    const editAction = {
        width: 'auto',
        dataIndex: 'edit',
        render: (key, { id, name, code }) => {
            return (
                <Link to={`${book.product}/${id}`}>
                    <EditOutlined
                        style={{
                            color: 'var(--green)',
                            fontSize: 24,
                            verticalAlign: 'baseline'
                        }}
                    />
                </Link>
            );
        }
    };

    const isAvailableCol = {
        title: () => {
            return (
                <div>
                    <span>
                        <FormattedMessage id='storage.in_stock' /> / <FormattedMessage id='storage.available' />
                    </span>
                </div>
            );
        },
        key: 'store',
        dataIndex: 'store',
        className: 'columnAlignedCenter',
        render: (store, elem) => {
            return isGrantAccessed(props.user, grants.DIRECTORIES_PRODUCTS_LIST_PRICES) ? (
                <Link
                    to={{
                        pathname: `${book.product}/${elem.id}`,
                        state: {
                            specificTab: 'cell'
                        }
                    }}
                >
                    <span
                        style={{
                            color: 'var(--primary)',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}
                    >
                        {elem.countInWarehouses} / {elem.available}
                    </span>
                </Link>
            ) : (
                <span
                    style={{
                        color: 'var(--primary)',
                        textDecoration: 'underline',
                        cursor: 'pointer'
                    }}
                >
                    {elem.countInWarehouses} / {elem.available}
                </span>
            );
        }
    };

    const enableAction = {
        title: () => <FormattedMessage id='product.visibility' />,
        render: data => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Switch
                    checked={data.enabled}
                    disabled={!(data.countInWarehouses === 0 && data.available === 0)}
                    onChange={newValue => props.handleGroupChange([data], { enabled: newValue })}
                    // onChange={newValue => props.handleGroupChange([data], { enabled: true })}
                />
            </div>
        )
    };

    const deleteAction = {
        width: 'auto',
        dataIndex: 'delete',
        render: (key, { id, name, code }) => {
            const message = props.intl.formatMessage({ id: 'сan not be deleted' });

            return (
                <Button
                    onClick={() => props.deleteProduct(id, message)}
                    style={{
                        padding: '0px 8px',
                        fontSize: 18
                    }}
                >
                    <DeleteOutlined />
                </Button>
            );
        }
    };

    const downloadReport = {
        width: '5%',
        render: row => {
            const reportType =
                row.source === 'PRODUCTION'
                    ? 'productionReport'
                    : row.source === 'DISASSEMBLY'
                    ? 'disassemblyReport'
                    : null;

            return (
                <Tooltip
                    getPopupContainer={trigger => trigger.parentNode}
                    title={
                        <div>
                            <div>
                                <FormattedMessage id='complexes.product_report_production' />/
                            </div>
                            <div>
                                <FormattedMessage id='complexes.product_report__disassembly' />
                            </div>
                        </div>
                    }
                >
                    <Button
                        disabled={row.source === 'OWN'}
                        icon={<FileOutlined />}
                        onClick={() => props.downloadProductReport(row.id, reportType)}
                    />
                </Tooltip>
            );
        }
    };

    const barcode = {
        width: 'auto',
        dataIndex: 'barcode',
        render: data =>
            data && (
                <Barcode
                    iconStyle={{
                        fontSize: 24
                    }}
                    value={data}
                />
            )
    };

    if (editable) {
        return [
            // code,
            codeCol,
            // name,
            brandName,
            // markup,
            // editAction,
            purchasePriceCol,
            priceCol,
            isAvailableCol,
            unitsCol,
            enableAction,
            deleteAction,
            downloadReport
            // barcode,
        ];
    }

    return [
        // code,
        codeCol,
        // name,
        brandName,
        // markup,
        // editAction,
        purchasePriceCol,
        priceCol,
        isAvailableCol,
        unitsCol,
        deleteAction,
        downloadReport
        // barcode,
    ];
};
