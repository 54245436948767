// Core
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { InsertRowAboveOutlined, SolutionOutlined, TransactionOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Checkbox, Table, Tabs, Tooltip, notification } from 'antd';
import { Layout, Spinner, Subscriptions } from 'commons';
import { resetModal, setModal } from 'core/modals/duck';
import { ProfileForm } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { DeliveryTruckIcon } from 'theme';
import {
    fetchAPI,
    getHeaderIcons,
    getOrderIcons,
    isForbidden,
    permissions,
    setHeaderIcons,
    setOrderIcons
} from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
    modal: state.modals.modal,
    profileUpdating: state.ui.profileUpdating,
    user: state.auth,
    header: state.subscription.header,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setModal,
    resetModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            icons: [
                {
                    icon: 'bell',
                    legacy: true,
                    tooltip: 'navigation.tasks',
                    checked: false,
                    key: 'TASKS',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_EMPLOYEE_TASKS)
                },
                {
                    icon: 'file-add',
                    legacy: true,
                    tooltip: 'navigation.new_document',
                    checked: false,
                    key: 'FAST_NAVIGATION',
                    enabled: !isForbidden(this.props.user, permissions.NEW_DOCUMENT)
                },
                {
                    icon: 'barcode',
                    legacy: true,
                    tooltip: 'navigation.barcode',
                    checked: false,
                    key: 'BARCODE',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_STORE_PRODUCT_BARCODE)
                },
                {
                    icon: 'schedule',
                    legacy: true,
                    tooltip: 'navigation.planner',
                    checked: false,
                    key: 'SCHEDULE',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_DASHBOARD)
                },
                {
                    icon: 'tool',
                    legacy: true,
                    tooltip: 'navigation.workflow',
                    checked: false,
                    key: 'ORDERS',
                    enabled: !isForbidden(this.props.user, permissions.SHOW_ORDERS)
                },
                {
                    icon: 'setting',
                    legacy: true,
                    tooltip: 'profile.spare_parts_workplace.title',
                    checked: false,
                    key: 'ORDERS_DETAILS',
                    enabled: true
                },
                {
                    icon: 'contacts',
                    legacy: true,
                    tooltip: 'navigation.directories',
                    checked: false,
                    key: 'CONTACTS',
                    enabled: true
                },
                {
                    icon: 'control',
                    legacy: true,
                    tooltip: 'navigation.other_settings',
                    checked: false,
                    key: 'OTHER_SETTINGS',
                    enabled: true
                },
                {
                    icon: 'bar-chart',
                    legacy: true,
                    tooltip: 'navigation.reports',
                    checked: false,
                    key: 'REPORTS',
                    enabled: true
                },
                {
                    icon: 'dollar',
                    legacy: true,
                    tooltip: 'navigation.cash_bank',
                    checked: false,
                    key: 'CASH_BOX',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_FINANCE_CASH)
                },
                {
                    icon: 'appstore',
                    legacy: true,
                    tooltip: 'navigation.locations',
                    checked: false,
                    key: 'APPSTORE',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_LOCATIONS)
                },
                {
                    icon: 'database',
                    legacy: true,
                    tooltip: 'navigation.products',
                    checked: false,
                    key: 'DATABASE',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_STORE_PRODUCTS)
                },
                {
                    icon: 'car',
                    legacy: true,
                    tooltip: 'navigation.vehicles',
                    checked: false,
                    key: 'CAR',
                    enabled: !isForbidden(this.props.user, permissions.GET_CLIENTS)
                },
                {
                    icon: 'idcard',
                    legacy: true,
                    tooltip: 'navigation.employees',
                    checked: false,
                    key: 'IDCARD',
                    enabled: !isForbidden(this.props.user, permissions.GET_EMPLOYEES)
                },
                {
                    icon: <DeliveryTruckIcon />,
                    legacy: false,
                    tooltip: 'navigation.suppliers',
                    checked: false,
                    key: 'ROCKET',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_SUPPLIERS)
                },
                {
                    icon: 'hourglass',
                    legacy: true,
                    tooltip: 'navigation.labors_page',
                    checked: false,
                    key: 'HOURGLASS',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_LABOR_CATALOGUE)
                },
                {
                    icon: 'credit-card',
                    legacy: true,
                    tooltip: 'navigation.flow_of_money',
                    checked: false,
                    key: 'CREDIT-CARD',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_ACCOUNTING)
                },
                {
                    icon: 'printer',
                    legacy: true,
                    tooltip: 'navigation.report_cash_orders_logs',
                    checked: false,
                    key: 'PRINTER',
                    enabled: isGrantAccessed(
                        this.props.user,
                        grants.REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_REGISTER_DEVICES
                    )
                },
                {
                    icon: 'issues-close',
                    legacy: true,
                    tooltip: 'navigation.receivables_and_payables',
                    checked: false,
                    key: 'ISSUES-CLOSE',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_FINANCE_RECEIVABLE)
                },
                {
                    icon: 'ordered-list',
                    legacy: true,
                    tooltip: 'navigation.storage_balance',
                    checked: false,
                    key: 'ORDERED-LIST',
                    enabled: !isForbidden(this.props.user, permissions.VIEW_STORE)
                },
                {
                    icon: 'upload',
                    legacy: true,
                    tooltip: 'navigation.orders',
                    checked: false,
                    key: 'UPLOAD',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_SUPPLIER_ORDER_STORE_DOCS)
                },
                {
                    icon: 'login',
                    legacy: true,
                    tooltip: 'navigation.incomes',
                    checked: false,
                    key: 'LOGIN',
                    enabled: !isForbidden(this.props.user, permissions.VIEW_STORE)
                },
                {
                    icon: 'logout',
                    legacy: true,
                    tooltip: 'navigation.expenses',
                    checked: false,
                    key: 'LOGOUT',
                    enabled: !isForbidden(this.props.user, permissions.VIEW_STORE)
                },
                {
                    icon: 'table',
                    legacy: true,
                    tooltip: 'navigation.wms',
                    checked: false,
                    key: 'TABLE',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_WMS)
                },
                {
                    icon: 'area-chart',
                    legacy: true,
                    tooltip: 'navigation.report_orders',
                    checked: false,
                    key: 'AREA-CHART',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_REPORT_PROFIT_FROM_ORDERS)
                },
                {
                    icon: 'phone',
                    legacy: true,
                    tooltip: 'navigation.call_statistics',
                    checked: false,
                    key: 'PHONE',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_CALL_STATISTICS)
                },
                {
                    icon: 'like',
                    legacy: true,
                    tooltip: 'navigation.feedback',
                    checked: false,
                    key: 'LIKE',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_FEEDBACK)
                },
                {
                    icon: 'fund',
                    legacy: true,
                    tooltip: 'navigation.report_cash_flow',
                    checked: false,
                    key: 'FUND',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_REPORT_PROFIT_FROM_BUSINESSES)
                },
                {
                    icon: <InsertRowAboveOutlined />,
                    legacy: false,
                    tooltip: 'navigation.journals',
                    checked: false,
                    key: 'JOURNALS',
                    enabled: true
                },
                {
                    icon: <UsergroupAddOutlined />,
                    legacy: false,
                    tooltip: 'navigation.interactions',
                    checked: false,
                    key: 'CRM',
                    enabled: true
                },
                {
                    icon: <SolutionOutlined />,
                    legacy: false,
                    tooltip: 'navigation.clients',
                    checked: false,
                    key: 'CLIENTS',
                    enabled: true
                },
                {
                    icon: <TransactionOutlined />,
                    link: book.exchangeRate,
                    tooltip: 'navigation.currency',
                    enabled: user => true,
                    key: 'CURRENCY'
                }
            ],
            tabs: [
                {
                    icon: 'bell',
                    legacy: true,
                    checked: false,
                    tooltip: 'add_order_form.services',
                    key: 'LABORS',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_EMPLOYEE_TASKS)
                },
                {
                    icon: 'file-add',
                    legacy: true,
                    checked: false,
                    tooltip: 'add_order_form.details',
                    key: 'PARTS',
                    enabled: !isForbidden(this.props.user, permissions.NEW_DOCUMENT)
                },
                {
                    icon: 'barcode',
                    legacy: true,
                    checked: false,
                    tooltip: 'order_form_table.history',
                    key: 'VEHICLE_HISTORY',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_STORE_PRODUCT_BARCODE)
                },
                {
                    icon: 'schedule',
                    legacy: true,
                    tooltip: 'order_form_table.order_tasks',
                    checked: false,
                    key: 'TASKS',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_DASHBOARD)
                },
                {
                    icon: 'tool',
                    legacy: true,
                    tooltip: 'order_tabs.map',
                    checked: false,
                    key: 'MAP',
                    enabled: !isForbidden(this.props.user, permissions.SHOW_ORDERS)
                },
                {
                    icon: 'setting',
                    legacy: true,
                    tooltip: 'add_order_form.request',
                    checked: false,
                    key: 'ORDER',
                    enabled: true
                },
                {
                    icon: 'contacts',
                    legacy: true,
                    tooltip: 'add_order_form.intake_tab',
                    checked: false,
                    key: 'REVIEW',
                    enabled: true
                },
                {
                    icon: 'bar-chart',
                    legacy: true,
                    tooltip: 'order_form_table.station',
                    checked: false,
                    key: 'POSTS',
                    enabled: true
                },
                {
                    icon: 'dollar',
                    legacy: true,
                    tooltip: 'order_form_table.diagnostic',
                    checked: false,
                    key: 'DIAGNOSTICS',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_FINANCE_CASH)
                },
                {
                    icon: 'appstore',
                    legacy: true,
                    tooltip: 'order_tabs.workshop',
                    checked: false,
                    key: 'WORKSHOP',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_LOCATIONS)
                },
                {
                    icon: 'bell',
                    legacy: true,
                    tooltip: 'order_form_table.recommendation_for_icons',
                    checked: false,
                    key: 'RECOMMENDATIONS',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_EMPLOYEE_TASKS)
                },
                {
                    icon: 'file-add',
                    legacy: true,
                    tooltip: 'add_order_form.respond',
                    checked: false,
                    key: 'OVERLOOK',
                    enabled: !isForbidden(this.props.user, permissions.NEW_DOCUMENT)
                },
                {
                    icon: 'barcode',
                    legacy: true,
                    tooltip: 'add_order_form.comments',
                    checked: false,
                    key: 'COMMENTS',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_STORE_PRODUCT_BARCODE)
                },
                {
                    icon: 'schedule',
                    legacy: true,
                    tooltip: 'add_order_form.logs',
                    checked: false,
                    key: 'ORDERS_LOGS',
                    enabled: !isForbidden(this.props.user, permissions.ACCESS_DASHBOARD)
                },
                {
                    icon: 'tool',
                    legacy: true,
                    tooltip: 'order_form_table.calls',
                    checked: false,
                    key: 'CALLS',
                    enabled: !isForbidden(this.props.user, permissions.SHOW_ORDERS)
                },
                {
                    icon: 'setting',
                    legacy: true,
                    tooltip: 'add_order_form.docs',
                    checked: false,
                    key: 'DOCUMENTS',
                    enabled: true
                },
                {
                    icon: 'contacts',
                    legacy: true,
                    tooltip: 'add_order_form.statistics',
                    checked: false,
                    key: 'STATISTICS',
                    enabled: true
                },
                {
                    icon: 'contacts',
                    legacy: true,
                    tooltip: 'add_order_form.regulations',
                    checked: false,
                    key: 'REGULATIONS',
                    enabled: true
                }
            ]
        };

        this.columns = () => [
            {
                title: <FormattedMessage id='name' />,
                dataIndex: 'tooltip',
                key: 'name',
                render: (_, row) => {
                    return (
                        <div>
                            {row.legacy ? <LegacyIcon type={row.icon} /> : row.icon}
                            <span style={{ marginLeft: 8 }}>
                                <FormattedMessage id={row.tooltip} />
                            </span>
                        </div>
                    );
                }
            },
            {
                dataIndex: 'checked',
                key: 'checked',
                render: (_, row) => {
                    return (
                        <Checkbox
                            checked={row.checked}
                            onChange={e => {
                                if (!row.checked) {
                                    let checkedCount = 0;
                                    this.state.icons.map(icon => {
                                        if (icon.checked) {
                                            checkedCount += 1;
                                        }
                                    });
                                    if (checkedCount > 9) {
                                        notification.error({
                                            message: this.props.intl.formatMessage({
                                                id: 'profile.icons_count_error'
                                            })
                                        });

                                        return;
                                    }
                                }
                                row.checked = !row.checked;
                                this.setState({});
                            }}
                        />
                    );
                }
            }
        ];

        this.tab_colums = () => [
            {
                title: <FormattedMessage id='tab' />,
                dataIndex: 'tooltip',
                key: 'name',
                render: (_, row) => {
                    return (
                        <div>
                            <span style={{ marginLeft: 8 }}>
                                <FormattedMessage id={row.tooltip} />
                            </span>
                        </div>
                    );
                }
            },
            {
                dataIndex: 'checked',
                key: 'checked',
                render: (_, row) => {
                    return (
                        <Checkbox
                            checked={row.checked}
                            disabled={
                                !isGrantAccessed(
                                    this.props.user,
                                    grants.DIRECTORIES_USER_SETTINGS_ORDER_TABS,
                                    accesses.ROWO
                                )
                            }
                            onChange={e => {
                                if (!row.checked) {
                                    let checkedCount = 0;
                                    this.state.tabs.map(tab => {
                                        if (tab.checked) {
                                            checkedCount += 1;
                                        }
                                    });
                                    if (checkedCount > 20) {
                                        notification.error({
                                            message: this.props.intl.formatMessage({
                                                id: 'profile.icons_count_error'
                                            })
                                        });

                                        return;
                                    }
                                }
                                row.checked = !row.checked;
                                this.setState({});
                            }}
                        />
                    );
                }
            }
        ];
    }

    componentDidMount() {
        const userIcons = getHeaderIcons();
        this.state.icons.map(icon => {
            if (userIcons.includes(icon.key)) {
                icon.checked = true;
            }
        });
        this.setState({});
        const tabsIcons = getOrderIcons();
        this.state.tabs.map(tab => {
            if (tabsIcons.includes(tab.key)) {
                tab.checked = true;
            }
        });
        this.setState({});
    }

    render() {
        const {
            profileUpdating,
            header,
            user,
            isMobile,
            intl: { formatMessage }
        } = this.props;
        const { icons, tabs } = this.state;

        const { employeeId, businesses, businessId } = user;

        return profileUpdating ? (
            <Spinner spin={profileUpdating} />
        ) : (
            <Layout
                controls={
                    <React.Fragment>
                        <Subscriptions packages={header.rolePackage} suggestions={header.suggestionGroup} />
                        {!isMobile &&
                            isGrantAccessed(user, grants.DIRECTORIES_USER_SETTINGS) &&
                            isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD) &&
                            businesses.includes(businessId) &&
                            employeeId && (
                                <Link className={Styles.linkToEmployee} to={`${book.employeesPage}/${user.employeeId}`}>
                                    <Tooltip
                                        placement='bottom'
                                        title={<FormattedMessage id='profile.to_the_emplyee_card' />}
                                    >
                                        <Button
                                            className={Styles.toEmployeeButton}
                                            icon={<SolutionOutlined />}
                                            size='large'
                                        />
                                    </Tooltip>
                                </Link>
                            )}
                    </React.Fragment>
                }
                description={<FormattedMessage id='profile.description' />}
                title={<FormattedMessage id='profile.title' />}
            >
                <Tabs>
                    {isGrantAccessed(user, grants.DIRECTORIES_USER_SETTINGS_GENERAL) && (
                        <TabPane key='settings' tab={<FormattedMessage id='profile.settings' />}>
                            <ProfileForm />
                        </TabPane>
                    )}

                    {isGrantAccessed(user, grants.DIRECTORIES_USER_SETTINGS_ICONS) && (
                        <TabPane key='icons' tab={<FormattedMessage id='profile.icons' />}>
                            <Table
                                bordered
                                columns={this.columns()}
                                dataSource={icons}
                                pagination={false}
                                rowClassName={record => !record.enabled && Styles.disabledRow}
                                size='small'
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: 8
                                }}
                            >
                                <Button
                                    onClick={async () => {
                                        await fetchAPI(
                                            'PUT',
                                            `employees/${user.employeeId}`,
                                            undefined,
                                            {
                                                navigationIcons: icons
                                                    .filter(({ checked }) => checked)
                                                    .map(({ key }) => key)
                                            },
                                            { handleErrorInternally: true }
                                        );
                                        await setHeaderIcons(
                                            icons.filter(({ checked }) => checked).map(({ key }) => key)
                                        );
                                        await window.location.reload();
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='save' />
                                </Button>
                            </div>
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.DIRECTORIES_USER_SETTINGS_ORDER_TABS) && (
                        <TabPane key='tabs' tab={<FormattedMessage id='profile.tabs' />}>
                            <Table
                                bordered
                                columns={this.tab_colums()}
                                dataSource={tabs}
                                pagination={false}
                                rowClassName={record => !record.enabled && Styles.disabledRow}
                                size='small'
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: 8
                                }}
                            >
                                <Button
                                    disabled={
                                        !isGrantAccessed(
                                            user,
                                            grants.DIRECTORIES_USER_SETTINGS_ORDER_TABS,
                                            accesses.ROWO
                                        )
                                    }
                                    onClick={async () => {
                                        await fetchAPI(
                                            'PUT',
                                            `employees/${user.employeeId}`,
                                            undefined,
                                            {
                                                ordersIcons: tabs.filter(({ checked }) => checked).map(({ key }) => key)
                                            },
                                            { handleErrorInternally: true }
                                        );
                                        await setOrderIcons(
                                            tabs.filter(({ checked }) => checked).map(({ key }) => key)
                                        );
                                        await window.location.reload();
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='save' />
                                </Button>
                            </div>
                        </TabPane>
                    )}
                </Tabs>
            </Layout>
        );
    }
}
