import { FilterFilled, InfoOutlined, PlusOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Select, Table, Tag, Tooltip } from 'antd';
import { Layout, Numeral } from 'commons';
import { DateRangePicker, DebtsPairComponent } from 'components';
import { fetchBusinessRequisites, selectBusinessRequisites } from 'modals/AccountsReceivablesReportModal/redux/duck';

import { fetchWarehouses } from 'core/warehouses/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { DebtPairResultsModal } from 'modals';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import styled from 'styled-components';
import { fetchAPI, journalTypes } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import { AddRowModal } from '../StorageJournalPage/modals';
import Styles from './styles.m.css';

const dateFormat = 'DD.MM.YYYY';

const mapStateToProps = state => ({
    user: state.auth,
    warehouses: state.warehouses.warehouses,
    businessRequisites: selectBusinessRequisites(state)
});

const { Option } = Select;

const mapDispatchToProps = {
    fetchBusinessRequisites,
    fetchWarehouses
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class StorageCalculationsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            page: 1,
            totalCount: 0,
            pageSize: 25,
            pairSelectedIds: [],
            clientRequisites: [],
            supplierRequisites: [],
            labors: []
        };

        this.columns = () => [
            {
                key: 'pairCol',
                width: 150,
                render: row => (
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <DebtsPairComponent
                            createPair={this.createPair}
                            disabled={
                                !isGrantAccessed(
                                    this.props.user,
                                    grants.ACCOUNTING_SETTLEMENT_JOURNAL_PAIRING_AND_UNPAIRING
                                )
                            }
                            id={row.id}
                            onPair={({ id, sign, cashOrderId, counterpartId }) => {
                                if (this.state.pairSelectedIds.includes(id)) {
                                    this.setState({
                                        pairSelectedIds: this.state.pairSelectedIds.filter(el => el !== id),
                                        pairSign:
                                            this.state.pairSelectedIds.length > 1 ? this.state.pairSign : undefined,
                                        pairCounterpartId:
                                            this.state.pairSelectedIds.length > 1
                                                ? this.state.pairCounterpartId
                                                : undefined,
                                        singleType:
                                            this.state.pairSelectedIds.length > 1 ? this.state.singleType : undefined
                                    });
                                } else {
                                    this.state.pairSelectedIds.push(id);
                                    this.setState({
                                        pairSelectedIds: this.state.pairSelectedIds,
                                        pairSign: this.state.pairSign || sign,
                                        pairCounterpartId: counterpartId,
                                        singleType: this.state.singleType || cashOrderId ? 'CASH' : 'ORDER'
                                    });
                                }
                            }}
                            onUnpair={this.deletePair}
                            pairCounterpartId={this.state.pairCounterpartId}
                            pairSelectedIds={this.state.pairSelectedIds}
                            pairSign={this.state.pairSign}
                            remainSum={row.remaining}
                            singleType={this.state.singleType}
                            {...row}
                        />
                        <Tooltip title={<FormattedMessage id='navigation.paired_documents' />}>
                            <Button
                                icon={<InfoOutlined />}
                                onClick={() => {
                                    this.setState({ visibleDebtPairResultsModal: row });
                                }}
                                size='large'
                                style={{
                                    marginLeft: 8
                                }}
                            />
                        </Tooltip>
                    </div>
                )
            },
            {
                title: <FormattedMessage id='storage_journal.document_date' />,
                key: 'date',
                dataIndex: 'docDateTime',
                align: 'center',
                width: 150,
                ...this.getColumnSearchProps('docDateTime'),
                render: data => {
                    return data ? dayjs(data).format('DD.MM.YYYY HH:mm') : dayjs().format('DD.MM.YYYY HH:mm');
                }
            },
            {
                title: <FormattedMessage id='storage_journal.closing_date' />,
                key: 'date',
                dataIndex: 'dateTime',
                align: 'center',
                width: 150,
                ...this.getColumnSearchProps('dateRange'),
                render: date => dayjs(date).format('DD.MM.YYYY HH:mm')
            },
            {
                title: <FormattedMessage id='storage_calculations.doc_type' />,
                key: 'doc_type',
                dataIndex: 'documentType',
                align: 'center',
                width: 200,
                ...this.getColumnSearchProps('filterDocumentType'),
                render: data => {
                    return (
                        <Tooltip
                            title={
                                <div>
                                    {data} - <FormattedMessage id={`storage_document.docType.${data}`} />
                                </div>
                            }
                        >
                            <div>{data}</div>
                        </Tooltip>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_journal.document_number' />,
                key: 'docNumber',
                width: 200,
                ...this.getColumnSearchProps('filterDocumentNumber'),
                render: (key, data) => {
                    const storeDocId = _.get(data, 'storeDocId');
                    const salaryDocId = _.get(data, 'salaryDocId');
                    const documentNumber = _.get(data, 'documentNumber');

                    return data.orderId ? (
                        <div>
                            <a
                                href={`${book.order}/${data.orderId}`}
                                style={{ color: 'var(--link)', fontWeight: 'bold' }}
                            >
                                {documentNumber}
                            </a>
                        </div>
                    ) : storeDocId && isGrantAccessed(this.props.user, grants.WAREHOUSE_DOCUMENT) ? (
                        <div>
                            <a
                                href={`${book.storageDocument}/${storeDocId}`}
                                style={{ color: 'var(--link)', fontWeight: 'bold' }}
                            >
                                {documentNumber}
                            </a>
                        </div>
                    ) : storeDocId ? (
                        documentNumber
                    ) : salaryDocId ? (
                        <div>
                            <a
                                href={`${book.salariesPage}/${salaryDocId}`}
                                style={{ color: 'var(--link)', fontWeight: 'bold' }}
                            >
                                {documentNumber}
                            </a>
                        </div>
                    ) : (
                        <div>
                            <Link
                                style={{ color: 'var(--link)', fontWeight: 'bold' }}
                                to={{
                                    pathname: book.cashFlowPage,
                                    state: { cashOrderId: documentNumber }
                                }}
                            >
                                {documentNumber}
                            </Link>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_journal.operation_type' />,
                key: 'sign',
                width: 200,
                ...this.getColumnSearchProps('filterSign'),
                align: 'center',
                render: (key, data) => {
                    const sign = _.get(data, 'sign');

                    return (
                        <div>
                            <Tag color={sign === '+' ? 'var(--green)' : 'var(--warning)'}>
                                {props.intl.formatMessage({
                                    id: `storage.${sign === '+' ? 'sign_+' : 'sign_-'}`
                                })}
                            </Tag>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_calculations.sum' />,
                key: 'sum',
                width: 150,
                align: 'right',
                render: data => {
                    const sum = _.get(data, 'sum');
                    const sign = _.get(data, 'sign');

                    return (
                        <div>
                            {sum ? sign : ''}
                            <Numeral mask='0,0.00'>{Number(sum)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_calculations.remaining_sum' />,
                key: 'remaining',
                width: 150,
                align: 'right',
                render: data => {
                    const remaining = _.get(data, 'remaining');
                    const sign = _.get(data, 'sign');

                    return (
                        <div>
                            {remaining ? sign : ''}
                            <Numeral mask='0,0.00'>{Number(remaining)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_calculations.paired_document' />,
                key: 'pairingIds',
                align: 'center',
                width: 200,
                ...this.getColumnSearchProps('filterRelatedDoc'),
                render: (key, data) => {
                    const relatedDoc = _.get(data, 'relatedDoc', '');
                    const type = String(relatedDoc).substr(0, 3);
                    const index = String(relatedDoc).lastIndexOf('-');
                    const id = String(relatedDoc).substr(index + 1);

                    switch (type) {
                        case 'MRD':
                            return (
                                <div>
                                    <a
                                        href={`${book.order}/${id}`}
                                        style={{ color: 'var(--link)', fontWeight: 'bold' }}
                                    >
                                        {relatedDoc}
                                    </a>
                                </div>
                            );
                        case 'SAL':
                            return (
                                <div>
                                    <a
                                        href={`${book.salariesPage}/${id}`}
                                        style={{ color: 'var(--link)', fontWeight: 'bold' }}
                                    >
                                        {relatedDoc}
                                    </a>
                                </div>
                            );
                        default:
                            return isGrantAccessed(this.props.user, grants.WAREHOUSE_DOCUMENT) ? (
                                <div>
                                    <a
                                        href={`${book.storageDocument}/${id}`}
                                        style={{ color: 'var(--link)', fontWeight: 'bold' }}
                                    >
                                        {relatedDoc}
                                    </a>
                                </div>
                            ) : (
                                relatedDoc
                            );
                    }
                }
            },
            {
                title: <FormattedMessage id='storage_journal.counterparty_type' />,
                key: 'counterpartyType',
                dataIndex: 'counterpartType',
                ...this.getColumnSearchProps('filterCounterpartType'),
                width: 200
            },
            {
                title: <FormattedMessage id='storage_journal.counterpartyName' />,
                key: 'counterpartyName',
                width: 200,
                align: 'center',
                // ...this.getColumnSearchProps('filterCounterpartName'),
                render: (doc, data) => {
                    const counterpartType = _.get(data, 'counterpartType');

                    const clientName = _.get(data, 'clientName', '');

                    const supplierName = _.get(data, 'nameBsp');

                    const employeeName = _.get(data, 'employeeName');

                    return counterpartType ? (
                        <Link
                            to={
                                supplierName
                                    ? `${book.supplier}/${_.get(data, 'counterpartId')}`
                                    : clientName
                                    ? `${book.client}/${_.get(data, 'counterpartId')}`
                                    : `${book.employeesPage}/${_.get(data, 'employeeId')}`
                            }
                        >
                            {supplierName || employeeName || clientName}
                        </Link>
                    ) : null;
                }
            },
            {
                title: <FormattedMessage id='storage_funds.phone_number' />,
                key: 'phone',
                width: 200,
                // ...this.getColumnSearchProps('filterPhone'),
                render: (doc, data) => {
                    const counterpartType = _.get(data, 'counterpartType');

                    const clientPhone = _.get(data, 'clientPhone') ? `${_.get(data, 'clientPhone')}` : '';

                    const counterpartEmployeePhone = _.get(data, 'employeePhone')
                        ? `${_.get(data, 'employeePhone')}`
                        : '';

                    const supplierPhone = _.get(data, 'bspPhone') ? `${_.get(data, 'bspPhone')}` : '';

                    // const businessSupplierPhone = _.get(data, 'doc.businessSupplier.phones')
                    //     ? _.get(data, 'doc.businessSupplier.phones')
                    //     : '';

                    return counterpartType === 'client' ? (
                        <div>{clientPhone}</div>
                    ) : counterpartType === 'supplier' ? (
                        <div>{supplierPhone}</div>
                    ) : (
                        <div>{counterpartEmployeePhone}</div>
                    );
                }
            },

            {
                title: <FormattedMessage id='storage_journal.row_id' />,
                key: 'rowId',
                dataIndex: 'id',
                align: 'right',
                width: 200
            },
            {
                title: <FormattedMessage id='storage_journal.counterparty_id' />,
                key: 'counterpartyId',
                dataIndex: 'counterpartId',
                align: 'right',
                ...this.getColumnSearchProps('filterCounterpartId'),
                width: 200,
                render: (doc, data) => {
                    const counterpartId = _.get(data, 'counterpartId');
                    const counterpartType = _.get(data, 'counterpartType');
                    const clientSurname = _.get(data, 'clientSurname');
                    const clientName = clientSurname
                        ? `${_.get(data, 'clientName', '')} ${_.get(data, 'clientSurname', '')}`
                        : `${_.get(data, 'clientName', '')}`;

                    const supplierName = _.get(data, 'nameBsp');

                    return counterpartType ? (
                        <Link
                            to={
                                supplierName
                                    ? `${book.supplier}/${_.get(data, 'counterpartId')}`
                                    : clientName
                                    ? `${book.client}/${_.get(data, 'counterpartId')}`
                                    : `${book.employees}/${_.get(data, 'counterpartId')}`
                            }
                        >
                            {counterpartId}
                        </Link>
                    ) : null;
                }
            },
            {
                title: <FormattedMessage id='storage_document.business_requisites' />,
                key: 'businessRequisiteName',
                width: 200,
                align: 'right',
                ...this.getColumnSearchProps('filterBusinessRequisiteId'),
                render: (key, data) => {
                    const businessRequisiteName = _.get(data, 'businessRequisiteName');

                    return <div>{businessRequisiteName}</div>;
                }
            },
            {
                title: <FormattedMessage id='storage_document.clients_requisites' />,
                key: 'clientRequisiteName',
                dataIndex: 'clientRequisiteName',
                ...this.getColumnSearchProps('filterCounterpartRequisiteClient'),
                width: 200,
                align: 'right',
                render: (key, data) => {
                    const clientRequisiteName = _.get(data, 'clientRequisiteName');

                    return <div>{clientRequisiteName}</div>;
                }
            },
            {
                title: <FormattedMessage id='storage_document.business_supplier_requisites' />,
                key: 'businessSupplierRequisiteName',
                dataIndex: 'businessSupplierRequisiteName',
                ...this.getColumnSearchProps('filterCounterpartRequisiteSupplier'),
                width: 200,
                align: 'right',
                render: (key, data) => {
                    const businessSupplierRequisiteName = _.get(data, 'businessSupplierRequisiteName');

                    return <div>{businessSupplierRequisiteName}</div>;
                }
            }
        ];
    }

    async componentDidMount() {
        if (_.get(this.props, 'location.state.documentNumber')) {
            await this.setState({
                filterRelatedDoc: _.get(this.props, 'location.state.documentNumber')
            });
        }
        this.getStoreDocProducts();
        this.props.fetchBusinessRequisites();
        this.fetchClientRequisites();
        this.fetchLabors();
        this.props.fetchWarehouses();
    }

    fetchLabors = async () => {
        const data = await fetchAPI('GET', '/labors', null, null, {
            handleErrorInternally: true
        });

        this.setState({ labors: data.labors });
    };

    fetchClientRequisites = async () => {
        const dataSuppliers = await fetchAPI('GET', 'business_suppliers', {
            all: true,
            showHidden: false
        });

        const supplierRequisites = _.uniqBy(
            _.flattenDeep(
                dataSuppliers.filter(({ requisites }) => _.get(requisites, 'length')).map(el => el.requisites)
            ),
            'id'
        );

        const clientRequisites = await fetchAPI('GET', '/clients/requisites', null, null, {
            handleErrorInternally: true
        });

        this.setState({ clientRequisites, supplierRequisites });
    };

    createPair = async () => {
        const { user } = this.props;
        const { pairSelectedIds, pairSign: cashOrderSign, singleType } = this.state;

        await fetchAPI('GET', 'debts/pairing', {
            cashOrderSign: singleType === 'CASH' ? cashOrderSign : cashOrderSign === '+' ? '-' : '+',
            pairingIds: cashOrderSign === '+' ? pairSelectedIds.slice(1) : [pairSelectedIds[0]],
            cashOrderIds: cashOrderSign === '+' ? [pairSelectedIds[0]] : pairSelectedIds.slice(1),
            managerId: user.employeeId
        });
        this.setState({
            pairSelectedIds: [],
            pairSign: undefined,
            pairCounterpartId: undefined,
            singleType: undefined
        });
        this.getStoreDocProducts();
    };

    deletePair = async ({ pairingIds, cashOrderSign, cashOrderId }) => {
        await fetchAPI('DELETE', 'debts/pairing', {
            pairingIds,
            cashOrderSign,
            cashOrderId
        });
        this.setState({
            pairSelectedIds: [],
            pairSign: undefined
        });
        this.getStoreDocProducts();
    };

    getStoreDocProducts = async () => {
        const {
            dateRange,
            docTime,
            page,
            pageSize,
            filterDate,
            filterDocumentNumber,
            filterDocumentType,
            filterSign,
            filterCounterpartId,
            filterCounterpartType,
            filterCounterpartName,
            filterRelatedDoc,
            filterStartDate,
            filterEndDate,
            sign,
            documentIds,
            rowId,
            filterBusinessRequisiteId,
            filterCounterpartRequisiteId
        } = this.state;
        const data = await fetchAPI(
            'GET',
            '/journal_calculations',
            {
                page,
                pageSize,
                // startDate: _.get(dateRange, '[0]') ? dateRange[0].format('YYYY-MM-DD') : undefined,
                // endDate: _.get(dateRange, '[1]') ? dateRange[1].format('YYYY-MM-DD') : undefined,
                filterDocumentNumber,
                filterDocumentType,
                filterSign,
                filterBusinessRequisiteId,
                filterCounterpartRequisiteId,
                filterCounterpartId,
                filterCounterpartType,
                filterStartDocDate: _.get(docTime, '[0]') ? docTime[0].format('YYYY-MM-DD') : undefined,
                filterEndDocDate: _.get(docTime, '[1]') ? docTime[1].format('YYYY-MM-DD') : undefined,
                filterCounterpartName,
                filterRelatedDoc,
                filterStartDate: _.get(dateRange, '[0]') ? dateRange[0].format('YYYY-MM-DD') : undefined,
                filterEndDate: _.get(dateRange, '[1]') ? dateRange[1].format('YYYY-MM-DD') : undefined
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            dataSource: data.res,
            stats: data.stats,
            sum: data.sum
        });
    };

    getMinus = (docType, value) => {
        if (docType) {
            return '';
        }
        if (!docType && value === 0) {
            return '';
        }

        return '-';
    };

    getColumnSearchProps = dataIndex => {
        let filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.setState({
                        [dataIndex]: e.target.value
                    });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                style={{ marginBottom: 8, display: 'block', width: 180 }}
                value={this.state[dataIndex]}
            />
        );

        if (dataIndex === 'dateRange' || dataIndex === 'docTime') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        allowClear
                        dateRange={this.state[dataIndex]}
                        format={dateFormat}
                        getPopupContainer={trigger => trigger.parentNode}
                        onDateChange={async dateRange => {
                            await this.setState({
                                [dataIndex]: dateRange
                            });
                            this.handleSearch(confirm, dataIndex);
                            this.getStoreDocProducts();
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            );
        }

        if (dataIndex === 'filterDocumentType') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({
                            filterDocumentType: value
                        });
                    }}
                    // mode='multiple'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_journal.choose_type'
                    })}
                    style={{ marginBottom: 8, display: 'block', width: 275 }}
                    value={this.state.filterDocumentType}
                >
                    {journalTypes.map(type => (
                        <Option key={type} value={type}>
                            {type} - <FormattedMessage id={`storage_document.docType.${type}`} />
                        </Option>
                    ))}
                </Select>
            );
        }

        if (dataIndex === 'filterSign') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({
                            filterSign: value
                        });
                    }}
                    // mode='multiple'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_journal.choose_type'
                    })}
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                    value={this.state.filterSign}
                >
                    <Option value='+'>
                        <FormattedMessage id='storage.sign_+' />
                    </Option>
                    <Option value='-'>
                        <FormattedMessage id='storage.sign_-' />
                    </Option>
                </Select>
            );
        }

        if (dataIndex === 'filterBusinessRequisiteId') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({
                            filterBusinessRequisiteId: value
                        });
                    }}
                    optionFilterProp='children'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_document.business_requisites'
                    })}
                    showSearch
                    style={{ marginBottom: 8, display: 'block', width: 275 }}
                    value={this.state.filterBusinessRequisiteId}
                >
                    {this.props.businessRequisites.map(elem => (
                        <Select.Option key={elem.id} value={elem.id}>
                            {elem.name} ({elem.id})
                        </Select.Option>
                    ))}
                </Select>
            );
        }

        if (dataIndex === 'filterCounterpartRequisiteClient') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({
                            filterCounterpartRequisiteId: value,
                            filterCounterpartRequisiteClient: true
                        });
                    }}
                    optionFilterProp='children'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_document.clients_requisites'
                    })}
                    showSearch
                    style={{ marginBottom: 8, display: 'block', width: 275 }}
                    value={this.state.filterCounterpartRequisiteId}
                >
                    {this.state.clientRequisites.map(elem => (
                        <Select.Option key={elem.id} value={elem.id}>
                            {elem.name} ({elem.id})
                        </Select.Option>
                    ))}
                </Select>
            );
        }

        if (dataIndex === 'filterCounterpartRequisiteSupplier') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({
                            filterCounterpartRequisiteId: value,
                            filterCounterpartRequisiteSupplier: true
                        });
                    }}
                    optionFilterProp='children'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_document.business_supplier_requisites'
                    })}
                    showSearch
                    style={{ marginBottom: 8, display: 'block', width: 275 }}
                    value={this.state.filterCounterpartRequisiteId}
                >
                    {this.state.supplierRequisites.map(elem => (
                        <Select.Option key={elem.id} value={elem.id}>
                            {elem.name} ({elem.id})
                        </Select.Option>
                    ))}
                </Select>
            );
        }

        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}
                    {dataIndex !== 'dateRange' && dataIndex !== 'docTime' && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Button
                                icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                onClick={() => this.handleSearch(confirm, dataIndex)}
                                size='small'
                                type='primary'
                            >
                                <FormattedMessage id='search' />
                            </Button>
                            <Button onClick={() => this.handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                <FormattedMessage id='reset' />
                            </Button>
                        </div>
                    )}
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.state[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = async confirm => {
        confirm();
        await this.setState({ page: 1 });
        this.getStoreDocProducts();
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.setState({ [dataIndex]: undefined, page: 1 });
        this.getStoreDocProducts();
    };

    render() {
        const { user } = this.props;
        const {
            dataSource,
            dateRange,
            loading,
            page,
            pageSize,
            totalCount,
            operationCodes,
            stats,
            sum,
            visibleDebtPairResultsModal,
            visibleAddRowModal,
            labors
        } = this.state;

        const pagination = {
            pageSize,
            total: Math.ceil(_.get(stats, '[0].count', 0) / pageSize) * pageSize,
            hideOnSinglePage: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.getStoreDocProducts();
            }
        };

        return (
            <React.Fragment>
                <Layout
                    controls={
                        <div style={{ display: 'flex' }}>
                            <Tooltip title={<FormattedMessage id='storage.reset_all_filters' />}>
                                <Button
                                    icon={<RedoOutlined />}
                                    onClick={async () => {
                                        await this.setState({
                                            dateRange: undefined,
                                            docTime: undefined,
                                            page: 1,
                                            filterCounterpartName: undefined,
                                            filterDocumentNumber: undefined,
                                            filterDocumentType: undefined,
                                            filterSign: undefined,
                                            filterBusinessSupplierRequisiteId: undefined,
                                            filterCounterpartId: undefined,
                                            filterCounterpartType: undefined,
                                            filterCounterpartNam: undefined,
                                            filterRelatedDoc: undefined,
                                            filterBusinessRequisiteId: undefined,
                                            filterCounterpartRequisiteId: undefined
                                        });
                                        this.getStoreDocProducts();
                                    }}
                                    style={{
                                        marginRight: 8
                                    }}
                                />
                            </Tooltip>
                            {isGrantAccessed(this.props.user, grants.ACCOUNTING_SETTLEMENT_JOURNAL_ADD_ROW) && (
                                <Tooltip title={<FormattedMessage id='add' />}>
                                    <Button
                                        icon={<PlusOutlined />}
                                        onClick={() => {
                                            this.setState({ visibleAddRowModal: true });
                                        }}
                                        style={{
                                            marginRight: 8
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    }
                    paper
                    title={<FormattedMessage id='navigation.storage_calculations' />}
                >
                    <DataRow>
                        <DataWrapper>
                            <span
                                style={{
                                    marginRight: 4
                                }}
                            >
                                <FormattedMessage id='storage_journal.row_amount' />
                            </span>
                            <Numeral currency={this.props.intl.formatMessage({ id: 'pc' })}>
                                {Number(_.get(stats, '[0].count', 0))}
                            </Numeral>
                        </DataWrapper>
                        <DataWrapper>
                            <span
                                style={{
                                    marginRight: 4
                                }}
                            >
                                <FormattedMessage id='storage_calculations.general_sum' />:
                            </span>
                            <Numeral mask='0,0.00'>{Number(_.get(sum, '[0].totalSum'))}</Numeral>
                        </DataWrapper>
                        <DataWrapper>
                            <span
                                style={{
                                    marginRight: 4
                                }}
                            >
                                <FormattedMessage id='storage_calculations.remaining' />:
                            </span>
                            <Numeral mask='0,0.00'>{Number(_.get(sum, '[0].sumAmountLeft'))}</Numeral>
                        </DataWrapper>
                    </DataRow>
                    <Table
                        columns={this.columns()}
                        dataSource={dataSource}
                        page={page}
                        pageSize={pageSize}
                        pagination={pagination}
                        scroll={{
                            x: 800,
                            y: '60vh'
                        }}
                        setPage={async (page, pageSize) => {
                            await this.setState({ page, pageSize });
                            this.getStoreDocProducts();
                        }}
                    />
                    <DebtPairResultsModal
                        hideModal={() => {
                            this.setState({ visibleDebtPairResultsModal: false });
                        }}
                        row={visibleDebtPairResultsModal}
                        visible={Boolean(visibleDebtPairResultsModal)}
                    />
                    {/* <AddRowCalculationsModal
                        hideModal={() => {
                            this.setState({ visibleAddRowCalculationsModal: false });
                        }}
                        updateData={this.getStoreDocProducts}
                        visible={visibleAddRowCalculationsModal}
                    /> */}
                </Layout>
                <AddRowModal
                    hideModal={() => {
                        this.setState({ visibleAddRowModal: false });
                    }}
                    labors={labors}
                    updateData={this.getStoreDocProducts}
                    visible={visibleAddRowModal}
                    warehouses={this.props.warehouses}
                />
            </React.Fragment>
        );
    }
}

const DataRow = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 14px;
    padding: 14px 0;

    border-bottom: 1px solid;
    border-top: 1px solid;
`;

const DataWrapper = styled.div`
    display: flex;
    font-size: 16px;
`;
