import { CloseOutlined } from '@ant-design/icons';
import { Col, DatePicker, Input, Modal, notification, Radio, Row, Select, TimePicker } from 'antd';
import { fetchClients, setClientsSearchFilter } from 'core/clients/duck';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import _ from 'lodash';
import CounterpartSelector from 'modals/EmployeeTaskModal/components/CounterpartSelector';
import DocumentsTable from 'modals/EmployeeTaskModal/components/tables/DocumentsTable';
import {
    counterpartValues,
    documentMapTypeValues,
    documentTypeValues,
    priorityArrayValues
} from 'modals/EmployeeTaskModal/constants';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import {
    createEmployeeTask,
    fetchDocuments,
    fetchManagers,
    resetFields,
    selectEmployees,
    selectFields,
    selectManagers,
    setDocumentFilters,
    setFields
} from './redux/duck';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

const RadioGroup = Radio.Group;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    employees: selectEmployees(state),
    fields: selectFields(state),
    managers: selectManagers(state)
});

const mapDispatchToProps = {
    resetModal,
    fetchClients,
    setClientsSearchFilter,
    setFields,
    resetFields,
    createEmployeeTask,
    setDocumentFilters,
    fetchDocuments,
    fetchManagers
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class EmployeeTaskModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: [],
            suppliers: [],
            clientList: []
        };

        this.handleSearchClients = _.debounce(value => {
            const { setClientsSearchFilter, fetchClients } = this.props;
            setClientsSearchFilter(value);
            fetchClients();
        }, 500).bind(this);
    }

    componentDidUpdate(prevProps) {
        const prevInitValues = _.get(prevProps, 'modalProps.initialValues');
        const currInitValues = _.get(this.props, 'modalProps.initialValues');

        // Initialize some values if they were provided
        if (!_.isEqual(prevInitValues, currInitValues)) {
            this.props.setFields(this.props.modalProps.initialValues);
        }

        if (this.props.visible === MODALS.EMPLOYEE_TASK_MODAL && prevProps.visible !== this.props.visible) {
            this.getAllEmployees();
            this.fetchSuppliers();
            this.props.fetchClients();
        }
    }

    setFieldsEmployeeTask = (key, value) => {
        this.props.setFields({ [key]: value });
    };

    handleSubmit = e => {
        e.preventDefault();

        const afterCreateCallback = _.get(this.props, 'modalProps.afterCreateCallback');

        this.props.createEmployeeTask(afterCreateCallback);

        this.props.resetModal();
        this.props.resetFields();
        notification.success({
            message: this.props.intl.formatMessage({ id: 'barcode.success' })
        });
    };

    getAllEmployees = async query => {
        const employees = await fetchAPI('GET', 'employees', { query }, null, { handleErrorInternally: true });
        this.setState({
            employees
        });
    };

    fetchSuppliers = async (query, all) => {
        const suppliers = await fetchAPI('GET', 'business_suppliers', {
            query,
            all
        });
        this.setState({
            suppliers
        });
    };

    handleSearchSuppliers = _.debounce(value => {
        this.fetchSuppliers(value, true);
    }, 1000);

    handleSearchEmployees = _.debounce(value => {
        this.getAllEmployees(value);
    }, 1000);

    render() {
        const {
            resetModal,
            visible,
            resetFields,
            fields,
            managers,

            intl: { formatMessage }
        } = this.props;

        const { employees, suppliers, clientList } = this.state;

        const showDocumentTable =
            (fields.documentType == documentTypeValues.ORDER && fields.orderId) ||
            (fields.documentType == documentTypeValues.CASH_ORDER && fields.cashOrderId) ||
            (fields.documentType == documentTypeValues.STORE_DOC && fields.storeDocId);

        return (
            <div>
                <Modal
                    maskClosable={false}
                    onCancel={() => {
                        resetModal();
                        resetFields();
                    }}
                    onOk={this.handleSubmit}
                    title={<FormattedMessage id='employee_task_modal.title' />}
                    visible={visible === MODALS.EMPLOYEE_TASK_MODAL}
                    width='70vw'
                >
                    <Row className={Styles.row}>
                        <Col span={12}>
                            <Input
                                onChange={e => this.setFieldsEmployeeTask('name', e.target.value)}
                                placeholder={formatMessage({ id: 'employee_task_modal.name' })}
                                value={fields.name}
                            />
                        </Col>
                        <Col span={12}>
                            <Select
                                dropdownStyle={{ zIndex: '9999' }}
                                filterOption={(input, option) => {
                                    return (
                                        option.props.children &&
                                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                onChange={value => {
                                    this.setFieldsEmployeeTask('performerId', value);
                                }}
                                onSearch={input => this.handleSearchEmployees(input)}
                                placeholder={<FormattedMessage id='employee_task_modal.performer' />}
                                showSearch
                                value={fields.performerId}
                            >
                                {employees
                                    ? employees
                                          .filter(({ isManager }) => isManager)
                                          .map(elem => (
                                              <Option key={elem.managerId} value={elem.managerId}>
                                                  {`${_.get(elem, 'surname', '')} ${_.get(elem, 'name', '')}`}
                                              </Option>
                                          ))
                                    : []}
                            </Select>
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col span={8}>
                            <DatePicker
                                format='YYYY-MM-DD'
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={e => {
                                    this.setFieldsEmployeeTask('scheduledDate', e);
                                }}
                                placeholder={formatMessage({
                                    id: 'employee_task_modal.deadline_date_placeholder'
                                })}
                                value={fields.scheduledDate}
                            />
                        </Col>
                        <Col span={4}>
                            <TimePicker
                                allowClear={false}
                                format='HH:mm'
                                getPopupContainer={trigger => trigger.parentNode}
                                minuteStep={30}
                                onChange={e => {
                                    this.setFieldsEmployeeTask('scheduledTime', e);
                                }}
                                placeholder={formatMessage({
                                    id: 'employee_task_modal.deadline_time_placeholder'
                                })}
                                value={fields.scheduledTime}
                            />
                        </Col>
                        <Col span={12}>
                            <Select
                                dropdownStyle={{ zIndex: '9999' }}
                                filterOption={(input, option) => {
                                    return (
                                        option.props.children &&
                                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                onChange={value => {
                                    this.setFieldsEmployeeTask('priority', value);
                                }}
                                placeholder={<FormattedMessage id='employee_task_modal.priority_placeholder' />}
                                showSearch
                                value={fields.priority}
                            >
                                {priorityArrayValues.map(elem => (
                                    <Option key={elem} value={elem}>
                                        <FormattedMessage
                                            id={`employee_task_modal.priority.${String(elem).toLowerCase()}`}
                                        />
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col span={12}>
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setFieldsEmployeeTask('counterpartyType', value);
                                    this.props.setFields({
                                        documentType: undefined,
                                        counterpartClientId: null,
                                        counterpartEmployeeId: null,
                                        counterpartBusinessSupplierId: null,
                                        storeDocId: null,
                                        orderId: null,
                                        cashOrderId: null
                                    });
                                }}
                                placeholder={formatMessage({
                                    id: 'employee_task_modal.counterpart_select'
                                })}
                                value={fields.counterpartyType}
                            >
                                {_.map(counterpartValues, (type, index) => {
                                    return (
                                        <Option key={index} value={type}>
                                            <FormattedMessage id={`employee_task_modal.${type.toLowerCase()}`} />
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                        <Col span={12}>
                            <CounterpartSelector
                                clients={clientList}
                                employees={employees}
                                handleSearchClient={this.handleSearchClients}
                                handleSearchEmployees={this.handleSearchEmployees}
                                handleSearchSuppliers={this.handleSearchSuppliers}
                                suppliers={suppliers}
                            />
                        </Col>
                    </Row>

                    {fields.counterpartClientId ||
                    fields.counterpartEmployeeId ||
                    fields.counterpartBusinessSupplierId ? (
                        <div>
                            {showDocumentTable ? null : (
                                <Row className={Styles.row}>
                                    <Col span={24}>
                                        <RadioGroup
                                            // className={Styles.radioGroup}
                                            onChange={e => {
                                                this.setFieldsEmployeeTask('documentType', e.target.value);

                                                this.props.setFields({
                                                    storeDocId: null,
                                                    orderId: null,
                                                    cashOrderId: null
                                                });

                                                this.props.fetchDocuments();
                                            }}
                                            value={fields.documentType}
                                        >
                                            {_.map(documentMapTypeValues[fields.counterpartyType], type => (
                                                <Radio value={type}>
                                                    <FormattedMessage
                                                        id={`employee_task_modal.${type.toLowerCase()}`}
                                                    />
                                                </Radio>
                                            ))}
                                        </RadioGroup>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col span={24}>
                                    <div>
                                        <Col span={24}>
                                            {fields.documentType == documentTypeValues.ORDER && fields.orderId ? (
                                                <div>
                                                    <FormattedMessage id='employee_task_modal.selected_order' />:
                                                    {fields.orderId}{' '}
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            this.props.setFields({ orderId: null });
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                            {fields.documentType == documentTypeValues.CASH_ORDER &&
                                            fields.cashOrderId ? (
                                                <div>
                                                    <FormattedMessage id='employee_task_modal.selected_cash_order' />:
                                                    {fields.cashOrderId}{' '}
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            this.props.setFields({
                                                                cashOrderId: null
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            ) : null}

                                            {fields.documentType == documentTypeValues.STORE_DOC &&
                                            fields.storeDocId ? (
                                                <div>
                                                    <FormattedMessage id='employee_task_modal.selected_store_doc' />:
                                                    {fields.storeDocId}{' '}
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            this.props.setFields({
                                                                storeDocId: null
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                        </Col>
                                    </div>
                                </Col>
                                <div>{showDocumentTable ? null : fields.documentType && <DocumentsTable />}</div>
                            </Row>
                        </div>
                    ) : null}

                    <Row className={Styles.row}>
                        <Col span={24}>
                            <TextArea
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                onChange={e => this.setFieldsEmployeeTask('comment', e.target.value)}
                                placeholder={formatMessage({
                                    id: 'employee_task_modal.comment_placeholder'
                                })}
                                value={fields.comment}
                            />
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}
