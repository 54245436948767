import {
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    IssuesCloseOutlined,
    MinusCircleOutlined,
    SettingOutlined,
    ToolOutlined
} from '@ant-design/icons';
import { Button, Modal, Select, Table, TreeSelect, notification } from 'antd';
import _, { get, pick, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, filterTreeNodeByPart } from 'utils';

import Styles from './styles.m.css';

const { Option } = Select;

// const green = '#44982B';
const red = '#ff3725';
const yellow = '#ffc801';
const colorByAnswer = {
    1: '#44982ba6',
    2: '#fdc50070',
    3: '#e72c1c78'
};

export const DiagnosticConfirmModal = injectIntl(
    ({
        open,
        handleClose,
        diagnostic: orderDiagnostic = [],
        tecdocId,
        orderId,
        defaultEmployeeId,
        defaultResponsibleId,
        reloadOrderForm,
        answeredDiagnosticts = [],
        intl,
        fetchDiagnosticData,
        allLabors = [],
        detailsTreeData = [],
        unitDefault,
        bodyId
    }) => {
        const [loading, setLoading] = useState(false);
        const [labors, setLabors] = useState([]);
        const [details, setDetails] = useState([]);
        const [diagnostic, setDiagnostic] = useState([]);
        const [ownDiagnostic, setOwnDiagnostic] = useState([]);

        const [detailRows, setDetailRows] = useState({
            selectedRowKeys: [],
            setDetailRows: []
        });
        const [ownRows, setOwnRows] = useState({
            selectedRowKeys: [],
            setDetailRows: []
        });
        const [laborRows, setLaborRows] = useState({
            selectedRowKeys: [],
            setDetailRows: []
        });

        const getDefaultDetails = async (details = []) => {
            if (tecdocId) {
                const modificationId = tecdocId;

                const result = [[]];

                for (let i = 0; i < details.length; i++) {
                    if (result[result.length - 1].length < 20) {
                        result[result.length - 1].push({
                            modificationId,
                            bodyId,
                            storeGroupId: details[i].id
                        });
                    }
                }

                const args = await Promise.all(
                    result.map(el =>
                        fetchAPI(
                            'POST',
                            'store_groups/default_details',
                            null,
                            { details: el },
                            { handleErrorInternally: true }
                        ))
                );

                return args.flat().map((result, i) => {
                    return {
                        ...details[i],
                        brandName: result.brandName,
                        brandId: result.brandId,
                        productCode: result.partNumber,
                        productPrice: result.sellingPrice,
                        supplierId: _.get(result, 'price.businessSupplierId'),
                        name: result.description || details[i].name
                    };
                });
            }

            return details;
        };

        const getRelatedPartsAndLabors = async partIds => {
            const data = await fetchAPI(
                'PUT',
                'diagnostic_calculation_data',
                null,
                { partIds },
                { handleErrorInternally: true }
            );
            const lbr = [];
            const dtl = [];

            const dg = orderDiagnostic.filter(({ partId }) => partId);
            data.forEach((el, index) => {
                const comment = el.comment || {
                    comment: _.get(dg, `[${index}].placeName`),
                    positions: [],
                    problems: []
                };
                lbr.push(
                    ...el.labor.map(lb => ({
                        ...lb,
                        isCritical: el.isCritical,
                        comment
                    }))
                );
                dtl.push(
                    ...el.storeGroup.map(dt => ({
                        ...dt,
                        isCritical: el.isCritical,
                        oeCode: el.oeCode,
                        comment
                    }))
                );
            });
            const resultDetails = await getDefaultDetails(dtl);
            console.log(resultDetails, lbr);
            setLabors(lbr);
            setDetails(resultDetails);

            setLaborRows({ selectedRowKeys: lbr.map(({ id }) => id), selectedRows: lbr });
            setDetailRows({ selectedRowKeys: (resultDetails || []).map(({ id }) => id), selectedRows: resultDetails });
        };

        useEffect(() => {
            if (!open) return;
            const dg = orderDiagnostic.filter(({ partId }) => partId);
            setDiagnostic(dg);
            const own = orderDiagnostic.filter(({ partId }) => !partId);
            setOwnDiagnostic(own);

            setOwnRows({ selectedRowKeys: own.map(({ diagnosticId }) => diagnosticId), selectedRows: own });

            getRelatedPartsAndLabors(
                dg.map(({ partId, partAnswer, partOECode, partComment }) => ({
                    partId,
                    comment: partComment || undefined,
                    isCritical: partAnswer == 3,
                    oeCode: partOECode
                }))
            );
        }, [open, orderDiagnostic]);

        const handleCancel = () => {
            setLoading(false);
            handleClose();
        };

        const handleOk = async () => {
            setLoading(true);
            const { status } = await fetchAPI('GET', 'orders/status', { orderId }, null);
            if (status === 'success') {
                window.location.reload();

                return;
            }
            const data = {
                services: [],
                details: [],
                insertMode: true
            };

            if (tecdocId) {
                data.modificationId = tecdocId;
            }

            const defaultUse = _.get(
                unitDefault.filter(({ defaultUse }) => defaultUse),
                '[0].id'
            );

            console.log(detailRows);

            laborRows.selectedRows.forEach(el => {
                console.log('LABOR', el);
                const comment = get(el, 'comment.comment') ? ` (${get(el, 'comment.comment')})` : '';
                data.services.push({
                    serviceName: el.name + comment,
                    serviceId: el.id,
                    comment: el.comment,
                    count: Number(el.normHours) || 1,
                    servicePrice: get(el, 'laborPrice.price'),
                    isCritical: el.isCritical,
                    employeeId: defaultEmployeeId,
                    laborUnitId: el.laborUnitId || defaultUse
                });
            });
            detailRows.selectedRows.forEach(el => {
                console.log('DETAIL', el);
                const comment = get(el, 'comment.comment') ? ` (${get(el, 'comment.comment')})` : '';
                data.details.push({
                    name: el.name + comment,
                    storeGroupId: el.id,
                    count: 1,
                    isCritical: el.isCritical,
                    comment: pick(el.comment, ['comment', 'position']),
                    productCode: el.oeCode || el.productCode,
                    supplierBrandId: el.oeCode ? 8000 : el.brandId || undefined,
                    oeCode: el.oeCode,
                    responsibleId: defaultResponsibleId || undefined,
                    price: el.productPrice || 1,
                    supplierId: el.supplierId
                });
            });
            ownRows.selectedRows.forEach(el => {
                if (!el.partStoreGroupId) return;

                data.details.push({
                    name: el.partName,
                    storeGroupId: el.partStoreGroupId,
                    count: 1,
                    isCritical: el.partAnswer == 3,
                    comment: pick(el.partComment, ['comment', 'position']),
                    productCode: el.partOECode,
                    supplierBrandId: el.partOECode ? 8000 : undefined,
                    oeCode: el.partOECode,
                    responsibleId: defaultResponsibleId || undefined
                });

                if (!el.partLaborId) return;

                data.services.push({
                    serviceName: get(
                        allLabors.find(lbr => lbr.id == el.laborId),
                        'name',
                        el.partName
                    ),
                    serviceId: `${el.partLaborId}${el.partStoreGroupId}`,
                    comment: el.partComment || {
                        comment: undefined,
                        positions: [],
                        problems: []
                    },
                    count: Number(el.normHours) || 1,
                    isCritical: el.partAnswer == 3,
                    employeeId: defaultEmployeeId,
                    laborUnitId: el.laborUnitId || defaultUse
                });
            });

            try {
                await fetchAPI('PUT', `orders/${orderId}`, null, data, { handleErrorInternally: true });
                notification.success({ message: intl.formatMessage({ id: 'barcode.success' }) });
            } catch (err) {
                notification.error({ message: intl.formatMessage({ id: 'error' }) });
            }
            await fetchAPI(
                'LOCK',
                'order_diagnostic',
                {
                    rowIds: `[${answeredDiagnosticts.map(({ diagnosticId }) => diagnosticId).join(',')}]`,
                    orderId,
                    completed: true
                },
                null,
                {
                    handleErrorInternally: true
                }
            );
            handleCancel();
            fetchDiagnosticData();
            reloadOrderForm();
        };

        const diagnosticColumns = useMemo(
            () => [
                {
                    key: 'partName',
                    render: row => {
                        const color = row.partAnswer ? colorByAnswer[row.partAnswer] : undefined;

                        return (
                            <div
                                style={{ borderBottom: color ? `3px solid ${color}` : 'none', display: 'inline-block' }}
                            >
                                {get(row, 'partName')}
                            </div>
                        );
                    }
                },
                {
                    key: 'partOECode',
                    align: 'center',
                    render: row => {
                        return get(row, 'partOECode');
                    }
                },
                {
                    key: 'answer',
                    align: 'center',
                    render: ({ partAnswer }) => {
                        const style = { fontSize: 18, ...{ color: partAnswer == 2 ? yellow : red } };
                        const Icon =
                            partAnswer == 2 ? (
                                <ExclamationCircleOutlined style={style} />
                            ) : (
                                <CloseCircleOutlined style={style} />
                            );

                        return <Button icon={Icon} type='text' />;
                    }
                }
            ],
            []
        );
        const laborColumns = useMemo(
            () => [
                {
                    title: <ToolOutlined />,
                    align: 'center',
                    key: 'name',
                    render: row => {
                        return get(row, 'name', row.masterLaborName);
                    }
                },
                {
                    key: 'action',
                    align: 'center',
                    render: (_, row, ind) => {
                        return (
                            <Button
                                danger
                                icon={<MinusCircleOutlined style={{ fontSize: 16 }} />}
                                onClick={() => {
                                    setLabors(labors.filter((_, index) => index !== ind));
                                }}
                                type='text'
                            />
                        );
                    }
                }
            ],
            [labors]
        );
        const detailColumns = useMemo(
            () => [
                {
                    title: <SettingOutlined />,
                    align: 'center',
                    key: 'singleName',
                    render: row => {
                        return get(row, 'singleName');
                    }
                },
                {
                    align: 'center',
                    key: 'brandName',
                    render: row => {
                        return get(row, 'brandName', <FormattedMessage id='long_dash' />);
                    }
                },
                {
                    key: 'action',
                    align: 'center',
                    render: (_, row, ind) => {
                        return (
                            <Button
                                danger
                                icon={<MinusCircleOutlined style={{ fontSize: 16 }} />}
                                onClick={() => {
                                    setDetails(details.filter((_, index) => index !== ind));
                                }}
                                type='text'
                            />
                        );
                    }
                }
            ],
            [details]
        );

        const ownDiagnosticColumns = useMemo(
            () => [
                {
                    title: <IssuesCloseOutlined />,
                    align: 'center',
                    key: 'partName',
                    render: row => {
                        const color = row.partAnswer ? colorByAnswer[row.partAnswer] : undefined;

                        return (
                            <div
                                style={{ borderBottom: color ? `3px solid ${color}` : 'none', display: 'inline-block' }}
                            >
                                {get(row, 'partName')}
                            </div>
                        );
                    }
                },
                ...diagnosticColumns.slice(1),
                {
                    title: <ToolOutlined />,
                    key: 'labor',
                    align: 'center',
                    render: (row, _, index) => {
                        const isStoreGroupSelected = row.partStoreGroupId && row.partStoreGroupId !== 1000000;
                        const options = !isStoreGroupSelected
                            ? allLabors.map(elem => (
                                  <Option
                                      key={elem.id}
                                      master_id={elem.masterLaborId}
                                      store_group_id={elem.storeGroupId}
                                      value={elem.id}
                                  >
                                      {elem.customName || elem.name}
                                  </Option>
                              ))
                            : uniqBy(allLabors, 'masterLaborId').map(elem => (
                                  <Option
                                      key={elem.masterLaborId}
                                      master_id={elem.masterLaborId}
                                      store_group_id={elem.storeGroupId}
                                      value={elem.masterLaborId}
                                  >
                                      {elem.masterLaborName}
                                  </Option>
                              ));

                        return (
                            <Select
                                allowClear
                                filterOption={(input, option) => {
                                    const parts = input.toLowerCase().split(' ');

                                    return parts.every(part => String(option.children).toLowerCase().includes(part));
                                }}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={(value, option) => {
                                    const tmp = { ...row };
                                    tmp.laborId = value;
                                    if (option) {
                                        tmp.partLaborId = option.master_id;
                                        if (!isStoreGroupSelected) {
                                            tmp.partStoreGroupId = option.store_group_id;
                                        }
                                    } else {
                                        tmp.partLaborId = undefined;
                                    }
                                    setOwnDiagnostic(
                                        ownDiagnostic.map((item, ind) => (ind === index ? { ...item, ...tmp } : item))
                                    );
                                }}
                                placeholder={intl.formatMessage({
                                    id: 'services_table.labor'
                                })}
                                showSearch
                                style={{ minWidth: 188 }}
                                value={isStoreGroupSelected ? row.partLaborId : row.laborId}
                            >
                                {options}
                            </Select>
                        );
                    }
                },
                {
                    title: <SettingOutlined />,
                    key: 'detail',
                    align: 'center',
                    render: (row, _, index) => {
                        return (
                            <TreeSelect
                                dropdownMatchSelectWidth={false}
                                filterTreeNode={filterTreeNodeByPart}
                                getPopupContainer={trigger => trigger.parentNode}
                                onSelect={value => {
                                    const tmp = { ...row };
                                    tmp.partStoreGroupId = value;

                                    setOwnDiagnostic(
                                        ownDiagnostic.map((item, ind) => (ind === index ? { ...item, ...tmp } : item))
                                    );
                                }}
                                placeholder={intl.formatMessage({
                                    id: 'services_table.store_group'
                                })}
                                showSearch
                                treeData={detailsTreeData}
                                value={row.partStoreGroupId}
                            />
                        );
                    }
                }
            ],
            [allLabors, detailsTreeData, diagnosticColumns, intl, ownDiagnostic]
        );

        return (
            <Modal
                destroyOnClose
                okButtonProps={{ disabled: !orderDiagnostic.length, loading }}
                onCancel={handleCancel}
                onOk={handleOk}
                open={open}
                style={{ minWidth: 800 }}
                title={<FormattedMessage id='order_form_table.diagnostic.calculation' />}
                width='75%'
            >
                <div className={Styles.content}>
                    <Table
                        bordered
                        className={Styles.table}
                        columns={diagnosticColumns}
                        dataSource={diagnostic}
                        pagination={false}
                        size='small'
                        title={() => <IssuesCloseOutlined />}
                    />
                    <Table
                        bordered
                        className={Styles.table}
                        columns={laborColumns}
                        dataSource={labors}
                        pagination={false}
                        rowKey='id'
                        rowSelection={{
                            selectedRowKeys: laborRows.selectedRowKeys,
                            onChange: (selectedRowKeys, selectedRows) => {
                                setLaborRows({
                                    selectedRowKeys,
                                    selectedRows
                                });
                            }
                        }}
                        size='small'
                    />
                    <Table
                        bordered
                        className={Styles.table}
                        columns={detailColumns}
                        dataSource={details}
                        pagination={false}
                        rowKey='id'
                        rowSelection={{
                            selectedRowKeys: detailRows.selectedRowKeys,
                            onChange: (selectedRowKeys, selectedRows) => {
                                setDetailRows({
                                    selectedRowKeys,
                                    selectedRows
                                });
                            }
                        }}
                        size='small'
                    />
                </div>
                {!!ownDiagnostic.length && (
                    <Table
                        bordered
                        className={Styles.table}
                        columns={ownDiagnosticColumns}
                        dataSource={ownDiagnostic}
                        pagination={false}
                        rowKey='diagnosticId'
                        rowSelection={{
                            selectedRowKeys: ownRows.selectedRowKeys,
                            onChange: (selectedRowKeys, selectedRows) => {
                                setOwnRows({
                                    selectedRowKeys,
                                    selectedRows
                                });
                            }
                        }}
                        size='small'
                        style={{ marginTop: 14 }}
                    />
                )}
            </Modal>
        );
    }
);
