import CashboxRSTIcon from 'commons/CashboxRSTIcon/messages';
import DocumentTypesMapper from 'commons/DocumentTypesMapper/messages';
// commons
import Footer from 'commons/Footer/messages';
// commons
import Header from 'commons/Header/messages';
import Navigation from 'commons/Navigation/messages';
import OrderStatusesMapper from 'commons/OrderStatusesMapper/messages';
import ArrayBreakScheduleInput from 'components/ArrayBreakScheduleInput/messages';
import ArrayScheduleInput from 'components/ArrayScheduleInput/messages';
import CallsStatistics from 'components/CallsStatistics/messages';
import StatisticsCallsTable from 'components/CallsTable/messages';
import ChangeStatusDropdown from 'components/ChangeStatusDropdown/messages';
import ClientCallsTab from 'components/ClientTabs/ClientCallsTab/messages';
import ClientFeedbackTab from 'components/ClientTabs/ClientFeedbackTab/messages';
import ClientMRDsTab from 'components/ClientTabs/ClientMRDsTab/messages';
import ClientOrdersTab from 'components/ClientTabs/ClientOrdersTab/messages';
import EmployeeFeedback from 'components/EmployeeFeedback/messages';
import EmployeesTable from 'components/EmployeesTable/messages';
import EmployeeStatistics from 'components/EmployeeStatistics/messages';
import PartAttributes from 'components/PartAttributes/messages';
import PartSuggestions from 'components/PartSuggestions/messages';
import ReportsDropdown from 'components/ReportsDropdown/messages';
import ReviewResponse from 'components/ReviewResponse/messages';
import ReviewsTable from 'components/ReviewsTable/messages';
import SettingSalaryTable from 'components/SettingSalaryTable/messages';
import StatsCountsPanel from 'components/StatsCountsPanel/messages';
// components
import StatusIcons from 'components/StatusIcons/messages';
import CashTables from 'components/Tables/CashTables/messages';
import ClientMRDsTable from 'components/Tables/ClientMRDsTable/messages';
import ReportOrdersTable from 'components/Tables/ReportTables/messages';
import SubscriptionTables from 'components/Tables/SubscriptionTables/messages';
import UniversalFiltersTags from 'components/UniversalFiltersTags/messages';
import VehicleNumberHistory from 'components/VehicleNumberHistory/messages';
import BarcodeContainer from 'containers/BarcodeContainer/messages';
import BusinessPackageContainer from 'containers/BusinessPackageContainer/messages';
import ClientContainer from 'containers/ClientContainer/messages';
import ClientRequisitesContainer from 'containers/ClientRequisitesContainer/messages';
import ClientsContainer from 'containers/ClientsContainer/messages';
import ClientsDebtsContainer from 'containers/ClientsDebtsContainer/messages';
import ManagerRoleContainer from 'containers/ManagerRoleContainer/messages';
import MyTasksContainer from 'containers/MyTasksContainer/messages';
import OrdersContainer from 'containers/OrdersContainer/messages';
// containers
import OrdersFilterContainer from 'containers/OrdersFilterContainer/messages';
import PackageContainer from 'containers/PackageContainer/messages';
import ReviewsContainer from 'containers/ReviewsContainer/messages';
import RoleContainer from 'containers/RoleContainer/messages';
import StorageDocumentsContainer from 'containers/StorageDocumentsContainer/messages';
import SuppliersDebtsContainer from 'containers/SuppliersDebtsContainer/messages';
import UniversalFiltersContainer from 'containers/UniversalFilters/messages';
import errorMessage from 'core/errorMessage/messages';
import merge from 'deepmerge';
import AbstractClientForm from 'forms/AbstractClientForm/messages';
import AddBusinessPackageForm from 'forms/AddBusinessPackageForm/messages';
import AddPackageForm from 'forms/AddPackageForm/messages';
import AddRoleForm from 'forms/AddRoleForm/messages';
import BrandsForm from 'forms/BrandsForm/messages';
import BusinessPackageForm from 'forms/BusinessPackageForm/messages';
import CancelReasonForm from 'forms/CancelReasonForm/messages';
import CashCreationForm from 'forms/CashCreationForm/messages';
import CashOrderForm from 'forms/CashOrderForm/messages';
// forms
import DecoratedDatePicker from 'forms/DecoratedFields/DecoratedDatePicker/messages';
import EmployeeForm from 'forms/EmployeeForm/messages';
import EmployeeScheduleForm from 'forms/EmployeeScheduleForm/messages';
import LoginForm from 'forms/LoginForm/messages';
import ManagerRoleForm from 'forms/ManagerRoleForm/messages';
// OrderForm
import OrderForm from 'forms/OrderForm/messages';
import CallsTable from 'forms/OrderForm/OrderFormTables/CallsTable/messages';
import DiagnosticTable from 'forms/OrderForm/OrderFormTables/DiagnosticTable/messages';
import OrderFormTables from 'forms/OrderForm/OrderFormTables/messages';
import TasksTable from 'forms/OrderForm/OrderFormTables/TasksTable/messages';
import OrderTaskForm from 'forms/OrderTaskForm/messages';
import PackageForm from 'forms/PackageForm/messages';
import ProfileForm from 'forms/ProfileForm/messages';
import ReportAnalyticsForm from 'forms/ReportForms/ReportAnalyticsForms/messages';
import ReportOrdersFilterForm from 'forms/ReportForms/ReportOrdersFilterForm/messages';
import RoleForm from 'forms/RoleForm/messages';
import SalaryReportForm from 'forms/SalaryReportForm/messages';
import SetDetailProductForm from 'forms/SetDetailProductForm/messages';
import SettingSalaryForm from 'forms/SettingSalaryForm/messages';
import SpreadBusinessBrandsForm from 'forms/SpreadBusinessBrandsForm/messages';
import StorageDocumentForm from 'forms/StorageForms/StorageDocumentForm/messages';
import StoreProductForm from 'forms/StorageForms/StoreProductForm/messages';
import SubscribeForm from 'forms/SubscribeForm/messages';
import SwitchBusinessForm from 'forms/SwitchBusinessForm/messages';
import ToSuccessForm from 'forms/ToSuccessForm/messages';
import UniversalFiltersForm from 'forms/UniversalFiltersForm/messages';
// Module locales
import Storage from 'locales/storage.json';
import AccountReceivableReportModal from 'modals/AccountsReceivablesReportModal/messages';
import AddClientModal from 'modals/AddClientModal/messages';
import AddLaborOrDetailToOrderModal from 'modals/AddLaborOrDetailToOrderModal/messages';
import BrandsCrossModal from 'modals/BrandsCrossModal/messages';
import BrandsModal from 'modals/BrandsModal/messages';
import ChangeVehicleMileageModal from 'modals/ChangeVehicleMileageModal/messages';
import ClientVehicleTransfer from 'modals/ClientVehicleTransfer/messages';
import ConfirmRescheduleModal from 'modals/ConfirmRescheduleModal/messages';
import DebtZeroingModal from 'modals/DebtZeroingModal/messages';
import EmployeeSubTasksModal from 'modals/EmployeeSubTasksModal/messages';
import EmployeeTaskModal from 'modals/EmployeeTaskModal/messages';
import ImportReceiptDocumentModal from 'modals/ImportReceiptDocumentModal/messages';
import InviteModal from 'modals/InviteModal/messages';
import OrderStatsInfoModal from 'modals/OrderStatsInfoModal/messages';
import OrderTaskModal from 'modals/OrderTaskModal/messages';
import RefactoredCashOrderModal from 'modals/RefactoredCashOrderModal/messages';
import RegisterForServiceModal from 'modals/RegisterForServiceModal/messages';
import RemainsReportModel from 'modals/RemainsReportModal/messages';
import ReportAnalyticsModal from 'modals/ReportModals/ReportAnalyticsModal/messages';
import ReportOrdersExportModal from 'modals/ReportModals/ReportOrdersExportModal/messages';
import SalesReportModal from 'modals/SalesReportModal/messages';
import SelectOrderModal from 'modals/SelectOrderModal/messages';
import StoreDocServiceModal from 'modals/StoreDocServiceModal/messages';
import SupplierModal from 'modals/SupplierModal/messages';
import SuppliersReportModal from 'modals/SuppliersReportModal/messages';
import TecDocInfoModal from 'modals/TecDocInfoModal/messages';
import UniversalChartModal from 'modals/UniversalChartModal/messages';
// modals
import UniversalFiltersModal from 'modals/UniversalFiltersModal/messages';
import VehicleLocationModal from 'modals/VehicleLocationModal/messages';
import VehicleModal from 'modals/VehicleModal/messages';
import numeral from 'numeral';
import AddEmployeePage from 'pages/AddEmployeePage/messages';
import AddOrderPage from 'pages/AddOrderPage/messages';
import AgreementPage from 'pages/AgreementPage/messages';
import AvailabilitiesPage from 'pages/AvailabilitiesPage/messages';
import BusinessPackagePage from 'pages/BusinessPackagePage/messages';
import CallsPage from 'pages/CallsPage/messages';
import ChartPage from 'pages/ChartPage/messages';
import ClientHotOperationsPage from 'pages/ClientHotOperationsPage/messages';
import ClientPage from 'pages/ClientPage/messages';
import ClientsPage from 'pages/ClientsPage/messages';
import ComplexesPage from 'pages/ComplexesPage/messages';
import DashboardPage from 'pages/DashboardPage/messages';
import DebtMovementPage from 'pages/DebtMovementPage/messages';
import DetailStatusSettingsPage from 'pages/DetailStatusSettingsPage/messages';
import DiagnosticPatternsPage from 'pages/DiagnosticPatternsPage/messages';
import DirectoriesPage from 'pages/DirectoriesPage/messages';
import DirectoryBrandsPage from 'pages/DirectoryBrandsPage/messages';
import EmployeesPage from 'pages/EmployeesPage/messages';
// pages
import AccountPlanPage from 'pages/AccountPlanPage/messages';
import AddInteractionPage from 'pages/AddInteractionPage/messages';
import EditMakesPage from 'pages/EditMakesPage/messages';
import EntriesJournalPage from 'pages/EntriesJournalPage/messages';
import ExceptionPage from 'pages/ExceptionPage/messages';
import ExchangeRatePage from 'pages/ExchangeRatePage/messages';
import GraphicReportsPage from 'pages/GraphicReportsPage/messages';
import InteractionsPage from 'pages/InteractionsPage/messages';
import Locations from 'pages/Locations/messages';
import ManagerRolePage from 'pages/ManagerRolePage/messages';
import MyNormativePage from 'pages/MyNormativePage/messages';
import NewDocumentPage from 'pages/NewDocumentPage/messages';
import OnlineServiceBookPage from 'pages/OnlineServiceBookPage/messages';
import OrderPage from 'pages/OrderPage/messages';
import OrdersPage from 'pages/OrdersPage/messages';
import PackagePage from 'pages/PackagePage/messages';
import PricelistsPage from 'pages/PricelistsPage/messages';
import ProductPage from 'pages/ProductPage/messages';
import ProfilePage from 'pages/ProfilePage/messages';
import ReceivablesAndPayablesPage from 'pages/ReceivablesAndPayablesPage/messages';
import ReferenceBookPage from 'pages/ReferenceBookPage/messages';
import RegulationsPage from 'pages/RegulationsPage/messages';
import RepairMapSettingPage from 'pages/RepairMapSettingPage/messages';
import ReportAnalyticsPage from 'pages/Reports/ReportAnalyticsPage/messages';
import ReportCashFlowPage from 'pages/Reports/ReportCashFlowPage/messages';
import ReportCashOrdersLogsPage from 'pages/Reports/ReportCashOrdersLogsPage/messages';
import ReportLoadKPIPage from 'pages/Reports/ReportLoadKPIPage/messages';
import ReportOrdersPage from 'pages/Reports/ReportOrdersPage/messages';
import ReportsPage from 'pages/ReportsPage/messages';
import RequisiteSettingPage from 'pages/RequisiteSettingPage/messages';
import ReviewPage from 'pages/ReviewPage/messages';
import ReviewsPage from 'pages/ReviewsPage/messages';
import RolePage from 'pages/RolePage/messages';
import SettingsPage from 'pages/SettingsPage/messages';
import SMSSettingsPage from 'pages/SMSSettingsPage/messages';
import JournalVATPage from 'pages/Storage/JournalVATPage/messages';
import SupplierPage from 'pages/SupplierPage/messages';
import SuppliersPage from 'pages/SuppliersPage/messages';
import SyncImportExport from 'pages/SyncImportExport/messages';
import TaskPage from 'pages/TaskPage/messages';
import TasksPage from 'pages/TasksPage/messages';
import VehiclePage from 'pages/VehiclePage/messages';
import VehiclesPage from 'pages/VehiclesPage/messages';
import WMSPage from 'pages/WMSPage/messages';
// Messages
import global from 'utils/messages';
// Locale data

/* eslint-disable array-element-newline */
const messages = merge.all([
    global,
    errorMessage,

    // locales
    Storage,

    // commons
    Navigation,
    Header,
    Footer,
    OrderStatusesMapper,
    DocumentTypesMapper,
    CashboxRSTIcon,

    // pages
    ProfilePage,
    OrdersPage,
    OrderPage,
    ExceptionPage,
    AddOrderPage,
    AddInteractionPage,
    DashboardPage,
    ComplexesPage,
    RolePage,
    PackagePage,
    ClientsPage,
    ClientPage,
    ReceivablesAndPayablesPage,
    ClientHotOperationsPage,
    EmployeesPage,
    AddEmployeePage,
    ChartPage,
    ReviewsPage,
    ReviewPage,
    CallsPage,
    BusinessPackagePage,
    ManagerRolePage,
    DiagnosticPatternsPage,
    AgreementPage,
    AvailabilitiesPage,
    RequisiteSettingPage,
    GraphicReportsPage,
    NewDocumentPage,
    SupplierPage,
    SuppliersPage,
    ReportOrdersPage,
    ReportAnalyticsPage,
    ReportLoadKPIPage,
    ReportCashFlowPage,
    ReportCashOrdersLogsPage,
    AccountPlanPage,
    RepairMapSettingPage,
    Locations,
    SyncImportExport,
    ProductPage,
    WMSPage,
    RegulationsPage,
    DirectoriesPage,
    VehiclePage,
    VehiclesPage,
    ReportsPage,
    PricelistsPage,
    OnlineServiceBookPage,
    DirectoryBrandsPage,
    InteractionsPage,
    SMSSettingsPage,
    DetailStatusSettingsPage,
    TasksPage,
    TaskPage,
    ReferenceBookPage,
    MyNormativePage,
    DebtMovementPage,
    SettingsPage,
    EditMakesPage,
    JournalVATPage,
    EntriesJournalPage,
    ExchangeRatePage,

    // containers
    OrdersContainer,
    OrdersFilterContainer,
    MyTasksContainer,
    PackageContainer,
    RoleContainer,
    SettingSalaryForm,
    BusinessPackageContainer,
    ManagerRoleContainer,
    UniversalFiltersContainer,
    ClientsContainer,
    ClientsDebtsContainer,
    ClientContainer,
    ClientRequisitesContainer,
    ReviewsContainer,
    StorageDocumentsContainer,
    BarcodeContainer,
    SuppliersDebtsContainer,

    // forms
    ProfileForm,
    UniversalFiltersForm,
    OrderForm,
    CancelReasonForm,
    ToSuccessForm,
    AbstractClientForm,
    OrderTaskForm,
    AddPackageForm,
    PackageForm,
    AddRoleForm,
    RoleForm,
    SwitchBusinessForm,
    EmployeeForm,
    BusinessPackageForm,
    AddBusinessPackageForm,
    ManagerRoleForm,
    LoginForm,
    SalaryReportForm,
    BrandsForm,
    StoreProductForm,
    SetDetailProductForm,
    SpreadBusinessBrandsForm,
    CashCreationForm,
    CashOrderForm,
    SubscribeForm,
    DiagnosticTable,
    ReportOrdersFilterForm,
    ReportAnalyticsForm,
    StorageDocumentForm,

    // modals
    UniversalFiltersModal,
    AddClientModal,
    InviteModal,
    OrderTaskModal,
    UniversalChartModal,
    ConfirmRescheduleModal,
    SupplierModal,
    TecDocInfoModal,
    VehicleLocationModal,
    ReportOrdersExportModal,
    ReportAnalyticsModal,
    VehicleModal,
    AddLaborOrDetailToOrderModal,
    ClientVehicleTransfer,
    SalesReportModal,
    SuppliersReportModal,
    RegisterForServiceModal,
    ChangeVehicleMileageModal,
    BrandsModal,
    BrandsCrossModal,
    StoreDocServiceModal,
    SelectOrderModal,
    RefactoredCashOrderModal,
    RemainsReportModel,
    EmployeeTaskModal,
    EmployeeSubTasksModal,
    DebtZeroingModal,
    OrderStatsInfoModal,
    AccountReceivableReportModal,
    ImportReceiptDocumentModal,

    // components
    StatusIcons,
    OrderFormTables,
    StatsCountsPanel,
    UniversalFiltersTags,
    TasksTable,
    ReportsDropdown,
    DecoratedDatePicker,
    CallsTable,
    EmployeesTable,
    EmployeeScheduleForm,
    SettingSalaryTable,
    ArrayScheduleInput,
    ArrayBreakScheduleInput,
    ChangeStatusDropdown,
    ClientFeedbackTab,
    ClientOrdersTab,
    ClientMRDsTab,
    ClientCallsTab,
    EmployeeFeedback,
    EmployeeStatistics,
    ReviewsTable,
    ReviewResponse,
    StatisticsCallsTable,
    CallsStatistics,
    PartSuggestions,
    PartAttributes,
    VehicleNumberHistory,
    CashTables,
    ClientMRDsTable,
    ReportOrdersTable,
    SubscriptionTables
]);
/* eslint-enable array-element-newline */

// Intl
const fallbackLocale = window.navigator.language || window.navigator.userLanguage === 'uk_UA' ? 'uk' : 'ru';

const setIntl = language => {
    let locale = language;
    if (locale === 'ua') {
        locale = 'uk';
    }

    return {
        locale: locale || fallbackLocale,
        messages: messages[locale || fallbackLocale]
    };
};

let persistedLocale = localStorage.getItem('_my.carbook.pro_locale') || fallbackLocale;

if (persistedLocale === 'ua') {
    persistedLocale = 'uk';
}

// const intl = createIntl(setIntl(persistedLocale), cache);
const intl = setIntl(persistedLocale);

// Numeral
// TODO: provide locale dynamic for numeral register
numeral.register('locale', 'ru', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'тыс.',
        million: 'мил.',
        billion: 'бил.',
        trillion: 'трил.'
    },
    ordinal: () => '.',
    currency: {
        symbol: '₴'
    }
});
numeral.locale('ru');

export { intl, messages, setIntl };
