exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__title---nnjza{text-align:center}.styles-m__submit---2hKgG{display:flex;justify-content:center;margin:10px 0}.styles-m__submitButton---3r4-F{margin:0 12px}.styles-m__checkbox---9bs40{margin:12px 0}.styles-m__text---16m8L{margin-top:14px}.styles-m__hiddenFormItem---3dyhk.ant-form-item{display:none}.styles-m__styledFormItem---1DHl3 .ant-form-item{display:flex;align-items:center;justify-content:flex-end}.styles-m__styledFormItem---1DHl3 .ant-input-number{width:100%}.styles-m__row---2vEpu{align-items:center}.styles-m__checkboxStyle---1ysIP,.styles-m__row---2vEpu{display:flex;justify-content:space-between}.styles-m__blockFilling---2fHUv{background:var(--lightGray);padding:14px 8px;border-radius:6px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,yBACI,iBACJ,CAEA,0BACI,YAAa,CACb,sBAAuB,CACvB,aACJ,CAEA,gCACI,aACJ,CAEA,4BACI,aACJ,CAEA,wBACI,eACJ,CAEA,gDACI,YACJ,CAEA,iDACI,YAAa,CACb,kBAAmB,CACnB,wBACJ,CAEA,oDACI,UACJ,CAGA,uBAEI,kBAEJ,CAEA,wDALI,YAAa,CAEb,6BAMJ,CAEA,gCACI,2BAA4B,CAC5B,gBAAiB,CACjB,iBACJ","file":"styles.m.css","sourcesContent":[".title {\n    text-align: center;\n}\n\n.submit {\n    display: flex;\n    justify-content: center;\n    margin: 10px 0;\n}\n\n.submitButton {\n    margin: 0 12px;\n}\n\n.checkbox {\n    margin: 12px 0;\n}\n\n.text {\n    margin-top: 14px;\n}\n\n.hiddenFormItem:global(.ant-form-item) {\n    display: none;\n}\n\n.styledFormItem :global(.ant-form-item) {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n}\n\n.styledFormItem :global(.ant-input-number) {\n    width: 100%;\n}\n\n\n.row {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.checkboxStyle {\n        display: flex;\n        justify-content: space-between;\n}\n\n.blockFilling{\n    background: var(--lightGray);\n    padding: 14px 8px;\n    border-radius: 6px;\n}"]}]);

// Exports
exports.locals = {
	"title": "styles-m__title---nnjza",
	"submit": "styles-m__submit---2hKgG",
	"submitButton": "styles-m__submitButton---3r4-F",
	"checkbox": "styles-m__checkbox---9bs40",
	"text": "styles-m__text---16m8L",
	"hiddenFormItem": "styles-m__hiddenFormItem---3dyhk",
	"styledFormItem": "styles-m__styledFormItem---1DHl3",
	"row": "styles-m__row---2vEpu",
	"checkboxStyle": "styles-m__checkboxStyle---1ysIP",
	"blockFilling": "styles-m__blockFilling---2fHUv"
};