import { CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Switch, Table } from 'antd';
import { DataItem, Numeral } from 'commons';
import { _ } from 'core-js';
import { fetchOrderForm } from 'core/forms/orderForm/duck';
import { MODALS, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import { RefactoredCashOrderModal } from 'modals';
import {
    selectFetchingVehicleOrders,
    selectVehicleOrders,
    selectVehicleOrdersSort,
    selectVehicleOrdersStats,
    setPageOrders
} from 'pages/OnlineServiceBookPage/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const DATETIME_FORMAT = 'DD.MM.YYYY';

const mapStateToProps = state => ({
    user: state.auth,
    orders: selectVehicleOrders(state),
    stats: selectVehicleOrdersStats(state),
    sort: selectVehicleOrdersSort(state),
    fetchingVehicleOrders: selectFetchingVehicleOrders(state)
});

const mapDispatchToProps = {
    setPageOrders,
    setModal,
    fetchOrderForm
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class BookCurrentOrderContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.laborsColumns = () => [
            {
                title: <FormattedMessage id='description' />,
                key: 'name',
                width: '25%',
                dataIndex: 'serviceName'
            },
            {
                title: <FormattedMessage id='price' />,
                key: 'price',
                width: '5%',
                dataIndex: 'price',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral> ₴
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_docs.quantity' />,
                key: 'count',
                width: '5%',
                dataIndex: 'count'
            },
            {
                title: <FormattedMessage id='sum' />,
                key: 'sum',
                width: '5%',
                dataIndex: 'sum',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral> ₴
                        </div>
                    );
                }
            },
            {
                key: 'agreement',
                width: '5%',
                dataIndex: 'agreement',
                render: data => (
                    <div>
                        {data == 'UNDEFINED' ? (
                            <QuestionCircleOutlined
                                style={{
                                    fontSize: 18,
                                    verticalAlign: 'sub',
                                    marginRight: 8
                                }}
                            />
                        ) : data == 'AGREED' ? (
                            <CheckCircleOutlined
                                style={{
                                    fontSize: 18,
                                    verticalAlign: 'sub',
                                    marginRight: 8,
                                    color: 'var(--green)'
                                }}
                            />
                        ) : data == 'REJECTED' ? (
                            <CloseCircleOutlined
                                style={{
                                    fontSize: 18,
                                    marginRight: 8,
                                    color: 'rgb(255, 126, 126)'
                                }}
                            />
                        ) : (
                            '-'
                        )}
                    </div>
                )
            }
        ];

        this.appurtenancesColumns = () => [
            {
                title: <FormattedMessage id='description' />,
                key: 'name',
                width: '15%',
                dataIndex: 'name'
            },
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brandId',
                width: '10%',
                dataIndex: 'supplierBrandName'
            },
            {
                title: <FormattedMessage id='price' />,
                key: 'price',
                width: '5%',
                dataIndex: 'price',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral> ₴
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_docs.quantity' />,
                key: 'count',
                width: '5%',
                dataIndex: 'count'
            },
            {
                title: <FormattedMessage id='sum' />,
                key: 'sum',
                width: '5%',
                dataIndex: 'sum',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral> ₴
                        </div>
                    );
                }
            },
            {
                key: 'agreement',
                width: '5%',
                dataIndex: 'agreement',
                render: data => (
                    <div>
                        {data == 'UNDEFINED' ? (
                            <QuestionCircleOutlined
                                style={{
                                    fontSize: 18,
                                    verticalAlign: 'sub',
                                    marginRight: 8
                                }}
                            />
                        ) : data == 'AGREED' ? (
                            <CheckCircleOutlined
                                style={{
                                    fontSize: 18,
                                    verticalAlign: 'sub',
                                    marginRight: 8,
                                    color: 'var(--green)'
                                }}
                            />
                        ) : data == 'REJECTED' ? (
                            <CloseCircleOutlined
                                style={{
                                    fontSize: 18,
                                    marginRight: 8,
                                    color: 'rgb(255, 126, 126)'
                                }}
                            />
                        ) : (
                            '-'
                        )}
                    </div>
                )
            }
        ];

        this.generalLaborsColumns = () => [
            {
                title: <FormattedMessage id='directories.orders' />,
                key: 'orderNum',
                dataIndex: 'orderNum',
                width: '40%',
                render: (data, row) => (
                    <div>
                        <div
                            className={Styles.orderLink}
                            onClick={() => {
                                this.props.getReport({
                                    link: `/orders/reports/completedWorkReport/${row.orderId}`,
                                    name: 'completedWorkReport',
                                    token: this.props.token
                                });
                            }}
                        >
                            {row.orderNum ? row.orderNum : ''}
                        </div>
                        <div>
                            {row.recommendationToDate ? dayjs(row.recommendationToDate).format('DD.MM.YYYY HH:mm') : ''}
                            {' -'}
                        </div>
                    </div>
                )
            },
            {
                title: <FormattedMessage id='date' />,
                key: 'orderMillage',
                dataIndex: 'orderSuccessDatetime',
                align: 'center',
                width: '10%',
                render: (data, row) => {
                    return (
                        <div>
                            {row.orderSuccessDatetime ? dayjs(row.orderSuccessDatetime).format('DD.MM.YYYY HH:mm') : ''}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='client_order_tab.odometer' />,
                key: 'orderMillage',
                dataIndex: 'orderMillage',
                align: 'center',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='services_table.labor' />,
                key: 'defaultName',
                dataIndex: 'defaultName',
                align: 'center',
                width: '10%',
                sorter: (a, b) => a.defaultName && a.defaultName.localeCompare(b.defaultName),
                sortDirections: ['descend', 'ascend'],
                render: data => {
                    return <div>{data}</div>;
                }
            },
            {
                title: <FormattedMessage id='description' />,
                key: 'laborName',
                dataIndex: 'laborName',
                align: 'center',
                width: '10%',
                render: data => {
                    return <div>{data}</div>;
                }
            },
            {
                title: <FormattedMessage id='comment' />,
                key: 'recommendationComment',
                dataIndex: 'recommendationComment',
                align: 'center',
                width: '10%',
                render: data => {
                    return <div>{data || '-'}</div>;
                }
            },
            {
                title: <FormattedMessage id='status' />,
                key: 'status',
                dataIndex: 'status',
                align: 'center',
                width: '10%',
                render: (data, row) => {
                    return (
                        <div>
                            <Switch checked={row.recommendationStatus} disabled />
                        </div>
                    );
                }
            }
        ];
    }

    render() {
        const {
            orders,
            stats,
            sort,
            intl: { formatMessage },
            setPageOrders,
            fetchingVehicleOrders,
            setModal,
            fetchOrderForm,
            labors,
            appurtenances,
            generalData,
            isMobile,
            dataServiceBook,
            fetchVehicleOrders,
            recommendations
        } = this.props;

        const currentOrder = orders.filter(
            ({ status }) => status !== 'success' || status !== 'cancel' || status !== 'denied'
        );
        const laborsForCurrentOrder =
            _.get(currentOrder, '[0].id') && labors
                ? labors.filter(({ orderId }) => orderId == _.get(currentOrder, '[0].id'))
                : [];
        const appurtenancesForCurrentOrder =
            _.get(currentOrder, '[0].id') && appurtenances
                ? appurtenances.filter(({ orderId }) => orderId == _.get(currentOrder, '[0].id'))
                : [];

        console.log(currentOrder, 'currentOrder');

        return _.get(currentOrder, 'length') && !isMobile ? (
            <div>
                <div className={Styles.display}>
                    <div className={Styles.dataItemBlock}>
                        <DataItem className={Styles.dataItem} label={<FormattedMessage id='reports_page.orders' />}>
                            <div
                                className={Styles.orderLink}
                                onClick={() => {
                                    this.props.getReport({
                                        link: `/orders/reports/completedWorkReport/${
                                            _.get(currentOrder, '[0].id') || '-'
                                        }`,
                                        name: 'completedWorkReport',
                                        token: this.props.token
                                    });
                                }}
                            >
                                {_.get(currentOrder, '[0].num') || '-'}
                            </div>
                        </DataItem>
                        <DataItem className={Styles.dataItem} label={<FormattedMessage id='orders.status' />}>
                            {(
                                <span
                                    className={
                                        _.get(currentOrder, '[0].status') == 'progress'
                                            ? Styles.green
                                            : _.get(currentOrder, '[0].status') == 'success'
                                            ? Styles.blue
                                            : Styles.red
                                    }
                                >
                                    <FormattedMessage
                                        id={`order_statuses_mapper.${_.get(currentOrder, '[0].status')}`}
                                    />{' '}
                                </span>
                            ) || '-'}
                        </DataItem>
                        <DataItem
                            className={Styles.dataItem}
                            label={<FormattedMessage id='client_order_tab.odometer' />}
                        >
                            {_.get(currentOrder, '[0].odometer') || '-'}{' '}
                            <FormattedMessage id='online_service_book_page.km' />
                        </DataItem>
                        <DataItem
                            className={Styles.dataItem}
                            label={<FormattedMessage id='business-package-form.activation_datetime' />}
                        >
                            {dayjs(_.get(currentOrder, '[0].beginDatetime')).format(DATETIME_FORMAT) || '-'}
                        </DataItem>
                        <DataItem className={Styles.dataItem} label={<FormattedMessage id='sum' />}>
                            {<Numeral mask='0,0.00'>{_.get(currentOrder, '[0].totalSumWithTax')}</Numeral> || '-'}{' '}
                            <FormattedMessage id='cur' />
                        </DataItem>
                        <DataItem className={Styles.dataItem} label={<FormattedMessage id='remain' />}>
                            {<Numeral mask='0,0.00'>{_.get(currentOrder, '[0].remainingSum')}</Numeral> || '-'}{' '}
                            <FormattedMessage id='cur' />
                        </DataItem>
                        <div className={Styles.btnGroupStyle}>
                            <Button
                                disabled
                                onClick={async () => {
                                    await setModal(MODALS.REFACTORED_CASH_ORDER, {
                                        initValues: {
                                            orderId: _.get(currentOrder, '[0].id'),
                                            clientId: _.get(currentOrder, '[0].clientId'),
                                            sum: Math.round(_.get(currentOrder, '[0].remainingSum') * 100) / 100
                                        },
                                        onCashOrderCreatedCallback: () => fetchOrderForm(_.get(currentOrder, '[0].id'))
                                    });
                                }}
                                style={{
                                    marginBottom: 8,
                                    width: '100%'
                                }}
                                type='primary'
                            >
                                <FormattedMessage id='online_service_book_page.pay_for_order' />
                            </Button>
                        </div>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            maxWidth: '100%',
                            minWidth: '1%'
                        }}
                    >
                        <div className={Styles.titleForTable}>
                            <FormattedMessage id='online_service_book_page.services' />
                        </div>
                        <Table
                            bordered
                            className={Styles.table}
                            columns={this.laborsColumns()}
                            dataSource={laborsForCurrentOrder}
                            pagination={false}
                            rowKey='id'
                            scroll={{ x: 'auto', y: '30vh' }}
                            summary={() => {
                                return (
                                    <React.Fragment>
                                        <Table.Summary.Row fixed>
                                            <Table.Summary.Cell
                                                className={Styles.hidden}
                                                index={0}
                                            ></Table.Summary.Cell>
                                            <Table.Summary.Cell
                                                className={Styles.hidden}
                                                index={1}
                                            ></Table.Summary.Cell>
                                            <Table.Summary.Cell
                                                className={Styles.hidden}
                                                index={2}
                                            ></Table.Summary.Cell>
                                            <Table.Summary.Cell className={Styles.summaryWrap} index={3}>
                                                <div className={Styles.summary}>
                                                    <Numeral mask='0,0.00'>
                                                        {laborsForCurrentOrder.reduce((acc, cur) => acc + cur.sum, 0)}
                                                    </Numeral>{' '}
                                                    ₴
                                                </div>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell
                                                className={Styles.hidden}
                                                index={4}
                                            ></Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </React.Fragment>
                                );
                            }}
                        />
                        <div className={Styles.titleForTable}>
                            <FormattedMessage id='online_service_book_page.details' />
                        </div>
                        <Table
                            bordered
                            className={Styles.table}
                            columns={this.appurtenancesColumns()}
                            dataSource={appurtenancesForCurrentOrder}
                            pagination={false}
                            rowKey='id'
                            scroll={{ x: 'auto', y: '30vh' }}
                            summary={() => {
                                return (
                                    <React.Fragment>
                                        <Table.Summary.Row fixed>
                                            <Table.Summary.Cell
                                                className={Styles.hidden}
                                                index={0}
                                            ></Table.Summary.Cell>
                                            <Table.Summary.Cell
                                                className={Styles.hidden}
                                                index={1}
                                            ></Table.Summary.Cell>
                                            <Table.Summary.Cell
                                                className={Styles.hidden}
                                                index={2}
                                            ></Table.Summary.Cell>
                                            <Table.Summary.Cell
                                                className={Styles.hidden}
                                                index={3}
                                            ></Table.Summary.Cell>
                                            <Table.Summary.Cell className={Styles.summaryWrap} index={4}>
                                                <div className={Styles.summary}>
                                                    <Numeral mask='0,0.00'>
                                                        {appurtenancesForCurrentOrder.reduce(
                                                            (acc, cur) => acc + cur.sum,
                                                            0
                                                        )}
                                                    </Numeral>{' '}
                                                    ₴
                                                </div>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </React.Fragment>
                                );
                            }}
                        />
                    </div>
                </div>
                <div className={Styles.tableCont}>
                    <div className={Styles.titleForTable}>
                        <FormattedMessage id='online_service_book_page.recom' />
                    </div>
                    <Table
                        bordered
                        className={Styles.table}
                        columns={this.generalLaborsColumns()}
                        dataSource={recommendations}
                        pagination={false}
                        rowKey='id'
                        scroll={{ x: 'auto', y: '45vh' }}
                    />
                </div>
                <RefactoredCashOrderModal
                    fetchVehicleOrders={fetchVehicleOrders}
                    vehicleId={_.get(dataServiceBook, 'vehicleId')}
                />
            </div>
        ) : !_.get(currentOrder, 'length') && !isMobile ? (
            <div className={Styles.noRepairMessage}>
                <FormattedMessage id='online_service_book_page.no_current_order' />
            </div>
        ) : _.get(currentOrder, 'length') && isMobile ? (
            <div className={Styles.mobileOrderBlock}>
                <div className={Styles.firtBlockInfoStyle}>
                    <div className={Styles.dataItemMobileBlock}>
                        <DataItem
                            className={Styles.dataItemMobile}
                            label={<FormattedMessage id='reports_page.orders' />}
                        >
                            <div
                                className={Styles.orderLink}
                                onClick={() => {
                                    this.props.getReport({
                                        link: `/orders/reports/completedWorkReport/${
                                            _.get(currentOrder, '[0].id') || '-'
                                        }`,
                                        name: 'completedWorkReport',
                                        token: this.props.token
                                    });
                                }}
                            >
                                {_.get(currentOrder, '[0].num') || '-'}
                            </div>
                        </DataItem>
                        <DataItem className={Styles.dataItemMobile} label={<FormattedMessage id='orders.status' />}>
                            {(
                                <span
                                    style={{
                                        color: 'green'
                                    }}
                                >
                                    <FormattedMessage
                                        id={`order_statuses_mapper.${_.get(currentOrder, '[0].status')}`}
                                    />{' '}
                                </span>
                            ) || '-'}
                        </DataItem>
                        <DataItem
                            className={Styles.dataItemMobile}
                            label={<FormattedMessage id='client_order_tab.odometer' />}
                        >
                            {_.get(currentOrder, '[0].odometer') || '-'}{' '}
                            <FormattedMessage id='online_service_book_page.km' />
                        </DataItem>
                    </div>
                    <div className={Styles.dataItemMobileBlock}>
                        <DataItem
                            className={Styles.dataItemMobile}
                            label={<FormattedMessage id='business-package-form.activation_datetime' />}
                        >
                            {dayjs(_.get(currentOrder, '[0].deliveryDatetime')).format(DATETIME_FORMAT) || '-'}
                        </DataItem>
                        <DataItem
                            className={Styles.dataItemMobile}
                            label={<FormattedMessage id='business-package-form.activation_datetime' />}
                        >
                            {dayjs(_.get(currentOrder, '[0].beginDatetime')).format(DATETIME_FORMAT) || '-'}
                        </DataItem>
                        <DataItem className={Styles.dataItemMobile} label={<FormattedMessage id='sum' />}>
                            {<Numeral mask='0,0.00'>{_.get(currentOrder, '[0].totalSumWithTax')}</Numeral> || '-'}{' '}
                            <FormattedMessage id='cur' />
                        </DataItem>
                        <DataItem className={Styles.dataItemMobile} label={<FormattedMessage id='remain' />}>
                            {<Numeral mask='0,0.00'>{_.get(currentOrder, '[0].remainingSum')}</Numeral> || '-'}{' '}
                            <FormattedMessage id='cur' />
                        </DataItem>
                    </div>
                </div>
                <div>
                    <div className={Styles.titleForMobile}>
                        <FormattedMessage id='online_service_book_page.services' />
                    </div>
                    {_.get(laborsForCurrentOrder, 'length') ? (
                        laborsForCurrentOrder.map(({ defaultName, serviceName }, index) => (
                            <div
                                style={{
                                    padding: 2
                                }}
                            >{`${index + 1}. ${defaultName}(${serviceName});`}</div>
                        ))
                    ) : (
                        <FormattedMessage id='Відсутні дані' />
                    )}
                </div>
                <div>
                    <div className={Styles.titleForMobile}>
                        <FormattedMessage id='online_service_book_page.details' />
                    </div>
                    {_.get(appurtenancesForCurrentOrder, 'length') ? (
                        appurtenancesForCurrentOrder.map(({ name }, index) => (
                            <div
                                style={{
                                    padding: 2
                                }}
                            >{`${index + 1}. ${name};`}</div>
                        ))
                    ) : (
                        <FormattedMessage id='Відсутні дані' />
                    )}
                </div>
                <div>
                    <div className={Styles.titleForMobile}>
                        <FormattedMessage id='online_service_book_page.recom' />
                    </div>
                    {_.get(recommendations, 'length') ? (
                        recommendations.map(({ laborName, defaultName }, index) => (
                            <div
                                style={{
                                    padding: 2
                                }}
                            >{`${index + 1}. ${laborName}(${defaultName});`}</div>
                        ))
                    ) : (
                        <FormattedMessage id='Відсутні дані' />
                    )}
                </div>
                <div className={Styles.btnGroupStyle}>
                    <Button
                        disabled
                        onClick={() => {
                            setModal(MODALS.REFACTORED_CASH_ORDER, {
                                initValues: {
                                    orderId: _.get(currentOrder, '[0].id'),
                                    clientId: _.get(currentOrder, '[0].clientId'),
                                    sum: Math.round(_.get(currentOrder, '[0].remainingSum') * 100) / 100
                                },
                                onCashOrderCreatedCallback: () =>
                                    fetchVehicleOrders(_.get(dataServiceBook, 'vehicleId'))
                            });
                        }}
                        style={{
                            marginBottom: 8,
                            width: '100%'
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='online_service_book_page.pay_for_order' />
                    </Button>

                    <RefactoredCashOrderModal vehicleId={_.get(dataServiceBook, 'vehicleId')} />
                </div>
            </div>
        ) : (
            <div className={Styles.noRepairMessage}>
                <FormattedMessage id='online_service_book_page.no_current_order' />
            </div>
        );
    }
}
