import { Col, DatePicker, Modal, Row, TimePicker } from 'antd';
import dayjs from 'dayjs';
import {
    postponedTask,
    selectPostponedTaskFields,
    selectPostponedTaskModalVisibility,
    setPostponedTaskFields,
    setPostponedTaskModalVisibility
} from 'pages/TaskPage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    visible: selectPostponedTaskModalVisibility(state),
    fields: selectPostponedTaskFields(state)
});

const mapDispatchToProps = {
    setPostponedTaskFields,
    setPostponedTaskModalVisibility,
    postponedTask
    // resetFields,
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class PostponedTaskModal extends Component {
    onSubmit = () => {
        const { postponedTask } = this.props;
        postponedTask();
        this.closeModal();
    };

    closeModal = () => {
        const { setPostponedTaskModalVisibility, resetFields } = this.props;
        setPostponedTaskModalVisibility();
        // resetFields();
    };

    componentDidMount() {
        const scheduledMoment = dayjs().add(1, 'hour');
        this.props.setPostponedTaskFields({ scheduledDate: scheduledMoment });
        this.props.setPostponedTaskFields({ scheduledTime: scheduledMoment });
    }

    render() {
        const {
            visible,
            fields,
            setPostponedTaskFields,
            intl: { formatMessage }
        } = this.props;

        return (
            <div>
                <Modal
                    destroyOnClose
                    maskClosable={false}
                    onCancel={this.closeModal}
                    onOk={this.onSubmit}
                    title={
                        <div className={Styles.title}>
                            <FormattedMessage id='task_page.postponed_task' />
                        </div>
                    }
                    visible={visible}
                    // okButtonProps={{
                    //     disabled: !Boolean(performerId)
                    // }}
                >
                    <Row className={Styles.row}>
                        <Col span={8}>
                            <DatePicker
                                format='YYYY-MM-DD'
                                // defaultValue={dayjs()}
                                getCalendarContainer={trigger => trigger.parentNode}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={e => {
                                    setPostponedTaskFields({ scheduledDate: e });
                                }}
                                placeholder={formatMessage({
                                    id: 'task_page.deadline_date_placeholder'
                                })}
                                value={fields.scheduledDate}
                            />
                        </Col>
                        <Col span={8}>
                            <TimePicker
                                allowClear={false}
                                format='HH:mm'
                                getPopupContainer={trigger => trigger.parentNode}
                                minuteStep={30}
                                onChange={e => {
                                    setPostponedTaskFields({ scheduledTime: e });
                                }}
                                placeholder={formatMessage({
                                    id: 'task_page.deadline_time_placeholder'
                                })}
                                value={fields.scheduledTime}
                            />
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}
