import { Button, Input, Modal, Select, message } from 'antd';
import { DateRangePicker } from 'components';
import { selectBrands } from 'core/brands/duck';
import { fetchSuppliers } from 'core/suppliers/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { fetchAPI, goTo } from 'utils';

const { Option } = Select;

const mapStateToProps = state => ({
    brands: selectBrands(state),
    user: state.auth,
    warehouses: state.warehouses.warehouses,
    suppliers: state.suppliers.suppliers,
    clients: state.clients.clients
});

const mapDispatchToProps = {
    fetchSuppliers
};
const requiredField = () => <b style={{ color: 'var(--required)' }}> *</b>;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class AddSalaryDocModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            requisites: []
        };
    }

    componentDidMount() {
        this.getRequisites();
    }

    handleOk = async () => {
        const { startDate, endDate } = this.state;

        if (startDate && endDate) {
            this.setState({
                loading: true
            });
            await this.addSalaryRow();
            this.handleCancel();
            this.props.fetchSalariesList();
        } else {
            message.error('Оберіть період');
        }
    };

    handleCancel = () => {
        const { hideModal } = this.props;
        this.setState({
            employeeId: undefined,
            sum: undefined,
            incomeTax: undefined,
            insuranceTax: undefined,
            otherTaxes: undefined,
            startDate: undefined,
            endDate: undefined,
            selectedRequisiteId: undefined,
            loading: false
        });
        hideModal();
    };

    getRequisites = async () => {
        const data = await fetchAPI('GET', 'businesses/requisites', null, null, {
            handleErrorInternally: true
        });
        this.setState({ requisites: data });
    };

    addSalaryRow = async () => {
        const {
            employeeId,
            startDate,
            endDate,
            sum,
            incomeTax,
            insuranceTax,
            otherTaxes,
            comment,
            selectedRequisiteId
        } = this.state;
        const created = await fetchAPI(
            'POST',
            'add/salary/doc',
            null,
            {
                employeeId,
                startDate,
                endDate,
                type: 'SAL',
                status: 'NEW',
                incomeTax,
                insuranceTax,
                otherTaxes,
                requisiteId: selectedRequisiteId,
                comment: comment || ' '
            },
            { handleErrorInternally: true }
        );
        if (_.get(created, '[0].id')) {
            goTo(`${book.salariesPage}/${_.get(created, '[0].id')}`);
        }
    };

    render() {
        const { visible, allEmployees, suppliers, handleSearchEmployee } = this.props;
        const {
            employeeId,
            startDate,
            endDate,
            sum,
            incomeTax,
            insuranceTax,
            otherTaxes,
            loading,
            comment,
            requisites,
            selectedRequisiteId
        } = this.state;

        const dateFormat = 'DD-MM-YYYY';

        return (
            <Modal
                footer={
                    <div>
                        <Button
                            key='back'
                            onClick={() => {
                                this.handleCancel();
                            }}
                        >
                            <FormattedMessage id='stock_table.button.cancel' />
                        </Button>
                        <Button
                            key='submit'
                            loading={loading}
                            onClick={async () => {
                                await this.handleOk();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='ok' />
                        </Button>
                    </div>
                }
                okButtonProps={{
                    disabled: !employeeId
                }}
                onCancel={this.handleCancel}
                title={<FormattedMessage id='storage_journal.add_row' />}
                visible={visible}
                width='70%'
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 8,
                            paddingBottom: 8
                        }}
                    >
                        <div>
                            <FormattedMessage id='cash-order-form.counterparty.EMPLOYEE' />
                            {requiredField()}
                            <Select
                                filterOption={(input, option) => {
                                    const searchValue = option.children.toLowerCase().replace(/[+()-\s]/g, '');
                                    const inputValue = input.toLowerCase();

                                    return searchValue.indexOf(inputValue) >= 0;
                                }}
                                getPopupContainer={trigger => trigger.parentNode}
                                onSearch={input => handleSearchEmployee(input)}
                                onSelect={value => this.setState({ employeeId: value })}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'search'
                                })}
                                showSearch
                                style={{ display: 'block', width: '300px' }}
                                value={employeeId}
                            >
                                {allEmployees
                                    .filter(({ disabled }) => !disabled)
                                    .map(({ id, name, surname }) => (
                                        <Option key={id} value={id}>
                                            {`${name} ${surname}`}
                                        </Option>
                                    ))}
                            </Select>
                        </div>
                        <div>
                            <FormattedMessage id='storage_document.business_requisites' />
                            <Select
                                fieldNames={{value: 'id', label: 'name'}}
                            value={selectedRequisiteId}
                                // filterOption={(input, option) => {
                                //     const searchValue = option.children.toLowerCase().replace(/[+()-\s]/g, '');
                                //     const inputValue = input.toLowerCase();

                                //     return searchValue.indexOf(inputValue) >= 0;
                                // }}
                                // getPopupContainer={trigger => trigger.parentNode}
                                onSelect={value => this.setState({ selectedRequisiteId: value })}
                                // placeholder={this.props.intl.formatMessage({
                                //     id: 'search'
                                // })}
                                options={requisites}
                                showSearch
                                style={{ display: 'block', width: '300px' }}
                                // value={employeeId}
                            />
                            {/* {allEmployees
                                    .filter(({ disabled }) => !disabled)
                                    .map(({ id, name, surname }) => (
                                        <Option key={id} value={id}>
                                            {`${name} ${surname}`}
                                        </Option>
                                    ))}
                            </Select> */}
                        </div>
                    </div>
                    <div>
                        <FormattedMessage id='employee_salary_rules.start_period' />
                        {requiredField()}
                        <DateRangePicker
                            allowClear
                            dateRange={[startDate, endDate]}
                            onDateChange={([startDate, endDate]) => {
                                this.setState({ startDate, endDate });
                            }}
                            popupStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: 200
                            }}
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
                <div>
                    <div>
                        <FormattedMessage id='comment' />
                    </div>
                    <span>
                        <Input.TextArea
                            onChange={e => {
                                this.setState({
                                    comment: e.target.value
                                });
                            }}
                            type='textarea'
                            value={comment}
                        />
                    </span>
                </div>
            </Modal>
        );
    }
}
