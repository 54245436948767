import { DeleteOutlined, EditOutlined, FolderAddOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popover, notification } from 'antd';
import { Block, DataItem } from 'commons';
import Barcode from 'components/Barcode';
import { setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import { AddAggregateModal, AggregateTransferModal, VehicleModal } from 'modals';
import {
    createOrder,
    deleteVehicle,
    fetchVehicle,
    fetchVehicleAttributes,
    fetchVehicleOrdersLatest,
    selectClient,
    selectFetchingOrdersLatest,
    selectFetchingVehicle,
    selectFetchingVehicleAttributes,
    selectFetchingVehicleClient,
    selectGeneralData,
    /* -------Selectors----*/
    selectVehicle,
    selectVehicleAttributes,
    sendEmailWithServiceBookLink,
    sendSmsWithServiceBookLink
} from 'pages/VehiclePage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import book from 'routes/book';
import { fetchAPI, goTo } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    vehicle: selectVehicle(state),
    client: selectClient(state),
    generalData: selectGeneralData(state),
    vehicleAttributes: selectVehicleAttributes(state),
    fetchingVehicle: selectFetchingVehicle(state),
    fetchingVehicleClient: selectFetchingVehicleClient(state),
    fetchingOrdersLatest: selectFetchingOrdersLatest(state),
    fetchingVehicleAttributes: selectFetchingVehicleAttributes(state)
});

const mapDispatchToProps = {
    setModal,
    fetchVehicle,
    fetchVehicleAttributes,
    fetchVehicleOrdersLatest,
    sendEmailWithServiceBookLink,
    sendSmsWithServiceBookLink,

    createOrder,
    deleteVehicle
};

/**
 * Supposed to represent general vehicle informations and its specific parameters.
 */
@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientAggregateTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleAddAggregateModal: false
        };
    }

    /**
     * This event handler is used to create an order which will contain specific client and may contain vehicle if id was provided
     * @param {*} param0 Contains clientId which is used to define client in order and vehicleId of this client
     */

    onCreateOrder = async id => {
        const { user, clientId } = this.props;

        if (!clientId) {
            notification.error({
                message: this.props.intl.formatMessage({
                    id: 'vehicle_page.add_owner_vehicle'
                })
            });
        } else {
            const orders = await fetchAPI(
                'POST',
                '/orders',
                null,
                {
                    clientAggregateId: id,
                    clientId,
                    managerId: user.id,
                    duration: 0.5,
                    stationLoads: [
                        {
                            beginDatetime: dayjs().startOf('hour').toISOString(),
                            duration: 0.5,
                            status: 'TO_DO'
                        }
                    ],
                    status: 'not_complete',
                    beginDatetime: dayjs().startOf('hour').toISOString(),
                    orderBloke: 'UNIT_REPAIR'
                },
                {
                    handleErrorInternally: true
                }
            );

            if (orders && orders.created) {
                goTo(`${book.order}/${orders.created[0].id}`);
            }
        }
    };

    editArr = row => {
        this.setState({
            visibleAddAggregateModal: true,
            addAggregateModalRow: row
        });
    };

    render() {
        const {
            user: { businessId },

            user,
            clientId,
            intl: { formatMessage },
            disabled,
            clientsAggregates
        } = this.props;

        const { visibleAddAggregateModal, addAggregateModalRow } = this.state;

        return (
            <div className={Styles.tabContent}>
                {clientsAggregates.map(
                    ({
                        aggregateNumber,
                        brandName,
                        brandId,
                        code,
                        comment,
                        count,
                        level,
                        name,
                        clientAggregateId,
                        aggregateId,
                        vin,
                        barcode
                    }) => (
                        <Block
                            className={Styles.block}
                            controls={
                                !disabled && (
                                    <div>
                                        <Popover content={<FormattedMessage id='vehicle_page.hint_barcode_modal' />}>
                                            <span style={{ width: '2em' }}>
                                                <Barcode
                                                    barcodeClassName={Styles.barcodeIcon}
                                                    iconStyle={{
                                                        fontSize: 24,
                                                        marginLeft: 4
                                                    }}
                                                    onConfirm={() => {
                                                        window.location.reload();
                                                    }}
                                                    prefix='CVH'
                                                    provideHint={false}
                                                    referenceId={clientAggregateId}
                                                    table='CLIENTS_AGGREGATES'
                                                    value={barcode}
                                                />
                                            </span>
                                        </Popover>

                                        <Popover
                                            content={<FormattedMessage id='add_client_form.aggregate_hint_edit' />}
                                        >
                                            <EditOutlined
                                                className={Styles.editIcon}
                                                onClick={() => {
                                                    this.editArr({
                                                        aggregateId,
                                                        clientAggregateId,
                                                        name: name.ua,
                                                        brandId,
                                                        aggregateNumber,
                                                        count,
                                                        comment,
                                                        vin
                                                    });
                                                }}
                                            />
                                        </Popover>

                                        <Popover content={<FormattedMessage id='add_client_form.aggregate_hint_add' />}>
                                            <FolderAddOutlined
                                                className={Styles.editIcon}
                                                onClick={() => {
                                                    this.setState({
                                                        visibleAddAggregateModal: true
                                                    });
                                                }}
                                            />
                                        </Popover>

                                        <Popover
                                            content={<FormattedMessage id='add_client_form.aggregate_hint_delete' />}
                                        >
                                            <DeleteOutlined
                                                className={Styles.deleteIcon}
                                                onClick={async () => {
                                                    await fetchAPI(
                                                        'DELETE',
                                                        `/client/aggregates/${clientAggregateId}`,
                                                        null,
                                                        null,
                                                        {
                                                            handleErrorInternally: true
                                                        }
                                                    );
                                                    await this.getClientsAggregates();
                                                }}
                                            />
                                        </Popover>
                                        {isGrantAccessed(
                                            user,
                                            grants.DIRECTORIES_CUSTOMERS_LIST_AGGREGATES_CAR_OWNER_CHANGE
                                        ) && (
                                            <Popover
                                                content={
                                                    <FormattedMessage id='vehicle_page.hint_change_aggregate_owner' />
                                                }
                                            >
                                                <span className={Styles.changeVehicleOwnerIcon}>
                                                    <AggregateTransferModal
                                                        aggregateId={aggregateId}
                                                        brandId={brandId}
                                                        id={clientAggregateId}
                                                    />
                                                </span>
                                            </Popover>
                                        )}

                                        <Popover
                                            content={
                                                <FormattedMessage id='vehicle_page.hint_create_order_for_this_vehicle' />
                                            }
                                        >
                                            <Button
                                                className={Styles.iconButton}
                                                onClick={() => this.onCreateOrder(clientAggregateId)}
                                                type='primary'
                                            >
                                                <PlusOutlined className={Styles.plusIcon} />
                                            </Button>
                                        </Popover>
                                    </div>
                                )
                            }
                            title={<FormattedMessage id='cleint_container_aggregate' />}
                        >
                            <div>
                                <DataItem
                                    className={Styles.dataItem}
                                    label={<FormattedMessage id='add_client_form.aggregate_number' />}
                                >
                                    {aggregateNumber}
                                </DataItem>
                                <DataItem
                                    className={Styles.dataItem}
                                    label={<FormattedMessage id='add_client_form.aggregate_brand' />}
                                >
                                    {brandName}
                                </DataItem>
                                <DataItem
                                    className={Styles.dataItem}
                                    label={<FormattedMessage id='add_client_form.aggregate_code' />}
                                >
                                    {code}
                                </DataItem>
                                <DataItem
                                    className={Styles.dataItem}
                                    label={<FormattedMessage id='add_client_form.aggregate_name' />}
                                >
                                    {name.ua}
                                </DataItem>
                                <DataItem
                                    className={Styles.dataItem}
                                    label={<FormattedMessage id='add_client_form.aggregate_count' />}
                                >
                                    {count}
                                </DataItem>
                                <DataItem
                                    className={Styles.dataItem}
                                    label={<FormattedMessage id='add_order_form.vin' />}
                                >
                                    {vin}
                                </DataItem>
                                <DataItem
                                    className={Styles.dataItem}
                                    label={<FormattedMessage id='add_client_form.aggregate_level' />}
                                >
                                    {level}
                                </DataItem>
                                <DataItem className={Styles.dataItem} label={<FormattedMessage id='comment' />}>
                                    {comment}
                                </DataItem>
                            </div>
                        </Block>
                    )
                )}

                <AddAggregateModal
                    addAggregateModalRow={addAggregateModalRow}
                    clientId={clientId}
                    getClientsAggregates={this.getClientsAggregates}
                    hideModal={() => {
                        this.setState({
                            visibleAddAggregateModal: false,
                            addAggregateModalRow: undefined
                        });
                    }}
                    visible={visibleAddAggregateModal}
                />
                <VehicleModal />
            </div>
        );
    }
}
