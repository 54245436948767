import book from 'routes/book';
import { grants } from './grants';

export const routeToGrant = {
    // PROFILE
    [book.profile]: grants.DIRECTORIES_USER_SETTINGS,

    // DIRECTORIES
    [book.aggregatesPage]: grants.DIRECTORIES_AGGREGATES_LIST,
    [book.exchangeRate]: grants.DIRECTORIES_CURRENCIES,
    [book.clientHotOperations]: grants.DIRECTORIES_CUSTOMERS_LIST,
    [book.complexes]: grants.DIRECTORIES_COMPLEXES,
    [book.crosses]: grants.DIRECTORIES_CROSSES,
    [book.myNormative]: grants.DIRECTORIES_STANDARDS,
    [book.suppliersPage]: grants.DIRECTORIES_SUPPLIERS_LIST,
    [book.employeesPage]: grants.DIRECTORIES_EMPLOYEES_LIST,
    [book.editEmployee]: grants.DIRECTORIES_EMPLOYEE_CARD,
    [book.laborsPage]: grants.DIRECTORIES_JOBS,
    [book.products]: grants.DIRECTORIES_PRODUCTS_LIST,

    // SETTINGS
    [book.settingsDirectoriesPage]: grants.SETTINGS,
    [book.settings]: grants.SETTINGS_BUSINESS_SETTINGS,
    [book.apiSettings]: grants.SETTINGS_API_PROVIDERS,
    [book.intDirectories]: grants.SETTINGS_CRM,
    [book.priceGroups]: grants.SETTINGS_MARKUP_GROUPS,
    [book.productsGroups]: grants.SETTINGS_PRODUCT_GROUPS,
    [book.source]: grants.SETTINGS_SOURCE,
    [book.cashSettingsPage]: grants.SETTINGS_CASH_REGISTERS,
    [book.wms]: grants.SETTINGS_CELLS,
    [book.themeSettings]: grants.SETTINGS_THEME_SETTINGS,
    [book.referenceBookPage]: grants.SETTINGS_NUMBERING,
    [book.units]: grants.SETTINGS_UNITS_OF_MEASURE,
    [book.rights]: grants.SETTINGS_PERMISSIONS_AND_ACCESS,
    [book.availabilitiesPage]: grants.SETTINGS_SUPPLIER_PRICES,
    [book.analytics]: grants.SETTINGS_INVOICES_AND_ANALYTICS,
    [book.requisites]: grants.SETTINGS_DETAILS,
    [book.warehouses]: grants.SETTINGS_STORES,
    [book.smsSettings]: grants.SETTINGS_SMS,
    [book.detailStatusSettings]: grants.SETTINGS_PART_STATUSES,
    [book.repairMapSetting]: grants.SETTINGS_ORDER_STATUSES,
    [book.webSettings]: grants.SETTINGS_WEB_SETTINGS,
    [book.locationSettings]: grants.SETTINGS_LOCATIONS,
    [book.regulations]: grants.SETTINGS_REGULATIONS,
    [book.diagnosticsDirectory]: grants.DIRECTORIES_DIAGNOSTICS,

    // OPERATIONS
    [book.dashboard]: grants.OPERATIONS_PLANNER,
    [book.orders]: grants.OPERATIONS_PLANNER,
    [book.orderId]: grants.OPERATIONS_ORDER_DOCUMENT,
    [book.sparePartsWorkplacePage]: grants.OPERATIONS_JOB_DETAILS,
    [book.locationsPage]: grants.OPERATIONS_LOCATIONS_LOCATION_LIST,
    [book.locationsVehicles]: grants.OPERATIONS_LOCATIONS_CARS_BY_LOCATIONS,

    // APM
    [book.newDocumentPage]: grants.WORKPLACE_QUICK_NAVIGATION,
    [book.barcodePage]: grants.WORKPLACE_BARCODES,
    [book.journalsPage]: grants.WORKPLACE_JOURNALS,

    // Accounting
    [book.cashBankPage]: grants.ACCOUNTING_CASH_REGISTERS,
    [book.cashFlowPage]: grants.ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER,
    [book.receivablesAndPayablesPage]: grants.ACCOUNTING_OUTSTANDING_DEBTS,
    [book.salariesPage]: grants.ACCOUNTING_SALARIES_DOCUMENT_LIST,
    [book.journalVAT]: grants.ACCOUNTING_VAT_JOURNAL,
    [book.accDocs]: grants.ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST,
    [book.accountPlan]: grants.ACCOUNTING_GENERAL_LEDGER_CHART_OF_ACCOUNTS,
    [book.journalEntries]: grants.ACCOUNTING_JOURNAL_ENTRIES,
    [book.storageCalculations]: grants.ACCOUNTING_SETTLEMENT_JOURNAL,
    [book.reportCashOrdersLogs]: grants.REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_REGISTER_DEVICES,

    // Administation
    [book.pricelistsPage]: grants.ADMINISTRATION_PRICELISTS,
    [book.brands]: grants.ADMINISTRATION_BRANDS,
    [book.syncImportPage]: grants.ADMINISTRATION_SYNCHRONIZATION_IMPORT,
    [book.syncExportPage]: grants.ADMINISTRATION_SYNCHRONIZATION_BACKUP,
    [book.editMakes]: grants.ADMINISTRATION_CAR_MANAGEMENT,
    [book.businessPackagePage]: grants.ADMINISTRATION_BUSINESS_PACKAGES,

    // Reports
    [book.reportGraphic]: grants.REPORTS_GRAPHICAL_REPORTS,
    [book.reportsPage]: grants.REPORTS_XLSX_REPORTS,

    // CRM
    [book.interactions]: grants.CRM_INTERACTIONS_LIST,
    [book.interactionId]: grants.CRM_INTERACTION_WINDOW,
    [book.addInteraction]: grants.CRM_INTERACTION_WINDOW,
    [book.ordersKanban]: grants.CRM_KANBAN,
    [book.callsJournal]: grants.CRM_CALL_LOG,
    [book.myTasksPage]: grants.CRM_TASKS,
    [book.feedback]: grants.CRM_REVIEWS,
    [book.directoriesPage]: grants.DIRECTORIES,
    [book.vehicles]: grants.DIRECTORIES_CARS_LIST,

    // STORAGE
    [book.storageOrders]: grants.WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS,
    [book.storageIncomes]: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS,
    [book.storageExpenses]: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES,
    [book.storageTransfers]: grants.WAREHOUSE_DOCUMENTS_LIST_MOVEMENTS,
    [book.storageBalance]: grants.WAREHOUSE_PRODUCTS_IN_STOCK,
    [book.storageTransfers]: grants.WAREHOUSE_DOCUMENTS_LIST_MOVEMENTS,
    [book.storageJournal]: grants.WAREHOUSE_JOURNAL,
    // [book.wms]: grants.WAREHOUSE_CELLS_WMS
};
