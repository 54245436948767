'use strict';

/**
  * DO NOT EDIT THE ENTRIES MANUALLY AS IT MAY BREAK THE ACCESSES!
  * THIS FILE SHOULD BE COPIED TO EACH MICROSERVICE WHEN CHANGED!
  * SHOULD BE UPDATED EACH OBJECT WHEN CHANGED!
  */

const accesses = {
  /*
    The entries below are AUTO-GENERATED using this script:
     select 
      rlx_access || ': ' || rlx_id || ', '
     from role_accesses_tbl 
     order by rlx_id;
  */
  NA: 0, 
  AD: 1, 
  RO: 2, 
  RA: 3, 
  ROWO: 4, 
  RAWO: 5, 
  WA: 6, 
}

// Custom script
const accessMapping = {
  [accesses.NA]: [],
  [accesses.AD]: [],
  [accesses.RO]: [accesses.RO],
  [accesses.RA]: [accesses.RA, accesses.RO],
  [accesses.ROWO]: [accesses.ROWO, accesses.RO],
  [accesses.RAWO]: [accesses.RAWO, accesses.RA, accesses.RO, accesses.ROWO],
  [accesses.WA]: [accesses.WA, accesses.RA, accesses.RO, accesses.RAWO, accesses.ROWO],
};


const grants = {
  ADMIN: 'ADMIN', // Адмін права
  /*
    The entries below are AUTO-GENERATED using this script:
     select 
      ' ' || spt_code || ': ''' || spt_code || ''', // ' || spt_name
     from standard_packages_tree_tbl 
     order by spt_index;
  */
  SETTINGS: 'SETTINGS', // НАЛАШТУВАННЯ
  SETTINGS_API_PROVIDERS: 'SETTINGS_API_PROVIDERS', // API постачальників
  SETTINGS_CRM: 'SETTINGS_CRM', // CRM
  SETTINGS_MARKUP_GROUPS: 'SETTINGS_MARKUP_GROUPS', // Групи націнок
  SETTINGS_PRODUCT_GROUPS: 'SETTINGS_PRODUCT_GROUPS', // Групи товарів
  SETTINGS_SOURCE: 'SETTINGS_SOURCE', // Джерело
  SETTINGS_CASH_REGISTERS: 'SETTINGS_CASH_REGISTERS', // Каси
  SETTINGS_CELLS: 'SETTINGS_CELLS', // Комірки
  SETTINGS_BUSINESS_SETTINGS: 'SETTINGS_BUSINESS_SETTINGS', // Налаштування СТО
  SETTINGS_BUSINESS_SETTINGS_GENERAL_INFORMATION: 'SETTINGS_BUSINESS_SETTINGS_GENERAL_INFORMATION', // загальна інформація
  SETTINGS_BUSINESS_SETTINGS_ADDRESS_AND_MAP: 'SETTINGS_BUSINESS_SETTINGS_ADDRESS_AND_MAP', // адреса і мапа
  SETTINGS_BUSINESS_SETTINGS_WORKING_HOURS: 'SETTINGS_BUSINESS_SETTINGS_WORKING_HOURS', // час роботи
  SETTINGS_BUSINESS_SETTINGS_POSTS: 'SETTINGS_BUSINESS_SETTINGS_POSTS', // пости
  SETTINGS_BUSINESS_SETTINGS_COUNTRY: 'SETTINGS_BUSINESS_SETTINGS_COUNTRY', // країна
  SETTINGS_BUSINESS_SETTINGS_PRICES: 'SETTINGS_BUSINESS_SETTINGS_PRICES', // ціни
  SETTINGS_BUSINESS_SETTINGS_PRINTED_FORMS: 'SETTINGS_BUSINESS_SETTINGS_PRINTED_FORMS', // друковані форма
  SETTINGS_BUSINESS_SETTINGS_ACCOUNTING: 'SETTINGS_BUSINESS_SETTINGS_ACCOUNTING', // облік
  SETTINGS_BUSINESS_SETTINGS_OTHER: 'SETTINGS_BUSINESS_SETTINGS_OTHER', // інше
  SETTINGS_BUSINESS_SETTINGS_OTHER_BUSINESS_NETWORK_ACCESS: 'SETTINGS_BUSINESS_SETTINGS_OTHER_BUSINESS_NETWORK_ACCESS', // доступ до мережі СТО
  SETTINGS_THEME_SETTINGS: 'SETTINGS_THEME_SETTINGS', // Налаштування теми
  SETTINGS_NUMBERING: 'SETTINGS_NUMBERING', // Нумерація
  SETTINGS_UNITS_OF_MEASURE: 'SETTINGS_UNITS_OF_MEASURE', // Одиниці виміру
  SETTINGS_PERMISSIONS_AND_ACCESS: 'SETTINGS_PERMISSIONS_AND_ACCESS', // Права і доступи
  SETTINGS_PERMISSIONS_AND_ACCESS_STANDARD_PACKAGES: 'SETTINGS_PERMISSIONS_AND_ACCESS_STANDARD_PACKAGES', // Стандартні пакети
  SETTINGS_PERMISSIONS_AND_ACCESS_STANDARD_ROLES: 'SETTINGS_PERMISSIONS_AND_ACCESS_STANDARD_ROLES', // Стандартні ролі
  SETTINGS_PERMISSIONS_AND_ACCESS_BUSINESS_PACKAGE: 'SETTINGS_PERMISSIONS_AND_ACCESS_BUSINESS_PACKAGE', // Пакет СТО
  SETTINGS_PERMISSIONS_AND_ACCESS_BUSINESS_ROLES: 'SETTINGS_PERMISSIONS_AND_ACCESS_BUSINESS_ROLES', // Ролі СТО
  SETTINGS_SUPPLIER_PRICES: 'SETTINGS_SUPPLIER_PRICES', // Прайси постачальників
  SETTINGS_INVOICES_AND_ANALYTICS: 'SETTINGS_INVOICES_AND_ANALYTICS', // Рахункі і аналітики
  SETTINGS_INVOICES_AND_ANALYTICS_ANALYTICS: 'SETTINGS_INVOICES_AND_ANALYTICS_ANALYTICS', // аналітики
  SETTINGS_INVOICES_AND_ANALYTICS_INVOICES: 'SETTINGS_INVOICES_AND_ANALYTICS_INVOICES', // рахунки
  SETTINGS_INVOICES_AND_ANALYTICS_ACCOUNTING_OPERATIONS: 'SETTINGS_INVOICES_AND_ANALYTICS_ACCOUNTING_OPERATIONS', // бухгалтерські операції
  SETTINGS_REQUISITES: 'SETTINGS_REQUISITES', // Реквізити
  SETTINGS_STORES: 'SETTINGS_STORES', // Склади
  SETTINGS_SMS: 'SETTINGS_SMS', // СМС
  SETTINGS_PART_STATUSES: 'SETTINGS_PART_STATUSES', // Статуси запчастин
  SETTINGS_ORDER_STATUSES: 'SETTINGS_ORDER_STATUSES', // Статуси наряд-замовлень
  SETTINGS_ORDER_STATUSES_ORDER_STATUSES: 'SETTINGS_ORDER_STATUSES_ORDER_STATUSES', // статуси н/з
  SETTINGS_ORDER_STATUSES_STATUS_TRANSITIONS: 'SETTINGS_ORDER_STATUSES_STATUS_TRANSITIONS', // переходи між статусами
  SETTINGS_ORDER_STATUSES_ORDER_STATUS_CHECK: 'SETTINGS_ORDER_STATUSES_ORDER_STATUS_CHECK', // перевірка н/з
  SETTINGS_WEB_SETTINGS: 'SETTINGS_WEB_SETTINGS', // Веб налаштування
  SETTINGS_LOCATIONS: 'SETTINGS_LOCATIONS', // Локації
  SETTINGS_REGULATIONS: 'SETTINGS_REGULATIONS', // Регламенти
  DIRECTORIES: 'DIRECTORIES', // ДОВІДНИКИ
  DIRECTORIES_CARS_LIST: 'DIRECTORIES_CARS_LIST', // Автомобілі (список)
  DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD: 'DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD', // Загальна інформація (картка)
  DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD_CAR_INFO: 'DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD_CAR_INFO', // Інфо по а/м
  DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD_CAR_OWNER_CHANGE: 'DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD_CAR_OWNER_CHANGE', // Зміна власника а/м
  DIRECTORIES_CARS_LIST_INSPECTION: 'DIRECTORIES_CARS_LIST_INSPECTION', // Огляд
  DIRECTORIES_CARS_LIST_STANDARD_HOURS: 'DIRECTORIES_CARS_LIST_STANDARD_HOURS', // Нормо-години
  DIRECTORIES_CARS_LIST_VIN: 'DIRECTORIES_CARS_LIST_VIN', // ВІН
  DIRECTORIES_CARS_LIST_REPAIRS: 'DIRECTORIES_CARS_LIST_REPAIRS', // Ремонти
  DIRECTORIES_CARS_LIST_WORKS: 'DIRECTORIES_CARS_LIST_WORKS', // Роботи
  DIRECTORIES_CARS_LIST_PARTS: 'DIRECTORIES_CARS_LIST_PARTS', // Запчастини
  DIRECTORIES_CARS_LIST_RECOMMENDATIONS: 'DIRECTORIES_CARS_LIST_RECOMMENDATIONS', // Рекомендації
  DIRECTORIES_CARS_LIST_REGULATIONS: 'DIRECTORIES_CARS_LIST_REGULATIONS', // Регламенти
  DIRECTORIES_CARS_LIST_MILEAGE: 'DIRECTORIES_CARS_LIST_MILEAGE', // Пробіг
  DIRECTORIES_AGGREGATES_LIST: 'DIRECTORIES_AGGREGATES_LIST', // Агрегати (список)
  DIRECTORIES_AGGREGATES_LIST_GENERAL_INFORMATION_CARD: 'DIRECTORIES_AGGREGATES_LIST_GENERAL_INFORMATION_CARD', // Загальна інформація (картка)
  DIRECTORIES_AGGREGATES_LIST_GENERAL_INFORMATION_CARD_CAR_OWNER_CHANGE: 'DIRECTORIES_AGGREGATES_LIST_GENERAL_INFORMATION_CARD_CAR_OWNER_CHANGE', // Зміна власника а/м
  DIRECTORIES_AGGREGATES_LIST_VIN: 'DIRECTORIES_AGGREGATES_LIST_VIN', // ВІН
  DIRECTORIES_AGGREGATES_LIST_REPAIRS: 'DIRECTORIES_AGGREGATES_LIST_REPAIRS', // Ремонти
  DIRECTORIES_AGGREGATES_LIST_WORKS: 'DIRECTORIES_AGGREGATES_LIST_WORKS', // Роботи
  DIRECTORIES_AGGREGATES_LIST_PARTS: 'DIRECTORIES_AGGREGATES_LIST_PARTS', // Запчастини
  DIRECTORIES_AGGREGATES_LIST_RECOMMENDATIONS: 'DIRECTORIES_AGGREGATES_LIST_RECOMMENDATIONS', // Рекомендації
  DIRECTORIES_CURRENCIES: 'DIRECTORIES_CURRENCIES', // Валюти
  DIRECTORIES_DIAGNOSTICS: 'DIRECTORIES_DIAGNOSTICS', // Діагностика
  DIRECTORIES_CUSTOMERS_LIST: 'DIRECTORIES_CUSTOMERS_LIST', // Клієнти (список)
  DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD: 'DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD', // Загальна інформація (картка)
  DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD_CAR_OWNER_CHANGE: 'DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD_CAR_OWNER_CHANGE', // Зміна власника а/м
  DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD_MARKUP_GROUP: 'DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD_MARKUP_GROUP', // Група націнки
  DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD_ACCOUNTING_BY_ACCOUNT: 'DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD_ACCOUNTING_BY_ACCOUNT', // Облік по бух.рахунку
  DIRECTORIES_CUSTOMERS_LIST_CARS: 'DIRECTORIES_CUSTOMERS_LIST_CARS', // Автомобілі
  DIRECTORIES_CUSTOMERS_LIST_CARS_CAR_OWNER_CHANGE: 'DIRECTORIES_CUSTOMERS_LIST_CARS_CAR_OWNER_CHANGE', // Зміна власника а/м
  DIRECTORIES_CUSTOMERS_LIST_AGGREGATES: 'DIRECTORIES_CUSTOMERS_LIST_AGGREGATES', // Агрегати
  DIRECTORIES_CUSTOMERS_LIST_AGGREGATES_CAR_OWNER_CHANGE: 'DIRECTORIES_CUSTOMERS_LIST_AGGREGATES_CAR_OWNER_CHANGE', // Зміна власника а/м
  DIRECTORIES_CUSTOMERS_LIST_ORDERS: 'DIRECTORIES_CUSTOMERS_LIST_ORDERS', // Замовлення
  DIRECTORIES_CUSTOMERS_LIST_REVIEWS: 'DIRECTORIES_CUSTOMERS_LIST_REVIEWS', // Відгуки
  DIRECTORIES_CUSTOMERS_LIST_REQUISITES: 'DIRECTORIES_CUSTOMERS_LIST_REQUISITES', // Реквізити
  DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS: 'DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS', // Заборгованість
  DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS_PAIRING_AND_UNPAIRING: 'DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS_PAIRING_AND_UNPAIRING', // Парування і розпарування
  DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS_PAYMENT: 'DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS_PAYMENT', // Оплата
  DIRECTORIES_CUSTOMERS_LIST_CALLS: 'DIRECTORIES_CUSTOMERS_LIST_CALLS', // Дзвінки
  DIRECTORIES_CUSTOMERS_LIST_CALLS_LISTENING: 'DIRECTORIES_CUSTOMERS_LIST_CALLS_LISTENING', // Прослуховування
  DIRECTORIES_CUSTOMERS_LIST_DISCOUNTS: 'DIRECTORIES_CUSTOMERS_LIST_DISCOUNTS', // Знижки
  DIRECTORIES_CUSTOMERS_LIST_INTERACTIONS: 'DIRECTORIES_CUSTOMERS_LIST_INTERACTIONS', // Взаємодії
  DIRECTORIES_COMPLEXES: 'DIRECTORIES_COMPLEXES', // Комплекси
  DIRECTORIES_CROSSES: 'DIRECTORIES_CROSSES', // Кроси
  DIRECTORIES_STANDARDS: 'DIRECTORIES_STANDARDS', // Нормативи
  DIRECTORIES_SUPPLIERS_LIST: 'DIRECTORIES_SUPPLIERS_LIST', // Постачальники (список)
  DIRECTORIES_SUPPLIERS_LIST_GENERAL_INFORMATION_CARD: 'DIRECTORIES_SUPPLIERS_LIST_GENERAL_INFORMATION_CARD', // Загальна інформація (картка)
  DIRECTORIES_SUPPLIERS_LIST_GENERAL_INFORMATION_CARD_ACCOUNTING_BY_ACCOUNT: 'DIRECTORIES_SUPPLIERS_LIST_GENERAL_INFORMATION_CARD_ACCOUNTING_BY_ACCOUNT', // Облік по бух.рахунку
  DIRECTORIES_SUPPLIERS_LIST_REQUISITES: 'DIRECTORIES_SUPPLIERS_LIST_REQUISITES', // Реквізити
  DIRECTORIES_SUPPLIERS_LIST_ORDERS: 'DIRECTORIES_SUPPLIERS_LIST_ORDERS', // Замовлення
  DIRECTORIES_SUPPLIERS_LIST_DELIVERIES: 'DIRECTORIES_SUPPLIERS_LIST_DELIVERIES', // Поставки
  DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS: 'DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS', // Заборгованість
  DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS_PAIRING_AND_UNPAIRING: 'DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS_PAIRING_AND_UNPAIRING', // Парування і розпарування
  DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS_PAYMENT: 'DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS_PAYMENT', // Оплата
  DIRECTORIES_SUPPLIERS_LIST_INTERACTIONS: 'DIRECTORIES_SUPPLIERS_LIST_INTERACTIONS', // Взаємодії
  DIRECTORIES_EMPLOYEES_LIST: 'DIRECTORIES_EMPLOYEES_LIST', // Працівники (список)
  DIRECTORIES_EMPLOYEES_LIST_GENERAL_WORK_SCHEDULE: 'DIRECTORIES_EMPLOYEES_LIST_GENERAL_WORK_SCHEDULE', // Загальний графік робіт
  DIRECTORIES_EMPLOYEES_LIST_SALARIES: 'DIRECTORIES_EMPLOYEES_LIST_SALARIES', // Зарплати
  DIRECTORIES_EMPLOYEE_CARD: 'DIRECTORIES_EMPLOYEE_CARD', // Працівник (картка)
  DIRECTORIES_EMPLOYEE_CARD_GENERAL_DATA: 'DIRECTORIES_EMPLOYEE_CARD_GENERAL_DATA', // Загальні дані
  DIRECTORIES_EMPLOYEE_CARD_POSITIONS: 'DIRECTORIES_EMPLOYEE_CARD_POSITIONS', // Посади
  DIRECTORIES_EMPLOYEE_CARD_WORK_SCHEDULE: 'DIRECTORIES_EMPLOYEE_CARD_WORK_SCHEDULE', // Графік роботи
  DIRECTORIES_EMPLOYEE_CARD_SALARY_SETTINGS: 'DIRECTORIES_EMPLOYEE_CARD_SALARY_SETTINGS', // Налаштування з/п
  DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS: 'DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS', // Заборгованість
  DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS_PAIRING_AND_UNPAIRING: 'DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS_PAIRING_AND_UNPAIRING', // Парування і розпарування
  DIRECTORIES_EMPLOYEE_CARD_WINDOW_SETTINGS: 'DIRECTORIES_EMPLOYEE_CARD_WINDOW_SETTINGS', // Налаштування вікон
  DIRECTORIES_EMPLOYEE_CARD_STATISTICS: 'DIRECTORIES_EMPLOYEE_CARD_STATISTICS', // Статистика
  DIRECTORIES_EMPLOYEE_CARD_REVIEWS: 'DIRECTORIES_EMPLOYEE_CARD_REVIEWS', // Відгуки
  DIRECTORIES_EMPLOYEE_CARD_USER_SETTINGS: 'DIRECTORIES_EMPLOYEE_CARD_USER_SETTINGS', // Налаштування юзера
  DIRECTORIES_JOBS: 'DIRECTORIES_JOBS', // Роботи
  DIRECTORIES_PRODUCTS_LIST: 'DIRECTORIES_PRODUCTS_LIST', // Товари (список)
  DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD: 'DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD', // Загальна інформація (картка)
  DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD_SOURCE: 'DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD_SOURCE', // Джерело
  DIRECTORIES_PRODUCTS_LIST_PRICES: 'DIRECTORIES_PRODUCTS_LIST_PRICES', // Ціни
  DIRECTORIES_PRODUCTS_LIST_PRICES_CURRENCY_PRICES: 'DIRECTORIES_PRODUCTS_LIST_PRICES_CURRENCY_PRICES', // Ціни у валюті
  DIRECTORIES_PRODUCTS_LIST_SUPPLIERS: 'DIRECTORIES_PRODUCTS_LIST_SUPPLIERS', // Постачальники
  DIRECTORIES_PRODUCTS_LIST_PRODUCTION_DISASSEMBLY: 'DIRECTORIES_PRODUCTS_LIST_PRODUCTION_DISASSEMBLY', // Виробництво / Розборка
  DIRECTORIES_PRODUCTS_LIST_AVAILABILITY: 'DIRECTORIES_PRODUCTS_LIST_AVAILABILITY', // Наявність
  DIRECTORIES_PRODUCTS_LIST_WAREHOUSE_MOVEMENT: 'DIRECTORIES_PRODUCTS_LIST_WAREHOUSE_MOVEMENT', // Рух по складу
  DIRECTORIES_PRODUCTS_LIST_RESERVED: 'DIRECTORIES_PRODUCTS_LIST_RESERVED', // Резерв
  DIRECTORIES_PRODUCTS_LIST_ORDERS: 'DIRECTORIES_PRODUCTS_LIST_ORDERS', // Замовлення
  DIRECTORIES_PRODUCTS_LIST_STORAGE: 'DIRECTORIES_PRODUCTS_LIST_STORAGE', // Зберігання
  DIRECTORIES_PRODUCTS_LIST_TECHNICAL_INFORMATION: 'DIRECTORIES_PRODUCTS_LIST_TECHNICAL_INFORMATION', // Тех.інфо
  DIRECTORIES_PRODUCTS_LIST_TECHDOC_ANALOGS: 'DIRECTORIES_PRODUCTS_LIST_TECHDOC_ANALOGS', // Аналоги Текдок
  DIRECTORIES_PRODUCTS_LIST_OWN_CROSSES: 'DIRECTORIES_PRODUCTS_LIST_OWN_CROSSES', // Власні кроси
  OPERATIONS: 'OPERATIONS', // ОПЕРАЦІЇ
  OPERATIONS_PLANNER: 'OPERATIONS_PLANNER', // Планувальник
  OPERATIONS_PLANNER_1D_AND_7D_PLANNER: 'OPERATIONS_PLANNER_1D_AND_7D_PLANNER', // Планувальник 1D і 7D
  OPERATIONS_PLANNER_1D_AND_7D_PLANNER_OVERDUE_REPAIRS_TRANSFER: 'OPERATIONS_PLANNER_1D_AND_7D_PLANNER_OVERDUE_REPAIRS_TRANSFER', // Перенос просрочених ремонтів
  OPERATIONS_PLANNER_MECHANIC_PLANNER: 'OPERATIONS_PLANNER_MECHANIC_PLANNER', // Планувальник механіків
  OPERATIONS_PLANNER_MECHANIC_PLANNER_OVERDUE_REPAIRS_TRANSFER: 'OPERATIONS_PLANNER_MECHANIC_PLANNER_OVERDUE_REPAIRS_TRANSFER', // Перенос просрочених ремонтів
  OPERATIONS_ORDER_LIST: 'OPERATIONS_ORDER_LIST', // Список н/з
  OPERATIONS_ORDER_DOCUMENT: 'OPERATIONS_ORDER_DOCUMENT', // Документ н/з
  OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER: 'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER', // Заголовок н/з
  OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_REPAIR_TYPE: 'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_REPAIR_TYPE', // Тип ремонту
  OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_MODAL_PLANNER: 'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_MODAL_PLANNER', // Модалка "Планувальник"
  OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_LOCATION: 'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_LOCATION', // Локація
  OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_RESPONSIBLE: 'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_RESPONSIBLE', // Відповідальний
  OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_CAR_INFO: 'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_CAR_INFO', // Інфо по а/м
  OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_COORDINATION: 'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_COORDINATION', // Узгодження
  OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_PAYMENT: 'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_PAYMENT', // Оплата
  OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_DOCUMENT_PRINTING: 'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_DOCUMENT_PRINTING', // Друк документів
  OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_DOCUMENT_UNPOSTING: 'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_DOCUMENT_UNPOSTING', // Розпроведення документа
  OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_CLOSED_ORDER_EDITING_MILEAGE: 'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_CLOSED_ORDER_EDITING_MILEAGE', // Редагування закритого н/з (пробіг)
  OPERATIONS_ORDER_DOCUMENT_JOBS: 'OPERATIONS_ORDER_DOCUMENT_JOBS', // Роботи
  OPERATIONS_ORDER_DOCUMENT_JOBS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT: 'OPERATIONS_ORDER_DOCUMENT_JOBS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT', // Зміна цін (закупочної і продажної) і знижки
  OPERATIONS_ORDER_DOCUMENT_JOBS_COMPLEXES: 'OPERATIONS_ORDER_DOCUMENT_JOBS_COMPLEXES', // Комплекси
  OPERATIONS_ORDER_DOCUMENT_JOBS_STANDARD_HOURS: 'OPERATIONS_ORDER_DOCUMENT_JOBS_STANDARD_HOURS', // Нормо-години
  OPERATIONS_ORDER_DOCUMENT_JOBS_REPAIR_ORDER_STATUS_CHANGE: 'OPERATIONS_ORDER_DOCUMENT_JOBS_REPAIR_ORDER_STATUS_CHANGE', // Зміна статусу ПД
  OPERATIONS_ORDER_DOCUMENT_PARTS: 'OPERATIONS_ORDER_DOCUMENT_PARTS', // Запчастини
  OPERATIONS_ORDER_DOCUMENT_PARTS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT: 'OPERATIONS_ORDER_DOCUMENT_PARTS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT', // Зміна цін (закупочної і продажної) і знижки
  OPERATIONS_ORDER_DOCUMENT_PARTS_CATALOG: 'OPERATIONS_ORDER_DOCUMENT_PARTS_CATALOG', // Каталог
  OPERATIONS_ORDER_DOCUMENT_PARTS_VIN: 'OPERATIONS_ORDER_DOCUMENT_PARTS_VIN', // ВІН
  OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS: 'OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS', // Склади і резервування
  OPERATIONS_ORDER_DOCUMENT_PARTS_REPAIR_ORDER_STATUS_CHANGE: 'OPERATIONS_ORDER_DOCUMENT_PARTS_REPAIR_ORDER_STATUS_CHANGE', // Зміна статусу ПД
  OPERATIONS_ORDER_DOCUMENT_PARTS_PART_STATUS_CHANGE: 'OPERATIONS_ORDER_DOCUMENT_PARTS_PART_STATUS_CHANGE', // Зміна статусу з/ч
  OPERATIONS_ORDER_DOCUMENT_PARTS_PRICE_UPDATE_BY_API: 'OPERATIONS_ORDER_DOCUMENT_PARTS_PRICE_UPDATE_BY_API', // Оновлення ціни по API
  OPERATIONS_ORDER_DOCUMENT_HISTORY: 'OPERATIONS_ORDER_DOCUMENT_HISTORY', // Історія
  OPERATIONS_ORDER_DOCUMENT_TASKS: 'OPERATIONS_ORDER_DOCUMENT_TASKS', // Задачі
  OPERATIONS_ORDER_DOCUMENT_REQUEST: 'OPERATIONS_ORDER_DOCUMENT_REQUEST', // Заявка
  OPERATIONS_ORDER_DOCUMENT_REGULATIONS: 'OPERATIONS_ORDER_DOCUMENT_REGULATIONS', // Регламент
  OPERATIONS_ORDER_DOCUMENT_INSPECTION: 'OPERATIONS_ORDER_DOCUMENT_INSPECTION', // Огляд
  OPERATIONS_ORDER_DOCUMENT_POSTS: 'OPERATIONS_ORDER_DOCUMENT_POSTS', // Пости
  OPERATIONS_ORDER_DOCUMENT_DIAGNOSTICS: 'OPERATIONS_ORDER_DOCUMENT_DIAGNOSTICS', // Діагностика
  OPERATIONS_ORDER_DOCUMENT_DIAGNOSTICS_DIAGNOSTICS_CREATION: 'OPERATIONS_ORDER_DOCUMENT_DIAGNOSTICS_DIAGNOSTICS_CREATION', // Створення діагностики
  OPERATIONS_ORDER_DOCUMENT_DIAGNOSTICS_OWN_DIAGNOSTICS_SAVING: 'OPERATIONS_ORDER_DOCUMENT_DIAGNOSTICS_OWN_DIAGNOSTICS_SAVING', // Збереження власної діагностики
  OPERATIONS_ORDER_DOCUMENT_WORKSHOP: 'OPERATIONS_ORDER_DOCUMENT_WORKSHOP', // Цех
  OPERATIONS_ORDER_DOCUMENT_RECOMMENDATIONS: 'OPERATIONS_ORDER_DOCUMENT_RECOMMENDATIONS', // Рекомендації
  OPERATIONS_ORDER_DOCUMENT_REVIEWS: 'OPERATIONS_ORDER_DOCUMENT_REVIEWS', // Відгук
  OPERATIONS_ORDER_DOCUMENT_COMMENTS: 'OPERATIONS_ORDER_DOCUMENT_COMMENTS', // Коментарі
  OPERATIONS_ORDER_DOCUMENT_LOGS: 'OPERATIONS_ORDER_DOCUMENT_LOGS', // Логи
  OPERATIONS_ORDER_DOCUMENT_CALLS: 'OPERATIONS_ORDER_DOCUMENT_CALLS', // Дзвінки
  OPERATIONS_ORDER_DOCUMENT_CALLS_LISTENING: 'OPERATIONS_ORDER_DOCUMENT_CALLS_LISTENING', // Прослуховування
  OPERATIONS_ORDER_DOCUMENTS: 'OPERATIONS_ORDER_DOCUMENTS', // Документи
  OPERATIONS_ORDER_DOCUMENT_STATISTICS: 'OPERATIONS_ORDER_DOCUMENT_STATISTICS', // Статистика
  OPERATIONS_JOB_DETAILS: 'OPERATIONS_JOB_DETAILS', // Деталі в роботі
  OPERATIONS_JOB_DETAILS_ORDER_PARTS: 'OPERATIONS_JOB_DETAILS_ORDER_PARTS', // Запчастини в н/з
  OPERATIONS_JOB_DETAILS_WAREHOUSE_ORDER: 'OPERATIONS_JOB_DETAILS_WAREHOUSE_ORDER', // Замовлення на склад
  OPERATIONS_JOB_DETAILS_RETURN_OF_SURPLUS: 'OPERATIONS_JOB_DETAILS_RETURN_OF_SURPLUS', // Повернення надлишків
  OPERATIONS_LOCATIONS: 'OPERATIONS_LOCATIONS', // Локації
  OPERATIONS_LOCATIONS_LOCATION_LIST: 'OPERATIONS_LOCATIONS_LOCATION_LIST', // Список локацій
  OPERATIONS_LOCATIONS_CARS_BY_LOCATIONS: 'OPERATIONS_LOCATIONS_CARS_BY_LOCATIONS', // Авто по локаціям
  WAREHOUSE: 'WAREHOUSE', // СКЛАД
  WAREHOUSE_DOCUMENTS_LIST: 'WAREHOUSE_DOCUMENTS_LIST', // Список СД (замовлення, прихід, витрати)
  WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS: 'WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS', // Замовлення постачальнику
  WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS_API: 'WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS_API', // API
  WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS: 'WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS', // Приходи на склад
  WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS_API: 'WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS_API', // API
  WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS_PAYMENT: 'WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS_PAYMENT', // Оплата
  WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES: 'WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES', // Витрати зі складу
  WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES_API: 'WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES_API', // API
  WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES_PAYMENT: 'WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES_PAYMENT', // Оплата
  WAREHOUSE_DOCUMENTS_LIST_MOVEMENTS: 'WAREHOUSE_DOCUMENTS_LIST_MOVEMENTS', // Переміщення
  WAREHOUSE_DOCUMENT: 'WAREHOUSE_DOCUMENT', // Складський документ (СД)
  WAREHOUSE_DOCUMENT_HEADER: 'WAREHOUSE_DOCUMENT_HEADER', // Заголовок СД
  WAREHOUSE_DOCUMENT_HEADER_PAYMENT: 'WAREHOUSE_DOCUMENT_HEADER_PAYMENT', // Оплата
  WAREHOUSE_DOCUMENT_HEADER_DOCUMENT_PRINTING: 'WAREHOUSE_DOCUMENT_HEADER_DOCUMENT_PRINTING', // Друк документу
  WAREHOUSE_DOCUMENT_HEADER_DOCUMENT_UNPOSTING: 'WAREHOUSE_DOCUMENT_HEADER_DOCUMENT_UNPOSTING', // Розпроведення документа
  WAREHOUSE_DOCUMENT_HEADER_CLOSED_DOCUMENT_EDITING_DATE_COMMENT: 'WAREHOUSE_DOCUMENT_HEADER_CLOSED_DOCUMENT_EDITING_DATE_COMMENT', // Редагування закритого СД (дата, коментар)
  WAREHOUSE_DOCUMENT_LINES: 'WAREHOUSE_DOCUMENT_LINES', // Рядки СД
  WAREHOUSE_PRODUCTS_IN_STOCK: 'WAREHOUSE_PRODUCTS_IN_STOCK', // Товари на складі
  WAREHOUSE_GOODS_MOVEMENT: 'WAREHOUSE_GOODS_MOVEMENT', // Рух товарів
  WAREHOUSE_CELLS_WMS: 'WAREHOUSE_CELLS_WMS', // Комірки (WMS)
  WAREHOUSE_CELLS_WMS_WAREHOUSE_PLAN: 'WAREHOUSE_CELLS_WMS_WAREHOUSE_PLAN', // План складу
  WAREHOUSE_CELLS_WMS_CELLS_SETTINGS: 'WAREHOUSE_CELLS_WMS_CELLS_SETTINGS', // Налаштування комірок
  WAREHOUSE_CELLS_WMS_GENERATE_WMS: 'WAREHOUSE_CELLS_WMS_GENERATE_WMS', // Згенерувати WMS
  WAREHOUSE_CELLS_WMS_PRODUCTS_WITHOUT_CELLS: 'WAREHOUSE_CELLS_WMS_PRODUCTS_WITHOUT_CELLS', // Товари без клітинок
  WAREHOUSE_JOURNAL: 'WAREHOUSE_JOURNAL', // Журнал складу
  ACCOUNTING: 'ACCOUNTING', // БУХГАЛТЕРІЯ
  ACCOUNTING_CASH_REGISTERS: 'ACCOUNTING_CASH_REGISTERS', // Каси
  ACCOUNTING_CASH_REGISTERS_BALANCES_FOR_PERIOD: 'ACCOUNTING_CASH_REGISTERS_BALANCES_FOR_PERIOD', // Залишки за період
  ACCOUNTING_CASH_REGISTERS_MOVEMENT_FOR_PERIOD: 'ACCOUNTING_CASH_REGISTERS_MOVEMENT_FOR_PERIOD', // Рух за період
  ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER: 'ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER', // Рух грошей (Касовий ордер)
  ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER_ADD_ANALYTICS: 'ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER_ADD_ANALYTICS', // додавання аналітики
  ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER_PRINT: 'ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER_PRINT', // друк
  ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER_CASH_ORDER_UNPOSTING: 'ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER_CASH_ORDER_UNPOSTING', // розпроведення касового ордеру
  ACCOUNTING_OUTSTANDING_DEBTS: 'ACCOUNTING_OUTSTANDING_DEBTS', // Заборгованість
  ACCOUNTING_OUTSTANDING_DEBTS_RECEIVABLES: 'ACCOUNTING_OUTSTANDING_DEBTS_RECEIVABLES', // Дебіторка
  ACCOUNTING_OUTSTANDING_DEBTS_RECEIVABLES_PAYMENT: 'ACCOUNTING_OUTSTANDING_DEBTS_RECEIVABLES_PAYMENT', // Оплата
  ACCOUNTING_OUTSTANDING_DEBTS_PAYABLES: 'ACCOUNTING_OUTSTANDING_DEBTS_PAYABLES', // Кредиторка
  ACCOUNTING_OUTSTANDING_DEBTS_PAYABLES_PAYMENT: 'ACCOUNTING_OUTSTANDING_DEBTS_PAYABLES_PAYMENT', // Оплата
  ACCOUNTING_SALARIES: 'ACCOUNTING_SALARIES', // Зарплати
  ACCOUNTING_SALARIES_DOCUMENT_LIST: 'ACCOUNTING_SALARIES_DOCUMENT_LIST', // Список документів
  ACCOUNTING_SALARIES_DOCUMENT_LIST_SALARY_UNPOSTING: 'ACCOUNTING_SALARIES_DOCUMENT_LIST_SALARY_UNPOSTING', // Розпроведення SAL
  ACCOUNTING_SALARIES_DOCUMENT_LIST_PAYMENT: 'ACCOUNTING_SALARIES_DOCUMENT_LIST_PAYMENT', // Оплата
  ACCOUNTING_SALARIES_SALARY_HEADER: 'ACCOUNTING_SALARIES_SALARY_HEADER', // SAL - заголовок
  ACCOUNTING_SALARIES_SALARY_HEADER_SALARY_UNPOSTING: 'ACCOUNTING_SALARIES_SALARY_HEADER_SALARY_UNPOSTING', // розпроведення SAL
  ACCOUNTING_SALARIES_SALARY_HEADER_PAYMENT: 'ACCOUNTING_SALARIES_SALARY_HEADER_PAYMENT', // оплата
  ACCOUNTING_SALARIES_SALARY_HEADER_PRINT: 'ACCOUNTING_SALARIES_SALARY_HEADER_PRINT', // друк
  ACCOUNTING_SALARIES_SALARY_LINES: 'ACCOUNTING_SALARIES_SALARY_LINES', // SAL - рядки
  ACCOUNTING_SALARIES_SALARY_LINES_MANUAL_ADDITION_CORRECTION_LINE: 'ACCOUNTING_SALARIES_SALARY_LINES_MANUAL_ADDITION_CORRECTION_LINE', // ручне додавання / коригування рядка
  ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST: 'ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST', // Бухгалтерські документи - список
  ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST_ACCOUNTING_DOCUMENT: 'ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST_ACCOUNTING_DOCUMENT', // Бухгалтерський документ
  ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST_ACCOUNTING_DOCUMENT_ACC_UNPOSTING: 'ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST_ACCOUNTING_DOCUMENT_ACC_UNPOSTING', // розпроведення ACC
  ACCOUNTING_GENERAL_LEDGER_CHART_OF_ACCOUNTS: 'ACCOUNTING_GENERAL_LEDGER_CHART_OF_ACCOUNTS', // Головна книга (план рахунків)
  ACCOUNTING_JOURNAL_ENTRIES: 'ACCOUNTING_JOURNAL_ENTRIES', // Проводки
  ACCOUNTING_SETTLEMENT_JOURNAL: 'ACCOUNTING_SETTLEMENT_JOURNAL', // Журнал розрахунків
  ACCOUNTING_SETTLEMENT_JOURNAL_ADD_ROW: 'ACCOUNTING_SETTLEMENT_JOURNAL_ADD_ROW', // Додавання рядка
  ACCOUNTING_SETTLEMENT_JOURNAL_PAIRING_AND_UNPAIRING: 'ACCOUNTING_SETTLEMENT_JOURNAL_PAIRING_AND_UNPAIRING', // Парування і розпарування
  ACCOUNTING_VAT_JOURNAL: 'ACCOUNTING_VAT_JOURNAL', // Журнал ПДВ
  CRM: 'CRM', // CRM
  CRM_INTERACTIONS_LIST: 'CRM_INTERACTIONS_LIST', // Взаємодії - список
  CRM_INTERACTION_WINDOW: 'CRM_INTERACTION_WINDOW', // Взаємодія - вікно
  CRM_KANBAN: 'CRM_KANBAN', // Канбан
  CRM_CALL_LOG: 'CRM_CALL_LOG', // Журнал дзвінків
  CRM_CALL_LOG_LISTEN_TO_CALL: 'CRM_CALL_LOG_LISTEN_TO_CALL', // слухати дзвінок
  CRM_TASKS: 'CRM_TASKS', // Задачі
  CRM_REVIEWS: 'CRM_REVIEWS', // Відгуки
  CRM_MAILINGS: 'CRM_MAILINGS', // Розсилки
  CRM_CHATBOTS: 'CRM_CHATBOTS', // Чат-боти
  REPORTS: 'REPORTS', // ЗВІТИ
  REPORTS_GRAPHICAL_REPORTS: 'REPORTS_GRAPHICAL_REPORTS', // Графічні звіти
  REPORTS_GRAPHICAL_REPORTS_SALES: 'REPORTS_GRAPHICAL_REPORTS_SALES', // Продажі
  REPORTS_GRAPHICAL_REPORTS_SALES_ANALYTICS: 'REPORTS_GRAPHICAL_REPORTS_SALES_ANALYTICS', // Аналітика Продажів
  REPORTS_GRAPHICAL_REPORTS_FUNDS: 'REPORTS_GRAPHICAL_REPORTS_FUNDS', // Кошти
  REPORTS_GRAPHICAL_REPORTS_WAREHOUSE: 'REPORTS_GRAPHICAL_REPORTS_WAREHOUSE', // Склад
  REPORTS_GRAPHICAL_REPORTS_OUTSTANDING_DEBTS: 'REPORTS_GRAPHICAL_REPORTS_OUTSTANDING_DEBTS', // Заборгованість
  REPORTS_GRAPHICAL_REPORTS_APPEALS: 'REPORTS_GRAPHICAL_REPORTS_APPEALS', // Звернення*
  REPORTS_GRAPHICAL_REPORTS_NETWORK_REPORTS: 'REPORTS_GRAPHICAL_REPORTS_NETWORK_REPORTS', // Звіти по мережі
  REPORTS_GRAPHICAL_REPORTS_FILTERS: 'REPORTS_GRAPHICAL_REPORTS_FILTERS', // Фільтри
  REPORTS_TABULAR_REPORTS: 'REPORTS_TABULAR_REPORTS', // Табличні звіти*
  REPORTS_TABULAR_REPORTS_ORDERS: 'REPORTS_TABULAR_REPORTS_ORDERS', // Наряд-замовлення
  REPORTS_TABULAR_REPORTS_PRODUCTS: 'REPORTS_TABULAR_REPORTS_PRODUCTS', // Товари
  REPORTS_TABULAR_REPORTS_OUTSTANDING_DEBTS: 'REPORTS_TABULAR_REPORTS_OUTSTANDING_DEBTS', // Заборгованість
  REPORTS_XLSX_REPORTS: 'REPORTS_XLSX_REPORTS', // Звіти в xlsx
  REPORTS_XLSX_REPORTS_CRM: 'REPORTS_XLSX_REPORTS_CRM', // CRM
  REPORTS_XLSX_REPORTS_CRM_CALLS: 'REPORTS_XLSX_REPORTS_CRM_CALLS', // Дзвінки 
  REPORTS_XLSX_REPORTS_CRM_SMS: 'REPORTS_XLSX_REPORTS_CRM_SMS', // СМС
  REPORTS_XLSX_REPORTS_CRM_CONVERSIONS: 'REPORTS_XLSX_REPORTS_CRM_CONVERSIONS', // Конверсії
  REPORTS_XLSX_REPORTS_CRM_MAILINGS: 'REPORTS_XLSX_REPORTS_CRM_MAILINGS', // Розсилки
  REPORTS_XLSX_REPORTS_CRM_INVITATIONS: 'REPORTS_XLSX_REPORTS_CRM_INVITATIONS', // Запрошення
  REPORTS_XLSX_REPORTS_CRM_TASKS: 'REPORTS_XLSX_REPORTS_CRM_TASKS', // Задачі
  REPORTS_XLSX_REPORTS_CRM_REVIEWS: 'REPORTS_XLSX_REPORTS_CRM_REVIEWS', // Відгуки
  REPORTS_XLSX_REPORTS_PRODUCTS: 'REPORTS_XLSX_REPORTS_PRODUCTS', // Товари
  REPORTS_XLSX_REPORTS_PRODUCTS_UNIVERSAL: 'REPORTS_XLSX_REPORTS_PRODUCTS_UNIVERSAL', // Універсальний
  REPORTS_XLSX_REPORTS_PRODUCTS_STOCKS_BY_PRODUCTS: 'REPORTS_XLSX_REPORTS_PRODUCTS_STOCKS_BY_PRODUCTS', // Залишки по товарам
  REPORTS_XLSX_REPORTS_PRODUCTS_STOCKS_BY_WAREHOUSES: 'REPORTS_XLSX_REPORTS_PRODUCTS_STOCKS_BY_WAREHOUSES', // Залишки по складам
  REPORTS_XLSX_REPORTS_PRODUCTS_STOCKS_BY_CELLS: 'REPORTS_XLSX_REPORTS_PRODUCTS_STOCKS_BY_CELLS', // Залишки по коміркам
  REPORTS_XLSX_REPORTS_PRODUCTS_PRODUCT_MOVEMENT: 'REPORTS_XLSX_REPORTS_PRODUCTS_PRODUCT_MOVEMENT', // Рух товару
  REPORTS_XLSX_REPORTS_PRODUCTS_INVENTORY: 'REPORTS_XLSX_REPORTS_PRODUCTS_INVENTORY', // Інвентаризація
  REPORTS_XLSX_REPORTS_PRODUCTS_PURCHASES_BY_SUPPLIERS: 'REPORTS_XLSX_REPORTS_PRODUCTS_PURCHASES_BY_SUPPLIERS', // Закупівлі за постачальниками
  REPORTS_XLSX_REPORTS_PRODUCTS_WRITE_OFF: 'REPORTS_XLSX_REPORTS_PRODUCTS_WRITE_OFF', // Списання
  REPORTS_XLSX_REPORTS_ORDERS: 'REPORTS_XLSX_REPORTS_ORDERS', // Наряд-замовлення
  REPORTS_XLSX_REPORTS_ORDERS_UNIVERSAL_BY_ORDER: 'REPORTS_XLSX_REPORTS_ORDERS_UNIVERSAL_BY_ORDER', // Універсальний по н/з
  REPORTS_XLSX_REPORTS_ORDERS_UNIVERSAL_BY_WORKS: 'REPORTS_XLSX_REPORTS_ORDERS_UNIVERSAL_BY_WORKS', // Універсальний по роботах
  REPORTS_XLSX_REPORTS_ORDERS_UNIVERSAL_BY_PARTS: 'REPORTS_XLSX_REPORTS_ORDERS_UNIVERSAL_BY_PARTS', // Універсальний по з/ч
  REPORTS_XLSX_REPORTS_ORDERS_SUMMARY_BY_PARTS: 'REPORTS_XLSX_REPORTS_ORDERS_SUMMARY_BY_PARTS', // Зведений по з/ч
  REPORTS_XLSX_REPORTS_ORDERS_PROFIT_BY_STO: 'REPORTS_XLSX_REPORTS_ORDERS_PROFIT_BY_STO', // За прибутком по СТО
  REPORTS_XLSX_REPORTS_ORDERS_BY_DAYS: 'REPORTS_XLSX_REPORTS_ORDERS_BY_DAYS', // По днях
  REPORTS_XLSX_REPORTS_ORDERS_BY_EMPLOYEES: 'REPORTS_XLSX_REPORTS_ORDERS_BY_EMPLOYEES', // По працівниках
  REPORTS_XLSX_REPORTS_ORDERS_BY_STATUSES: 'REPORTS_XLSX_REPORTS_ORDERS_BY_STATUSES', // За статусами
  REPORTS_XLSX_REPORTS_ORDERS_BY_RECOMMENDATIONS: 'REPORTS_XLSX_REPORTS_ORDERS_BY_RECOMMENDATIONS', // За рекомендаціями
  REPORTS_XLSX_REPORTS_ORDERS_BY_CUSTOMERS: 'REPORTS_XLSX_REPORTS_ORDERS_BY_CUSTOMERS', // За клієнтами
  REPORTS_XLSX_REPORTS_ORDERS_BY_CARS: 'REPORTS_XLSX_REPORTS_ORDERS_BY_CARS', // За автомобілями
  REPORTS_XLSX_REPORTS_ORDERS_BY_POSTS: 'REPORTS_XLSX_REPORTS_ORDERS_BY_POSTS', // За постами
  REPORTS_XLSX_REPORTS_ORDERS_JOBS: 'REPORTS_XLSX_REPORTS_ORDERS_JOBS', // Роботи
  REPORTS_XLSX_REPORTS_ORDERS_PRODUCT_GROUPS: 'REPORTS_XLSX_REPORTS_ORDERS_PRODUCT_GROUPS', // Групи товарів
  REPORTS_XLSX_REPORTS_ORDERS_BRANDS: 'REPORTS_XLSX_REPORTS_ORDERS_BRANDS', // Бренди
  REPORTS_XLSX_REPORTS_ORDERS_PRODUCTS: 'REPORTS_XLSX_REPORTS_ORDERS_PRODUCTS', // Товари
  REPORTS_XLSX_REPORTS_ACCOUNTING: 'REPORTS_XLSX_REPORTS_ACCOUNTING', // Бухгалтерія
  REPORTS_XLSX_REPORTS_ACCOUNTING_UNIVERSAL_BY_PAYMENTS: 'REPORTS_XLSX_REPORTS_ACCOUNTING_UNIVERSAL_BY_PAYMENTS', // Універсальний по оплатах
  REPORTS_XLSX_REPORTS_ACCOUNTING_COUNTERPARTIES: 'REPORTS_XLSX_REPORTS_ACCOUNTING_COUNTERPARTIES', // Контрагенти
  REPORTS_XLSX_REPORTS_ACCOUNTING_RECEIVABLES: 'REPORTS_XLSX_REPORTS_ACCOUNTING_RECEIVABLES', // Дебіторка
  REPORTS_XLSX_REPORTS_ACCOUNTING_PAYABLES: 'REPORTS_XLSX_REPORTS_ACCOUNTING_PAYABLES', // Кредиторка
  REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_REGISTERS: 'REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_REGISTERS', // Каси
  REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_REGISTER_DEVICES: 'REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_REGISTER_DEVICES', // РРО
  REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_FLOW: 'REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_FLOW', // Грошовий потік
  REPORTS_XLSX_REPORTS_ACCOUNTING_SALARIES: 'REPORTS_XLSX_REPORTS_ACCOUNTING_SALARIES', // Зарплати
  ADMINISTRATION: 'ADMINISTRATION', // АДМІНІСТРУВАННЯ
  ADMINISTRATION_PRICELISTS: 'ADMINISTRATION_PRICELISTS', // Прайслисти
  ADMINISTRATION_BRANDS: 'ADMINISTRATION_BRANDS', // Бренди
  ADMINISTRATION_SYNCHRONIZATION_IMPORT: 'ADMINISTRATION_SYNCHRONIZATION_IMPORT', // Синхронізація / Імпорт
  ADMINISTRATION_SYNCHRONIZATION_BACKUP: 'ADMINISTRATION_SYNCHRONIZATION_BACKUP', // Синхронізація / Бекап
  ADMINISTRATION_CAR_MANAGEMENT: 'ADMINISTRATION_CAR_MANAGEMENT', // Управління автомобілями
  WORKPLACE: 'WORKPLACE', // АРМ
  WORKPLACE_QUICK_NAVIGATION: 'WORKPLACE_QUICK_NAVIGATION', // Швидка навігація
  WORKPLACE_BARCODES: 'WORKPLACE_BARCODES', // Штрих-коди
  WORKPLACE_JOURNALS: 'WORKPLACE_JOURNALS', // Журнали
  OTHER: 'OTHER', // ІНШЕ
  DIRECTORIES_USER_SETTINGS: 'DIRECTORIES_USER_SETTINGS', // Налаштування юзера
  DIRECTORIES_USER_SETTINGS_GENERAL: 'DIRECTORIES_USER_SETTINGS_GENERAL', // Загальні налаштування
  DIRECTORIES_USER_SETTINGS_ICONS: 'DIRECTORIES_USER_SETTINGS_ICONS', // Іконки
  DIRECTORIES_USER_SETTINGS_ORDER_TABS: 'DIRECTORIES_USER_SETTINGS_ORDER_TABS', // Таби н/з
  OPERATIONS_PLANNER_1D_AND_7D_PLANNER_BUSINESS_NETWORK: 'OPERATIONS_PLANNER_1D_AND_7D_PLANNER_BUSINESS_NETWORK', // Мережеві СТО
  OPERATIONS_PLANNER_MECHANIC_PLANNER_BUSINESS_NETWORK: 'OPERATIONS_PLANNER_MECHANIC_PLANNER_BUSINESS_NETWORK', // Мережеві СТО
  ADMINISTRATION_BUSINESS_PACKAGES: 'ADMINISTRATION_BUSINESS_PACKAGES', // Пакети по СТО
  DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS_CORRECTION: 'DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS_CORRECTION', // Коригування заборгованості
  DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS_CORRECTION: 'DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS_CORRECTION', // Коригування заборгованості
  DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS_CORRECTION: 'DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS_CORRECTION', // Коригування заборгованості
  ACCOUNTING_OUTSTANDING_DEBTS_RECEIVABLES_CORRECTION: 'ACCOUNTING_OUTSTANDING_DEBTS_RECEIVABLES_CORRECTION', // Коригування заборгованості
  ACCOUNTING_OUTSTANDING_DEBTS_PAYABLES_CORRECTION: 'ACCOUNTING_OUTSTANDING_DEBTS_PAYABLES_CORRECTION', // Коригування заборгованості
  ACCOUNTING_GENERAL_LEDGER_CHART_OF_ACCOUNTS_LEDGER: 'ACCOUNTING_GENERAL_LEDGER_CHART_OF_ACCOUNTS_LEDGER', // Головна книга
  ACCOUNTING_GENERAL_LEDGER_CHART_OF_ACCOUNTS_BALANCE: 'ACCOUNTING_GENERAL_LEDGER_CHART_OF_ACCOUNTS_BALANCE', // Баланс
  ACCOUNTING_GENERAL_LEDGER_CHART_OF_ACCOUNTS_INCOMES_AND_EXPENSES: 'ACCOUNTING_GENERAL_LEDGER_CHART_OF_ACCOUNTS_INCOMES_AND_EXPENSES', // Доходи і витрати
  WORKPLACE_QUICK_NAVIGATION_REPAIR: 'WORKPLACE_QUICK_NAVIGATION_REPAIR', // Ремонт
  WORKPLACE_QUICK_NAVIGATION_STOCK: 'WORKPLACE_QUICK_NAVIGATION_STOCK', // Склад
  WORKPLACE_QUICK_NAVIGATION_CASH_ORDER: 'WORKPLACE_QUICK_NAVIGATION_CASH_ORDER', // Касовий ордер
  WORKPLACE_QUICK_NAVIGATION_OTHER_ACCOUNTING: 'WORKPLACE_QUICK_NAVIGATION_OTHER_ACCOUNTING', // Інша бухгалтерія
  DIRECTORIES_PRODUCTS_LIST_RESERVED_REMOVE_ALL: 'DIRECTORIES_PRODUCTS_LIST_RESERVED_REMOVE_ALL', // Вдалити резерви по товару
  WAREHOUSE_PRODUCTS_IN_STOCK_REMOVE_ALL_RESERVED: 'WAREHOUSE_PRODUCTS_IN_STOCK_REMOVE_ALL_RESERVED', // Видалити всі резерви
  WAREHOUSE_PRODUCTS_IN_STOCK_RESERVE_ALL: 'WAREHOUSE_PRODUCTS_IN_STOCK_RESERVE_ALL', // Зарезервувати всі
  DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD_SERVICE_BOOK: 'DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD_SERVICE_BOOK', // Сервісна книжна
  DIRECTORIES_CUSTOMERS_LIST_CARS_SERVICE_BOOK: 'DIRECTORIES_CUSTOMERS_LIST_CARS_SERVICE_BOOK', // Сервісна книжка
  DIRECTORIES_USER_SETTINGS_GENERAL_BUSINESS_TYPE: 'DIRECTORIES_USER_SETTINGS_GENERAL_BUSINESS_TYPE', // Тип СТО
  DIRECTORIES_EMPLOYEE_CARD_POSITIONS_ROLE: 'DIRECTORIES_EMPLOYEE_CARD_POSITIONS_ROLE', // Права доступу
};

const grantToIndex = Object.freeze({
  'ADMIN': { treeId: 0, index: 0}, // Адмін права

  /*
    The entries below are AUTO-GENERATED using this script:
     select 
      ' ''' || spt_code || ''': { index: ' || spt_index || ', treeId: ' || spt_id_pk || ' }, // ' || spt_name
     from standard_packages_tree_tbl 
     order by spt_index;
  */

  'SETTINGS': { index: 1, treeId: 11000000 }, // НАЛАШТУВАННЯ
  'SETTINGS_API_PROVIDERS': { index: 2, treeId: 11010000 }, // API постачальників
  'SETTINGS_CRM': { index: 3, treeId: 11020000 }, // CRM
  'SETTINGS_MARKUP_GROUPS': { index: 4, treeId: 11030000 }, // Групи націнок
  'SETTINGS_PRODUCT_GROUPS': { index: 5, treeId: 11040000 }, // Групи товарів
  'SETTINGS_SOURCE': { index: 6, treeId: 11050000 }, // Джерело
  'SETTINGS_CASH_REGISTERS': { index: 7, treeId: 11060000 }, // Каси
  'SETTINGS_CELLS': { index: 8, treeId: 11070000 }, // Комірки
  'SETTINGS_BUSINESS_SETTINGS': { index: 9, treeId: 11080000 }, // Налаштування СТО
  'SETTINGS_BUSINESS_SETTINGS_GENERAL_INFORMATION': { index: 10, treeId: 11080100 }, // загальна інформація
  'SETTINGS_BUSINESS_SETTINGS_ADDRESS_AND_MAP': { index: 11, treeId: 11080200 }, // адреса і мапа
  'SETTINGS_BUSINESS_SETTINGS_WORKING_HOURS': { index: 12, treeId: 11080300 }, // час роботи
  'SETTINGS_BUSINESS_SETTINGS_POSTS': { index: 13, treeId: 11080400 }, // пости
  'SETTINGS_BUSINESS_SETTINGS_COUNTRY': { index: 14, treeId: 11080500 }, // країна
  'SETTINGS_BUSINESS_SETTINGS_PRICES': { index: 15, treeId: 11080600 }, // ціни
  'SETTINGS_BUSINESS_SETTINGS_PRINTED_FORMS': { index: 16, treeId: 11080700 }, // друковані форма
  'SETTINGS_BUSINESS_SETTINGS_ACCOUNTING': { index: 17, treeId: 11080800 }, // облік
  'SETTINGS_BUSINESS_SETTINGS_OTHER': { index: 18, treeId: 11080900 }, // інше
  'SETTINGS_BUSINESS_SETTINGS_OTHER_BUSINESS_NETWORK_ACCESS': { index: 19, treeId: 11080901 }, // доступ до мережі СТО
  'SETTINGS_THEME_SETTINGS': { index: 20, treeId: 11090000 }, // Налаштування теми
  'SETTINGS_NUMBERING': { index: 21, treeId: 11100000 }, // Нумерація
  'SETTINGS_UNITS_OF_MEASURE': { index: 22, treeId: 11110000 }, // Одиниці виміру
  'SETTINGS_PERMISSIONS_AND_ACCESS': { index: 23, treeId: 11120000 }, // Права і доступи
  'SETTINGS_PERMISSIONS_AND_ACCESS_STANDARD_PACKAGES': { index: 24, treeId: 11120100 }, // Стандартні пакети
  'SETTINGS_PERMISSIONS_AND_ACCESS_STANDARD_ROLES': { index: 25, treeId: 11120200 }, // Стандартні ролі
  'SETTINGS_PERMISSIONS_AND_ACCESS_BUSINESS_PACKAGE': { index: 26, treeId: 11120300 }, // Пакет СТО
  'SETTINGS_PERMISSIONS_AND_ACCESS_BUSINESS_ROLES': { index: 27, treeId: 11120400 }, // Ролі СТО
  'SETTINGS_SUPPLIER_PRICES': { index: 28, treeId: 11130000 }, // Прайси постачальників
  'SETTINGS_INVOICES_AND_ANALYTICS': { index: 29, treeId: 11140000 }, // Рахункі і аналітики
  'SETTINGS_INVOICES_AND_ANALYTICS_ANALYTICS': { index: 30, treeId: 11140100 }, // аналітики
  'SETTINGS_INVOICES_AND_ANALYTICS_INVOICES': { index: 31, treeId: 11140200 }, // рахунки
  'SETTINGS_INVOICES_AND_ANALYTICS_ACCOUNTING_OPERATIONS': { index: 32, treeId: 11140300 }, // бухгалтерські операції
  'SETTINGS_REQUISITES': { index: 33, treeId: 11150000 }, // Реквізити
  'SETTINGS_STORES': { index: 34, treeId: 11160000 }, // Склади
  'SETTINGS_SMS': { index: 35, treeId: 11170000 }, // СМС
  'SETTINGS_PART_STATUSES': { index: 36, treeId: 11180000 }, // Статуси запчастин
  'SETTINGS_ORDER_STATUSES': { index: 37, treeId: 11190000 }, // Статуси наряд-замовлень
  'SETTINGS_ORDER_STATUSES_ORDER_STATUSES': { index: 38, treeId: 11190100 }, // статуси н/з
  'SETTINGS_ORDER_STATUSES_STATUS_TRANSITIONS': { index: 39, treeId: 11190200 }, // переходи між статусами
  'SETTINGS_ORDER_STATUSES_ORDER_STATUS_CHECK': { index: 40, treeId: 11190300 }, // перевірка н/з
  'SETTINGS_WEB_SETTINGS': { index: 41, treeId: 11200000 }, // Веб налаштування
  'SETTINGS_LOCATIONS': { index: 42, treeId: 11210000 }, // Локації
  'SETTINGS_REGULATIONS': { index: 43, treeId: 11220000 }, // Регламенти
  'DIRECTORIES': { index: 44, treeId: 12000000 }, // ДОВІДНИКИ
  'DIRECTORIES_CARS_LIST': { index: 45, treeId: 12010000 }, // Автомобілі (список)
  'DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD': { index: 46, treeId: 12010100 }, // Загальна інформація (картка)
  'DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD_CAR_INFO': { index: 47, treeId: 12010101 }, // Інфо по а/м
  'DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD_CAR_OWNER_CHANGE': { index: 48, treeId: 12010102 }, // Зміна власника а/м
  'DIRECTORIES_CARS_LIST_INSPECTION': { index: 49, treeId: 12010200 }, // Огляд
  'DIRECTORIES_CARS_LIST_STANDARD_HOURS': { index: 50, treeId: 12010300 }, // Нормо-години
  'DIRECTORIES_CARS_LIST_VIN': { index: 51, treeId: 12010400 }, // ВІН
  'DIRECTORIES_CARS_LIST_REPAIRS': { index: 52, treeId: 12010500 }, // Ремонти
  'DIRECTORIES_CARS_LIST_WORKS': { index: 53, treeId: 12010600 }, // Роботи
  'DIRECTORIES_CARS_LIST_PARTS': { index: 54, treeId: 12010700 }, // Запчастини
  'DIRECTORIES_CARS_LIST_RECOMMENDATIONS': { index: 55, treeId: 12010800 }, // Рекомендації
  'DIRECTORIES_CARS_LIST_REGULATIONS': { index: 56, treeId: 12010900 }, // Регламенти
  'DIRECTORIES_CARS_LIST_MILEAGE': { index: 57, treeId: 12011000 }, // Пробіг
  'DIRECTORIES_AGGREGATES_LIST': { index: 58, treeId: 12020000 }, // Агрегати (список)
  'DIRECTORIES_AGGREGATES_LIST_GENERAL_INFORMATION_CARD': { index: 59, treeId: 12020100 }, // Загальна інформація (картка)
  'DIRECTORIES_AGGREGATES_LIST_GENERAL_INFORMATION_CARD_CAR_OWNER_CHANGE': { index: 60, treeId: 12020101 }, // Зміна власника а/м
  'DIRECTORIES_AGGREGATES_LIST_VIN': { index: 61, treeId: 12020200 }, // ВІН
  'DIRECTORIES_AGGREGATES_LIST_REPAIRS': { index: 62, treeId: 12020300 }, // Ремонти
  'DIRECTORIES_AGGREGATES_LIST_WORKS': { index: 63, treeId: 12020400 }, // Роботи
  'DIRECTORIES_AGGREGATES_LIST_PARTS': { index: 64, treeId: 12020500 }, // Запчастини
  'DIRECTORIES_AGGREGATES_LIST_RECOMMENDATIONS': { index: 65, treeId: 12020600 }, // Рекомендації
  'DIRECTORIES_CURRENCIES': { index: 66, treeId: 12030000 }, // Валюти
  'DIRECTORIES_DIAGNOSTICS': { index: 67, treeId: 12040000 }, // Діагностика
  'DIRECTORIES_CUSTOMERS_LIST': { index: 68, treeId: 12050000 }, // Клієнти (список)
  'DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD': { index: 69, treeId: 12050100 }, // Загальна інформація (картка)
  'DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD_CAR_OWNER_CHANGE': { index: 70, treeId: 12050101 }, // Зміна власника а/м
  'DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD_MARKUP_GROUP': { index: 71, treeId: 12050102 }, // Група націнки
  'DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD_ACCOUNTING_BY_ACCOUNT': { index: 72, treeId: 12050103 }, // Облік по бух.рахунку
  'DIRECTORIES_CUSTOMERS_LIST_CARS': { index: 73, treeId: 12050200 }, // Автомобілі
  'DIRECTORIES_CUSTOMERS_LIST_CARS_CAR_OWNER_CHANGE': { index: 74, treeId: 12050201 }, // Зміна власника а/м
  'DIRECTORIES_CUSTOMERS_LIST_AGGREGATES': { index: 75, treeId: 12050300 }, // Агрегати
  'DIRECTORIES_CUSTOMERS_LIST_AGGREGATES_CAR_OWNER_CHANGE': { index: 76, treeId: 12050301 }, // Зміна власника а/м
  'DIRECTORIES_CUSTOMERS_LIST_ORDERS': { index: 77, treeId: 12050400 }, // Замовлення
  'DIRECTORIES_CUSTOMERS_LIST_REVIEWS': { index: 78, treeId: 12050500 }, // Відгуки
  'DIRECTORIES_CUSTOMERS_LIST_REQUISITES': { index: 79, treeId: 12050600 }, // Реквізити
  'DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS': { index: 80, treeId: 12050700 }, // Заборгованість
  'DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS_PAIRING_AND_UNPAIRING': { index: 81, treeId: 12050701 }, // Парування і розпарування
  'DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS_PAYMENT': { index: 82, treeId: 12050702 }, // Оплата
  'DIRECTORIES_CUSTOMERS_LIST_CALLS': { index: 83, treeId: 12050800 }, // Дзвінки
  'DIRECTORIES_CUSTOMERS_LIST_CALLS_LISTENING': { index: 84, treeId: 12050801 }, // Прослуховування
  'DIRECTORIES_CUSTOMERS_LIST_DISCOUNTS': { index: 85, treeId: 12050900 }, // Знижки
  'DIRECTORIES_CUSTOMERS_LIST_INTERACTIONS': { index: 86, treeId: 12051000 }, // Взаємодії
  'DIRECTORIES_COMPLEXES': { index: 87, treeId: 12060000 }, // Комплекси
  'DIRECTORIES_CROSSES': { index: 88, treeId: 12070000 }, // Кроси
  'DIRECTORIES_STANDARDS': { index: 89, treeId: 12080000 }, // Нормативи
  'DIRECTORIES_SUPPLIERS_LIST': { index: 90, treeId: 12090000 }, // Постачальники (список)
  'DIRECTORIES_SUPPLIERS_LIST_GENERAL_INFORMATION_CARD': { index: 91, treeId: 12090100 }, // Загальна інформація (картка)
  'DIRECTORIES_SUPPLIERS_LIST_GENERAL_INFORMATION_CARD_ACCOUNTING_BY_ACCOUNT': { index: 92, treeId: 12090101 }, // Облік по бух.рахунку
  'DIRECTORIES_SUPPLIERS_LIST_REQUISITES': { index: 93, treeId: 12090200 }, // Реквізити
  'DIRECTORIES_SUPPLIERS_LIST_ORDERS': { index: 94, treeId: 12090300 }, // Замовлення
  'DIRECTORIES_SUPPLIERS_LIST_DELIVERIES': { index: 95, treeId: 12090400 }, // Поставки
  'DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS': { index: 96, treeId: 12090500 }, // Заборгованість
  'DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS_PAIRING_AND_UNPAIRING': { index: 97, treeId: 12090501 }, // Парування і розпарування
  'DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS_PAYMENT': { index: 98, treeId: 12090502 }, // Оплата
  'DIRECTORIES_SUPPLIERS_LIST_INTERACTIONS': { index: 99, treeId: 12090600 }, // Взаємодії
  'DIRECTORIES_EMPLOYEES_LIST': { index: 100, treeId: 12100000 }, // Працівники (список)
  'DIRECTORIES_EMPLOYEES_LIST_GENERAL_WORK_SCHEDULE': { index: 101, treeId: 12100100 }, // Загальний графік робіт
  'DIRECTORIES_EMPLOYEES_LIST_SALARIES': { index: 102, treeId: 12100200 }, // Зарплати
  'DIRECTORIES_EMPLOYEE_CARD': { index: 103, treeId: 12110000 }, // Працівник (картка)
  'DIRECTORIES_EMPLOYEE_CARD_GENERAL_DATA': { index: 104, treeId: 12110100 }, // Загальні дані
  'DIRECTORIES_EMPLOYEE_CARD_POSITIONS': { index: 105, treeId: 12110200 }, // Посади
  'DIRECTORIES_EMPLOYEE_CARD_WORK_SCHEDULE': { index: 106, treeId: 12110300 }, // Графік роботи
  'DIRECTORIES_EMPLOYEE_CARD_SALARY_SETTINGS': { index: 107, treeId: 12110400 }, // Налаштування з/п
  'DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS': { index: 108, treeId: 12110500 }, // Заборгованість
  'DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS_PAIRING_AND_UNPAIRING': { index: 109, treeId: 12110501 }, // Парування і розпарування
  'DIRECTORIES_EMPLOYEE_CARD_WINDOW_SETTINGS': { index: 110, treeId: 12110600 }, // Налаштування вікон
  'DIRECTORIES_EMPLOYEE_CARD_STATISTICS': { index: 111, treeId: 12110700 }, // Статистика
  'DIRECTORIES_EMPLOYEE_CARD_REVIEWS': { index: 112, treeId: 12110800 }, // Відгуки
  'DIRECTORIES_EMPLOYEE_CARD_USER_SETTINGS': { index: 113, treeId: 12110900 }, // Налаштування юзера
  'DIRECTORIES_JOBS': { index: 114, treeId: 12120000 }, // Роботи
  'DIRECTORIES_PRODUCTS_LIST': { index: 115, treeId: 12130000 }, // Товари (список)
  'DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD': { index: 116, treeId: 12130100 }, // Загальна інформація (картка)
  'DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD_SOURCE': { index: 117, treeId: 12130101 }, // Джерело
  'DIRECTORIES_PRODUCTS_LIST_PRICES': { index: 118, treeId: 12130200 }, // Ціни
  'DIRECTORIES_PRODUCTS_LIST_PRICES_CURRENCY_PRICES': { index: 119, treeId: 12130201 }, // Ціни у валюті
  'DIRECTORIES_PRODUCTS_LIST_SUPPLIERS': { index: 120, treeId: 12130300 }, // Постачальники
  'DIRECTORIES_PRODUCTS_LIST_PRODUCTION_DISASSEMBLY': { index: 121, treeId: 12130400 }, // Виробництво / Розборка
  'DIRECTORIES_PRODUCTS_LIST_AVAILABILITY': { index: 122, treeId: 12130500 }, // Наявність
  'DIRECTORIES_PRODUCTS_LIST_WAREHOUSE_MOVEMENT': { index: 123, treeId: 12130600 }, // Рух по складу
  'DIRECTORIES_PRODUCTS_LIST_RESERVED': { index: 124, treeId: 12130700 }, // Резерв
  'DIRECTORIES_PRODUCTS_LIST_ORDERS': { index: 125, treeId: 12130800 }, // Замовлення
  'DIRECTORIES_PRODUCTS_LIST_STORAGE': { index: 126, treeId: 12130900 }, // Зберігання
  'DIRECTORIES_PRODUCTS_LIST_TECHNICAL_INFORMATION': { index: 127, treeId: 12131000 }, // Тех.інфо
  'DIRECTORIES_PRODUCTS_LIST_TECHDOC_ANALOGS': { index: 128, treeId: 12131100 }, // Аналоги Текдок
  'DIRECTORIES_PRODUCTS_LIST_OWN_CROSSES': { index: 129, treeId: 12131200 }, // Власні кроси
  'OPERATIONS': { index: 130, treeId: 13000000 }, // ОПЕРАЦІЇ
  'OPERATIONS_PLANNER': { index: 131, treeId: 13010000 }, // Планувальник
  'OPERATIONS_PLANNER_1D_AND_7D_PLANNER': { index: 132, treeId: 13010100 }, // Планувальник 1D і 7D
  'OPERATIONS_PLANNER_1D_AND_7D_PLANNER_OVERDUE_REPAIRS_TRANSFER': { index: 133, treeId: 13010101 }, // Перенос просрочених ремонтів
  'OPERATIONS_PLANNER_MECHANIC_PLANNER': { index: 134, treeId: 13010200 }, // Планувальник механіків
  'OPERATIONS_PLANNER_MECHANIC_PLANNER_OVERDUE_REPAIRS_TRANSFER': { index: 135, treeId: 13010201 }, // Перенос просрочених ремонтів
  'OPERATIONS_ORDER_LIST': { index: 136, treeId: 13020000 }, // Список н/з
  'OPERATIONS_ORDER_DOCUMENT': { index: 137, treeId: 13030000 }, // Документ н/з
  'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER': { index: 138, treeId: 13030100 }, // Заголовок н/з
  'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_REPAIR_TYPE': { index: 139, treeId: 13030101 }, // Тип ремонту
  'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_MODAL_PLANNER': { index: 140, treeId: 13030102 }, // Модалка "Планувальник"
  'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_LOCATION': { index: 141, treeId: 13030103 }, // Локація
  'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_RESPONSIBLE': { index: 142, treeId: 13030104 }, // Відповідальний
  'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_CAR_INFO': { index: 143, treeId: 13030105 }, // Інфо по а/м
  'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_COORDINATION': { index: 144, treeId: 13030106 }, // Узгодження
  'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_PAYMENT': { index: 145, treeId: 13030107 }, // Оплата
  'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_DOCUMENT_PRINTING': { index: 146, treeId: 13030108 }, // Друк документів
  'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_DOCUMENT_UNPOSTING': { index: 147, treeId: 13030109 }, // Розпроведення документа
  'OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_CLOSED_ORDER_EDITING_MILEAGE': { index: 148, treeId: 13030110 }, // Редагування закритого н/з (пробіг)
  'OPERATIONS_ORDER_DOCUMENT_JOBS': { index: 149, treeId: 13030200 }, // Роботи
  'OPERATIONS_ORDER_DOCUMENT_JOBS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT': { index: 150, treeId: 13030201 }, // Зміна цін (закупочної і продажної) і знижки
  'OPERATIONS_ORDER_DOCUMENT_JOBS_COMPLEXES': { index: 151, treeId: 13030202 }, // Комплекси
  'OPERATIONS_ORDER_DOCUMENT_JOBS_STANDARD_HOURS': { index: 152, treeId: 13030203 }, // Нормо-години
  'OPERATIONS_ORDER_DOCUMENT_JOBS_REPAIR_ORDER_STATUS_CHANGE': { index: 153, treeId: 13030204 }, // Зміна статусу ПД
  'OPERATIONS_ORDER_DOCUMENT_PARTS': { index: 154, treeId: 13030300 }, // Запчастини
  'OPERATIONS_ORDER_DOCUMENT_PARTS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT': { index: 155, treeId: 13030301 }, // Зміна цін (закупочної і продажної) і знижки
  'OPERATIONS_ORDER_DOCUMENT_PARTS_CATALOG': { index: 156, treeId: 13030302 }, // Каталог
  'OPERATIONS_ORDER_DOCUMENT_PARTS_VIN': { index: 157, treeId: 13030303 }, // ВІН
  'OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS': { index: 158, treeId: 13030304 }, // Склади і резервування
  'OPERATIONS_ORDER_DOCUMENT_PARTS_REPAIR_ORDER_STATUS_CHANGE': { index: 159, treeId: 13030305 }, // Зміна статусу ПД
  'OPERATIONS_ORDER_DOCUMENT_PARTS_PART_STATUS_CHANGE': { index: 160, treeId: 13030306 }, // Зміна статусу з/ч
  'OPERATIONS_ORDER_DOCUMENT_PARTS_PRICE_UPDATE_BY_API': { index: 161, treeId: 13030307 }, // Оновлення ціни по API
  'OPERATIONS_ORDER_DOCUMENT_HISTORY': { index: 162, treeId: 13030400 }, // Історія
  'OPERATIONS_ORDER_DOCUMENT_TASKS': { index: 163, treeId: 13030500 }, // Задачі
  'OPERATIONS_ORDER_DOCUMENT_REQUEST': { index: 164, treeId: 13030600 }, // Заявка
  'OPERATIONS_ORDER_DOCUMENT_REGULATIONS': { index: 165, treeId: 13030700 }, // Регламент
  'OPERATIONS_ORDER_DOCUMENT_INSPECTION': { index: 166, treeId: 13030800 }, // Огляд
  'OPERATIONS_ORDER_DOCUMENT_POSTS': { index: 167, treeId: 13030900 }, // Пости
  'OPERATIONS_ORDER_DOCUMENT_DIAGNOSTICS': { index: 168, treeId: 13031000 }, // Діагностика
  'OPERATIONS_ORDER_DOCUMENT_DIAGNOSTICS_DIAGNOSTICS_CREATION': { index: 169, treeId: 13031001 }, // Створення діагностики
  'OPERATIONS_ORDER_DOCUMENT_DIAGNOSTICS_OWN_DIAGNOSTICS_SAVING': { index: 170, treeId: 13031002 }, // Збереження власної діагностики
  'OPERATIONS_ORDER_DOCUMENT_WORKSHOP': { index: 171, treeId: 13031100 }, // Цех
  'OPERATIONS_ORDER_DOCUMENT_RECOMMENDATIONS': { index: 172, treeId: 13031200 }, // Рекомендації
  'OPERATIONS_ORDER_DOCUMENT_REVIEWS': { index: 173, treeId: 13031300 }, // Відгук
  'OPERATIONS_ORDER_DOCUMENT_COMMENTS': { index: 174, treeId: 13031400 }, // Коментарі
  'OPERATIONS_ORDER_DOCUMENT_LOGS': { index: 175, treeId: 13031500 }, // Логи
  'OPERATIONS_ORDER_DOCUMENT_CALLS': { index: 176, treeId: 13031600 }, // Дзвінки
  'OPERATIONS_ORDER_DOCUMENT_CALLS_LISTENING': { index: 177, treeId: 13031601 }, // Прослуховування
  'OPERATIONS_ORDER_DOCUMENTS': { index: 178, treeId: 13031700 }, // Документи
  'OPERATIONS_ORDER_DOCUMENT_STATISTICS': { index: 179, treeId: 13031800 }, // Статистика
  'OPERATIONS_JOB_DETAILS': { index: 180, treeId: 13040000 }, // Деталі в роботі
  'OPERATIONS_JOB_DETAILS_ORDER_PARTS': { index: 181, treeId: 13040100 }, // Запчастини в н/з
  'OPERATIONS_JOB_DETAILS_WAREHOUSE_ORDER': { index: 182, treeId: 13040200 }, // Замовлення на склад
  'OPERATIONS_JOB_DETAILS_RETURN_OF_SURPLUS': { index: 183, treeId: 13040300 }, // Повернення надлишків
  'OPERATIONS_LOCATIONS': { index: 184, treeId: 13050000 }, // Локації
  'OPERATIONS_LOCATIONS_LOCATION_LIST': { index: 185, treeId: 13050100 }, // Список локацій
  'OPERATIONS_LOCATIONS_CARS_BY_LOCATIONS': { index: 186, treeId: 13050200 }, // Авто по локаціям
  'WAREHOUSE': { index: 187, treeId: 14000000 }, // СКЛАД
  'WAREHOUSE_DOCUMENTS_LIST': { index: 188, treeId: 14010000 }, // Список СД (замовлення, прихід, витрати)
  'WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS': { index: 189, treeId: 14010100 }, // Замовлення постачальнику
  'WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS_API': { index: 190, treeId: 14010101 }, // API
  'WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS': { index: 191, treeId: 14010200 }, // Приходи на склад
  'WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS_API': { index: 192, treeId: 14010201 }, // API
  'WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS_PAYMENT': { index: 193, treeId: 14010202 }, // Оплата
  'WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES': { index: 194, treeId: 14010300 }, // Витрати зі складу
  'WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES_API': { index: 195, treeId: 14010301 }, // API
  'WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES_PAYMENT': { index: 196, treeId: 14010302 }, // Оплата
  'WAREHOUSE_DOCUMENTS_LIST_MOVEMENTS': { index: 197, treeId: 14010400 }, // Переміщення
  'WAREHOUSE_DOCUMENT': { index: 198, treeId: 14020000 }, // Складський документ (СД)
  'WAREHOUSE_DOCUMENT_HEADER': { index: 199, treeId: 14020100 }, // Заголовок СД
  'WAREHOUSE_DOCUMENT_HEADER_PAYMENT': { index: 200, treeId: 14020101 }, // Оплата
  'WAREHOUSE_DOCUMENT_HEADER_DOCUMENT_PRINTING': { index: 201, treeId: 14020102 }, // Друк документу
  'WAREHOUSE_DOCUMENT_HEADER_DOCUMENT_UNPOSTING': { index: 202, treeId: 14020103 }, // Розпроведення документа
  'WAREHOUSE_DOCUMENT_HEADER_CLOSED_DOCUMENT_EDITING_DATE_COMMENT': { index: 203, treeId: 14020104 }, // Редагування закритого СД (дата, коментар)
  'WAREHOUSE_DOCUMENT_LINES': { index: 204, treeId: 14020200 }, // Рядки СД
  'WAREHOUSE_PRODUCTS_IN_STOCK': { index: 205, treeId: 14030000 }, // Товари на складі
  'WAREHOUSE_GOODS_MOVEMENT': { index: 206, treeId: 14040000 }, // Рух товарів
  'WAREHOUSE_CELLS_WMS': { index: 207, treeId: 14050000 }, // Комірки (WMS)
  'WAREHOUSE_CELLS_WMS_WAREHOUSE_PLAN': { index: 208, treeId: 14050100 }, // План складу
  'WAREHOUSE_CELLS_WMS_CELLS_SETTINGS': { index: 209, treeId: 14050200 }, // Налаштування комірок
  'WAREHOUSE_CELLS_WMS_GENERATE_WMS': { index: 210, treeId: 14050300 }, // Згенерувати WMS
  'WAREHOUSE_CELLS_WMS_PRODUCTS_WITHOUT_CELLS': { index: 211, treeId: 14050400 }, // Товари без клітинок
  'WAREHOUSE_JOURNAL': { index: 212, treeId: 14060000 }, // Журнал складу
  'ACCOUNTING': { index: 213, treeId: 15000000 }, // БУХГАЛТЕРІЯ
  'ACCOUNTING_CASH_REGISTERS': { index: 214, treeId: 15010000 }, // Каси
  'ACCOUNTING_CASH_REGISTERS_BALANCES_FOR_PERIOD': { index: 215, treeId: 15010100 }, // Залишки за період
  'ACCOUNTING_CASH_REGISTERS_MOVEMENT_FOR_PERIOD': { index: 216, treeId: 15010200 }, // Рух за період
  'ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER': { index: 217, treeId: 15020000 }, // Рух грошей (Касовий ордер)
  'ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER_ADD_ANALYTICS': { index: 218, treeId: 15020001 }, // додавання аналітики
  'ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER_PRINT': { index: 219, treeId: 15020002 }, // друк
  'ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER_CASH_ORDER_UNPOSTING': { index: 220, treeId: 15020003 }, // розпроведення касового ордеру
  'ACCOUNTING_OUTSTANDING_DEBTS': { index: 221, treeId: 15030000 }, // Заборгованість
  'ACCOUNTING_OUTSTANDING_DEBTS_RECEIVABLES': { index: 222, treeId: 15030100 }, // Дебіторка
  'ACCOUNTING_OUTSTANDING_DEBTS_RECEIVABLES_PAYMENT': { index: 223, treeId: 15030101 }, // Оплата
  'ACCOUNTING_OUTSTANDING_DEBTS_PAYABLES': { index: 224, treeId: 15030200 }, // Кредиторка
  'ACCOUNTING_OUTSTANDING_DEBTS_PAYABLES_PAYMENT': { index: 225, treeId: 15030201 }, // Оплата
  'ACCOUNTING_SALARIES': { index: 226, treeId: 15040000 }, // Зарплати
  'ACCOUNTING_SALARIES_DOCUMENT_LIST': { index: 227, treeId: 15040100 }, // Список документів
  'ACCOUNTING_SALARIES_DOCUMENT_LIST_SALARY_UNPOSTING': { index: 228, treeId: 15040101 }, // Розпроведення SAL
  'ACCOUNTING_SALARIES_DOCUMENT_LIST_PAYMENT': { index: 229, treeId: 15040102 }, // Оплата
  'ACCOUNTING_SALARIES_SALARY_HEADER': { index: 230, treeId: 15040200 }, // SAL - заголовок
  'ACCOUNTING_SALARIES_SALARY_HEADER_SALARY_UNPOSTING': { index: 231, treeId: 15040201 }, // розпроведення SAL
  'ACCOUNTING_SALARIES_SALARY_HEADER_PAYMENT': { index: 232, treeId: 15040202 }, // оплата
  'ACCOUNTING_SALARIES_SALARY_HEADER_PRINT': { index: 233, treeId: 15040203 }, // друк
  'ACCOUNTING_SALARIES_SALARY_LINES': { index: 234, treeId: 15040300 }, // SAL - рядки
  'ACCOUNTING_SALARIES_SALARY_LINES_MANUAL_ADDITION_CORRECTION_LINE': { index: 235, treeId: 15040301 }, // ручне додавання / коригування рядка
  'ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST': { index: 236, treeId: 15050000 }, // Бухгалтерські документи - список
  'ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST_ACCOUNTING_DOCUMENT': { index: 237, treeId: 15050100 }, // Бухгалтерський документ
  'ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST_ACCOUNTING_DOCUMENT_ACC_UNPOSTING': { index: 238, treeId: 15050101 }, // розпроведення ACC
  'ACCOUNTING_GENERAL_LEDGER_CHART_OF_ACCOUNTS': { index: 239, treeId: 15060000 }, // Головна книга (план рахунків)
  'ACCOUNTING_JOURNAL_ENTRIES': { index: 240, treeId: 15070000 }, // Проводки
  'ACCOUNTING_SETTLEMENT_JOURNAL': { index: 241, treeId: 15080000 }, // Журнал розрахунків
  'ACCOUNTING_SETTLEMENT_JOURNAL_ADD_ROW': { index: 242, treeId: 15080001 }, // Додавання рядка
  'ACCOUNTING_SETTLEMENT_JOURNAL_PAIRING_AND_UNPAIRING': { index: 243, treeId: 15080002 }, // Парування і розпарування
  'ACCOUNTING_VAT_JOURNAL': { index: 244, treeId: 15090000 }, // Журнал ПДВ
  'CRM': { index: 245, treeId: 16000000 }, // CRM
  'CRM_INTERACTIONS_LIST': { index: 246, treeId: 16010000 }, // Взаємодії - список
  'CRM_INTERACTION_WINDOW': { index: 247, treeId: 16020000 }, // Взаємодія - вікно
  'CRM_KANBAN': { index: 248, treeId: 16030000 }, // Канбан
  'CRM_CALL_LOG': { index: 249, treeId: 16040000 }, // Журнал дзвінків
  'CRM_CALL_LOG_LISTEN_TO_CALL': { index: 250, treeId: 16040001 }, // слухати дзвінок
  'CRM_TASKS': { index: 251, treeId: 16050000 }, // Задачі
  'CRM_REVIEWS': { index: 252, treeId: 16060000 }, // Відгуки
  'CRM_MAILINGS': { index: 253, treeId: 16070000 }, // Розсилки
  'CRM_CHATBOTS': { index: 254, treeId: 16080000 }, // Чат-боти
  'REPORTS': { index: 255, treeId: 17000000 }, // ЗВІТИ
  'REPORTS_GRAPHICAL_REPORTS': { index: 256, treeId: 17010000 }, // Графічні звіти
  'REPORTS_GRAPHICAL_REPORTS_SALES': { index: 257, treeId: 17010100 }, // Продажі
  'REPORTS_GRAPHICAL_REPORTS_SALES_ANALYTICS': { index: 258, treeId: 17010200 }, // Аналітика Продажів
  'REPORTS_GRAPHICAL_REPORTS_FUNDS': { index: 259, treeId: 17010300 }, // Кошти
  'REPORTS_GRAPHICAL_REPORTS_WAREHOUSE': { index: 260, treeId: 17010400 }, // Склад
  'REPORTS_GRAPHICAL_REPORTS_OUTSTANDING_DEBTS': { index: 261, treeId: 17010500 }, // Заборгованість
  'REPORTS_GRAPHICAL_REPORTS_APPEALS': { index: 262, treeId: 17010600 }, // Звернення*
  'REPORTS_GRAPHICAL_REPORTS_NETWORK_REPORTS': { index: 263, treeId: 17010700 }, // Звіти по мережі
  'REPORTS_GRAPHICAL_REPORTS_FILTERS': { index: 264, treeId: 17010800 }, // Фільтри
  'REPORTS_TABULAR_REPORTS': { index: 265, treeId: 17020000 }, // Табличні звіти*
  'REPORTS_TABULAR_REPORTS_ORDERS': { index: 266, treeId: 17020100 }, // Наряд-замовлення
  'REPORTS_TABULAR_REPORTS_PRODUCTS': { index: 267, treeId: 17020200 }, // Товари
  'REPORTS_TABULAR_REPORTS_OUTSTANDING_DEBTS': { index: 268, treeId: 17020300 }, // Заборгованість
  'REPORTS_XLSX_REPORTS': { index: 269, treeId: 17030000 }, // Звіти в xlsx
  'REPORTS_XLSX_REPORTS_CRM': { index: 270, treeId: 17030100 }, // CRM
  'REPORTS_XLSX_REPORTS_CRM_CALLS': { index: 271, treeId: 17030101 }, // Дзвінки 
  'REPORTS_XLSX_REPORTS_CRM_SMS': { index: 272, treeId: 17030102 }, // СМС
  'REPORTS_XLSX_REPORTS_CRM_CONVERSIONS': { index: 273, treeId: 17030103 }, // Конверсії
  'REPORTS_XLSX_REPORTS_CRM_MAILINGS': { index: 274, treeId: 17030104 }, // Розсилки
  'REPORTS_XLSX_REPORTS_CRM_INVITATIONS': { index: 275, treeId: 17030105 }, // Запрошення
  'REPORTS_XLSX_REPORTS_CRM_TASKS': { index: 276, treeId: 17030106 }, // Задачі
  'REPORTS_XLSX_REPORTS_CRM_REVIEWS': { index: 277, treeId: 17030107 }, // Відгуки
  'REPORTS_XLSX_REPORTS_PRODUCTS': { index: 278, treeId: 17030200 }, // Товари
  'REPORTS_XLSX_REPORTS_PRODUCTS_UNIVERSAL': { index: 279, treeId: 17030201 }, // Універсальний
  'REPORTS_XLSX_REPORTS_PRODUCTS_STOCKS_BY_PRODUCTS': { index: 280, treeId: 17030202 }, // Залишки по товарам
  'REPORTS_XLSX_REPORTS_PRODUCTS_STOCKS_BY_WAREHOUSES': { index: 281, treeId: 17030203 }, // Залишки по складам
  'REPORTS_XLSX_REPORTS_PRODUCTS_STOCKS_BY_CELLS': { index: 282, treeId: 17030204 }, // Залишки по коміркам
  'REPORTS_XLSX_REPORTS_PRODUCTS_PRODUCT_MOVEMENT': { index: 283, treeId: 17030205 }, // Рух товару
  'REPORTS_XLSX_REPORTS_PRODUCTS_INVENTORY': { index: 284, treeId: 17030206 }, // Інвентаризація
  'REPORTS_XLSX_REPORTS_PRODUCTS_PURCHASES_BY_SUPPLIERS': { index: 285, treeId: 17030207 }, // Закупівлі за постачальниками
  'REPORTS_XLSX_REPORTS_PRODUCTS_WRITE_OFF': { index: 286, treeId: 17030208 }, // Списання
  'REPORTS_XLSX_REPORTS_ORDERS': { index: 287, treeId: 17030300 }, // Наряд-замовлення
  'REPORTS_XLSX_REPORTS_ORDERS_UNIVERSAL_BY_ORDER': { index: 288, treeId: 17030301 }, // Універсальний по н/з
  'REPORTS_XLSX_REPORTS_ORDERS_UNIVERSAL_BY_WORKS': { index: 289, treeId: 17030302 }, // Універсальний по роботах
  'REPORTS_XLSX_REPORTS_ORDERS_UNIVERSAL_BY_PARTS': { index: 290, treeId: 17030303 }, // Універсальний по з/ч
  'REPORTS_XLSX_REPORTS_ORDERS_SUMMARY_BY_PARTS': { index: 291, treeId: 17030304 }, // Зведений по з/ч
  'REPORTS_XLSX_REPORTS_ORDERS_PROFIT_BY_STO': { index: 292, treeId: 17030305 }, // За прибутком по СТО
  'REPORTS_XLSX_REPORTS_ORDERS_BY_DAYS': { index: 293, treeId: 17030306 }, // По днях
  'REPORTS_XLSX_REPORTS_ORDERS_BY_EMPLOYEES': { index: 294, treeId: 17030307 }, // По працівниках
  'REPORTS_XLSX_REPORTS_ORDERS_BY_STATUSES': { index: 295, treeId: 17030308 }, // За статусами
  'REPORTS_XLSX_REPORTS_ORDERS_BY_RECOMMENDATIONS': { index: 296, treeId: 17030309 }, // За рекомендаціями
  'REPORTS_XLSX_REPORTS_ORDERS_BY_CUSTOMERS': { index: 297, treeId: 17030310 }, // За клієнтами
  'REPORTS_XLSX_REPORTS_ORDERS_BY_CARS': { index: 298, treeId: 17030311 }, // За автомобілями
  'REPORTS_XLSX_REPORTS_ORDERS_BY_POSTS': { index: 299, treeId: 17030312 }, // За постами
  'REPORTS_XLSX_REPORTS_ORDERS_JOBS': { index: 300, treeId: 17030313 }, // Роботи
  'REPORTS_XLSX_REPORTS_ORDERS_PRODUCT_GROUPS': { index: 301, treeId: 17030314 }, // Групи товарів
  'REPORTS_XLSX_REPORTS_ORDERS_BRANDS': { index: 302, treeId: 17030315 }, // Бренди
  'REPORTS_XLSX_REPORTS_ORDERS_PRODUCTS': { index: 303, treeId: 17030316 }, // Товари
  'REPORTS_XLSX_REPORTS_ACCOUNTING': { index: 304, treeId: 17030400 }, // Бухгалтерія
  'REPORTS_XLSX_REPORTS_ACCOUNTING_UNIVERSAL_BY_PAYMENTS': { index: 305, treeId: 17030401 }, // Універсальний по оплатах
  'REPORTS_XLSX_REPORTS_ACCOUNTING_COUNTERPARTIES': { index: 306, treeId: 17030402 }, // Контрагенти
  'REPORTS_XLSX_REPORTS_ACCOUNTING_RECEIVABLES': { index: 307, treeId: 17030403 }, // Дебіторка
  'REPORTS_XLSX_REPORTS_ACCOUNTING_PAYABLES': { index: 308, treeId: 17030404 }, // Кредиторка
  'REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_REGISTERS': { index: 309, treeId: 17030405 }, // Каси
  'REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_REGISTER_DEVICES': { index: 310, treeId: 17030406 }, // РРО
  'REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_FLOW': { index: 311, treeId: 17030407 }, // Грошовий потік
  'REPORTS_XLSX_REPORTS_ACCOUNTING_SALARIES': { index: 312, treeId: 17030408 }, // Зарплати
  'ADMINISTRATION': { index: 313, treeId: 18000000 }, // АДМІНІСТРУВАННЯ
  'ADMINISTRATION_PRICELISTS': { index: 314, treeId: 18010000 }, // Прайслисти
  'ADMINISTRATION_BRANDS': { index: 315, treeId: 18020000 }, // Бренди
  'ADMINISTRATION_SYNCHRONIZATION_IMPORT': { index: 316, treeId: 18030000 }, // Синхронізація / Імпорт
  'ADMINISTRATION_SYNCHRONIZATION_BACKUP': { index: 317, treeId: 18040000 }, // Синхронізація / Бекап
  'ADMINISTRATION_CAR_MANAGEMENT': { index: 318, treeId: 18050000 }, // Управління автомобілями
  'WORKPLACE': { index: 319, treeId: 19000000 }, // АРМ
  'WORKPLACE_QUICK_NAVIGATION': { index: 320, treeId: 19010000 }, // Швидка навігація
  'WORKPLACE_BARCODES': { index: 321, treeId: 19020000 }, // Штрих-коди
  'WORKPLACE_JOURNALS': { index: 322, treeId: 19030000 }, // Журнали
  'OTHER': { index: 323, treeId: 20000000 }, // ІНШЕ
  'DIRECTORIES_USER_SETTINGS': { index: 324, treeId: 12140000 }, // Налаштування юзера
  'DIRECTORIES_USER_SETTINGS_GENERAL': { index: 325, treeId: 12140100 }, // Загальні налаштування
  'DIRECTORIES_USER_SETTINGS_ICONS': { index: 326, treeId: 12140200 }, // Іконки
  'DIRECTORIES_USER_SETTINGS_ORDER_TABS': { index: 327, treeId: 12140300 }, // Таби н/з
  'OPERATIONS_PLANNER_1D_AND_7D_PLANNER_BUSINESS_NETWORK': { index: 328, treeId: 13010102 }, // Мережеві СТО
  'OPERATIONS_PLANNER_MECHANIC_PLANNER_BUSINESS_NETWORK': { index: 329, treeId: 13010202 }, // Мережеві СТО
  'ADMINISTRATION_BUSINESS_PACKAGES': { index: 330, treeId: 18060000 }, // Пакети по СТО
  'DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS_CORRECTION': { index: 331, treeId: 12050703 }, // Коригування заборгованості
  'DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS_CORRECTION': { index: 332, treeId: 12090503 }, // Коригування заборгованості
  'DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS_CORRECTION': { index: 333, treeId: 12110502 }, // Коригування заборгованості
  'ACCOUNTING_OUTSTANDING_DEBTS_RECEIVABLES_CORRECTION': { index: 334, treeId: 15030102 }, // Коригування заборгованості
  'ACCOUNTING_OUTSTANDING_DEBTS_PAYABLES_CORRECTION': { index: 335, treeId: 15030202 }, // Коригування заборгованості
  'ACCOUNTING_GENERAL_LEDGER_CHART_OF_ACCOUNTS_LEDGER': { index: 336, treeId: 15060100 }, // Головна книга
  'ACCOUNTING_GENERAL_LEDGER_CHART_OF_ACCOUNTS_BALANCE': { index: 337, treeId: 15060200 }, // Баланс
  'ACCOUNTING_GENERAL_LEDGER_CHART_OF_ACCOUNTS_INCOMES_AND_EXPENSES': { index: 338, treeId: 15060300 }, // Доходи і витрати
  'WORKPLACE_QUICK_NAVIGATION_REPAIR': { index: 339, treeId: 19010100 }, // Ремонт
  'WORKPLACE_QUICK_NAVIGATION_STOCK': { index: 340, treeId: 19010200 }, // Склад
  'WORKPLACE_QUICK_NAVIGATION_CASH_ORDER': { index: 341, treeId: 19010300 }, // Касовий ордер
  'WORKPLACE_QUICK_NAVIGATION_OTHER_ACCOUNTING': { index: 342, treeId: 19010400 }, // Інша бухгалтерія
  'DIRECTORIES_PRODUCTS_LIST_RESERVED_REMOVE_ALL': { index: 343, treeId: 12130701 }, // Вдалити резерви по товару
  'WAREHOUSE_PRODUCTS_IN_STOCK_REMOVE_ALL_RESERVED': { index: 344, treeId: 14030001 }, // Видалити всі резерви
  'WAREHOUSE_PRODUCTS_IN_STOCK_RESERVE_ALL': { index: 345, treeId: 14030002 }, // Зарезервувати всі
  'DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD_SERVICE_BOOK': { index: 346, treeId: 12010103 }, // Сервісна книжна
  'DIRECTORIES_CUSTOMERS_LIST_CARS_SERVICE_BOOK': { index: 347, treeId: 12050202 }, // Сервісна книжка
  'DIRECTORIES_USER_SETTINGS_GENERAL_BUSINESS_TYPE': { index: 348, treeId: 12140101 }, // Тип СТО
  'DIRECTORIES_EMPLOYEE_CARD_POSITIONS_ROLE': { index: 349, treeId:  12110201 }, //  Права доступу
});

module.exports = {
  accesses,
  grants,
  grantToIndex,
  accessMapping,
};