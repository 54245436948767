/** Constants * */

export const moduleName = 'taskPage';
const prefix = `cpb/${moduleName}`;

export const FETCH_TASK = `${prefix}/FETCH_TASKS`;
export const FETCH_TASK_SUCCESS = `${prefix}/FETCH_TASKS_SUCCESS`;

export const FETCH_MANAGERS = `${prefix}/FETCH_MANAGERS`;
export const FETCH_MANAGERS_SUCCESS = `${prefix}/FETCH_MANAGERS_SUCCESS`;

export const DELEGATE_TASK = `${prefix}/DELEGATE_TASK`;
export const POSTPONED_TASK = `${prefix}/POSTPONED_TASK`;
export const CLOSE_TASK = `${prefix}/CLOSE_TASK`;

export const SET_FETCHING_TASK = `${prefix}/SET_FETCHING_TASK`;
export const SET_MANAGER_MODAL_VISIBILITY = `${prefix}/SET_MANAGER_MODAL_VISIBILITY`;
export const SET_POSTPONED_TASK_MODAL_VISIBILITY = `${prefix}/SET_POSTPONED_TASK_MODAL_VISIBILITY`;
export const SET_PERFORMER = `${prefix}/SET_PERFORMER`;

export const SET_POSTPONED_TASK_FIELDS = `${prefix}/SET_POSTPONED_TASK_FIELDS`;
export const UPDATE_TASK = `${prefix}/UPDATE_TASK`;

/** Reducer * */
const ReducerState = {
    task: {},
    fetchingTask: false,
    managers: [],

    setManagerModalVisibility: false,
    performerId: undefined, // in setManagerModal,

    postponedTaskModalVisibility: false,
    postponedTask: {
        fields: {
            scheduledDate: undefined,
            scheduledTime: undefined
        }
    }
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_TASK_SUCCESS:
            return {
                ...state,
                task: payload
            };

        case FETCH_MANAGERS_SUCCESS:
            return {
                ...state,
                managers: payload
            };

        case SET_FETCHING_TASK:
            return {
                ...state,
                fetchingTask: payload
            };

        case SET_POSTPONED_TASK_FIELDS:
            return {
                ...state,
                postponedTask: {
                    ...state.postponedTask,
                    fields: {
                        ...state.postponedTask.fields,
                        ...payload
                    }
                }
            };

        case SET_MANAGER_MODAL_VISIBILITY:
            return {
                ...state,
                setManagerModalVisibility: payload
            };

        case SET_POSTPONED_TASK_MODAL_VISIBILITY:
            return {
                ...state,
                postponedTaskModalVisibility: payload
            };

        case SET_PERFORMER:
            return {
                ...state,
                performerId: payload
            };

        default:
            return state;
    }
}

/** Selectors * */

export const stateSelector = state => state[moduleName];

export const selectTask = state => state[moduleName].task;
export const selectManagers = state => state[moduleName].managers;
export const selectFetchingTask = state => state[moduleName].fetchingTask;
export const selectSetManagerModalVisibility = state => state[moduleName].setManagerModalVisibility;
export const selectPostponedTaskModalVisibility = state =>
    state[moduleName].postponedTaskModalVisibility;
export const selectPerformer = state => state[moduleName].performerId;
export const selectPostponedTaskFields = state => state[moduleName].postponedTask.fields;

/** Action Creators * */

/** Fetch all vehicles */
export const fetchTask = id => ({
    type: FETCH_TASK,
    payload: id
});

export const fetchTaskSuccess = data => ({
    type: FETCH_TASK_SUCCESS,
    payload: data
});

export const fetchManagers = () => ({
    type: FETCH_MANAGERS
});

export const fetchManagersSuccess = data => ({
    type: FETCH_MANAGERS_SUCCESS,
    payload: data
});

export const setFetchingTask = value => ({
    type: SET_FETCHING_TASK,
    payload: value
});

export const setManagerModalVisibility = value => ({
    type: SET_MANAGER_MODAL_VISIBILITY,
    payload: value
});

export const setPostponedTaskModalVisibility = value => ({
    type: SET_POSTPONED_TASK_MODAL_VISIBILITY,
    payload: value
});

export const setPerformer = value => ({
    type: SET_PERFORMER,
    payload: value
});

export const delegateTask = () => ({
    type: DELEGATE_TASK
});

export const postponedTask = () => ({
    type: POSTPONED_TASK
});

export const closeTask = () => ({
    type: CLOSE_TASK
});

export const setPostponedTaskFields = fields => ({
    type: SET_POSTPONED_TASK_FIELDS,
    payload: fields
});

export const updateTask = (id, data) => ({
    type: UPDATE_TASK,
    payload: { id, data }
});
