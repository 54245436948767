exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".ant-table-row-cell-last,.ant-table-selection-column{vertical-align:bottom}.styles-m__formItem---_ZSb6{min-width:480px;display:flex;justify-content:space-between;align-items:center;margin-bottom:8px}.styles-m__rowStyles---WcYL4{margin-bottom:8px}.styles-m__blockStyle---1JMpa{display:flex;justify-content:space-between}.styles-m__textStyle---33mqm{margin-bottom:8px}.styles-m__ogTitle---1yYBc{font-size:16px;font-weight:700}.styles-m__ogDesc---onOFy{margin-bottom:8px;margin-top:8px;opacity:.7}.styles-m__ogImg---11WSP{margin-bottom:4px;margin-top:4px}.styles-m__linkBlock---3cgaD{margin-bottom:12px;background-color:#f5f5f5;border:1px solid #8a8d8a;padding:4px;border-radius:8px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,qDAEE,qBACF,CAEA,4BACE,eAAgB,CAChB,YAAa,CACb,6BAA8B,CAC9B,kBAAmB,CACnB,iBACF,CAEA,6BACC,iBACD,CAEA,8BACC,YAAa,CACb,6BACD,CAEA,6BACC,iBACD,CAEA,2BACE,cAAe,CACf,eACF,CAEA,0BACE,iBAAkB,CAClB,cAAe,CACf,UACF,CAEA,yBACE,iBAAkB,CAClB,cACF,CAEA,6BACE,kBAAmB,CACnB,wBAA4B,CAC5B,wBAAyB,CACzB,WAAY,CACZ,iBACF","file":"styles.m.css","sourcesContent":[":global(.ant-table-row-cell-last),\n:global(.ant-table-selection-column) {\n  vertical-align: bottom;\n}\n\n.formItem {\n  min-width: 480px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n}\n\n.rowStyles {\n\tmargin-bottom: 8px;\n}\n\n.blockStyle {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n\n.textStyle {\n\tmargin-bottom: 8px;\n}\n\n.ogTitle {\n  font-size: 16px;\n  font-weight: 700;\n}\n\n.ogDesc {\n  margin-bottom: 8px;\n  margin-top: 8px;\n  opacity: 0.7;\n}\n\n.ogImg {\n  margin-bottom: 4px;\n  margin-top: 4px;\n}\n\n.linkBlock {\n  margin-bottom: 12px;\n  background-color: whitesmoke;\n  border: 1px solid #8a8d8a;\n  padding: 4px;\n  border-radius: 8px; \n}\n"]}]);

// Exports
exports.locals = {
	"formItem": "styles-m__formItem---_ZSb6",
	"rowStyles": "styles-m__rowStyles---WcYL4",
	"blockStyle": "styles-m__blockStyle---1JMpa",
	"textStyle": "styles-m__textStyle---33mqm",
	"ogTitle": "styles-m__ogTitle---1yYBc",
	"ogDesc": "styles-m__ogDesc---onOFy",
	"ogImg": "styles-m__ogImg---11WSP",
	"linkBlock": "styles-m__linkBlock---3cgaD"
};