import { DeleteOutlined, ProfileOutlined, UndoOutlined } from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Input,
    InputNumber,
    Popconfirm,
    Select,
    Switch,
    Table,
    TreeSelect,
    message
} from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { LaborPriceGroupsModal, Layout } from 'tireFitting';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';

const { Option } = Select;

const mapStateToProps = state => {
    return {
        user: state.auth,
        isMobile: state.ui.views.isMobile
    };
};

@injectIntl
@connect(mapStateToProps, void 0)
export default class LaborsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            labors: [],
            masterLabors: [],
            storeGroups: [],
            filterCode: null,
            filterCrossId: null,
            filterId: null,
            filterDetail: null,
            filterDefaultName: null,
            filterName: null,
            currentPage: 1,
            selectedRows: []
        };
        this.treeData = [];
        const disabled = !isGrantAccessed(this.props.user, grants.DIRECTORIES_JOBS, accesses.ROWO)
        this.columns = () => [
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='order_form_table.labors_code' />
                            <Input
                                allowClear
                                onChange={event => {
                                    this.setState({
                                        filterCode: event.target.value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.labors_code'
                                })}
                                value={this.state.filterCode}
                            />
                        </div>
                    );
                },
                key: 'laborCode',
                dataIndex: 'laborCode'
            },
            {
                title: () => {
                    return (
                        <div>
                            <p>ID</p>
                            <Input
                                allowClear
                                onChange={event => {
                                    this.setState({
                                        filterId: event.target.value
                                    });
                                }}
                                placeholder='ID'
                                value={this.state.filterId}
                            />
                        </div>
                    );
                },
                dataIndex: 'masterLaborId',
                key: 'masterLaborId',
                render: (data, elem) => {
                    const { key } = elem;

                    return !elem.new ? (
                        <p>{data}</p>
                    ) : (
                        <Select
                            disabled={disabled}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0 ||
                                String(option.props.value).indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value, option) => {
                                this.state.labors[key].masterLaborId = value;
                                this.state.labors[key].name = option.props.children;
                                this.state.labors[key].laborCode = `${value}-${
                                    elem.storeGroupId ? elem.storeGroupId : '0000000'
                                }`;
                                this.state.labors[
                                    key
                                ].customName = `${option.props.children} ${this.state.labors[key].detailTitle}`;
                                this.setState({
                                    update: true
                                });
                            }}
                            placeholder='ID'
                            showSearch
                            style={{ minWidth: '100px' }}
                            value={
                                this.state.labors[key].masterLaborId
                                    ? this.state.labors[key].masterLaborId
                                    : undefined
                            }
                        >
                            {this.state.masterLabors.map((elem, index) => (
                                <Option key={index} value={elem.masterLaborId}>
                                    {elem.defaultMasterLaborName}
                                </Option>
                            ))}
                        </Select>
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='order_form_table.detail_code' />
                            <Input
                                allowClear
                                onChange={event => {
                                    this.setState({
                                        filterDetail: event.target.value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.detail_code'
                                })}
                                value={this.state.filterDetail}
                            />
                        </div>
                    );
                },
                dataIndex: 'storeGroupId',
                key: 'storeGroupId',
                render: (data, elem) => {
                    const { key } = elem;

                    return !elem.new ? (
                        <p>{data}</p>
                    ) : (
                        <TreeSelect
                            
                        disabled={disabled}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            filterTreeNode={(input, node) =>
                                node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                String(node.props.value).indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={(value, option) => {
                                this.state.labors[key].storeGroupId = value;
                                this.state.labors[key].laborCode = `${
                                    elem.masterLaborId ? elem.masterLaborId : '0000'
                                }-${value}`;
                                this.state.labors[key].detailTitle = option.props.title;
                                this.state.labors[
                                    key
                                ].customName = `${this.state.labors[key].name} ${option.props.title}`;
                                this.setState({
                                    update: true
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.detail_code'
                            })}
                            showSearch
                            style={{ minWidth: '130px', maxWidth: '10%' }}
                            treeData={this.treeData}
                            value={
                                this.state.labors[key].storeGroupId
                                    ? this.state.labors[key].storeGroupId
                                    : undefined
                            }
                        />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='order_form_table.external_id' />
                            <Input
                                allowClear
                                onChange={event => {
                                    this.setState({
                                        filterCrossId: event.target.value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.external_id'
                                })}
                                value={this.state.filterCrossId}
                            />
                        </div>
                    );
                },
                key: 'crossId',
                dataIndex: 'crossId',
                render: (data, elem) => {
                    const { key } = elem;

                    return (
                        <Input
                            onChange={event => {
                                elem.changed = true;
                                elem.crossId = event.target.value;
                                this.setState({
                                    update: true
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.external_id'
                            })}
                            style={{ color: 'var(--text)' }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='order_form_table.service_type' />
                            <Input
                                allowClear
                                onChange={event => {
                                    this.setState({
                                        filterDefaultName: event.target.value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.service_type'
                                })}
                                value={this.state.filterDefaultName}
                            />
                        </div>
                    );
                },
                dataIndex: 'masterLaborName',
                key: 'name'
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='order_form_table.detail_name' />
                            <Input
                                allowClear
                                onChange={event => {
                                    this.setState({
                                        filterName: event.target.value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.detail_name'
                                })}
                                value={this.state.filterName}
                            />
                        </div>
                    );
                },
                key: 'customName',
                render: elem => {
                    const { key } = elem;

                    return (
                        <Input
                            ref={input => {
                                this.nameInput = input;
                            }}
                            disabled={disabled}
                            onChange={event => {
                                elem.changed = true;
                                elem.customName = event.target.value;
                                this.setState({
                                    update: true
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.detail_name'
                            })}
                            style={{ color: 'var(--text)' }}
                            value={elem.customName || elem.name}
                        />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <p>
                                <FormattedMessage id='supplier.show' />
                            </p>
                            <p>
                                <Checkbox
                                    onChange={event => {
                                        this.state.selectedRows.map(elem => {
                                            elem.changed = true;
                                            elem.disable = !event.target.checked;
                                        });
                                        this.setState({});
                                    }}
                                />
                            </p>
                        </div>
                    );
                },
                key: 'disable',
                dataIndex: 'disable',
                render: (data, elem) => {
                    return (
                        <Checkbox
                            checked={!data}
                            onChange={event => {
                                elem.disable = !event.target.checked;
                                elem.changed = true;
                                this.setState({});
                            }}
                        />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <p>
                                <FormattedMessage id='order_form_table.fixed' />
                            </p>
                            <p>
                                <Switch
                                    onClick={value => {
                                        this.state.selectedRows.map(elem => {
                                            elem.changed = true;
                                            elem.fixed = value;
                                        });
                                        this.setState({});
                                    }}
                                />
                            </p>
                        </div>
                    );
                },
                dataIndex: 'fixed',
                key: 'fixed',
                render: (fixed, elem) => {
                    const { key } = elem;

                    return (
                        <Switch
                            checked={fixed}
                            disabled={disabled}
                            onClick={value => {
                                elem.changed = true;
                                elem.fixed = value;
                                this.setState({});
                            }}
                        />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <p>
                                <FormattedMessage id='hours' />
                            </p>
                            <p>
                                <InputNumber
                                    decimalSeparator=','
                                    min={0.1}
                                    onChange={value => {
                                        this.state.selectedRows.map(elem => {
                                            elem.changed = true;
                                            elem.normHours = value;
                                        });
                                        this.setState({});
                                    }}
                                    step={0.2}
                                    style={{ color: 'var(--text)' }}
                                />
                            </p>
                        </div>
                    );
                },
                dataIndex: 'normHours',
                key: 'normHours',
                render: (data, elem) => {
                    const { key } = elem;

                    return (
                        <InputNumber
                            decimalSeparator=','
                            disabled={
                                elem.fixed ||
                                disabled
                            }
                            min={0.1}
                            onChange={value => {
                                elem.changed = true;
                                elem.normHours = value;
                                this.setState({});
                            }}
                            step={0.2}
                            style={{ color: 'var(--text)' }}
                            value={data || 1}
                        />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <p>
                                <FormattedMessage id='order_form_table.price' />
                            </p>
                            <p>
                                <InputNumber
                                    decimalSeparator=','
                                    min={1}
                                    onChange={value => {
                                        this.state.selectedRows.map(elem => {
                                            elem.changed = true;
                                            elem.price = value;
                                        });
                                        this.setState({});
                                    }}
                                    style={{ color: 'var(--text)' }}
                                />
                            </p>
                        </div>
                    );
                },
                dataIndex: 'price',
                key: 'price',
                render: (data, elem) => {
                    const { key } = elem;

                    return (
                        <InputNumber
                            decimalSeparator=','
                            disabled={
                                !elem.fixed ||
                                disabled
                            }
                            min={1}
                            onChange={value => {
                                elem.changed = true;
                                elem.price = value;
                                this.setState({});
                            }}
                            style={{ color: 'var(--text)' }}
                            value={data || 1}
                        />
                    );
                }
            },
            {
                key: 'priceGroups',
                render: (data, { id }) => {
                    return (
                        <Button
                            onClick={() => {
                                this.setState({ selectedLaborId: id });
                            }}
                            type='primary'
                        >
                            <ProfileOutlined />
                        </Button>
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <Popconfirm
                                onConfirm={() => {
                                    this.state.selectedRows.map(elem => {
                                        if (elem.laborBusinessId) elem.deleted = true;
                                    });
                                    this.setState({});
                                }}
                                title={<FormattedMessage id='add_order_form.delete_confirm' />}
                            >
                                <Button type='danger'>
                                    <DeleteOutlined />
                                </Button>
                            </Popconfirm>
                        </div>
                    );
                },
                key: 'delete',
                render: row => {
                    const buttonType = row.masterLaborId >= 9000 ? 'danger' : 'primary';
                    const icon = row.masterLaborId >= 9000 ? <DeleteOutlined /> : <UndoOutlined />;

                    return (
                        <Popconfirm
                            onConfirm={async () => {
                                await fetchAPI('PUT', `labors/reset?laborIds=[${row.id}]`);
                                this.fetchLabors();
                            }}
                            title={<FormattedMessage id='add_order_form.delete_confirm' />}
                        >
                            <Button
                                icon = {icon}
                                style={{
                                    width: '100%'
                                }}
                                title={
                                    row.masterLaborId >= 9000
                                        ? this.props.intl.formatMessage({ id: 'delete' })
                                        : this.props.intl.formatMessage({ id: 'update' })
                                }
                                type={buttonType}
                            />
                        </Popconfirm>
                    );
                }
            }
        ];

        this.mobileColumns = () => [
            {
                title: <FormattedMessage id='order_form_table.labors_code' />,
                key: 'laborCode',
                dataIndex: 'laborCode',
                render: (data, row) => {
                    return <div style={{ display: 'flex' }}>{data}</div>;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'name',
                render: elem => {
                    return elem.name || elem.customName;
                }
            },
            {
                title: <FormattedMessage id='hours' />,
                dataIndex: 'normHours',
                key: 'normHours',
                render: data => {
                    return Math.round(data * 100) / 100;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.price' />,
                dataIndex: 'price',
                key: 'price',
                render: data => {
                    return Math.round(data * 100) / 100;
                }
            },
            {
                key: 'disable',
                dataIndex: 'disable',
                render: (data, elem) => {
                    return (
                        <Checkbox
                            checked={!data}
                            onChange={event => {
                                elem.disable = !event.target.checked;
                                elem.changed = true;
                                this.setState({});
                            }}
                        />
                    );
                }
            }
        ];
    }

    async updatePrice() {
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = '/labors/recalc_prices';
        url += params;

        await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                message.success('Цена обновлена');
            })
            .catch(function (error) {
                message.error('Цена не задана!');
            });

        await this.fetchLabors();
    }

    async saveLabors() {
        const labors = [];
        const newLabors = [];
        this.state.labors.map(elem => {
            if (elem.changed && !elem.new) {
                labors.push({
                    id: elem.id,
                    masterLaborId: elem.masterLaborId,
                    storeGroupId: elem.storeGroupId,
                    disabled: Boolean(elem.disabled),
                    crossId: elem.crossId || null,
                    name: elem.customName,
                    fixed: Boolean(elem.fixed),
                    price: elem.price || 1,
                    normHours: elem.normHours || 1
                });
            } else if (elem.new && elem.masterLaborId && elem.storeGroupId) {
                newLabors.push({
                    id: `${elem.masterLaborId}${elem.storeGroupId}`,
                    masterLaborId: elem.masterLaborId,
                    storeGroupId: elem.storeGroupId,
                    disabled: Boolean(elem.disabled),
                    crossId: elem.crossId || null,
                    name: elem.customName,
                    fixed: Boolean(elem.fixed),
                    price: elem.price || 1,
                    normHours: elem.normHours || 1
                });
            }
        });

        if (newLabors.length) {
            await fetchAPI('PUT', 'labors', null, newLabors);
        }
        if (labors.length) {
            await fetchAPI('PUT', 'labors', null, labors);
        }
        this.fetchLabors();
    }

    fetchLabors = async () => {
        await this.setState({
            loading: true,
            selectedRowKeys: []
        });

        const response = await fetchAPI('GET', 'labors', { all: true });
        response.labors.sort((a, b) =>
            a.masterLaborId < b.masterLaborId ? -1 : a.masterLaborId > b.masterLaborId ? 1 : 0
        );
        response.labors.map((elem, index) => {
            elem.key = index;
            elem.laborCode = `${elem.masterLaborId}-${elem.storeGroupId}`;
            elem.price = elem.laborPrice.price;
            elem.fixed = elem.laborPrice.fixed;
            elem.normHours = elem.laborPrice.normHours;
        });
        this.setState({
            labors: response.labors,
            currentPage:
                this.props.location.state && this.props.location.state.showForm
                    ? Math.ceil(response.labors.length / 10)
                    : 1
        });

        if (this.props.location.state && this.props.location.state.showForm) {
            this.nameInput.focus();
        }

        this.setState({
            loading: false
        });
    };

    fetchData = async () => {
        const masterLabors = await fetchAPI('GET', 'labors/master');
        this.setState({
            masterLabors: masterLabors.masterLabors
        });

        const storeGroups = await fetchAPI('GET', 'store_groups');
        this.setState({
            storeGroups
        });
        this.buildStoreGroupsTree();

        this.fetchLabors();
    };

    buildStoreGroupsTree() {
        const treeData = [];
        for (let i = 0; i < this.state.storeGroups.length; i++) {
            const parentGroup = this.state.storeGroups[i];
            treeData.push({
                title: `${parentGroup.name} (#${parentGroup.id})`,
                value: parentGroup.id,
                key: `${i}`,
                children: []
            });
            for (let j = 0; j < parentGroup.childGroups.length; j++) {
                const childGroup = parentGroup.childGroups[j];
                treeData[i].children.push({
                    title: `${childGroup.name} (#${childGroup.id})`,
                    value: childGroup.id,
                    key: `${i}-${j}`,
                    children: []
                });
                for (let k = 0; k < childGroup.childGroups.length; k++) {
                    const lastNode = childGroup.childGroups[k];
                    treeData[i].children[j].children.push({
                        title: `${lastNode.name} (#${lastNode.id})`,
                        value: lastNode.id,
                        key: `${i}-${j}-${k}`,
                        children: []
                    });
                    for (let l = 0; l < lastNode.childGroups.length; l++) {
                        const elem = lastNode.childGroups[l];
                        treeData[i].children[j].children[k].children.push({
                            title: `${elem.name} (#${elem.id})`,
                            value: elem.id,
                            key: `${i}-${j}-${k}-${l}`
                        });
                    }
                }
            }
        }
        this.treeData = treeData;
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { isMobile } = this.props;
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRows
                });
            }
        };

        const {
            loading,
            labors,
            filterCode,
            filterCrossId,
            filterId,
            filterDetail,
            filterDefaultName,
            filterName,
            currentPage,
            selectedLaborId
        } = this.state;
        if (
            !isMobile &&
            isGrantAccessed(this.props.user, grants.DIRECTORIES_JOBS, accesses.ROWO) &&
            labors.length &&
            (labors[labors.length - 1].name != '' || labors[labors.length - 1].masterLaborId != '')
        ) {
            labors.push({
                key: labors.length,
                laborCode: '0000-0000000',
                masterLaborId: '',
                storeGroupId: '',
                detailTitle: '',
                customName: '',
                name: '',
                fixed: false,
                normHours: 1,
                price: null,
                new: true
            });
        }
        const columns = !isMobile ? this.columns() : this.mobileColumns();
        let dataSource = [...labors];
        dataSource = dataSource.filter(elem => !elem.deleted);
        if (filterCode) {
            dataSource = dataSource.filter((data, i) => data.laborCode.includes(filterCode));
        }
        if (filterCrossId) {
            dataSource = dataSource.filter((data, i) =>
                String(data.crossId).includes(filterCrossId));
        }
        if (filterId) {
            dataSource = dataSource.filter((data, i) =>
                String(data.masterLaborId).includes(String(filterId)));
        }
        if (filterDetail) {
            dataSource = dataSource.filter((data, i) =>
                String(data.storeGroupId).includes(String(filterDetail)));
        }
        if (filterDefaultName) {
            dataSource = dataSource.filter((data, i) =>
                data.name.toLowerCase().includes(filterDefaultName.toLowerCase()));
        }
        if (filterName) {
            dataSource = dataSource.filter((data, i) =>
                data.customName.toLowerCase().includes(filterName.toLowerCase()));
        }

        return (
            <Layout
                controls={
                    isGrantAccessed(this.props.user, grants.DIRECTORIES_JOBS, accesses.ROWO) && (<React.Fragment>
                        <Button
                           
                            onClick={() => this.updatePrice()}
                            style={{ marginRight: 10 }}
                        >
                            <FormattedMessage id='update_price' />
                        </Button>
                        <Button
                           
                            onClick={() => this.saveLabors()}
                            type='primary'
                        >
                            <FormattedMessage id='save' />
                        </Button>
                    </React.Fragment>)
                }
                title={<FormattedMessage id='navigation.labors_page' />}
            >
                <Table
                    bordered
                    columns={columns}
                    dataSource={dataSource}
                    loading={loading}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={{
                        pageSize: 10,
                        hideOnSinglePage: true,
                        current: currentPage,
                        onChange: page => {
                            this.setState({
                                currentPage: page
                            });
                        }
                    }}
                    rowSelection={!isMobile && rowSelection}
                />
                <LaborPriceGroupsModal
                    hideModal={() => {
                        this.setState({
                            selectedLaborId: undefined
                        });
                    }}
                    laborId={selectedLaborId}
                    visible={Boolean(selectedLaborId)}
                />
            </Layout>
        );
    }
}
