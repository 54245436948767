exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__buttonGroup---3jwQa>button{margin:0 4px}.styles-m__filters---2A89q{display:flex;position:fixed;flex-direction:column;justify-content:space-between;overflow:initial;box-sizing:border-box;background-color:#fff;padding:16px;margin-bottom:24px;z-index:210;width:calc(100% - 256px);box-shadow:0 3px 10px rgba(0,0,0,.16),0 3px 10px rgba(0,0,0,.23);-webkit-tap-highlight-color:rgba(0,0,0,0)}.styles-m__filtersCollapsed---2TylP{width:calc(100% - 80px)}.styles-m__table---lriW-{padding:96px 0 0}@media screen and (max-width:1199px){.styles-m__filtersCollapsed---2TylP{width:100%}}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AACI,sCACI,YACJ,CAGJ,2BACI,YAAa,CACb,cAAe,CACf,qBAAsB,CACtB,6BAA8B,CAC9B,gBAAiB,CACjB,qBAAsB,CACtB,qBAAoC,CACpC,YAAa,CACb,kBAAmB,CACnB,WAAY,CACZ,wBAAyB,CACzB,gEACoC,CACpC,yCAEJ,CAEA,oCACI,uBACJ,CAQA,yBACI,gBACJ,CARA,qCACI,oCACI,UACJ,CACJ","file":"styles.m.css","sourcesContent":[".buttonGroup {\n    & > button {\n        margin: 0 4px;\n    }\n}\n\n.filters {\n    display: flex;\n    position: fixed;\n    flex-direction: column;\n    justify-content: space-between;\n    overflow: initial;\n    box-sizing: border-box;\n    background-color: rgb(255, 255, 255);\n    padding: 16px;\n    margin-bottom: 24px;\n    z-index: 210;\n    width: calc(100% - 256px);\n    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px,\n        rgba(0, 0, 0, 0.23) 0px 3px 10px;\n    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n    /* width: calc(100% - 310px); */\n}\n\n.filtersCollapsed {\n    width: calc(100% - 80px);\n}\n\n@media screen and (max-width: 1199px) {\n    .filtersCollapsed {\n        width: 100%;\n    }\n}\n\n.table {\n    padding: 96px 0px 0px 0px;\n}\n"]}]);

// Exports
exports.locals = {
	"buttonGroup": "styles-m__buttonGroup---3jwQa",
	"filters": "styles-m__filters---2A89q",
	"filtersCollapsed": "styles-m__filtersCollapsed---2TylP",
	"table": "styles-m__table---lriW-"
};