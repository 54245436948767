import { PrinterOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { DataItem, Numeral } from 'commons';
import { setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import { DiagnosticPrintModal } from 'forms/OrderForm/OrderFormTables/DiagnosticTable/components/VinDiagnostic/DiagnosticPrintModal';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const dateFormat = 'YYYY-MM-DD';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setModal
};

/**
 * Table of inspection intervals
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class BookDiagnosticsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            printOpen: false
        };

        this.columns = () => [
            {
                title: <FormattedMessage id='calls-table.order' />,
                key: 'orderNum',
                dataIndex: 'orderNum',
                width: '40%',
                render: (data, row) => (
                    <div>
                        <div
                            className={Styles.orderLink}
                            onClick={() => {
                                this.props.getReport({
                                    link: `/orders/reports/completedWorkReport/${row.orderId}`,
                                    name: 'completedWorkReport',
                                    token: this.props.token
                                });
                            }}
                        >
                            {row.orderNum ? row.orderNum : ''}
                        </div>{' '}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='universal_filters_tags.beginDate' />,
                key: 'orderBeginDatetime',
                dataIndex: 'orderBeginDatetime',
                width: '10%',
                render: (data, row) => {
                    return (
                        <div>
                            {row.orderBeginDatetime ? dayjs(row.orderBeginDatetime).format('DD.MM.YYYY HH:mm') : ''}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='add_order_form.delivery_date' />,
                key: 'orderSuccessDatetime',
                dataIndex: 'orderSuccessDatetime',
                width: '10%',
                render: (data, row) => {
                    return (
                        <div>
                            {row.orderSuccessDatetime ? dayjs(row.orderSuccessDatetime).format('DD.MM.YYYY HH:mm') : ''}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='client_order_tab.odometer' />,
                key: 'orderOdometerValue',
                dataIndex: 'orderOdometerValue',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='description' />,
                key: 'laborName',
                dataIndex: 'laborName',
                width: '10%',
                render: (data, row) => {
                    return <div>{[...new Set(row.diagnostics)].join(', ')}</div>;
                }
            },
            {
                key: 'laborName',
                dataIndex: 'laborName',
                align: 'center',
                width: '5%',
                render: (data, row) => {
                    return (
                        <div>
                            <Tooltip title={<FormattedMessage id='print' />}>
                                <Button
                                    icon={<PrinterOutlined />}
                                    onClick={() => this.setState({ printOpen: true, orderPrintId: row.orderId })}
                                    type='text'
                                />
                            </Tooltip>
                        </div>
                    );
                }
            }
        ];
    }

    render() {
        const {
            diagtosticsData,
            intl: { formatMessage },
            isMobile,
            token
        } = this.props;

        const { printOpen, orderPrintId } = this.state;

        // const pagination = {
        //     pageSize: sort.pageSize,
        //     total: Math.ceil(_.get(stats, 'countOrders', 0) / sort.pageSize) * sort.pageSize,
        //     hideOnSinglePage: true,
        //     current: sort.page,
        //     onChange: async (page, pageSize) => {
        //         await setPageOrders({ page, pageSize });
        //         fetchVehicleOrders();
        //     }
        // };

        return !isMobile ? (
            <div>
                <div className={Styles.tableCont}>
                    <Table
                        bordered
                        columns={this.columns()}
                        dataSource={diagtosticsData}
                        pagination={false}
                        rowKey='id'
                        scroll={{ x: 'auto', y: '60vh' }}
                        size='small'
                    />
                </div>
                <DiagnosticPrintModal
                    handleClose={() => {
                        this.setState({ printOpen: false });
                    }}
                    open={this.state.printOpen}
                    orderId={orderPrintId}
                    token={token}
                />
            </div>
        ) : (
            <div>
                {diagtosticsData.map(
                    ({
                        orderNum,
                        orderBeginDatetime,
                        orderSuccessDatetime,
                        diagnostics,
                        orderOdometerValue,
                        orderId,
                        orderStatus
                    }) => (
                        <div
                            style={{
                                borderBottom: '3px solid var(--primary)',
                                marginBottom: 12
                            }}
                        >
                            <div className={Styles.firtBlockInfoStyle}>
                                <div className={Styles.dataItemBlock}>
                                    <DataItem
                                        className={Styles.dataItemMobile}
                                        label={<FormattedMessage id='reports_page.orders' />}
                                    >
                                        <div
                                            className={Styles.orderLink}
                                            onClick={() => {
                                                this.props.getReport({
                                                    link: `/orders/reports/completedWorkReport/${orderId}`,
                                                    name: 'completedWorkReport',
                                                    token: this.props.token
                                                });
                                            }}
                                        >
                                            {orderNum}
                                        </div>
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItemMobile}
                                        label={<FormattedMessage id='status' />}
                                    >
                                        <span
                                            className={
                                                orderStatus == 'progress'
                                                    ? Styles.green
                                                    : orderStatus == 'success'
                                                    ? Styles.blue
                                                    : Styles.red
                                            }
                                        >
                                            <FormattedMessage id={`order_statuses_mapper.${orderStatus}`} />
                                        </span>
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItemMobile}
                                        label={<FormattedMessage id='business-package-form.activation_datetime' />}
                                    >
                                        {orderBeginDatetime ? dayjs(orderBeginDatetime).format(dateFormat) : '-'}
                                    </DataItem>
                                </div>
                                <div className={Styles.dataItemBlock}>
                                    <DataItem
                                        className={Styles.dataItemMobile}
                                        label={<FormattedMessage id='add_order_form.delivery_date' />}
                                    >
                                        {orderSuccessDatetime ? dayjs(orderSuccessDatetime).format(dateFormat) : '-'}
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItemMobile}
                                        label={<FormattedMessage id='client_order_tab.odometer' />}
                                    >
                                        <Numeral mask='0,0'>{Number(orderOdometerValue) || '-'}</Numeral>{' '}
                                        <FormattedMessage id='online_service_book_page.km' />
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItemMobile}
                                        label={<FormattedMessage id='navigation.diagnostic_patterns' />}
                                    >
                                        {[...new Set(diagnostics)].join(', ')}
                                    </DataItem>
                                    <DataItem className={Styles.dataItemMobile} label={<FormattedMessage id='print' />}>
                                        <Tooltip title={<FormattedMessage id='print' />}>
                                            <Button
                                                icon={<PrinterOutlined />}
                                                onClick={() =>
                                                    this.setState({ printOpen: true, orderPrintId: orderId })
                                                }
                                                type='text'
                                            />
                                        </Tooltip>
                                    </DataItem>
                                </div>
                                <DiagnosticPrintModal
                                    handleClose={() => {
                                        this.setState({ printOpen: false });
                                    }}
                                    isMobile={isMobile}
                                    open={this.state.printOpen}
                                    orderId={orderPrintId}
                                    token={token}
                                />
                            </div>
                        </div>
                    )
                )}
            </div>
        );
    }
}
