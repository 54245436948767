import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image } from 'antd';
import { Layout } from 'commons';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const struct = [
    // {
    //     blockTitle: 'directories.popular',
    //     color: 'var(--db_approve)',
    //     items: [
    //         {
    //             itemName: 'navigation.products',
    //             disabled: false,
    //             link: book.products
    //         },
    //         {
    //             itemName: 'navigation.client_hot_operations',
    //             disabled: false,
    //             link: book.clientHotOperations
    //         },
    //         {
    //             itemName: 'navigation.vehicles',
    //             disabled: user => isForbidden(user, permissions.GET_CLIENTS),
    //             link: book.vehicles
    //         },
    //         {
    //             itemName: 'navigation.employees',
    //             disabled: false,
    //             link: book.employeesPage
    //         }
    //     ]
    // },
    {
        blockTitle: 'directories.counterparties',
        color: 'var(--db_progress)',
        items: [
            {
                itemName: 'navigation.client_hot_operations',
                disabled: user => !isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST),
                link: book.clientHotOperations
            },
            {
                itemName: 'navigation.vehicles',
                disabled: user => !isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST),
                link: book.vehicles
            },
            {
                itemName: 'navigation.employees',
                disabled: user => !isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEES_LIST),
                link: book.employeesPage
            },
            {
                itemName: 'navigation.suppliers',
                disabled: user => !isGrantAccessed(user, grants.DIRECTORIES_SUPPLIERS_LIST),
                link: book.suppliersPage
            },
            {
                itemName: 'navigation.aggregates',
                disabled: user => !isGrantAccessed(user, grants.DIRECTORIES_AGGREGATES_LIST),
                link: book.aggregatesPage
            }
            // {
            //     itemName: 'CRM',
            //     disabled: false,
            //     link: book.intDirectories
            // },
            // {
            //     itemName: 'packages.rights_n_access',
            //     disabled: true,
            //     link: book.rights
            // }
        ]
    },
    {
        blockTitle: 'navigation.products',
        color: 'var(--db_reserve)',
        items: [
            {
                itemName: 'navigation.products',
                disabled: user => !isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST),
                link: book.products
            },
            // {
            //     itemName: 'navigation.products_groups',
            //     disabled: false,
            //     link: book.productsGroups
            // },
            // {
            //     itemName: 'navigation.markups_groups',
            //     disabled: false,
            //     link: book.priceGroups
            // },
            {
                itemName: 'navigation.exchange_rate',
                disabled: user => !isGrantAccessed(user, grants.DIRECTORIES_CURRENCIES),
                link: book.exchangeRate
            },
            // {
            //     itemName: 'navigation.warehouses',
            //     disabled: false,
            //     link: book.warehouses
            // },
            // {
            //     itemName: 'wms.cells',
            //     disabled: false,
            //     link: book.wms
            // },
            // {
            //     itemName: 'navigation.units',
            //     disabled: false,
            //     link: book.units
            // },
            {
                itemName: 'navigation.crosses',
                link: book.crosses,
                disabled: user => !isGrantAccessed(user, grants.DIRECTORIES_CROSSES)
            }
        ]
    },
    {
        blockTitle: 'navigation.labors_page',
        color: 'var(--db_success)',
        items: [
            {
                itemName: 'navigation.labors_page',
                disabled: user => !isGrantAccessed(user, grants.DIRECTORIES_JOBS),
                link: book.laborsPage
            },
            {
                itemName: 'navigation.my_normatives',
                disabled: user => !isGrantAccessed(user, grants.DIRECTORIES_STANDARDS),
                link: book.myNormative
            },
            {
                itemName: 'navigation.complexes',
                disabled: user => !isGrantAccessed(user, grants.DIRECTORIES_COMPLEXES),
                link: book.complexes
            }
        ]
    }
    // {
    //     blockTitle: 'directories.orders',
    //     color: 'var(--db_success)',
    //     items: [
    //         {
    //             itemName: 'navigation.labors_page',
    //             disabled: false,
    //             link: book.laborsPage
    //         },
    //         {
    //             itemName: 'navigation.my_normatives',
    //             disabled: false,
    //             link: book.myNormative
    //         },
    //         {
    //             itemName: 'navigation.complexes',
    //             disabled: false,
    //             link: book.complexes
    //         },
    //         {
    //             itemName: 'navigation.regulations',
    //             disabled: false,
    //             link: book.regulations
    //         },
    //         // {
    //         //     itemName: 'navigation.related_labors',
    //         //     disabled: true,
    //         //     link: book.exception
    //         // },
    //         {
    //             itemName: 'navigation.order_statuses_directory',
    //             disabled: false,
    //             link: book.repairMapSetting
    //         },
    //         {
    //             itemName: 'navigation.statuses',
    //             disabled: false,
    //             link: book.detailStatusSettings
    //         },
    //         {
    //             itemName: 'navigation.locations_settings',
    //             disabled: false,
    //             link: book.locationSettings
    //         },
    //         {
    //             itemName: 'navigation.diagnostic_patterns',
    //             disabled: false,
    //             link: book.diagnosticsDirectory
    //         }
    //         // {
    //         //     itemName: 'navigation.quick_requests',
    //         //     disabled: false,
    //         //     link: book.quick_requests
    //         // }
    //     ]
    // },
    // {
    //     blockTitle: 'navigation.accounting',
    //     color: 'var(--approve)',
    //     items: [
    //         {
    //             itemName: 'navigation.cash_settings',
    //             disabled: false,
    //             link: book.cashSettingsPage
    //         },
    //         {
    //             itemName: 'navigation.requisites',
    //             disabled: false,
    //             link: book.requisites
    //         },
    //         {
    //             itemName: 'navigation.accounts_entries',
    //             disabled: false,
    //             link: book.analytics
    //         },
    //         {
    //             itemName: 'directory_page.numbering',
    //             disabled: false,
    //             link: book.ReferenceBookPage
    //         }
    //     ]
    // },
    // {
    //     blockTitle: 'directories.general_settings',
    //     color: 'var(--db_not_complete)',
    //     items: [
    //         {
    //             itemName: 'navigation.station_settings',
    //             disabled: false,
    //             link: book.settings
    //         },
    //         {
    //             itemName: 'navigation.theme_settings',
    //             disabled: user => !isAdmin(user),
    //             link: book.themeSettings
    //         },
    //         {
    //             itemName: 'navigation.suppliers_pricelists',
    //             disabled: false,
    //             link: book.availabilitiesPage
    //         },
    //         {
    //             itemName: 'navigation.suppliers_API',
    //             disabled: false,
    //             link: book.apiSettings
    //         },
    //         {
    //             itemName: 'navigation.IP_calls',
    //             disabled: true,
    //             link: book.exception
    //         },
    //         {
    //             itemName: 'navigation.SMS',
    //             disabled: user => isForbidden(user, permissions.ACCESS_SMS_TEMPLATES),
    //             link: book.smsSettings
    //         },
    //         {
    //             itemName: 'navigation.source',
    //             disabled: false,
    //             link: book.source
    //         },
    //         {
    //             itemName: 'navigation.www',
    //             disabled: false,
    //             link: book.webSettings
    //         }
    //     ]
    // }
];

const mapStateToProps = state => {
    return {
        user: state.auth,
        isMobile: state.ui.views.isMobile
    };
};

@connect(mapStateToProps, void 0)
export default class DirectoriesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            helperDrawerOpen: false,
            allLinks: []
        };
    }

    componentDidMount() {}

    _renderBlock = ({ blockTitle, items, color }, key) => {
        const { isMobile } = this.props;

        return (
            <div key={key} className={!isMobile ? Styles.block : Styles.blockMobile}>
                <div className={Styles.blockTitle}>
                    <FormattedMessage id={blockTitle} />
                </div>
                <div className={!isMobile ? Styles.blockItems : Styles.blockItemsMobile}>
                    {items.map((item, key) => this._renderItem(blockTitle, item, key, color))}
                </div>
            </div>
        );
    };

    _renderItem = (blockTitle, { itemName, link, disabled, oldApp }, key, color) => {
        const { user, isMobile } = this.props;
        const itemDisabled = _.isFunction(disabled) ? disabled(user) : disabled;

        return (
            <div
                key={key}
                className={
                    !isMobile
                        ? itemDisabled
                            ? `${Styles.disabledItem} ${Styles.item}`
                            : Styles.item
                        : `${itemDisabled ? Styles.disabledItem : ''} ${Styles.itemMobile}`
                }
            >
                {oldApp ? (
                    <a className={Styles.buttonLink} href={link}>
                        <Button
                            className={Styles.itemButton}
                            disabled={itemDisabled}
                            style={{ fontWeight: 500, border: `2px solid ${color}` }}
                        >
                            <FormattedMessage id={itemName} />
                        </Button>
                    </a>
                ) : (
                    <Link className={Styles.buttonLink} to={link}>
                        <Button
                            className={Styles.itemButton}
                            disabled={itemDisabled}
                            style={{ fontWeight: 500, border: `2px solid ${color}` }}
                        >
                            <FormattedMessage id={itemName} />
                        </Button>
                    </Link>
                )}
            </div>
        );
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    render() {
        const { helperDrawerOpen, allLinks, isMobile } = this.state;

        return (
            <div>
                <Layout
                    controls={
                        <div>
                            <Button
                                icon={<QuestionCircleOutlined />}
                                onClick={async () => {
                                    const links = await fetchAPI(
                                        'GET',
                                        '/helps',
                                        { helpId: 'directories_and_settings' },
                                        undefined,
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    this.setState({
                                        allLinks: links,
                                        helperDrawerOpen: true
                                    });
                                }}
                                style={{
                                    fontSize: 22,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                type='text'
                            />
                        </div>
                    }
                    description={<FormattedMessage id='directories.description' />}
                    title={<FormattedMessage id='navigation.directories' />}
                >
                    {struct.map((block, key) => this._renderBlock(block, key))}
                </Layout>
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </div>
        );
    }
}
