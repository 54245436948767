import { Col, Row } from 'antd';
import { MODES } from 'modals/RefactoredCashOrderModal/constants';
import {
    selectSelectedClientId,
    setClientsFilters
} from 'modals/RefactoredCashOrderModal/redux/clients/duck';
import {
    selectClientSearchType,
    selectMode,
    selectSelectedOrderId,
    selectSelectedStoreDocId,
    setClientSearchType,
    setStoreDocsFilters
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    ClientCounterpartySearchInput,
    ClientCounterpartySearchTypeSelector,
    ClientsTable,
    ClientVisualizer,
    OrdersTable,
    OrderVisualizer,
    StoreDocsTable,
    StoreDocVisualizer
} from './components';
import { SEARCH_TYPES } from './constants';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    mode: selectMode(state),
    clientSearchType: selectClientSearchType(state),
    selectedClientId: selectSelectedClientId(state),
    selectedOrderId: selectSelectedOrderId(state),
    selectedStoreDocId: selectSelectedStoreDocId(state)
});

const mapDispatchToProps = {
    setClientSearchType,
    setClientsFilters,
    setStoreDocsFilters
};

/**
 * When counterparty is a CLIENT this component will render all additional components and
 * handle logic for data processing.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientCounterpartyContainer extends React.Component {
    render() {
        const {
            mode,

            clientSearchType,
            selectedClientId,
            selectedOrderId,
            selectedStoreDocId,
            isMobile,
            editable
        } = this.props;

        const span = isMobile ? 24 : 12;
        let counterpartyTable = null;
        if (mode === MODES.ADD || editable) {
            switch (clientSearchType) {
                case SEARCH_TYPES.FOR_CLIENT:
                    if (selectedOrderId) {
                        counterpartyTable = (
                            <OrderVisualizer editable={editable} isMobile={isMobile} />
                        );
                    } else if (selectedClientId) {
                        counterpartyTable = (
                            <div>
                                <ClientVisualizer editable={editable} />
                                <OrdersTable isMobile={isMobile} />
                            </div>
                        );
                    } else {
                        counterpartyTable = <ClientsTable />;
                    }
                    break;
                case SEARCH_TYPES.FOR_ORDER:
                    counterpartyTable = selectedOrderId ? (
                        <OrderVisualizer editable={editable} isMobile={isMobile} />
                    ) : (
                        <OrdersTable />
                    );
                    break;
                case SEARCH_TYPES.FOR_DOCUMENT:
                    counterpartyTable = selectedStoreDocId ? (
                        <StoreDocVisualizer editable={editable} />
                    ) : (
                        <StoreDocsTable />
                    );
                    break;
            }
        } else if (selectedOrderId) {
            counterpartyTable = <OrderVisualizer editable={editable} isMobile={isMobile} />;
        } else if (selectedStoreDocId) {
            counterpartyTable = <StoreDocVisualizer editable={editable} />;
        } else if (selectedClientId) {
            counterpartyTable = <ClientVisualizer editable={editable} />;
        }

        console.log(this, counterpartyTable);

        return (
            <div className={Styles.mainContainer}>
                <Row className={Styles.row}>
                    <Col className={Styles.col} span={span}>
                        <ClientCounterpartySearchInput editable={editable} />
                    </Col>
                    <Col className={Styles.col} span={span}>
                        <ClientCounterpartySearchTypeSelector editable={editable} />
                    </Col>
                </Row>
                <Row className={Styles.row}>
                    <Col className={Styles.col} span={24}>
                        <div className={Styles.counterpartyTables}>{counterpartyTable}</div>
                    </Col>
                </Row>
            </div>
        );
    }
}
