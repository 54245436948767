/* eslint-disable react/sort-comp */
/* eslint-disable react/no-array-index-key */
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
    ApiOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    FormOutlined,
    LoadingOutlined,
    MenuOutlined,
    MessageOutlined,
    MinusCircleOutlined,
    MinusOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    UnorderedListOutlined,
    ZoomInOutlined,
    ZoomOutOutlined
} from '@ant-design/icons';
import { Button, Modal, Popover, Radio, Select, Slider, Spin, Table, Tooltip, TreeSelect } from 'antd';
import { MODALS, loadModal } from 'core/modals/duck';
import { CommentaryButton, PhotoButton } from 'forms/OrderForm/OrderFormTables/DiagnosticTable';
import PaddedWrapper from 'forms/OrderForm/OrderFormTables/DiagnosticTable/components/PaddedWrapper';
import classFilterDataSource from 'forms/OrderForm/OrderFormTables/DiagnosticTable/components/VinDiagnostic/DiagnosticTableDrawer/classFilterDataSource';
import _, { get, uniqBy } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { GridIcon } from 'theme';
import { fetchAPI, filterByPart, filterTreeNodeByPart } from 'utils';
import Styles from './styles.m.css';

const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

const { Option } = Select;

const green = '#44982B';
const red = '#ff3725';
const yellow = '#ffc801';

const diagnosticFilterStatuses = [
    {
        labelId: 'order_form_table.diagnostic.filters.answer.ok',
        partAnswer: '1'
    },
    {
        labelId: 'order_form_table.diagnostic.filters.answer.attention',
        partAnswer: '2'
    },
    {
        labelId: 'order_form_table.diagnostic.filters.answer.critical',
        partAnswer: '3'
    },
    {
        labelId: 'order_form_table.diagnostic.filters.answer.blocked',
        partAnswer: '4'
    },
    {
        labelId: 'order_form_table.diagnostic.filters.answer.not_selected',
        partAnswer: [null, '0']
    }
];

const answerDisplay = [
    {
        answer: 1,
        color: '#44982ba6',
        icon: CheckCircleOutlined
    },
    {
        answer: 3,
        color: '#e72c1c78',
        icon: CloseCircleOutlined
    },
    {
        answer: 2,
        color: '#fdc50070',
        icon: ExclamationCircleOutlined
    }
];

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile,
    modalProps: state.modals.modalProps
});

const mapDispatchToProps = {
    loadModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class VinDiagnosticModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayType: 'list',
            categoryMode: false,
            categories: [],
            loading: false,
            tableLoading: false,
            zoomed: false,
            itemsInfo: [],
            blockPositions: [],
            tableHoverCode: undefined,
            imgHoverCode: undefined,
            imgHoverIndex: undefined,
            checkedCodes: [],
            infoModalVisible: false,
            infoItem: undefined,
            imageList: [],
            image: undefined,
            itemsListEmpty: false,
            zoomMultiplier: 0.75,
            allCategories: [],
            listItemPreview: [],
            previewIndex: undefined,
            variantRadioValue: 0,
            index: 0,
            filters: { placeName: '' },
            relatedTableFilters: { name: '' },
            createdDgNeededOems: []
        };

        this.clickedBlockRef = React.createRef();

        this.dgColumns = () => [
            {
                title: <FormattedMessage id='order_form_table.action' />,
                key: 'actions',
                width: '5%',
                render: (row, _, index) => {
                    const popoverContent = (
                        <div className={Styles.actionBlock}>
                            {row.partComment ? (
                                <Button
                                    className={Styles.commentaryButton}
                                    onClick={() => this.setState({ commentaryRow: row })}
                                    title={this.props.intl.formatMessage({
                                        id: 'commentary.edit'
                                    })}
                                    type='primary'
                                >
                                    <FormOutlined className={Styles.commentaryButtonIcon} />
                                </Button>
                            ) : (
                                <Tooltip title={<FormattedMessage id='commentary.add' />} zIndex={3001}>
                                    <Button onClick={() => this.setState({ commentaryRow: row })}>
                                        <MessageOutlined />
                                    </Button>
                                </Tooltip>
                            )}
                            <PhotoButton
                                data-qa='btn_photo_diagnostic_table_order_page'
                                disabled={row.disabled}
                                getCurrentDiagnostic={this.props.modalProps.fetchDiagnosticData}
                                onOk={partPhotosHashes => {
                                    row.partPhotosHashes = partPhotosHashes;
                                    this.putDiagnosticData({
                                        ...row,
                                        partPhotosHashes
                                    });
                                    this.setState({});
                                }}
                                rowProp={row}
                            />
                            <Tooltip title={<FormattedMessage id='order-page.create_copy' />}>
                                <Button
                                    icon={<PlusCircleOutlined />}
                                    onClick={async () => {
                                        const ids = await this.putDiagnosticData({
                                            ...row,
                                            partAnswer: null,
                                            partOECode: null,
                                            createItem: true
                                        });
                                        this.props.modalProps.relatedParts.splice(index + 1, 0, {
                                            ...row,
                                            partAnswer: null,
                                            partOECode: null,
                                            diagnosticId: get(ids, '[0]')
                                        });
                                        this.setState({});
                                    }}
                                    type='text'
                                />
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id='order-page.create_copy' />}>
                                <Button
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        this.deleteDiagnosticData({
                                            ...row
                                        });
                                        this.setState({});
                                    }}
                                    type='text'
                                />
                            </Tooltip>
                        </div>
                    );

                    return (
                        <Popover
                            content={popoverContent}
                            getPopupContainer={trigger => trigger.parentNode}
                            placement='bottomLeft'
                            trigger='click'
                        >
                            <Button data-qa='btn_menu_diagnostic_table_order_page' disabled={row.disabled}>
                                <MenuOutlined />
                            </Button>
                        </Popover>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.diagnostic.system' />,
                key: 'systemName',
                dataIndex: 'systemName',
                filtered: this.state.filters.systemName,
                filterDropdown: () => {
                    return (
                        <div style={{ padding: 8 }}>
                            <Select
                                allowClear
                                getPopupContainer={trigger => trigger.parentNode}
                                id='select_unique'
                                onChange={selectValue => this.handleFiltersChange({ systemName: selectValue })}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.diagnostic.filters.element.placeholder'
                                })}
                                style={{ display: 'block' }}
                                value={this.state.filters.systemName}
                            >
                                {this.state.filterParams.systemNames.map(systemName => (
                                    <Option key={systemName} value={systemName}>
                                        {systemName}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    );
                }
            },
            // {
            //     title: <FormattedMessage id='order_form_table.diagnostic.position_title' />,
            //     key: 'placeName',
            //     dataIndex: 'placeName',
            //     filtered: this.state.filters.placeName,
            //     filterDropdown: () => {
            //         return (
            //             <TableSearchInput
            //                 onChange={e => this.handleFiltersChange({ placeName: e.target.value })}
            //                 placeholder={this.props.intl.formatMessage({
            //                     id: 'order_form_table.diagnostic.filters.position.placeholder'
            //                 })}
            //                 value={this.state.filters.placeName}
            //                 zIndex={3001}
            //             />
            //         );
            //     }
            // },
            {
                title: <FormattedMessage id='order_form_table.diagnostic.node_title' />,
                key: 'partName',
                filtered: this.state.filters.partName && this.state.filters.partName.length,
                render: row => {
                    const color = get(
                        answerDisplay.filter(({ answer }) => answer === row.partAnswer),
                        'color'
                    );

                    return (
                        <div style={{ borderBottom: color ? `3px solid ${color}` : 'none', display: 'inline-block' }}>
                            {get(row, 'partName')}
                        </div>
                    );
                },
                onCell: row => ({
                    onClick: () => {
                        this.setState({ commentaryRow: row });
                    }
                }),
                filterDropdown: () => {
                    return (
                        <PaddedWrapper>
                            <Select
                                allowClear
                                filterOption={filterByPart}
                                getPopupContainer={trigger => trigger.parentNode}
                                mode='multiple'
                                onChange={selectValue => this.handleFiltersChange({ partName: selectValue })}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.diagnostic.filters.node.placeholder'
                                })}
                                style={{ minWidth: 300, maxWidth: 500 }}
                                value={this.state.filters.partName}
                            >
                                {this.state.filterParams.partNames.map(partName => {
                                    return (
                                        <Option key={partName} value={partName}>
                                            {partName}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </PaddedWrapper>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.diagnostic.oe_code_title' />,
                key: 'partOECode',
                onCell: (record, index) => ({
                    onClick: async () => {
                        const { selectedDiagnosticIndex } = this.state;
                        await this.setState({
                            selectedDiagnosticIndex: selectedDiagnosticIndex === index ? undefined : index,
                            stateStoreGroupId: selectedDiagnosticIndex === index ? undefined : record.partStoreGroupId
                        });
                        this.setState({ tableLoading: true });
                        this.fetchData();
                    }
                }),
                render: (row, _, index) => {
                    const { selectedDiagnosticIndex } = this.state;
                    const color = get(
                        answerDisplay.find(({ answer }) => answer === row.partAnswer),
                        'color'
                    );
                    const partOECode = get(row, 'partOECode');

                    return partOECode ? (
                        <div
                            style={{
                                borderBottom: color ? `3px solid ${color}` : 'none',
                                display: 'inline-block',
                                fontWeight: index === selectedDiagnosticIndex ? 700 : 500
                            }}
                        >
                            {partOECode}
                        </div>
                    ) : (
                        <div style={{ fontWeight: index === selectedDiagnosticIndex ? 700 : 500 }}>-----</div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.diagnostic.status_title' />,
                key: 'partAnswer',
                filterDropdown: ({ visible }) => {
                    return (
                        <PaddedWrapper>
                            <Select
                                allowClear
                                dropdownStyle={{ minWidth: 250, maxWidth: 400 }}
                                filtered={this.state.labelId && this.state.labelId.length}
                                getPopupContainer={trigger => trigger.parentNode}
                                mode='multiple'
                                onChange={(labelId, option) => {
                                    this.setState({ labelId });
                                    this.handleFiltersChange({
                                        partAnswer: option.map(({ partAnswer }) => partAnswer)
                                    });
                                }}
                                open={visible}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.diagnostic.filters.answer.placeholder'
                                })}
                                showSearch={false}
                                style={{ minWidth: 250, maxWidth: 400 }}
                                value={this.state.labelId}
                            >
                                {diagnosticFilterStatuses.map(({ labelId, partAnswer }) => (
                                    <Option key={labelId} partAnswer={partAnswer} value={labelId}>
                                        <FormattedMessage id={labelId} />
                                    </Option>
                                ))}
                            </Select>
                        </PaddedWrapper>
                    );
                },
                render: row => {
                    return (
                        <div style={{ display: 'flex' }}>
                            <Button
                                disabled={row.partAnswer == 4 || row.diagnosticComplete}
                                icon={<CheckCircleOutlined style={{ color: row.partAnswer == 1 && green }} />}
                                onClick={() => {
                                    this.handleStatusChange(1, row);
                                    this.setState({});
                                }}
                                type='text'
                            />
                            <Button
                                disabled={row.partAnswer == 4 || row.diagnosticComplete}
                                icon={<CloseCircleOutlined style={{ color: row.partAnswer == 3 && red }} />}
                                onClick={() => {
                                    this.handleStatusChange(3, row);
                                    this.setState({});
                                }}
                                type='text'
                            />
                            <Button
                                disabled={row.partAnswer == 4 || row.diagnosticComplete}
                                icon={<ExclamationCircleOutlined style={{ color: row.partAnswer == 2 && yellow }} />}
                                onClick={() => {
                                    this.handleStatusChange(2, row);
                                    this.setState({});
                                }}
                                type='text'
                            />
                            <Button
                                disabled={row.diagnosticComplete}
                                icon={<MinusCircleOutlined />}
                                onClick={() => {
                                    this.handleStatusChange(4, row);
                                    this.setState({});
                                }}
                                type='text'
                            />
                        </div>
                    );
                }
            }
        ];

        this.columns = () => [
            {
                title: '№',
                key: 'key',
                dataIndex: 'key',
                width: 'auto',
                render: (data, elem, ind) => {
                    return (
                        <span
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12
                            }}
                        >
                            {elem.codeonimage || ind + 1}
                        </span>
                    );
                }
            },
            {
                key: 'action',
                width: 'auto',
                title: <FormattedMessage id='order_form_table.action' />,
                className: Styles.infoActionButtonCol,
                render: row => {
                    const { dg } = this.getRelatedDiagnostic(row);

                    let title = '';
                    if (row.attributes && row.attributes.length) {
                        row.attributes.map(attr => {
                            title += `${attr.name}: ${attr.value}\n`;
                        });
                    }

                    return dg.partId ? (
                        <Popover
                            content={
                                <div className={Styles.actionBlock}>
                                    <Tooltip title={`${row.storeGroupId} - ${row.laximoGroupIds}`} zIndex={3001}>
                                        <Button
                                            icon={<ApiOutlined />}
                                            style={{
                                                pointerEvents: 'all'
                                            }}
                                            type='text'
                                        />
                                    </Tooltip>
                                    {/* <CommentaryButton
                                        commentary={dg.partComment || {}}
                                        data-qa='btn_comentary_diagnostic_table_order_page'
                                        getCurrentDiagnostic={this.props.modalProps.fetchDiagnosticData}
                                        onOk={partComment => {
                                            dg.partComment = partComment;
                                            this.putDiagnosticData({
                                                ...dg,
                                                partComment
                                            });
                                            this.setState({});
                                        }}
                                        rowProp={dg}
                                    /> */}
                                    {dg.partComment ? (
                                        <Button
                                            className={Styles.commentaryButton}
                                            onClick={() => this.setState({ commentaryRow: dg })}
                                            title={this.props.intl.formatMessage({
                                                id: 'commentary.edit'
                                            })}
                                            type='primary'
                                        >
                                            <FormOutlined className={Styles.commentaryButtonIcon} />
                                        </Button>
                                    ) : (
                                        <Tooltip title={<FormattedMessage id='commentary.add' />} zIndex={3001}>
                                            <Button onClick={() => this.setState({ commentaryRow: dg })}>
                                                <MessageOutlined />
                                            </Button>
                                        </Tooltip>
                                    )}
                                    <PhotoButton
                                        data-qa='btn_photo_diagnostic_table_order_page'
                                        disabled={dg.disabled}
                                        getCurrentDiagnostic={this.props.modalProps.fetchDiagnosticData}
                                        onOk={partPhotosHashes => {
                                            dg.partPhotosHashes = partPhotosHashes;
                                            this.putDiagnosticData({
                                                ...dg,
                                                partPhotosHashes
                                            });
                                            this.setState({});
                                        }}
                                        rowProp={dg}
                                    />
                                </div>
                            }
                            trigger='click'
                            zIndex={3001}
                        >
                            <Button
                                icon={<MenuOutlined />}
                                style={{
                                    pointerEvents: 'all'
                                }}
                                type='text'
                            />
                        </Popover>
                    ) : (
                        <Tooltip title={title} zIndex={3001}>
                            <Button
                                disabled
                                icon={<QuestionCircleOutlined />}
                                onClick={() => this.showInfoModal(row.name, row.attributes, row.codeonimage)}
                                style={{
                                    pointerEvents: 'all'
                                }}
                                type='text'
                            />
                        </Tooltip>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_code' />,
                key: 'oem',
                width: 'auto',
                // onCell: row => ({
                //     onClick: () => this.showInfoModal(row.name, row.attributes, row.codeonimage),
                //     style: { cursor: 'pointer' }
                // }),
                render: row => {
                    const { dg } = this.getRelatedDiagnostic(row);

                    const color = dg.partAnswer
                        ? get(
                              answerDisplay.find(({ answer }) => answer === dg.partAnswer),
                              'color'
                          )
                        : undefined;

                    return (
                        <div style={{ borderBottom: color ? `3px solid ${color}` : 'none', display: 'inline-block' }}>
                            {get(row, 'oem')}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'name',
                dataIndex: 'name',
                onCell: dg => ({
                    style: { cursor: 'pointer' },
                    onClick: () => {
                        this.setState({ commentaryRow: dg });
                    }
                }),
                width: 'auto',
                // filterDropdown: () => {
                //     return (
                //         <PaddedWrapper>
                //             <Select
                //                 allowClear
                //                 filterOption={filterByPart}
                //                 getPopupContainer={trigger => trigger.parentNode}
                //                 mode='multiple'
                //                 onChange={selectValue => this.handleRelatedFiltersChange({ name: selectValue })}
                //                 placeholder={this.props.intl.formatMessage({
                //                     id: 'order_form_table.diagnostic.filters.node.placeholder'
                //                 })}
                //                 style={{ minWidth: 300, maxWidth: 500 }}
                //                 value={this.state.relatedTableFilters.name}
                //             >
                //                 {this.state.relatedFilterParams.relatedNames.map(name => {
                //                     return (
                //                         <Option key={name} value={name}>
                //                             {name}
                //                         </Option>
                //                     );
                //                 })}
                //             </Select>
                //         </PaddedWrapper>
                //     );
                // },
                render: (data, elem) => {
                    return (
                        <div>
                            <p>{data}</p>
                            <p style={{ fontSize: 12, color: 'var(--text2)' }}>
                                {(elem.attributes || []).map(attr => `${attr.name}: ${attr.value}`).join('; ')}
                            </p>
                        </div>
                    );
                }
            },
            {
                key: 'diagnosticName',
                title: <FormattedMessage id='order_form_table.diagnostic.position_title' />,
                width: 'auto',
                render: row => {
                    const { dg } = this.getRelatedDiagnostic(row);

                    return get(dg, 'partName');
                }
            },
            {
                key: 'diagnostic',
                title: <FormattedMessage id='order_form_table.diagnostic.status_title' />,
                render: row => {
                    const {
                        modalProps: { relatedParts, orderId }
                    } = this.props;

                    const { dg, createItem } = this.getRelatedDiagnostic(row);

                    return (
                        <div style={{ display: 'flex' }}>
                            <Button
                                disabled={dg.partAnswer === 4 || dg.diagnosticComplete}
                                icon={<CheckCircleOutlined style={{ color: dg.partAnswer == 1 && green }} />}
                                onClick={async () => {
                                    const ids = await this.putDiagnosticData({
                                        ...dg,
                                        orderId,
                                        createItem,
                                        partOECode: row.oem,
                                        partAnswer: 1
                                    });
                                    if (!createItem) {
                                        dg.partAnswer = dg.partAnswer === 1 ? 0 : 1;
                                        dg.partOECode = row.oem;
                                    } else {
                                        relatedParts.push({
                                            ...dg,
                                            diagnosticId: get(ids, '[0]'),
                                            partAnswer: 1,
                                            partOECode: row.oem
                                        });
                                    }

                                    this.setState({});
                                }}
                                type='text'
                            />
                            <Button
                                disabled={dg.partAnswer === 4 || dg.diagnosticComplete}
                                icon={<CloseCircleOutlined style={{ color: dg.partAnswer == 3 && red }} />}
                                onClick={async () => {
                                    const ids = await this.putDiagnosticData({
                                        ...dg,
                                        orderId,
                                        createItem,
                                        partOECode: row.oem,
                                        partAnswer: 3
                                    });
                                    if (!createItem) {
                                        dg.partAnswer = dg.partAnswer === 3 ? 0 : 3;
                                        dg.partOECode = row.oem;
                                    } else {
                                        relatedParts.push({
                                            ...dg,
                                            diagnosticId: get(ids, '[0]'),
                                            partAnswer: 3,
                                            partOECode: row.oem
                                        });
                                    }

                                    this.setState({});
                                }}
                                type='text'
                            />
                            <Button
                                disabled={dg.partAnswer === 4 || dg.diagnosticComplete}
                                icon={<ExclamationCircleOutlined style={{ color: dg.partAnswer == 2 && yellow }} />}
                                onClick={async () => {
                                    const ids = await this.putDiagnosticData({
                                        ...dg,
                                        orderId,
                                        createItem,
                                        partOECode: row.oem,
                                        partAnswer: 2
                                    });
                                    if (!createItem) {
                                        dg.partAnswer = dg.partAnswer === 2 ? 0 : 2;
                                        dg.partOECode = row.oem;
                                    } else {
                                        relatedParts.push({
                                            ...dg,
                                            diagnosticId: get(ids, '[0]'),
                                            partAnswer: 2,
                                            partOECode: row.oem
                                        });
                                    }

                                    this.setState({});
                                }}
                                type='text'
                            />
                            <Button
                                disabled={dg.diagnosticComplete}
                                icon={<MinusCircleOutlined />}
                                onClick={async () => {
                                    const ids = await this.putDiagnosticData({
                                        ...dg,
                                        orderId,
                                        createItem,
                                        partOECode: row.oem,
                                        partAnswer: 4
                                    });
                                    if (!createItem) {
                                        dg.partAnswer = dg.partAnswer === 4 ? 0 : 4;
                                        dg.partOECode = row.oem;
                                    } else {
                                        relatedParts.push({
                                            ...dg,
                                            diagnosticId: get(ids, '[0]'),
                                            partAnswer: 4,
                                            partOECode: row.oem
                                        });
                                    }

                                    this.setState({});
                                }}
                                type='text'
                            />
                        </div>
                    );
                }
            }
        ];
    }

    getRelatedDiagnostic = row => {
        const {
            modalProps: { relatedParts, orderId }
        } = this.props;

        let createItem = false;
        let dg = (relatedParts || []).find(
            ({ partStoreGroupId, partOECode }) =>
                (partStoreGroupId == row.storeGroupId || partStoreGroupId == 1000000) && partOECode == row.oem
        );
        if (!dg) {
            dg = (relatedParts || []).find(
                ({ partStoreGroupId, partOECode }) => partStoreGroupId == row.storeGroupId && !partOECode
            );
            if (!dg) {
                dg = {
                    ...(relatedParts || []).find(({ partStoreGroupId }) => partStoreGroupId == row.storeGroupId)
                };

                createItem = true;
                if (!dg) {
                    dg = {};
                } else {
                    dg.partAnswer = undefined;
                }
            } else if (dg.partAnswer) {
                dg.partOECode = row.oem;

                this.putDiagnosticData({
                    ...dg,
                    orderId,
                    partOECode: row.oem
                });
                this.setState({});
            }
        }

        return { dg, createItem };
    };

    putDiagnosticData = async ({
        imgId,
        orderId,
        diagnosticId,
        partAnswer,
        partOECode,
        createItem = false,
        partPhotosHashes,
        partComment
    }) => {
        const ids = await fetchAPI(
            'PUT',
            'order_diagnostic',
            null,
            {
                imgId,
                orderId,
                partsData: [
                    {
                        rowId: diagnosticId,
                        answer: partAnswer,
                        oeCode: partOECode,
                        photoHashes: partPhotosHashes,
                        comment: partComment
                    }
                ],
                createItem
            },
            {
                handleErrorInternally: true
            }
        );
        await this.props.modalProps.fetchDiagnosticData();
        this.setState({ loading: false });

        return get(ids, 'update', []);
    };

    deleteDiagnosticData = async ({ templateId, orderId, imgId, groupId, systemId, diagnosticId }) => {
        await fetchAPI(
            'DELETE',
            'order_diagnostic',
            null,
            {
                orderId,
                imgId,
                templateId,
                groupIds: groupId ? [groupId] : undefined,
                rowIds: diagnosticId ? [diagnosticId] : undefined,
                systemIds: systemId ? [systemId] : undefined
            },
            { handleErrorInternally: true }
        );
        const { relatedParts } = this.props.modalProps;
        this.props.modalProps.relatedParts = relatedParts.filter(({ diagnosticId: dgId }) => dgId !== diagnosticId);
        await this.props.modalProps.fetchDiagnosticData();
        this.setState({ loading: false });
    };

    showInfoModal = (name, attributes, code = -1) => {
        this.setState({
            infoItem: {
                name,
                body: attributes.map(attr => (
                    <p key={attr.value}>
                        <b>{attr.name}:</b> {attr.value}
                    </p>
                ))
            },
            infoModalVisible: true
        });
    };

    onImgLoad = ({ target: img }) => {
        if (this.state.imageList.length) {
            this.state.imageList[this.state.imageList.length - 1].height = img.naturalHeight;
            this.state.imageList[this.state.imageList.length - 1].width = img.naturalWidth;
        }
        this.setState({});
    };

    handleStatusChange = (rowStatus, row) => {
        row.partAnswer = row.partAnswer == rowStatus ? 0 : rowStatus;
        this.putDiagnosticData({
            ...row
        });
    };

    handleFiltersChange = filterObj => {
        this.setState(state => ({ ...state, filters: { ...state.filters, ...filterObj } }));
    };

    handleRelatedFiltersChange = filterObj => {
        this.setState(state => ({ ...state, relatedTableFilters: { ...state.relatedTableFilters, ...filterObj } }));
    };

    handleOk = async () => {
        const {
            storeGroupId,
            modalProps: { setVinDetail, setVinDetails, onClose }
        } = this.props;
        const { stateStoreGroupId, checkedCodes } = this.state;

        const itemsInfo = this.state.itemsInfo.length ? this.state.itemsInfo[this.state.itemsInfo.length - 1] : [];

        if (checkedCodes.length === 1) {
            const selectedItem = itemsInfo.find(
                item => item.key === checkedCodes[0].key && item.codeonimage === checkedCodes[0].code
            );
            setVinDetail && setVinDetail({ ...selectedItem, storeGroupId: stateStoreGroupId || storeGroupId });
        } else if (setVinDetails) {
            setVinDetails(
                checkedCodes.map(({ code, key }) => {
                    const selectedItem = itemsInfo.find(item => item.key === key && item.codeonimage === code);

                    return selectedItem;
                })
            );
        }

        this.handleCancel();
    };

    handleCancel = () => {
        const {
            resetModal,
            loadModal,
            modalProps: { modalToLoad, onClose }
        } = this.props;
        this.setState({
            displayType: 'list',
            categoryMode: false,
            categories: [],
            loading: false,
            zoomed: false,
            itemsInfo: [],
            blockPositions: [],
            tableHoverCode: undefined,
            imgHoverCode: undefined,
            imgHoverIndex: undefined,
            checkedCodes: [],
            infoModalVisible: false,
            infoItem: undefined,
            imageList: [],
            image: undefined,
            itemsListEmpty: false,
            zoomMultiplier: 0.75,
            allCategories: [],
            listItemPreview: [],
            previewIndex: undefined,
            variantRadioValue: 0,
            stateStoreGroupId: undefined,
            index: 0,
            detailsWithStoreGroup: undefined,
            createdDgNeededOems: [],
            selectedDiagnosticIndex: undefined
        });
        if (modalToLoad) {
            loadModal(modalToLoad);
        } else {
            if (onClose) onClose();

            resetModal();
        }
    };

    handleBack = () => {
        const {
            modalProps: { storeGroupId: propsStoreGroupId }
        } = this.props;
        const { stateStoreGroupId } = this.state;
        const storeGroupId = propsStoreGroupId || stateStoreGroupId;
        if (this.state.categoryMode && !storeGroupId) {
            this.setState({
                categories: this.state.allCategories,
                categoryMode: true
            });
        } else if (this.state.itemsInfo.length > 1) {
            const itemsInfo = this.state.itemsInfo.pop();
            const blockPositions = this.state.blockPositions.pop();
            const imageList = this.state.imageList.pop();
            this.setState({
                itemsInfo,
                blockPositions,
                imageList,
                checkedCodes: []
            });
        } else {
            this.setState({
                categoryMode: true,
                checkedCodes: []
            });
        }
    };

    fetchData = async (showAll = false) => {
        await this.setState({
            loading: true
        });
        const {
            vin,
            modalProps: { storeGroupId: propsStoreGroupId }
        } = this.props;
        const { stateStoreGroupId } = this.state;
        const storeGroupId = stateStoreGroupId || propsStoreGroupId;

        if (storeGroupId && !showAll) {
            try {
                const data = await fetchAPI(
                    'GET',
                    'vin/list_quick_detail',
                    {
                        vin,
                        storeGroupIds: storeGroupId ? `[${[storeGroupId].flat().filter(e => e)}]` : '',
                        isDiagnostic: true
                    },
                    null,
                    {
                        handleErrorInternally: true
                    }
                );
                if (data) {
                    const { catalog, ssd, vehicleid } = data.vehicle;
                    const categoriesArray = data.categories;
                    const normalizedCategories = [];

                    if (categoriesArray.length) {
                        categoriesArray.map(elem => {
                            if (elem.units.length == 1) {
                                elem.name = elem.units[0].name;
                                normalizedCategories.push({
                                    catalog,
                                    vehicleId: vehicleid,
                                    ssd,
                                    ...elem,
                                    unit: { ...elem.units[0] }
                                });
                            } else {
                                elem.units.map(unit => {
                                    elem.name = unit.name;
                                    normalizedCategories.push({
                                        catalog,
                                        vehicleId: vehicleid,
                                        ssd,
                                        ...elem,
                                        unit
                                    });
                                });
                            }
                        });
                    }

                    for (let i = 0; i < normalizedCategories.length % 3; i++) {
                        normalizedCategories.push({
                            emptyElement: true
                        });
                    }
                    this.setState({
                        loading: false,
                        displayType: 'grid',
                        categoryMode: normalizedCategories.length || true,
                        categories: normalizedCategories
                    });
                } else {
                    this.setState({
                        loading: false,
                        itemsListEmpty: true
                    });
                }
            } catch (e) {
                this.setState({
                    loading: false,
                    itemsListEmpty: true
                });
            }
        } else {
            try {
                const response = await fetchAPI('GET', 'vin/categories', { vin }, null, {
                    handleErrorInternally: true
                });
                const data = get(response, 'data');
                if (data) {
                    const { catalog, ssd, vehicleid } = data.response.FindVehicle.response.FindVehicle[0].row[0].$;
                    const categoriesArray = data.response.ListCategories[0].row;
                    const normalizedCategories = [];

                    if (categoriesArray.length) {
                        categoriesArray.map(elem => {
                            normalizedCategories.push({
                                catalog,
                                vehicleId: vehicleid,
                                ...elem.$,
                                unit: { ...elem.$ }
                            });
                        });
                    }

                    if (this.state.displayType == 'grid') {
                        for (let i = 0; i < normalizedCategories.length % 5; i++) {
                            normalizedCategories.push({
                                emptyElement: true
                            });
                        }
                    }
                    this.setState({
                        loading: false,
                        categoryMode: normalizedCategories.length,
                        categories: normalizedCategories,
                        allCategories: normalizedCategories
                    });

                    if (this.state.displayType == 'list' && normalizedCategories.length) {
                        this.fetchCategoryItemsList(
                            normalizedCategories[0].ssd,
                            normalizedCategories[0].catalog,
                            normalizedCategories[0].categoryid,
                            normalizedCategories[0].vehicleId,
                            true,
                            0
                        );
                    }
                } else {
                    this.setState({
                        loading: false,
                        itemsListEmpty: true
                    });
                }
            } catch (e) {
                this.setState({
                    loading: false,
                    itemsListEmpty: true
                });
            }
        }
        this.setState({ tableLoading: false });
    };

    fetchItemsList = async (ssd, unitId, catalog) => {
        await this.setState({
            loading: true
        });
        try {
            const { modalProps } = this.props;
            const index = {};

            const diagnostics = get(modalProps, 'diagnostics', []);
            const relatedParts = get(modalProps, 'relatedParts', []);

            const response = await fetchAPI('GET', 'vin/list_detail_by_unit_id', {
                ssd,
                unitId,
                catalog
            });
            const data = get(response, 'data');
            if (data) {
                const itemsInfo = [];
                const blockPositions = [];
                const image = get(data.response.GetUnitInfo, '[0].row[0].$');
                get(data.response.ListDetailsByUnit, '[0].row', []).map(({ $: tm, attribute }, key) => {
                    const item = { ...tm };
                    const preset = diagnostics.find(
                        ({ keyWord }) => String(item.name).toLowerCase().includes(String(keyWord).toLowerCase())
                        // eslint-disable-next-line function-paren-newline
                    );
                    const related = (relatedParts || []).find(
                        ({ partOECode }) => String(item.oem) === String(partOECode)
                    );
                    item.preset = preset;
                    item.relatedDiagnostic = related || {};
                    if (related) {
                        index[related.partId] = Number(index[related.partId] || 0) + 1;
                    }

                    if (item.oem) {
                        itemsInfo.push({
                            key,
                            ...item,
                            attributes: attribute && attribute.map(attr => attr.$)
                        });
                    }
                });
                get(data.response.ListImageMapByUnit, '[0].row', []).map(({ $: position }, key) => {
                    blockPositions.push({
                        ...position,
                        key
                    });
                });

                this.setState({
                    index,
                    loading: false,
                    imageList: [image],
                    itemsInfo: [itemsInfo],
                    blockPositions: [blockPositions],
                    categoryMode: false
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (e) {
            this.setState({
                loading: false,
                itemsInfo: [],
                blockPositions: [],
                categoryMode: false
            });
        }
    };

    fetchCategoryItemsList = async (ssd, catalog, categoryId, vehicleid, previewMode = false, index) => {
        if (!previewMode) {
            await this.setState({
                loading: true
            });
        } else {
            await this.setState({
                previewIndex: index
            });
        }
        try {
            const { data } = await fetchAPI('GET', 'vin/list_units', { ssd, vehicleid, catalog, categoryId }, null, {
                handleErrorInternally: true
            });
            if (data) {
                const categoriesArray = data.response.ListUnits[0].row;
                const normalizedCategories = [];
                const { modalProps } = this.props;

                if (categoriesArray.length) {
                    categoriesArray.map(elem => {
                        normalizedCategories.push({
                            catalog,
                            vehicleId: vehicleid,
                            ...elem.$,
                            unit: { ...elem.$ }
                        });
                    });
                }
                if (previewMode) {
                    for (let i = 0; i < normalizedCategories.length % 3; i++) {
                        normalizedCategories.push({
                            emptyElement: true
                        });
                    }
                    this.setState({
                        loading: false,
                        listItemPreview: normalizedCategories
                    });
                } else {
                    for (let i = 0; i < normalizedCategories.length % 5; i++) {
                        normalizedCategories.push({
                            emptyElement: true
                        });
                    }
                    this.setState({
                        loading: false,
                        categoryMode: normalizedCategories.length,
                        categories: normalizedCategories
                    });
                }
            } else {
                this.setState({
                    loading: false,
                    itemsListEmpty: true
                });
            }
        } catch (e) {
            this.setState({
                loading: false,
                itemsInfo: [],
                blockPositions: [],
                categoryMode: true
            });
        }
    };

    searchImage = () => {
        this.setState({
            loading: true
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.modal !== this.props.modal && this.props.modal === MODALS.VIN_DG_MODAL) {
            const { relatedParts } = this.props.modalProps;

            const uniqueParams = {
                uniquePlaceNames: uniqBy(relatedParts, 'placeName'),
                uniquePartNames: uniqBy(relatedParts, 'partName'),
                uniqueSystemNames: uniqBy(relatedParts, 'systemName'),
                uniqueRelatedNames: uniqBy(this.itemsInfo, 'name')
            };
            const { uniquePlaceNames, uniquePartNames, uniqueSystemNames, uniqueRelatedNames } = uniqueParams;

            const filterParams = {
                systemNames: uniqueSystemNames.map(({ systemName }) => systemName),
                placeNames: uniquePlaceNames.map(({ placeName }) => placeName),
                partNames: uniquePartNames.map(({ partName }) => partName)
            };

            const relatedFilterParams = { relatedNames: uniqueRelatedNames.map(({ name }) => name) };

            this.setState({
                loading: true,
                displayType: this.props.isMobile ? 'grid' : 'list',
                zoomMultiplier: this.props.isMobile ? 2 : 0.75,
                filterParams,
                createdDgNeededOems: relatedParts
                    .filter(({ partStoreGroupId }) => partStoreGroupId === 1000000)
                    .map(({ partOECode }) => ({ oem: partOECode }))
            });
            this.fetchData();
        }
    }

    render() {
        const {
            isMobile,
            modal,
            detailsTreeData,
            modalProps: { storeGroupId: propsStoreGroupId, onClose, relatedParts },
            intl: { formatMessage }
        } = this.props;
        const {
            displayType,
            zoomed,
            tableHoverCode,
            imgHoverCode,
            imgHoverIndex,
            checkedCodes,
            infoItem,
            infoModalVisible,
            loading,
            tableLoading,
            categories,
            categoryMode,
            itemsListEmpty,
            zoomMultiplier,
            allCategories,
            listItemPreview,
            previewIndex,
            tableIsFolded,
            stateStoreGroupId,
            detailsWithStoreGroup,
            createdDgNeededOems,
            selectedDiagnosticIndex,
            commentaryRow
        } = this.state;

        const storeGroupId = propsStoreGroupId || stateStoreGroupId;

        const columns = this.columns();

        const tmInf = this.state.itemsInfo.length ? this.state.itemsInfo[this.state.itemsInfo.length - 1] : [];
        const blockPositions = this.state.blockPositions.length
            ? this.state.blockPositions[this.state.blockPositions.length - 1]
            : [];
        const image = this.state.imageList.length ? this.state.imageList[this.state.imageList.length - 1] : undefined;

        const itemsInfo = detailsWithStoreGroup
            ? tmInf
                  .filter(({ oem }) => [...detailsWithStoreGroup, ...createdDgNeededOems].find(det => det.oem === oem))
                  .map(el => {
                      const storeGroupId = get(
                          detailsWithStoreGroup.find(det => det.oem === el.oem),
                          'storeGroupId',
                          1000000
                      );

                      const laximoGroupIds = get(
                          detailsWithStoreGroup.find(det => det.oem === el.oem),
                          'laximoGroupIds',
                          []
                      );

                      return {
                          ...el,
                          storeGroupId,
                          laximoGroupIds
                      };
                  })
            : tmInf;

        const zoomActionBlock = (
            <div className={Styles.zoomActionBlock}>
                {zoomed ? (
                    <ZoomOutOutlined
                        onClick={() => {
                            this.setState({
                                zoomed: !zoomed
                            });
                        }}
                        style={{
                            fontSize: 24,
                            zIndex: 9999
                        }}
                    />
                ) : (
                    <ZoomInOutlined
                        onClick={() => {
                            this.setState({
                                zoomed: !zoomed
                            });
                        }}
                        style={{
                            fontSize: 24,
                            zIndex: 9999
                        }}
                    />
                )}
                <MinusOutlined
                    onClick={() => {
                        const res = zoomMultiplier - 0.1;
                        this.setState({ zoomMultiplier: res < 0.2 ? 0.2 : res });
                    }}
                    style={{
                        marginLeft: 15
                    }}
                />
                <Slider
                    dots
                    max={2.5}
                    min={0.2}
                    onChange={value => {
                        this.setState({ zoomMultiplier: value });
                    }}
                    step={0.1}
                    style={{
                        minWidth: 200,
                        margin: '0px 14px'
                    }}
                    value={zoomMultiplier}
                />
                <PlusOutlined
                    onClick={() => {
                        const res = zoomMultiplier + 0.1;
                        this.setState({ zoomMultiplier: res > 2.5 ? 2.5 : res });
                    }}
                />
            </div>
        );

        return (
            <Modal
                footer={
                    <Button
                        key='submit'
                        disabled={checkedCodes.length === 0 && !onClose}
                        onClick={() => {
                            if (!categoryMode) {
                                this.handleBack();

                                return;
                            }
                            this.handleOk();
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='ok' />
                    </Button>
                }
                maskClosable={false}
                onCancel={() => {
                    if (!categoryMode) {
                        this.handleBack();

                        return;
                    }

                    this.handleCancel();
                }}
                onOk={this.handleOk}
                open={modal === MODALS.VIN_DG_MODAL}
                style={{
                    maxWidth: '98%',
                    minWidth: displayType === 'list' ? '85%' : '70%'
                }}
                title={<FormattedMessage id='add_order_form.vin' />}
                width='fit-content'
                zIndex={3000}
            >
                {!loading &&
                    !itemsListEmpty &&
                    categoryMode &&
                    !stateStoreGroupId &&
                    allCategories &&
                    allCategories == categories && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                margin: '-16px 0 8px 0'
                            }}
                        >
                            <div style={{ fontSize: 18 }}>
                                {displayType === 'list' && categories.length > previewIndex
                                    ? categories[previewIndex].name
                                    : null}
                            </div>
                            {!isMobile && (
                                <React.Fragment>
                                    <div>
                                        <TreeSelect
                                            allowClear
                                            data-qa='tree_select_storeGroupId_order_detail_modal'
                                            dropdownMatchSelectWidth={320}
                                            filterTreeNode={filterTreeNodeByPart}
                                            getPopupContainer={trigger => trigger.parentNode}
                                            onChange={async stateStoreGroupId => {
                                                await this.setState({ stateStoreGroupId });
                                                this.fetchData();
                                            }}
                                            placeholder={formatMessage({
                                                id: 'order_form_table.store_group'
                                            })}
                                            showSearch
                                            style={{
                                                minWidth: 320
                                            }}
                                            treeData={detailsTreeData}
                                            value={stateStoreGroupId}
                                        />
                                    </div>
                                    <Radio.Group
                                        buttonStyle='solid'
                                        onChange={event => {
                                            this.setState({
                                                displayType: event.target.value
                                            });
                                        }}
                                        value={displayType}
                                    >
                                        <Radio.Button data-qa='button_view_unordered_list_vin_code_modal' value='list'>
                                            <UnorderedListOutlined
                                                style={{
                                                    fontSize: 18,
                                                    verticalAlign: 'middle'
                                                }}
                                            />
                                        </Radio.Button>
                                        <Radio.Button data-qa='button_view_grid_vin_code_modal' value='grid'>
                                            <GridIcon
                                                style={{
                                                    fontSize: 18,
                                                    verticalAlign: 'middle'
                                                }}
                                            />
                                        </Radio.Button>
                                    </Radio.Group>
                                </React.Fragment>
                            )}
                        </div>
                    )}
                {!loading &&
                    !itemsListEmpty &&
                    (!categoryMode || (!storeGroupId && allCategories && allCategories != categories)) && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: isMobile ? 'space-between' : 'flex-end',
                                margin: '-16px 0 8px 0'
                            }}
                        >
                            {isMobile && (
                                <Button
                                    className={Styles.foldBtn}
                                    icon={
                                        <LegacyIcon
                                            type={tableIsFolded ? 'vertical-align-bottom' : 'vertical-align-top'}
                                        />
                                    }
                                    onClick={() => {
                                        this.setState({
                                            tableIsFolded: !tableIsFolded,
                                            zoomMultiplier: !tableIsFolded ? 2 : zoomMultiplier
                                        });
                                    }}
                                    type={tableIsFolded ? 'primary' : 'default'}
                                />
                            )}
                        </div>
                    )}
                {loading && !tableLoading ? (
                    <Spin indicator={spinIcon} />
                ) : categoryMode && !itemsListEmpty ? (
                    <div className={`${Styles.diagnosticModeCategoryWrap}`}>
                        {
                            <div className={Styles.tableAndHeader}>
                                <h2 className={Styles.tableTitle}>
                                    {relatedParts[0].systemName}, {relatedParts[0].placeName}
                                </h2>
                                <Table
                                    bordered
                                    className={Styles.diagnosticTable}
                                    columns={this.dgColumns()}
                                    dataSource={classFilterDataSource(this.state.filters, relatedParts)}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    loading={tableLoading}
                                    // onRow={(record, index) => {
                                    //     return {
                                    //         onDoubleClick: async () => {
                                    //             await this.setState({
                                    //                 selectedDiagnosticIndex:
                                    //                     selectedDiagnosticIndex === index ? undefined : index,
                                    //                 stateStoreGroupId:
                                    //                     selectedDiagnosticIndex === index
                                    //                         ? undefined
                                    //                         : record.partStoreGroupId
                                    //             });
                                    //             this.setState({ tableLoading: true });
                                    //             this.fetchData();
                                    //         }
                                    //     };
                                    // }}
                                    pagination={false}
                                    rowClassName={(row, index) =>
                                        `${
                                            (row.partAnswer === 4 || row.diagnosticComplete) &&
                                            Styles.diagnosticTableDisabledRow
                                        } ${index === selectedDiagnosticIndex && Styles.diagnosticTableBoldRow} ${
                                            Styles.diagnosticTableRow
                                        }`
                                    }
                                    size='small'
                                />
                            </div>
                        }
                        <div className={`${Styles.categoryList} ${Styles.diagnosticModeCategoryList}`}>
                            {displayType == 'grid' ? (
                                categories.length ? (
                                    categories.map((category, key) => {
                                        return category.emptyElement ? (
                                            <div
                                                key={`categories-${key}-emptyElement`}
                                                className={Styles.emptyItem}
                                                style={{ pointerEvents: 'none' }}
                                            />
                                        ) : (
                                            <div
                                                key={`categories-${key}-${category.unit.unitid}`}
                                                className={Styles.categoryItem}
                                                onClick={() => {
                                                    this.setState({
                                                        detailsWithStoreGroup: _.get(category, 'unit.detail', [])
                                                    });
                                                    if (category.unit.imageurl) {
                                                        this.fetchItemsList(
                                                            category.unit.ssd,
                                                            category.unit.unitid,
                                                            category.catalog
                                                        );
                                                    } else {
                                                        this.fetchCategoryItemsList(
                                                            category.ssd,
                                                            category.catalog,
                                                            category.categoryid,
                                                            category.vehicleId
                                                        );
                                                    }
                                                }}
                                            >
                                                {category.unit.imageurl && (
                                                    <img
                                                        alt={category.unit.name}
                                                        src={category.unit.imageurl.replace('%size%', 'source')}
                                                        style={{
                                                            cursor: 'pointer',
                                                            width: '100%'
                                                        }}
                                                        title={category.unit.name}
                                                    />
                                                )}
                                                <div className={Styles.categoryName}>{category.name}</div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div>Відсутні дані</div>
                                )
                            ) : (
                                <React.Fragment>
                                    <div className={Styles.allCategoriesList}>
                                        {categories.map((category, key) => {
                                            return category.emptyElement ? (
                                                <div
                                                    key={`categories-${key}-emptyElement`}
                                                    className={Styles.emptyItem}
                                                    style={{ pointerEvents: 'none' }}
                                                />
                                            ) : (
                                                <div
                                                    key={`categories-${key}-${category.categoryid}`}
                                                    className={Styles.categoryListItem}
                                                    onClick={() => {
                                                        this.fetchCategoryItemsList(
                                                            category.ssd,
                                                            category.catalog,
                                                            category.categoryid,
                                                            category.vehicleId,
                                                            true,
                                                            key
                                                        );
                                                    }}
                                                    style={
                                                        previewIndex == key
                                                            ? {
                                                                  backgroundColor: 'var(--db_reserve)'
                                                              }
                                                            : {}
                                                    }
                                                >
                                                    <div className={Styles.categoryListName}>{category.name}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={Styles.previewBLock}>
                                        {listItemPreview.length == 0 ? (
                                            <FormattedMessage id='no_data' />
                                        ) : (
                                            listItemPreview.map((item, key) => {
                                                return item.emptyElement ? (
                                                    <div
                                                        key={`listItemPreview-${key}-emptyElement`}
                                                        className={Styles.emptyItem}
                                                        style={{ pointerEvents: 'none', width: '33%' }}
                                                    />
                                                ) : (
                                                    <div
                                                        key={`listItemPreview-${key}-${item.unit.unitid}`}
                                                        className={Styles.categoryItem}
                                                        onClick={() => {
                                                            this.fetchItemsList(
                                                                item.unit.ssd,
                                                                item.unit.unitid,
                                                                item.catalog
                                                            );
                                                        }}
                                                        style={{
                                                            width: '33%',
                                                            height: 'fit-content'
                                                        }}
                                                    >
                                                        <img
                                                            alt='img'
                                                            src={item.unit.imageurl.replace('%size%', 'source')}
                                                            style={{
                                                                cursor: 'pointer',
                                                                width: '100%'
                                                            }}
                                                            title={item.unit.name}
                                                        />
                                                        <div className={Styles.categoryName}>{item.name}</div>
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                ) : !itemsListEmpty ? (
                    <React.Fragment>
                        <div className={Styles.categoryTitle}>{image && image.name}</div>
                        <div className={Styles.vinModal}>
                            <div className={Styles.imgWrap}>
                                {!isMobile && zoomActionBlock}
                                <div
                                    className={Styles.zoomBlock}
                                    style={{
                                        width: `${100 * zoomMultiplier}%`
                                    }}
                                >
                                    {blockPositions.map((item, ind) => {
                                        const { code } = item;
                                        const mainItem = itemsInfo.find(elem => elem.codeonimage === code);
                                        const resItem = tmInf.find(elem => elem.codeonimage === code);
                                        const oem = get(mainItem, 'oem');
                                        const storeGroupId = get(mainItem, 'storeGroupId');
                                        if (!resItem) {
                                            return null;
                                        }

                                        const key = get(mainItem, 'key');
                                        const title = mainItem ? mainItem.name : '';
                                        const isHovered =
                                            imgHoverCode === code || (imgHoverIndex && imgHoverIndex === key);
                                        const isChecked =
                                            checkedCodes.findIndex(
                                                ({ code: cd, key: k }) => cd === code && k === key
                                            ) >= 0;

                                        const {
                                            modalProps: { relatedParts, orderId }
                                        } = this.props;
                                        const { dg: part, createItem } = this.getRelatedDiagnostic({
                                            storeGroupId,
                                            oem
                                        });

                                        const partAnswer = get(part, 'partAnswer');

                                        return (
                                            <React.Fragment>
                                                <Popover
                                                    content={
                                                        <div>
                                                            {answerDisplay.map(({ answer, icon, color }) => {
                                                                const Icon = icon;
                                                                const style = {
                                                                    ...(answer == partAnswer ? { color } : {})
                                                                };
                                                                const numberedAnswer = Number(answer);

                                                                return (
                                                                    <Button
                                                                        className={Styles.statusButtonSize}
                                                                        icon={<Icon style={style} />}
                                                                        onClick={async () => {
                                                                            if (mainItem) {
                                                                                const ids =
                                                                                    await this.putDiagnosticData({
                                                                                        ...part,
                                                                                        orderId,
                                                                                        createItem,
                                                                                        partOECode: oem,
                                                                                        partAnswer: numberedAnswer
                                                                                    });
                                                                                if (!createItem) {
                                                                                    part.partAnswer =
                                                                                        part.partAnswer ==
                                                                                        numberedAnswer
                                                                                            ? 0
                                                                                            : numberedAnswer;
                                                                                    part.partOECode = oem;
                                                                                } else {
                                                                                    relatedParts.push({
                                                                                        ...part,
                                                                                        diagnosticId: get(ids, '[0]'),
                                                                                        partAnswer: numberedAnswer,
                                                                                        partOECode: oem
                                                                                    });
                                                                                }
                                                                            } else {
                                                                                const part = get(
                                                                                    relatedParts,
                                                                                    '[0]',
                                                                                    {}
                                                                                );
                                                                                const diagnostic = {
                                                                                    templateId: part.templateId,
                                                                                    groupId: part.groupId,
                                                                                    schemeId: part.schemeId,
                                                                                    systemId: part.systemId,
                                                                                    coordinateSchemeId:
                                                                                        part.coordinateSchemeId,
                                                                                    partName: resItem.name,
                                                                                    partOECode: resItem.oem,
                                                                                    partAnswer: String(numberedAnswer),
                                                                                    partStoreGroupId: 1000000,
                                                                                    partLaborId: 1101
                                                                                };

                                                                                const ids = await fetchAPI(
                                                                                    'POST',
                                                                                    'order_missing_diagnostic_parts',
                                                                                    null,
                                                                                    {
                                                                                        orderId,
                                                                                        rows: [diagnostic]
                                                                                    },
                                                                                    {
                                                                                        handleErrorInternally: true
                                                                                    }
                                                                                );

                                                                                relatedParts.push({
                                                                                    ...part,
                                                                                    ...diagnostic,
                                                                                    diagnosticId: get(ids, '[0]')
                                                                                });

                                                                                createdDgNeededOems.push(resItem);
                                                                            }
                                                                            this.setState({});
                                                                        }}
                                                                        type='text'
                                                                    />
                                                                );
                                                            })}
                                                        </div>
                                                    }
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                >
                                                    <div
                                                        key={`blockPositions-${key}-${code}-${ind}`}
                                                        ref={isChecked && this.clickedBlockRef}
                                                        className={`${Styles.zoomBlockItem} ${
                                                            isHovered && Styles.hoveredItem
                                                        } ${isChecked && Styles.checkedItem} ${
                                                            !key && Styles.noKeyItem
                                                        }`}
                                                        onClick={async event => {
                                                            if (!isChecked) {
                                                                checkedCodes.push({ code, key });
                                                                this.setState({});
                                                            } else {
                                                                this.setState({
                                                                    checkedCodes: checkedCodes.filter(
                                                                        ({ code: cd }) => cd !== code
                                                                    )
                                                                });
                                                            }
                                                        }}
                                                        onMouseEnter={event => {
                                                            this.setState({
                                                                tableHoverCode: code,
                                                                imgHoverIndex: key
                                                            });
                                                        }}
                                                        onMouseLeave={event => {
                                                            this.setState({
                                                                tableHoverCode: undefined,
                                                                imgHoverIndex: undefined
                                                            });
                                                        }}
                                                        style={{
                                                            left: `${(item.x1 / image.width) * 100}%`,
                                                            top: `${(item.y1 / image.height) * 100}%`,
                                                            width: `${((item.x2 - item.x1) / image.width) * 100}%`,
                                                            height: `${((item.y2 - item.y1) / image.height) * 100}%`,
                                                            ...(partAnswer
                                                                ? {
                                                                      borderColor: get(
                                                                          answerDisplay.find(
                                                                              ({ answer }) => answer == partAnswer
                                                                          ),
                                                                          'color'
                                                                      ),
                                                                      background: get(
                                                                          answerDisplay.find(
                                                                              ({ answer }) => answer == partAnswer
                                                                          ),
                                                                          'color'
                                                                      )
                                                                  }
                                                                : {})
                                                        }}
                                                        title={title}
                                                    />
                                                </Popover>
                                            </React.Fragment>
                                        );
                                    })}
                                    <img
                                        alt='vin'
                                        onLoad={this.onImgLoad}
                                        src={`${image && image.imageurl.replace('%size%', 'source')}`}
                                        width='100%'
                                    />
                                    <Modal
                                        footer={[]}
                                        maskClosable={false}
                                        onCancel={() => {
                                            this.setState({
                                                zoomed: false
                                            });
                                        }}
                                        open={zoomed}
                                        title={image && image.name.toUpperCase()}
                                        width='85%'
                                    >
                                        <img
                                            alt='vin'
                                            src={`${image && image.imageurl.replace('%size%', 'source')}`}
                                            width='100%'
                                        />
                                    </Modal>
                                </div>
                            </div>
                            {isMobile && zoomActionBlock}
                            <div className={`${Styles.listWrap} ${tableIsFolded && 'folded'}`}>
                                <Table
                                    bordered
                                    columns={columns}
                                    dataSource={classFilterDataSource(this.state.relatedTableFilters, itemsInfo)}
                                    loading={loading}
                                    onRow={({ codeonimage: code, key }, rowIndex) => {
                                        return {
                                            onMouseEnter: event => {
                                                this.setState({
                                                    imgHoverCode: code
                                                });
                                            },
                                            onMouseLeave: event => {
                                                this.setState({
                                                    imgHoverCode: undefined
                                                });
                                            }
                                        };
                                    }}
                                    pagination={false}
                                    rowClassName={(record, rowIndex) => {
                                        const code = record.codeonimage;
                                        const isHovered = tableHoverCode === code;
                                        const isChecked =
                                            checkedCodes.findIndex(
                                                ({ code: cd, key: k }) => cd === code && k === record.key
                                            ) >= 0 || Boolean(record.preset);

                                        return `${Styles.listTableRow} ${isHovered && Styles.tableRowHovered} ${
                                            isChecked && Styles.checkedRow
                                        } ${
                                            record.relatedDiagnostic &&
                                            Styles[`tableRowStatus${Number(record.relatedDiagnostic.status)}`]
                                        } ${record.partAnswer === 4 && Styles.diagnosticTableDisabledRow}`;
                                    }}
                                    size='small'
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'none',
                                textAlign: 'end',
                                fontSize: 12,
                                color: 'var(--text2)'
                            }}
                        >
                            <i style={{ color: 'var(--required)' }}>* </i>Ctrl + click to select multiple item
                        </div>
                    </React.Fragment>
                ) : (
                    <FormattedMessage id='no_data' />
                )}
                <Modal
                    footer={[]}
                    maskClosable={false}
                    onCancel={() => {
                        this.setState({
                            infoModalVisible: false
                        });
                    }}
                    open={infoModalVisible}
                    title={infoItem && infoItem.name.toUpperCase()}
                    zIndex={3001}
                >
                    {infoItem && infoItem.body}
                </Modal>
                <CommentaryButton
                    data-qa='btn_comentary_diagnostic_table_order_page'
                    getCurrentDiagnostic={this.props.modalProps.fetchDiagnosticData}
                    handleClose={() => this.setState({ commentaryRow: undefined })}
                    onOk={partComment => {
                        commentaryRow.partComment = partComment;
                        this.putDiagnosticData({
                            ...commentaryRow,
                            partComment
                        });
                        this.setState({});
                    }}
                    rowProp={commentaryRow}
                />
            </Modal>
        );
    }
}

export default VinDiagnosticModal;
