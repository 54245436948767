import { Button, Modal, Select, notification } from 'antd';
import { DateRangePicker } from 'components';
import { selectBrands } from 'core/brands/duck';
import { fetchSuppliers } from 'core/suppliers/duck';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from '../styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    brands: selectBrands(state),
    user: state.auth,
    warehouses: state.warehouses.warehouses,
    suppliers: state.suppliers.suppliers,
    clients: state.clients.clients
});

const mapDispatchToProps = {
    fetchSuppliers
};
const requiredField = () => <b style={{ color: 'var(--required)' }}> *</b>;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class AddSalaryDocModalCalculated extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    componentDidUpdate(prevProps) {
        const { calculatedSalary, onlyForCurrentEmployee, empId, startD, endD } = this.props;
        const { employeeId } = this.state;
        if (!prevProps.visible && this.props.visible) {
            if (onlyForCurrentEmployee) {
                this.setState({
                    employeeIds: [Number(empId)],
                    startDate: dayjs(startD),
                    endDate: dayjs(endD)
                });
            }
        }
    }

    addSalaryRow = async () => {
        const { employeeIds, startDate, endDate } = this.state;
        const employees = this.props.allEmployees
            .filter(({ disabled }) => !disabled)
            .map(({ id }) => id)
            .flat();

        try {
            const response = await fetchAPI(
                'POST',
                'add/salary/docs',
                null,
                {
                    employeeIds: employeeIds || employees,
                    startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : undefined,
                    endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
                    documentId: this.props.onlyForCurrentEmployee ? Number(this.props.id) : undefined
                },
                { handleErrorInternally: true }
            );
            if (!get(response.insertedRows, 'length')) {
                notification.warning({
                    message: this.props.intl.formatMessage({ id: 'employee_salary_rules.already_calculated' })
                });
            } else {
                notification.success({
                    message: this.props.intl.formatMessage({ id: 'barcode.success' })
                });
            }
        } catch (err) {
            notification.error({
                message: this.props.intl.formatMessage({ id: 'employee_salary_rules.docs_already_created' })
            });
            this.handleCancel();
        }
    };

    handleOk = async () => {
        this.setState({
            loading: true
        });
        await this.addSalaryRow();
        this.handleCancel();
        if (this.props.fetchSalariesList) {
            this.props.fetchSalariesList();
        }
        if (this.props.fetchSalaryDocData) {
            this.props.fetchSalaryDocData();
        }
    };

    handleCancel = () => {
        const { hideModal } = this.props;
        this.setState({
            employeeIds: undefined,
            documentId: undefined,
            startDate: undefined,
            endDate: undefined,
            loading: false
        });
        hideModal();
    };

    render() {
        const { visible, allEmployees, onlyForCurrentEmployee, empId, handleSearchEmployee } = this.props;
        const { employeeIds, startDate, endDate, loading } = this.state;

        return (
            <Modal
                footer={
                    <div>
                        <Button
                            key='back'
                            onClick={() => {
                                this.handleCancel();
                            }}
                        >
                            <FormattedMessage id='stock_table.button.cancel' />
                        </Button>
                        <Button
                            key='submit'
                            disabled={!startDate || !endDate}
                            loading={loading}
                            onClick={async () => {
                                await this.handleOk();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='ok' />
                        </Button>
                    </div>
                }
                okButtonProps={{
                    disabled: !employeeIds || !startDate || !endDate
                }}
                onCancel={this.handleCancel}
                title={<FormattedMessage id='employee_salary_rules.period_calculated' />}
                visible={visible}
                width='30%'
            >
                <div className={Styles.blockStyle}>
                    <div>
                        <div className={Styles.rowStyles}>
                            <FormattedMessage id='cash-order-form.counterparty.EMPLOYEE' />
                            {!onlyForCurrentEmployee ? (
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    mode='multiple'
                                    onChange={value => {
                                        this.setState({
                                            employeeIds: value
                                        });
                                    }}
                                    onSearch={input => handleSearchEmployee(input)}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: '100%' }}
                                    value={employeeIds}
                                    optionFilterProp='children'
                                    // mode='multiple'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'search'
                                    })}
                                >
                                    {allEmployees
                                        .filter(({ disabled }) => !disabled)
                                        .map(({ id, name, surname }) => (
                                            <Option key={id} value={id}>
                                                {`${name} ${surname}`}
                                            </Option>
                                        ))}
                                </Select>
                            ) : (
                                <Select
                                    allowClear
                                    disabled
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        this.setState({
                                            employeeIds: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    // mode='multiple'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'search'
                                    })}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: '100%' }}
                                    value={employeeIds}
                                >
                                    {allEmployees
                                        .filter(({ id }) => id === Number(empId))
                                        .map(({ id, name, surname }) => (
                                            <Option key={id} value={id}>
                                                {`${name} ${surname}`}
                                            </Option>
                                        ))}
                                </Select>
                            )}
                        </div>
                        <div className={Styles.rowStyles}>
                            <FormattedMessage id='setting-salary.period' />
                            {requiredField()}
                            <DateRangePicker
                                allowClear
                                dateRange={[
                                    startDate ? dayjs(startDate) : undefined,
                                    endDate ? dayjs(endDate) : undefined
                                ]}
                                onDateChange={async dateRange => {
                                    await this.setState({
                                        startDate: dayjs(dateRange[0]).format('YYYY-MM-DD'),
                                        endDate: dayjs(dateRange[1]).format('YYYY-MM-DD')
                                    });
                                }}
                                popupStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                style={{ width: '100%' }}
                            />
                            {/* <FormattedMessage id='employee_salary_rules.start_period' />
                            {requiredField()}
                            <DatePicker
                                allowClear
                                format='YYYY-MM-DD '
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setState({
                                        startDate: value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'select_date'
                                })}
                                style={{
                                    width: '100%',
                                    marginTop: 4
                                }}
                                value={startDate}
                            /> */}
                        </div>
                        {/* <div className={Styles.rowStyles}>
                            <FormattedMessage id='employee_salary_rules.end_period' />
                            {requiredField()}
                            <DatePicker
                                allowClear
                                format='YYYY-MM-DD '
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setState({
                                        endDate: value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'select_date'
                                })}
                                style={{
                                    width: '100%',
                                    marginTop: 4
                                }}
                                value={endDate}
                            />
                        </div> */}
                    </div>
                </div>
            </Modal>
        );
    }
}
