import { CheckCircleFilled, CloseSquareFilled, WarningFilled } from '@ant-design/icons';
import { Radio, Select, Space, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import DebouncedInput from 'pages/AccountPlanPage/components/DebouncedInput';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, filterByPartGeneral } from 'utils';
import AccessSettingModal from './AccessSettingModal';
import Styles from './styles.m.css';

const fontOptions = {
    1: 'bold',
    2: 'bold',
    4: 'italic'
};

const { Option } = Select;

const radioOptions = Array.from(Array(4)).map((_, index) => index + 1);

const allRolesAcees = [
    {
        name: <FormattedMessage id='packages.role.NA' />,
        value: 'NA',
        color: '#eb8c86',
        colorValue: 'red'
    },
    {
        name: <FormattedMessage id='packages.role.AD' />,
        value: 'AD',
        color: '#eb8c86',
        colorValue: 'red'
    },
    {
        name: <FormattedMessage id='packages.role.RO' />,
        value: 'RO',
        color: '#f0a871',
        colorValue: 'orange'
    },
    {
        name: <FormattedMessage id='packages.role.RA' />,
        value: 'RA',
        color: '#f0a871',
        colorValue: 'orange'
    },
    {
        name: <FormattedMessage id='packages.role.ROWO' />,
        value: 'ROWO',
        color: '#83c468',
        colorValue: 'green'
    },
    {
        name: <FormattedMessage id='packages.role.RAWO' />,
        value: 'RAWO',
        color: '#83c468',
        colorValue: 'green'
    },
    {
        name: <FormattedMessage id='packages.role.WA' />,
        value: 'WA',
        color: '#83c468',
        colorValue: 'green'
    }
];

const DefaultRolesTab = ({ intl, allRoles }) => {
    const [query, setQuery] = useState({
        dateFrom: dayjs().startOf('month').toISOString(),
        dateTo: dayjs().toISOString()
    });
    const [defaultRoles, setDefaultRoles] = useState([]);
    const [flattenRoles, setFlattenRoles] = useState([]);
    const [tablePagination, setTablePagination] = useState({ pageSize: 2 });
    const [foundIds, setFoundIds] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [visibleLevel, setVisibleLevel] = useState(3);
    const [filter, setFilter] = useState('');
    const [edit, setEdit] = useState({});
    const [open, setModalOpen] = useState(false);

    const onFlatten = useCallback(rights => {
        const flattenTree = [];
        const flattenStoreGroups = data => {
            for (let item = 0; item < get(data, 'length'); item++) {
                const node = data[item];

                flattenTree.push({
                    id: node.id,
                    name: node.name,
                    level: node.level,
                    parentId: node.parentId
                });
                if (node.childs) {
                    flattenStoreGroups(node.childs);
                }
            }
        };
        flattenStoreGroups(rights);

        setFlattenRoles(flattenTree);
        setExpandedRowKeys(flattenTree.map(({ id }) => id));

        return flattenTree;
    }, []);

    const fetchRoles = useMemo(
        () => async query => {
            const res = await fetchAPI('GET', '/v2/roles_and_accesses/standard_roles', query, null, {
                handleErrorInternally: true
            });

            setDefaultRoles(res.list);

            onFlatten(res.list);
        },
        []
    );

    const handleTableRole = useMemo(
        () => async (roleId, access, treeIds) => {
            await fetchAPI(
                'PUT',
                '/v2/roles_and_accesses/standard_roles/access',
                null,
                {
                    roleId,
                    access,
                    treeIds
                },
                {
                    handleErrorInternally: true
                }
            );
            await fetchRoles();
        },
        []
    );

    const handleLevelVisibility = useCallback(
        e => {
            setExpandedRowKeys(flattenRoles.filter(({ level }) => level < e.target.value).map(({ id }) => id));
            setVisibleLevel(Number(e.target.value));
        },
        [flattenRoles]
    );

    const findIcon = useCallback(isInclude => {
        const icon =
            get(isInclude, 'access', null) == 'AD' || get(isInclude, 'access', null) == 'NA' ? (
                <CloseSquareFilled className={Styles.optionsDangerIconStyle} />
            ) : get(isInclude, 'access', null) == 'RO' || get(isInclude, 'access', null) == 'RA' ? (
                <WarningFilled className={Styles.optionsWarningIconStyle} />
            ) : get(isInclude, 'access', null) == 'ROWO' ||
              get(isInclude, 'access', null) == 'RAWO' ||
              get(isInclude, 'access', null) == 'WA' ? (
                <CheckCircleFilled className={Styles.optionsSuccessIconStyle} />
            ) : undefined;

        return icon;
    }, []);

    const findPostId = useCallback((allRoles, postName) => {
        const response = allRoles ? allRoles.find(({ name }) => name === postName) : undefined;

        return response ? response.id : undefined;
    }, []);

    const debouncedFilterChange = useMemo(
        () => filter => {
            const foundMap = {};
            const found = flattenRoles.filter(
                entry => filterByPartGeneral(filter, entry.name) || filterByPartGeneral(filter, entry.id)
            );

            const findParents = entry => {
                if (!foundMap[entry.id]) {
                    foundMap[entry.id] = entry;
                    const parent = flattenRoles.find(genEntry => genEntry.id === entry.parentId);
                    if (parent) findParents(parent);
                }
            };

            found.forEach(foundEntry => {
                findParents(foundEntry);
            });

            const res = Object.values(foundMap);

            if (filter.length > 0) {
                const maxLevel = Math.max(...found.map(({ level }) => level));
                setExpandedRowKeys(res.filter(({ level }) => level < maxLevel).map(({ id }) => id));
                setFoundIds(found.map(({ id }) => id));
            } else {
                setFoundIds([]);
                setExpandedRowKeys(flattenRoles.filter(({ level }) => level < visibleLevel).map(({ id }) => id));
            }

            const rows = range => {
                const diff = range[1] - range[0];
                if (diff === 0) return `${intl.formatMessage({ id: 'storage_journal.row_amount' })}: 0`;

                return `${intl.formatMessage({ id: 'storage_journal.row_amount' })}: ${diff + 1}`;
            };

            if (found.length < 30) {
                setTablePagination({ showTotal: (total, range) => rows(range), pageSize: 9999 });
            } else {
                visibleLevel === 1
                    ? setTablePagination({
                          pageSize: 10,
                          showTotal: (total, range) => rows(range)
                      })
                    : setTablePagination({ pageSize: 2, showTotal: (total, range) => rows(range) });
            }
        },
        [flattenRoles, intl, visibleLevel]
    );

    const columns = useMemo(
        () => [
            {
                title: <FormattedMessage id='packages.id_access' />,
                key: 'id',
                width: '180px',
                fixed: 'left',
                render: record => {
                    const slicedId =
                        record.id && record.level == 1
                            ? String(record.id).slice(0, 2)
                            : record.id && record.level == 2
                            ? String(record.id).slice(0, 4)
                            : record.id && record.level == 3
                            ? String(record.id).slice(0, 6)
                            : String(record.id).slice(0, 8);

                    return record.id ? slicedId : record.id;
                },
                onCell: () => ({ style: { backgroundColor: 'white' } })
            },
            {
                title: <FormattedMessage id='description' />,
                key: 'name',
                width: '200px',
                fixed: 'left',
                render: record => {
                    const space =
                        record.level === 2 ? '8px' : record.level === 3 ? '24px' : record.level === 4 ? '38px' : 0;

                    return record.name ? (
                        <div
                            style={{
                                marginLeft: space
                            }}
                        >
                            {record.name}
                        </div>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                },
                onCell: () => ({ style: { backgroundColor: 'white' } })
            },
            {
                title: <FormattedMessage id='packages.head' />,
                key: 'head',
                width: '125px',
                align: 'center',
                render: row => {
                    const isInclude = row.roles ? row.roles.find(({ name }) => name === 'Керівник') : undefined;
                    const postId = findPostId(allRoles, 'Керівник');

                    const icon = findIcon(isInclude);

                    return (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        roleId: get(isInclude, 'roleId', postId),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon}{' '}
                                {isInclude ? (
                                    get(isInclude, 'access', null)
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )}
                            </div>
                        </Tooltip>
                    );
                }
            },
            {
                title: <FormattedMessage id='packages.admin' />,
                key: 'admin',
                width: '125px',
                align: 'center',
                render: row => {
                    const isInclude = row.roles ? row.roles.find(({ name }) => name === 'Адмін') : undefined;
                    const postId = findPostId(allRoles, 'Адмін');

                    const icon = findIcon(isInclude);

                    return (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        roleId: get(isInclude, 'roleId', postId),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon}{' '}
                                {isInclude ? (
                                    get(isInclude, 'access', null)
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )}
                            </div>
                        </Tooltip>
                    );
                }
            },
            {
                title: <FormattedMessage id='packages.senior_master_receiver' />,
                key: 'senior',
                width: '125px',
                align: 'center',
                render: row => {
                    const isInclude = row.roles ? row.roles.find(({ name }) => name === 'Senior МП') : undefined;
                    const postId = findPostId(allRoles, 'Senior МП');
                    const icon = findIcon(isInclude);

                    return (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        roleId: get(isInclude, 'roleId', postId),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon}{' '}
                                {isInclude ? (
                                    get(isInclude, 'access', null)
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )}
                            </div>
                        </Tooltip>
                    );
                }
            },
            {
                title: <FormattedMessage id='packages.master_receiver_sp_specialist' />,
                key: 'mr_sp_specialist',
                width: '125px',
                align: 'center',
                render: row => {
                    const isInclude = row.roles ? row.roles.find(({ name }) => name === 'МП-Зап.') : undefined;
                    const postId = findPostId(allRoles, 'МП-Зап.');
                    const icon = findIcon(isInclude);

                    return (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        roleId: get(isInclude, 'roleId', postId),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon}{' '}
                                {isInclude ? (
                                    get(isInclude, 'access', null)
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )}
                            </div>
                        </Tooltip>
                    );
                }
            },
            {
                title: <FormattedMessage id='packages.master_receiver' />,
                key: 'mr',
                width: '125px',
                align: 'center',
                render: row => {
                    const isInclude = row.roles ? row.roles.find(({ name }) => name === 'М/п') : undefined;
                    const postId = findPostId(allRoles, 'М/п');
                    const icon = findIcon(isInclude);

                    return (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        roleId: get(isInclude, 'roleId', postId),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon}{' '}
                                {isInclude ? (
                                    get(isInclude, 'access', null)
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )}
                            </div>
                        </Tooltip>
                    );
                }
            },
            {
                title: <FormattedMessage id='packages.sp_specialist' />,
                key: 'sp_specialist',
                width: '125px',
                align: 'center',
                render: row => {
                    const isInclude = row.roles ? row.roles.find(({ name }) => name === 'Запчаст.') : undefined;
                    const postId = findPostId(allRoles, 'Запчаст.');
                    const icon = findIcon(isInclude);

                    return (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        roleId: get(isInclude, 'roleId', postId),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon}{' '}
                                {isInclude ? (
                                    get(isInclude, 'access', null)
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )}
                            </div>
                        </Tooltip>
                    );
                }
            },
            {
                title: <FormattedMessage id='packages.call_center' />,
                key: 'cc',
                width: '125px',
                align: 'center',
                render: row => {
                    const isInclude = row.roles ? row.roles.find(({ name }) => name === 'Керівник КЦ') : undefined;
                    const postId = findPostId(allRoles, 'Керівник КЦ');
                    const icon = findIcon(isInclude);

                    return (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        roleId: get(isInclude, 'roleId', postId),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon}{' '}
                                {isInclude ? (
                                    get(isInclude, 'access', null)
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )}
                            </div>
                        </Tooltip>
                    );
                }
            },
            {
                title: <FormattedMessage id='packages.operator' />,
                key: 'operator',
                width: '125px',
                align: 'center',
                render: row => {
                    const isInclude = row.roles ? row.roles.find(({ name }) => name === 'Оператор КЦ') : undefined;
                    const postId = findPostId(allRoles, 'Оператор КЦ');
                    const icon = findIcon(isInclude);

                    return (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        roleId: get(isInclude, 'roleId', postId),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon}{' '}
                                {isInclude ? (
                                    get(isInclude, 'access', null)
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )}
                            </div>
                        </Tooltip>
                    );
                }
            },
            {
                title: <FormattedMessage id='packages.workshop_master' />,
                key: 'wm',
                width: '125px',
                align: 'center',
                render: row => {
                    const isInclude = row.roles ? row.roles.find(({ name }) => name === 'М. цеха') : undefined;
                    const postId = findPostId(allRoles, 'М. цеха');
                    const icon = findIcon(isInclude);

                    return (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        roleId: get(isInclude, 'roleId', postId),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon}{' '}
                                {isInclude ? (
                                    get(isInclude, 'access', null)
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )}
                            </div>
                        </Tooltip>
                    );
                }
            },
            {
                title: <FormattedMessage id='packages.mechanic' />,
                key: 'mechanic',
                width: '125px',
                align: 'center',
                render: row => {
                    const isInclude = row.roles ? row.roles.find(({ name }) => name === 'Механік') : undefined;
                    const postId = findPostId(allRoles, 'Механік');
                    const icon = findIcon(isInclude);

                    return (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        roleId: get(isInclude, 'roleId', postId),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon}{' '}
                                {isInclude ? (
                                    get(isInclude, 'access', null)
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )}
                            </div>
                        </Tooltip>
                    );
                }
            },
            {
                title: <FormattedMessage id='packages.accountant' />,
                key: 'accountant',
                width: '125px',
                align: 'center',
                render: row => {
                    const isInclude = row.roles ? row.roles.find(({ name }) => name === 'Бухгалтер') : undefined;
                    const postId = findPostId(allRoles, 'Бухгалтер');
                    const icon = findIcon(isInclude);

                    return (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        roleId: get(isInclude, 'roleId', postId),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon}{' '}
                                {isInclude ? (
                                    get(isInclude, 'access', null)
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )}
                            </div>
                        </Tooltip>
                    );
                }
            },
            {
                title: <FormattedMessage id='packages.cashier' />,
                key: 'cashier',
                width: '125px',
                align: 'center',
                render: row => {
                    const isInclude = row.roles ? row.roles.find(({ name }) => name === 'Касир') : undefined;
                    const postId = findPostId(allRoles, 'Касир');
                    const icon = findIcon(isInclude);

                    return (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        roleId: get(isInclude, 'roleId', postId),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon}{' '}
                                {isInclude ? (
                                    get(isInclude, 'access', null)
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )}
                            </div>
                        </Tooltip>
                    );
                }
            },

            {
                title: <FormattedMessage id='packages.storage' />,
                key: 'storage',
                width: '125px',
                align: 'center',
                render: row => {
                    const isInclude = row.roles ? row.roles.find(({ name }) => name === 'Комірник') : undefined;
                    const postId = findPostId(allRoles, 'Комірник');
                    const icon = findIcon(isInclude);

                    return (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        roleId: get(isInclude, 'roleId', postId),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon}{' '}
                                {isInclude ? (
                                    get(isInclude, 'access', null)
                                ) : (
                                    <FormattedMessage id='packages.no_data' />
                                )}
                            </div>
                        </Tooltip>
                    );
                }
            }
        ],
        [allRoles, findIcon, findPostId]
    );

    const renderNestedTable = useCallback(
        roots => {
            const tableProps = {
                dataSource: roots,
                columns,
                rowExpandable: record => record.childs.length,
                expandable: {
                    childrenColumnName: 'childs',
                    expandedRowKeys,
                    onExpandedRowsChange: expandedRowKeys => setExpandedRowKeys(expandedRowKeys)
                },
                onRow: ({ id, level }) => {
                    const classNames = `${Styles[fontOptions[level]] || ''} ${
                        foundIds.includes(id) ? Styles.foundEntry : ''
                    }`.trim();
                    if (classNames.length) {
                        return {
                            className: classNames
                        };
                    }
                },
                rowKey: 'id'
            };

            return (
                <Table
                    size='small'
                    {...tableProps}
                    bordered
                    pagination={tablePagination}
                    rowKey='id'
                    scroll={{ y: 700 }}
                />
            );
        },
        [columns, expandedRowKeys, tablePagination, foundIds]
    );

    useEffect(() => {
        fetchRoles();
    }, [fetchRoles]);

    useEffect(() => {
        debouncedFilterChange(filter);
    }, [flattenRoles, filter, debouncedFilterChange]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <Space>
                <Radio.Group optionType='button' value={visibleLevel}>
                    {radioOptions.map(index => (
                        <Radio key={index} onClick={handleLevelVisibility} value={index}>
                            {index}
                        </Radio>
                    ))}
                </Radio.Group>
                <DebouncedInput
                    placeholder={intl.formatMessage({ id: 'packages.search_by' })}
                    setState={setFilter}
                    width={300}
                />
            </Space>
            {renderNestedTable(defaultRoles)}
            <AccessSettingModal
                allRolesAcees={allRolesAcees}
                edit={edit}
                handleTableRole={handleTableRole}
                hideModal={() => {
                    setModalOpen(false);
                    setEdit(undefined);
                }}
                open={open}
            />
        </div>
    );
};

export default injectIntl(DefaultRolesTab);
