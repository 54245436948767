/* eslint-disable default-case */
import { Col, Row } from 'antd';
import {
    AnalyticsSelector,
    CashboxSelector,
    CashOrderTypeSelector,
    ClientCounterpartyContainer,
    CommentInput,
    CounterPartySelector,
    DateSelector,
    EmployeeCounterpartyContainer,
    OtherCounterpartyContainer,
    SumInput,
    SupplierCounterpartyContainer
} from 'modals/RefactoredCashOrderModal/components';
import { COUNTERPARTY_TYPES } from 'modals/RefactoredCashOrderModal/constants';
import { selectSelectedCounterpartyType } from 'modals/RefactoredCashOrderModal/redux/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    selectedCounterpartyType: selectSelectedCounterpartyType(state)
});

const mapDispatchToProps = {};

/**
 * This form is used to create ordinary cash order.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class DefaultForm extends Component {
    render() {
        const { selectedCounterpartyType, isMobile, editable, analyticsUniqueIdForEmployee } = this.props;
        const span = isMobile ? 24 : 12;
        // Select which counterparty has to be rendered depending on a value(selectedCounterparty) from redux
        let currentCounterpartyContainer = null;
        switch (selectedCounterpartyType) {
            case COUNTERPARTY_TYPES.CLIENT:
                currentCounterpartyContainer = <ClientCounterpartyContainer editable={editable} isMobile={isMobile} />;
                break;
            case COUNTERPARTY_TYPES.EMPLOYEE:
                currentCounterpartyContainer = <EmployeeCounterpartyContainer editable={editable} />;
                break;
            case COUNTERPARTY_TYPES.BUSINESS_SUPPLIER:
                currentCounterpartyContainer = <SupplierCounterpartyContainer editable={editable} />;
                break;
            case COUNTERPARTY_TYPES.OTHER:
                currentCounterpartyContainer = <OtherCounterpartyContainer editable={editable} />;
                break;
        }

        return (
            <React.Fragment>
                <div>
                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={span}>
                            <CashOrderTypeSelector editable={editable} />
                        </Col>
                        <Col className={Styles.col} span={span}>
                            <CashboxSelector editable={editable} />
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={span}>
                            <DateSelector editable={editable} />
                        </Col>
                        <Col className={Styles.col} span={span}>
                            <AnalyticsSelector
                                analyticsUniqueIdForEmployee={analyticsUniqueIdForEmployee}
                                editable={editable}
                            />
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={span}>
                            <SumInput editable={editable} />
                        </Col>
                        <Col className={Styles.col} span={span}>
                            <CounterPartySelector editable={editable} />
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={24}>
                            {currentCounterpartyContainer}
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={24}>
                            <CommentInput editable={editable} />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}
