import {
    CarOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    LockOutlined,
    PlusSquareOutlined,
    QuestionCircleOutlined,
    SafetyOutlined,
    StopOutlined,
    SwapOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal, notification } from 'antd';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
// import book from 'routes/book';
import Styles from './styles.m.css';

const { confirm } = Modal;
const { Item } = Menu;

@injectIntl
class ChangeStatusDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingStatus: false
        };
    }

    render() {
        const { orderStatus, onStatusChange, setModal, modals, isMobile, user, checkReserved, showToNotComplete } =
            this.props;

        const getMenuItems = () => {
            const changeToRequired = {
                status: 'required',
                name: 'transfer_required',
                icon: <QuestionCircleOutlined className={Styles.iconStyle} />,
                action: () => onStatusChange('required')
            };

            const changeToReserve = {
                status: 'reserve',
                name: 'transfer_reserve',
                icon: <LockOutlined className={Styles.iconStyle} />,
                action: () => onStatusChange('reserve')
            };

            const changeToNotComplete = {
                status: 'not_complete',
                name: 'transfer_not_complete',
                icon: <PlusSquareOutlined className={Styles.iconStyle} />,
                action: () => {
                    confirm({
                        title: this.props.intl.formatMessage({
                            id: 'order-page.change_status_to_new'
                        }),
                        onOk: () => {
                            this.setState({ loadingStatus: true });
                            notification.warning({ message: 'Н/З обробляється, не закривайте вкладку 20 секунд.' });
                            setTimeout(() => {
                                onStatusChange('not_complete');
                                notification.success({
                                    message: 'Успішно!'
                                });
                            }, 20000);
                        },
                        okButtonProps: {
                            disabled: this.state.loadingStatus
                        }
                    });
                }
            };

            const changeToApprove = {
                status: 'approve',
                name: 'transfer_approve',
                icon: <SafetyOutlined className={Styles.iconStyle} />,
                action: () => onStatusChange('approve')
            };

            const changeToProgress = {
                status: 'progress',
                name: 'transfer_progress',
                icon: <CarOutlined className={Styles.iconStyle} />,
                action: () => onStatusChange('progress')
            };

            const changeToSuccess = {
                status: 'success',
                name: 'transfer_success',
                icon: <CheckOutlined className={Styles.iconStyle} />,
                action: () => {
                    checkReserved(() => setModal(modals.TO_SUCCESS));
                }
            };

            const changeToProcessing = {
                status: 'processing',
                name: 'transfer_processing',
                icon: <ClockCircleOutlined className={Styles.iconStyle} />,
                action: () => onStatusChange('processing')
            };

            const changeToStop = {
                status: 'stop',
                name: 'transfer_stop',
                icon: <StopOutlined className={Styles.iconStyle} />,
                action: () => onStatusChange('stop')
            };

            const statuses = [
                changeToReserve,
                changeToRequired,
                changeToNotComplete,
                changeToProcessing,
                changeToApprove,
                changeToProgress,
                changeToStop,
                changeToSuccess
            ];
            const appointments = ['required', 'not_complete', 'reserve', 'invite', 'processing'];
            const approves = ['approve'];
            const statusesChain = [['call'], appointments, approves, 'progress', 'stop', 'success'];

            if (showToNotComplete) {
                statusesChain.push('not_complete');
            }

            const statusIndex = _.findIndex(statusesChain, elem =>
                _.isArray(elem) ? elem.includes(orderStatus) : elem === orderStatus);

            const suggestStatus = statusIndex ? _.flatten(statusesChain.slice(statusIndex + 1)) : [];

            const additionalStatuses = appointments.includes(orderStatus)
                ? appointments.filter(appointmentStatus =>
                      orderStatus === 'processing'
                          ? appointmentStatus !== 'not_complete'
                          : appointmentStatus !== orderStatus
                  )
                : orderStatus === 'reserve'
                ? ['approve']
                : orderStatus === 'call'
                ? ['reserve', 'required', 'approve', 'processing', 'progress', 'stop', 'success']
                : [];

            const allStatuses = [...suggestStatus, ...additionalStatuses];

            const menuItems = statuses
                .filter(({ status }) => allStatuses.includes(status) || (orderStatus === 'stop' && status !== 'stop'))
                .map(({ name, icon, action }) => ({
                    name,
                    icon,
                    action
                }));

            return menuItems;
        };

        const menuItems = getMenuItems();

        const menu = (
            <Menu>
                {menuItems.map((item, index) => (
                    <Item key={`${index}-${item.name}`} disabled={item.disabled} onClick={() => item.action()}>
                        {item.icon}
                        <FormattedMessage id={item.name} />
                    </Item>
                ))}
            </Menu>
        );

        return orderStatus !== 'cancel' && menuItems.length ? (
            <Dropdown overlay={menu}>
                <div className={Styles.dropdownTitle}>
                    <Button
                        icon={<SwapOutlined className={Styles.dropdownTitleIcon} />}
                        style={{ padding: '4px 8px' }}
                        type='text'
                    >
                        {!isMobile && (
                            <span style={{ verticalAlign: 'text-bottom' }}>
                                <FormattedMessage id='change_status_dropdown.change_status' />
                            </span>
                        )}
                    </Button>
                </div>
            </Dropdown>
        ) : null;
    }
}

export default ChangeStatusDropdown;
