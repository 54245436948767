import { setClientMRDsFetchingState } from 'core/ui/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    FETCH_CASH_ORDER_ENTITY,
    FETCH_CLIENT_MRDS,
    fetchCashOrderEntitySuccess,
    fetchClientMRDsSuccess,
    setCashOrderEntityIsFetching
} from './duck';

const selectFilter = ({ clientMRDs: { filter, sort } }) => ({
    sort,
    filter
});

export function* fetchClientMRDsSaga() {
    while (true) {
        try {
            const {
                payload: { clientId }
            } = yield take(FETCH_CLIENT_MRDS);
            yield put(setClientMRDsFetchingState(true));

            const {
                filter: {
                    datetime,
                    page,
                    pageSize,
                    filterSign,
                    filterDocumentType,
                    datetimeFrom,
                    datetimeTo,
                    nonZeroRows,
                    filterBusinessRequisiteId,
                    filterCounterpartRequisiteId
                }
            } = yield select(selectFilter);

            const debts = yield call(fetchAPI, 'GET', '/report/debts', {
                counterpartId: clientId,
                datetime,
                page,
                pageSize,
                filterSign,
                filterDocumentType,
                datetimeFrom: datetimeFrom ? datetimeFrom.format('YYYY-MM-DD') : undefined,
                datetimeTo: datetimeTo ? datetimeTo.format('YYYY-MM-DD') : undefined,
                nonZeroRows,
                filterBusinessRequisiteId,
                filterCounterpartRequisiteId
            });
            // const mrds = yield call(fetchAPI, 'GET', '/report/mrds', { filters, sort });
            yield put(fetchClientMRDsSuccess(debts || {}));
        } finally {
            yield put(setClientMRDsFetchingState(false));
        }
    }
}

export function* fetchCashOrderEntitySaga() {
    while (true) {
        try {
            const { payload: cashOrderId } = yield take(FETCH_CASH_ORDER_ENTITY);
            put(setCashOrderEntityIsFetching(true));

            const cashOrderEntity = yield call(fetchAPI, 'GET', `/cash_orders/${cashOrderId}`);
            yield put(fetchCashOrderEntitySuccess(cashOrderEntity));
        } finally {
            put(setCashOrderEntityIsFetching(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchClientMRDsSaga), call(fetchCashOrderEntitySaga)]);
}
