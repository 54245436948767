import { InputNumber, Modal, Table } from 'antd';
import { MODALS, setModal } from 'core/modals/duck';
// import { MODALS, setModal } from 'core/modals/duck';
import {
    fetchStoreDocServices,
    selectFilters,
    selectStats,
    selectStoreDocServices,
    setStoreDocServicesFilters
} from 'forms/StorageForms/StorageDocumentForm/redux/duck';
import {
    setStoreDocServiceFields,
    updateStoreDocService
} from 'modals/StoreDocServiceModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchAPI } from 'utils';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    storeDocServices: selectStoreDocServices(state),
    stats: selectStats(state),
    filters: selectFilters(state)
    // fetching: selectFetching(state),
});

const mapDispatchToProps = {
    setModal,
    fetchStoreDocServices,
    setStoreDocServicesFilters,
    updateStoreDocService,
    setStoreDocServiceFields
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class StoreDocServicesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],
            selectedRows: [],
            setPriceModalValue: 1
        };
    }

    componentDidMount() {
        this.props.setStoreDocServicesFilters({ storeDocId: this.props.storeDocId });
        this.props.fetchStoreDocServices();
    }

    onAddStoreDocService = () => {
        this.props.setModal(MODALS.STORE_DOC_SERVICE, {
            mode: 'ADD',
            storeDocId: this.props.storeDocId,
            onSubmit: () => {
                this.props.fetchStoreDocServices();
                this.props.handleStoreDocServices(); // update sums in store document
            }
        });
    };

    onEditStoreDocService = storeDocServiceEntity => {
        console.log('StoreDocServiceEntity', storeDocServiceEntity);
        this.props.setModal(MODALS.STORE_DOC_SERVICE, {
            mode: 'EDIT',
            // storeDocId: this.props.storeDocId,
            storeDocServiceEntity,
            onSubmit: () => {
                this.props.fetchStoreDocServices();
                this.props.handleStoreDocServices(); // update sums in store document
            }
        });
    };

    render() {
        const {
            storeDocServices,
            stats,
            filters,
            disabled,
            setStoreDocServicesFilters,
            handleStoreDocServices,
            updateFormData,
            fetchStoreDocServices,
            storeDocId,
            mapperData
        } = this.props;
        const { selectedRowKeys, selectedRows, setPriceModal, setPriceModalValue } = this.state;

        const columns = columnsConfig({
            mapperData,
            disabled,
            deleteStoreDocService: ids => {
                ids.forEach(async id => {
                    await fetchAPI('DELETE', `store_doc_service/${id}`, null, null, {
                        handleErrorInternally: true
                    });
                });
                this.setState({
                    selectedRowKeys: [],
                    selectedRows: []
                });
                fetchStoreDocServices();
            },
            handleStoreDocServices,
            onAddStoreDocService: this.onAddStoreDocService,
            onEditStoreDocService: this.onEditStoreDocService,
            selectedRowKeys,
            selectedRows,
            storeDocServices,
            updateFormData,
            showRecalcModal: () => this.setState({ setPriceModal: true })
        });

        if (disabled) {
            columns.splice(0, 1);
        }

        const pagination = {
            pageSize: 25,
            size: 'large',
            hideOnSinglePage: true,
            total: Math.ceil(stats.totalRowsCount / 25) * 25,
            current: filters.page,
            onChange: page => {
                setStoreDocServicesFilters({ page });
            }
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            }
        };

        return (
            <div>
                <Table
                    bordered
                    columns={columns}
                    dataSource={storeDocServices}
                    pagination={pagination}
                    rowClassName={Styles.detailsTableRow}
                    rowKey='storeDocServiceId'
                    rowSelection={!disabled && rowSelection}
                />
                <Modal
                    destroyOnClose
                    onCancel={() => {
                        this.setState({
                            setPriceModal: undefined,
                            setPriceModalValue: 1
                        });
                    }}
                    onOk={() => {
                        selectedRows.forEach(async srv => {
                            await fetchAPI(
                                'PUT',
                                `store_doc_services/${srv.storeDocServiceId}`,
                                null,
                                {
                                    storeDocId,
                                    name: srv.name,
                                    count: srv.count,
                                    price: srv.price * setPriceModalValue,
                                    sum: srv.sum * setPriceModalValue
                                },
                                {
                                    handleErrorInternally: true
                                }
                            );
                        });
                        this.setState({
                            selectedRowKeys: [],
                            selectedRows: [],
                            setPriceModal: undefined,
                            setPriceModalValue: 1
                        });
                        fetchStoreDocServices();
                    }}
                    title={<FormattedMessage id='update_price' />}
                    visible={setPriceModal}
                >
                    <FormattedMessage id='factor' />
                    <InputNumber
                        decimalSeparator=','
                        onChange={value => {
                            this.setState({
                                setPriceModalValue: value
                            });
                        }}
                        precision={4}
                        step={0.002}
                        style={{ margin: '0 0 0 8px' }}
                        value={setPriceModalValue}
                    />
                </Modal>
            </div>
        );
    }
}
