import { Button, Input, InputNumber, Modal, Select, Tooltip } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from '../styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

@injectIntl
export default class SalaryAddRowModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps, prevState) {}

    handleOk = async () => {
        const { id, fetchSalaryDocData, empId } = this.props;
        const { employeeId, type, salary, comment } = this.state;
        await fetchAPI(
            'POST',
            '/add/salary/rows',
            undefined,
            {
                salaryDocId: id,
                employeeId: empId,
                type,
                salary,
                comment,
                creationType: 'MANUAL'
            },
            { handleErrorInternally: true }
        );

        this.props.hideModal();
        fetchSalaryDocData();
    };

    handleCancel() {
        this.setState({
            employeeId: undefined,
            type: undefined,
            salary: undefined
        });
        this.props.hideModal();
    }

    render() {
        const { allEmployees, isMobile } = this.props;
        const { employeeId, salary, type, comment } = this.state;

        return (
            <Modal
                destroyOnClose
                footer={
                    <div>
                        <Button
                            key='back'
                            onClick={() => {
                                this.handleCancel();
                            }}
                        >
                            <FormattedMessage id='stock_table.button.cancel' />
                        </Button>
                        <Button
                            key='submit'
                            onClick={async () => {
                                await this.handleOk();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='ok' />
                        </Button>
                    </div>
                }
                maskClosable={false}
                onCancel={() => {
                    this.handleCancel();
                }}
                title={<FormattedMessage id='regulations.add_row' />}
                visible={this.props.visible}
                width='fit-content'
                zIndex={200}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        margin: '24px 0 0 0'
                    }}
                >
                    <div className={Styles.addProductItemWrap} style={{ minWidth: 140 }}>
                        <FormattedMessage id='storage.type' />

                        <Tooltip placement='top' title={<FormattedMessage id='order_form_table.brand' />}></Tooltip>
                        <Select
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: 220
                            }}
                            onSelect={(value, option) => {
                                this.setState({
                                    type: value,
                                    salary: undefined
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'search'
                            })}
                            showSearch
                            style={{
                                maxWidth: 140
                            }}
                            value={type}
                        >
                            <Option key='bonus' value='BONUS'>
                                <FormattedMessage id='employee_salary_rules.BONUS' />
                            </Option>
                            <Option key='panalty' value='PENALTY'>
                                <FormattedMessage id='employee_salary_rules.PENALTY' />
                            </Option>
                            <Option key='payout' value='PAYOUT'>
                                <FormattedMessage id='employee_salary_rules.PAYOUT' />
                            </Option>
                        </Select>
                    </div>

                    <div className={Styles.addProductItemWrap}>
                       
                            <div>
                                <FormattedMessage id='employee_salary_rules.sal' />
                                <span
                                    style={{
                                        color: 'red'
                                    }}
                                >
                                    *
                                </span>
                            </div>
                        
                        <span className={Styles.priceslNumberInput}>
                                <InputNumber
                                    decimalSeparator=','
                                    onChange={value => {
                                        let newSalary = value;
                                        if (type === 'BONUS' && value < 0) {
                                            newSalary = -value;
                                        } else if ((type === 'PENALTY') && value > 0) {
                                            newSalary = -value;
                                        } else if (type === 'PAYOUT') {
                                            newSalary = value;
                                        } else if (value === null || value === undefined) {
                                            newSalary = 0.00;
                                        }
                                        this.setState({
                                            salary: newSalary
                                        });
                                    }}
                                    precision={2}
                                    step={0.01}
                                    style={
                                        {
                                            // marginLeft: 10,
                                        }
                                    }
                                    value={salary}
                                />
                        </span>
                    </div>
                </div>
                <div className={Styles.addProductItemWrap}>
                    <div>
                        <FormattedMessage id='comment' />
                    </div>
                    <span className={Styles.priceslNumberInput}>
                            <Input.TextArea
                                onChange={e => {
                                    this.setState({
                                        comment: e.target.value
                                    });
                                }}
                                type='textarea'
                                value={comment}
                            />
                    </span>
                </div>
            </Modal>
        );
    }
}
