import { Form } from '@ant-design/compatible';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import { Numeral } from 'commons';
import { MODALS } from 'core/modals/duck';
import dayjs from 'dayjs';
import {
    DecoratedDatePicker,
    DecoratedInput,
    DecoratedSelect,
    DecoratedSlider,
    DecoratedTextArea,
    DecoratedTimePicker
} from 'forms/DecoratedFields';
import _ from 'lodash';
import { AddClientModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isForbidden, permissions } from 'utils';
import { v4 } from 'uuid';
import { ClientsSearchTable } from '../../OrderForm/OrderFormTables';
import Styles from './styles.m.css';

const FormItem = Form.Item;
const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xl: { span: 24 },
        xxl: { span: 4 }
    },
    wrapperCol: {
        xl: { span: 24 },
        xxl: { span: 20 }
    },
    colon: false
};

const getDisabledHours = (startTime = 0, endTime = 23) => {
    const availableHours = [];
    for (let i = Number(startTime); i <= Number(endTime); i++) {
        availableHours.push(i);
    }

    return _.difference(
        Array(24)
            .fill(1)
            .map((value, index) => index),
        availableHours
    );
};

@injectIntl
export class OrderMobileFormFields extends Component {
    constructor(props) {
        super(props);

        this.state = {
            workshopModalVisible: false,
            stockModalVisible: false
        };
    }

    bodyUpdateIsForbidden() {
        return isForbidden(this.props.user, permissions.ACCESS_ORDER_BODY);
    }

    _renderClientSearch = () => {
        const { getFieldDecorator } = this.props.form;
        const { user, fields, errors } = this.props;
        const { CREATE_EDIT_DELETE_CLIENTS } = permissions;

        const disabledClientSearch =
            (!_.get(this.props, 'order.status') ||
                _.get(this.props, 'order.status') !== 'reserve') &&
            _.get(this.props, 'order.clientId');

        return !disabledClientSearch ? (
            <div className={Styles.client}>
                <DecoratedInput
                    className={Styles.clientSearchField}
                    colon={false}
                    defaultGetValueProps
                    disabled={Boolean(disabledClientSearch)}
                    errors={errors}
                    field='searchClientQuery'
                    fieldValue={_.get(fields, 'searchClientQuery')}
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    label={this.props.intl.formatMessage({
                        id: 'add_order_form.search_client'
                    })}
                    placeholder={this.props.intl.formatMessage({
                        id: 'add_order_form.search_client.placeholder'
                    })}
                />
                {!isForbidden(user, CREATE_EDIT_DELETE_CLIENTS) ? (
                    <Button
                        className={Styles.addClientIcon}
                        onClick={() => this.props.setAddClientModal()}
                        type='primary'
                    >
                        <PlusOutlined />
                    </Button>
                ) : null}
            </div>
        ) : null;
    };

    _renderClientSearchTable = () => {
        const {
            searchClientsResult: { searching: clientsSearching, clients },
            setClientSelection,
            form,
            isMobile
        } = this.props;

        const formFieldsValues = form.getFieldsValue();
        const searchClientQuery = _.get(formFieldsValues, 'searchClientQuery');

        return (
            <ClientsSearchTable
                clients={clients}
                clientsSearching={clientsSearching}
                isMobile={isMobile}
                setClientSelection={setClientSelection}
                visible={searchClientQuery}
            />
        );
    };

    _renderClientColumn = () => {
        const { selectedClient, fetchedOrder, fields, errors, setModal } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <div className={Styles.bodyColumn}>
                <div className={Styles.bodyColumnContent}>
                    <div className={Styles.contentWrapper}>
                        <div className={Styles.comboFieldWrapper}>
                            <FormattedMessage id='add_order_form.client' />
                            <div
                                className={Styles.comboField}
                                onClick={() => {
                                    setModal(MODALS.ADD_CLIENT, {
                                        mode: 'EDIT',
                                        clientId: _.get(selectedClient, 'clientId')
                                    });
                                }}
                            >
                                {selectedClient.name || selectedClient.surname
                                    ? `${
                                          selectedClient.surname ? `${selectedClient.surname} ` : ''
                                      }${selectedClient.name}`
                                    : void 0}
                            </div>
                        </div>
                        <DecoratedSelect
                            className={`${Styles.clientCol} ${Styles.comboFieldSelect}`}
                            colon={false}
                            defaultGetValueProps
                            disabled={this.bodyUpdateIsForbidden()}
                            dropdownStyle={this._clientPhoneBorderStyle}
                            errors={errors}
                            field='clientPhone'
                            fieldValue={_.get(fields, 'clientPhone')}
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            initialValue={
                                _.get(fetchedOrder, 'order.clientPhone') ||
                                (this.bodyUpdateIsForbidden()
                                    ? void 0
                                    : _.get(selectedClient, 'phones[0]'))
                            }
                            rules={this.requiredFieldRules}
                        >
                            {_.get(selectedClient, 'phones', [])
                                .filter(Boolean)
                                .map(phone => (
                                    <Option key={v4()} value={phone}>
                                        {phone}
                                    </Option>
                                ))}
                        </DecoratedSelect>
                    </div>
                </div>
            </div>
        );
    };

    _renderTotalBlock = () => {
        const { totalSum, totalSumWithTax, isTaxPayer, cashSum } = this.props;

        const remainPrice = isTaxPayer
            ? Math.round((totalSumWithTax - cashSum) * 100) / 100
            : Math.round((totalSum - cashSum) * 100) / 100;

        const mask = '0,0.00';

        return (
            <div className={Styles.totalBlock}>
                <div className={Styles.sum}>
                    <span className={Styles.sumWrapper}>
                        <FormattedMessage id='sum' />
                        <Numeral
                            className={Styles.sumNumeral}
                            currency={this.props.intl.formatMessage({
                                id: 'currency'
                            })}
                            mask={mask}
                            nullText='0'
                        >
                            {totalSum}
                        </Numeral>
                    </span>
                    {isTaxPayer && (
                        <span className={Styles.sumWrapper}>
                            <FormattedMessage id='with' /> <FormattedMessage id='VAT' />
                            <Numeral
                                className={Styles.sumNumeral}
                                currency={this.props.intl.formatMessage({
                                    id: 'currency'
                                })}
                                mask={mask}
                                nullText='0'
                            >
                                {totalSumWithTax}
                            </Numeral>
                        </span>
                    )}
                    <span className={Styles.sumWrapper}>
                        <FormattedMessage id='paid' />
                        <Numeral
                            className={Styles.sumNumeral}
                            currency={this.props.intl.formatMessage({
                                id: 'currency'
                            })}
                            mask={mask}
                            nullText='0'
                        >
                            {cashSum}
                        </Numeral>
                    </span>
                </div>
                <div className={Styles.totalSumWrap}>
                    <FormattedMessage id='remain' />
                    <Numeral
                        className={Styles.totalSum}
                        currency={this.props.intl.formatMessage({
                            id: 'currency'
                        })}
                        mask={mask}
                        nullText='0'
                    >
                        {remainPrice || 0}
                    </Numeral>
                </div>
            </div>
        );
    };

    _renderVehicleColumn = () => {
        const {
            selectedClient,
            fetchedOrder,
            fields,
            errors,
            vehicleTypes,
            updateOrderField,
            setModal,
            intl: { formatMessage },
            form: { getFieldDecorator, setFieldsValue }
        } = this.props;
        const selectedVehicleId = _.get(fetchedOrder, 'order.clientVehicleId');
        const clientVehicleRadius = Math.round(_.get(fetchedOrder, 'order.clientVehicleRadius', 0));

        const selectedVehicle =
            selectedClient &&
            selectedVehicleId &&
            _.find(selectedClient.vehicles, { id: selectedVehicleId });

        console.log(this, selectedVehicle);

        const radiuses = [...Array(41).keys()].map(i => i + 10);

        return (
            <div className={Styles.bodyColumn}>
                <div className={Styles.bodyColumnContent}>
                    <div className={Styles.contentWrapper}>
                        <div className={Styles.comboFieldWrapper}>
                            <FormattedMessage id='add_order_form.car' />
                            <div
                                className={Styles.comboField}
                                onClick={() => {
                                    setModal(MODALS.VEHICLE, {
                                        mode: 'EDIT',
                                        vehicleId: _.get(selectedVehicle, 'id'),
                                        onSubmit: () => window.location.reload()
                                    });
                                }}
                            >
                                <div>
                                    {_.get(selectedVehicle, 'number') && (
                                        <div>
                                            <FormattedMessage id='add_client_form.number' />
                                            {_.get(selectedVehicle, 'number')}
                                        </div>
                                    )}
                                    {_.get(selectedVehicle, 'vin') && (
                                        <div>
                                            <FormattedMessage id='add_client_form.vin' />
                                            {_.get(selectedVehicle, 'vin')}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <DecoratedSelect
                            className={Styles.comboFieldSelect}
                            colon={false}
                            defaultGetValueProps
                            disabled={this.bodyUpdateIsForbidden()}
                            errors={errors}
                            field='clientVehicle'
                            fieldValue={_.get(fields, 'clientVehicle')}
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            initialValue={
                                _.get(fetchedOrder, 'order.clientVehicleId') ||
                                (this.bodyUpdateIsForbidden()
                                    ? void 0
                                    : _.get(selectedClient, 'vehicles[0].id'))
                            }
                            onSelect={(value, option) => {
                                setFieldsValue({
                                    clientVehicleTypeId: option.props.type,
                                    clientVehicleRadius: option.props.radius
                                });
                            }}
                            optionDisabled='enabled'
                        >
                            {_.get(selectedClient, 'vehicles', []).map(vehicle => (
                                <Option
                                    key={vehicle.id}
                                    disabled={vehicle.disabled}
                                    radius={vehicle.wheelRadius}
                                    type={vehicle.vehicleTypeId}
                                    value={vehicle.id}
                                >
                                    {`${vehicle.make} ${vehicle.model}`}
                                </Option>
                            ))}
                        </DecoratedSelect>
                    </div>
                </div>
                {vehicleTypes && (
                    <div className={Styles.vehicleInfo}>
                        <DecoratedSelect
                            defaultGetValueProps
                            disabled={this.bodyUpdateIsForbidden()}
                            errors={errors}
                            field='clientVehicleTypeId'
                            fieldValue={_.get(fields, 'clientVehicleTypeId')}
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            initialValue={
                                _.get(fetchedOrder, 'order.clientVehicleTypeId') ||
                                (this.bodyUpdateIsForbidden()
                                    ? void 0
                                    : _.get(this.props, 'vehicleTypes[0].id'))
                            }
                            onChange={(value, option) => {
                                updateOrderField({
                                    clientVehicleTypeId: value,
                                    clientVehicleRadius: option.props.radius
                                });
                            }}
                        >
                            {vehicleTypes.map(({ id, name, defaultRadius }) => (
                                <Option key={id} radius={defaultRadius} value={id}>
                                    {name}
                                </Option>
                            ))}
                        </DecoratedSelect>
                        <DecoratedSelect
                            defaultGetValueProps
                            disabled={this.bodyUpdateIsForbidden()}
                            errors={errors}
                            field='clientVehicleRadius'
                            fieldValue={Math.round(_.get(fields, 'clientVehicleRadius'))}
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            initialValue={Math.round(
                                _.get(fetchedOrder, 'order.clientVehicleRadius', 0) ||
                                    (this.bodyUpdateIsForbidden()
                                        ? void 0
                                        : _.get(selectedClient, 'vehicles[0].wheelRadius', 14))
                            )}
                            onChange={(value, option) => {
                                updateOrderField({
                                    clientVehicleRadius: value
                                });
                            }}
                            style={{
                                width: '45%'
                            }}
                        >
                            {radiuses.map(radius => (
                                <Option key={radius} value={radius}>
                                    {`${radius}R`}
                                </Option>
                            ))}
                        </DecoratedSelect>
                    </div>
                )}
                <DecoratedSelect
                    defaultGetValueProps
                    disabled={this.bodyUpdateIsForbidden()}
                    errors={errors}
                    field='replacementType'
                    fieldValue={_.get(fields, 'replacementType')}
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    initialValue={_.get(fetchedOrder, 'order.replacementType')}
                    label={formatMessage({
                        id: 'add_order_form.replacement_type'
                    })}
                    placeholder={formatMessage({
                        id: 'add_order_form.select_replacement_type'
                    })}
                    style={{
                        width: '100%'
                    }}
                >
                    <Option value='BOUGHT_IN_AUTODOC'>
                        <FormattedMessage id='add_order_form.replacement_type.BOUGHT_IN_AUTODOC' />
                    </Option>
                    <Option value='BROUGHT_BY_ONESELF'>
                        <FormattedMessage id='add_order_form.replacement_type.BROUGHT_BY_ONESELF' />
                    </Option>
                    <Option value='STORAGE'>
                        <FormattedMessage id='add_order_form.replacement_type.STORAGE' />
                    </Option>
                </DecoratedSelect>
            </div>
        );
    };

    _saveFormRef = formRef => {
        this.formRef = formRef;
    };

    render() {
        const {
            selectedClient,
            stations,
            onStatusChange,
            order: { status },
            schedule,
            fetchedOrder,
            form,
            modal,
            resetModal,
            addClientFormData,
            fetchOrderForm,
            orderId
        } = this.props;
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { formatMessage } = this.props.intl;

        const formFieldsValues = form.getFieldsValue();
        const searchClientQuery = _.get(formFieldsValues, 'searchClientQuery');

        const vehicle =
            selectedClient.vehicles.find(
                vehicle => vehicle.id == this.props.order.clientVehicleId
            ) || undefined;

        const dayNumber = dayjs(_.get(this.props, 'stationLoads[0].beginDate')).day();
        let disabledHours;
        if (schedule && dayNumber) {
            let index;
            switch (dayNumber) {
                case 6:
                    index = 1;
                    break;
                case 7:
                    index = 2;
                    break;
                default:
                    index = 0;
            }

            disabledHours = getDisabledHours(
                schedule[index] && schedule[index].beginTime
                    ? schedule[index].beginTime.split(/[.:]/)[0]
                    : 9,
                schedule[index] && schedule[index].endTime
                    ? schedule[index].endTime.split(/[.:]/)[0]
                    : 20
            );
        }

        const isDurationDisabled = _.every(
            getFieldsValue([
                'stationLoads[0].beginDate',
                'stationLoads[0].beginTime',
                'stationLoads[0].station'
            ])
        );

        const totalBlock = this._renderTotalBlock();
        const clientSearch = this._renderClientSearch();
        const clientsSearchTable = this._renderClientSearchTable();
        const clientColumn = this._renderClientColumn();
        const vehicleColumn = this._renderVehicleColumn();

        return (
            <div>
                {clientSearch}
                {clientsSearchTable}
                {clientColumn}
                {vehicleColumn}
                <div style={{ display: 'none' }}>
                    <DecoratedInput
                        field='stationLoads[0].status'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        hiddeninput='hiddeninput'
                        initialValue='TO_DO'
                    />

                    <FormItem
                        label={<FormattedMessage id='add_order_form.client' />}
                        {...formItemLayout}
                    >
                        <Input
                            disabled
                            placeholder={formatMessage({
                                id: 'add_order_form.select_name',
                                defaultMessage: 'Select client'
                            })}
                            style={{ color: 'var(--text)' }}
                            value={
                                selectedClient.name || selectedClient.surname
                                    ? `${
                                          selectedClient.surname ? `${selectedClient.surname} ` : ''
                                      }${selectedClient.name}`
                                    : void 0
                            }
                        />
                    </FormItem>
                    <DecoratedSelect
                        className={Styles.clientCol}
                        colon={false}
                        field='clientPhone'
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        initialValue={
                            _.get(fetchedOrder, 'order.clientPhone') ||
                            (this.bodyUpdateIsForbidden()
                                ? void 0
                                : _.get(selectedClient, 'phones[0]'))
                        }
                        label={<FormattedMessage id='add_order_form.phone' />}
                        placeholder={formatMessage({
                            id: 'add_order_form.select_client_phone'
                        })}
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                    >
                        {_.get(selectedClient, 'phones', [])
                            .filter(Boolean)
                            .map(phone => (
                                <Option key={v4()} value={phone}>
                                    {phone}
                                </Option>
                            ))}
                    </DecoratedSelect>
                    <DecoratedSelect
                        className={Styles.clientCol}
                        colon={false}
                        field='clientVehicle'
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        initialValue={
                            _.get(fetchedOrder, 'order.clientVehicleId') ||
                            (this.bodyUpdateIsForbidden()
                                ? void 0
                                : _.get(selectedClient, 'vehicles[0].id'))
                        }
                        label={<FormattedMessage id='add_order_form.car' />}
                        optionDisabled='enabled'
                        placeholder={formatMessage({
                            id: 'add_order_form.select_client_vehicle'
                        })}
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                    >
                        {_.get(selectedClient, 'vehicles', []).map(vehicle => (
                            <Option key={v4()} value={vehicle.id}>
                                {`${vehicle.make} ${vehicle.model} ${
                                    vehicle.number || vehicle.vin || ''
                                }`}
                            </Option>
                        ))}
                    </DecoratedSelect>
                </div>
                <hr />
                {totalBlock}
                <hr />
                <div style={{ fontSize: '18px', marginBottom: '10px' }}>
                    <FormattedMessage id='add_order_form.appointment_details' />
                </div>
                <DecoratedSelect
                    className={Styles.datePanelItem}
                    colon={false}
                    field='stationLoads[0].station'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    initialValue={this.props.order.stationNum}
                    label={<FormattedMessage id='add_order_form.station' />}
                    optionLabel='name'
                    options={stations}
                    optionValue='num'
                    placeholder={<FormattedMessage id='add_order_form.select_station' />}
                />
                <DecoratedDatePicker
                    allowClear={false}
                    className={Styles.datePanelItem}
                    field='stationLoads[0].beginDate'
                    formatMessage={formatMessage}
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    initialValue={dayjs(this.props.order.beginDatetime).toISOString()}
                    label={<FormattedMessage id='date' />}
                    {...formItemLayout}
                    rules={[
                        {
                            required: true,
                            message: formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                <DecoratedTimePicker
                    allowClear={false}
                    className={Styles.datePanelItem}
                    disabledHours={() => disabledHours}
                    field='stationLoads[0].beginTime'
                    formatMessage={formatMessage}
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    hideDisabledOptions
                    initialValue={dayjs(this.props.order.beginDatetime).toISOString()}
                    inputReadOnly
                    label={<FormattedMessage id='time' />}
                    minuteStep={30}
                    rules={[
                        {
                            required: true,
                            message: formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                <DecoratedSlider
                    disabled={!isDurationDisabled}
                    field='stationLoads[0].duration'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    initialValue={this.props.order.duration}
                    label={
                        <React.Fragment>
                            <FormattedMessage id='add_order_form.duration' /> (
                            {_.get(
                                formFieldsValues,
                                'stationLoads[0].duration',
                                this.props.order.duration
                            )}
                            )
                        </React.Fragment>
                    }
                    max={8}
                    min={0}
                    step={0.5}
                    {...formItemLayout}
                />
                <DecoratedSelect
                    className={Styles.datePanelItem}
                    colon={false}
                    field='manager'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    initialValue={this.props.order.managerId}
                    label={<FormattedMessage id='add_order_form.manager' />}
                    placeholder={formatMessage({
                        id: 'add_order_form.select_manager'
                    })}
                    rules={[
                        {
                            required: true,
                            message: formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                    {...formItemLayout}
                >
                    {this.props.managers.map(manager => (
                        <Option key={v4()} disabled={manager.disabled} value={manager.id}>
                            {`${manager.managerSurname} ${manager.managerName}`}
                        </Option>
                    ))}
                </DecoratedSelect>
                <DecoratedSelect
                    className={Styles.datePanelItem}
                    defaultGetValueProps
                    field='employee'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    initialValue={
                        _.get(fetchedOrder, 'order.employeeId') ||
                        (location.state ? location.state.employeeId : undefined)
                    }
                    label={<FormattedMessage id='order_form_table.master' />}
                    placeholder={formatMessage({
                        id: 'order_form_table.select_master'
                    })}
                    {...formItemLayout}
                >
                    {_.get(this.props, 'employees', []).map(employee => {
                        if (!employee.disabled) {
                            return (
                                <Option
                                    key={`employee-${employee.id}`}
                                    disabled={employee.disabled}
                                    value={employee.id}
                                >
                                    {`${employee.surname} ${employee.name}`}
                                </Option>
                            );
                        }
                    })}
                </DecoratedSelect>
                <DecoratedTextArea
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    field='comment'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    initialValue={this.props.order.comment}
                    label={<FormattedMessage id='add_order_form.client_comments' />}
                    placeholder={formatMessage({
                        id: 'add_order_form.client_comments',
                        defaultMessage: 'Client_comments'
                    })}
                    rules={[
                        {
                            max: 2000,
                            message: formatMessage({
                                id: 'field_should_be_below_2000_chars'
                            })
                        }
                    ]}
                />
                <AddClientModal
                    addClientFormData={addClientFormData}
                    callback={() => {
                        resetModal();
                        fetchOrderForm(orderId);
                    }}
                    resetModal={resetModal}
                    searchQuery={searchClientQuery}
                    visible={modal}
                    wrappedComponentRef={this._saveFormRef}
                />
            </div>
        );
    }
}
