import DiscountPanel from './DiscountPanel';
import ClientsSearchTable from './ClientsSearchTable';
import ClientsVehiclesTable from './ClientsVehiclesTable';
import HistoryTable from './HistoryTable';
import TasksTable from './TasksTable';
import CallsTable from './CallsTable';
import StationsTable from './StationsTable';

export {
    DiscountPanel,
    ClientsSearchTable,
    HistoryTable,
    TasksTable,
    CallsTable,
    StationsTable,
    ClientsVehiclesTable
};
