/* eslint-disable no-template-curly-in-string */
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Select, Upload, notification } from 'antd';
import imageCompression from 'browser-image-compression';
import _ from 'lodash';
import React, { forwardRef, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchAPI, getToken, toBase64 } from 'utils';
import './styles.less';

const { Option } = Select;

export const GeneralSettings = forwardRef(
    ({ cb24data, saveData, fetchCB24data, sessionId, formatMessage, isDisabledCRUD }, ref) => {
        const [generalSettingsForm] = Form.useForm();
        const [logo, setLogo] = useState([]);

        useEffect(() => {
            const bussinesType = [
                _.get(cb24data, 'is_garage') && 'isGarage',
                _.get(cb24data, 'is_car_wash') && 'isCarWash',
                _.get(cb24data, 'is_tire_station') && 'isTireStation'
            ];

            generalSettingsForm.setFieldsValue({
                name: _.get(cb24data, 'name'),
                language: _.get(cb24data, 'language_id'),
                synonym: _.get(cb24data, 'synonym'),
                theme: _.get(cb24data, 'theme'),
                description: _.get(cb24data, 'description'),
                bussinesType
            });
            setLogo(_.get(cb24data, 'logo'));
        }, [cb24data, generalSettingsForm]);

        const deleteLogo = async () => {
            await fetchAPI('DELETE', 'rest/v1/business/logo', null, { sessionId }, { url: __OLD_APP_API_URL__ });

            fetchCB24data();
        };

        const confirmLogo = async file => {
            const base64 = await toBase64(file);

            await fetchAPI(
                'PUT',
                'rest/v1/business/logo-base64',
                null,
                { sessionId, base64 },
                { url: __OLD_APP_API_URL__ }
            );

            fetchCB24data();
        };

        const updateLogo = async file => {
            if (_.get(logo, 'path')) {
                deleteLogo();
            }
            const formData = new FormData();
            const compressedFile = await imageCompression(file, {
                maxWidthOrHeight: 280,
                maxSizeMB: 2
            });
            formData.append('file', compressedFile);

            await fetch(`${__OLD_APP_API_URL__}/rest/v1/business/logo`, {
                method: 'POST',
                headers: {
                    Authorization: getToken()
                },
                body: formData
            });

            confirmLogo(compressedFile);
        };

        const onFinish = values => {
            saveData({
                ...values,
                isGarage: values.bussinesType.includes('isGarage'),
                isCarWash: values.bussinesType.includes('isCarWash'),
                isTireStation: values.bussinesType.includes('isTireStation')
            });
        };

        return (
            <div className='general-settings'>
                <Form
                    ref={ref}
                    className='general-settings-form'
                    disabled={isDisabledCRUD}
                    form={generalSettingsForm}
                    labelCol={{
                        span: 8
                    }}
                    name='general-settings'
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={<FormattedMessage id='general_settings.business_name' />}
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'general_settings.enter_your_name'
                                })
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id='general_settings.url_name' />}
                        name='synonym'
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'general_settings.enter_your_url_name'
                                })
                            }
                        ]}
                        tooltip={{
                            title: formatMessage({
                                id: 'general_settings.url_tooltip'
                            }),
                            icon: <QuestionCircleOutlined />
                        }}
                    >
                        <Input suffix='.cb24.eu' />
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id='general_settings.bussines_type' />} name='bussinesType'>
                        <Checkbox.Group>
                            <Checkbox value='isGarage'>
                                <FormattedMessage id='general_settings.car_service_type' />
                            </Checkbox>
                            <Checkbox value='isCarWash'>
                                <FormattedMessage id='general_settings.car_wash_type' />
                            </Checkbox>
                            <Checkbox value='isTireStation'>
                                <FormattedMessage id='general_settings.tire_fitting_type' />
                            </Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id='general_settings.language' />}
                        name='language'
                        tooltip={{
                            title: formatMessage({
                                id: 'general_settings.language_tooltip'
                            }),
                            icon: <QuestionCircleOutlined />
                        }}
                    >
                        <Select
                            getPopupContainer={trigger => trigger.parentNode}
                            placeholder={formatMessage({
                                id: 'general_settings.language.placeholder'
                            })}
                        >
                            <Option value={2}>English</Option>
                            <Option value={4}>Українська</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id='general_settings.color_scheme' />}
                        name='theme'
                        tooltip={{
                            title: formatMessage({
                                id: 'general_settings.scheme_tooltip'
                            }),
                            icon: <QuestionCircleOutlined />
                        }}
                    >
                        <Select
                            placeholder={formatMessage({
                                id: 'general_settings.color_scheme.placeholder'
                            })}
                        >
                            <Option value='blue'>
                                <FormattedMessage id='general_settings.blue' />
                            </Option>

                            <Option value='green'>
                                <FormattedMessage id='general_settings.green' />
                            </Option>
                            <Option value='red'>
                                <FormattedMessage id='general_settings.red' />
                            </Option>
                            <Option value='grayblue'>
                                <FormattedMessage id='general_settings.gray_blue' />
                            </Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id='general_settings.bussines_description' />}
                        name='description'
                    >
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 14,
                            span: 12
                        }}
                    >
                        <Button htmlType='submit' type='primary'>
                            <FormattedMessage id='general_settings.submit' />
                        </Button>
                    </Form.Item>
                </Form>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Upload
                        action={updateLogo}
                        beforeUpload={file => {
                            const isPNG = file.type === 'image/png';
                            const isJPG = file.type === 'image/jpeg';
                            if (!isPNG && !isJPG) {
                                notification.error({
                                    message: `${file.name} ${formatMessage({
                                        id: 'photo_modal.is_not_an_image'
                                    })}!`
                                });
                            }

                            return isPNG || isJPG;
                        }}
                        className='avatar-uploader'
                        fileList={
                            _.get(logo, 'path')
                                ? [
                                      {
                                          uid: '-1',
                                          name: 'logo.png',
                                          status: 'done',
                                          url: `//s1.car-book.com${_.get(logo, 'path')}`
                                      }
                                  ]
                                : []
                        }
                        listType='picture-card'
                        // showUploadList={false}
                        name='avatar'
                        onRemove={deleteLogo}
                    >
                        {!_.get(logo, 'path') && (
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>
                                    <FormattedMessage id='general_settings.upload_logo' />
                                </div>
                            </div>
                        )}
                    </Upload>
                    <span
                        style={{
                            opacity: 0.5,
                            fontSize: 16
                        }}
                    >
                        <FormattedMessage id='general_settings.recomendation_size' />
                    </span>
                </div>
            </div>
        );
    }
);
